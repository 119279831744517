import {
    Error,
    OrderQuoteBase,
    OrderQuoteByQuantityRequest,
    OrderQuoteByQuantityWithBundleMass,
    OrderQuoteByQuantityWithBundlePercentage,
} from '@lune-climate/lune'
import { Big } from 'big.js'
import { useSnackbar } from 'notistack'
import { FC, ReactNode, useCallback, useEffect, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { debounce } from 'throttle-debounce'

import useBuyOffsetsState from 'hooks/useBuyOffsetsState'
import { useLuneClient } from 'hooks/useLuneClient'
import useMixpanel from 'hooks/useMixpanel'
import { EditType } from 'models/order'
import { mapErrorsToMessage } from 'SnackbarMessages'
import { Allocation, IOrderByMassPayload } from 'views/BuyOffsets/BuyOffsetsTypes'
import {
    orderPayloadByMass,
    orderPayloadByMassWithCustomPercentage,
} from 'views/BuyOffsets/BuyOffsetsUtils'

const ByVolumeCalculationsWrapper: FC<{
    allocation: Allocation[]
    customizedPortfolio: boolean
    children?: ReactNode
}> = ({ allocation, customizedPortfolio, children }) => {
    const mixpanel = useMixpanel()
    const client = useQueryClient()
    const { enqueueSnackbar: snackbar } = useSnackbar()
    const luneClient = useLuneClient()
    const { buyOffsetProps, setBuyOffsetProps } = useBuyOffsetsState()

    const { error, orderType, amount, truncateToTonnes, editType, isCustomBundleVolumeSet } =
        buyOffsetProps

    // QUOTE CALCULATIONS
    const orderQuantityInvalidError = (errors: Error[]) => {
        const err = mapErrorsToMessage(errors)
        if (err.key !== Error.error_code.ORDER_QUANTITY_INVALID) {
            snackbar(err.message)
        }
    }

    const setQuote = (quote: OrderQuoteBase) => {
        const insufficientBundles = quote.bundles
            .filter((bundle) => bundle.insufficientAvailableQuantity)
            .map((b) => b.bundleName)
        const insufficientBundlesError = insufficientBundles.length
            ? {
                  key: 'insufficientBundles',
                  message: ` We don’t have enough ${insufficientBundles.join(
                      ', ',
                  )} credits to fulfill your request. We have allocated the maximum available.`,
              }
            : undefined
        setBuyOffsetProps({
            error: undefined,
            insufficientBundlesError,
            quote,
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const calculateQuoteDebounced = useCallback(
        debounce(
            250,
            async (payload: OrderQuoteByQuantityRequest) => {
                const result = await client.fetchQuery(JSON.stringify(payload), () =>
                    luneClient.getOrderQuoteByMass({ orderQuoteByQuantityRequest: payload }),
                )

                if (result.isOk()) {
                    setQuote(result.value)
                } else {
                    const errors = 'errors' in result.error ? result.error.errors : undefined
                    if (errors) {
                        orderQuantityInvalidError(errors)
                    }
                    setBuyOffsetProps({
                        error: mapErrorsToMessage(errors),
                        insufficientBundlesError: undefined,
                        quote: undefined,
                    })
                }

                mixpanel.track('Quote', {
                    type: 'by-mass',
                    value: Big(amount || 0).toNumber(),
                })
            },
            { atBegin: false },
        ),
        [],
    )

    const payloadByMass = useMemo<IOrderByMassPayload>((): IOrderByMassPayload => {
        if (editType === EditType.EDIT_BY_PERCENTAGE) {
            return orderPayloadByMassWithCustomPercentage(
                allocation,
                amount || '0',
                truncateToTonnes,
            )
        }

        return orderPayloadByMass(
            allocation,
            isCustomBundleVolumeSet || customizedPortfolio,
            amount || '0',
            truncateToTonnes,
        ) as OrderQuoteByQuantityWithBundlePercentage | OrderQuoteByQuantityWithBundleMass
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [amount, allocation, truncateToTonnes, orderType, error])

    useEffect(() => {
        if (amount && amount !== '0' && allocation.length && !error) {
            calculateQuoteDebounced(payloadByMass)
        }
    }, [allocation, amount, calculateQuoteDebounced, error, payloadByMass])

    return <>{children}</>
}

export default ByVolumeCalculationsWrapper
