import { Checkbox, Text } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import { Dispatch, FC, SetStateAction } from 'react'

const TermsAndPolicies: FC<{
    marketingConsent: boolean
    setMarketingConsent: Dispatch<SetStateAction<boolean>>
}> = ({ marketingConsent, setMarketingConsent }) => {
    return (
        <Box
            sx={{
                mt: 4,
                a: {
                    textDecoration: 'underline !important',
                    fontStyle: 'normal',
                    color: 'inherit',
                },
            }}
        >
            <Checkbox
                label={
                    'I am happy to receive marketing communications from Lune. You can unsubscribe any time.'
                }
                checked={marketingConsent}
                onChange={(e, checked) => setMarketingConsent(checked)}
            />
            <Text variant={'body3'} sx={{ mt: 2, display: 'block' }}>
                By selecting Agree and request access below, I agree to Lune’s{' '}
                <a target="_blank" href="https://lune.co/terms" rel="noreferrer">
                    Terms of Service, Privacy and Cookie Policies
                </a>
            </Text>
        </Box>
    )
}

export default TermsAndPolicies
