import { Button, MemberInfoCard } from '@lune-fe/lune-ui-lib'
import { SxProps } from '@mui/material'
import Box from '@mui/material/Box'
import { Dispatch, FC, SetStateAction } from 'react'
import { useNavigate } from 'react-router-dom'

import { GoogleSSOUserProfile } from 'endpoints/dapi'

const GoogleSSOData: FC<{
    googleData: GoogleSSOUserProfile
    setGoogleData: Dispatch<SetStateAction<GoogleSSOUserProfile | undefined>>
    sx?: SxProps
}> = ({ googleData, setGoogleData, sx: customSx }) => {
    const navigate = useNavigate()

    const resetGoogleSSOData = () => {
        setGoogleData(undefined)
        navigate('/signup')
    }

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '8px 0',
                ...(customSx || {}),
            }}
        >
            <MemberInfoCard
                name={`${googleData.firstname} ${googleData.lastname}`}
                email={googleData.email}
                src={googleData.avatar}
            />
            <Button variant={'text'} onClick={resetGoogleSSOData}>
                Not you?
            </Button>
        </Box>
    )
}
export default GoogleSSOData
