import { Project } from '@lune-climate/lune'
import { UnSDG } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'

const UnSDGs = ({ project }: { project: Project }) => {
    return (
        <Box
            sx={{
                width: '100%',
                display: `grid`,
                gridGap: 8,
                gridTemplateColumns: `repeat(auto-fill, 72px)`,
                gridTemplateRows: `repeat(auto-fill,72px)`,
            }}
        >
            {project.unSdg?.map((num, i) => {
                return (
                    <Box
                        key={`sdg-${num}`}
                        sx={{
                            display: `flex`,
                            justifyContent: `center`,
                            alignItems: `center`,
                        }}
                    >
                        <UnSDG n={num as any} sx={{ width: '72px', height: '72px' }} key={i} />
                    </Box>
                )
            })}
        </Box>
    )
}

export default UnSDGs
