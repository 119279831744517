import { LuneTheme } from '@lune-fe/lune-ui-lib'
import { TooltipItem } from 'chart.js'

export interface DoughnutDatapoint {
    label: string
    value: number
}

const { palette } = LuneTheme

export const doughnutChartOptions = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            backgroundColor: palette.Grey900,
            displayColors: false,
            callbacks: {
                label: (tooltipItem: TooltipItem<any>) => {
                    const tooltipValue = Number(tooltipItem.formattedValue)
                    return `${
                        tooltipValue < 1 && tooltipValue > 0 ? '< 1' : Math.round(tooltipValue)
                    }% ${tooltipItem.label}`
                },
            },
        },
    },
}
