import { LuneTheme, Switch, Text, Tile } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import _ from 'lodash'
import { FC, memo } from 'react'

const RoundDownToTonne: FC<{
    truncateToTonnes: boolean
    setTruncateToTonnes: (truncate: boolean) => void
}> = ({ truncateToTonnes, setTruncateToTonnes }) => {
    const { palette } = LuneTheme

    return (
        <Tile
            rightSide={
                <Switch
                    data-testid={`toggle-round-down-to-tonne`}
                    defaultChecked={truncateToTonnes}
                    onChange={(e) => {
                        setTimeout(() => {
                            setTruncateToTonnes(e.target.checked)
                        }, 100)
                    }}
                />
            }
        >
            <Box>
                <Text variant={'body2'} sx={{ marginBottom: '4px' }}>
                    Round down to nearest tonne
                </Text>
                <Text variant={'body3'} sx={{ color: palette.Grey600 }}>
                    To ensure the order can be fully retired
                </Text>
            </Box>
        </Tile>
    )
}

export default memo(RoundDownToTonne, (prev, next) => {
    return _.isEqual(prev.truncateToTonnes, next.truncateToTonnes)
})
