import AccessTimeIcon from '@mui/icons-material/AccessTime'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import type { SxProps } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { FC } from 'react'

import Loading from './Loading'

export enum StepState {
    PENDING,
    ACTIVE,
    COMPLETED,
    CANCELED,
}

export interface Step {
    label: string
    state: StepState
    icon?: JSX.Element
    color?: string
    backgroundColor?: string
}

export interface StepperProps {
    steps: Step[]
    sx: SxProps
}

const Stepper: FC<StepperProps> = ({ steps, sx }) => {
    const setStateProps = (step: Step): Step => {
        switch (step.state) {
            case StepState.ACTIVE:
                return {
                    ...step,
                    icon: <Loading sx={{ width: '24px' }} />,
                    color: `Grey900`,
                    backgroundColor: `Grey100`,
                }
            case StepState.COMPLETED:
                return {
                    ...step,
                    icon: <CheckIcon sx={{ width: '24px' }} />,
                    color: `Blue500`,
                    backgroundColor: `Blue50`,
                }
            case StepState.PENDING:
                return {
                    ...step,
                    icon: <AccessTimeIcon sx={{ width: '24px' }} />,
                    color: `Grey900`,
                    backgroundColor: `Grey100`,
                }
            case StepState.CANCELED:
                return {
                    ...step,
                    icon: <CloseIcon sx={{ width: '24px' }} />,
                    color: `Red500`,
                    backgroundColor: `Red50`,
                }
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                ...sx,
            }}
        >
            {steps
                .map((step) => setStateProps(step))
                .map((step, index) => (
                    <>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                                position: 'relative',
                            }}
                        >
                            <Box
                                sx={{
                                    height: '48px',
                                    width: '48px',
                                    borderRadius: '8px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    color: step.color,
                                    backgroundColor: step.backgroundColor,
                                    marginBottom: '12px',
                                }}
                            >
                                {step.icon}
                            </Box>
                            {step.label && (
                                <Typography
                                    variant={'body3'}
                                    sx={{
                                        color:
                                            step.state === StepState.CANCELED
                                                ? `Red500`
                                                : step.state === StepState.PENDING
                                                  ? `Grey500`
                                                  : `Grey900`,
                                    }}
                                >
                                    {step.label}
                                </Typography>
                            )}
                        </Box>
                        {index < steps.length - 1 && (
                            <Box
                                sx={{
                                    marginTop: '-40px',
                                }}
                            >
                                ....
                            </Box>
                        )}
                    </>
                ))}
        </Box>
    )
}

export default Stepper
