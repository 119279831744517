import type { SxProps } from '@mui/material'
import MUICheckbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import React, { FC, useMemo } from 'react'

import { LuneTheme } from '../theme'

export interface CheckboxProps {
    checked: boolean
    onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
    disabled?: boolean
    indeterminate?: boolean
    sx?: SxProps
    label?: string
}

const Checkbox: FC<CheckboxProps> = ({ checked, disabled, indeterminate, onChange, sx, label }) => {
    const { palette } = LuneTheme
    const StyledCheckbox = useMemo(
        () =>
            styled(MUICheckbox)(
                LuneTheme.unstable_sx({
                    color: palette.Grey900,
                    padding: 0,
                    '&.Mui-checked': {
                        color: palette.Grey900,
                    },
                }),
            ),
        [],
    )

    return (
        <FormControlLabel
            sx={{
                marginLeft: '0 !important',
                marginRight: '0 !important',
                ...sx,
            }}
            control={
                <StyledCheckbox
                    checked={checked}
                    indeterminate={indeterminate}
                    disabled={disabled}
                    onChange={onChange}
                    id={`checkbox-id-${label}`}
                />
            }
            label={
                label && (
                    <Typography variant={'body3'} sx={{ ml: 3 }}>
                        {label}
                    </Typography>
                )
            }
        />
    )
}

export default Checkbox
