import { Bundle } from '@lune-climate/lune'
import { Switch, Table } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'
import { FC, useMemo } from 'react'

import BundleListItemCell from 'views/Settings/BundlePicker/BundleListItemCell'

const BundleSwitcherList: FC<{
    bundles: Bundle[]
    bundleSelection: Record<string, number | string>
    onChangeItem: (id: string, checked: boolean) => void
}> = ({ bundles, bundleSelection, onChangeItem }) => {
    const listOfUncontrolledSwitches = useMemo(() => {
        return bundles.map((bundle) => {
            const { id, name } = bundle
            return (
                <Table.TableRow key={id} data-testid={`bundle-${name}`} hover>
                    <BundleListItemCell bundle={bundle} />
                    <Table.TableCell colSize={2} align="right">
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                width: '100%',
                            }}
                        >
                            <Switch
                                data-testid={`${name
                                    .toLocaleLowerCase()
                                    .replaceAll(' ', '-')}-switchControl`}
                                onChange={(e) => onChangeItem(id, e.target.checked)}
                                defaultChecked={Object.keys(bundleSelection).includes(id)}
                            />
                        </Box>
                    </Table.TableCell>
                </Table.TableRow>
            )
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bundles])

    return (
        <Table.TableContainer data-testid={`bundle-switcher-list`}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <Table.TableBody>{listOfUncontrolledSwitches}</Table.TableBody>
            </Table>
        </Table.TableContainer>
    )
}

export default BundleSwitcherList
