import { AccordionTile, LuneTheme, Text } from '@lune-fe/lune-ui-lib'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { Box, Link } from '@mui/material'
import { styled } from '@mui/material/styles'

const FAQs = () => {
    const { palette } = LuneTheme
    const StyleLink = styled(Link)(
        LuneTheme.unstable_sx({
            color: palette.Grey600,
            textDecorationColor: palette.Grey600,
        }),
    )
    return (
        <Box>
            <Text variant={'h6'} sx={{ mb: 4 }}>
                FAQs
            </Text>
            <AccordionTile
                title={
                    <Text variant={'body2'}>
                        What’s the difference between reductions and removals?
                    </Text>
                }
                icon={<HelpOutlineIcon />}
            >
                <Text variant={'body3'} sx={{ color: palette.Grey600 }}>
                    Emissions reduction – or emissions avoidance – projects result in future
                    greenhouse gas emissions being reduced or avoided. E.g. capturing the CO₂
                    emitted when producing cement, or conserving forests to avoid the emissions
                    connected with their cutting or burning.
                    <br />
                    <br />
                    Carbon removal projects – also referred to as Carbon Dioxide Removal (CDR) or
                    carbon drawdown projects – physically remove existing carbon from the
                    atmosphere. This happens in nature and can also be achieved by technological
                    solutions.
                    <br />
                    <br />
                    <StyleLink
                        target={'_blank'}
                        href={
                            'https://lune.co/blog/difference-between-carbon-removal-and-emissions-avoidance-in-offsetting/'
                        }
                    >
                        Learn more.
                    </StyleLink>
                </Text>
            </AccordionTile>
            <AccordionTile
                title={<Text variant={'body2'}>Why does storage timeframe matter?</Text>}
                icon={<HelpOutlineIcon />}
            >
                <Text variant={'body3'} sx={{ color: palette.Grey600 }}>
                    Permanence – the CO₂ storage timeframe – refers to how long the carbon will be
                    kept out of the atmosphere. It can be impacted by both the type of project
                    (nature projects typically have short timeframes, while pumping CO₂ underground
                    can last millennia) as well as the risks mitigation in place.
                    <br />
                    <br />
                    In general, the longer we can guarantee CO₂ stays out of the atmosphere, the
                    better.
                    <br />
                    <br />
                    <StyleLink
                        target={'_blank'}
                        href={'https://lune.co/blog/permanence-in-carbon-offsetting-explained/'}
                    >
                        Learn more.
                    </StyleLink>
                </Text>
            </AccordionTile>
            <AccordionTile
                title={<Text variant={'body2'}>What are the different certifications?</Text>}
                icon={<HelpOutlineIcon />}
            >
                <Text variant={'body3'} sx={{ color: palette.Grey600 }}>
                    Verra, Gold Standard, Climate Action Reserve, Puro.earth and others, are
                    organisations working to safeguard the quality of carbon credits sold in the
                    unregulated Voluntary Carbon Market (VCM).
                    <br />
                    <br />
                    They develop methodologies to verify projects, audit and certify projects, and
                    issue and retire carbon credits within a registry.
                    <br />
                    <br />
                    <StyleLink
                        target={'_blank'}
                        href={
                            'https://lune.co/blog/whats-the-role-of-carbon-standards-in-the-voluntary-carbon-market/'
                        }
                    >
                        Learn more.
                    </StyleLink>
                </Text>
            </AccordionTile>
            <AccordionTile
                title={<Text variant={'body2'}>Why are some projects uncertified?</Text>}
                icon={<HelpOutlineIcon />}
            >
                <Text variant={'body3'} sx={{ color: palette.Grey600 }}>
                    Novel solutions to remove greenhouse gases or reduce emissions are currently
                    being developed (e.g. Direct Air Capture, Rock Weathering, Bio-oil
                    sequestration, etc). This means that existing certification bodies don’t yet
                    have methodologies to verify the climate benefits of these projects.
                    <br />
                    <br />
                    The methodology to verify such projects could be in the works, in other cases it
                    might be ready and the projects will be verified in the next few years.
                    Additional risk mitigation is provided by many of these projects who publicly
                    report their data and verification by third party bodies (e.g. Charm
                    Industrial’s public registry).
                    <br />
                    <br />
                    These projects may not yet be certified, but are nonetheless worth funding to
                    make them workable solutions in the near future.
                    <br />
                    <br />
                    <StyleLink
                        target={'_blank'}
                        href={
                            'https://lune.co/blog/the-state-of-carbon-dioxide-removal-we-need-to-scale-up-carbon-removal-tech-quickly-to-meet-climate-targets/'
                        }
                    >
                        Learn more.
                    </StyleLink>
                </Text>
            </AccordionTile>
            <AccordionTile
                title={
                    <Text variant={'body2'}>
                        What should I take into account when buying carbon?
                    </Text>
                }
                icon={<HelpOutlineIcon />}
            >
                <Text variant={'body3'} sx={{ color: palette.Grey600 }}>
                    Not all carbon offsets are created equal – and if you are buying carbon credits,
                    you need to be able to tell the high-quality carbon offsets from the
                    low-quality. In basic terms, a carbon offset is high-quality if it definitely
                    provides the carbon benefit that it claims to.
                    <br />
                    <br />
                    There are several markers of quality to look out for: External checks and
                    verification, single counting, permanence, additionality, avoiding
                    overestimation.
                    <br />
                    <br />
                    <StyleLink
                        target={'_blank'}
                        href={'https://lune.co/blog/what-makes-a-high-quality-carbon-offset/'}
                    >
                        Learn more.
                    </StyleLink>
                </Text>
            </AccordionTile>
        </Box>
    )
}

export default FAQs
