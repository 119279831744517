import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import React, { FC } from 'react'
import { ListChildComponentProps, VariableSizeList } from 'react-window'

import Textarea from './Textarea'

export type FilterFunction = (option: { label: string }, key: string) => boolean

export interface Props<T> {
    value: T
    items: T[]
    onChange: (value: T) => void
    disabled?: boolean
    label?: string
    error?: string | boolean
    groupBy?: (option: T) => string
    showGroupName?: boolean
    filter?: FilterFunction
    customOptions?: (option: T) => React.ReactNode
}

const defaultSearch = (option: { label: string }, key: string): boolean => {
    return option.label.toLowerCase().includes(key)
}

const AutocompleteSelect = <T,>({
    value,
    items,
    onChange,
    disabled,
    label,
    error,
    groupBy,
    customOptions,
    filter,
    showGroupName = true,
    ...rest
}: Props<T>): React.ReactElement => {
    const { spacing, palette } = useTheme()

    function renderRow(props: ListChildComponentProps) {
        const { data, index, style } = props
        const dataSet = data[index]

        const inlineStyle = {
            ...style,
            top: style.top as number,
        }

        if (Object.prototype.hasOwnProperty.call(dataSet, 'group')) {
            return (
                <Box
                    style={inlineStyle}
                    sx={{
                        borderTop: `1px solid ${palette.Grey300}`,
                    }}
                >
                    {showGroupName && (
                        <Typography
                            variant={'body3'}
                            sx={{
                                lineHeight: '47px',
                                padding: '0 16px',
                                fontWeight: '500',
                            }}
                        >
                            {dataSet.group}
                        </Typography>
                    )}
                </Box>
            )
        }

        return (
            <MenuItem
                {...dataSet[0]}
                style={inlineStyle}
                sx={{
                    padding: `0 ${spacing(2)} !important`,
                    height: '48px',
                }}
            >
                {customOptions ? (
                    <>{customOptions(dataSet[1])}</>
                ) : (
                    <Typography sx={{ color: palette.Grey900 }} variant={`body3`}>
                        {dataSet[1].label}
                    </Typography>
                )}
            </MenuItem>
        )
    }

    const useResetCache = (data: number) => {
        const ref = React.useRef<VariableSizeList>(null)
        React.useEffect(() => {
            if (ref.current != null) {
                ref.current.resetAfterIndex(0, true)
            }
        }, [data])
        return ref
    }

    const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(
        function ListboxComponent(props, ref) {
            const { children, ...other } = props
            const itemData: React.ReactChild[] = []
            ;(children as React.ReactChild[]).forEach(
                (item: React.ReactChild & { children?: React.ReactChild[] }) => {
                    itemData.push(item)
                    itemData.push(...(item.children || []))
                },
            )

            const itemCount = itemData.length
            const itemSize = 48

            const getChildSize = (child: React.ReactChild) => {
                if (Object.prototype.hasOwnProperty.call(child, 'group')) {
                    return showGroupName ? 48 : 1
                }

                return itemSize
            }

            const getHeight = () => {
                if (itemCount > 8) {
                    return 8 * itemSize
                }
                return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
            }

            const gridRef = useResetCache(itemCount)

            const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
                return <div ref={ref} {...props} {...other} />
            })
            OuterElementType.displayName = 'OuterElementType'

            return (
                <div ref={ref}>
                    <VariableSizeList
                        itemData={itemData}
                        height={getHeight() + 2}
                        width="100%"
                        ref={gridRef}
                        /* @ts-ignore: ... */
                        outerElementType={OuterElementType}
                        innerElementType="ul"
                        itemSize={(index) => getChildSize(itemData[index])}
                        overscanCount={5}
                        itemCount={itemCount}
                    >
                        {renderRow}
                    </VariableSizeList>
                </div>
            )
        },
    )

    const CustomPaper: FC = (props) => {
        return (
            <Paper
                {...props}
                elevation={4}
                sx={{
                    borderRadius: '8px',
                    '.MuiAutocomplete-listbox': {
                        padding: `0`,
                        '> ul': {
                            padding: 0,
                            margin: 0,
                        },
                    },
                }}
            />
        )
    }

    return (
        <Autocomplete<T>
            PaperComponent={CustomPaper}
            groupBy={groupBy}
            renderOption={(props, option) => [props, option] as React.ReactNode}
            renderGroup={(params) => params as unknown as React.ReactNode}
            disabled={disabled}
            renderInput={(params) => (
                <Textarea error={error} {...params} placeholder={label} singleLine={true} />
            )}
            value={value}
            onChange={(event, selectedOption) => onChange(selectedOption as T)}
            options={items}
            filterOptions={(options, state) => {
                const searchKey = state.inputValue.toLowerCase()
                if (filter && !searchKey.includes(' ')) {
                    return options.filter((option) =>
                        filter(option as unknown as { label: string }, searchKey),
                    )
                } else {
                    return options.filter((option) =>
                        defaultSearch(option as unknown as { label: string }, searchKey),
                    )
                }
            }}
            ListboxComponent={ListboxComponent}
            {...rest}
        />
    )
}

export default AutocompleteSelect
