import { formatNumbers } from '@lune-fe/lune-components-lib'
import { IconButtonDropdown } from '@lune-fe/lune-ui-lib'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import CreateIcon from '@mui/icons-material/Create'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { EstimateWithAccountInfo } from 'models/openDapi'
import { EstimateTypeSlug } from 'views/CalculateEmissions/EmissionCalculationDefinitions'

const EmissionCalculationsContextMenu: FC<{
    calculation: EstimateWithAccountInfo
    editable?: boolean
}> = ({ calculation, editable }) => {
    const navigate = useNavigate()

    return (
        <IconButtonDropdown menuAutoWidth icon={<MoreVertOutlinedIcon />}>
            {editable && (
                <IconButtonDropdown.MenuItem
                    icon={<CreateIcon />}
                    title={`Edit`}
                    onClick={() =>
                        navigate(
                            `/calculate-emissions/${EstimateTypeSlug(calculation.estimateType)}/${
                                calculation.id
                            }`,
                        )
                    }
                />
            )}
            <IconButtonDropdown.MenuItem
                icon={<CloudDownloadOutlinedIcon />}
                title={`Offset`}
                onClick={() => navigate(`/order?amount=${formatNumbers(calculation.quantity, 2)}`)}
            />
        </IconButtonDropdown>
    )
}

export default EmissionCalculationsContextMenu
