export interface IAccount {
    id: string
    name: string
    currency: string
    balance: string
    balanceOutstanding: string
    organisationId: string
    status: AccountStatus
    modifiedAt: string
    createdAt: string
    type: AccountType
    logo?: string
    beneficiary?: string
}

export enum AccountType {
    LIVE = 'live',
    TEST = 'test',
}

export enum AccountStatus {
    ACTIVE = 'active',
    SUSPENDED = 'suspended',
}

export enum AccountMetricName {
    THIRTY_D_OFFSET_VALUE = '30d_offset_value',
    THIRTY_D_OFFSET_QUANTITY = '30d_offset_quantity',
    THIRTY_D_AVG_OFFSET_VALUE = '30d_avg_offset_value',
    THIRTY_D_NUM_ORDERS = '30d_num_orders',
}

export interface IAccountMetric {
    name: AccountMetricName
    value: string
}

export interface IDataPoint {
    x: string
    y: string
}
