import { Bundle } from '@lune-climate/lune'
import { formatNumbers } from '@lune-fe/lune-components-lib'
import { Button, Loading, Text, Tooltip } from '@lune-fe/lune-ui-lib'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import { Box } from '@mui/material'
import { Big } from 'big.js'
import { FC, RefObject, useEffect, useMemo, useState } from 'react'

import useAccountCurrency from 'hooks/useAccountCurrency'
import useBundles from 'hooks/useBundles'
import { Portfolio } from 'models/openDapi'
import { Allocation } from 'views/BuyOffsets/BuyOffsetsTypes'
import BundlesGrid from 'views/Proposals/Proposal/BundlesGrid'
import BundlesList from 'views/Proposals/Proposal/BundlesList'
import ExpandableSection from 'views/Proposals/Proposal/ExpandableSection'

export const PROPOSAL_OPTION_PREFIX = 'proposal-option'

// This method returns a list of bundles with rounded down percentages
// These bundles are used only to visualize 'perfect' options, and not for allocations
// example:
// [{bundle: 1, percentage: 10.2, bundle: 2, percentage: 89.8, }]
// [{bundle: 1, percentage: 10, bundle: 2, percentage: 90, }]
export const setFixedPercentages = (selectedBundles: { bundle: Bundle; percentage: string }[]) => {
    let totalPercentage = Big(0)
    return selectedBundles.map((selectedBundle, index) => {
        if (index < selectedBundles.length - 1) {
            const percentage = Big(selectedBundle.percentage).round(0)
            totalPercentage = totalPercentage.add(percentage)
            return { bundle: selectedBundle.bundle, percentage: percentage.toString() }
        } else {
            return {
                bundle: selectedBundle.bundle,
                percentage: Big(100).sub(totalPercentage).round(0).toString(),
            }
        }
    })
}
const Option: FC<{
    option: Portfolio
    scrollRef: RefObject<HTMLDivElement>
    index: number
    onEdit: (allocation: Allocation[]) => void
    onBuyNow: (allocation: Allocation[]) => void
    calculatingQuote: boolean
    proposalId: string
}> = ({ option, index, scrollRef, onBuyNow, onEdit, calculatingQuote, proposalId }) => {
    const { bundles } = useBundles()
    const toCurrency = useAccountCurrency()

    const [localCalculatingQuote, setLocalCalculatingQuote] = useState<boolean>(false)
    const [expanded, setExpanded] = useState<boolean>(
        sessionStorage.getItem(`${PROPOSAL_OPTION_PREFIX}-${index}-${proposalId}`) === 'expanded',
    )

    useEffect(() => {
        sessionStorage.setItem(
            `${PROPOSAL_OPTION_PREFIX}-${index}-${proposalId}`,
            expanded ? 'expanded' : 'collapsed',
        )
    }, [expanded, index, proposalId])

    const selectedBundles: { bundle: Bundle; percentage: string }[] = useMemo(() => {
        return option.bundleSelection
            .map((b) => {
                return {
                    bundle: bundles.find((bundle) => bundle.id === b.bundleId)!,
                    percentage: b.percentage.toString(),
                }
            })
            .sort((b1, b2) => Number(b1.percentage) - Number(b2.percentage))
    }, [bundles, option])

    const selectedBundlesWithFixedPercentages = setFixedPercentages(selectedBundles)

    const allocations = useMemo(() => {
        const allocation: Allocation[] = selectedBundles.map((selection) => {
            return {
                bundle: selection.bundle,
                percentage: selection.percentage.toString(),
                volume: Big(option.quantity.amount)
                    .mul(Big(selection.percentage))
                    .div(100)
                    .toNumber(),
            }
        })
        return allocation
    }, [option.quantity.amount, selectedBundles])

    return (
        <>
            {!!bundles.length && (
                <Box>
                    <Text variant={'h5'}>Option {index + 1}</Text>
                    <ExpandableSection
                        expanded={expanded}
                        bundlesList={
                            <BundlesList selectedBundles={selectedBundlesWithFixedPercentages} />
                        }
                        bundlesGrid={
                            <BundlesGrid
                                scrollRef={scrollRef}
                                selectedBundles={selectedBundlesWithFixedPercentages}
                            />
                        }
                        optionsLength={option.bundleSelection.length}
                    />

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                gap: 1,
                                alignItems: 'center',
                            }}
                        >
                            <Button
                                sx={{ minWidth: '110px' }}
                                variant={'contained'}
                                onClick={() => {
                                    setLocalCalculatingQuote(true)
                                    onBuyNow(allocations)
                                }}
                                leftIcon={
                                    calculatingQuote && localCalculatingQuote ? (
                                        <Loading
                                            sx={{
                                                filter: 'invert(1)',
                                            }}
                                        />
                                    ) : (
                                        <ArrowCircleRightIcon
                                            sx={{
                                                width: `20px`,
                                                height: `20px`,
                                                borderRadius: `40px`,
                                            }}
                                        />
                                    )
                                }
                            >
                                Buy now
                            </Button>
                            <Button
                                variant={'text'}
                                onClick={() => onEdit(allocations)}
                                leftIcon={<ModeEditOutlineOutlinedIcon />}
                            >
                                Edit
                            </Button>
                            <Button
                                variant={'text'}
                                onClick={() => setExpanded(!expanded)}
                                leftIcon={
                                    expanded ? (
                                        <ExpandLessOutlinedIcon />
                                    ) : (
                                        <ExpandMoreOutlinedIcon />
                                    )
                                }
                            >
                                {expanded ? 'Less details' : 'More details'}
                            </Button>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                gap: 3,
                                alignItems: 'center',
                            }}
                        >
                            <Text variant={'h6'}>{formatNumbers(option.quantity.amount)} tCO₂</Text>
                            <Text
                                variant={'h6'}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    gap: 1,
                                    alignItems: 'center',
                                }}
                            >
                                Total {toCurrency?.(option.estimatedTotalCost)}
                                <Tooltip
                                    title={
                                        <Box sx={{ maxWidth: '140px', textAlign: 'center' }}>
                                            All prices are indicative until final purchase
                                        </Box>
                                    }
                                    placement="top"
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <InfoOutlinedIcon
                                            sx={{
                                                verticalAlign: 'middle',
                                                width: '20px',
                                                height: '20px',
                                            }}
                                        />
                                    </Box>
                                </Tooltip>
                            </Text>
                        </Box>
                    </Box>
                </Box>
            )}
        </>
    )
}

export default Option
