import type { SxProps } from '@mui/material'
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import MuiMenuItem from '@mui/material/MenuItem'
import * as React from 'react'
import { ReactElement, ReactNode } from 'react'

import Button from './Button'

const IconButtonDropdown = ({
    icon,
    id,
    children,
    closeOnClick = true,
    menuAutoWidth,
    sx,
    position = 'left',
}: {
    icon: JSX.Element
    id?: string
    closeOnClick?: boolean
    menuAutoWidth?: boolean
    sx?: SxProps
    position?: 'left' | 'right' | 'center'
    children: ReactNode
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    const handlePanelClick = () => {
        if (closeOnClick) handleClose()
    }

    return (
        <>
            <Button
                variant={'text'}
                iconButton
                aria-controls={open ? `dropdown-panel-${id}` : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                leftIcon={icon}
                onClick={handleClick}
                sx={{
                    color: `Grey900`,
                    ...sx,
                }}
            />
            <Menu
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: position,
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: position,
                }}
                onClick={handlePanelClick}
                MenuListProps={{
                    'aria-labelledby': `dropdown-btn-${id}`,
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                elevation={4}
                sx={{
                    '.MuiPaper-root': {
                        mt: 1,
                        borderRadius: '8px',
                        minWidth: menuAutoWidth ? 'auto' : '280px',
                        py: 1,
                    },
                }}
            >
                {children}
            </Menu>
        </>
    )
}

const MenuItem = ({
    icon,
    title,
    onClick,
    disabled,
}: {
    icon: ReactElement
    title: ReactNode
    onClick: () => void
    disabled?: boolean
}) => {
    return (
        <MuiMenuItem
            sx={{
                py: 1.5,
                color: `Grey900`,
                fontSize: '14px',
                '&:hover': {
                    backgroundColor: `Grey100`,
                },
            }}
            onClick={onClick}
            disabled={disabled}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    px: 1,
                }}
            >
                <Box
                    sx={{
                        color: `Grey900`,
                        width: `24px`,
                        height: `24px`,
                        mr: 3,
                    }}
                >
                    {icon}
                </Box>
                <Box
                    sx={{
                        color: `Grey900`,
                        fontSize: '14px',
                    }}
                >
                    {title}
                </Box>
            </Box>
        </MuiMenuItem>
    )
}

IconButtonDropdown.MenuItem = MenuItem
export default IconButtonDropdown
