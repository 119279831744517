import { Bundle, OrderQuoteBase } from '@lune-climate/lune'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { EditType, OrderType } from 'models/order'
import { validatePartialUpdate } from 'utils'
import initialState from 'views/BuyOffsets/BuyOffsetsInitialState'
import {
    Allocation,
    BundleWithPercentage,
    BundleWithVolume,
    BuyOffsetProps,
} from 'views/BuyOffsets/BuyOffsetsTypes'

interface BuyOffsetsState {
    buyOffsetProps: BuyOffsetProps
    setBuyOffsetProps: (props: Partial<BuyOffsetProps>) => void
    resetBuyOffsetProps: () => void
}

type BuyOffsetPropsV0 = {
    amount?: string
    selectedBundles?: Bundle[]
    orderType: OrderType
    editType?: string
    truncateToTonnes?: boolean
    quote?: OrderQuoteBase
    error?: { key: string; message: string }
    insufficientBundlesError?: { key: string; message: string }
    allocation?: Allocation[]
    customVolumes?: BundleWithVolume[]
    isCustomBundleVolumeSet?: boolean
    customPercentages?: BundleWithPercentage[]
    isCustomBundlePercentageSet?: boolean
    orderTotalAmount?: number
    orderTotalPercentage?: number
}

// Handle the changes in
// https://github.com/lune-climate/lune-dashboard/pull/1616
export function migrateToV1(prev: BuyOffsetPropsV0 | BuyOffsetProps): BuyOffsetProps {
    if (prev.customPercentages !== undefined) {
        // Some states have transitioned to BuyOffsetProps
        return prev as BuyOffsetProps
    }

    // and some have not
    return {
        amount: prev.amount ?? '',
        orderType: prev.orderType,
        editType: prev.editType ?? (EditType.EDIT_BY_PERCENTAGE as string),
        truncateToTonnes: prev.truncateToTonnes ?? true,
        quote: prev.quote,
        error: prev.error,
        allocation: prev.allocation ?? [],
        insufficientBundlesError: prev.insufficientBundlesError,
        isCustomBundleVolumeSet: prev.isCustomBundleVolumeSet ?? false,
        customVolumes: [],
        isCustomBundlePercentageSet: false,
        customPercentages: [],
        selectedBundles: prev.selectedBundles ?? [],
        orderTotalAmount: prev.orderTotalAmount ?? 0,
        orderTotalPercentage: prev.orderTotalPercentage ?? 0,
        isCustomPortfolioCartOpen: false,
        selectedPortfolioId: undefined,
    }
}

const useBuyOffsetsState = create<BuyOffsetsState>()(
    persist(
        (set) => ({
            buyOffsetProps: initialState,
            setBuyOffsetProps: (partialUpdateOfState) => {
                return set((state) => {
                    // Disallow setting of undefined values to any field that has a value in initialState
                    // To strongly discourage this, we throw an error in validatePartialUpdate
                    validatePartialUpdate({
                        partialUpdate: partialUpdateOfState,
                        validStateWithUndefined: initialState,
                    })

                    return {
                        buyOffsetProps: {
                            ...state.buyOffsetProps,
                            ...partialUpdateOfState,
                        },
                    }
                })
            },
            resetBuyOffsetProps: () => {
                set(() => ({
                    buyOffsetProps: initialState,
                }))
            },
        }),
        {
            name: 'buyOffsetsState',
            version: 1,
            migrate: (state: unknown, version: number) => {
                if (version === 0) {
                    const qualifiedState = state as Omit<BuyOffsetsState, 'buyOffsetsState'> & {
                        buyOffsetsState: BuyOffsetProps | BuyOffsetPropsV0
                    }
                    return {
                        ...qualifiedState,
                        buyOffsetProps: migrateToV1(qualifiedState.buyOffsetProps),
                    } as BuyOffsetsState
                }

                return state as BuyOffsetsState
            },
        },
    ),
)

export default useBuyOffsetsState
