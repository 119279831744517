import { EmissionFactorLogicalUnit, EmissionFactorRegions } from '@lune-climate/lune'
import { toTitleCase } from '@lune-fe/lune-components-lib'
import { AutocompleteMultiSelect, Input, Select } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'
import { Dispatch, SetStateAction, useCallback } from 'react'
import { debounce } from 'throttle-debounce'

import MultiselectFilter from 'components/MultiselectFilter'
import { EmissionFactor } from 'models/openDapi'
import { GetEmissionFactorsFilters } from 'views/EmissionFactors/BrowseEmissionFactors'
import { categoryMetadata } from 'views/EmissionFactors/CategoryTile'

export const NAME_INPUT_ID = 'emission-factors-search-input'

export interface FilterProps {
    filterValues: GetEmissionFactorsFilters
    setFilterValues: Dispatch<SetStateAction<GetEmissionFactorsFilters>>
    availableRegions: EmissionFactorRegions
}

type EmissionFactorSource = EmissionFactor['source']
const availableSources: EmissionFactorSource[] = [
    'epa',
    'exiobase',
    'ecoinvent',
    'beis',
    'lune',
    'glec',
    'emsa',
    'cbam',
    'ademe',
    'idemat',
    'iata',
]
export const emissionFactorLabel: { [key in EmissionFactorSource[number]]: string } = {
    epa: 'EPA',
    exiobase: 'EXIOBASE',
    ecoinvent: 'ecoinvent',
    beis: 'BEIS',
    lune: 'Lune',
    glec: 'GLEC',
    emsa: 'EMSA',
    cbam: 'CBAM',
    ademe: 'ADEME',
    idemat: 'IDEMAT',
    iata: 'IATA',
}
const availableLogicalUnits = Object.values(EmissionFactorLogicalUnit)
// We currently don't have production data older than this.
const PUBLICATION_YEAR_START = 2016
const REGION_DEFAULT_LABEL = 'Region'

const EmissionFactorsFiltersBox = ({
    filterValues,
    setFilterValues,
    availableRegions,
}: FilterProps) => {
    const publicationYearOptions = Array.from(
        Array(new Date().getFullYear() - PUBLICATION_YEAR_START + 1).keys(),
    )
        .map((x) => x + PUBLICATION_YEAR_START)
        .reverse()
    const logicalUnitsOptions = [
        {
            label: 'Any unit',
            value: undefined as EmissionFactorLogicalUnit | undefined,
        },
    ].concat(
        availableLogicalUnits.concat().map((u) => {
            return {
                label: toTitleCase(u),
                value: u,
            }
        }),
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateNameFilter = useCallback(
        debounce(
            200,
            async (e) => {
                // Don't send empty string since we want to not filter in that case
                setFilterValues({ ...filterValues, name: e.target.value || null })
            },
            { atBegin: false },
        ),
        [filterValues],
    )

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'stretch',
                justifyContent: 'space-around',
                flexFlow: 'row wrap',
                gap: 1 / 2,
                mt: 4,
            }}
        >
            <Box
                sx={{
                    flexGrow: '10',
                }}
            >
                <Input
                    id={NAME_INPUT_ID}
                    sx={{ width: '100%' }}
                    placeholder={'Name'}
                    disabled={false}
                    onChange={updateNameFilter}
                    /* @ts-ignore: ... */
                    defaultValue={filterValues.name ?? ''}
                />
            </Box>
            <Box
                sx={{
                    flexGrow: '0',
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    gap: 1 / 2,
                    // 353px => 345 button group + 8px gap
                }}
            >
                <AutocompleteMultiSelect
                    sx={{ minWidth: '183px', width: undefined, maxWidth: '300px' }}
                    options={availableRegions.sort()}
                    getOptionLabel={(label) => toTitleCase(label)}
                    /* @ts-ignore: ... */
                    isOptionEqualToValue={(option, value) => option === value}
                    onChange={(selectedValues) => {
                        const selectedRegions = availableRegions.filter((r) =>
                            selectedValues.includes(r),
                        )
                        setFilterValues({
                            ...filterValues,
                            region: selectedRegions,
                        })
                    }}
                    value={filterValues.region ?? []}
                    label={
                        filterValues.region && filterValues.region.length !== 0
                            ? ''
                            : REGION_DEFAULT_LABEL
                    }
                />
                <MultiselectFilter<EmissionFactorSource>
                    preselected={filterValues.source ?? []}
                    setSelectedOptions={(e) =>
                        setFilterValues({
                            ...filterValues,
                            source: e as EmissionFactorSource[],
                        })
                    }
                    options={availableSources}
                    transformLabel={(label) => emissionFactorLabel[label]}
                    filterName={'Source'}
                />
                <AutocompleteMultiSelect
                    sx={{ minWidth: '120px', maxWidth: '240px' }}
                    options={Array.from(categoryMetadata.keys()).sort()}
                    getOptionLabel={(label) => toTitleCase(label)}
                    /* @ts-ignore: ... */
                    isOptionEqualToValue={(option, value) => option === value}
                    onChange={(selectedValues) => {
                        const selectedCategories = Array.from(categoryMetadata.keys()).filter((r) =>
                            selectedValues.includes(r),
                        )
                        setFilterValues({
                            ...filterValues,
                            category: selectedCategories,
                        })
                    }}
                    value={filterValues.category ?? []}
                    label={
                        filterValues.category && filterValues.category.length !== 0
                            ? ''
                            : 'Category'
                    }
                />
                <MultiselectFilter<number>
                    preselected={filterValues.publicationYear ?? []}
                    setSelectedOptions={(e) =>
                        setFilterValues({
                            ...filterValues,
                            publicationYear: e as number[],
                        })
                    }
                    options={publicationYearOptions}
                    filterName={'Year'}
                />
                <Select
                    sx={{ minWidth: '70px', width: undefined }}
                    key={`key-${filterValues.unit}`}
                    items={logicalUnitsOptions}
                    value={filterValues.unit}
                    onChange={(e) =>
                        setFilterValues({
                            ...filterValues,
                            unit: e,
                        })
                    }
                    label={'Unit'}
                />
            </Box>
        </Box>
    )
}

export default EmissionFactorsFiltersBox
