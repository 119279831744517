import { toTitleCase } from '@lune-fe/lune-components-lib'
import { Box, SxProps } from '@mui/material'
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import MultiselectFilter from 'components/MultiselectFilter'
import PriceRangeFilter from 'components/PriceRangeFilter'
import useAccounts from 'hooks/useAccounts'
import useUpdateEffect from 'hooks/useUpdateEffect'
import ListMapSwitcher from 'views/Projects/ProjectBundles/ListMapSwitcher'

export interface AllBundleFilters {
    offsetType?: OffsetType[]
    carbonPermanence?: CarbonPermanence[]
    approach?: Approach[]
    certification?: CertificationType[]
    priceRange?: [number, number]
}

export enum OffsetType {
    EMISSIONS_REDUCTION = 'emissions_reduction',
    CARBON_REMOVAL = 'carbon_removal',
}

export enum CarbonPermanence {
    LONG_TERM = 'long_term',
    SHORT_TERM = 'short_term',
    NO_STORAGE = 'no_storage',
}

export enum Approach {
    TRADITIONAL = 'traditional',
    INNOVATIVE = 'innovative',
}

export enum CertificationType {
    ACR = 'American Carbon Registry',
    VERRA = 'Verra',
    PURO_EARTH = 'Puro.earth',
    CLIMATE_ACTION_RESERVE = 'Climate Action Reserve',
    WOODLAND_CARBON_CODE = 'Woodland Carbon Code',
    ACCU = 'ACCU',
    CARBON_FUTURE = 'Carbon Future',
    ISOMETRIC = 'Isometric',
    TUV_NORD = 'Tuv Nord',
    NO_CERTIFICATION = 'Not certified',
}

export interface FilterBoxProps {
    view: string
    setView: (selected: string) => void
    sx?: SxProps
    sticky?: boolean
    minPrice: number
    maxPrice: number
    onLoad: (filters: AllBundleFilters) => void
    setQueryParams: (qp: string) => void
    reset: boolean
}

const BundleFiltersBox: FC<FilterBoxProps> = ({
    view,
    setView,
    sx,
    sticky,
    onLoad,
    minPrice,
    maxPrice,
    setQueryParams,
    reset,
}) => {
    const [, setSearchParams] = useSearchParams()
    const { activeAccount } = useAccounts()

    const [priceRange, setPriceRange] = useState<[number, number]>([minPrice, maxPrice])
    const [offsetType, setOffsetType] = useState<OffsetType[]>([])
    const [carbonPermanence, setCarbonPermanence] = useState<CarbonPermanence[]>([])
    const [approach, setApproach] = useState<Approach[]>([])
    const [certification, setCertification] = useState<CertificationType[]>([])

    useEffect(() => {
        setPriceRange([minPrice, maxPrice])
    }, [activeAccount, minPrice, maxPrice])

    const resetFilters = useCallback(() => {
        setPriceRange([minPrice, maxPrice])
        setOffsetType([])
        setCarbonPermanence([])
        setApproach([])
        setCertification([])
    }, [minPrice, maxPrice])

    useEffect(() => {
        if (reset) {
            resetFilters()
        }
    }, [reset, resetFilters])

    const isPriceRangeEmpty = useMemo(() => {
        return minPrice === priceRange[0] && maxPrice === priceRange[1]
    }, [minPrice, maxPrice, priceRange])

    const addFiltersToUrl = () => {
        const localOffsetType = offsetType.map((selection) => `&offset_type=${selection}`).join('&')
        const localCarbonPermanence = carbonPermanence
            .map((selection) => `&carbon_permanence=${selection}`)
            .join('&')
        const localApproach = approach.map((selection) => `&approach=${selection}`).join('&')
        const localCertification = certification
            .map((selection) => `&certification=${selection}`)
            .join('&')

        const minPrice = !isPriceRangeEmpty && priceRange[0] ? `&min_price=${priceRange[0]}` : ''
        const maxPrice = !isPriceRangeEmpty && priceRange[1] ? `&max_price=${priceRange[1]}` : ''

        const queryParams = `?${localOffsetType}${localCarbonPermanence}${localApproach}${localCertification}${
            minPrice && maxPrice ? `${minPrice}${maxPrice}` : ''
        }`
        setQueryParams(queryParams)
        setSearchParams(queryParams)
    }

    useUpdateEffect(() => {
        addFiltersToUrl()
        onLoad({
            priceRange,
            offsetType,
            carbonPermanence,
            approach,
            certification,
        })
    }, [priceRange, offsetType, carbonPermanence, approach, certification])

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        const localOffsetType = params.getAll('offset_type')
        const localCarbonPermanence = params.getAll('carbon_permanence')
        const localApproach = params.getAll('approach')
        const localCertification = params.getAll('certification')
        const [minPrice] = params.getAll('min_price')
        const [maxPrice] = params.getAll('max_price')

        setOffsetType(localOffsetType as OffsetType[])
        setCarbonPermanence(localCarbonPermanence as CarbonPermanence[])
        setApproach(localApproach as Approach[])
        setCertification(localCertification as CertificationType[])

        if (minPrice && maxPrice) {
            setPriceRange([Number(minPrice), Number(maxPrice)])
        }
    }, [])

    const transformLabel = (label: string) => {
        return toTitleCase(label.replace('_', ' '))
    }

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                mt: 4,
                ...sx,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    gap: 1 / 2,
                    // 296px => 240 button group + 8px gap + 48 horizontal spacing
                    width: 'calc(100% - 296px)',
                    ...(sticky && {
                        position: 'absolute',
                        top: '24px',
                        left: '24px',
                        zIndex: 9,
                    }),
                }}
            >
                <PriceRangeFilter
                    min={minPrice}
                    max={maxPrice}
                    value={priceRange}
                    setValue={setPriceRange}
                />
                <MultiselectFilter<OffsetType>
                    filterName={'Emissions'}
                    setSelectedOptions={(selected) => setOffsetType(selected)}
                    preselected={offsetType}
                    options={[OffsetType.EMISSIONS_REDUCTION, OffsetType.CARBON_REMOVAL]}
                    transformLabel={transformLabel}
                />
                <MultiselectFilter<CarbonPermanence>
                    filterName={'Storage'}
                    setSelectedOptions={(selected) => setCarbonPermanence(selected)}
                    preselected={carbonPermanence}
                    options={[
                        CarbonPermanence.SHORT_TERM,
                        CarbonPermanence.LONG_TERM,
                        CarbonPermanence.NO_STORAGE,
                    ]}
                    transformLabel={transformLabel}
                />
                <MultiselectFilter<Approach>
                    filterName={'Approach'}
                    setSelectedOptions={(selected) => setApproach(selected)}
                    preselected={approach}
                    options={[Approach.INNOVATIVE, Approach.TRADITIONAL]}
                    transformLabel={transformLabel}
                />
                <MultiselectFilter<CertificationType>
                    filterName={'Certification'}
                    setSelectedOptions={(selected) => setCertification(selected)}
                    preselected={certification}
                    options={[
                        CertificationType.ACR,
                        CertificationType.ACCU,
                        CertificationType.CLIMATE_ACTION_RESERVE,
                        CertificationType.PURO_EARTH,
                        CertificationType.VERRA,
                        CertificationType.WOODLAND_CARBON_CODE,
                        CertificationType.CARBON_FUTURE,
                        CertificationType.ISOMETRIC,
                        CertificationType.TUV_NORD,
                        CertificationType.NO_CERTIFICATION,
                    ]}
                    transformLabel={(option) => {
                        return transformLabel(option === 'Tuv Nord' ? 'Tüv Nord' : option)
                    }}
                />
            </Box>
            <Box
                sx={{
                    textAlign: 'right',
                    ...(sticky && {
                        position: 'absolute',
                        top: '24px',
                        right: '24px',
                        zIndex: 9,
                    }),
                }}
            >
                <ListMapSwitcher view={view} setView={setView} />
            </Box>
        </Box>
    )
}

export default memo(BundleFiltersBox)
