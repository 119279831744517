import type { SxProps } from '@mui/material'
import Box from '@mui/material/Box'
import { FC, ReactNode } from 'react'

const CodeBox: FC<{ children: ReactNode; sx?: SxProps }> = ({ children, sx, ...rest }) => {
    return (
        <Box
            sx={{
                p: 2,
                color: `White`,
                backgroundColor: `Grey900`,
                borderRadius: `8px`,
                fontSize: `1rem`,
                fontFamily: `monospace`,
                overflowWrap: `anywhere`,
                ...(sx || ''),
            }}
            {...rest}
        >
            {children}
        </Box>
    )
}

export default CodeBox
