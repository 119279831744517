import { Pin } from '@lune-fe/lune-ui-lib'
import React, { FC } from 'react'
import { Marker } from 'react-map-gl'

interface MapMarkerProps {
    latitude: number
    longitude: number
    src?: string
    children?: React.ReactNode
    title?: string
    subTitle?: string
    onClick?: () => void
    active?: boolean
}

const MapMarker: FC<MapMarkerProps> = ({
    src,
    longitude,
    latitude,
    children,
    title,
    subTitle,
    onClick,
    active,
}) => {
    return (
        <Marker longitude={longitude} latitude={latitude} anchor="bottom-left">
            <Pin active={active} src={src} title={title} subTitle={subTitle} onClick={onClick}>
                {children}
            </Pin>
        </Marker>
    )
}

export default MapMarker
