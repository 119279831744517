import type { BundleSelectionRequest } from '@lune-climate/lune'
import { MassUnit } from '@lune-climate/lune'
// We're importing from a private path here. This may stop working
// in the future, we should migrate away from it eventually.
// There's no other way to import ApiError at the moment.
import { ApiError } from '@lune-climate/lune/esm/core/ApiError'
import { Button, Loading } from '@lune-fe/lune-ui-lib'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import { Big } from 'big.js'
import { ConversionIds } from 'Linkedin'
import { useSnackbar } from 'notistack'
import { useCallback, useMemo, useState } from 'react'
import LinkedInTag from 'react-linkedin-insight'
import { useNavigate } from 'react-router-dom'

import useBuyOffsetsState from 'hooks/useBuyOffsetsState'
import useHasUnsavedChanges from 'hooks/useHasUnsavedChanges'
import useIsBillingReady from 'hooks/useIsBillingReady'
import useIsTestMode from 'hooks/useIsTestMode'
import { useLuneClient } from 'hooks/useLuneClient'
import useMixpanel from 'hooks/useMixpanel'
import { OrderType } from 'models/order'
import { mapErrorsToMessage } from 'SnackbarMessages'

const PlaceOrder = () => {
    const { buyOffsetProps } = useBuyOffsetsState()
    const { enqueueSnackbar: snackbar } = useSnackbar()
    const navigate = useNavigate()
    const mixpanel = useMixpanel()
    const isTestMode = useIsTestMode()
    const { isBillingReady } = useIsBillingReady()
    const luneClient = useLuneClient()

    const { setHasUnsavedChangesState } = useHasUnsavedChanges()

    const [placingOrder, setPlacingOrder] = useState<boolean>(false)

    const handleError = useCallback(
        (r: ApiError) => {
            const errors = 'errors' in r ? r.errors : undefined
            snackbar(mapErrorsToMessage(errors).message, {
                variant: 'error',
            })
            setPlacingOrder(false)
        },
        [snackbar],
    )

    const payloadByMass = useMemo(
        () => ({
            createOrderByQuantityRequest: {
                bundleMasses: buyOffsetProps.allocation.map((a) => ({
                    bundleId: a.bundle.id,
                    mass: {
                        amount: (parseFloat(Big(a.volume).toString()) * 1000).toString(),
                        unit: MassUnit.KG,
                    },
                })),
                ...(buyOffsetProps.truncateToTonnes && { quantity_trunc: 't' }),
            },
        }),
        [buyOffsetProps],
    )

    const payloadByValue: {
        value: string
        bundleSelection?: BundleSelectionRequest
    } = useMemo(
        () => ({
            value: buyOffsetProps.amount,
            bundleSelection: buyOffsetProps.allocation.map((a) => ({
                bundleId: a.bundle.id,
                percentage: a.percentage,
            })),
        }),
        [buyOffsetProps],
    )

    const placeOrderAndHandleResponse = async () => {
        setHasUnsavedChangesState(false)

        if (placingOrder) {
            return
        }
        setPlacingOrder(true)

        const result = await (buyOffsetProps.orderType === OrderType.VALUE
            ? luneClient.createOrderByValue(payloadByValue)
            : luneClient.createOrderByMass(payloadByMass))

        if (result.isOk()) {
            setHasUnsavedChangesState(false)
            const order = result.value
            const placedOrderId = `/orders/${order.id}`
            navigate(placedOrderId, {
                state: {
                    success: true,
                },
            })
            LinkedInTag.track(ConversionIds.ORDER)
            mixpanel.track('order_placed_dashboard')
            return
        }
        setHasUnsavedChangesState(true)
        handleError(result.error)
    }

    return (
        <Button
            data-testid={`place-order-btn`}
            onClick={placeOrderAndHandleResponse}
            leftIcon={
                placingOrder ? (
                    <Loading
                        sx={{
                            filter: 'invert(1)',
                        }}
                    />
                ) : (
                    <ArrowCircleRightIcon
                        sx={{
                            width: `20px`,
                            height: `20px`,
                            borderRadius: `40px`,
                        }}
                    />
                )
            }
            disabled={!isTestMode && !isBillingReady}
        >
            {placingOrder ? 'Placing order...' : 'Place order'}
        </Button>
    )
}

export default PlaceOrder
