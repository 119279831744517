import { AutocompleteMultiSelect, Select } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'
import { memo, useEffect, useState } from 'react'

interface ListItemValue {
    label: string
    value: string
}

interface ListItem {
    value: ListItemValue
    selected: boolean
}

interface MainValue {
    items: string[]
    mustHave: boolean
}

export const SelectWithMustHaveOption = ({
    onChange,
    value,
    error,
    placeholder,
    items,
    dataTestId,
}: {
    onChange: (val: MainValue) => void
    value: MainValue
    items: ListItemValue[]
    error?: string
    placeholder: string
    dataTestId?: string
}) => {
    const [selectedValue, setSelectedValue] = useState<readonly ListItem[]>(
        value.items.map((item) => ({
            value: items.find((i) => i.value === item)!,
            selected: true,
        })),
    )
    const list: ListItem[] = items.map((i) => ({
        value: i,
        selected: false,
    }))
    const [label, setLabel] = useState<string>('')

    useEffect(() => {
        setLabel(value.items.length ? '' : placeholder)
    }, [value, placeholder])

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                gap: 1 / 2,
                width: '100%',
            }}
        >
            <Box sx={{ width: '100%' }}>
                <AutocompleteMultiSelect
                    data-testid={dataTestId}
                    sx={{
                        width: '100%',
                        'textarea:first-child': {
                            maxWidth: '100% !important',
                            height: '23px !important',
                        },
                    }}
                    options={list}
                    getOptionLabel={(option) => option.value.label}
                    /* @ts-ignore: ... */
                    isOptionEqualToValue={(option, value) =>
                        option.value.value === value.value.value
                    }
                    onChange={(selectedItems) => {
                        setSelectedValue(selectedItems)
                        onChange({
                            ...value,
                            items: selectedItems.map((i) => i.value.value),
                        })
                    }}
                    value={selectedValue as ListItem[]}
                    label={label}
                    error={error}
                />
            </Box>
            <Select
                sx={{ width: '155px', flex: '1 0 auto' }}
                items={[
                    {
                        label: 'Nice to have',
                        value: 'nice-to-have',
                    },
                    {
                        label: 'Must have',
                        value: 'must-have',
                    },
                ]}
                onChange={(val) =>
                    onChange({
                        ...value,
                        mustHave: val === 'must-have',
                    })
                }
                value={value.mustHave ? 'must-have' : 'nice-to-have'}
            />
        </Box>
    )
}

export default memo(SelectWithMustHaveOption)
