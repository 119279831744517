import { BannerMessage } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'

import useIsBillingReady from 'hooks/useIsBillingReady'

const PaymentInfoWarnings = () => {
    const { isMissingBillingInfo, isMissingPaymentInfo } = useIsBillingReady()

    return (
        <Box
            sx={{
                mb: isMissingBillingInfo || isMissingPaymentInfo ? 4 : 0,
            }}
        >
            {isMissingBillingInfo && (
                <BannerMessage sx={{ mb: 1 }} message={`Please set up your billing info`} />
            )}
            {isMissingPaymentInfo && (
                <BannerMessage
                    sx={{ mb: 1 }}
                    message={`Please set up your organisation payment method`}
                />
            )}
        </Box>
    )
}

export default PaymentInfoWarnings
