import { TransactionEmissionEstimate } from '@lune-climate/lune'
import { Stack } from '@mui/material'
import { FC } from 'react'

import { Divider, RequestAndResponse } from '../components'
import {
    CalculationResults,
    TransactionEmissionExplanation,
    TransactionExplanationInputSection,
} from '../components/emissionsCalculationExplanation'

interface TransactionEstimateResultProps {
    estimate: TransactionEmissionEstimate & { _meta: { response: unknown } }
    enableEmissionFactorLink: boolean
    requestAndResponseSnippets?: {
        isTestMode?: boolean
        testApiKey?: string
    }
}

export const TransactionEstimateResult: FC<TransactionEstimateResultProps> = ({
    estimate,
    enableEmissionFactorLink,
    requestAndResponseSnippets,
}) => {
    if (
        typeof estimate._meta.response !== 'object' ||
        estimate._meta.response === null ||
        !('request' in estimate._meta.response)
    ) {
        throw new Error('Unexpected _meta object')
    }
    return (
        <>
            <CalculationResults
                title={
                    estimate.request.name ||
                    ('categoryCode' in estimate.request.merchant
                        ? `MCC ${estimate.request.merchant.categoryCode}`
                        : `${estimate.request.merchant.searchTerm}`)
                }
                amount={estimate.mass.amount}
            />
            <Stack direction={'column'} spacing={9} mt={9}>
                <Divider />
                <TransactionEmissionExplanation
                    estimate={estimate}
                    enableEmissionFactorLink={enableEmissionFactorLink}
                />
                <Divider />
                <TransactionExplanationInputSection estimate={estimate} />
                {requestAndResponseSnippets && (
                    <>
                        <Divider />
                        <RequestAndResponse
                            path={`/v1/estimates/transactions`}
                            request={(estimate._meta.response as { request: unknown }).request}
                            response={estimate._meta.response}
                            idempotencyKey={
                                (estimate._meta.response as { idempotency_key?: string })
                                    .idempotency_key
                            }
                            metadata={
                                (
                                    estimate._meta.response as {
                                        metadata?: Record<string, string>
                                    }
                                ).metadata
                            }
                            isTestMode={requestAndResponseSnippets.isTestMode}
                            testApiKey={requestAndResponseSnippets.testApiKey}
                        />
                    </>
                )}
            </Stack>
        </>
    )
}

export default TransactionEstimateResult
