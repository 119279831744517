import { Button, Text } from '@lune-fe/lune-ui-lib'
import { ConversionIds } from 'Linkedin'
import { useEffect } from 'react'
import LinkedInTag from 'react-linkedin-insight'
import { Link } from 'react-router-dom'

import FormBox from 'views/Account/Shared/FormBox'
import Layout from 'views/Account/Shared/Layout'

const RequestConfirmation = () => {
    useEffect(() => {
        const partnerId = process.env.REACT_APP_LINKEDIN_PARTNER_ID
        if (partnerId) {
            LinkedInTag.init(partnerId)
            LinkedInTag.track(ConversionIds.SIGNUP)
        }
    }, [])

    return (
        <Layout>
            <FormBox title={'Thanks for signing up'}>
                <Text variant={'body1'}>
                    We continuously approve sign up requests, so watch out for our email in the next
                    few days.
                </Text>
                <Text variant={'body1'} sx={{ mt: 2 }}>
                    Any questions?
                </Text>
                <Button
                    sx={{ width: '100%', mb: 1, mt: 4 }}
                    onClick={() => window.open('mailto:support@lune.co', '_blank')}
                >
                    Contact us
                </Button>
                <Link to="login">
                    <Button variant={'text'} sx={{ width: '100%', mb: 1 }}>
                        Login
                    </Button>
                </Link>
            </FormBox>
        </Layout>
    )
}

export default RequestConfirmation
