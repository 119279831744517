import { Bundle } from '@lune-climate/lune'
import { Big } from 'big.js'
import { sortBy } from 'lodash'
import { useEffect, useState } from 'react'

import useBuyOffsetsState from 'hooks/useBuyOffsetsState'
import useHasUnsavedChanges from 'hooks/useHasUnsavedChanges'
import { OrderType } from 'models/order'
import { Allocation, BundleWithVolume } from 'views/BuyOffsets/BuyOffsetsTypes'
import CartModal from 'views/BuyOffsets/CartModal/CartModal'

/*
 * Component to manage the Cart Modal for the Custom Portfolio view (as opposed to Pre-built Portfolio view).
 * It's a wrapper around CartModal component (CartModal is used for both Pre-built and Custom Portfolio views - check
 * the diagram in BuyOffsets/Docs/). It's responsible for managing the calculations when the user
 * provides custom volumes or percentages for the bundles.
 */
const CustomPortfolioCartModalController = ({
    onCloseModal,
    onRemoveBundle,
    modalTitle,
}: {
    onCloseModal: () => void
    onRemoveBundle: (bundle: Bundle) => void
    modalTitle?: string
}) => {
    const { setHasUnsavedChangesState } = useHasUnsavedChanges()

    const { buyOffsetProps, setBuyOffsetProps, resetBuyOffsetProps } = useBuyOffsetsState()
    const {
        quote,
        amount,
        customVolumes,
        isCustomBundleVolumeSet,
        customPercentages,
        isCustomBundlePercentageSet,
        selectedBundles,
        orderType,
        allocation,
        truncateToTonnes,
        error,
    } = buyOffsetProps

    const [localAllocation, setLocalAllocation] = useState<Allocation[]>([])
    const [allocationPercentages, setAllocationPercentages] = useState<Record<string, string>>()
    // used when navigating from within a project

    const calculatedCustomPercentage = (bundle: Bundle, acc: Record<string, string>) => {
        if (isCustomBundleVolumeSet) {
            // recalculate percentage based on changed volume
            const bundleVolume = customVolumes.find((v) => v.bundleId === bundle.id)?.volume
            const total = quote
                ? orderType === OrderType.VALUE
                    ? quote.estimatedQuantity
                    : amount
                : amount

            if (!total || total === '0') {
                return Big(100).div(customVolumes.length).toString()
            }

            // Due to the rounding to 2 decimal cases, the sum of all percentages not be 100%.
            // To avoid this, modify the last bundle percentage to be the remaining percentage.
            // This means the percentages might not be exactly in sync with the inputted volumes,
            // but considering we round to 2 decimal cases this is already the case.
            const selectionCount = selectedBundles.length
            if (selectedBundles.indexOf(bundle) === selectionCount - 1) {
                const total = Object.values(acc).reduce((a, v) => a.add(Big(v)), Big(0))
                return Big(100).minus(total).toString()
            } else {
                return (
                    Big(bundleVolume || 0)
                        .mul(100)
                        .div(total)
                        .toFixed(2)
                        .toString() || '0'
                )
            }
        } else {
            // use set percentages
            return allocation.find((a: Allocation) => a.bundle.id === bundle.id)?.percentage || '0'
        }
    }

    const calculatedInitialPercentage = (bundle: Bundle): string => {
        // diving percentages to fixed numbers
        // 2 selected bundles = [50%, 50%], 3 selected bundles = [33%, 33%, 34%]
        const selectionCount = selectedBundles.length
        const diff = 100 % selectionCount
        const total = 100 - diff
        let percentage: string = '0'
        if (selectedBundles.indexOf(bundle) === selectionCount - 1) {
            percentage = Big(total).div(selectionCount).add(diff).toString()
        } else {
            percentage = Big(total).div(selectionCount).toString()
        }
        return percentage
    }

    const calculateVolume = (bundle: Bundle, onOrderTypeAndRoundDownChange?: boolean) => {
        const percentage = allocationPercentages?.[bundle.id] || 0
        if (!amount || amount === '' || amount === '0') {
            return 0
        }
        const byValueVolume = percentage
            ? Big(percentage)
                  .mul(amount || 0)
                  .div(100)
                  .toNumber()
            : 0
        if (orderType === OrderType.QUANTITY) {
            return byValueVolume
        } else {
            if (quote) {
                return Big(
                    quote.bundles.find((b) => b.bundleId === bundle.id)?.quantity || 0,
                ).toNumber()
            } else {
                // workaround to avoid flickering when switching the order type or round down to tonne toggle
                return onOrderTypeAndRoundDownChange && !error ? byValueVolume : 0
            }
        }
    }

    /**
     * Loop through the selected bundles in the local state
     * For each bundle determine if we need to update any numbers for volume or percentage
     * (e.g. when a quote was returned or user manually provided a volume or percentage)
     * Map to a new array of sorted allocations
     * Finally set the new allocations back to the local state and the "global" state
     * @param onOrderTypeAndRoundDownChange - used to avoid flickering when switching the order type or round down to tonne toggle
     */
    const updateAllocations = (onOrderTypeAndRoundDownChange?: boolean) => {
        setHasUnsavedChangesState(selectedBundles.length > 0, resetBuyOffsetProps)

        const hasMultipleBundles = selectedBundles.length > 1
        const selection: Allocation[] = selectedBundles.map((bundle) => {
            // Determine the percentage of this allocation
            // If Custom Percentages are set in the BuyOffset global state, use those
            // If there's only a single bundle or no Custom Percentages:
            // Use the locally calculated percentages based on the number of selected bundles
            const newPercentage =
                customPercentages.length && isCustomBundlePercentageSet && hasMultipleBundles
                    ? customPercentages.find((b) => b.bundleId === bundle.id)?.percentage
                    : allocationPercentages?.[bundle.id]

            const quoteVolume =
                quote?.bundles.find((b) => b.bundleId === bundle.id)?.quantity || '0'

            // Determine the volume of this allocation
            // If Custom Volumes are set in the BuyOffset global state, use those
            // If Custom Percentages are set -> use the volume from the quote (i.e. let BE calculate the volume)
            const volume = error
                ? 0
                : customVolumes.length && isCustomBundleVolumeSet
                  ? Number(customVolumes.find((a) => a.bundleId === bundle.id)?.volume || 0)
                  : customPercentages.length && isCustomBundlePercentageSet
                    ? parseFloat(quoteVolume)
                    : calculateVolume(bundle, onOrderTypeAndRoundDownChange)

            const formattedVolume =
                volume < 1
                    ? Big(Big(volume).toFixed(6)).toNumber()
                    : Big(Big(volume).toFixed(2)).toNumber()
            return {
                bundle,
                percentage: newPercentage || '0',
                volume: truncateToTonnes ? Math.floor(formattedVolume) : formattedVolume,
            }
        })
        const sortedSelection: Allocation[] = sortBy(selection, (s: Allocation) => s.bundle.name)
        setLocalAllocation(sortedSelection)
        setBuyOffsetProps({
            allocation: sortedSelection,
        })
        setTimeout(() => {
            setBuyOffsetProps({
                orderTotalAmount: sortedSelection.reduce(
                    (a, b) => Number(a || 0) + Number(b.volume || 0),
                    0,
                ),
                orderTotalPercentage: sortedSelection.reduce(
                    (a, b) => Number(a || 0) + Number(b.percentage || 0),
                    0,
                ),
            })
        }, 0)
    }

    useEffect(() => {
        updateAllocations()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        amount,
        quote,
        isCustomBundleVolumeSet,
        isCustomBundlePercentageSet,
        customVolumes,
        customPercentages,
        allocationPercentages,
        selectedBundles,
        error,
    ])

    useEffect(() => {
        updateAllocations(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderType, truncateToTonnes])

    /**
     * Calculate the percentages locally and set them to the local state
     * @param initial - is this the initial calculation (i.e. when the user first selects bundles no inputs are set)
     */
    const setPercentages = (initial?: boolean) => {
        const percentages: Record<string, string> = selectedBundles.reduce(
            (acc: Record<string, any>, curr: Bundle) => {
                acc[curr.id] = initial
                    ? calculatedInitialPercentage(curr)
                    : calculatedCustomPercentage(curr, acc)
                return acc
            },
            {} as Record<string, string>,
        )
        setAllocationPercentages(percentages)
    }

    useEffect(() => {
        // If the user has set a custom volume, but not a custom percentage, calculate the percentages locally
        if (isCustomBundleVolumeSet && !isCustomBundlePercentageSet && amount !== '0') {
            setPercentages()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCustomBundleVolumeSet, isCustomBundlePercentageSet, amount])

    useEffect(() => {
        if (!selectedBundles.length) {
            setBuyOffsetProps({ quote: undefined })
        }

        const isSingleItem = selectedBundles.length === 1
        if (!isCustomBundlePercentageSet || isSingleItem) {
            setPercentages(customVolumes.length === 0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBundles, isCustomBundlePercentageSet, isCustomBundleVolumeSet])

    const updateAmount = (bundle: Bundle) => {
        if (customVolumes.length && orderType === OrderType.QUANTITY) {
            const updatedCustomVolumes: BundleWithVolume[] = customVolumes.filter(
                (volume: BundleWithVolume) => volume.bundleId !== bundle.id,
            )
            const total = updatedCustomVolumes.reduce(
                (accumulator, current) => Number(accumulator || 0) + Number(current.volume || 0),
                0,
            )
            setBuyOffsetProps({
                amount: total.toString(),
                customVolumes: updatedCustomVolumes,
                isCustomBundleVolumeSet: true,
            })
        }
    }

    return (
        <CartModal
            modalTitle={modalTitle}
            allocation={localAllocation}
            onRemoveBundle={(bundle) => {
                updateAmount(bundle)
                onRemoveBundle(bundle)
            }}
            onClose={onCloseModal}
            customizedPortfolio
        />
    )
}

export default CustomPortfolioCartModalController
