import { Button, LuneTheme } from '@lune-fe/lune-ui-lib'
import { SxProps } from '@mui/material'
import { useSnackbar } from 'notistack'
import { FC, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import {
    googleAuthorisation,
    GoogleSSOUserProfile,
    googleVerifyAuthorisationCode,
} from 'endpoints/dapi'
import GoogleSSOLogo from 'images/googleSSOLogo.svg'
import GenericErrorMessage from 'views/Account/Shared/GenericErrorMessage'

const GoogleSSOButton: FC<{
    buttonLabel: string
    onSuccess: (response: GoogleSSOUserProfile) => void
    onFailure?: () => void
    sx?: SxProps
    redirectUri: string
}> = ({ onSuccess, buttonLabel, sx: customSX, redirectUri }) => {
    const { palette } = LuneTheme
    const { enqueueSnackbar: snackbar } = useSnackbar()

    const search = useLocation().search

    useEffect(() => {
        const code = new URLSearchParams(search).get('code')
        if (code) {
            googleVerifyAuthorisationCode({
                code,
                redirectUrl: redirectUri,
            })
                .then((res) => {
                    onSuccess(res)
                })
                .catch((err) => {
                    const errorMessage = err.response?.data?.errors?.[0].message
                    snackbar(errorMessage || <GenericErrorMessage />, {
                        persist: true,
                        variant: 'error',
                    })
                })
        }
        // The exhaustivce-deps linting rule would make us add onSuccess and snackbar
        // to the list of the dependencies.
        //
        // The thing is having these dependencies present causes the effect callback to
        // be called multiple times instead of just once when we actually log in using
        // Google SSO.
        //
        // With the depdencies removed the callback is only executed once per the search
        // string/redirectUri, which is what we want.
        //
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [redirectUri, search])

    const localSx = useMemo(
        () => ({
            width: '100%',
            backgroundColor: `${palette.Grey100} !important`,
            border: 'none !important',
            '&:hover': {
                backgroundColor: `${palette.Grey300} !important`,
                boxShadow: `4 !important`,
            },
            '&:active': {
                backgroundColor: `${palette.Grey300} !important`,
                boxShadow: `6 !important`,
            },
            '&.disabled': {
                backgroundColor: `${palette.Grey200} !important`,
                color: `${palette.Grey500}`,
            },
            'span:first-child': {
                position: 'absolute',
                left: '14px',
            },
            ...(customSX || {}),
        }),
        [customSX, palette],
    )

    const getGoogleAuthorisationUrl = () => {
        googleAuthorisation({
            redirectUrl: redirectUri,
        }).then((res) => {
            window.open(res.authorisationUrl, '_self')
        })
    }

    return (
        <Button
            sx={localSx}
            wide
            variant={'outlined'}
            leftIcon={<img src={GoogleSSOLogo} alt={'Google SSO'} />}
            onClick={getGoogleAuthorisationUrl}
        >
            {buttonLabel}
        </Button>
    )
}
export default GoogleSSOButton
