import { Bundle } from '@lune-climate/lune'
import { Input } from '@lune-fe/lune-ui-lib'
import { Big } from 'big.js'
import { FC } from 'react'
import { debounce } from 'throttle-debounce'

import { Allocation, BundleWithPercentage } from 'views/BuyOffsets/BuyOffsetsTypes'

const BundlePercentageInput: FC<{
    bundle: Bundle
    percentage: string
    allocation: Allocation[]
    onBundlesPercentageChange: (items: BundleWithPercentage[]) => void
}> = ({ bundle, percentage, allocation, onBundlesPercentageChange }) => {
    const updateBuyOffsetPropsDebounced = debounce(
        250,
        (val) => {
            const updatedBundles: BundleWithPercentage[] = allocation.map((a) => {
                if (a.bundle.id === bundle.id) {
                    return {
                        bundleId: a.bundle.id,
                        percentage: val,
                    }
                } else {
                    return {
                        bundleId: a.bundle.id,
                        percentage: a.percentage,
                    }
                }
            })
            onBundlesPercentageChange(updatedBundles)
        },
        { atBegin: true },
    )

    return (
        <Input
            value={percentage}
            highlightOnFocus
            endAdornment={'%'}
            onChange={(e) => {
                // Remove leading zeros if second character is not a decimal point
                const percentageAsString =
                    e.target.value[1] !== '.' ? e.target.value.replace(/^0+/, '') : e.target.value

                let percentageAsBig
                try {
                    percentageAsBig = Big(percentageAsString)
                } catch {
                    // If the percentage is not a valid number, set it to 0
                    updateBuyOffsetPropsDebounced(0)
                    return
                }

                // Ignore if the percentage is greater than 100, ignore
                if (percentageAsBig.gt(100)) {
                    return
                }

                const numberOfDecimalPlaces = percentageAsBig.c.length - percentageAsBig.e - 1

                // Ignore if the percentage has more than 2 decimal places (e.g. 100.001)
                if (numberOfDecimalPlaces > 2) {
                    return
                }

                // Remove any decimal places beyond 2 (there might be trailing zeros e.g. 100.00000)
                const finalNumber = percentageAsString.replace(/(\d+\.\d{2})\d+/g, '$1')
                updateBuyOffsetPropsDebounced(finalNumber)
            }}
            sx={{
                input: {
                    textAlign: 'right !important',
                },
            }}
            data-testid={`bundle-volume-input-${bundle.id}`}
        />
    )
}

export default BundlePercentageInput
