import { NoResultsPlaceholder, Text } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'
import { FC } from 'react'
import { Range } from 'react-date-range'

import Divider from 'components/Divider'
import useBundles from 'hooks/useBundles'
import { OrderType } from 'models/order'
import OrdersChart from 'views/Dashboard/Charts/OrdersChart'
import { BundleData } from 'views/Dashboard/Charts/OrdersChartConfig'
import Totals from 'views/Dashboard/Totals'

const OrdersAnalytics: FC<{
    dateRange: Range
    analyticsType: OrderType
    setAnalyticsType: (type: OrderType) => void
    orderChartData: BundleData[] | undefined
}> = ({ dateRange, analyticsType, setAnalyticsType, orderChartData }) => {
    const { bundles } = useBundles()

    return (
        <>
            {orderChartData && orderChartData.length ? (
                <>
                    <OrdersChart
                        analyticsType={analyticsType}
                        setAnalyticsType={setAnalyticsType}
                        data={orderChartData}
                        allBundles={bundles}
                        dateRange={dateRange}
                    />
                    <Divider />
                </>
            ) : (
                <>
                    <Totals data={orderChartData || []} analyticsType={analyticsType} />
                    <Box sx={{ height: `450px` }}>
                        <NoResultsPlaceholder
                            message={
                                <Text
                                    variant={`h6`}
                                    sx={{
                                        textAlign: 'center',
                                        maxWidth: '250px',
                                    }}
                                >
                                    You don&apos;t have any orders for the selected time period
                                </Text>
                            }
                        />
                    </Box>
                </>
            )}
        </>
    )
}

export default OrdersAnalytics
