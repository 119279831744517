import { TransactionEmissionEstimate } from '@lune-climate/lune'
import { SuccessResponse } from '@lune-climate/lune/esm/core/SuccessResponse'
import { TransactionEstimateResult } from '@lune-fe/lune-components-lib'
import { useSnackbar } from 'notistack'
import { FC, RefObject, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useLuneClient } from 'hooks/useLuneClient'
import { SnackbarMessages } from 'SnackbarMessages'
import { ResultsWrapper } from 'views/CalculateEmissions/index'

const EverydayPurchasesResults: FC<{ scrollRef: RefObject<HTMLDivElement> }> = ({ scrollRef }) => {
    const { enqueueSnackbar: snackbar } = useSnackbar()
    const { id } = useParams<{ id: string }>()
    const [estimate, setEstimate] = useState<SuccessResponse<TransactionEmissionEstimate>>()
    const luneClient = useLuneClient()

    useEffect(() => {
        if (id) {
            luneClient
                .getTransactionEstimate(id)
                .then((res) => {
                    if (res.isOk()) {
                        const e = res.value
                        setEstimate(e)
                    }
                })
                .catch(() => snackbar(SnackbarMessages.GENERIC_ERROR))
        }
    }, [id, snackbar, luneClient])

    return (
        <ResultsWrapper scrollRef={scrollRef} calculatedAmount={estimate?.mass.amount || ''}>
            {estimate && (
                <TransactionEstimateResult estimate={estimate} enableEmissionFactorLink={true} />
            )}
        </ResultsWrapper>
    )
}

export default EverydayPurchasesResults
