import { LuneTheme } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'
import { Dispatch, SetStateAction } from 'react'
import { Range } from 'react-date-range'

import AccountFilter from 'components/AccountFilter'
import DateRangeFilter, { PredefinedRange } from 'components/DateRangeFilter'
import MultiselectFilter from 'components/MultiselectFilter'

export interface FilterProps<T> {
    preselectedAccountIds: string[]
    preselectedDateRange: Range
    preselectedTypes: T[]
    types?: T[]
    setSelectedAccountIds: Dispatch<SetStateAction<string[]>>
    setDateRange: Dispatch<SetStateAction<Range>>
    setSelectedTypes?: Dispatch<SetStateAction<T[]>>
    dropDownFilterName: string
    transformDropDownLabel?: (label: string) => string
}

const FiltersBox = <T,>({
    preselectedAccountIds,
    preselectedDateRange,
    preselectedTypes,
    types,
    setSelectedAccountIds,
    setDateRange,
    setSelectedTypes,
    dropDownFilterName,
    transformDropDownLabel,
}: FilterProps<T>) => {
    const { palette, spacing } = LuneTheme
    return (
        <Box sx={{ display: 'flex', gap: spacing(1), flexWrap: 'wrap' }}>
            <AccountFilter
                sx={{ flex: 2 }}
                preselectedIds={preselectedAccountIds}
                setSelectedIds={setSelectedAccountIds}
            />
            <DateRangeFilter
                sx={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
                labelSx={{ color: palette.Grey900 }}
                dateDisplayFormat="D MMM YY"
                defaultRange={PredefinedRange.ALL_TIME}
                setDateRange={setDateRange}
                predefinedDateRange={preselectedDateRange}
            />
            {types && setSelectedTypes && (
                <MultiselectFilter<T>
                    sx={{ flex: 1 }}
                    preselected={preselectedTypes}
                    setSelectedOptions={setSelectedTypes}
                    options={types}
                    transformLabel={transformDropDownLabel}
                    filterName={dropDownFilterName}
                />
            )}
        </Box>
    )
}

export default FiltersBox
