import { Button, Text } from '@lune-fe/lune-ui-lib'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import BackgroundImage from 'images/protfolio-curator-banner.png'

const CreateProposalRequest = () => {
    const navigate = useNavigate()

    return (
        <Box
            sx={{
                mt: 9,
                height: '400px',
                width: '100%',
                borderRadius: 4,
                boxSizing: 'border-box',
                cursor: 'pointer',
                backgroundImage: `url(${BackgroundImage})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                p: 6,
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                flexDirection: 'column',
            }}
            tabIndex={1}
            onClick={() => navigate('/proposal-request')}
        >
            <Box>
                <Text variant={'h5'} sx={{ mb: 3, color: 'white' }}>
                    Get bespoke portfolios <br /> based on your requirements
                </Text>
                <Text variant={'body1'} sx={{ color: 'white' }}>
                    Answer a few questions to get a breadth of
                    <br /> proposals leveraging Lune’s expert guidance
                    <br />
                    in the Carbon Market.
                </Text>
            </Box>
            <Button
                data-testid={`create-proposal-request-btn`}
                light
                onClick={(e) => {
                    e.stopPropagation()
                    navigate('/proposal-request')
                }}
                leftIcon={<ArrowCircleRightIcon />}
            >
                Request proposal
            </Button>
        </Box>
    )
}

export default CreateProposalRequest
