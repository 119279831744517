import { Text } from '@lune-fe/lune-ui-lib'
import { FC } from 'react'

const GenericErrorMessage: FC = () => {
    return (
        <Text variant={'body3'}>
            An error has occurred, please email us at{' '}
            <a href="mailto:support@lune.co" target="_black">
                support@lune.co
            </a>
        </Text>
    )
}

export default GenericErrorMessage
