import { Checkbox, Input } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'
import { memo, useState } from 'react'

export const InputWithIDontKnowOption = ({
    onChange,
    value,
    error,
    placeholder,
    endAdornment,
    dataTestId,
}: {
    onChange: (val: string) => void
    value: string
    error?: string
    placeholder?: string
    endAdornment?: string
    dataTestId?: string
}) => {
    const [inputValue, setInputValue] = useState<string>(value)
    const [inputValueTemp, setInputValueTemp] = useState<string>(value)
    const [checkboxValue, setCheckboxValue] = useState<boolean>(false)

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1 / 2,
                width: '100%',
            }}
        >
            <Input
                endAdornment={endAdornment}
                placeholder={placeholder}
                error={error}
                sx={{ width: '100%' }}
                data-testid={dataTestId ?? 'input-with-i-dont-know-option-input'}
                value={inputValue}
                highlightOnFocus
                onChange={(e) => {
                    setCheckboxValue(false)
                    if (e.target.value === '') {
                        setInputValue('')
                        setInputValueTemp('')
                        return
                    }
                    const valueAsNumber = Number(e.target.value)
                    if (Number.isNaN(valueAsNumber)) {
                        return
                    }
                    setInputValue(valueAsNumber.toString())
                    setInputValueTemp(valueAsNumber.toString())
                }}
                onBlur={() => {
                    onChange(inputValue)
                }}
            />
            <Checkbox
                data-testid={'input-with-i-dont-know-option-checkbox'}
                sx={{ p: 1 }}
                label={"I don't know yet"}
                checked={checkboxValue}
                onChange={(e, checked) => {
                    setCheckboxValue(checked)
                    setInputValue(checked ? '' : inputValueTemp)
                    onChange(checked ? '' : inputValueTemp)
                }}
            />
        </Box>
    )
}

export default memo(InputWithIDontKnowOption)
