import { CountryPicker } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { memo } from 'react'
import { Field } from 'react-final-form'

import { FormInput } from 'components/FormInput'

const required = (value: any) => (value?.trim() ? undefined : 'This is a required field')

const AddressInputs = ({ source }: { source?: boolean }) => {
    return (
        <Box>
            <Stack
                direction="column"
                sx={{
                    width: '100%',
                }}
                spacing={{
                    xs: 1 / 2,
                }}
            >
                <Field
                    validate={required}
                    placeholder={'Street line 1'}
                    name={`route.${source ? 'source' : 'destination'}.streetLine1`}
                    component={FormInput}
                />
                <Field
                    placeholder={'Street line 2'}
                    name={`route.${source ? 'source' : 'destination'}.streetLine2`}
                    component={FormInput}
                />
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        '> div': {
                            width: '100%',
                        },
                        gap: 1 / 2,
                    }}
                >
                    <div>
                        <Field
                            validate={required}
                            placeholder={'Postcode'}
                            name={`route.${source ? 'source' : 'destination'}.postcode`}
                            component={FormInput}
                        />
                    </div>
                    <div>
                        <Field
                            validate={required}
                            placeholder={'City'}
                            name={`route.${source ? 'source' : 'destination'}.city`}
                            component={FormInput}
                        />
                    </div>
                </Box>
                <Field
                    validate={required}
                    placeholder={'Country code'}
                    name={`route.${source ? 'source' : 'destination'}.countryCode`}
                >
                    {({ input: { onChange, value }, meta }: any) => (
                        <CountryPicker
                            value={value}
                            onChange={(e) => onChange(e)}
                            error={meta.touched && meta.error}
                        />
                    )}
                </Field>
            </Stack>
        </Box>
    )
}

export default memo(AddressInputs)
