import { Button, LabeledFormSection, MainLayoutContainer, Text } from '@lune-fe/lune-ui-lib'
import Check from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import { AxiosError, AxiosResponse } from 'axios'
import { FORM_ERROR, FormApi } from 'final-form'
import { useSnackbar } from 'notistack'
import { useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import FormChangesDetector from 'components/FormChangesDetector'
import { createApiKey, DapiErrors } from 'endpoints/dapi'
import useAccounts from 'hooks/useAccounts'
import useHasUnsavedChanges from 'hooks/useHasUnsavedChanges'
import useIsBillingReady from 'hooks/useIsBillingReady'
import useIsTestMode from 'hooks/useIsTestMode'
import useMixpanel from 'hooks/useMixpanel'
import { queryKeys } from 'queryKeys'
import BillingInfo from 'views/BillingInfo/BillingInfo'
import PaymentInfoWarnings from 'views/BuyOffsets/OrderSummary/PaymentInfoWarnings'
import CreateApiKeyForm from 'views/Developers/APIKeys/CreateApiKeyForm'
import SecretTokenModal from 'views/Developers/SecretTokenModal'
import PaymentMethod from 'views/PaymentMethod/PaymentMethod'

export enum AccountFormKeys {
    NAME = 'name',
    DEFAULT_ACCOUNT = 'defaultAccount',
}

export interface ApiKeyFormModel {
    [AccountFormKeys.NAME]: string
    [AccountFormKeys.DEFAULT_ACCOUNT]: string
}

const CreateApiKey = () => {
    const reactQueryClient = useQueryClient()
    const navigate = useNavigate()
    const { enqueueSnackbar: snackbar } = useSnackbar()
    const { activeAccount, accounts, loading } = useAccounts()
    const [secretToken, setSecretToken] = useState<string>()
    const [initialValues, setInitialValues] = useState({
        name: '',
        defaultAccount: '',
    })
    const [formApi, setFormApi] = useState<FormApi<ApiKeyFormModel>>()
    const [formValue, setFormValue] = useState<ApiKeyFormModel>()

    const isTestMode = useIsTestMode()
    const { setHasUnsavedChangesState } = useHasUnsavedChanges()
    const { isBillingReady } = useIsBillingReady()
    const mixpanel = useMixpanel()

    const accountsOptions = useMemo(() => {
        if (!accounts.length || !activeAccount) {
            return []
        }

        setInitialValues({
            name: '',
            defaultAccount: activeAccount.id,
        })

        const filteredAccounts = accounts.filter(
            (acc) =>
                acc.organisationId === activeAccount.organisationId &&
                acc.type === activeAccount.type,
        )

        return filteredAccounts.map((acc) => ({
            value: acc.id,
            label: acc.name,
        }))
    }, [accounts, activeAccount])

    const onSubmit = async (values: ApiKeyFormModel) => {
        try {
            const secret = await createApiKey({
                name: values[AccountFormKeys.NAME],
                accountId: values[AccountFormKeys.DEFAULT_ACCOUNT],
            })

            mixpanel.track('api_key_created')
            setSecretToken(secret)
            await reactQueryClient.invalidateQueries(queryKeys.GET_API_KEYS)
            setHasUnsavedChangesState(false)
        } catch (error) {
            let msg: string = `Something went wrong. Please contact support if this happens again.`
            if (error instanceof AxiosError && error.response && error.response.status === 403) {
                const response = error.response as AxiosResponse<DapiErrors, any>
                if (response.data.errors[0].errorCode === 'organisation_user_is_not_admin') {
                    msg = `Only admins have permissions to perform this operation.`
                }
            }
            snackbar(msg)
            return { [FORM_ERROR]: msg }
        }
    }

    const shouldDisableSaveButton = useMemo(() => {
        return (
            !formApi ||
            !formApi.getState().dirty ||
            !formApi.getState().valid ||
            (!isTestMode && !isBillingReady)
        )
    }, [formApi, isTestMode, isBillingReady])

    return (
        <MainLayoutContainer
            headerComponent={
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            mb: 6,
                        }}
                    >
                        <Button
                            variant="text"
                            iconButton
                            leftIcon={<CloseIcon />}
                            sx={{
                                ml: -1.5,
                            }}
                            onClick={() => navigate('/developers')}
                        />
                        <Button
                            leftIcon={<Check />}
                            type="submit"
                            onClick={() => formApi?.submit()}
                            disabled={shouldDisableSaveButton}
                            data-testid={`create-api-key-btn`}
                        >
                            Save
                        </Button>
                    </Box>
                    <Text variant="h4">New {isTestMode ? 'Test' : 'Live'} API Key</Text>
                    <Text variant="body3" sx={{ color: `Grey700`, display: 'block' }} mt={1}>
                        {isTestMode
                            ? 'Test API Keys can only perform test operations and will never affect your live environment.'
                            : 'Use in your production app. Keep your keys safe.'}
                    </Text>
                    {!isTestMode && !isBillingReady && (
                        <Box my={9}>
                            <PaymentInfoWarnings />
                        </Box>
                    )}
                </Box>
            }
        >
            <FormChangesDetector initialValues={initialValues} newValues={formValue}>
                <CreateApiKeyForm
                    accountsOptions={accountsOptions}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    loading={loading}
                    setFormApi={setFormApi}
                    setFormValue={setFormValue}
                />
            </FormChangesDetector>
            {secretToken && (
                <SecretTokenModal
                    secretToken={secretToken}
                    onClose={() => navigate(`/developers`)}
                />
            )}
            {!isTestMode && (
                <span data-testid={`payment-section`}>
                    <LabeledFormSection title={`Payment`}>
                        <BillingInfo />
                        <PaymentMethod />
                    </LabeledFormSection>
                </span>
            )}
        </MainLayoutContainer>
    )
}

export default CreateApiKey
