import { Input } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'
import { memo, useState } from 'react'

export const RangeInputs = ({
    onChange,
    value,
    maxPlaceholder,
    minPlaceholder,
}: {
    onChange: (val: [number, number]) => void
    value: [number, number]
    minPlaceholder?: string
    maxPlaceholder?: string
}) => {
    const [minInputValue, setMinInputValue] = useState<number | undefined>(value[0] || 2)
    const [maxInputValue, setMaxInputValue] = useState<number | undefined>(value[1] || 3)

    const MAX_NUMBER_OF_BUNDLES = 5

    const sharedInputStyle = {
        width: '80px',
        '> input': {
            textAlign: 'left !important',
        },
    }

    const onInputChange = (
        inputValue: string,
        setInputValue: (value: number | undefined) => void,
    ) => {
        if (inputValue === '') {
            setInputValue(undefined)
            return
        }

        const valueAsNumber = Number(inputValue)
        if (Number.isNaN(valueAsNumber)) {
            return
        }
        if (valueAsNumber < 1 || valueAsNumber > MAX_NUMBER_OF_BUNDLES) {
            return
        }
        setInputValue(valueAsNumber)
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 1 / 2,
            }}
        >
            <Box>
                <Input
                    type={'number'}
                    data-testid={`range-inputs-min`}
                    placeholder={minPlaceholder || 'Min.'}
                    sx={sharedInputStyle}
                    value={minInputValue}
                    highlightOnFocus
                    onChange={(e) => {
                        onInputChange(e.target.value, setMinInputValue)
                    }}
                    onBlur={() => {
                        if (minInputValue === undefined) {
                            setMinInputValue(2)
                        }
                        if (minInputValue && maxInputValue) {
                            if (maxInputValue < minInputValue) {
                                setMaxInputValue(
                                    minInputValue === MAX_NUMBER_OF_BUNDLES
                                        ? MAX_NUMBER_OF_BUNDLES
                                        : minInputValue + 1,
                                )
                            }
                            onChange([minInputValue, maxInputValue])
                        }
                    }}
                />
            </Box>
            <Box sx={{ lineHeight: '56px' }}>-</Box>
            <Box>
                <Input
                    type={'number'}
                    data-testid={`range-inputs-max`}
                    placeholder={maxPlaceholder || 'Max.'}
                    sx={sharedInputStyle}
                    value={maxInputValue}
                    highlightOnFocus
                    onChange={(e) => {
                        onInputChange(e.target.value, setMaxInputValue)
                    }}
                    onBlur={() => {
                        if (maxInputValue === undefined) {
                            setMaxInputValue(3)
                        }
                        if (minInputValue && maxInputValue) {
                            if (maxInputValue < minInputValue) {
                                setMinInputValue(maxInputValue === 1 ? 1 : maxInputValue - 1)
                            }
                            onChange([minInputValue, maxInputValue])
                        }
                    }}
                />
            </Box>
        </Box>
    )
}

export default memo(RangeInputs)
