import { Bundle, Error } from '@lune-climate/lune'
import { BannerMessage, LuneTheme } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import _ from 'lodash'
import { FC, memo } from 'react'

import useBuyOffsetsState from 'hooks/useBuyOffsetsState'
import {
    Allocation,
    BundleWithPercentage,
    BundleWithVolume,
} from 'views/BuyOffsets/BuyOffsetsTypes'
import CartTable from 'views/BuyOffsets/CartModal/CartTable'
import EmptyStatePlaceholder from 'views/BuyOffsets/CartModal/EmptyStatePlaceholder'

const CardModalContent: FC<{
    customizedPortfolio: boolean
    onRemoveBundle?: (bundle: Bundle) => void
    onBundlesVolumeChange: (items: BundleWithVolume[]) => void
    onBundlesPercentageChange: (items: BundleWithPercentage[]) => void
    allocation: Allocation[]
}> = ({
    customizedPortfolio,
    onRemoveBundle,
    onBundlesVolumeChange,
    onBundlesPercentageChange,
    allocation,
}) => {
    const { buyOffsetProps } = useBuyOffsetsState()
    const { error, insufficientBundlesError, truncateToTonnes } = buyOffsetProps
    const { palette } = LuneTheme

    return (
        <>
            {allocation.length ? (
                <>
                    <Box
                        sx={{
                            borderBottom: `1px solid`,
                            borderColor: palette.Grey300,
                            pb: 6,
                            mb: 6,
                            mt: 1,
                        }}
                    >
                        <CartTable
                            removeBundle={onRemoveBundle}
                            allocation={allocation}
                            editable={customizedPortfolio}
                            onBundlesVolumeChange={onBundlesVolumeChange}
                            onBundlesPercentageChange={onBundlesPercentageChange}
                        />
                    </Box>
                    {error?.key === Error.error_code.ORDER_QUANTITY_INVALID && (
                        <>
                            {truncateToTonnes ? (
                                <BannerMessage
                                    message={
                                        'Increase purchase amount or turn off "Round Down" in Advanced Settings'
                                    }
                                />
                            ) : (
                                <BannerMessage message={'Increase purchase amount'} />
                            )}
                        </>
                    )}
                    {insufficientBundlesError && (
                        <BannerMessage message={insufficientBundlesError.message} />
                    )}
                </>
            ) : (
                <EmptyStatePlaceholder />
            )}
        </>
    )
}

export default memo(CardModalContent, (prev, next) => {
    return _.isEqual(prev.allocation, next.allocation)
})
