import { Bundle } from '@lune-climate/lune'
import {
    LoadingWrapper,
    MainLayoutContainer,
    NoResultsPlaceholder,
    Text,
    useIsMobile,
} from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'
import { cloneElement, FC, ReactElement, ReactNode, useLayoutEffect, useRef, useState } from 'react'

const BundlesLayout: FC<{
    children: ReactElement
    header: ReactNode
    stickyHeader?: ReactNode
    loading?: boolean
    bundles: Bundle[]
    emptyState?: ReactNode
}> = ({ children, header, stickyHeader, loading, bundles, emptyState }) => {
    const { isMobile } = useIsMobile()

    const cardWidth = 264
    const gap = 16

    enum breakpoints {
        xs = cardWidth,
        sm = 2 * cardWidth + 2 * gap,
        md = 3 * cardWidth + 3 * gap,
        lg = 4 * cardWidth + 4 * gap,
        xl = 5 * cardWidth + 5 * gap,
        xxl = 6 * cardWidth + 6 * gap,
    }

    const [containerWidth, setContainerWidth] = useState(breakpoints.xxl)
    const [cols, setCols] = useState<number>(6)
    const cardsContainer = useRef<HTMLDivElement>(null)

    function handleResize() {
        const layoutWidth = cardsContainer.current?.clientWidth
        if (layoutWidth) {
            let wrapperWidth = breakpoints.xxl
            setCols(6)
            if (layoutWidth < breakpoints.xxl) {
                wrapperWidth = breakpoints.xl
                setCols(5)
            }
            if (layoutWidth < breakpoints.xl) {
                wrapperWidth = breakpoints.lg
                setCols(4)
            }
            if (layoutWidth < breakpoints.lg) {
                wrapperWidth = breakpoints.md
                setCols(3)
            }
            if (layoutWidth < breakpoints.md) {
                wrapperWidth = breakpoints.sm
                setCols(2)
            }
            if (layoutWidth < breakpoints.sm) {
                wrapperWidth = breakpoints.xs
                setCols(1)
            }
            setContainerWidth(wrapperWidth)
        }
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', handleResize)
        handleResize()
        return () => window.removeEventListener('resize', handleResize)
    })

    return (
        <LoadingWrapper loading={loading} sx={{ minHeight: '100vh' }}>
            {stickyHeader && (
                <Box
                    sx={{
                        position: 'sticky',
                        top: 0,
                        background: `White`,
                        maxWidth: isMobile ? '100%' : containerWidth,
                        margin: 'auto',
                        padding: '12px 0',
                        zIndex: 9,
                        // 72px container margin-top - 12px header padding-bottom
                        mb: '-60px',
                        mt: '60px',
                    }}
                >
                    {stickyHeader}
                </Box>
            )}
            <MainLayoutContainer
                maxWidth={'xl'}
                headerComponent={
                    <Box
                        sx={{
                            maxWidth: isMobile ? '100%' : containerWidth,
                            margin: 'auto',
                        }}
                    >
                        {header}
                    </Box>
                }
                // Override the default padding of MainLayoutContainer to add px: {{ lg: 2, xl: 2 }} instead of 0
                // This avoids the cards from taking up the full width of container on certain resolutions
                // https://linear.app/lune/issue/LUN-2369/dashboard-project-overview-page-padding
                sx={{
                    my: 9,
                    px: {
                        xs: 2,
                        sm: 2,
                        md: 2,
                        lg: 2,
                        xl: 2,
                    },
                }}
            >
                <Box sx={{ width: '100%' }} ref={cardsContainer}>
                    <Box
                        sx={{
                            maxWidth: isMobile ? '100%' : containerWidth,
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: bundles.length < cols ? 'flex-start' : 'space-between',
                            gap: emptyState && !bundles.length ? '0px' : '16px',
                            margin: 'auto',
                        }}
                    >
                        {emptyState && bundles.length === 0 && (
                            <Box sx={{ height: '550px', width: '100%' }}>
                                <NoResultsPlaceholder
                                    message={
                                        <Text
                                            variant={`h6`}
                                            sx={{
                                                textAlign: 'center',
                                                maxWidth: '250px',
                                            }}
                                        >
                                            {emptyState}
                                        </Text>
                                    }
                                />
                            </Box>
                        )}
                        {[...new Array(cols)].map((c, index) => (
                            <Box key={`outer-${index}`} data-testid={`bundle-column-${index}`}>
                                {bundles
                                    .filter((b) => bundles.indexOf(b) % cols === index)
                                    .map((bundle) => (
                                        <Box
                                            data-testid={`bundle-card`}
                                            key={`inner-${bundle.id}-${index}`}
                                            sx={{
                                                height: 'auto',
                                            }}
                                        >
                                            {cloneElement(children, {
                                                bundle,
                                                key: `bundle-card-${bundle.id}`,
                                            })}
                                        </Box>
                                    ))}
                            </Box>
                        ))}
                    </Box>
                </Box>
            </MainLayoutContainer>
        </LoadingWrapper>
    )
}

export default BundlesLayout
