export const ProjectTypesWithLabels = {
    afforestation: 'Afforestation',
    animal_processes: 'Animal Processes',
    avoided_forest_conversion: 'Avoided forest conversion',
    biochar: 'Biochar',
    biomass: 'Biomass',
    blue_carbon: 'Blue Carbon',
    direct_air_capture: 'Direct Air Capture',
    enhanced_weathering: 'Enhanced Weathering',
    forest_conservation: 'Forest conservation',
    improved_forest_management: 'Improved Forest Management',
    industrial_processes: 'Industrial Processes',
    mineralisation: 'Mineralisation',
    portfolio: 'Portfolio',
    reforestation: 'Reforestation',
    solar_farm: 'Solar Farm',
    wind_farm: 'Wind Farm',
    wooden_building_elements: 'Wooden building elements',
}
