import { Account, AccountPair, Bundle, ClientAccount } from '@lune-climate/lune'
import { Button, LoadingWrapper, Text } from '@lune-fe/lune-ui-lib'
import Check from '@mui/icons-material/Check'
import CreateIcon from '@mui/icons-material/CreateOutlined'
import { Box } from '@mui/material'
import { Big } from 'big.js'
import { FORM_ERROR } from 'final-form'
import { useSnackbar } from 'notistack'
import { FC, ReactElement, useCallback, useMemo, useState } from 'react'

import AccountForm from 'components/AccountForm/AccountForm'
import ConditionalTooltip from 'components/ConditionalTooltip'
import FormChangesDetector from 'components/FormChangesDetector'
import useBundlesPortfolios from 'hooks/useBundlesPortfolios'
import useMixpanel from 'hooks/useMixpanel'
import { calculateBundleSelectionTotalSum } from 'utils'
import BundlePicker, {
    BundlePickerView,
    CUSTOM_PORTFOLIO_ID,
} from 'views/Settings/BundlePicker/BundlePicker'

export enum AccountFormKeys {
    NAME = 'name',
    CURRENCY = 'currency',
    BENEFICIARY = 'beneficiary',
    BUNDLE_SETTINGS = 'bundleSettings',
}

export interface BundleSettings {
    customBundleSelection: Record<string, number | string>
    selectedBundlePortfolioId: string
}

export interface IAccountFormData {
    name?: string
    currency: string
    beneficiary?: string
    bundleSettings: BundleSettings
}

const AccountFormWrapper = ({
    onSubmit,
    initialValues,
    onSuccess,
    readOnly,
    existingAccount,
    disabledFields,
    bundles,
    loading,
    forbiddenTooltip,
}: {
    onSubmit: (data: IAccountFormData) => Promise<Account | AccountPair | ClientAccount | undefined>
    initialValues: IAccountFormData
    onSuccess: (res?: any) => void
    readOnly?: boolean
    existingAccount?: boolean
    disabledFields?: AccountFormKeys[]
    bundles?: Bundle[]
    loading?: boolean
    forbiddenTooltip: FC<{ children: ReactElement; contentWidth?: boolean }>
}) => {
    const { bundlesPortfolios, loading: loadingBundles } = useBundlesPortfolios()
    const [view, setView] = useState(BundlePickerView.PERCENTAGES)
    const [formValue, setFormValue] = useState<IAccountFormData>()
    const [bundleSettings, setBundleSettings] = useState<BundleSettings>({
        customBundleSelection: initialValues[AccountFormKeys.BUNDLE_SETTINGS].customBundleSelection,
        selectedBundlePortfolioId:
            initialValues[AccountFormKeys.BUNDLE_SETTINGS].selectedBundlePortfolioId,
    })
    const [bundlePickerError, setBundlePickerError] = useState<boolean>(false)
    const mixpanel = useMixpanel()
    const { enqueueSnackbar: snackbar } = useSnackbar()

    const onSubmitForm = async (values?: IAccountFormData) => {
        if (values) {
            try {
                const beneficiary = values.beneficiary?.trim()
                const res = await onSubmit({
                    name: values.name!.trim(),
                    beneficiary,
                    currency: values.currency,
                    bundleSettings: {
                        customBundleSelection: bundleSettings.customBundleSelection,
                        selectedBundlePortfolioId: bundleSettings.selectedBundlePortfolioId,
                    },
                })

                if (!res) {
                    throw new Error('Empty response means something went wrong')
                }

                if (
                    bundleSettings.customBundleSelection !==
                        initialValues[AccountFormKeys.BUNDLE_SETTINGS].customBundleSelection ||
                    bundleSettings.selectedBundlePortfolioId !==
                        initialValues[AccountFormKeys.BUNDLE_SETTINGS].selectedBundlePortfolioId
                ) {
                    mixpanel.track('settings_default_bundles_saved')
                }

                if (beneficiary) {
                    mixpanel.track('settings_beneficiary_saved')
                }

                onSuccess(res)
            } catch {
                const msg = `Something went wrong. Please contact support if this happens again.`
                snackbar(msg)
                return {
                    [FORM_ERROR]: msg,
                }
            }
        }
    }

    const onChangeCustomBundleSelection = useCallback(
        (selection: Record<string, string | number>) => {
            setBundlePickerError(!calculateBundleSelectionTotalSum(selection).eq(Big(100)))
            setBundleSettings((prev) => {
                return {
                    ...prev,
                    customBundleSelection: selection,
                }
            })
        },
        [],
    )

    const onChangeBundlePortfolio = useCallback((selection: string) => {
        setBundleSettings((prev) => {
            return {
                ...prev,
                selectedBundlePortfolioId: selection,
            }
        })
    }, [])

    const bundlesAlphabetical = useMemo(() => {
        if (!bundles) {
            return []
        }
        return bundles.sort((a, b) => a.name.localeCompare(b.name))
    }, [bundles])

    return (
        <LoadingWrapper loading={loadingBundles || loading}>
            {!loadingBundles && !loading && (
                <FormChangesDetector initialValues={initialValues} newValues={formValue}>
                    <AccountForm
                        existingAccount={existingAccount}
                        disabledFields={disabledFields}
                        readOnly={readOnly}
                        initialValues={initialValues}
                        setFormValue={setFormValue}
                        onSubmitForm={onSubmitForm}
                        forbiddenTooltip={forbiddenTooltip}
                    />
                    <Box sx={{ mb: 9 }}>
                        <Text variant={'h6'} sx={{ mb: 1 }}>
                            Default bundle selection
                        </Text>
                        <Text
                            variant={'body3'}
                            sx={{
                                color: `Grey700`,
                                mb: 4,
                                display: 'block',
                            }}
                        >
                            These settings will apply to any purchase made for this account, unless
                            overridden.
                        </Text>
                        <BundlePicker
                            view={view}
                            readOnly={readOnly}
                            customBundleSelection={bundleSettings.customBundleSelection}
                            onChangeCustomBundleSelection={onChangeCustomBundleSelection}
                            selectedBundlePortfolioId={bundleSettings.selectedBundlePortfolioId}
                            onChangeBundlePortfolio={onChangeBundlePortfolio}
                            allBundles={bundlesAlphabetical}
                            predefinedBundlePortfolios={bundlesPortfolios}
                        />
                    </Box>
                    {view === BundlePickerView.PERCENTAGES && (
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <ConditionalTooltip show={!!readOnly} tooltip={forbiddenTooltip}>
                                <Button
                                    data-testid="account-submit-btn"
                                    disabled={
                                        !formValue?.name?.trim().length ||
                                        !formValue.currency.length ||
                                        bundlePickerError ||
                                        readOnly
                                    }
                                    onClick={() => onSubmitForm(formValue)}
                                    leftIcon={<Check />}
                                    sx={{ mr: 1 }}
                                >
                                    Save Account
                                </Button>
                            </ConditionalTooltip>
                            {bundleSettings.selectedBundlePortfolioId === CUSTOM_PORTFOLIO_ID && (
                                <ConditionalTooltip show={!!readOnly} tooltip={forbiddenTooltip}>
                                    <Button
                                        disabled={readOnly}
                                        data-testid="account-edit-bundles-btn"
                                        variant={'outlined'}
                                        leftIcon={<CreateIcon />}
                                        onClick={() => setView(BundlePickerView.SWITCHES)}
                                    >
                                        Edit bundles
                                    </Button>
                                </ConditionalTooltip>
                            )}
                        </Box>
                    )}
                    {view === BundlePickerView.SWITCHES && (
                        <Button
                            data-testid="account-save-bundles-btn"
                            leftIcon={<Check />}
                            variant={`outlined`}
                            onClick={() => setView(BundlePickerView.PERCENTAGES)}
                            disabled={!Object.keys(bundleSettings.customBundleSelection).length}
                        >
                            Save bundles
                        </Button>
                    )}
                </FormChangesDetector>
            )}
        </LoadingWrapper>
    )
}

export default AccountFormWrapper
