import { Project } from '@lune-climate/lune'
import { ScrollableContainer } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import moment from 'moment'
import React, { FC } from 'react'

import CardItem from 'views/Projects/Project/shared/CardItem'

export type NewsAndUpdatesProps = NonNullable<Project['linksAndNews']>['news']

const NewsAndUpdates: FC<{
    news: NewsAndUpdatesProps
    scrollRef: React.RefObject<HTMLDivElement>
}> = ({ news, scrollRef }) => {
    return (
        <Box
            sx={{
                display: 'block',
                mt: 4,
                width: '100%',
            }}
        >
            <ScrollableContainer
                containerRef={scrollRef}
                ignoreElements={'.cart-item__description, .cart-item__title'}
            >
                {news.map((item, index) => {
                    const { imageUrl, title, description, publishedAt, articleUrl, faviconUrl } =
                        item
                    return (
                        <CardItem
                            key={`card-item-${index}`}
                            imageUrl={imageUrl}
                            title={title}
                            description={description}
                            link={{
                                url: articleUrl,
                                // This line will crash if articleUrl is not a valid URL
                                label: new URL(articleUrl).hostname,
                                icon: <img src={faviconUrl} width={20} height={20} />,
                            }}
                            date={
                                publishedAt !== null
                                    ? moment(publishedAt).format(`D MMMM YYYY`)
                                    : undefined
                            }
                        />
                    )
                })}
            </ScrollableContainer>
        </Box>
    )
}

export default NewsAndUpdates
