import {
    BannerMessage,
    Button,
    DragAndDropFileUpload,
    LoadingWrapper,
    LuneTheme,
    MainLayoutContainer,
    Modal,
    Table,
    Text,
    Tooltip,
} from '@lune-fe/lune-ui-lib'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import AirplanemodeActiveOutlinedIcon from '@mui/icons-material/AirplanemodeActiveOutlined'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import DirectionsBoatFilledOutlinedIcon from '@mui/icons-material/DirectionsBoatFilledOutlined'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined'
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined'
import TrainOutlinedIcon from '@mui/icons-material/TrainOutlined'
import { Box, Divider, Stack } from '@mui/material'
import { AxiosError } from 'axios'
import fileDownload from 'js-file-download'
import { flatten } from 'lodash'
import DownloadDropdown from 'lune-ui-lib/src/components/DownloadDropdown'
import moment from 'moment/moment'
import React, { useEffect, useMemo, useState } from 'react'
import { FileRejection } from 'react-dropzone'
import { useNavigate, useParams } from 'react-router-dom'

import CSVLogisticsAirTemplate from '../../../assets/air_shipments.csv'
import CSVFintechTemplate from '../../../assets/fintech_sheet_template_v1.csv'
import CSVLogisticsMultiModalTemplate from '../../../assets/multi_modal_shipments.csv'
import CSVLogisticsRoadTemplate from '../../../assets/road_shipments.csv'
import CSVLogisticsSeaTemplate from '../../../assets/sea_shipments.csv'
import CSVLogisticsTrainTemplate from '../../../assets/train_shipments.csv'
import { notify } from '../../../bugsnag'
import { downloadCsvSheet, uploadCsvSheet } from '../../../endpoints/dapi'
import useCsvSheets from '../../../hooks/useCsvSheets'
import { CsvSheet } from '../../../models/openDapi'
import { assertType } from '../../../utils'

const FIRST_COL_SIZE = 4
const MAX_SIZE_BYTES = 2097152

const formatDate = (date: string): string => {
    return moment(date).format('ll')
}

const formatSheetType = (type: CsvSheet['type']): string => {
    if (type === 'lune') {
        return 'Lune'
    } else if (type === 'ecotransit') {
        return 'EcoTransIT'
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (type === 'lune_logistics_v2') {
        return 'Lune v2'
    } else if (type === 'lune_fintech') {
        return 'Spend'
    } else {
        assertType<null>(type)
        return '-'
    }
}

const templateNameToFileMeta = new Map<string, string>([
    [CSVLogisticsRoadTemplate, 'road_shipment_template'],
    [CSVLogisticsSeaTemplate, 'sea_shipment_template'],
    [CSVLogisticsTrainTemplate, 'rail_shipment_template'],
    [CSVLogisticsAirTemplate, 'air_shipment_template'],
    [CSVLogisticsMultiModalTemplate, 'multi_modal_shipment_template'],
])

const CsvSheetCommonWrapper = ({ type }: { type: 'fintech' | 'logistics' }) => {
    const { spacing } = LuneTheme
    const navigate = useNavigate()
    const [isUploading, setUploading] = useState(false)
    const [modal, setModal] = useState<{ subject: string[]; description?: string } | undefined>(
        undefined,
    )
    const ref = React.useRef<HTMLDivElement>(null)
    const { loading, paginatedCsvSheets, hasNextPage, fetchNextPage, refetch } = useCsvSheets(type)
    const { sheetId } = useParams<{ sheetId?: string }>()
    useEffect(() => {
        refetch()
    }, [type])

    const downloadSheet = async (id: string) => {
        try {
            const data = await downloadCsvSheet(id)
            fileDownload(data, `${id}.csv`, 'text/csv')
        } catch (err) {
            if (err instanceof AxiosError && err.status === 404) {
                // swallow, users can change the page url to trigger this error
                return
            }
            throw err
        }
    }

    const downloadTemplate = (path: string) => {
        fetch(path).then(async (r) =>
            fileDownload(await r.blob(), `${templateNameToFileMeta.get(path)}.csv`, 'text/csv'),
        )
    }

    const csvSheets = useMemo(
        () => flatten((paginatedCsvSheets?.pages ?? []).map(({ data }) => data)),
        [paginatedCsvSheets],
    )

    useEffect(() => {
        if (!sheetId) {
            return
        }

        downloadSheet(sheetId)
    }, [sheetId])

    const onUploadSheet = (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
        if (rejectedFiles.length) {
            setModal({
                subject: ['Upload failed', 'CSV files only, up to 2MB'],
            })

            return
        }

        const formData = new FormData()
        const file = acceptedFiles[0]
        formData.append('sheet', file)
        setUploading(true)
        uploadCsvSheet(formData, type)
            .then(() => {
                setModal({
                    subject: [
                        `Upload complete.`,
                        `Processing your file, we’ll email you when ready.`,
                    ],
                    description: 'Should take a few minutes',
                })

                refetch()
            })
            .catch((err) => {
                if (err.response.status === 400) {
                    if (err.response.data.errors[0].error_code === 'invalid_file_extension') {
                        setModal({
                            subject: ['Upload failed', 'Please upload a CSV file'],
                        })
                        return
                    }
                    if (err.response.data.errors[0].error_code === 'max_file_size_exceeded') {
                        setModal({
                            subject: ['Upload failed', 'Max file size of 2MB exceeded'],
                        })
                        return
                    }
                    if (err.response.data.errors[0].error_code === 'file_format_invalid') {
                        setModal({
                            subject: [
                                'Upload failed',
                                'File format is invalid. Please upload a CSV file.',
                            ],
                        })
                        return
                    }
                }

                setModal({
                    subject: ['Unexpected upload failure'],
                })
                notify(err)
            })
            .finally(() => setUploading(false))
    }

    return (
        <>
            <Modal
                fullWidth
                open={modal !== undefined}
                onClose={() => {}}
                header={
                    <Box>
                        {(modal?.subject ?? []).map((e, i) => (
                            <Text
                                key={i}
                                variant={'h5'}
                                sx={{
                                    textAlign: 'center',
                                }}
                            >
                                {e}
                            </Text>
                        ))}
                    </Box>
                }
                subheader={<Text variant={'body1'}>{modal?.description}</Text>}
                actions={
                    <Button variant={'contained'} onClick={() => setModal(undefined)} wide>
                        OK
                    </Button>
                }
            />
            <MainLayoutContainer
                headerComponent={
                    <Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                mb: 6,
                            }}
                        >
                            <Button
                                variant="text"
                                iconButton
                                leftIcon={<ArrowBackOutlinedIcon />}
                                sx={{
                                    ml: -1.5,
                                }}
                                onClick={() => navigate('/calculate-emissions')}
                            />
                        </Box>
                        <Text
                            variant={'h4'}
                        >{`${type === 'fintech' ? 'Fintech' : 'Logistics'} spreadsheet calculations`}</Text>
                        <Text variant="body3" sx={{ color: `Grey700`, display: 'block' }} mt={1}>
                            {`Upload your ${type === 'fintech' ? 'transaction' : 'logistics'} data using our template${type === 'fintech' ? '.' : ", or EcoTransIT's CSV"}`}
                        </Text>
                    </Box>
                }
            >
                <Stack spacing={{ xs: 4, sm: 9, md: 9, lg: 9, xl: 9 }}>
                    <Box sx={{ height: spacing(40) }}>
                        <DragAndDropFileUpload
                            /*
              // Property 'ref' does not exist on type 'IntrinsicAttributes & { children?: ReactNode; isUploading?: boolean | undefined; } & Pick<HTMLProps<HTMLElement>, PropTypes> & { ...; }'.
              */
                            /* @ts-ignore: ... */
                            ref={ref}
                            accept={{ 'text/csv': ['.csv'] }}
                            maxSize={MAX_SIZE_BYTES}
                            isUploading={isUploading}
                            onDrop={onUploadSheet}
                            rejectDescription={`CSV files only, up to 2MB`}
                            description={`Drop your CSV file here`}
                        />
                    </Box>

                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        spacing={1}
                    >
                        <Button
                            leftIcon={<AddOutlinedIcon />}
                            onClick={() => {
                                if (ref.current) {
                                    ref.current.click()
                                }
                            }}
                        >
                            Upload file
                        </Button>
                        {type === 'fintech' ? (
                            <Button
                                href={CSVFintechTemplate}
                                leftIcon={<FileDownloadOutlinedIcon />}
                                variant="outlined"
                                /* @ts-ignore: ... */
                                download
                            >
                                Download CSV template
                            </Button>
                        ) : (
                            <DownloadDropdown
                                downloadPropMap={{
                                    sea: {
                                        downloadFn: () => downloadTemplate(CSVLogisticsSeaTemplate),
                                        title: 'Sea Shipments',
                                        icon: <DirectionsBoatFilledOutlinedIcon />,
                                    },
                                    plane: {
                                        downloadFn: () => downloadTemplate(CSVLogisticsAirTemplate),
                                        title: 'Air Shipments',
                                        icon: <AirplanemodeActiveOutlinedIcon />,
                                    },
                                    train: {
                                        downloadFn: () =>
                                            downloadTemplate(CSVLogisticsTrainTemplate),
                                        title: 'Train Shipments',
                                        icon: <TrainOutlinedIcon />,
                                    },
                                    truck: {
                                        downloadFn: () =>
                                            downloadTemplate(CSVLogisticsRoadTemplate),
                                        title: 'Road Shipments',
                                        icon: <LocalShippingOutlinedIcon />,
                                    },
                                    multimodal: {
                                        downloadFn: () =>
                                            downloadTemplate(CSVLogisticsMultiModalTemplate),
                                        title: 'Multi-modal Shipments',
                                        icon: <Inventory2OutlinedIcon />,
                                    },
                                }}
                            />
                        )}

                        {type !== 'fintech' && (
                            <Button
                                target="_blank"
                                leftIcon={<AutoStoriesOutlinedIcon />}
                                variant="outlined"
                                href="https://docs.lune.co/guides/logistics-sheets"
                            >
                                Read template guide
                            </Button>
                        )}
                    </Stack>

                    <BannerMessage
                        message={`Calculations will count towards your tier limit, one per row`}
                    />

                    {csvSheets.length > 0 && <Divider />}

                    <LoadingWrapper loading={loading}>
                        {csvSheets.length > 0 && (
                            <Table.TableContainer>
                                <Table sx={{ minWidth: 650 }}>
                                    <Table.TableHead>
                                        <Table.TableRow>
                                            <Table.TableCell colSize={FIRST_COL_SIZE}>
                                                Upload date
                                            </Table.TableCell>
                                            {type !== 'fintech' && (
                                                <Table.TableCell>Type</Table.TableCell>
                                            )}
                                            <Table.TableCell>Status</Table.TableCell>
                                            <Table.TableCell align="right" alignEnd>
                                                # of calculations
                                            </Table.TableCell>
                                            <Table.TableCell align="right" alignEnd>
                                                # of errors
                                            </Table.TableCell>
                                            <Table.TableCell align="right" alignEnd>
                                                tCO₂
                                            </Table.TableCell>
                                            <Table.TableCell
                                                align="right"
                                                alignEnd
                                            ></Table.TableCell>
                                        </Table.TableRow>
                                    </Table.TableHead>
                                    <Table.TableBody>
                                        {csvSheets.map((sheet, i) => (
                                            <Table.TableRow key={i}>
                                                <Table.TableCell colSize={FIRST_COL_SIZE}>
                                                    <DescriptionOutlinedIcon sx={{ mr: 3 }} />
                                                    <span>{formatDate(sheet.createdAt)}</span>
                                                </Table.TableCell>
                                                {type !== 'fintech' && (
                                                    <Table.TableCell>
                                                        {formatSheetType(sheet.type)}
                                                    </Table.TableCell>
                                                )}
                                                <Table.TableCell>
                                                    {sheet.processedAt ? 'Completed' : 'Processing'}
                                                </Table.TableCell>
                                                <Table.TableCell align="right" alignEnd>
                                                    {sheet.processedAt
                                                        ? sheet.numCalculations
                                                        : '-'}
                                                </Table.TableCell>
                                                <Table.TableCell align="right" alignEnd>
                                                    {sheet.processedAt ? sheet.numErrors : '-'}
                                                </Table.TableCell>
                                                <Table.TableCell align="right" alignEnd>
                                                    {sheet.processedAt ? sheet.quantity : '-'}
                                                </Table.TableCell>
                                                <Table.TableCell align="right" alignEnd>
                                                    {sheet.processedAt ? (
                                                        <Button
                                                            variant="text"
                                                            iconButton
                                                            rightIcon={<FileDownloadOutlinedIcon />}
                                                            onClick={() => downloadSheet(sheet.id)}
                                                        />
                                                    ) : (
                                                        <Tooltip
                                                            placement="top"
                                                            title="File is being processed"
                                                        >
                                                            <Button
                                                                variant="text"
                                                                disabled
                                                                iconButton
                                                                rightIcon={
                                                                    <FileDownloadOutlinedIcon />
                                                                }
                                                            />
                                                        </Tooltip>
                                                    )}
                                                </Table.TableCell>
                                            </Table.TableRow>
                                        ))}
                                    </Table.TableBody>
                                </Table>
                            </Table.TableContainer>
                        )}
                    </LoadingWrapper>

                    {hasNextPage && (
                        <Button
                            leftIcon={<ArrowDownwardIcon />}
                            variant="outlined"
                            onClick={() => fetchNextPage()}
                        >
                            Load more
                        </Button>
                    )}
                </Stack>
            </MainLayoutContainer>
        </>
    )
}

export default CsvSheetCommonWrapper
