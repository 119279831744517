import { LabeledFormSection } from '@lune-fe/lune-ui-lib'
import Stack from '@mui/material/Stack'
import { FC, ReactNode } from 'react'

const CalculateEmissionsLabeledFormSection: FC<{ label?: string; children: ReactNode }> = ({
    label,
    children,
}) => {
    return (
        <LabeledFormSection title={label || ''}>
            <Stack
                direction="column"
                sx={{
                    width: '100%',
                }}
                spacing={{
                    xs: 1 / 2,
                }}
            >
                {children}
            </Stack>
        </LabeledFormSection>
    )
}

export default CalculateEmissionsLabeledFormSection
