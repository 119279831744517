import { Bundle, BundlePortfolio } from '@lune-climate/lune'
import { ButtonGroup } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'
import { Big } from 'big.js'
import { FC, ReactNode, useEffect, useMemo } from 'react'

import CustomBundleSelection from 'views/Settings/BundlePicker/CustomBundleSelection'
import PredefinedBundleAllocation from 'views/Settings/BundlePicker/PredefinedBundleAllocation'

const PickerView: FC<{ current: string; id: string; children: ReactNode }> = ({
    current,
    id,
    children,
}) => <div hidden={id !== current}>{id === current && <Box>{children}</Box>}</div>

export enum BundlePickerView {
    PERCENTAGES = 'percentages',
    SWITCHES = 'switches',
}

export const CUSTOM_PORTFOLIO_ID = 'custom'

export const calculateAveragePrice = (
    bundles: Bundle[],
    bundleSelection: Record<string, number | string>,
) => {
    return Object.entries(bundleSelection).reduce((acc: Big, [id, percentage]) => {
        const ratio = new Big(percentage).div(new Big(100))
        const price = bundles.find((b) => b.id === id)?.grossUnitPrice || 0
        return acc.add(ratio.mul(new Big(price)))
    }, new Big(0))
}

const BundlePicker: FC<{
    view: BundlePickerView
    readOnly?: boolean
    allBundles: Bundle[]
    predefinedBundlePortfolios: BundlePortfolio[]
    selectedBundlePortfolioId: string
    onChangeBundlePortfolio: (selectedPortfolioId: string) => void
    customBundleSelection: Record<string, number | string>
    onChangeCustomBundleSelection: (allocation: Record<string, number | string>) => void
    allocationVolume?: Record<string, number>
}> = ({
    view,
    readOnly,
    allBundles,
    customBundleSelection,
    predefinedBundlePortfolios,
    selectedBundlePortfolioId = CUSTOM_PORTFOLIO_ID,
    onChangeBundlePortfolio,
    onChangeCustomBundleSelection,
    allocationVolume,
}) => {
    const tabItems = useMemo(
        () => [
            {
                label: `Custom`,
                value: CUSTOM_PORTFOLIO_ID,
                dataTestId: 'bundle-picker-custom-btn',
            },
            ...predefinedBundlePortfolios.map(({ label, id }) => ({
                label,
                value: id,
                dataTestId: `bundle-picker-${label.toLocaleLowerCase().replaceAll(' ', '-')}-btn`,
            })),
        ],
        [predefinedBundlePortfolios],
    )

    useEffect(() => {
        if (selectedBundlePortfolioId) {
            onChangeBundlePortfolio(selectedBundlePortfolioId)
        }
    }, [onChangeBundlePortfolio, selectedBundlePortfolioId])

    return (
        <>
            {view === BundlePickerView.PERCENTAGES && (
                <ButtonGroup
                    items={tabItems}
                    value={selectedBundlePortfolioId}
                    onChange={onChangeBundlePortfolio}
                    disabled={readOnly}
                />
            )}
            <Box
                sx={{
                    mt: 3,
                }}
            >
                <PickerView current={selectedBundlePortfolioId} id={CUSTOM_PORTFOLIO_ID}>
                    <CustomBundleSelection
                        allocationVolume={allocationVolume}
                        view={view}
                        readOnly={readOnly}
                        bundles={allBundles}
                        bundleSelection={customBundleSelection}
                        onChange={onChangeCustomBundleSelection}
                    />
                </PickerView>

                {predefinedBundlePortfolios.map(({ bundleSelection, id }) => (
                    <PickerView key={id} id={id} current={selectedBundlePortfolioId}>
                        <PredefinedBundleAllocation
                            allocationVolume={allocationVolume}
                            bundles={allBundles}
                            bundleSelection={bundleSelection.reduce(
                                (acc, curr) => {
                                    acc[curr.bundleId] = curr.percentage
                                    return acc
                                },
                                {} as Record<string, number | string>,
                            )}
                        />
                    </PickerView>
                ))}
            </Box>
        </>
    )
}

export default BundlePicker
