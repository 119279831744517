import { AccountType } from '@lune-climate/lune'
import { LoadingWrapper, LuneTheme, Switch, Text, Tooltip } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import { styled } from '@mui/system'
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'

import DiscardChangesConfirmation from 'components/DiscardChangesConfirmation'
import { ACCOUNT_ID_QUERY_PARAM_KEY } from 'components/FiltersWithQueryParamsWrapper'
import { setUserPrimaryAccountId } from 'endpoints/dapi'
import useHasUnsavedChanges from 'hooks/useHasUnsavedChanges'
import { useLuneClient } from 'hooks/useLuneClient'
import useMixpanel from 'hooks/useMixpanel'
import { queryKeys } from 'queryKeys'

const getActiveAndSiblingAccounts = (luneClient: ReturnType<typeof useLuneClient>) =>
    luneClient.getAccount().then((r) => {
        const activeAccount = r.unwrap()
        return {
            liveId:
                activeAccount.type === AccountType.LIVE
                    ? activeAccount.id
                    : activeAccount.siblingId,
            testId:
                activeAccount.type === AccountType.LIVE
                    ? activeAccount.siblingId
                    : activeAccount.id,
            currenType: activeAccount.type,
        }
    })

/**
 * TestModeSwitch needs its own, unique Query which will remain static while
 * we invalidate all other queries. This prevents re-renders and allows the Switch animation
 * to play.
 */
const TestModeSwitch: FC = () => {
    const navigate = useNavigate()
    const { palette } = LuneTheme
    const [isTestMode, setIsTestMode] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()

    const { hasUnsavedChanges, setHasUnsavedChangesState } = useHasUnsavedChanges()
    const [loading, setLoading] = useState(true)
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [checked, setChecked] = useState<boolean>(isTestMode)
    const luneClient = useLuneClient()
    const mixpanel = useMixpanel()

    useEffect(() => {
        async function getAccountData() {
            const account: {
                liveId: string
                testId: string
                currenType: AccountType
            } = await getActiveAndSiblingAccounts(luneClient)
            setIsTestMode(account.currenType === AccountType.TEST)
            setChecked(account.currenType === AccountType.TEST)
            setLoading(false)
        }
        getAccountData()
    }, [luneClient])

    const { data: activeAndSibling } = useQuery<{
        liveId: string
        testId: string
        currenType: AccountType
    }>(queryKeys.GET_ACTIVE_ACCOUNT_FOR_TEST_SWITCH, () => getActiveAndSiblingAccounts(luneClient))
    const queryClient = useQueryClient()

    const StyledHr = useMemo(
        () =>
            styled('hr')(({ theme }) =>
                theme.unstable_sx({
                    border: 0,
                    height: '1px',
                    width: 1,
                    background: palette.Grey300,
                    margin: 0,
                }),
            ),
        [palette.Grey300],
    )

    const animateToggle = (checked: boolean) => {
        setTimeout(() => {
            setChecked(checked)
        }, 100)
    }

    const onSwitch = useCallback(
        (checked: boolean) => {
            setIsTestMode(!isTestMode)

            const newPrimaryAccountId = checked
                ? activeAndSibling?.testId
                : activeAndSibling?.liveId

            setUserPrimaryAccountId(newPrimaryAccountId!).then(async () => {
                mixpanel.track('AccountSwitched', {
                    id: newPrimaryAccountId,
                })

                const path = window.location.pathname

                // Special case for TEST MODE Switch on specific, individual page
                if (
                    path.includes(`/settings/accounts/`) &&
                    !path.includes(`/settings/accounts/new`)
                ) {
                    const accountId = path.replace(`/settings/accounts/`, '')
                    const acc = (await luneClient.getAccount({ accountId })).unwrap()
                    navigate(`/settings/accounts/${acc.siblingId}`, { replace: true })
                    setHasUnsavedChangesState(false)
                }

                // Special case for TEST MODE Switch on specific, individual page
                if (path.includes(`/settings/client-accounts/`)) {
                    navigate(`/settings/client-accounts`, { replace: true })
                }

                // Special case for TEST MODE Switch on Order details page (e.g. /orders/rKqngNM0G)
                if (path.includes(`/orders/`)) {
                    // Navigate to /orders because the current order details now belong to an order of the "opposite" mode
                    navigate(`/orders`, { replace: true })
                }

                // Special case for TEST MODE Switch on Orders list page (e.g. /orders or /orders?account_id=q9aKx7b etc)
                if (path.match(/^\/orders(\?.*)?$/)) {
                    // Switching between test/live mode means switching between sets of accounts
                    // Because OrdersList account filters are stored in the URL as query param account_id,
                    // we need to remove that query param when switching between modes to avoid showing stale data.
                    if (searchParams.has(ACCOUNT_ID_QUERY_PARAM_KEY)) {
                        searchParams.delete(ACCOUNT_ID_QUERY_PARAM_KEY)
                        setSearchParams(searchParams)
                    }
                }

                await queryClient.invalidateQueries({
                    predicate: (query) => {
                        const keysToExclude = [
                            queryKeys.GET_ACTIVE_ACCOUNT_FOR_TEST_SWITCH,
                            // Excluding RECENTLY_USED_ACCOUNTS because refetching is handled in useRecentltUsedAccounts hook manually
                            queryKeys.RECENTLY_USED_ACCOUNTS,
                        ]

                        // Check if the query key is not in the keysToExclude array
                        return !keysToExclude.includes(query.queryKey as queryKeys)
                    },
                })
            })
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [activeAndSibling, mixpanel],
    )

    const toggle = (switchValue: any) => {
        const path = window.location.pathname
        const shouldShowConfirmationModal =
            hasUnsavedChanges &&
            !path.includes(`/settings/accounts/new`) &&
            !path.includes(`/order`) &&
            !path.includes(`/proposal-request`)
        if (shouldShowConfirmationModal) {
            setOpenModal(true)
        } else {
            animateToggle(switchValue.target.checked)
            onSwitch(switchValue.target.checked)
        }
    }

    return (
        <>
            <Box sx={{ flex: '1 1 auto' }}>
                <StyledHr />

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        pl: 3,
                        pr: 3,
                        '> span:first-child': {
                            display: 'inline-block',
                            width: '100%',
                        },
                    }}
                >
                    <LoadingWrapper loading={loading}>
                        <Tooltip
                            sx={{ left: '16px !important' }}
                            title={
                                <Box
                                    sx={{
                                        maxWidth: '228px',
                                    }}
                                >
                                    Use Test mode to place orders without being charged and create
                                    Test API keys.
                                    <br />
                                    <br />
                                    Toggle off when you’re ready to go live.
                                </Box>
                            }
                            placement="top"
                        >
                            <Text
                                variant={`body3`}
                                sx={{
                                    width: '100%',
                                    height: '66px',
                                    lineHeight: '66px',
                                }}
                            >
                                Test mode
                            </Text>
                        </Tooltip>
                        <Switch
                            data-testid={'test-mode-switch'}
                            defaultChecked={checked}
                            onChange={toggle}
                        />
                    </LoadingWrapper>
                </Box>
            </Box>
            {openModal && (
                <DiscardChangesConfirmation
                    onConfirm={() => {
                        setOpenModal(false)
                        setHasUnsavedChangesState(false)
                        onSwitch(!checked)
                        animateToggle(!checked)
                    }}
                    onCancel={() => {
                        setOpenModal(false)
                        animateToggle(checked)
                    }}
                />
            )}
        </>
    )
}

export default memo(TestModeSwitch)
