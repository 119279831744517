import { Bundle } from '@lune-climate/lune'
import { formatToCurrency } from '@lune-fe/lune-components-lib'
import { ListItemLayout } from '@lune-fe/lune-ui-lib'
import Avatar from '@mui/material/Avatar'
import { FC } from 'react'

import useAccountCurrency from 'hooks/useAccountCurrency'
import {
    carbonPermanenceList,
    offsetTypeList,
} from 'views/Proposals/ProposalRequestForm/ProjectRequirementsStep'

const BundlesList: FC<{
    selectedBundles: {
        bundle: Bundle
        percentage: string
    }[]
    currency?: string
}> = ({ selectedBundles, currency }) => {
    const toCurrency = useAccountCurrency()

    return (
        <>
            {selectedBundles.map(({ bundle, percentage }) => {
                const priceWithCurrency = currency
                    ? formatToCurrency(bundle.grossUnitPrice, currency)
                    : toCurrency?.(bundle.grossUnitPrice)
                const offsetType = offsetTypeList.find((i) => i.value === bundle.offsetType)?.label
                const carbonPermanence = carbonPermanenceList.find(
                    (i) => i.value === bundle.carbonPermanence,
                )?.label
                return (
                    <ListItemLayout
                        data-testid={`bundle-list-item-${bundle.id}`}
                        key={`bundle-list-item-${bundle.id}`}
                        image={
                            <Avatar
                                alt={bundle.name}
                                src={bundle.primaryImage || ''}
                                variant={`rounded`}
                                sx={{
                                    width: `48px`,
                                    height: `48px`,
                                    borderRadius: `8px`,
                                }}
                            />
                        }
                        title={`${percentage}% ${bundle.name}`}
                        subTitle={`${priceWithCurrency} / tCO₂ • ${offsetType} • ${carbonPermanence}`}
                    />
                )
            })}
        </>
    )
}

export default BundlesList
