import { CtaTile, MainLayoutContainer, Text } from '@lune-fe/lune-ui-lib'
import Stack from '@mui/material/Stack'
import { RefObject } from 'react'
import { useNavigate } from 'react-router-dom'

import browseEmissionFactors from 'images/browse-emission-factors.png'
import {
    EmissionCalculationFlows,
    EmissionCalculations,
    EstimateTypeSlug,
} from 'views/CalculateEmissions'

const CalculateEmissions = ({ scrollRef }: { scrollRef: RefObject<HTMLDivElement> }) => {
    const navigate = useNavigate()

    return (
        <MainLayoutContainer
            headerComponent={
                <>
                    <Text variant={'h4'}>Calculate emissions</Text>
                </>
            }
        >
            <Stack spacing={{ xs: 4 }}>
                <CtaTile
                    key={`emission-flow-emission-factors`}
                    title={'Browse emission factors'}
                    image={browseEmissionFactors}
                    subTitle={'View our database of emission factors'}
                    onClick={() => {
                        navigate(`/emission-factors`)
                    }}
                />
                {EmissionCalculationFlows.map((e, index) => (
                    <CtaTile
                        key={`emission-flow-${index}`}
                        title={e.name}
                        image={e.logo}
                        subTitle={e.description}
                        onClick={() => {
                            navigate(
                                `/calculate-emissions/${
                                    'slug' in e ? e.slug : EstimateTypeSlug(e.type)
                                }`,
                            )
                        }}
                    />
                ))}
            </Stack>
            <EmissionCalculations scrollRef={scrollRef} />
        </MainLayoutContainer>
    )
}

export default CalculateEmissions
