import {
    SustainabilityPage,
    SustainabilityPageRequest,
    SustainabilityPageStatus,
} from '@lune-climate/lune'
import { Button } from '@lune-fe/lune-ui-lib'
import Check from '@mui/icons-material/Check'
import Box from '@mui/material/Box'
import { useSnackbar } from 'notistack'
import { Dispatch, FC, SetStateAction, useState } from 'react'

import { useLuneClient } from 'hooks/useLuneClient'
import useMixpanel from 'hooks/useMixpanel'
import { SnackbarMessages } from 'SnackbarMessages'

const ActionButtons: FC<{
    options: SustainabilityPage
    setOptions: Dispatch<SetStateAction<SustainabilityPage>>
    setInitialOptions: Dispatch<SetStateAction<SustainabilityPage>>
    isOnEdit: boolean
    setIsOnEdit: Dispatch<SetStateAction<boolean>>
    error: string | undefined
    setError: Dispatch<SetStateAction<string | undefined>>
}> = ({ options, setOptions, setInitialOptions, isOnEdit, setIsOnEdit, error, setError }) => {
    const { enqueueSnackbar: snackbar } = useSnackbar()
    const [saving, setSaving] = useState<boolean>(false)
    const luneClient = useLuneClient()
    const mixpanel = useMixpanel()

    const handleSave = (newOptions: SustainabilityPage) => {
        setSaving(true)
        const action = isOnEdit
            ? luneClient.updateSustainabilityPage(newOptions as SustainabilityPageRequest)
            : luneClient.createSustainabilityPage(newOptions as SustainabilityPageRequest)
        action
            .then((res) => {
                const successMessage = isOnEdit ? 'Updated successfully' : 'Created successfully'
                if (res.isOk()) {
                    snackbar(successMessage)
                    setIsOnEdit(true)
                    setInitialOptions(newOptions)

                    if (options.status === SustainabilityPageStatus.ENABLED) {
                        mixpanel.track('sustainability_page_published')
                    }
                    mixpanel.track('sustainability_page_saved')
                } else {
                    if (
                        'errors' in res.error &&
                        res.error.errors &&
                        res.error.errors[0]?.errorCode === 'sustainability_page_slug_not_unique'
                    ) {
                        setError('Sorry, this URL is already taken')
                    } else {
                        snackbar(SnackbarMessages.GENERIC_ERROR)
                    }
                    setIsOnEdit(false)
                }
                setSaving(false)
            })
            .catch(() => {
                snackbar(SnackbarMessages.GENERIC_ERROR)
                setSaving(false)
            })
    }

    const togglePageStatus = () => {
        const newOptions = {
            ...options,
            status:
                options.status === SustainabilityPageStatus.ENABLED
                    ? SustainabilityPageStatus.DISABLED
                    : SustainabilityPageStatus.ENABLED,
        }
        setOptions(newOptions)
        handleSave(newOptions)
    }

    return (
        <Box
            sx={{
                flex: '1 0 auto',
                minWidth: '170px',
                textAlign: 'right',
            }}
        >
            <Button disabled={!isOnEdit} variant={'text'} sx={{ mr: 1 }} onClick={togglePageStatus}>
                {options.status === SustainabilityPageStatus.ENABLED ? 'Disable' : 'Enable'}
            </Button>
            <Button
                type={'submit'}
                disabled={!!error}
                leftIcon={<Check />}
                onClick={() => handleSave(options)}
                loading={saving}
            >
                Save
            </Button>
        </Box>
    )
}

export default ActionButtons
