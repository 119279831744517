export enum queryKeys {
    GET_USER = `getUser`,
    GET_ACCOUNTS = `getAccounts`,
    GET_BUNDLE_SELECTION = `getBundleSelection`,
    GET_USER_ORGANISATIONS = `getUserOrganisations`,
    EMISSION_RESULTS_QUOTE = `emissionResultsQuote`,
    EMISSION_CALCULATION_RESULTS = `emissionsResults`,
    GET_ORGANISATION = 'getOrganisation',
    GET_ORGANISATION_USERS = 'getOrganisationUsers',
    GET_BUNDLES = 'getBundles',
    GET_PROJECTS = 'getProjects',
    GET_PROJECT_BY_SLUG = 'getProjectBySlug',
    GET_BUNDLE_PORTFOLIOS = 'getBundlePortfolios',
    GET_API_KEYS = 'getApiKeys',
    GET_WEBHOOKS = 'getWebhooks',
    PAYMENT_METHODS_KEY = `paymentMethods`,
    GET_ACTIVE_ACCOUNT_FOR_TEST_SWITCH = `getActiveAccountForTestSwitch`,
    GET_ORDERS = `getOrders`,
    GET_CLIENT_ACCOUNTS = `getClientAccounts`,
    GET_ACTIVE_ACCOUNT = `getActiveAccount`,
    HAS_UNSAVED_CHANGES = `hasUnsavedChanges`,
    GET_ESTIMATIONS = `getEstimations`,
    GET_INVOICES = `getInvoices`,
    GET_SECTOR_QUESTIONS = 'getSectorQuestions',
    GET_MULTILEG_EMISSIONS_CALCULATION_RESULTS = 'getMultilegEmissionsCalculationResults',
    GET_ALL_ORGANISATION_ACCOUNTS = 'getAllOrganisationAccounts',
    GET_CSV_SHEETS = 'getCsvSheets',
    GET_PROPOSAL_BY_ID = 'getProposalById',
    GET_PUBLIC_PROPOSAL_BY_ID = 'getPublicProposalById',
    GET_PROPOSALS = 'getProposals',
    RECENTLY_USED_ACCOUNTS = 'getRecentlyUsedAccounts',
    GET_EMISSION_FACTOR_VERSIONS = 'getEmissionFactorVersions',
}

/**
 * Shared query option for staleTime=0. Referencing this object in React Queries (as opposed to defining it inline)
 * helps developers understand the intention of staleTime:0.
 *
 * NOW PAY ATTENTION!!! -> with this option, the query will always be re-fetched when the component is mounted
 * BUT a re-render of the component will only be triggered if the data has changed. You can quickly click back and forth
 * between views, without constantly seeing loading spinners. But if there is new data, the component will still update.
 */
export const ALWAYS_CHECK_FOR_NEW_DATA = {
    staleTime: 0,
}

export const getCalcQueryKeyById = (id: string) => `${queryKeys.EMISSION_CALCULATION_RESULTS}-${id}`
