import { Button, LuneTheme } from '@lune-fe/lune-ui-lib'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/system'
import { MaterialDesignContent, SnackbarProvider as NotistackbarProvider } from 'notistack'
import { useEffect, useMemo } from 'react'

import useKeyPress from 'hooks/useKeyPress'

// eslint-disable-next-line no-undef
const SnackbarProvider = ({ children }: { children: JSX.Element }) => {
    const { typography, palette, spacing } = LuneTheme
    const escClicked = useKeyPress(['Escape'])
    let snackBarProviderRef: NotistackbarProvider | null

    useEffect(() => {
        if (escClicked && snackBarProviderRef) snackBarProviderRef.closeSnackbar()
    })

    const snackbarStyle = useMemo(() => {
        return {
            maxWidth: '200px',
            wordBreak: 'normal',
            fontFamily: typography.fontFamily,
            fontSize: '14px',
            fontWeight: 'normal',
            boxSizing: 'border-box',
            padding: '0',
            paddingRight: spacing(1),
            paddingLeft: spacing(2),
            lineHeight: '17px',
            borderRadius: '8px',
            a: {
                color: 'inherit !important',
                fontStyle: 'normal',
                textDecoration: 'underline',
            },
            '#notistack-snackbar': {
                maxWidth: 'calc(100% - 56px)',
            },
        }
    }, [typography, spacing])

    const StyledMaterialDesignContent = useMemo(
        () =>
            styled(MaterialDesignContent)(() => ({
                '&.notistack-MuiContent-default': {
                    ...snackbarStyle,
                    background: palette.Grey900,
                },
                '&.notistack-MuiContent-error': {
                    ...snackbarStyle,
                    background: palette.Red500,
                },
            })),
        [palette.Grey900, palette.Red500, snackbarStyle],
    )

    return (
        <NotistackbarProvider
            Components={{
                default: StyledMaterialDesignContent,
                error: StyledMaterialDesignContent,
            }}
            dense
            preventDuplicate
            maxSnack={2}
            ref={(snackbar) => {
                snackBarProviderRef = snackbar
            }}
            action={(snackbarKey) => (
                <Button
                    data-testid={`snackbar-close-btn`}
                    iconButton
                    leftIcon={<CloseIcon sx={{ color: 'white' }} />}
                    variant={'text'}
                    onClick={() => snackBarProviderRef?.closeSnackbar(snackbarKey)}
                />
            )}
        >
            {children}
        </NotistackbarProvider>
    )
}

export default SnackbarProvider
