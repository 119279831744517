import type { SxProps } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { FC, ReactNode } from 'react'

export interface NumberStepsProps {
    steps: ReactNode[]
    sx?: SxProps
}

const NumberCircle: FC<{ nr: number }> = ({ nr }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'Blue500',
                width: '24px',
                height: '24px',
                borderRadius: '12px',
            }}
        >
            <Typography
                variant={'body3'}
                sx={{
                    lineHeight: '20px',
                    color: `White`,
                    fontWeight: 700,
                }}
            >
                {nr}
            </Typography>
        </Box>
    )
}

const NumberSteps: FC<NumberStepsProps> = ({ steps, sx }) => {
    return (
        <Box
            sx={{
                ...sx,
            }}
        >
            {steps.map((step, index) => (
                <Box key={index}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            position: 'relative',
                            p: 1,
                        }}
                    >
                        <Box
                            sx={{
                                height: '48px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                flexDirection: 'row',
                                mr: '36px',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    boxSizing: 'border-box',
                                    position: 'relative',
                                    ...(index !== 0 && {
                                        '&:after': {
                                            content: '""',
                                            width: '1px',
                                            height: '32px',
                                            borderWidth: '0',
                                            borderLeft: '1px solid',
                                            borderColor: `Blue500`,
                                            margin: 'auto',
                                            position: 'absolute',
                                            top: '-36px',
                                        },
                                    }),
                                }}
                            >
                                <NumberCircle nr={index + 1} />
                            </Box>
                        </Box>
                        {step}
                    </Box>
                </Box>
            ))}
        </Box>
    )
}

export default NumberSteps
