import Container, { ContainerProps } from '@mui/material/Container'
import { Breakpoint } from '@mui/system'

export interface MainLayoutContainerProps extends ContainerProps {
    headerComponent?: React.ReactNode
    children: React.ReactNode
    maxWidth?: Breakpoint | false
}

/**
 * A layout container for the main content of standard views
 * Provides a maxWidth for a container with your content and center it within the view,
 * so you don't have to worry about padding on x-axis.
 * Adds margins on y-axis;
 * [Story](https://storybook.lune.co/?path=/story/MainLayoutContainer)
 * @param headerComponent - top part of the column (title or back button usually)
 * @param children - your content (the component will NOT provide spacing the children)
 * @param maxWidth - can be used to change the container maxWidth by passing a breakpoint or 'false' to remove it
 * by default is set to 'md' breakpoint
 */
const MainLayoutContainer = ({
    headerComponent,
    children,
    maxWidth = 'md',
    ...rest
}: MainLayoutContainerProps) => {
    return (
        <Container
            maxWidth={maxWidth}
            sx={{
                my: 9,
                px: {
                    xs: 2,
                    sm: 2,
                    md: 2,
                    lg: 0,
                    xl: 0,
                },
            }}
            {...rest}
        >
            <Container
                sx={{
                    mb: 9,
                    px: `0 !important`,
                    maxWidth: '100% !important',
                }}
            >
                {headerComponent}
            </Container>
            {children}
        </Container>
    )
}

export default MainLayoutContainer
