import { Bundle, Error } from '@lune-climate/lune'
import { formatNumbers } from '@lune-fe/lune-components-lib'
import { Button, LuneTheme, Table, Text } from '@lune-fe/lune-ui-lib'
import CloseIcon from '@mui/icons-material/Close'
import { Box } from '@mui/material'
import { Big } from 'big.js'
import _, { isEmpty } from 'lodash'
import { FC, memo, useEffect, useMemo, useState } from 'react'

import useBuyOffsetsState from 'hooks/useBuyOffsetsState'
import { EditType } from 'models/order'
import {
    Allocation,
    BundleWithPercentage,
    BundleWithVolume,
} from 'views/BuyOffsets/BuyOffsetsTypes'
import BundleAmountInput from 'views/BuyOffsets/CartModal/BundleAmountInput'
import BundlePercentageInput from 'views/BuyOffsets/CartModal/BundlePercentageInput'
import BundleListItemCell from 'views/Settings/BundlePicker/BundleListItemCell'

const FormattedVolume = ({ volume }: { volume: number }) => {
    return (
        <>
            {volume < 1
                ? Big(Big(volume).toFixed(6)).toNumber()
                : Big(Big(volume).toFixed(2)).toNumber()}{' '}
            tCO₂
        </>
    )
}

const EmptyAmountPlaceholder = ({
    isRed,
    dataTestId,
    isInvalidPercentageRatioError,
}: {
    isRed?: boolean
    dataTestId?: string
    isInvalidPercentageRatioError?: boolean
}) => (
    <Box
        data-testid={dataTestId}
        sx={{
            color: isRed ? LuneTheme.palette.Red500 : LuneTheme.palette.Grey900,
        }}
    >
        {isInvalidPercentageRatioError ? '-' : '0 tCO₂'}
    </Box>
)

const CartTable: FC<{
    allocation: Allocation[]
    onBundlesVolumeChange: (items: BundleWithVolume[]) => void
    onBundlesPercentageChange: (items: BundleWithPercentage[]) => void
    editable?: boolean
    removeBundle?: (bundle: Bundle) => void
}> = ({ allocation, editable, removeBundle, onBundlesVolumeChange, onBundlesPercentageChange }) => {
    const [isSingleItem, setIsSingleItem] = useState<boolean>(allocation.length === 1)
    const { buyOffsetProps } = useBuyOffsetsState()
    const { orderTotalAmount, editType, error, isCustomBundleVolumeSet, simpleModal } =
        buyOffsetProps
    const { palette } = LuneTheme

    useEffect(() => {
        setIsSingleItem(allocation.length === 1)
    }, [allocation])

    const orderTotalPercentage = useMemo(() => {
        return allocation.reduce((a, b) => Number(a || 0) + Number(b.percentage || 0), 0)
    }, [allocation])

    const isInvalidPercentageRatioError = useMemo(
        () => error?.key === Error.error_code.BUNDLE_SELECTION_RATIOS_INVALID,
        [error],
    )

    return (
        <Table.TableContainer data-testId={'cart-table'}>
            <Table sx={{ minWidth: 550 }}>
                <Table.TableHead>
                    <Table.TableRow>
                        <Table.TableCell colSize={7}>Bundle</Table.TableCell>
                        <Table.TableCell colSize={3} alignEnd align="right">
                            Volume
                        </Table.TableCell>
                        <Table.TableCell colSize={2} alignEnd align="right">
                            % of volume
                        </Table.TableCell>
                    </Table.TableRow>
                </Table.TableHead>
                <Table.TableBody>
                    {allocation.map((item) => {
                        const { bundle, percentage, volume } = item
                        return (
                            <Table.TableRow
                                data-testid={`order-table-row-${bundle.id}`}
                                key={`order-table-row-${bundle.id}`}
                            >
                                <BundleListItemCell bundle={bundle} />
                                <Table.TableCell colSize={3} align="right" alignEnd>
                                    {editable &&
                                    !isSingleItem &&
                                    editType === EditType.EDIT_BY_VOLUME ? (
                                        <BundleAmountInput
                                            allocation={allocation}
                                            bundle={bundle}
                                            volume={volume}
                                            onBundlesVolumeChange={onBundlesVolumeChange}
                                        />
                                    ) : (
                                        <Text
                                            variant={`body2`}
                                            data-testid={`readonly-bundle-volume-input-${bundle.id}`}
                                        >
                                            {error ? (
                                                <EmptyAmountPlaceholder
                                                    isInvalidPercentageRatioError={
                                                        isInvalidPercentageRatioError
                                                    }
                                                    dataTestId={`bundle-volume-placeholder-dash-${bundle.id}`}
                                                />
                                            ) : (
                                                <FormattedVolume volume={volume} />
                                            )}
                                        </Text>
                                    )}
                                </Table.TableCell>
                                <Table.TableCell colSize={2} align="right" alignEnd>
                                    {editable &&
                                    !isSingleItem &&
                                    editType === EditType.EDIT_BY_PERCENTAGE ? (
                                        <BundlePercentageInput
                                            allocation={allocation}
                                            percentage={isEmpty(percentage) ? `0` : percentage}
                                            bundle={bundle}
                                            onBundlesPercentageChange={onBundlesPercentageChange}
                                        />
                                    ) : (
                                        <Text variant={`body2`}>
                                            {percentage ? formatNumbers(percentage, 2) : 0}%
                                        </Text>
                                    )}
                                </Table.TableCell>
                                <Table.TableCell colSize={1} align="right" alignEnd>
                                    {editable && !isSingleItem && !simpleModal && (
                                        <Button
                                            iconButton
                                            variant={'text'}
                                            leftIcon={<CloseIcon sx={{ color: palette.Grey500 }} />}
                                            data-testId={`remove-bundle-button-${bundle.id}`}
                                            onClick={() => removeBundle?.(bundle)}
                                        />
                                    )}
                                </Table.TableCell>
                            </Table.TableRow>
                        )
                    })}
                    {!isSingleItem && (
                        <Table.TableRow key={'order-table-row-total'}>
                            <Table.TableCell
                                colSize={3}
                                sx={{
                                    paddingLeft: 10,
                                }}
                            >
                                {error?.key ===
                                    Error.error_code.BUNDLE_SELECTION_RATIOS_INVALID && (
                                    <Text
                                        variant={'body2'}
                                        data-testid={'invalid-ratio-error'}
                                        sx={{
                                            whiteSpace: 'nowrap',
                                            color: palette.Red500,
                                            textAlign: 'left',
                                        }}
                                    >
                                        {error.message}
                                    </Text>
                                )}
                            </Table.TableCell>
                            <Table.TableCell colSize={2} align="right" alignEnd>
                                <Text
                                    variant={'body2'}
                                    sx={{
                                        ...(isCustomBundleVolumeSet && {
                                            paddingRight: '12px',
                                        }),
                                    }}
                                >
                                    {error && (
                                        <EmptyAmountPlaceholder
                                            dataTestId={`total-amount-error-placeholder`}
                                            isRed={isInvalidPercentageRatioError}
                                        />
                                    )}
                                    {!error && <FormattedVolume volume={orderTotalAmount || 0} />}
                                </Text>
                            </Table.TableCell>
                            <Table.TableCell colSize={1} align="right" alignEnd>
                                <Text
                                    variant={'body2'}
                                    sx={{
                                        color:
                                            error?.key ===
                                            Error.error_code.BUNDLE_SELECTION_RATIOS_INVALID
                                                ? palette.Red500
                                                : `initial`,
                                    }}
                                >
                                    {formatNumbers(orderTotalPercentage, 2)}%
                                </Text>
                            </Table.TableCell>
                        </Table.TableRow>
                    )}
                </Table.TableBody>
            </Table>
        </Table.TableContainer>
    )
}

export default memo(CartTable, (prev, next) => {
    return _.isEqual(prev.allocation, next.allocation)
})
