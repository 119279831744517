import { Stepper, StepState } from '@lune-fe/lune-ui-lib'
import { FC } from 'react'

export enum Step {
    RECEIVED,
    PLACED,
    PAID,
    RETIRING,
    COMPLETE,
    FAILED,
    CANCELLED,
}

const Order: FC<{ orderStatusStep: Step }> = ({ orderStatusStep }) => {
    return (
        <>
            {[Step.FAILED, Step.CANCELLED].includes(orderStatusStep) ? (
                <Stepper
                    steps={[
                        {
                            label: 'Received',
                            state: StepState.COMPLETED,
                        },
                        {
                            label: orderStatusStep === Step.CANCELLED ? 'Cancelled' : 'Failed',
                            state: StepState.CANCELED,
                        },
                    ]}
                    sx={{
                        width: '210px',
                    }}
                />
            ) : (
                <Stepper
                    steps={[
                        {
                            label: 'Received',
                            state: StepState.COMPLETED,
                        },
                        {
                            label: 'Placed',
                            state:
                                orderStatusStep === Step.RECEIVED
                                    ? StepState.ACTIVE
                                    : StepState.COMPLETED,
                        },
                        {
                            label: 'Paid',
                            state:
                                orderStatusStep === Step.PLACED
                                    ? StepState.ACTIVE
                                    : orderStatusStep < Step.PLACED
                                      ? StepState.PENDING
                                      : StepState.COMPLETED,
                        },
                        {
                            label: orderStatusStep !== Step.COMPLETE ? 'Retiring' : 'Completed',
                            state: [Step.PAID, Step.RETIRING].includes(orderStatusStep)
                                ? StepState.ACTIVE
                                : orderStatusStep < Step.PAID
                                  ? StepState.PENDING
                                  : StepState.COMPLETED,
                        },
                    ]}
                    sx={{
                        width: '500px',
                    }}
                />
            )}
        </>
    )
}

export default Order
