import { Project } from '@lune-climate/lune'
import HistoryToggleOffOutlinedIcon from '@mui/icons-material/HistoryToggleOffOutlined'
import RestoreOutlinedIcon from '@mui/icons-material/RestoreOutlined'
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined'
import { useMemo } from 'react'

import CarbonCreditsListItem from 'views/Projects/Project/CarbonCredits/CarbonCreditsListItem'

export type OffsetDelivery = NonNullable<Project['carbonCredits']>['offsetDelivery']

const CarbonCreditType = ({ offsetDeliveryType }: { offsetDeliveryType: OffsetDelivery }) => {
    const offsetTypeCopy = useMemo(() => {
        if (offsetDeliveryType === `ex_ante`) {
            return {
                icon: UpdateOutlinedIcon,
                title: `Ex-ante credits`,
                description: `Carbon removal or emissions avoidance that will take place over the coming year(s)`,
            }
        }
        if (offsetDeliveryType === `ex_post`) {
            return {
                icon: RestoreOutlinedIcon,
                title: `Ex-post credits`,
                description: `Carbon removal or emissions avoidance that has already taken place and has been measured`,
            }
        }
        return {
            icon: HistoryToggleOffOutlinedIcon,
            title: `Pre-purchase credits`,
            description: `Funding project that will remove carbon or avoid emissions in the future`,
        }
    }, [offsetDeliveryType])

    return (
        <CarbonCreditsListItem
            Icon={offsetTypeCopy.icon}
            title={offsetTypeCopy.title}
            description={offsetTypeCopy.description}
        />
    )
}

export default CarbonCreditType
