import { OrderByEstimate, OrderByQuantity, OrderByValue } from '@lune-climate/lune'
import { Text } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import { useMemo } from 'react'

import { Step } from 'views/Orders/Order/OrderStatus'

const OrderTitle = ({
    orderStatusStep,
    user,
    retiredCredits,
    order,
}: {
    orderStatusStep: Step
    user: any
    retiredCredits: number
    order: OrderByQuantity | OrderByValue | OrderByEstimate
}) => {
    const orderTitle = useMemo(() => {
        switch (orderStatusStep) {
            case Step.RECEIVED:
                return (
                    <>
                        <Text variant={'h4'}>Thank you, {user?.firstname}</Text>
                        <Text variant={'h4'}>Your order is being placed.</Text>
                    </>
                )
            case Step.PLACED:
                if (order.paymentMethod === 'invoice') {
                    return <Text variant={'h4'}>Please pay this order with your next invoice.</Text>
                } else {
                    return (
                        <Text variant={'h4'}>We&apos;re in the process of charging your card.</Text>
                    )
                }
            case Step.PAID:
            case Step.RETIRING:
                return (
                    <Text variant={'h4'}>
                        {retiredCredits}/{order.quantity} credits have been retired
                    </Text>
                )
            case Step.COMPLETE:
                return (
                    <>
                        <Text variant={'h4'}>
                            {order.quantity}/{order.quantity} credits have been retired.
                        </Text>
                        <Text variant={'h4'}>This order is complete.</Text>
                    </>
                )
            case Step.CANCELLED:
                return <Text variant={'h4'}>Your order has been cancelled.</Text>
            case Step.FAILED:
                return <Text variant={'h4'}>There was an issue with your order.</Text>
        }
    }, [order, orderStatusStep, retiredCredits, user])

    return <Box sx={{ mb: 9 }}>{orderTitle}</Box>
}

export default OrderTitle
