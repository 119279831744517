import {
    AnalyticsMetrics,
    SustainabilityPage,
    SustainabilityPageDescription,
} from '@lune-climate/lune'
import { formatNumbers } from '@lune-fe/lune-components-lib'
import { LabeledFormSection, RadioButtonGroup, Textarea } from '@lune-fe/lune-ui-lib'
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'

import { useLuneClient } from 'hooks/useLuneClient'
import useUserState from 'hooks/useUserState'
import { TotalPlacedAnalytics } from 'views/SustainabilityPage/SustainabilityPageSettings'

const Description: FC<{
    options: SustainabilityPage
    setOptions: Dispatch<SetStateAction<SustainabilityPage>>
    analytics: TotalPlacedAnalytics
}> = ({ options, setOptions, analytics }) => {
    const FLIGHT_LND_NYC = 1.05
    const NO_DESCRIPTION = 'noDescription'

    const { userState } = useUserState()
    const [metrics, setMetrics] = useState<AnalyticsMetrics>({
        numberOfProjects: 0,
        numberOfUnSdgs: 0,
        unSdg: [],
    })
    const luneClient = useLuneClient()

    const customDescriptionInputRef: any = useRef(null)

    const loadMetrics = useCallback(async () => {
        const metrics = (await luneClient.getMetrics()).unwrap()
        setMetrics(metrics)
    }, [luneClient])

    useEffect(() => {
        loadMetrics()
    }, [loadMetrics, userState])

    return (
        <LabeledFormSection title={`Description`} subTile={`Optional`}>
            <RadioButtonGroup
                name={'Description type'}
                value={options.description || NO_DESCRIPTION}
                defaultValue={NO_DESCRIPTION}
                items={[
                    {
                        label: 'No description',
                        value: NO_DESCRIPTION,
                    },
                    {
                        label: `That’s an equivalent impact to ${formatNumbers(
                            Math.round(analytics.totalPlacedOffsetQuantity / FLIGHT_LND_NYC),
                        )} one-way flights from NYC to London.`,
                        value: SustainabilityPageDescription.BY_EQUIVALENT,
                    },
                    {
                        label: `Also supporting ${metrics.numberOfProjects} UN Sustainability Development Goals across ${metrics.numberOfProjects} projects.`,
                        value: SustainabilityPageDescription.BY_SUPPORTED_GOALS,
                    },
                    {
                        label: (
                            <Textarea
                                onFocus={() => {
                                    setOptions((prev) => {
                                        return {
                                            ...prev,
                                            description:
                                                SustainabilityPageDescription.BY_CUSTOM_DESCRIPTION,
                                        }
                                    })
                                }}
                                inputRef={customDescriptionInputRef}
                                placeholder="Custom Description"
                                rows={5}
                                sx={{ width: '100%' }}
                                value={options.customDescription ?? undefined}
                                onChange={(val) =>
                                    setOptions((prev) => {
                                        return {
                                            ...prev,
                                            customDescription: val.target.value,
                                        }
                                    })
                                }
                            />
                        ),
                        value: SustainabilityPageDescription.BY_CUSTOM_DESCRIPTION,
                    },
                ]}
                onChange={(val) => {
                    const selectedValue = (val.target as HTMLInputElement).value
                    if (selectedValue === SustainabilityPageDescription.BY_CUSTOM_DESCRIPTION) {
                        customDescriptionInputRef.current?.focus()
                    }
                    setOptions((prev) => {
                        return {
                            ...prev,
                            description:
                                selectedValue === NO_DESCRIPTION
                                    ? undefined
                                    : (selectedValue as any as SustainabilityPageDescription),
                        } as SustainabilityPage
                    })
                }}
            />
        </LabeledFormSection>
    )
}

export default Description
