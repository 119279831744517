import {
    BackButton,
    BannerMessage,
    LabeledFormSection,
    LuneTheme,
    MainLayoutContainer,
    Text,
    Tile,
} from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import { Big } from 'big.js'
import { RefObject, useEffect, useMemo } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'

import useAccountCurrency from 'hooks/useAccountCurrency'
import useBuyOffsetsState from 'hooks/useBuyOffsetsState'
import useHasUnsavedChanges from 'hooks/useHasUnsavedChanges'
import useIsTestMode from 'hooks/useIsTestMode'
import BillingInfo from 'views/BillingInfo/BillingInfo'
import Beneficiary from 'views/BuyOffsets/OrderSummary/Beneficiary/Beneficiary'
import OrderSummaryTable from 'views/BuyOffsets/OrderSummary/OrderSummaryTable'
import OrderSummaryTableFooter from 'views/BuyOffsets/OrderSummary/OrderSummaryTableFooter'
import PaymentInfoWarnings from 'views/BuyOffsets/OrderSummary/PaymentInfoWarnings'
import PlaceOrder from 'views/BuyOffsets/OrderSummary/PlaceOrder'
import PaymentMethod from 'views/PaymentMethod/PaymentMethod'

const OrderSummary = ({ scrollRef }: { scrollRef: RefObject<HTMLDivElement> }) => {
    const { setHasUnsavedChangesState } = useHasUnsavedChanges()
    const navigate = useNavigate()
    const toCurrency = useAccountCurrency()
    const { palette } = LuneTheme
    const isTestMode = useIsTestMode()
    const { buyOffsetProps, resetBuyOffsetProps, setBuyOffsetProps } = useBuyOffsetsState()
    const { quote, selectedPortfolioId } = buyOffsetProps

    const isEmptyOrder = useMemo(() => buyOffsetProps.quote === undefined, [buyOffsetProps])

    useEffect(() => {
        scrollRef.current!.scrollTo({ top: 0 })
    }, [scrollRef])

    const getQuantityPerBundle = (bundleId: string) => {
        return Number(quote?.bundles.find((b) => b.bundleId === bundleId)?.quantity) || 0
    }

    useEffect(() => {
        if (!isEmptyOrder) {
            setHasUnsavedChangesState(true, resetBuyOffsetProps)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEmptyOrder])

    return (
        <>
            <MainLayoutContainer
                headerComponent={
                    <>
                        <BackButton
                            sx={{ mb: 3, ml: -2 }}
                            onClick={() => {
                                setHasUnsavedChangesState(false)
                                setBuyOffsetProps({
                                    isCustomPortfolioCartOpen: !selectedPortfolioId,
                                })
                                navigate(-1)
                            }}
                        />
                        <Text variant="h4" sx={{ marginBottom: 1 }}>
                            Order Summary
                        </Text>
                    </>
                }
            >
                <Stack spacing={9}>
                    <OrderSummaryTable
                        bundlesInfo={buyOffsetProps.allocation.map((a) => ({
                            id: a.bundle.id,
                            primaryImage: a.bundle.primaryImage!,
                            name: a.bundle.name,
                            quantity: getQuantityPerBundle(a.bundle.id),
                            grossUnitPrice: Big(a.bundle.grossUnitPrice),
                        }))}
                    />
                    {buyOffsetProps.quote && (
                        <OrderSummaryTableFooter
                            fees={buyOffsetProps.quote.estimatedCommission}
                            quantity={buyOffsetProps.quote.estimatedQuantity}
                            cost={buyOffsetProps.quote.estimatedOffsetCost}
                        />
                    )}
                    <Divider />
                    {!isTestMode && (
                        <span data-testid={`payment-section`}>
                            <LabeledFormSection title={`Payment`}>
                                <BillingInfo />
                                <PaymentMethod />
                            </LabeledFormSection>
                        </span>
                    )}
                    <LabeledFormSection
                        title={`Beneficiary`}
                        subTile={
                            <div>
                                <div>
                                    The beneficiary is the legal entity shown on the Carbon Offset
                                    Certificates.
                                </div>
                                <div>
                                    It will also appear on the carbon registries (e.g. Verra).
                                </div>
                            </div>
                        }
                    >
                        <Beneficiary />
                    </LabeledFormSection>
                    <Divider />

                    <Box>
                        {isTestMode && (
                            <BannerMessage
                                sx={{
                                    mb: 4,
                                }}
                                message={`This is a Test order, you will not be charged! When ready to place a Live order, switch the Test mode toggle in the bottom-left corner.`}
                            />
                        )}

                        {!isTestMode && <PaymentInfoWarnings />}

                        <Tile
                            rightSide={
                                <Text
                                    data-testid={`total-cost`}
                                    sx={{
                                        color: palette.Grey900,
                                        fontWeight: `700`,
                                        fontSize: `16px`,
                                    }}
                                >
                                    {toCurrency?.(buyOffsetProps.quote?.estimatedTotalCost)}
                                </Text>
                            }
                        >
                            <Text
                                variant={'body2'}
                                sx={{ color: palette.Grey900, fontWeight: `700`, fontSize: `16px` }}
                            >
                                Total
                            </Text>
                        </Tile>
                    </Box>
                    <PlaceOrder />
                </Stack>
            </MainLayoutContainer>
            {isEmptyOrder && <Navigate to={'/order'} />}
        </>
    )
}

export default OrderSummary
