import { LoadingWrapper, NoResultsPlaceholder, Table, Text } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'

import useIsTestMode from 'hooks/useIsTestMode'
import useWebhooks from 'hooks/useWebhooks'
import WebhooksTableRow from 'views/Developers/Webhooks/WebhooksTableRow'

const WebhooksTable = () => {
    const isTestMode = useIsTestMode()
    const { isLoadingWebhooks, webhooks } = useWebhooks({
        refetchOnMount: true,
    })

    return (
        <LoadingWrapper loading={isLoadingWebhooks} sx={{ height: '554px', mt: 3 }}>
            {webhooks.length === 0 ? (
                <Box sx={{ height: `554px`, mt: 3 }}>
                    <NoResultsPlaceholder
                        message={
                            <Text
                                variant={`h6`}
                                sx={{
                                    textAlign: 'center',
                                    maxWidth: '250px',
                                }}
                            >
                                You don&apos;t have any Webhooks yet
                            </Text>
                        }
                    />
                </Box>
            ) : (
                <Table.TableContainer sx={{ mt: 3 }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <Table.TableHead>
                            <Table.TableRow>
                                <Table.TableCell colSize={7}>
                                    {isTestMode ? `Test` : `Live`} webhook
                                </Table.TableCell>
                                <Table.TableCell colSize={2} align="right">
                                    Secret
                                </Table.TableCell>
                                <Table.TableCell colSize={2} alignEnd align="right">
                                    Created
                                </Table.TableCell>
                            </Table.TableRow>
                        </Table.TableHead>
                        <Table.TableBody>
                            {webhooks.map((k, index) => (
                                <WebhooksTableRow item={k} key={`wh-${index}`} />
                            ))}
                        </Table.TableBody>
                    </Table>
                </Table.TableContainer>
            )}
        </LoadingWrapper>
    )
}

export default WebhooksTable
