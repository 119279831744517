import { PassengerTransportationEmissionEstimate } from '@lune-climate/lune'
import { BannerMessage, Select } from '@lune-fe/lune-ui-lib'
import AirplanemodeActiveOutlinedIcon from '@mui/icons-material/AirplanemodeActiveOutlined'
import Co2OutlinedIcon from '@mui/icons-material/Co2Outlined'
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined'
import PersonIcon from '@mui/icons-material/PersonOutlined'
import TrainOutlinedIcon from '@mui/icons-material/TrainOutlined'
import { Stack } from '@mui/material'
import { FC, useMemo, useState } from 'react'

import { Divider, RequestAndResponse } from '../components'
import {
    CalculationResults,
    InputsWrapper,
    PassengerTransportationEmissionExplanation,
    PassengerTransportExplanationButtonGroup,
    ResultLabeledSectionAndTiles,
} from '../components/emissionsCalculationExplanation'
import { buildRouteTiles } from '../components/emissionsCalculationExplanation/utils/buildLogisticsBreakdownTile'
import { getFormattedAmount } from '../utils/utils'

// useful for shortening types
type Request = PassengerTransportationEmissionEstimate['request']
type RequestLeg = Request['legs'][number]

interface PassengerTransportationEstimateResultProps {
    estimate: PassengerTransportationEmissionEstimate & { _meta: { response: unknown } }
    enableEmissionFactorLink: boolean
    isMobile: boolean
    requestAndResponseSnippets?: {
        isTestMode?: boolean
        testApiKey?: string
    }
}

function getIcon(leg: RequestLeg): JSX.Element {
    if ('vehicleType' in leg) {
        return <DirectionsCarFilledOutlinedIcon />
    }
    if ('railType' in leg) {
        return <TrainOutlinedIcon />
    }
    if ('cabinClass' in leg) {
        return <AirplanemodeActiveOutlinedIcon />
    }
    throw new Error('Unexpected leg type')
}

function getPassengerTransportationPassengerInfoLabel(request: Request): string | null {
    if (!('passengers' in request)) {
        return null
    }

    const passenger = request.passengers ?? 1
    return passenger === 1 ? '1 passenger' : `${passenger} passengers`
}

// eslint-disable-next-line complexity
function getPassengerTransportationVehicleTypeLabel(requestLeg: RequestLeg): string {
    if ('vehicleType' in requestLeg) {
        switch (requestLeg.vehicleType) {
            case 'car_mini':
                return 'Car, mini'
            case 'car_supermini':
                return 'Car, supermini'
            case 'car_lower_medium':
                return 'Car, lower medium'
            case 'car_upper_medium':
                return 'Car, upper medium'
            case 'car_executive':
                return 'Executive car'
            case 'car_luxury':
                return 'Car, luxury'
            case 'car_sports':
                return 'Sports car'
            case 'car_dual_purpose_4x4':
                return 'Car, dual purpose 4x4'
            case 'car_mpv':
                return 'Car MPV'
            case 'motorcycle_small':
                return 'Motorbike - Small'
            case 'motorcycle_medium':
                return 'Motorbike - Medium'
            case 'motorcycle_large':
                return 'Motorbike - Large'
            case 'motorcycle_average':
                return 'Motorbike - Average'
        }
    }

    if ('railType' in requestLeg) {
        switch (requestLeg.railType) {
            case 'national_rail':
                return 'National rail'
            case 'international_rail':
                return 'International rail'
            case 'light_rail_or_tram':
                return 'Light rail or tram'
        }
    }

    return 'Passeger flight'
}

const PassengerTransportInputSection = ({
    request,
    requestLeg,
}: {
    request: Request
    requestLeg: RequestLeg
}) => {
    const vehicleIcon = getIcon(requestLeg)
    const passengerInfo = getPassengerTransportationPassengerInfoLabel(request)
    const tiles = [
        ...(passengerInfo ? [{ icon: <PersonIcon />, label: passengerInfo }] : []),
        {
            icon: vehicleIcon,
            label: getPassengerTransportationVehicleTypeLabel(requestLeg),
        },
        ...buildRouteTiles(requestLeg.route),
    ]
    return (
        <InputsWrapper>
            <ResultLabeledSectionAndTiles tiles={tiles} />
        </InputsWrapper>
    )
}

export const PassengerTransportationEstimateResult: FC<
    PassengerTransportationEstimateResultProps
> = ({ estimate, isMobile, enableEmissionFactorLink, requestAndResponseSnippets }) => {
    if (
        typeof estimate._meta.response !== 'object' ||
        estimate._meta.response === null ||
        !('request' in estimate._meta.response)
    ) {
        throw new Error('Unexpected _meta object')
    }

    const [activeTabIndex, setActiveTabIndex] = useState<number>(1)

    const activeLegFormattedAmount = useMemo(() => {
        const leg = estimate.legs[Number(activeTabIndex) - 1]
        return getFormattedAmount(leg.mass.amount)
    }, [activeTabIndex, estimate])

    return (
        <>
            {isMobile && (
                <Select
                    value={activeTabIndex}
                    items={estimate.request.legs.map((_leg: RequestLeg, index: number) => ({
                        value: index + 1,
                        label: `Leg ${index + 1}`,
                    }))}
                    onChange={(value: number) => setActiveTabIndex(value)}
                />
            )}
            <CalculationResults
                title={estimate.request.name || 'Passenger transport'}
                amount={estimate.mass.amount}
            />
            <Stack direction={'column'} spacing={9} mt={9}>
                {!isMobile && (
                    <PassengerTransportExplanationButtonGroup
                        request={estimate.request}
                        setActiveTab={setActiveTabIndex}
                        activeTab={activeTabIndex}
                    />
                )}
                <ResultLabeledSectionAndTiles
                    label={'Emissions for this leg'}
                    tiles={[
                        {
                            icon: <Co2OutlinedIcon />,
                            label: `${activeLegFormattedAmount.amountAsNumber.toFixed(2)} ${
                                activeLegFormattedAmount.amountUnit
                            }CO₂e`,
                        },
                    ]}
                />
                {estimate.legs[activeTabIndex - 1].emissionFactor ? (
                    <>
                        <Divider />
                        <PassengerTransportationEmissionExplanation
                            estimate={estimate}
                            enableEmissionFactorLink={enableEmissionFactorLink}
                            leg={activeTabIndex - 1}
                        />
                    </>
                ) : (
                    <BannerMessage
                        sx={{ mb: 1 }}
                        message={`Unfortunately we don't have Emission Factor and calculation data for legacy estimates.`}
                    />
                )}
                <Divider />
                <PassengerTransportInputSection
                    request={estimate.request}
                    requestLeg={estimate.request.legs[activeTabIndex - 1]}
                />
                {requestAndResponseSnippets && (
                    <>
                        <Divider />
                        <RequestAndResponse
                            path={`/v1/estimates/passenger-transporation`}
                            request={(estimate._meta.response as { request: unknown }).request}
                            response={estimate._meta.response}
                            idempotencyKey={
                                (estimate._meta.response as { idempotency_key?: string })
                                    .idempotency_key
                            }
                            metadata={
                                (
                                    estimate._meta.response as {
                                        metadata?: Record<string, string>
                                    }
                                ).metadata
                            }
                            isTestMode={requestAndResponseSnippets.isTestMode}
                            testApiKey={requestAndResponseSnippets.testApiKey}
                        />
                    </>
                )}
            </Stack>
        </>
    )
}

export default PassengerTransportationEstimateResult
