import { CurrencyPicker, LabeledFormSection, Tooltip } from '@lune-fe/lune-ui-lib'
import Stack from '@mui/material/Stack'
import { Dispatch, FC, memo, ReactElement, SetStateAction, useCallback } from 'react'
import { Field, Form } from 'react-final-form'

import { AccountFormKeys, IAccountFormData } from 'components/AccountForm/AccountFormWrapper'
import { FormInput } from 'components/FormInput'

const required = (value: string | undefined) =>
    value?.trim() ? undefined : 'This is a required field'

const NotAllowedToEditTestAccountNameTooltip = ({
    children,
    contentWidth,
}: {
    children: ReactElement
    contentWidth?: boolean
}) => (
    <Tooltip
        title="Updating a test account's name is not allowed."
        placement="top"
        contentWidth={contentWidth}
    >
        {children}
    </Tooltip>
)

const AccountForm = ({
    onSubmitForm,
    initialValues,
    readOnly,
    disabledFields,
    existingAccount,
    setFormValue,
    forbiddenTooltip,
}: {
    onSubmitForm: (data: IAccountFormData) => Promise<any>
    disabledFields?: AccountFormKeys[]
    existingAccount?: boolean
    readOnly?: boolean
    initialValues: IAccountFormData
    setFormValue: Dispatch<SetStateAction<IAccountFormData | undefined>>
    forbiddenTooltip?: FC<{ children: ReactElement; contentWidth?: boolean }>
}) => {
    // To prevent rerendering when the other form elements change define the component here
    const CurrencyPickerComponent = useCallback(
        ({ input: { onChange, value }, meta }: any) => (
            <CurrencyPicker
                data-testid="account-currency-picker"
                error={meta.touched && meta.error}
                value={value}
                disabled={readOnly || existingAccount}
                onChange={onChange}
            />
        ),
        [readOnly, existingAccount],
    )
    return (
        <Form
            onSubmit={onSubmitForm}
            initialValues={initialValues}
            render={({ handleSubmit, values }) => {
                setFormValue(values)
                return (
                    <form style={{ position: `relative`, zIndex: 2 }} onSubmit={handleSubmit}>
                        <Stack
                            direction="column"
                            sx={{
                                mb: 9,
                                width: '100%',
                            }}
                            spacing={{
                                xs: 9,
                            }}
                        >
                            <LabeledFormSection
                                title={'Name'}
                                subTile={!readOnly ? 'E.g.EMEA, GBP...' : ''}
                            >
                                <Field
                                    disabled={
                                        readOnly || disabledFields?.includes(AccountFormKeys.NAME)
                                    }
                                    name={'name'}
                                    placeholder={'Name'}
                                    component={FormInput}
                                    validate={required}
                                    tooltip={
                                        disabledFields?.includes(AccountFormKeys.NAME)
                                            ? NotAllowedToEditTestAccountNameTooltip
                                            : forbiddenTooltip
                                    }
                                />
                            </LabeledFormSection>
                            <LabeledFormSection
                                title={'Currency'}
                                subTile={
                                    !existingAccount && !readOnly
                                        ? `You cannot change this later.`
                                        : ``
                                }
                            >
                                <Field
                                    name={'currency'}
                                    validate={required}
                                    component={CurrencyPickerComponent}
                                    tooltip={forbiddenTooltip}
                                />
                            </LabeledFormSection>
                            <LabeledFormSection
                                title={'Beneficiary'}
                                subTile={
                                    !readOnly ? (
                                        `Overrides the Organisation’s beneficiary.`
                                    ) : (
                                        <>
                                            <span>
                                                The beneficiary will be shown on the Carbon Offset
                                                Certificates.
                                            </span>
                                            <br />
                                            <span>
                                                It will also appear on the carbon registries (e.g.
                                                Verra).
                                            </span>
                                        </>
                                    )
                                }
                            >
                                <Field
                                    disabled={readOnly}
                                    name={'beneficiary'}
                                    placeholder={'Beneficiary'}
                                    component={FormInput}
                                    tooltip={forbiddenTooltip}
                                />
                            </LabeledFormSection>
                        </Stack>
                    </form>
                )
            }}
        />
    )
}

export default memo(AccountForm, (prev, next) => {
    return JSON.stringify(prev.initialValues) === JSON.stringify(next.initialValues)
})
