import { Bundle, PaginatedBundles } from '@lune-climate/lune'
import { sortBy } from 'lodash'
import { useQuery } from 'react-query'

import { useLuneClient } from 'hooks/useLuneClient'
import { queryKeys } from 'queryKeys'

const BUNDLES_LIMIT = '100'

const useBundles = () => {
    const luneClient = useLuneClient()
    const { isLoading, data: bundles = [] } = useQuery<Bundle[]>(
        queryKeys.GET_BUNDLES,
        async () => {
            let hasMoreLocal = true
            let localBundles: Bundle[] = []
            while (hasMoreLocal) {
                const response = (await luneClient.listBundles({ limit: BUNDLES_LIMIT })).unwrap()
                const { data, hasMore }: PaginatedBundles = response
                hasMoreLocal = hasMore
                localBundles = sortBy([...localBundles, ...data], (b: Bundle) => b.name)
            }
            return localBundles
        },
    )

    return {
        loading: isLoading,
        bundles,
    }
}

export default useBundles
