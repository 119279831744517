import { Bundle } from '@lune-climate/lune'
import { Button, LoadingWrapper, Text } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import _ from 'lodash'
import { RefObject, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import BundleCard from 'components/BundleCard'
import BundlesLayout from 'components/BundlesLayout'
import useAccounts from 'hooks/useAccounts'
import useBundles from 'hooks/useBundles'
import useMixpanel from 'hooks/useMixpanel'
import useQuery from 'hooks/useQuery'
import useScrollRestoration, { ScrollPositionKeys } from 'hooks/useScrollRestoration'
import BundleFiltersBox, {
    AllBundleFilters,
    Approach,
    CarbonPermanence,
    CertificationType,
    OffsetType,
} from 'views/Projects/ProjectBundles/BundleFiltersBox'
import { BundlesView } from 'views/Projects/ProjectBundles/ListMapSwitcher'
import ProjectsMap from 'views/Projects/ProjectBundles/ProjectsMap'

const ProjectBundles = ({
    scrollRef,
    bundles,
}: {
    scrollRef: RefObject<HTMLDivElement>
    bundles: Bundle[]
}) => {
    const mixpanel = useMixpanel()
    const navigate = useNavigate()
    const { activeAccount } = useAccounts()
    const { restoreScroll, saveScrollPosition } = useScrollRestoration()
    const [mapView] = useQuery('mapView')
    const [view, setView] = useState<string>(
        mapView ? BundlesView.BUNDLES_MAP : BundlesView.BUNDLES_LIST,
    )
    const [allFilters, setAllFilters] = useState<AllBundleFilters>()
    const [minMaxBundlePrice, setMinMaxBundlePrice] = useState<[number, number]>()
    const [filteredBundles, setFilteredBundles] = useState<Bundle[]>([])
    const [queryParams, setQueryParams] = useState<string>()

    const roundDownTo5 = (x: number) => {
        return Math.floor(x / 5) * 5
    }

    const roundUpTo5 = (x: number) => {
        return Math.ceil(x / 5) * 5
    }

    const filterByOffsetType = (b: Bundle, offsetTypeFilters: OffsetType[]) => {
        return (
            !offsetTypeFilters.length ||
            (b.offsetType && offsetTypeFilters.includes(b.offsetType as any as OffsetType))
        )
    }

    const filterByCarbonPermanence = (b: Bundle, carbonPermanenceFilters: CarbonPermanence[]) => {
        return (
            !carbonPermanenceFilters.length ||
            carbonPermanenceFilters.includes(b.carbonPermanence as any as CarbonPermanence)
        )
    }

    const filterByApproach = (b: Bundle, approachFilters: Approach[]) => {
        return (
            !approachFilters.length ||
            (b.approach && approachFilters.includes(b.approach as any as Approach))
        )
    }

    const filterByPriceRange = (b: Bundle, minMaxPriceFilters: number[]) => {
        return (
            !minMaxPriceFilters.length ||
            (Number(b.grossUnitPrice) >= minMaxPriceFilters[0] &&
                Number(b.grossUnitPrice) <= minMaxPriceFilters[1])
        )
    }

    const filterByCertification = (
        b: Bundle,
        certificationFilters: CertificationType[],
        notCertifiedSelected: boolean,
    ) => {
        return (
            !certificationFilters.length ||
            b.projects.some((p) => {
                return (
                    (p.registryName &&
                        certificationFilters.includes(
                            p.registryName as any as CertificationType,
                        )) ||
                    (notCertifiedSelected && !p.registryName)
                )
            })
        )
    }

    const getFilteredBundles = useMemo(() => {
        if (!allFilters) {
            return bundles
        }

        const {
            offsetType: offsetTypeFilters = [],
            carbonPermanence: carbonPermanenceFilters = [],
            certification: certificationFilters = [],
            priceRange: minMaxPriceFilters = [],
            approach: approachFilters = [],
        } = allFilters

        const notCertifiedSelected = certificationFilters.includes(
            CertificationType.NO_CERTIFICATION,
        )

        return [...bundles].filter((b: Bundle) => {
            return (
                filterByOffsetType(b, offsetTypeFilters) &&
                filterByCarbonPermanence(b, carbonPermanenceFilters) &&
                filterByApproach(b, approachFilters) &&
                filterByPriceRange(b, minMaxPriceFilters) &&
                filterByCertification(b, certificationFilters, notCertifiedSelected)
            )
        })
    }, [allFilters, bundles])

    useEffect(() => {
        setFilteredBundles(getFilteredBundles)
    }, [bundles, allFilters, getFilteredBundles])

    useEffect(() => {
        const bundlePrices = bundles.map((b) => Number(b.grossUnitPrice))
        if (bundlePrices.length) {
            const min: number = _.min(bundlePrices) || 0
            const max: number = _.max(bundlePrices) || 1000
            setMinMaxBundlePrice([roundDownTo5(min), roundUpTo5(max)])
        }
    }, [bundles, activeAccount])

    useEffect(() => {
        if (view === BundlesView.BUNDLES_LIST && mapView) {
            navigate('/projects')
        }
    }, [view, navigate, mapView])

    useEffect(() => {
        restoreScroll(scrollRef, ScrollPositionKeys.PROJECT_BUNDLES)
    }, [restoreScroll, scrollRef])

    const handleProjectClick = (projectSlug: string) => {
        mixpanel.track('project_clicked')
        saveScrollPosition(scrollRef, ScrollPositionKeys.PROJECT_BUNDLES)
        navigate(`/projects/${projectSlug}${queryParams ?? ''}`)
    }

    const resetFilters = () => {
        setAllFilters(undefined)
    }

    const EmptyState = () => {
        return (
            <>
                <Text
                    variant={`h6`}
                    sx={{
                        textAlign: 'center',
                        maxWidth: '306px',
                        mb: 2,
                    }}
                >
                    We don’t have any bundles matching your criteria
                </Text>
                <Button
                    sx={{ backgroundColor: `white` }}
                    variant={'outlined'}
                    onClick={resetFilters}
                >
                    Clear all filters
                </Button>
            </>
        )
    }

    return (
        <Box
            sx={{
                ...(view === BundlesView.BUNDLES_MAP && {
                    height: '100vh',
                    overflow: 'hidden',
                }),
            }}
        >
            <BundlesLayout
                emptyState={allFilters && <EmptyState />}
                bundles={filteredBundles}
                header={
                    <>
                        <Box>
                            <Text variant="h4" sx={{ mb: 1 }}>
                                Project bundles
                            </Text>
                            <Text variant="body3" sx={{ color: `Grey700`, display: 'block' }}>
                                Project bundles consist of one or multiple projects with similar
                                characteristics.
                            </Text>
                            <Text variant="body3" sx={{ color: `Grey700`, display: 'block' }}>
                                Once you&apos;ve learned more, you can go ahead and buy offsets!
                            </Text>
                        </Box>

                        {minMaxBundlePrice && (
                            <BundleFiltersBox
                                reset={!allFilters}
                                setQueryParams={setQueryParams}
                                minPrice={minMaxBundlePrice[0]}
                                maxPrice={minMaxBundlePrice[1]}
                                sticky={view === BundlesView.BUNDLES_MAP}
                                onLoad={(filters) => setAllFilters(filters)}
                                view={view}
                                setView={setView}
                            />
                        )}
                    </>
                }
            >
                <BundleCard handleProjectClick={handleProjectClick} />
            </BundlesLayout>
            <ProjectsMap
                emptyState={<EmptyState />}
                queryParams={queryParams}
                view={view}
                bundles={filteredBundles}
            />
        </Box>
    )
}

const ProjectBundlesLoadingWrapper = ({ scrollRef }: { scrollRef: RefObject<HTMLDivElement> }) => {
    const { loading, bundles } = useBundles()
    /**
     * Separation of concerns: this "loading wrapper" only cares about mounting a loading spinner and letting it
     * spin without re-renders until we've loaded the bundles. A re-render during the loading animation looks glitchy:
     * https://linear.app/lune/issue/LUN-2802/dashboard-homepage-loading-spinner-seems-to-glitch
     * This is why we mount LoadingWrapper outside ProjectBundles component with all its effects and state changes
     */
    return (
        <LoadingWrapper
            loading={loading}
            sx={{
                height: `100vh`,
            }}
        >
            <ProjectBundles scrollRef={scrollRef} bundles={bundles} />
        </LoadingWrapper>
    )
}
export default ProjectBundlesLoadingWrapper
