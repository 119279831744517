import { toTitleCase } from '@lune-fe/lune-components-lib'
import { Checkbox, Dropdown, DropdownItem, LuneTheme } from '@lune-fe/lune-ui-lib'
import { SxProps } from '@mui/material'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

export interface FilterProps<T> {
    sx?: SxProps
    setSelectedOptions: Dispatch<SetStateAction<T[]>>
    options: T[]
    filterName: string
    transformLabel?: (label: string) => string
    preselected?: T[]
}

export interface CheckboxItem<T> {
    name: T
    checked: boolean
}

const MultiselectFilter = <T,>({
    sx,
    setSelectedOptions,
    options,
    filterName,
    transformLabel,
    preselected,
}: FilterProps<T>) => {
    const { palette } = LuneTheme
    const [isEmpty, setIsEmpty] = useState<boolean>(true)
    const [buttonLabel, setButtonLabel] = useState<string>(toTitleCase(filterName))
    const [localSelected, setLocalSelected] = useState<CheckboxItem<T>[]>()

    const makeButtonLabel = (items: CheckboxItem<T>[]) => {
        const names = items.filter((item) => item.checked).map((item) => item.name)
        if (!names.length) {
            setButtonLabel(toTitleCase(filterName))
            setIsEmpty(true)
            return
        }
        const extraText = names.length > 1 ? ` + ${names.length - 1}` : ''
        const buttonLabel = `${
            transformLabel ? transformLabel(names[0] as any) : names[0]
        }${extraText}`
        setButtonLabel(buttonLabel)
        setIsEmpty(false)
    }

    function handleChange(selectedItem: CheckboxItem<T>) {
        setLocalSelected((oldItems) => {
            const updatedItems = oldItems?.map((item) => {
                if (item.name === selectedItem.name) {
                    return { name: item.name, checked: !item.checked }
                } else {
                    return item
                }
            })
            makeButtonLabel(updatedItems || [])
            setSelectedOptions(
                updatedItems?.filter((item) => item.checked).map((item) => item.name) || [],
            )
            return updatedItems
        })
    }

    useEffect(() => {
        if (preselected) {
            const selectedOption = options.map((option) => {
                if (preselected.includes(option)) {
                    return { name: option, checked: true }
                } else {
                    return { name: option, checked: false }
                }
            })
            setLocalSelected(selectedOption)
            makeButtonLabel(selectedOption)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preselected])

    return (
        <Dropdown
            closeOnClick={false}
            buttonLabel={buttonLabel}
            labelSx={{ color: isEmpty ? palette.Grey600 : palette.Grey900 }}
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                ...sx,
            }}
        >
            {localSelected?.map((filter, i) => {
                return (
                    <DropdownItem key={i} sx={{ padding: 0, margin: 0 }}>
                        <Checkbox
                            checked={filter.checked}
                            onChange={() => handleChange(filter)}
                            label={
                                transformLabel
                                    ? transformLabel(filter.name as string)
                                    : (filter.name as string)
                            }
                            sx={{
                                width: '100%',
                                height: '48px',
                                padding: '0px 24px',
                            }}
                        />
                    </DropdownItem>
                )
            })}
        </Dropdown>
    )
}

export default MultiselectFilter
