import { Box } from '@mui/material'
import { FC, ReactNode } from 'react'

export const PROPOSAL_OPTION_PREFIX = 'proposal-option'

const ExpandableSection: FC<{
    expanded: boolean
    bundlesList: ReactNode
    bundlesGrid: ReactNode
    optionsLength: number
}> = ({ expanded, bundlesList, bundlesGrid, optionsLength }) => {
    const collapsedHeight = optionsLength * 73 + 2 * 32
    const expandedHeight = 560

    return (
        <>
            <Box
                sx={{
                    pt: 4,
                    pb: 4,
                    opacity: 1,
                    transition: 'opacity 300ms ease-in-out 0ms',
                    pointerEvents: 'all',
                    zIndex: 99,
                    ...(expanded && {
                        opacity: 0,
                        pointerEvents: 'none',
                        zIndex: 0,
                    }),
                }}
            >
                {bundlesList}
            </Box>
            <Box
                sx={{
                    pt: 4,
                    pb: 0,
                    opacity: 0,
                    transition: 'all 300ms ease-in-out 0ms',
                    marginTop: `-${collapsedHeight}px`,
                    pointerEvents: expanded ? 'all' : 'none',
                    height: `${collapsedHeight - 32}px`,
                    '.scroll-container': {
                        transition: 'all 300ms ease-in-out 0ms',
                        height: `${collapsedHeight - 32}px`,
                    },
                    zIndex: 0,
                    ...(expanded && {
                        pb: 4,
                        opacity: 1,
                        height: `${expandedHeight}px`,
                        zIndex: 99,
                        transition: 'all 300ms ease-in-out 0ms',
                        '.scroll-container': {
                            height: `${expandedHeight}px`,
                        },
                    }),
                }}
            >
                {bundlesGrid}
            </Box>
        </>
    )
}

export default ExpandableSection
