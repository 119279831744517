import {
    AccountCurrencyCode,
    AccountPair,
    CreateAccountRequest,
    CurrencyCode,
} from '@lune-climate/lune'
import { LoadingWrapper, MainLayoutContainer, Text } from '@lune-fe/lune-ui-lib'
import { useSnackbar } from 'notistack'
import { memo, useMemo } from 'react'
import { flushSync } from 'react-dom'
import { useNavigate } from 'react-router-dom'

import AccountFormWrapper, {
    AccountFormKeys,
    IAccountFormData,
} from 'components/AccountForm/AccountFormWrapper'
import NotAdminTooltip from 'components/NotAdminTooltip'
import useAccounts from 'hooks/useAccounts'
import useBundles from 'hooks/useBundles'
import useHasUnsavedChanges from 'hooks/useHasUnsavedChanges'
import useIndividualAccountBundles from 'hooks/useIndividualAccountBundles'
import useIsTestMode from 'hooks/useIsTestMode'
import { useLuneClient } from 'hooks/useLuneClient'
import { SnackbarMessages } from 'SnackbarMessages'
import { CUSTOM_PORTFOLIO_ID } from 'views/Settings/BundlePicker/BundlePicker'

const LATIN_AMERICA_FORESTRY_ID = 'L0M3zv7Qr2OGRqY9WAVdbwKPx5XWao64'
const BIOCHAR_ID = 'P9MBnQK12a3WoJYBKplekjbvgV578NRq'

const CreateAccount = () => {
    const { refetchAccounts, loading: loadingAccounts } = useAccounts()
    const { bundles, loading } = useBundles()
    const navigate = useNavigate()
    const { enqueueSnackbar: snackbar } = useSnackbar()
    const isTestMode = useIsTestMode()
    const { updateCustomBundleSelection, updateBundlePortfolioSelection } =
        useIndividualAccountBundles()
    const { setHasUnsavedChangesState } = useHasUnsavedChanges()
    const luneClient = useLuneClient()
    const onSuccess = (newAccount?: AccountPair) => {
        snackbar(SnackbarMessages.ACCOUNT_CREATE)
        // When you call a state update function, the state does not immediately change, but it's scheduled the to happen sometime in the future
        // flushSync is used for synchronously flushing updates, meaning everything within flushSync is processed right away before moving to the next line of code.
        // The flushSync call is forcing setHasUnsavedChangesState(false) to update synchronously.
        // This means that it is fully executed and completed before the function call returns,
        // ensuring that the state value hasUnsavedChangesState has been updated to false before moving on to the navigate function.
        flushSync(() => {
            setHasUnsavedChangesState(false)
        })
        if (newAccount)
            navigate(`/settings/accounts/${isTestMode ? newAccount.test.id : newAccount.live.id}`)
        refetchAccounts()
    }

    const onCreateAccount = async (data: IAccountFormData) => {
        const newAccount: CreateAccountRequest = {
            name: data.name!,
            beneficiary: data.beneficiary,
            currency: data.currency as any as AccountCurrencyCode,
        }
        const acc = await luneClient.createAccountPair(newAccount).then((r) => r.unwrap())

        const liveAccountId = acc.live.id
        const testAccountId = acc.test.id
        await updateCustomBundleSelection(liveAccountId, data.bundleSettings.customBundleSelection)
        await updateCustomBundleSelection(testAccountId, data.bundleSettings.customBundleSelection)
        const selectedBundlePortfolioId = data.bundleSettings.selectedBundlePortfolioId
        if (selectedBundlePortfolioId !== CUSTOM_PORTFOLIO_ID) {
            await updateBundlePortfolioSelection(liveAccountId, selectedBundlePortfolioId)
            await updateBundlePortfolioSelection(testAccountId, selectedBundlePortfolioId)
        }
        return acc
    }

    const defaultBundleSelection = useMemo(() => {
        if (!bundles.length) {
            return undefined
        }

        if (
            bundles.find((b) => b.id === LATIN_AMERICA_FORESTRY_ID) &&
            bundles.find((b) => b.id === BIOCHAR_ID)
        ) {
            return {
                [LATIN_AMERICA_FORESTRY_ID]: 95,
                [BIOCHAR_ID]: 5,
            }
        }

        return {
            [bundles[0]?.id]: 100,
        }
    }, [bundles])

    return (
        <MainLayoutContainer
            headerComponent={
                <Text variant="h4" sx={{ mb: 1 }}>
                    New Account
                </Text>
            }
        >
            <LoadingWrapper loading={loadingAccounts || loading || !defaultBundleSelection}>
                {defaultBundleSelection && (
                    <AccountFormWrapper
                        bundles={bundles}
                        onSubmit={onCreateAccount}
                        onSuccess={onSuccess}
                        initialValues={{
                            [AccountFormKeys.NAME]: ``,
                            [AccountFormKeys.CURRENCY]: CurrencyCode.GBP,
                            [AccountFormKeys.BENEFICIARY]: undefined,
                            [AccountFormKeys.BUNDLE_SETTINGS]: {
                                customBundleSelection: defaultBundleSelection,
                                selectedBundlePortfolioId: CUSTOM_PORTFOLIO_ID,
                            },
                        }}
                        forbiddenTooltip={NotAdminTooltip}
                    />
                )}
            </LoadingWrapper>
        </MainLayoutContainer>
    )
}

export default memo(CreateAccount)
