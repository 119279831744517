// eslint-disable-next-line complexity
export function getCurrencySymbol(code: string): string {
    switch (code) {
        case 'GBP':
            return '£'
        case 'EUR':
            return '€'
        case 'USD':
            return '$'
        case 'SEK':
            return 'kr'
        case 'CHF':
            return 'CHf'
        case 'PLN':
            return 'zł'
        case 'DKK':
            return 'Kr.'
        case 'NOK':
            return 'kr'
        case 'BGN':
            return 'Лв.'
        case 'INR':
            return '₹'
        case 'SGD':
            return 'S$'
        case 'AED':
            return 'د.إ'
        case 'AUD':
            return '$A'
        case 'HUF':
            return 'Ft'
        case 'ISK':
            return 'kr'
        case 'CZK':
            return 'h'
        case 'CAD':
            return 'C$'
    }
    return ''
}

export function addTwoDecimalPoints(number: string) {
    return Number.parseFloat(number).toFixed(2)
}
