import { Distance } from '@lune-climate/lune'
import { FC } from 'react'
import { Field } from 'react-final-form'

import { InputWithButtonGroup } from 'views/CalculateEmissions/Shared/InputWithButtonGroup'

const validate = (value: Distance | undefined) => {
    if (!value?.amount) {
        return 'This is a required field'
    } else if (value.amount === '0') {
        return `This should be a positive number`
    } else {
        return undefined
    }
}
const RouteAsDistance: FC = () => {
    return (
        <Field
            name={'route'}
            placeholder={'Latitude'}
            validate={validate}
            component={({ input: { onChange, value }, meta }) => (
                <InputWithButtonGroup
                    placeholder={'Distance'}
                    error={meta.touched && meta.error}
                    onChange={(value) => {
                        const tempValue: Distance = {
                            amount: value.amount,
                            unit: (value.unit || Distance.unit.KM) as Distance.unit,
                        }
                        onChange(tempValue)
                    }}
                    value={value as any as Distance}
                    buttonItems={[
                        {
                            label: 'km',
                            value: Distance.unit.KM,
                        },
                        {
                            label: 'miles',
                            value: Distance.unit.MI,
                        },
                        {
                            label: 'nautical miles',
                            value: Distance.unit.NM,
                        },
                    ]}
                />
            )}
        />
    )
}

export default RouteAsDistance
