import { FC, useMemo } from 'react'

import { searchByWordPrefix } from '../utils'

import AutocompleteSelect, { FilterFunction } from './AutocompleteSelect'
import countriesList from './countriesList'

type CountryType = {
    label: string
    value: string
}

export interface Props {
    value: string
    onChange: (value: string | undefined) => void
    error?: string
    placeholder?: string
    disabled?: boolean
    filter?: FilterFunction
}

const CountryPicker: FC<Props> = ({
    value,
    onChange,
    disabled,
    error,
    placeholder = 'Country',
    filter,
    ...rest
}) => {
    const selectedValue = useMemo(() => {
        const c = countriesList.find((c) => c[2] === value)
        return c ? { label: c[0], value: c[2] } : null
    }, [value])

    return (
        <AutocompleteSelect
            error={error}
            label={placeholder}
            disabled={disabled}
            items={countriesList.map(([name, , threeLetterCode]) => ({
                value: threeLetterCode,
                label: name,
            }))}
            onChange={(currency: CountryType) => onChange(currency.value)}
            value={selectedValue as CountryType}
            filter={filter || searchByWordPrefix}
            {...rest}
        />
    )
}

export default CountryPicker
