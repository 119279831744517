import { LoadingWrapper } from '@lune-fe/lune-ui-lib'

import useAccounts from 'hooks/useAccounts'
import ActiveAccountBeneficiary from 'views/BuyOffsets/OrderSummary/Beneficiary/ActiveAccountBeneficiary'
import OrganisationBeneficiary from 'views/BuyOffsets/OrderSummary/Beneficiary/OrganisationBeneficiary'

const Beneficiary = () => {
    const { activeAccount, loading } = useAccounts()

    return (
        <LoadingWrapper loading={loading}>
            {activeAccount && activeAccount.beneficiary ? (
                <ActiveAccountBeneficiary />
            ) : (
                <OrganisationBeneficiary />
            )}
        </LoadingWrapper>
    )
}

export default Beneficiary
