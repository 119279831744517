import { CurrencyPicker, Input } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'
import { memo, useEffect, useState } from 'react'

const InputWithCurrency = ({
    onChange,
    value,
    error,
}: {
    onChange: (val: { value: string; currency: string }) => void
    value: {
        value: string
        currency: string
    }
    error?: string
}) => {
    const [currency, setCurrency] = useState<string>('GBP')
    const [inputValue, setInputValue] = useState<string>(value.value)

    useEffect(() => {
        setCurrency(value.currency)
        setInputValue(value.value)
    }, [value])

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                gap: 1 / 2,
            }}
        >
            <Box
                sx={{
                    flex: '1 1 auto',
                }}
            >
                <Input
                    error={error}
                    sx={{ width: '100%' }}
                    value={inputValue}
                    highlightOnFocus
                    onChange={(e) => {
                        const valueAsNumber = Number(e.target.value)
                        if (Number.isNaN(valueAsNumber)) {
                            return
                        }
                        setInputValue(!Number.isNaN(valueAsNumber) ? valueAsNumber.toString() : '')
                    }}
                    onBlur={() => {
                        onChange({
                            value: inputValue,
                            currency: value.currency,
                        })
                    }}
                />
            </Box>
            <Box sx={{ width: 'auto' }}>
                <CurrencyPicker
                    value={currency || 'GBP'}
                    onChange={(newValue) => {
                        onChange({
                            value: value.value,
                            currency: newValue || '',
                        })
                    }}
                />
            </Box>
        </Box>
    )
}

export default memo(InputWithCurrency)
