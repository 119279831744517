import {
    Button,
    LabeledFormSection,
    LoadingWrapper,
    LuneTheme,
    MainLayoutContainer,
    NoResultsPlaceholder,
    Text,
} from '@lune-fe/lune-ui-lib'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { Box } from '@mui/material'
import { flatten } from 'lodash'
import { useMemo } from 'react'

import useInvoices from 'hooks/useInvoices'
import BillingInfo from 'views/BillingInfo/BillingInfo'
import PaymentMethod from 'views/PaymentMethod/PaymentMethod'
import InvoicesList from 'views/Settings/Billing/InvoicesList'

export interface DapiInvoice {
    totalAmountCents: number
    currency: string
    createdAt: string
    paymentStatus: string
    url: string | null
}

export interface PaginatedInvoices {
    data: {
        hasMore: boolean
        data: DapiInvoice[]
    }
}

const Billing = () => {
    const { palette } = LuneTheme

    const { loading, paginatedInvoices, hasNextPage, fetchNextPage } = useInvoices()

    const invoices = useMemo(
        () => flatten((paginatedInvoices?.pages ?? []).map(({ data }) => data.data)),
        [paginatedInvoices],
    )

    return (
        <LoadingWrapper loading={false} sx={{ height: 1 }}>
            <MainLayoutContainer
                headerComponent={
                    <>
                        <Text variant="h4" sx={{ mb: 1 }}>
                            Billing
                        </Text>
                    </>
                }
            >
                <LabeledFormSection
                    title={`Default payment method`}
                    sx={{
                        mt: 9,
                    }}
                >
                    <BillingInfo />
                    <PaymentMethod />
                </LabeledFormSection>
                <Box
                    sx={{
                        mt: 8,
                        pt: 8,
                        pb: 4,
                        borderTop: `1px solid ${palette.Grey300} `,
                    }}
                >
                    <Text variant="h6">Invoices</Text>
                </Box>
                <LoadingWrapper loading={loading} sx={{ height: '554px', mt: 3 }}>
                    {invoices.length === 0 ? (
                        <Box sx={{ height: `554px`, mt: 3 }}>
                            <NoResultsPlaceholder
                                message={
                                    <Text
                                        variant={`h6`}
                                        sx={{
                                            textAlign: 'center',
                                            maxWidth: '250px',
                                        }}
                                    >
                                        You don&apos;t have any invoices yet
                                    </Text>
                                }
                            />
                        </Box>
                    ) : (
                        <InvoicesList invoices={invoices} />
                    )}
                    {!!hasNextPage && (
                        <Button
                            sx={{ mt: 9 }}
                            data-testid={`load-more-button`}
                            variant={'outlined'}
                            leftIcon={<ArrowDownwardIcon />}
                            onClick={() => fetchNextPage()}
                        >
                            Load More
                        </Button>
                    )}
                </LoadingWrapper>
            </MainLayoutContainer>
        </LoadingWrapper>
    )
}

export default Billing
