import { CountTimeseries } from '@lune-climate/lune'
import { Button, NoResultsPlaceholder, Text } from '@lune-fe/lune-ui-lib'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import Box from '@mui/material/Box'
import moment from 'moment'
import { FC, useMemo, useRef } from 'react'
import { Chart } from 'react-chartjs-2'
import { Range } from 'react-date-range'

import { downloadImageFromHtml } from 'utils'
import { chartAreaBorder, chartColors, chartOptions } from 'views/Dashboard/Charts/chartUtils'

import 'chart.js/auto'
import 'chartjs-adapter-moment'

const Total = ({ total }: { total: number }) => {
    return (
        <Text
            variant={'h4'}
            sx={{
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                mb: 9,
            }}
        >
            {total} emissions calculations
        </Text>
    )
}

const EmissionCalculationsChart: FC<{
    data: CountTimeseries
    dateRange: Range
}> = ({ data, dateRange }) => {
    const printRef = useRef<HTMLElement>(null)

    const chartData = useMemo(() => {
        const rangeDuration = moment.duration(moment(dateRange.endDate).diff(dateRange.startDate))
        const format =
            !dateRange.endDate || !dateRange.startDate || rangeDuration.asMonths() > 1
                ? 'MMM, YYYY'
                : 'DD MMM, YYYY'

        return {
            labels: data.map((d) => {
                return moment(d.date).format(format)
            }),
            cubicInterpolationMode: 'monotone' as const,
            tension: 0.8,
            datasets: [
                {
                    data: data.map((d) => d.count),
                    backgroundColor: chartColors[0],
                    barThickness: 12,
                },
            ],
        }
    }, [data, dateRange])

    const total = useMemo(() => {
        return data.reduce((a, b) => a + b.count, 0)
    }, [data])

    return (
        <Box>
            {data.length ? (
                <>
                    <Box ref={printRef}>
                        <Total total={total} />
                        <Box
                            ref={printRef}
                            sx={{ position: 'relative', height: '400px', width: '100%', p: 1 }}
                        >
                            <Chart
                                type="bar"
                                data={chartData as any}
                                options={chartOptions('calculations') as any}
                                plugins={[chartAreaBorder]}
                            />
                        </Box>
                    </Box>
                    <Button
                        sx={{ mt: 9 }}
                        onClick={() => {
                            if (printRef.current) {
                                downloadImageFromHtml(
                                    printRef.current,
                                    `emission-calculations-graph`,
                                )
                            }
                        }}
                        leftIcon={<FileDownloadOutlinedIcon />}
                        variant={'outlined'}
                    >
                        Download graph
                    </Button>
                </>
            ) : (
                <Box sx={{ height: `450px` }}>
                    <Total total={0} />
                    <NoResultsPlaceholder
                        message={
                            <Text
                                variant={`h6`}
                                sx={{
                                    textAlign: 'center',
                                    maxWidth: '250px',
                                }}
                            >
                                You don&apos;t have any emissions calculations for the selected time
                                period
                            </Text>
                        }
                    />
                </Box>
            )}
        </Box>
    )
}

export default EmissionCalculationsChart
