import { LuneTheme, Text } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import { FC } from 'react'
import { Helmet } from 'react-helmet'

const ContactErikSection: FC = () => {
    const { palette } = LuneTheme

    return (
        <Box>
            <Text variant={'h5'}>Want to discuss or review the proposal with us?</Text>
            <Box
                sx={{
                    mt: 4,
                    backgroundColor: palette.Grey50,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden',
                    pt: 3,
                    '.meetings-iframe-container': {
                        width: '700px',
                        margin: 'auto',
                        height: '655px',
                        overflow: 'hidden',
                        marginBottom: '-26px',
                    },
                }}
            >
                <Helmet>
                    <script
                        type="text/javascript"
                        src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
                    ></script>
                </Helmet>
                <div
                    className="meetings-iframe-container"
                    data-src="https://meetings-eu1.hubspot.com/erik-stadigh?embed=true"
                ></div>
            </Box>
        </Box>
    )
}

export default ContactErikSection
