import type { SxProps } from '@mui/material'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import React from 'react'

const LabeledFormSection = ({
    title,
    subTile,
    children,
    sx,
    titlePros,
}: {
    children: React.ReactNode
    title: string
    subTile?: React.ReactNode
    sx?: SxProps
    titlePros?: Record<string, any>
}) => {
    return (
        <Container disableGutters sx={sx}>
            <Typography
                sx={{
                    mb: subTile ? 1 : 4,
                }}
                variant="h6"
                {...(titlePros || {})}
            >
                {title}
            </Typography>
            {subTile && (
                <Typography variant="body3" color={`Grey700`}>
                    {subTile}
                </Typography>
            )}
            <Container
                disableGutters
                sx={{
                    mt: 4,
                }}
            >
                {children}
            </Container>
        </Container>
    )
}

export default LabeledFormSection
