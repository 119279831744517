import { Bundle } from '@lune-climate/lune'
import { FC } from 'react'

import useBuyOffsetsState from 'hooks/useBuyOffsetsState'
import {
    Allocation,
    BundleWithPercentage,
    BundleWithVolume,
} from 'views/BuyOffsets/BuyOffsetsTypes'
import ByVolumeCalculationsWrapper from 'views/BuyOffsets/CartModal/CartModalController/ByVolumeCalculationsWrapper'
import CardModalContent from 'views/BuyOffsets/CartModal/CartModalController/CardModalContent'

const ByVolume: FC<{
    allocation: Allocation[]
    customizedPortfolio: boolean
    onRemoveBundle?: (bundle: Bundle) => void
}> = ({ allocation, customizedPortfolio, onRemoveBundle }) => {
    const { setBuyOffsetProps } = useBuyOffsetsState()

    const onBundlesVolumeChange = (bundles: BundleWithVolume[]) => {
        const total = bundles.reduce((a, b) => Number(a || 0) + Number(b.volume || 0), 0)
        setBuyOffsetProps({
            isCustomBundleVolumeSet: true,
            isCustomBundlePercentageSet: false,
            customVolumes: bundles,
            amount: total.toString(),
            error: undefined,
        })
    }

    const onBundlesPercentageChange = (bundles: BundleWithPercentage[]) => {
        setBuyOffsetProps({
            isCustomBundlePercentageSet: true,
            isCustomBundleVolumeSet: false,
            customPercentages: bundles,
            error: undefined,
        })
    }

    return (
        <ByVolumeCalculationsWrapper
            allocation={allocation}
            customizedPortfolio={customizedPortfolio}
        >
            <CardModalContent
                onBundlesVolumeChange={onBundlesVolumeChange}
                onBundlesPercentageChange={onBundlesPercentageChange}
                allocation={allocation}
                customizedPortfolio={customizedPortfolio}
                onRemoveBundle={onRemoveBundle}
            />
        </ByVolumeCalculationsWrapper>
    )
}

export default ByVolume
