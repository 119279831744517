import type { SxProps } from '@mui/material'
import Box from '@mui/material/Box'
import { FC, RefObject, useEffect, useMemo, useRef, useState } from 'react'

export interface MediaProps {
    src: string
    type: 'image' | 'video'
    alt?: string
    placeholderSrc?: string
    dynamicPlaceholder?: {
        width: number
        height: number
        color?: string
    }
    width?: number
    height?: number
    key?: string
    sx?: SxProps
    autoPlay?: boolean
    muted?: boolean
    loop?: boolean
    backgroundImage?: boolean
    onLoad?: (loaded: boolean) => void
}

const useMediaLoaded = (type: 'image' | 'video') => {
    const [loadedMedia, setLoadedMedia] = useState<boolean>(false)
    const [loadedPlaceholder, setLoadedPlaceholder] = useState<boolean>(false)
    const mediaRef = useRef<HTMLImageElement | HTMLVideoElement>(null)
    const placeholderRef = useRef<HTMLImageElement>(null)

    const onMediaLoad = () => {
        setLoadedMedia(true)
    }
    const onPlaceholderLoad = () => {
        setLoadedPlaceholder(true)
    }

    useEffect(() => {
        if (type === 'image') {
            const image = mediaRef.current as any as HTMLImageElement
            if (image.complete && !loadedMedia) {
                onMediaLoad()
            }
        } else {
            const video = mediaRef.current as any as HTMLVideoElement
            if (video.readyState && !loadedMedia) {
                onMediaLoad()
            }
        }
        if (placeholderRef.current && placeholderRef.current.complete && !loadedPlaceholder) {
            onPlaceholderLoad()
        }
    })

    return {
        mediaRef,
        placeholderRef,
        loadedMedia,
        onMediaLoad,
        onPlaceholderLoad,
        loadedPlaceholder,
    }
}

/**
 * Media component
 * @param src - main image source
 * @param type - media type -> image or video
 * @param placeholderSrc - placeholder image source
 * @param dynamicPlaceholder - setting an autogenerated placeholder with specific width, height and color(optional)
 * @param alt - default image and video alt prop
 * @param sx - custom style that applies to dropdown button
 * @param width - media width
 * @param height - media height
 * @param key - default key prop
 * @param autoPlay - video specific -> default video autoplay prop
 * @param loop -  video specific -> default video loop prop
 * @param muted -  video specific -> default video muted prop
 * @param backgroundImage - setting image as a background image
 */
const Media: FC<MediaProps> = ({
    src,
    placeholderSrc,
    alt,
    sx,
    width,
    height,
    key,
    dynamicPlaceholder,
    autoPlay,
    loop,
    muted,
    type,
    backgroundImage,
    onLoad,
}) => {
    const {
        mediaRef,
        loadedMedia,
        onMediaLoad,
        placeholderRef,
        onPlaceholderLoad,
        loadedPlaceholder,
    } = useMediaLoaded(type)

    useEffect(() => {
        onLoad?.(loadedMedia)
    }, [loadedMedia])

    // eslint-disable-next-line complexity
    const sharedStyle = useMemo(() => {
        return {
            position: 'relative',
            height: '100%',

            '.main-media, .placeholder-image, .dynamic-placeholder-image, .background-image': {
                transition: 'opacity .3s ease-in-out',
                display: 'block',
                opacity: 0,

                ...(!loadedMedia && {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                }),
            },

            '.background-image': {
                width: width || '100%',
                height: height || '100%',
                backgroundImage: `url(${src})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                opacity: loadedMedia && backgroundImage ? 1 : 0,
                position: 'absolute',
                top: 0,
                left: 0,
            },

            '.main-media': {
                width: width || '100%',
                maxWidth: '100%',
                height: 'auto',
                maxHeight: height || 'unset',
                opacity: loadedMedia && !backgroundImage ? 1 : 0,
                position: 'absolute',
                top: 0,
                left: 0,
            },

            '.placeholder-image': {
                opacity: loadedPlaceholder && loadedMedia ? 0 : 1,
                width: width || '100%',
                maxWidth: '100%',
                height: 'auto',
                maxHeight: height || 'unset',
            },

            '.dynamic-placeholder-image': {
                opacity: loadedPlaceholder && loadedMedia ? 0 : 1,
                width: dynamicPlaceholder?.width || 0,
                maxWidth: '100%',
                height: 0,

                ...(backgroundImage && {
                    backgroundColor: dynamicPlaceholder?.color || `Grey300`,
                    width: width || '100%',
                    height: height || '100%',
                }),

                ...(!backgroundImage && {
                    '&:after': {
                        content: '""',
                        display: 'block',
                        width: '100%',
                        paddingBottom: `${((dynamicPlaceholder?.height || 0) / (dynamicPlaceholder?.width || 0)) * 100}%`,
                        backgroundColor: dynamicPlaceholder?.color || `Grey300`,
                    },
                }),
            },

            ...sx,
        }
    }, [loadedMedia, loadedPlaceholder])

    return (
        <Box sx={{ ...(sharedStyle as SxProps) }}>
            {type === 'image' ? (
                <>
                    {backgroundImage && <Box className={'background-image'} />}
                    <img
                        ref={mediaRef as RefObject<HTMLImageElement>}
                        onLoad={onMediaLoad}
                        draggable="false"
                        className={'main-media'}
                        src={src}
                        alt={alt}
                        {...(key && { key })}
                    />
                </>
            ) : (
                <video
                    ref={mediaRef as RefObject<HTMLVideoElement>}
                    autoPlay={autoPlay}
                    muted={muted}
                    loop={loop}
                    className={'main-media'}
                    onLoadedData={onMediaLoad}
                    {...(key && { key })}
                >
                    <source src={src} type="video/mp4" />
                    Your browser does not support this video.
                </video>
            )}
            {placeholderSrc && (
                <img
                    ref={placeholderRef}
                    onLoad={onPlaceholderLoad}
                    draggable="false"
                    className={'placeholder-image'}
                    src={placeholderSrc}
                    alt={`${alt}-placeholder`}
                    {...(width && { width })}
                    {...(height && { height })}
                    {...(key && { key: `${key}-placeholder` })}
                />
            )}
            {dynamicPlaceholder && <Box className={'dynamic-placeholder-image'} />}
        </Box>
    )
}
export default Media
