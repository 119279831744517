import { Bundle } from '@lune-climate/lune'
import { formatToCurrency } from '@lune-fe/lune-components-lib'
import { ListItemLayout, Table } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'
import { FC, useMemo } from 'react'

import BundleAvatar from 'components/BundleAvatar'
import BundleCard from 'components/BundleCard'

const BundleListItemCell: FC<{ bundle: Bundle }> = ({ bundle }) => {
    const { id, name, primaryImage } = bundle

    const avatar = useMemo(
        () => <BundleAvatar name={name} image={primaryImage ?? undefined} />,
        [name, primaryImage],
    )

    return (
        <Table.TableCell colSize={7}>
            <ListItemLayout
                image={avatar}
                title={name}
                subTitle={`${formatToCurrency(bundle.grossUnitPrice, bundle.currency)}  / tCO₂`}
                onHoverContent={
                    <Box
                        sx={{
                            width: `264px`,
                        }}
                    >
                        <BundleCard key={id} bundle={bundle} />
                    </Box>
                }
            />
        </Table.TableCell>
    )
}

export default BundleListItemCell
