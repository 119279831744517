import { Big } from 'big.js'
import { toJpeg } from 'html-to-image'
import moment from 'moment'

import { IApiKey } from 'models/apiKey'

export const flattenObj = (input: Record<string, any>) => {
    const result: any = {}
    for (const key in input) {
        if (typeof input[key] === 'object' && !Array.isArray(input[key])) {
            const temp = flattenObj(input[key])
            for (const j in temp) {
                result[key + ' - ' + j] = temp[j]
            }
        } else {
            result[key] = input[key]
        }
    }
    return result
}

export const capitalize = (s: string): string => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export const formatTimestamp = (isoTimestamp: string): string => {
    return moment(isoTimestamp).format('LLL')
}

export const truncateStringWithoutCutWords = (str: string | undefined, maxLen: number) => {
    const separator = ` `
    if (str === undefined || str.length <= maxLen) return str
    return str.substr(0, str.lastIndexOf(separator, maxLen))
}

export const maskify = (data: string, nrVisibleChars: number = 8) =>
    data.slice(0, -nrVisibleChars).replace(/./g, '∗') + data.slice(-nrVisibleChars)

/**
 * This takes a bundle selection object and returns the sum of the percentages as Big
 * eg { 'bundle-id-1': 50, 'bundle-id-2': 50 } => Big(100)
 * @param bundleSelection
 * @param volume
 */
export const calculateBundleSelectionTotalSum = (
    bundleSelection: Record<string, number | string>,
    volume?: boolean,
): Big =>
    Object.values(bundleSelection).reduce((sum, currentAllocation) => {
        try {
            const amountAsBig = Big(currentAllocation)
            if (!!volume || (amountAsBig.gte(Big(0)) && amountAsBig.lte(Big(100)))) {
                return sum.add(amountAsBig)
            }
        } catch {}
        return sum
    }, Big(0))

export const refreshDocsTestApiKeyIfPossible = (apiKeyId: string, newKey: IApiKey) => {
    const currentTestAPIDoc = JSON.parse(localStorage.getItem('docs_test_api_key') || '{}')
    if (currentTestAPIDoc.id === apiKeyId) {
        const cookieDomain = process.env.REACT_APP_DOCS_COOKIE_DOMAIN
            ? `; domain=${process.env.REACT_APP_DOCS_COOKIE_DOMAIN}`
            : ''
        document.cookie = `docs_test_api_key=${newKey.fullSecret}; Secure; SameSite=Strict${cookieDomain}`
        localStorage.setItem('docs_test_api_key', JSON.stringify(newKey))
    }
}

export function getTestApiKey(): string | undefined {
    return document.cookie
        .split('; ')
        .find((row) => row.startsWith('docs_test_api_key='))
        ?.split('=')[1]
}

export const snakeToCamel = (s: string) => {
    return s.replace(/([-_][a-z])/g, (group) =>
        group.toUpperCase().replace('-', '').replace('_', ''),
    )
}

export const pluralize = (word: string, number: number) => {
    return `${word}${number === 1 ? '' : 's'}`
}

/**
 * The function's only purpose is to fail at compile time if the parameter passed is *not*
 * of the type specified.
 *
 * This is useful to ensure, at compile time, what type are we dealing with. Particularly
 * helpful in making sure we handle all possible values in some context.
 *
 * @example
 *
 * ```
 * function f(value: 'a' | 'b') {
 *     if (value === 'a') {
 *         console.log('We got a')
 *     }
 *     else {
 *         assertType<'b'>(value)
 *         console.log('We got b')
 *     }
 * }
 * ```
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function assertType<T>(_value: T) {}

export const downloadImageFromHtml = (element: HTMLElement, fileName?: string) => {
    toJpeg(element, {
        backgroundColor: 'white',
        skipAutoScale: false,
        type: 'image/jpeg',
    })
        .then((dataUrl) => {
            const link = document.createElement('a')
            link.download = fileName || 'image.svg'
            link.href = dataUrl
            link.click()
        })
        .catch((err) => {
            console.log(err)
        })
}

export const luneAssetsToDynamicAssets = (
    imageUrl: string,
    width: number,
    height: number,
): string => {
    if (imageUrl.includes('https://assets.lune.co/')) {
        const newUrl = imageUrl.replace(
            'https://assets.lune.co/',
            'https://dynamic-assets.lune.co/',
        )
        return `${newUrl}?width=${width}&height=${height}`
    } else {
        return imageUrl
    }
}

/**
 * Takes an object A of type Partial<T> and object B of type T.
 * Throws an error if trying set a key to undefined that is not allowed to be undefined.
 *
 * This is a helper function for types with optional values. It's a workaround for TS's inability to distinguish
 * between "undefined" and "optional" keys in an object and thus allowing explicit undefined values to be passed in.
 * e.g. { key: undefined } is allowed by Partial<{ key?: string }> but it shouldn't be.
 * (rule address this https://www.typescriptlang.org/tsconfig#exactOptionalPropertyTypes, but we don't want to enable it)
 * @param partialUpdate - the partial update object (Partial<T>)
 * @param validStateWithUndefined - the valid state object (T) with undefined values for keys that are allowed to be
 * undefined (generally this will be the initial state of the object)
 */
export const validatePartialUpdate = <T extends Record<string, any | undefined>>({
    partialUpdate,
    validStateWithUndefined,
}: {
    partialUpdate: Partial<T>
    validStateWithUndefined: T
}) => {
    // Check which keys are undefined (and are therefore allowed to be undefined) from validState object
    const allowedUndefinedKeys = Object.keys(validStateWithUndefined).filter(
        (key) => validStateWithUndefined[key] === undefined,
    )

    Object.keys(partialUpdate).forEach((updateKey) => {
        if (!allowedUndefinedKeys.includes(updateKey) && partialUpdate[updateKey] === undefined) {
            throw new Error(
                `buildValidPartialUpdate: key ${updateKey} in a partial update is not allowed to be undefined according to the provided valid state. Make sure you're not passing in undefined values for keys that are OPTIONAL but not allowed to be undefined.`,
            )
        }
    })
}
