import { Table } from '@lune-fe/lune-ui-lib'
import { RefObject } from 'react'
import { useNavigate } from 'react-router-dom'

import { LoadMoreKeys } from 'hooks/useLoadMoreState'
import { ScrollPositionKeys } from 'hooks/useScrollRestoration'
import { EstimateWithAccountInfo, PaginatedEstimatesWithAccountInfo } from 'models/openDapi'
import { EmissionCalculationsRow, EstimateTypeSlug } from 'views/CalculateEmissions'

const EmissionCalculationsTable = ({
    scrollRef,
    paginatedEstimates,
    saveScrollPosition,
    loadedItemsCount,
    saveLastCount,
    queryParams,
}: {
    scrollRef: RefObject<HTMLDivElement>
    paginatedEstimates: PaginatedEstimatesWithAccountInfo
    saveScrollPosition: (scrollRef: RefObject<HTMLDivElement>, key: string) => void
    loadedItemsCount: number
    saveLastCount: (key: LoadMoreKeys, count: string) => void
    queryParams: string | undefined
}) => {
    const navigate = useNavigate()

    return (
        <Table.TableContainer>
            <Table>
                <Table.TableHead>
                    <Table.TableRow>
                        <Table.TableCell colSize={5}>Calculation</Table.TableCell>
                        <Table.TableCell colSize={2}>Date</Table.TableCell>
                        <Table.TableCell colSize={2}>Account</Table.TableCell>
                        <Table.TableCell colSize={2} alignEnd>
                            tCO₂
                        </Table.TableCell>
                        <Table.TableCell colSize={1} alignEnd />
                    </Table.TableRow>
                </Table.TableHead>
                <Table.TableBody>
                    {paginatedEstimates.data.map(
                        (calculation: EstimateWithAccountInfo, i: number) => (
                            <EmissionCalculationsRow
                                key={i}
                                calculation={calculation}
                                onClick={() => {
                                    saveScrollPosition(
                                        scrollRef,
                                        ScrollPositionKeys.EMISSION_CALCULATION,
                                    )
                                    if (loadedItemsCount) {
                                        saveLastCount(
                                            LoadMoreKeys.EMISSION_CALCULATION,
                                            loadedItemsCount.toString(),
                                        )
                                    }
                                    navigate(
                                        `/calculate-emissions/${EstimateTypeSlug(
                                            calculation.estimateType,
                                        )}/${calculation.id}/results${
                                            queryParams ? `?${queryParams}` : ''
                                        }`,
                                    )
                                }}
                            />
                        ),
                    )}
                </Table.TableBody>
            </Table>
        </Table.TableContainer>
    )
}

export default EmissionCalculationsTable
