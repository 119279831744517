import { TransactionDocumentEmissionEstimate } from '@lune-climate/lune'
import { ButtonGroup, Snippet, SnippetItem } from '@lune-fe/lune-ui-lib'
import Co2OutlinedIcon from '@mui/icons-material/Co2Outlined'
import { Box } from '@mui/material'
import { useMemo, useState } from 'react'

import Divider from '../../components/Divider'
import { getFormattedAmount } from '../../utils/utils'

import { CategoryIcon } from './CategoryMetadata'
import { InputWrapper } from './InputsWrapper'
import { ResultLabeledSectionAndTiles } from './ResultLabeledSectionAndTiles'
import { TransactionDocumentEmissionsExplanationLineItemSection } from './TransactionDocumentEmissionsExplanationLineItemSection'

export const TransactionDocumentLineItems = ({
    lineItems,
    input,
    enableEmissionFactorLink,
}: {
    lineItems: TransactionDocumentEmissionEstimate['lineItems']
    input: any
    enableEmissionFactorLink: boolean
}) => {
    const [activeTabIndex, setActiveTabIndex] = useState<string>('1')

    const activeLineItem = useMemo(() => {
        if (activeTabIndex && lineItems.length) {
            return lineItems[Number(activeTabIndex) - 1]
        }
        return null
    }, [activeTabIndex, lineItems])

    const activeLineItemFormattedAmount = useMemo(() => {
        if (activeTabIndex && lineItems.length) {
            const lineItem = lineItems[Number(activeTabIndex) - 1]
            return {
                amountAsNumber: getFormattedAmount(lineItem.mass.amount).amountAsNumber,
                amountUnit: getFormattedAmount(lineItem.mass.amount).amountUnit,
            }
        }
        return null
    }, [activeTabIndex, lineItems])

    const formattedInput = useMemo((): string => {
        return JSON.stringify(input, null, 2)
    }, [input])

    return (
        <>
            <ButtonGroup
                items={lineItems.map(
                    (
                        lineItem: TransactionDocumentEmissionEstimate['lineItems'][number],
                        index: number,
                    ) => {
                        const lineItemNumber = index + 1
                        return {
                            label: (
                                <>
                                    <CategoryIcon name={lineItem.emissionFactor!.category ?? ''} />{' '}
                                    Line item {lineItemNumber}
                                </>
                            ),
                            value: `${lineItemNumber}`,
                            dataTestId: `lineItem-btn-${lineItemNumber}`,
                        }
                    },
                )}
                onChange={(active) => {
                    setActiveTabIndex(active)
                }}
                value={activeTabIndex}
            />
            {activeLineItem && activeLineItemFormattedAmount && lineItems.length && (
                <>
                    <ResultLabeledSectionAndTiles
                        label={'Emissions for this line item'}
                        dataTestId={'lineItem-emissions-amount'}
                        tiles={[
                            {
                                icon: <Co2OutlinedIcon />,
                                label: `${activeLineItemFormattedAmount.amountAsNumber.toFixed(
                                    2,
                                )} ${activeLineItemFormattedAmount.amountUnit}CO₂e`,
                            },
                        ]}
                    />
                    <Divider />
                    <TransactionDocumentEmissionsExplanationLineItemSection
                        lineItem={activeLineItem}
                        enableEmissionFactorLink={enableEmissionFactorLink}
                    />
                    <Divider />
                    <Box>
                        <InputWrapper>
                            <Snippet header="JSON" sx={{ maxHeight: '480px' }}>
                                <SnippetItem language={'plain'} toCopy={formattedInput}>
                                    {formattedInput}
                                </SnippetItem>
                            </Snippet>
                        </InputWrapper>
                    </Box>
                </>
            )}
        </>
    )
}

export default TransactionDocumentLineItems
