import { AirportPicker } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'
import { memo } from 'react'
import { Field } from 'react-final-form'

const required = (value: any) => (value?.trim() ? undefined : 'This is a required field')

const RouteAsAirportCode = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                gap: 1 / 2,
                width: '100%',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                '> div': {
                    width: '100%',
                },
            }}
        >
            <Field
                validate={required}
                name={`route.source.code`}
                component={({ input: { onChange, value }, meta }: any) => (
                    <AirportPicker
                        placeholder={'Origin airport code'}
                        value={value}
                        onChange={(e) => onChange(e)}
                        error={meta.touched && meta.error}
                    />
                )}
            />
            <Field
                validate={required}
                name={`route.destination.code`}
                component={({ input: { onChange, value }, meta }: any) => (
                    <AirportPicker
                        placeholder={'Destination airport code'}
                        value={value}
                        onChange={(e) => onChange(e)}
                        error={meta.touched && meta.error}
                    />
                )}
            />
        </Box>
    )
}

export default memo(RouteAsAirportCode)
