import { SupportedUNSdg } from '@lune-climate/lune'
import { Text, Tooltip, UnSDG } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'
import { FC } from 'react'

export interface SDG {
    unSdgNumber: number
    occurrences: number
}

const UNSDGs: FC<{ unSDGNumbers?: SupportedUNSdg }> = ({ unSDGNumbers }) => {
    return (
        <Box>
            <Text variant={'h6'} sx={{ mb: 1 }}>
                UN Sustainable Development Goals
            </Text>
            <Text variant={'body3'}>These are the SDGs from the projects you support.</Text>
            <Box
                sx={{
                    pt: 4,
                    pb: 0,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        marginRight: '-16px',
                        marginLeft: '-16px',
                    }}
                >
                    {unSDGNumbers?.map((goal) => {
                        return (
                            <Tooltip
                                arrow
                                key={`unSDGs-${goal.unSdgNumber}`}
                                placement="top"
                                title={`Supported by ${goal.occurrences} project${
                                    goal.occurrences !== 1 ? 's' : ''
                                }`}
                            >
                                <Box
                                    sx={{
                                        width: '80px',
                                        height: '80px',
                                        marginBottom: 4,
                                        mr: 2,
                                        ml: 2,
                                        position: 'relative',
                                    }}
                                >
                                    {Array.from(
                                        Array(goal.occurrences > 5 ? 5 : goal.occurrences).keys(),
                                    ).map((i) => {
                                        const c: any = goal.unSdgNumber
                                        return (
                                            <UnSDG
                                                n={c}
                                                key={i}
                                                sx={{
                                                    position: 'absolute',
                                                    left: `${4 * i}px`,
                                                    borderRight: '2px solid white',
                                                    margin: 0,
                                                    height: '100%',
                                                    bottom: 0,
                                                    zIndex: -i,
                                                }}
                                            />
                                        )
                                    })}
                                </Box>
                            </Tooltip>
                        )
                    })}
                </Box>
            </Box>
        </Box>
    )
}
export default UNSDGs
