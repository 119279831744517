import { PaginatedClientAccounts } from '@lune-climate/lune'
import { InfiniteData, useInfiniteQuery } from 'react-query'

import { useLuneClient } from 'hooks/useLuneClient'
import { queryKeys } from 'queryKeys'

/**
 * Loads client accounts via infinite query (paginated query)
 * @param searchKey
 */
const useClientAccounts = (
    searchKey?: string,
): {
    paginatedAccounts: InfiniteData<PaginatedClientAccounts> | undefined
    loading: boolean
    fetchNextPage: () => void
    refetch: () => void
    hasNextPage: boolean | undefined
} => {
    const luneClient = useLuneClient()
    const {
        data: paginatedAccounts,
        isLoading,
        fetchNextPage,
        hasNextPage,
        refetch,
    } = useInfiniteQuery<PaginatedClientAccounts>(
        queryKeys.GET_CLIENT_ACCOUNTS,
        async ({
            pageParam = {
                cursor: undefined,
            },
        }) => {
            return luneClient
                .listClientAccounts({ after: pageParam.cursor, name: searchKey })
                .then((r) => r.unwrap())
        },
        {
            getNextPageParam: (lastPage) => {
                if (!lastPage.hasMore) {
                    return undefined
                }
                return {
                    cursor: lastPage.data[lastPage.data.length - 1].id,
                }
            },
            refetchOnMount: true,
        },
    )

    return {
        loading: isLoading,
        paginatedAccounts,
        fetchNextPage,
        refetch,
        hasNextPage,
    }
}

export default useClientAccounts
