import { useEffect, useState } from 'react'

export default function useKeyPress(targetKeys: string[]) {
    const [keyPressed, setKeyPressed] = useState<boolean>(false)

    const downHandler = (key: KeyboardEvent) => {
        if (targetKeys.includes(key.key)) {
            setKeyPressed(true)
        }
    }

    const upHandler = (key: KeyboardEvent) => {
        if (targetKeys.includes(key.key)) {
            setKeyPressed(false)
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', downHandler)
        window.addEventListener('keyup', upHandler)
        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener('keydown', downHandler)
            window.removeEventListener('keyup', upHandler)
        }
    })
    return keyPressed
}
