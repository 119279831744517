import { EmissionFactorEstimate } from '@lune-climate/lune'
import { Stack } from '@mui/material'
import { FC } from 'react'

import { Divider, EstimateTypeEnum, EstimateTypeName, RequestAndResponse } from '../components'
import {
    CalculationResults,
    EmissionFactorEmissionExplanation,
} from '../components/emissionsCalculationExplanation'

interface EmissionFactorEstimateResultProps {
    estimate: EmissionFactorEstimate & { _meta: { response: unknown } }
    enableEmissionFactorLink: boolean
    requestAndResponseSnippets?: {
        isTestMode?: boolean
        testApiKey?: string
    }
}

export const EmissionFactorEstimateResult: FC<EmissionFactorEstimateResultProps> = ({
    estimate,
    enableEmissionFactorLink,
    requestAndResponseSnippets,
}) => {
    if (
        typeof estimate._meta.response !== 'object' ||
        estimate._meta.response === null ||
        !('request' in estimate._meta.response)
    ) {
        throw new Error('Unexpected _meta object')
    }
    return (
        <>
            <CalculationResults
                title={
                    estimate.request.name ||
                    estimate.emissionFactor.name ||
                    EstimateTypeName(EstimateTypeEnum.EMISSION_FACTOR)
                }
                amount={estimate.mass.amount}
            />
            <Stack direction={'column'} spacing={9} mt={9}>
                <Divider />
                <EmissionFactorEmissionExplanation
                    estimate={estimate}
                    enableEmissionFactorLink={enableEmissionFactorLink}
                />
                {requestAndResponseSnippets && (
                    <>
                        <Divider />
                        <RequestAndResponse
                            path={`/v1/estimates/emission-factor`}
                            request={(estimate._meta.response as { request: unknown }).request}
                            response={estimate._meta.response}
                            idempotencyKey={
                                (estimate._meta.response as { idempotency_key?: string })
                                    .idempotency_key
                            }
                            metadata={
                                (
                                    estimate._meta.response as {
                                        metadata?: Record<string, string>
                                    }
                                ).metadata
                            }
                            isTestMode={requestAndResponseSnippets.isTestMode}
                            testApiKey={requestAndResponseSnippets.testApiKey}
                        />
                    </>
                )}
            </Stack>
        </>
    )
}

export default EmissionFactorEstimateResult
