import { BundleSelection, MassUnit } from '@lune-climate/lune'

export interface IOrderByMassPayload {
    mass: {
        amount: string
        unit: string
    }
    bundleSelection: BundleSelection
    quantityTrunc?: MassUnit
}

export interface IOrderByValuePayload {
    value: string
    bundleSelection: BundleSelection
    quantityTrunc?: MassUnit
}

export enum OrderType {
    QUANTITY = 'quantity',
    VALUE = 'value',
}

export enum EditType {
    EDIT_BY_VOLUME = 'editByVolume',
    EDIT_BY_PERCENTAGE = 'editByPercentage',
}

export enum OrderStatusEnum {
    RECEIVED = 'received',
    PLACED = 'placed',
    PAID = 'paid',
    RETIRING = 'retiring',
    COMPLETE = 'complete',
    CANCELLED = 'cancelled',
    FAILED = 'failed',
}

export type OrderStatus =
    | 'received'
    | 'placed'
    | 'paid'
    | 'retiring'
    | 'cancelled'
    | 'complete'
    | 'failed'
