import { Button } from '@lune-fe/lune-ui-lib'
import Stack from '@mui/material/Stack'
import { AxiosError, AxiosResponse } from 'axios'
import { useSnackbar } from 'notistack'
import { Field, Form } from 'react-final-form'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { FormInput } from 'components/FormInput'
import { DapiErrors, resetPassword } from 'endpoints/dapi'
import { SnackbarMessages } from 'SnackbarMessages'
import FormBox from 'views/Account/Shared/FormBox'
import GenericErrorMessage from 'views/Account/Shared/GenericErrorMessage'
import Layout from 'views/Account/Shared/Layout'

interface IFormData {
    password: string
    password2: string
}

export default function ResetPassword() {
    const { enqueueSnackbar: snackbar } = useSnackbar()

    const location = useLocation()
    const pathItems = location.pathname.split('/')
    const token = pathItems[pathItems.length - 1] ?? ''
    const navigate = useNavigate()

    const onSubmit = async ({ password }: IFormData) => {
        resetPassword({ password, token }).then(
            () => {
                snackbar(SnackbarMessages.PASSWORD_RESET_SUCCESS)
                navigate('/login')
            },
            (error) => {
                if (error instanceof AxiosError && error.response) {
                    if (error.response.status === 409) {
                        const response = error.response as AxiosResponse<DapiErrors, any>
                        const errorCode = response.data.errors[0].errorCode
                        if (errorCode === 'token_expired') {
                            snackbar(SnackbarMessages.PASSWORD_RESET_LINK_EXPIRED, {
                                persist: true,
                                variant: 'error',
                            })
                        } else {
                            snackbar(<GenericErrorMessage />, {
                                persist: true,
                                variant: 'error',
                            })
                        }
                    } else if (error.response.status > 299) {
                        snackbar(<GenericErrorMessage />, {
                            persist: true,
                            variant: 'error',
                        })
                    }
                }
            },
        )
    }

    return (
        <Layout>
            <FormBox title={'Reset password'}>
                <Form
                    sx={{
                        width: '100%',
                    }}
                    onSubmit={onSubmit}
                    render={({ handleSubmit, values }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Stack
                                    direction="column"
                                    sx={{ width: '100%' }}
                                    spacing={{ xs: 1 }}
                                >
                                    <Field
                                        name={'password'}
                                        placeholder={'Password'}
                                        component={FormInput}
                                        validate={(value: any) => {
                                            if (!value?.trim()) {
                                                return SnackbarMessages.REQUIRED_FIELD
                                            }
                                            if (
                                                !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/.test(value)
                                            ) {
                                                return SnackbarMessages.PASSWORD_PATTERN
                                            }
                                        }}
                                        type={'password'}
                                    />
                                    <Field
                                        name={'password2'}
                                        placeholder={'Re-enter password'}
                                        component={FormInput}
                                        validate={(value: any) => {
                                            if (value !== values.password) {
                                                return SnackbarMessages.PASSWORD_MATCH_FAIL
                                            }
                                            return value?.trim()
                                                ? undefined
                                                : SnackbarMessages.REQUIRED_FIELD
                                        }}
                                        type={'password'}
                                    />
                                </Stack>
                                <Button
                                    data-testid={`reset-password-button`}
                                    type={'submit'}
                                    sx={{ width: '100%', mb: 1, mt: 4 }}
                                >
                                    Reset password
                                </Button>
                                <Link to="/login">
                                    <Button variant={'text'} sx={{ width: '100%', mb: 1 }}>
                                        Log in
                                    </Button>
                                </Link>
                                <Link to="/signup">
                                    <Button variant={'text'} sx={{ width: '100%' }}>
                                        Sign up
                                    </Button>
                                </Link>
                            </form>
                        )
                    }}
                />
            </FormBox>
        </Layout>
    )
}
