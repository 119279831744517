import { SimpleSeaShippingMethod, SimpleShippingMethod } from '@lune-climate/lune'
import { getShippingMethodLabel } from '@lune-fe/lune-components-lib'
import { AutocompleteSelect, Text } from '@lune-fe/lune-ui-lib'
import { FC, memo, useMemo } from 'react'

type ListOption = {
    group: string
    label: string
    value: string
}

interface Props {
    value?: string
    onChange: (value: string | undefined) => void
    error?: { key: string; message: string }
    disabled?: boolean
}

const list = () => {
    const sea = Object.entries(SimpleSeaShippingMethod)
        .map((item) => ({
            group: 'Sea',
            value: item[1],
            label: getShippingMethodLabel(item[1]),
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
    const inland = [
        ...Object.entries(SimpleShippingMethod).filter((item) => item[0].includes('INLAND')),
    ]
        .map((item) => ({
            group: 'Inland',
            value: item[1],
            label: getShippingMethodLabel(item[1]),
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
    return [...sea, ...inland]
}

const ShippingMethods: FC<Props> = ({ value, onChange, error, disabled }) => {
    const selectedValue = useMemo(() => {
        const selectedMethod = list().find((method) => method.value === value)
        return selectedMethod
            ? {
                  label: selectedMethod.label,
                  value: selectedMethod.value,
                  group: selectedMethod.group,
              }
            : {
                  label: '',
                  value: '',
                  group: '',
              }
    }, [value])

    return (
        <AutocompleteSelect
            disabled={disabled}
            label={'Shipping method'}
            error={
                error?.key === 'SHIPPING_METHOD' || error?.key === 'required' ? error.message : ''
            }
            onChange={(selectedMethod: ListOption | undefined) => onChange(selectedMethod?.value)}
            value={selectedValue as ListOption}
            items={list()}
            groupBy={(option) => option.group}
            customOptions={(option) => (
                <Text variant={'body3'} sx={{ ml: 4 }}>
                    {option.label}
                </Text>
            )}
        />
    )
}

export default memo(ShippingMethods)
