import { Project } from '@lune-climate/lune'
import { formatNumbers } from '@lune-fe/lune-components-lib'
import { LabeledFormSection, Text, Tile } from '@lune-fe/lune-ui-lib'
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import CloudOffOutlinedIcon from '@mui/icons-material/CloudOffOutlined'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined'
import EventOutlinedIcon from '@mui/icons-material/EventOutlined'
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined'
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import SyncProblemOutlinedIcon from '@mui/icons-material/SyncProblemOutlined'
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined'
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined'
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined'
import { Box, Stack } from '@mui/material'
import moment from 'moment'
import { FC, ReactNode } from 'react'

interface DetailsTile {
    icon: ReactNode
    label: string
    value: string | null
}

const DATE_FORMAT = 'DD/MM/YYYY'

// eslint-disable-next-line complexity
const CaliforniaBillDetails: FC<{ project: Project }> = ({ project }) => {
    const dateTiles: DetailsTile[] = [
        {
            icon: <EventOutlinedIcon />,
            label: 'Start date',
            value: project.creditingStartDate
                ? moment(project.creditingStartDate).format(DATE_FORMAT)
                : null,
        },
        {
            icon: <EventAvailableOutlinedIcon />,
            label: 'Registration date',
            value: project.registrationDate
                ? moment(project.registrationDate).format(DATE_FORMAT)
                : null,
        },
        {
            icon: <FlagOutlinedIcon />,
            label: 'End date',
            value: project.creditingEndDate
                ? moment(project.creditingEndDate).format(DATE_FORMAT)
                : null,
        },
    ].filter((i) => i.value)

    const riskTiles: DetailsTile[] = [
        {
            icon: <UndoOutlinedIcon />,
            label: 'Risk of reversal',
            value: project.riskOfReversal,
        },
        {
            icon: <SyncProblemOutlinedIcon />,
            label: 'Risk of predicted emissions not occurring',
            value: project.riskOfPredictedEmissions,
        },
    ].filter((i) => i.value)

    const estimationTiles: DetailsTile[] = [
        {
            icon:
                project.bundles[0].offsetType === 'emissions_reduction' ? (
                    <CloudOffOutlinedIcon />
                ) : (
                    <CloudDownloadOutlinedIcon />
                ),
            label: `Estimated average annual ${
                project.bundles[0].offsetType === 'emissions_reduction'
                    ? 'emissions reduction'
                    : 'carbon removal'
            }`,
            value:
                project.bundles[0].offsetType && project.avgAnnualEmissionReductions
                    ? `${formatNumbers(project.avgAnnualEmissionReductions)} tCO₂`
                    : null,
        },
        {
            icon: <LockOutlinedIcon />,
            label: 'Durability',
            value: project.durability,
        },
    ].filter((i) => i.value)

    const otherTiles: DetailsTile[] = [
        {
            icon: <DescriptionOutlinedIcon />,
            label: 'Protocol',
            value: project.protocol?.join(', ') || null,
        },
        {
            icon: <InventoryOutlinedIcon />,
            label: 'Methodology',
            value: project.methodology,
        },
        {
            icon: <CategoryOutlinedIcon />,
            label: 'Project type',
            value: project.projectType || null,
        },
        {
            icon: <VerifiedOutlinedIcon />,
            label: 'Additional standards met',
            value: project.additionalStandardsMet?.join(', ') || null,
        },
        {
            icon: <VerifiedUserOutlinedIcon />,
            label: 'Verifying body',
            value: project.verifyingBody,
        },
    ].filter((i) => i.value)

    return (
        <LabeledFormSection
            title={`Additional project details`}
            subTile={`Voluntary carbon market disclosures for compliance with Bill No. 1305 of the state of California`}
            titlePros={{
                variant: `h5`,
            }}
        >
            <Stack
                spacing={{
                    xs: 3,
                }}
            >
                {!!dateTiles.length && (
                    <Box>
                        {dateTiles.map((tile, index) => (
                            <Tile
                                key={index}
                                sx={{ svg: { color: `Grey900`, width: '24px' }, ml: -1 }}
                                icon={tile.icon as JSX.Element}
                            >
                                <Text variant={'body3'}>
                                    {tile.label}: {tile.value}
                                </Text>
                            </Tile>
                        ))}
                    </Box>
                )}
                {!!otherTiles.length && (
                    <Box>
                        {otherTiles.map((tile, index) => (
                            <Tile
                                key={index}
                                sx={{ svg: { color: `Grey900`, width: '24px' }, ml: -1 }}
                                icon={tile.icon as JSX.Element}
                            >
                                <Text variant={'body3'}>
                                    {tile.label}: {tile.value}
                                </Text>
                            </Tile>
                        ))}
                    </Box>
                )}
                {!!estimationTiles.length && (
                    <Box>
                        {estimationTiles.map((tile, index) => (
                            <Tile
                                key={index}
                                sx={{ svg: { color: `Grey900`, width: '24px' }, ml: -1 }}
                                icon={tile.icon as JSX.Element}
                            >
                                <Text variant={'body3'}>
                                    {tile.label}: {tile.value}
                                </Text>
                            </Tile>
                        ))}
                    </Box>
                )}
                {!!riskTiles.length && (
                    <Box>
                        {riskTiles.map((tile, index) => (
                            <Tile
                                key={index}
                                sx={{ svg: { color: `Grey900`, width: '24px' }, ml: -1 }}
                                icon={tile.icon as JSX.Element}
                            >
                                <Text variant={'body3'}>
                                    {tile.label}: {tile.value}
                                </Text>
                            </Tile>
                        ))}
                    </Box>
                )}
            </Stack>
        </LabeledFormSection>
    )
}

export default CaliforniaBillDetails
