import AcUnitOutlinedIcon from '@mui/icons-material/AcUnitOutlined'
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive'
import AspectRatioOutlinedIcon from '@mui/icons-material/AspectRatioOutlined'
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import DevicesOutlinedIcon from '@mui/icons-material/DevicesOutlined'
import DirectionsBoatFilledOutlinedIcon from '@mui/icons-material/DirectionsBoatFilledOutlined'
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined'
import DirectionsOutlinedIcon from '@mui/icons-material/DirectionsOutlined'
import DirectionsSubwayOutlinedIcon from '@mui/icons-material/DirectionsSubwayOutlined'
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined'
import EnergySavingsLeafOutlinedIcon from '@mui/icons-material/EnergySavingsLeafOutlined'
import FastfoodOutlinedIcon from '@mui/icons-material/FastfoodOutlined'
import FlightClassOutlinedIcon from '@mui/icons-material/FlightClassOutlined'
import FlightOutlinedIcon from '@mui/icons-material/FlightOutlined'
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined'
import InsertInvitationOutlinedIcon from '@mui/icons-material/InsertInvitationOutlined'
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined'
import LocalFireDepartmentOutlinedIcon from '@mui/icons-material/LocalFireDepartmentOutlined'
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined'
import OilBarrelOutlinedIcon from '@mui/icons-material/OilBarrelOutlined'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined'
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined'
import ScaleOutlinedIcon from '@mui/icons-material/ScaleOutlined'
import TagIcon from '@mui/icons-material/Tag'
import TrainOutlinedIcon from '@mui/icons-material/TrainOutlined'
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined'

export const CalculateEmissionsIcons = {
    car: <DirectionsCarFilledOutlinedIcon />,
    calendar: <InsertInvitationOutlinedIcon />,
    location: <PinDropOutlinedIcon />,
    electronicDeviceExpenses: <DevicesOutlinedIcon />,
    employees: <PeopleAltOutlinedIcon />,
    employeesUsingPublicTransport: <TrainOutlinedIcon />,
    food: <FastfoodOutlinedIcon />,
    gasConsumption: <LocalFireDepartmentOutlinedIcon />,
    electricityConsumption: <BoltOutlinedIcon />,
    flights: <FlightOutlinedIcon />,
    firstOrBusinessClassPercentage: <FlightClassOutlinedIcon />,
    tech: <DnsOutlinedIcon />,
    officeArea: <AspectRatioOutlinedIcon />,
    garbage: <DeleteOutlinedIcon />,
    greenElectricity: <EnergySavingsLeafOutlinedIcon />,
    plane: <AirplanemodeActiveIcon />,
    ship: <DirectionsBoatFilledOutlinedIcon />,
    truck: <LocalShippingOutlinedIcon />,
    train: <DirectionsSubwayOutlinedIcon />,
    number: <TagIcon />,
    package: <Inventory2OutlinedIcon />,
    fuel: <OilBarrelOutlinedIcon />,
    load: <ScaleOutlinedIcon />,
    refrigerated: <AcUnitOutlinedIcon />,
    route: <RouteOutlinedIcon />,
    road: <DirectionsOutlinedIcon />,
    address: <HomeWorkOutlinedIcon />,
    airport: <AirplanemodeActiveIcon />,
    port: <DirectionsBoatFilledOutlinedIcon />,
    warehouse: <WarehouseOutlinedIcon />,
}
