export const searchByWordPrefix = (option: { label: string }, key: string): boolean => {
    const containsSearchKey = option.label
        .split(' ')
        .find((word) => word.toLowerCase().startsWith(key))

    if (containsSearchKey !== undefined) {
        return true
    } else {
        return false
    }
}

export default searchByWordPrefix
