import { LogisticsSiteMethod, ShippingMethod, SimpleShippingMethod } from '@lune-climate/lune'
import { FC, ReactNode } from 'react'

import { CalculateEmissionsIcons } from './calculateEmissionsIcons'
import { getShippingMethodMainTypeFromValue } from './utils'

export const LegMethodIcon: FC<{ method: ShippingMethod | LogisticsSiteMethod }> = ({ method }) => {
    const type = getShippingMethodMainTypeFromValue(method)
    const shippingMethodToIconMap: Record<string, ReactNode> = {
        SimpleShippingMethod: CalculateEmissionsIcons.truck,
        IdentifiedVesselShippingMethod: CalculateEmissionsIcons.ship,
        ContainerShippingMethod: CalculateEmissionsIcons.ship,
        LogisticsSiteMethod: CalculateEmissionsIcons.warehouse,
        AirShippingMethod: CalculateEmissionsIcons.plane,
        RoadShippingMethod: CalculateEmissionsIcons.truck,
        // default value
        default: CalculateEmissionsIcons.ship,
    }

    const getIconForSimpleShippingMethod = (method: SimpleShippingMethod): ReactNode => {
        if (method.includes('train')) {
            return CalculateEmissionsIcons.train
        } else if (method.includes('plane')) {
            return CalculateEmissionsIcons.plane
        } else if (method.includes('waterway')) {
            return CalculateEmissionsIcons.ship
        } else {
            return CalculateEmissionsIcons.truck
        }
    }

    if (type === 'SimpleShippingMethod') {
        return <>{getIconForSimpleShippingMethod(method as SimpleShippingMethod)}</>
    } else {
        return <>{shippingMethodToIconMap[type] || shippingMethodToIconMap.default}</>
    }
}

export default LegMethodIcon
