import MUISlider, { SliderProps } from '@mui/material/Slider'
import { styled } from '@mui/material/styles'
import { FC, useMemo } from 'react'

import { LuneTheme } from '../theme'

const Slider: FC<SliderProps> = (props) => {
    const { palette, typography } = LuneTheme
    const StyledSlider = useMemo(() => {
        return styled(MUISlider)(
            LuneTheme.unstable_sx({
                color: palette.Grey900,
                fontFamily: typography.fontFamily,
                '& .MuiSlider-valueLabel': {
                    lineHeight: 1.2,
                    fontSize: 12,
                    background: 'unset',
                    padding: '8px',
                    backgroundColor: palette.Grey900,
                    fontFamily: typography.fontFamily,
                },
                '.MuiSlider-thumb:hover, .css-14gf62f-MuiSlider-thumb.Mui-focusVisible': {
                    boxShadow: '0px 0px 0px 8px rgba(33, 33, 33, 0.16) !important',
                },
            }),
        )
    }, [])

    return <StyledSlider {...props} size="small" />
}

export default Slider
