import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { FC } from 'react'
import { Field } from 'react-final-form'

import { FormInput } from 'components/FormInput'
import { CalculateEmissionsLabeledFormSection } from 'views/CalculateEmissions'

const required = (value: number | undefined) => {
    if (value === undefined) {
        return 'This is a required field'
    } else {
        return undefined
    }
}

const RouteAsCoordinates: FC = () => {
    return (
        <Box>
            <Stack spacing={{ xs: 4 }} sx={{ mt: 4 }}>
                <CalculateEmissionsLabeledFormSection label={'Origin'}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'space-between',
                            '> div': {
                                width: '100%',
                            },
                            gap: 1 / 2,
                        }}
                    >
                        <div>
                            <Field
                                validate={required}
                                placeholder={'Latitude'}
                                name={'route.source.lat'}
                                number
                                highlightOnFocus
                                component={FormInput}
                            />
                        </div>
                        <div>
                            <Field
                                name={'route.source.lon'}
                                placeholder={'Longitude'}
                                number
                                highlightOnFocus
                                component={FormInput}
                                validate={required}
                            />
                        </div>
                    </Box>
                </CalculateEmissionsLabeledFormSection>
                <CalculateEmissionsLabeledFormSection label={'Destination'}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'space-between',
                            '> div': {
                                width: '100%',
                            },
                            gap: 1 / 4,
                        }}
                    >
                        <div>
                            <Field
                                name={'route.destination.lat'}
                                placeholder={'Latitude'}
                                number
                                highlightOnFocus
                                component={FormInput}
                                validate={required}
                            />
                        </div>
                        <div>
                            <Field
                                name={'route.destination.lon'}
                                placeholder={'Longitude'}
                                number
                                highlightOnFocus
                                component={FormInput}
                                validate={required}
                            />
                        </div>
                    </Box>
                </CalculateEmissionsLabeledFormSection>
            </Stack>
        </Box>
    )
}

export default RouteAsCoordinates
