import { Project } from '@lune-climate/lune'
import { ScrollableContainer } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import { FC, RefObject } from 'react'

import CardItem from 'views/Projects/Project/shared/CardItem'
import ProjectIcon from 'views/Projects/Project/shared/ProjectIcon'

export type ProjectImpactSummaryProps = NonNullable<Project['impactSummary']>

const ProjectImpactSummary: FC<{
    impactSummary: ProjectImpactSummaryProps
    scrollRef: RefObject<HTMLDivElement>
}> = ({ impactSummary, scrollRef }) => {
    return (
        <Box
            sx={{
                display: 'block',
                mt: 4,
                width: '100%',
            }}
        >
            <ScrollableContainer
                containerRef={scrollRef}
                ignoreElements={'.cart-item__description, .cart-item__title'}
            >
                {impactSummary.map((item, index) => {
                    const { imageUrl, title, description, label, icon } = item
                    return (
                        <CardItem
                            key={`impact-summary-${index}`}
                            imageUrl={imageUrl}
                            title={title}
                            description={description}
                            badge={{
                                label,
                                icon: (
                                    <ProjectIcon
                                        iconName={`${icon}Outlined` as any}
                                        sx={{ width: '20px', height: '20px' }}
                                    />
                                ),
                            }}
                        />
                    )
                })}
            </ScrollableContainer>
        </Box>
    )
}

export default ProjectImpactSummary
