import { Button, LoadingWrapper } from '@lune-fe/lune-ui-lib'
import Stack from '@mui/material/Stack'
import { AxiosError, AxiosResponse } from 'axios'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { FormInput } from 'components/FormInput'
import { DapiErrors, GoogleSSOUserProfile, login } from 'endpoints/dapi'
import useLogout from 'hooks/useLogout'
import useMixpanel from 'hooks/useMixpanel'
import useUserState, { useWriteUserState } from 'hooks/useUserState'
import { SnackbarMessages } from 'SnackbarMessages'
import { capitalize } from 'utils'
import FormBox from 'views/Account/Shared/FormBox'
import GenericErrorMessage from 'views/Account/Shared/GenericErrorMessage'
import GoogleSSOButton from 'views/Account/Shared/GoogleSSOButton'
import Layout from 'views/Account/Shared/Layout'

interface ILoginProps {
    setToken: (args: { token: string; docsPublishableKey: string }) => void
}

export interface IFormData {
    email: string
    password?: string
    accessToken?: string
}

export default function Login({ setToken }: ILoginProps) {
    const navigate = useNavigate()
    const location = useLocation()
    const { enqueueSnackbar: snackbar } = useSnackbar()
    const { setUserState } = useWriteUserState()
    const [error, setError] = useState<string>()
    const validate = (value: any) => (value?.trim() ? undefined : SnackbarMessages.REQUIRED_FIELD)
    const [loading, setLoading] = useState<boolean>(false)
    const { userState } = useUserState()
    const [searchParams] = useSearchParams()
    const mixpanel = useMixpanel()
    const { logout } = useLogout()
    const [hasLoggedIn, setHasLoggedIn] = useState<boolean | undefined>()

    useEffect(() => {
        if (userState?.user) {
            const redirect = searchParams.get('redirect') ?? undefined

            // the user ended up on the login page, but is already logged in
            if (!hasLoggedIn) {
                const forceReLogin = searchParams.get('force-relogin')
                if (forceReLogin === 'true') {
                    const path = redirect ? `/login?redirect=${redirect}` : undefined
                    logout({ path })
                    return
                }
            }

            if (redirect) {
                // redirect may point to an external link (eg docs.lune.co)
                window.location.href = redirect
            } else {
                const { from } = location.state ?? { from: { pathname: '/' } }
                navigate(from.pathname, { replace: true })
            }
        }
    }, [userState, location, navigate, searchParams, logout, hasLoggedIn])

    const onSubmit = useCallback(
        (formData: IFormData, fromSSO?: boolean) => {
            setError(undefined)
            login({
                email: fromSSO ? undefined : formData.email,
                password: formData.password,
                accessToken: formData.accessToken,
            })
                .then((data) => {
                    setToken({ token: data.token!, docsPublishableKey: data.docsPublishableKey })
                    setUserState({
                        user: data.user,
                        account: data.account,
                        isAdmin: data.isAdmin,
                    })
                    mixpanel.track('Login', {
                        user_id: data.user.id,
                    })
                    mixpanel.track('dashboard_login_successful')
                    setHasLoggedIn(true)
                })
                .catch((error) => {
                    let responseStatus
                    if (error instanceof AxiosError && error.response) {
                        responseStatus = error.response.status
                        // 401 responses might not come with error information
                        if (responseStatus === 401 && error.response.data?.errors) {
                            const response = error.response as AxiosResponse<DapiErrors, any>
                            if (fromSSO) {
                                snackbar(capitalize(response.data.errors[0].message), {
                                    persist: true,
                                    variant: 'error',
                                })
                            } else {
                                setError(capitalize(response.data.errors[0].message))
                            }
                        } else if (responseStatus > 299) {
                            snackbar(<GenericErrorMessage />, {
                                persist: true,
                                variant: 'error',
                            })
                        }
                    }
                    setLoading(false)
                    mixpanel.track('LoginFailure', {
                        status: responseStatus,
                    })
                })
        },
        [setToken, setUserState, mixpanel, snackbar],
    )
    const onGoogleSSOSuccess = useCallback(
        (profile: GoogleSSOUserProfile) => {
            setLoading(true)
            onSubmit({ email: profile.email, accessToken: profile.accessToken }, true)
        },
        [onSubmit],
    )

    return (
        <Layout>
            <FormBox title={'Login'}>
                <Form
                    sx={{
                        width: '100%',
                    }}
                    onSubmit={(data: IFormData) => onSubmit(data)}
                    render={({ handleSubmit }) => {
                        return (
                            <form onSubmit={handleSubmit} onChange={() => setError(undefined)}>
                                <LoadingWrapper loading={loading}>
                                    <Stack
                                        direction="column"
                                        sx={{
                                            width: '100%',
                                        }}
                                        spacing={{
                                            xs: 1,
                                        }}
                                    >
                                        <Field
                                            name={'email'}
                                            placeholder={'Email'}
                                            component={FormInput}
                                            validate={validate}
                                            error={error}
                                        />
                                        <Field
                                            name={'password'}
                                            type={'password'}
                                            placeholder={'Password'}
                                            component={FormInput}
                                            validate={validate}
                                            error={error}
                                        />
                                    </Stack>
                                </LoadingWrapper>
                                <Button
                                    data-testid={`login-button`}
                                    type={'submit'}
                                    sx={{ width: '100%', mb: 1, mt: 4 }}
                                >
                                    Login
                                </Button>
                                <GoogleSSOButton
                                    redirectUri={`${window.origin}/login`}
                                    sx={{ mb: 1 }}
                                    onSuccess={onGoogleSSOSuccess}
                                    onFailure={() => setLoading(false)}
                                    buttonLabel={'Log in with Google'}
                                />
                                <Link to="/forgot-password">
                                    <Button
                                        data-testid={`forgot-password-button`}
                                        variant={'text'}
                                        sx={{ width: '100%', mb: 1 }}
                                    >
                                        Forgot password
                                    </Button>
                                </Link>
                                <Link to="/signup">
                                    <Button
                                        data-testid={`sign-up-btn`}
                                        variant={'text'}
                                        sx={{ width: '100%' }}
                                    >
                                        Sign up
                                    </Button>
                                </Link>
                            </form>
                        )
                    }}
                />
            </FormBox>
        </Layout>
    )
}
