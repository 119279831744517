import { useEffect, useRef } from 'react'

// https://dev.to/luispa/how-to-add-a-dynamic-title-on-your-react-app-1l7k
function useTitle(title: string, prevailOnUnmount: boolean = false) {
    const defaultTitle = useRef(document.title)

    useEffect(() => {
        document.title = title
    }, [title])

    useEffect(
        () => () => {
            if (!prevailOnUnmount) {
                document.title = defaultTitle.current
            }
        },
        [prevailOnUnmount],
    )
}

export default useTitle
