import { PassengerTransportationEmissionEstimate } from '@lune-climate/lune'
import { Dispatch, SetStateAction } from 'react'

import { ButtonGroup } from '../../../../lune-ui-lib/src'

import { getPassengerTransportationType, passengerTransportationIcons } from './utils'

export const PassengerTransportExplanationButtonGroup = ({
    request,
    setActiveTab,
    activeTab,
}: {
    request: PassengerTransportationEmissionEstimate['request']
    setActiveTab: Dispatch<SetStateAction<number>>
    activeTab: number
}) => {
    return (
        <ButtonGroup
            items={request.legs.map((leg, index) => {
                const Icon = passengerTransportationIcons[getPassengerTransportationType(leg)]
                const legNumber = index + 1
                return {
                    label: (
                        <>
                            <Icon /> Leg {legNumber}
                        </>
                    ),
                    value: `${legNumber}`,
                }
            })}
            onChange={(active) => {
                setActiveTab(Number(active))
            }}
            value={activeTab.toString()}
        />
    )
}

export default PassengerTransportExplanationButtonGroup
