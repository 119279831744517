import { Bundle } from '@lune-climate/lune'
import { Button, Modal, Text } from '@lune-fe/lune-ui-lib'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import CloseIcon from '@mui/icons-material/Close'
import { Box } from '@mui/material'
import { FC, MouseEvent, useState } from 'react'
import { flushSync } from 'react-dom'
import { useNavigate } from 'react-router-dom'

import useBuyOffsetsState from 'hooks/useBuyOffsetsState'
import useHasUnsavedChanges from 'hooks/useHasUnsavedChanges'
import { Allocation } from 'views/BuyOffsets/BuyOffsetsTypes'
import AdvancedSettingsMenu from 'views/BuyOffsets/CartModal/AdvancedSettingsMenu'
import CartModalControls from 'views/BuyOffsets/CartModal/CartModalController/CartModalController'
import OrderTotal from 'views/BuyOffsets/OrderTotal'

const CartModal: FC<{
    allocation: Allocation[]
    customizedPortfolio: boolean
    onClose?: () => void
    onRemoveBundle?: (bundle: Bundle) => void
    modalTitle?: string
}> = ({ allocation, customizedPortfolio, onClose, onRemoveBundle, modalTitle }) => {
    const navigate = useNavigate()
    const [advancedSettingsAnchor, setAdvancedSettingsAnchor] = useState<null | HTMLElement>(null)
    const { setHasUnsavedChangesState } = useHasUnsavedChanges()
    const { buyOffsetProps } = useBuyOffsetsState()
    const { error, amount, quote, simpleModal } = buyOffsetProps

    return (
        <Modal
            data-testid={`buy-offsets-cart-modal`}
            disableEscapeKeyDown
            stickyFooter={false}
            maxWidth={'md'}
            fullWidth
            open
            onClose={() => {}}
            sx={{
                '.modal-content': {
                    mb: 0,
                    '*': {
                        boxSizing: 'border-box',
                    },
                },
                '.modal-actions': {
                    pt: 0,
                    pl: 0,
                    pr: 0,
                },
            }}
            header={
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <Text
                            variant={'h5'}
                            sx={{
                                textAlign: 'center',
                            }}
                        >
                            {modalTitle || `Your portfolio`}
                        </Text>
                        <Button
                            iconButton
                            leftIcon={<CloseIcon />}
                            variant={'text'}
                            onClick={onClose}
                        />
                    </Box>
                </>
            }
            actions={
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'start',
                            gap: 1,
                        }}
                    >
                        <Button
                            data-testid={`checkout-btn`}
                            variant={'contained'}
                            disabled={!!error || amount === '0' || amount === '' || !quote}
                            onClick={() => {
                                // When you call a state update function, the state does not immediately change, but it's scheduled the to happen sometime in the future
                                // flushSync is used for synchronously flushing updates, meaning everything within flushSync is processed right away before moving to the next line of code.
                                // The flushSync call is forcing setHasUnsavedChangesState(false) to update synchronously.
                                // This means that it is fully executed and completed before the function call returns,
                                // ensuring that the state value hasUnsavedChangesState has been updated to false before moving on to the navigate function.
                                flushSync(() => setHasUnsavedChangesState(false))
                                navigate('/order/summary', {
                                    state: buyOffsetProps,
                                })
                            }}
                            leftIcon={<ArrowCircleRightIcon />}
                        >
                            Checkout
                        </Button>

                        {!simpleModal && (
                            <Button
                                data-testid={`advanced-settings-btn`}
                                variant={`text`}
                                onClick={(event: MouseEvent<HTMLElement>) =>
                                    setAdvancedSettingsAnchor(
                                        advancedSettingsAnchor ? null : event.currentTarget,
                                    )
                                }
                            >
                                Advanced Settings
                            </Button>
                        )}
                    </Box>
                    <OrderTotal
                        total={amount && amount !== '0' ? quote?.estimatedTotalCost || '0' : '0'}
                        allocation={allocation}
                    />
                </Box>
            }
        >
            <CartModalControls
                allocation={allocation}
                customizedPortfolio={customizedPortfolio}
                onRemoveBundle={onRemoveBundle}
            />
            <AdvancedSettingsMenu
                customizedPortfolio={customizedPortfolio}
                anchorElement={advancedSettingsAnchor}
                onClickAway={() => setAdvancedSettingsAnchor(null)}
            />
        </Modal>
    )
}

export default CartModal
