import { EmissionFactorEstimate } from '@lune-climate/lune'
import { SuccessResponse } from '@lune-climate/lune/esm/core/SuccessResponse'
import { EmissionFactorEstimateResult } from '@lune-fe/lune-components-lib'
import { useSnackbar } from 'notistack'
import { FC, RefObject, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import useIsTestMode from 'hooks/useIsTestMode'
import { useLuneClient } from 'hooks/useLuneClient'
import { SnackbarMessages } from 'SnackbarMessages'
import { getTestApiKey } from 'utils'
import { ResultsWrapper } from 'views/CalculateEmissions'

const EmissionFactorEstimateResults: FC<{ scrollRef: RefObject<HTMLDivElement> }> = ({
    scrollRef,
}) => {
    const { enqueueSnackbar: snackbar } = useSnackbar()
    const { id } = useParams<{ id: string }>()
    const [estimate, setEstimate] = useState<SuccessResponse<EmissionFactorEstimate>>()
    const luneClient = useLuneClient()
    const isTestMode = useIsTestMode()
    const testApiKey = getTestApiKey()

    useEffect(() => {
        if (id) {
            luneClient
                .getEmissionFactorEstimate(id)
                .then((res) => {
                    if (res.isOk()) {
                        const e = res.value
                        setEstimate(e)
                    }
                })
                .catch(() => snackbar(SnackbarMessages.GENERIC_ERROR))
        }
    }, [id, snackbar, luneClient])

    return (
        <>
            {estimate && (
                <ResultsWrapper scrollRef={scrollRef} calculatedAmount={estimate.mass.amount}>
                    <EmissionFactorEstimateResult
                        estimate={estimate}
                        enableEmissionFactorLink={true}
                        requestAndResponseSnippets={{ isTestMode, testApiKey }}
                    />
                </ResultsWrapper>
            )}
        </>
    )
}

export default EmissionFactorEstimateResults
