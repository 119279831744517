import { Button, LoadingWrapper, MainLayoutContainer, Text } from '@lune-fe/lune-ui-lib'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { useInfiniteQuery } from 'react-query'

import Divider from 'components/Divider'
import { getProposals } from 'endpoints/dapi'
import useMountEffect from 'hooks/useMountEffect'
import useScrollRestoration, { ScrollPositionKeys } from 'hooks/useScrollRestoration'
import { PaginatedProposals } from 'models/openDapi'
import { ALWAYS_CHECK_FOR_NEW_DATA, queryKeys } from 'queryKeys'
import { PROPOSAL_OPTION_PREFIX } from 'views/Proposals/Proposal/Option'
import ProposalsTable, { ProposalsTableRow } from 'views/Proposals/ProposalsTable'

const Proposals = () => {
    const [limit, setLimit] = useState<number>(3)
    const { clearScrollSession } = useScrollRestoration()
    const {
        isLoading,
        data: paginatedProposals,
        fetchNextPage,
    } = useInfiniteQuery<PaginatedProposals>(
        queryKeys.GET_PROPOSALS,
        ({ pageParam }) =>
            getProposals({
                after: pageParam,
                limit,
            }),
        {
            getNextPageParam: (lastPage) => lastPage.data.at(-1)?.id,
            ...ALWAYS_CHECK_FOR_NEW_DATA,
        },
    )

    useMountEffect(() => {
        // remove all proposal option expanded states
        for (const key in sessionStorage) {
            if (key.includes(PROPOSAL_OPTION_PREFIX)) {
                sessionStorage.removeItem(key)
            }
        }
        // remove all proposal views scroll state
        clearScrollSession(ScrollPositionKeys.PROPOSAL_VIEW)
    })

    useEffect(() => {
        // By default 3 items should be loaded, after that pagination limit changes to 10
        if (paginatedProposals?.pages[0].data.length) {
            setLimit(10)
        }
    }, [paginatedProposals?.pages])

    return paginatedProposals?.pages[0].data.length ? (
        <MainLayoutContainer
            headerComponent={
                <>
                    <Text variant={'h4'} sx={{ mb: 4 }}>
                        Proposals
                    </Text>
                </>
            }
        >
            <LoadingWrapper sx={{ minHeight: '400px' }} loading={isLoading}>
                <Stack spacing={9}>
                    <ProposalsTable>
                        {paginatedProposals.pages.map((page, i) => (
                            <>
                                {page.data.map((proposal) => (
                                    <ProposalsTableRow key={i} proposal={proposal} />
                                ))}
                            </>
                        ))}
                    </ProposalsTable>
                    {paginatedProposals.pages.at(-1)?.hasMore && (
                        <Button
                            variant={`outlined`}
                            leftIcon={<ArrowDownwardIcon />}
                            onClick={() => fetchNextPage()}
                        >
                            Load more
                        </Button>
                    )}
                    <Divider />
                </Stack>
            </LoadingWrapper>
        </MainLayoutContainer>
    ) : null
}

export default Proposals
