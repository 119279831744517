import { Tooltip } from '@lune-fe/lune-ui-lib'
import { ReactElement } from 'react'

const NotAdminTooltip = ({
    children,
    contentWidth,
}: {
    children: ReactElement
    contentWidth?: boolean
}) => {
    return (
        <Tooltip title="Only Admins can do this" placement="top" contentWidth={contentWidth}>
            {children}
        </Tooltip>
    )
}

export default NotAdminTooltip
