import { Tile } from '@lune-fe/lune-ui-lib'
import { SxProps } from '@mui/material'
import { MouseEvent, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

import useActiveRoutePath from 'hooks/useActiveRoutePath'

interface INavItemProps {
    href: string
    children: ReactNode
    // eslint-disable-next-line no-undef
    icon?: JSX.Element
    // eslint-disable-next-line no-undef
    rightIcon?: JSX.Element
    // eslint-disable-next-line no-undef
    target?: string
    onClickCallback?: () => void
    sx?: SxProps
}

const LinkTile = ({
    children,
    icon,
    rightIcon,
    href,
    target,
    onClickCallback,
    sx,
    ...rest
}: INavItemProps) => {
    const activeRoute = useActiveRoutePath()
    const navigate = useNavigate()

    const handleClick = (e: MouseEvent<HTMLElement>) => {
        e.preventDefault()
        onClickCallback?.()
        target ? window.open(href, target) : navigate(href)
    }

    return (
        <Tile
            variant={'light'}
            selected={activeRoute === href}
            key={`key-` + href}
            href={href}
            onClick={handleClick}
            icon={icon}
            rightIcon={rightIcon}
            sx={{
                '*': {
                    color: `initial !important`,
                },
                color: `initial !important`,
                ...sx,
            }}
            {...rest}
        >
            {children}
        </Tile>
    )
}

export default LinkTile
