import { LuneTheme, MainLayoutContainer, Text } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'

const NotFoundPage = () => {
    const { palette } = LuneTheme
    return (
        <MainLayoutContainer>
            <Box
                sx={{
                    mt: 30,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <img src="https://assets.lune.co/404.png" width="421px"></img>
                <Text variant="h4" sx={{ mt: 4 }}>
                    Page not found
                </Text>
                <Text variant="body3" sx={{ mt: 1, color: palette.Grey600 }}>
                    {`Sorry, it looks like the page you were looking for doesn't exist`}
                </Text>
            </Box>
        </MainLayoutContainer>
    )
}

export default NotFoundPage
