import { ButtonGroup, Input } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'
import { memo, useState } from 'react'

export const InputWithButtonGroup = ({
    onChange,
    value,
    buttonItems,
    error,
    placeholder,
}: {
    onChange: (val: { amount: string; unit: string }) => void
    value: {
        unit: string
        amount: string
    }
    buttonItems: {
        label: string
        value: string
    }[]
    error?: string
    placeholder?: string
}) => {
    const [inputValue, setInputValue] = useState<string>(value.amount)
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                gap: 1 / 2,
            }}
        >
            <Box sx={{ width: '50%' }}>
                <Input
                    placeholder={placeholder}
                    error={error}
                    sx={{ width: '100%' }}
                    value={inputValue}
                    highlightOnFocus
                    onChange={(e) => {
                        const valueAsNumber = Number(e.target.value)
                        if (Number.isNaN(valueAsNumber)) {
                            return
                        }
                        setInputValue(!Number.isNaN(valueAsNumber) ? valueAsNumber.toString() : '')
                    }}
                    onBlur={() => {
                        onChange({
                            amount: inputValue,
                            unit: value.unit,
                        })
                    }}
                />
            </Box>
            <ButtonGroup
                sx={{
                    width: '50%',
                    marginTop: 1 / 4,
                    ml: 1 / 4,
                    mr: 1 / 4,
                    '.MuiButton-root': {
                        height: '52px !important',
                    },
                }}
                items={buttonItems}
                onChange={(newValue) =>
                    onChange({
                        amount: value.amount,
                        unit: newValue,
                    })
                }
                value={value.unit || buttonItems[0].value}
            />
        </Box>
    )
}

export default memo(InputWithButtonGroup)
