import { Area, CompanyEstimateRequest } from '@lune-climate/lune'
import {
    CalculateEmissionsIcons,
    formatNumbers,
    formatToCurrency,
    getCountryNameByThreeLetterCode,
} from '@lune-fe/lune-components-lib'

import { notify } from 'bugsnag'
import { pluralize } from 'utils'
import { getCloudProviderName } from 'views/CalculateEmissions/Shared/CloudInfrastructureProviderPicker'

// eslint-disable-next-line complexity
export const buildCompanyFootprintBreakdownTile = (
    key: string,
    request: CompanyEstimateRequest,
) => {
    switch (key) {
        case 'employees': {
            return {
                label: (
                    <>
                        {formatNumbers(request.employees)}{' '}
                        {pluralize('employee', request.employees)}
                        {request.remoteEmployeesPercentage > 0 && (
                            <>, {request.remoteEmployeesPercentage}% remotely</>
                        )}
                    </>
                ),
                icon: CalculateEmissionsIcons.employees,
            }
        }
        case 'companyCars': {
            if (request.companyCars) {
                return {
                    label: (
                        <>
                            {formatNumbers(request.companyCars)} company{' '}
                            {pluralize('car', request.companyCars)}
                            {request.averageCarDistanceTravelled.amount && (
                                <>
                                    , traveling on average{' '}
                                    {formatNumbers(request.averageCarDistanceTravelled.amount)}{' '}
                                    {request.averageCarDistanceTravelled.unit} each
                                </>
                            )}
                        </>
                    ),
                    icon: CalculateEmissionsIcons.car,
                }
            }
            break
        }
        case 'employeesUsingPublicTransport': {
            if (request.employeesUsingPublicTransport) {
                return {
                    label: (
                        <>
                            {`${formatNumbers(request.employeesUsingPublicTransport)} `}
                            {pluralize('employee', request.employeesUsingPublicTransport)} commute
                            via public transport
                        </>
                    ),
                    icon: CalculateEmissionsIcons.employeesUsingPublicTransport,
                }
            }
            break
        }
        case 'employeesUsingPrivateTransport': {
            const employeesUsingPrivateTransport = Math.round(
                request.employees -
                    (request.remoteEmployeesPercentage * request.employees) / 100 -
                    request.employeesUsingPublicTransport,
            )
            if (employeesUsingPrivateTransport > 0) {
                return {
                    label: (
                        <>
                            {`${formatNumbers(employeesUsingPrivateTransport)} `}
                            {pluralize('employee', employeesUsingPrivateTransport)} commute via
                            private transport
                        </>
                    ),
                    icon: CalculateEmissionsIcons.car,
                }
            }
            break
        }
        case 'gasConsumption': {
            if (request.gasConsumption > 0) {
                return {
                    label: <>{formatNumbers(request.gasConsumption)} m3 of gas</>,
                    icon: CalculateEmissionsIcons.gasConsumption,
                }
            }
            break
        }
        case 'electricityConsumption': {
            if (request.electricityConsumption > 0) {
                return {
                    label: <>{formatNumbers(request.electricityConsumption)} kWh</>,
                    icon: CalculateEmissionsIcons.electricityConsumption,
                }
            }
            break
        }
        case 'greenElectricityUsed': {
            if (request.greenElectricityUsed) {
                return {
                    label: <>Using green electricity</>,
                    icon: CalculateEmissionsIcons.greenElectricity,
                }
            }
            break
        }
        case 'foodAndDrinksExpenses': {
            if (request.foodAndDrinksExpenses.value !== '0') {
                return {
                    label: (
                        <>
                            {formatToCurrency(
                                request.foodAndDrinksExpenses.value,
                                request.foodAndDrinksExpenses.currency,
                            )}{' '}
                            spent on food & drinks
                        </>
                    ),
                    icon: CalculateEmissionsIcons.food,
                }
            }
            break
        }
        case 'vegetarianAndVeganPercentage': {
            if (request.vegetarianAndVeganPercentage > 0) {
                return {
                    label: <>{request.vegetarianAndVeganPercentage}% vegetarian / vegan</>,
                    icon: CalculateEmissionsIcons.food,
                }
            }
            break
        }
        case 'electronicDeviceExpenses': {
            if (request.electronicDeviceExpenses.value !== '0') {
                return {
                    label: (
                        <>
                            {formatToCurrency(
                                request.electronicDeviceExpenses.value,
                                request.electronicDeviceExpenses.currency,
                            )}{' '}
                            spent on new devices
                        </>
                    ),
                    icon: CalculateEmissionsIcons.electronicDeviceExpenses,
                }
            }
            break
        }
        case 'garbage': {
            if (request.garbage > 0) {
                return {
                    label: (
                        <>
                            {formatNumbers(request.garbage)}kg of waste
                            {request.recycledGarbagePercentage > 0 && (
                                <>, {request.recycledGarbagePercentage}% recycled</>
                            )}
                        </>
                    ),
                    icon: CalculateEmissionsIcons.garbage,
                }
            }
            break
        }
        case 'officeArea': {
            if (request.officeArea.amount) {
                return {
                    label: (
                        <>
                            {formatNumbers(request.officeArea.amount)}{' '}
                            {request.officeArea.unit === Area.unit.SQUARE_METERS ? 'm2' : 'ft2'}{' '}
                            office area
                        </>
                    ),
                    icon: CalculateEmissionsIcons.officeArea,
                }
            }
            break
        }
        case 'tech.cloud': {
            if (request.tech?.cloud?.cost.value !== '0' && request.tech?.cloud?.provider) {
                return {
                    label: (
                        <>
                            {formatToCurrency(
                                request.tech.cloud.cost.value,
                                request.tech.cloud.cost.currency,
                            )}

                            <> spent on {getCloudProviderName(request.tech.cloud.provider)}</>
                        </>
                    ),
                    icon: CalculateEmissionsIcons.tech,
                }
            }
            break
        }
        case 'tech.onPremise': {
            if (request.tech && request.tech.onPremise?.electricityCost.value !== '0') {
                return {
                    label: (
                        <>
                            {formatToCurrency(
                                request.tech.onPremise?.electricityCost.value!,
                                request.tech.onPremise?.electricityCost.currency!,
                            )}
                            <> spent on premise infrastructure</>
                        </>
                    ),
                    icon: CalculateEmissionsIcons.tech,
                }
            }
            break
        }
        case 'firstOrBusinessClassPercentage': {
            if (request.firstOrBusinessClassPercentage > 0) {
                return {
                    label: <>{request.firstOrBusinessClassPercentage}% first / business class</>,
                    icon: CalculateEmissionsIcons.firstOrBusinessClassPercentage,
                }
            }
            break
        }
        case 'longFlights': {
            if (request.shortFlights > 0 || request.mediumFlights > 0 || request.longFlights > 0) {
                return {
                    label: (
                        <>
                            {request.shortFlights > 0 && (
                                <>
                                    {formatNumbers(request.shortFlights)} short
                                    {(request.mediumFlights > 0 || request.longFlights > 0) && (
                                        <>, </>
                                    )}
                                </>
                            )}
                            {request.mediumFlights > 0 && (
                                <>
                                    {formatNumbers(request.mediumFlights)} medium
                                    {request.longFlights > 0 && <>, </>}
                                </>
                            )}
                            {request.longFlights > 0 && (
                                <>{formatNumbers(request.longFlights)} long</>
                            )}
                            <>
                                {' '}
                                {pluralize(
                                    'flight',
                                    request.shortFlights +
                                        request.mediumFlights +
                                        request.longFlights,
                                )}
                            </>
                        </>
                    ),
                    icon: CalculateEmissionsIcons.flights,
                }
            }
            break
        }
        case 'countryCode': {
            return {
                label: (
                    <>
                        {getCountryNameByThreeLetterCode(request.countryCode, () =>
                            notify(
                                new Error(
                                    `Could not find country with code ${request.countryCode}`,
                                ),
                            ),
                        )}
                        {request.postcode && <>, {request.postcode}</>}
                    </>
                ),
                icon: CalculateEmissionsIcons.location,
            }
        }
        default:
            return {
                label: null,
                icon: null,
            }
    }
}
