import { LuneTheme } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'

const Divider = () => {
    const { palette } = LuneTheme
    return (
        <Box
            sx={{
                width: '100%',
                height: '1px',
                background: palette.Grey300,
            }}
        />
    )
}

export default Divider
