import { AggregatedAnalyticsByProperty, OffsetTypeNonNullable } from '@lune-climate/lune'
import Box from '@mui/material/Box'
import { Big } from 'big.js'
import { orderBy } from 'lodash'
import { FC, useCallback, useEffect, useState } from 'react'

import Divider from 'components/Divider'
import { OrderType } from 'models/order'
import DoughnutChart from 'views/Dashboard/Charts/DoughnutChart'
import { DoughnutDatapoint } from 'views/Dashboard/Charts/DoughnutChartConfig'

interface ChartProps {
    title: string
    data: DoughnutDatapoint[]
}

const OrdersBreakdownCharts: FC<{
    breakdowns?: AggregatedAnalyticsByProperty
    analyticsType: OrderType
}> = ({ analyticsType, breakdowns }) => {
    const [allChartData, setAllChartData] = useState<ChartProps[]>()

    const toPercentage = (value: string | number, array: string[]): number => {
        const numberVal = Big(value).toNumber()
        const arrayTotal = array.reduce((partialSum, val) => partialSum + Number(val), 0)
        return (numberVal * 100) / arrayTotal
    }

    const getFormattedAndSortedDataPoints = useCallback(
        (key: string, array: any[]): DoughnutDatapoint[] => {
            const formatted: DoughnutDatapoint[] = array.map((item) => ({
                label: item[key as keyof typeof item],
                value:
                    analyticsType === OrderType.VALUE
                        ? toPercentage(
                              item.value,
                              array.map(({ value }) => value),
                          )
                        : toPercentage(
                              item.quantity,
                              array.map(({ quantity }) => quantity),
                          ),
            }))
            return orderBy(formatted, 'value', 'desc')
        },
        [analyticsType],
    )

    const getFormattedAndSortedLocationData = useCallback(
        (data: { location: string; value: string; quantity: string }[]): DoughnutDatapoint[] => {
            const values: { location: string; value: number }[] = Array.from(
                data.reduce(
                    (m, { location, value }) =>
                        m.set(location, Number(m.get(location) || 0) + Number(value)),
                    new Map(),
                ),
                ([location, value]) => ({ location, value }),
            )
            const quantities: { location: string; quantity: number }[] = Array.from(
                data.reduce(
                    (m, { location, quantity }) =>
                        m.set(location, Number(m.get(location) || 0) + Number(quantity)),
                    new Map(),
                ),
                ([location, quantity]) => ({ location, quantity }),
            )

            const aggregatedLocations: DoughnutDatapoint[] = values.map((i, index) => {
                return {
                    label: i.location,
                    value:
                        analyticsType === OrderType.VALUE
                            ? toPercentage(
                                  i.value,
                                  values.map((v) => v.value.toString()),
                              )
                            : toPercentage(
                                  quantities[index].quantity,
                                  quantities.map((v) => v.quantity.toString()),
                              ),
                }
            })
            return orderBy(aggregatedLocations, 'value', 'desc')
        },
        [analyticsType],
    )

    const getFormattedAndSortedOxfordPrincipleData = useCallback(
        (oxfordPrinciples: { type: number; value: string; quantity: string }[]) => {
            if (oxfordPrinciples.length < 2) {
                return []
            }

            const type1 = oxfordPrinciples.find((p) => p.type === 1)
            const type2 = oxfordPrinciples.find((p) => p.type === 2)

            const typeOneTwoData: DoughnutDatapoint[] = [
                {
                    label: 'Type 1 & 2',
                    value:
                        analyticsType === OrderType.VALUE
                            ? toPercentage(
                                  Big(type1?.value || 0)
                                      .add(Big(type2?.value || 0))
                                      .toNumber(),
                                  oxfordPrinciples.map(({ value }) => value),
                              )
                            : toPercentage(
                                  Big(type1?.quantity || 0)
                                      .add(Big(type2?.quantity || 0))
                                      .toNumber(),
                                  oxfordPrinciples.map(({ quantity }) => quantity),
                              ),
                },
            ]

            const otherTypesData: DoughnutDatapoint[] = orderBy(
                [...oxfordPrinciples].filter((p) => p.type !== 1 && p.type !== 2),
                ['type'],
                'asc',
            ).map((item) => ({
                label: `Type ${item.type}`,
                value:
                    analyticsType === OrderType.VALUE
                        ? toPercentage(
                              item.value,
                              oxfordPrinciples.map(({ value }) => value),
                          )
                        : toPercentage(
                              item.quantity,
                              oxfordPrinciples.map(({ quantity }) => quantity),
                          ),
            }))
            return [...typeOneTwoData, ...otherTypesData]
        },
        [analyticsType],
    )

    useEffect(() => {
        if (breakdowns) {
            const bundleData: DoughnutDatapoint[] = getFormattedAndSortedDataPoints(
                'bundleName',
                breakdowns.bundle,
            )
            const locationData: DoughnutDatapoint[] = getFormattedAndSortedLocationData(
                breakdowns.location,
            )
            const registryData: DoughnutDatapoint[] = getFormattedAndSortedDataPoints(
                'registryName',
                breakdowns.registry,
            )
            const offsetTypeData: DoughnutDatapoint[] = getFormattedAndSortedDataPoints(
                'offsetType',
                breakdowns.offsetType.map((o) => {
                    return {
                        ...o,
                        offsetType:
                            o.offsetType === OffsetTypeNonNullable.EMISSIONS_REDUCTION
                                ? 'Emissions Reduction'
                                : 'Carbon Removal',
                    }
                }),
            )
            const oxfordPrincipleData: DoughnutDatapoint[] =
                getFormattedAndSortedOxfordPrincipleData(breakdowns.oxfordOffsettingPrinciples)
            setAllChartData([
                {
                    title: 'Project Bundles',
                    data: bundleData,
                },
                {
                    title: 'Location',
                    data: locationData,
                },
                {
                    title: 'Verification standard',
                    data: registryData,
                },
                {
                    title: 'Oxford Principles types',
                    data: oxfordPrincipleData,
                },
                {
                    title: 'Reduction / Removal',
                    data: offsetTypeData,
                },
            ])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [breakdowns, analyticsType])

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    gap: 4,
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                }}
            >
                {allChartData?.map((item, index) => (
                    <DoughnutChart
                        reloadingData={false}
                        downloadable={true}
                        key={`breakdown-chart-${index}`}
                        maxWidth={256}
                        title={item.title}
                        dataPoints={item.data}
                    />
                ))}
                <Box sx={{ width: '256px' }} />
            </Box>
            <Divider />
        </>
    )
}

export default OrdersBreakdownCharts
