import { Project } from '@lune-climate/lune'
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined'
import EventOutlinedIcon from '@mui/icons-material/EventOutlined'
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined'
import { useMemo } from 'react'

import CarbonCreditsListItem from 'views/Projects/Project/CarbonCredits/CarbonCreditsListItem'

export type RetirementTimeframe = NonNullable<Project['carbonCredits']>['retirementTimeframe']

const CarbonCreditRetirement = ({
    retirementTimeframe,
}: {
    retirementTimeframe: RetirementTimeframe
}) => {
    const offsetTypeCopy = useMemo(() => {
        if (retirementTimeframe === `instant`) {
            return {
                icon: BoltOutlinedIcon,
                title: `Instant retirement`,
                description: `Credits will be retired and certificate issued upon payment`,
            }
        }
        if (retirementTimeframe === `future`) {
            return {
                icon: HourglassEmptyOutlinedIcon,
                title: `Future retirement`,
                description: `Credits will be delivered and retired in the future. Receive Allocation certificate upon payment`,
            }
        }
        return {
            icon: EventOutlinedIcon,
            title: `Near term retirement`,
            description: `Lune will retire credits within 1-2 days (sometimes 1-2 weeks) of payment`,
        }
    }, [retirementTimeframe])

    return (
        <CarbonCreditsListItem
            Icon={offsetTypeCopy.icon}
            title={offsetTypeCopy.title}
            description={offsetTypeCopy.description}
        />
    )
}

export default CarbonCreditRetirement
