import { SustainabilityPage } from '@lune-climate/lune'
import _ from 'lodash'
import { FC, ReactNode, useEffect, useMemo } from 'react'
import { debounce } from 'throttle-debounce'

import { IAccountFormData } from 'components/AccountForm/AccountFormWrapper'
import useAccounts from 'hooks/useAccounts'
import useHasUnsavedChanges from 'hooks/useHasUnsavedChanges'
import useIsTestMode from 'hooks/useIsTestMode'
import { ApiKeyFormModel } from 'views/Developers/APIKeys/CreateApiKey'

const FormChangesDetector: FC<{
    initialValues: IAccountFormData | SustainabilityPage | ApiKeyFormModel | any
    newValues?: IAccountFormData | SustainabilityPage | ApiKeyFormModel | any
    children: ReactNode
}> = ({ initialValues, newValues, children }) => {
    const { setHasUnsavedChangesState } = useHasUnsavedChanges()
    const isTestMode = useIsTestMode()
    const { activeAccount } = useAccounts()

    useEffect(() => {
        setHasUnsavedChangesState(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTestMode, activeAccount])

    const checkForChanges = useMemo(
        () =>
            debounce(200, async () => {
                setHasUnsavedChangesState(!_.isEqual(initialValues, newValues))
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [newValues, initialValues],
    )

    useEffect(() => {
        if (newValues) {
            checkForChanges()
        } else {
            setHasUnsavedChangesState(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newValues, initialValues])

    return <>{children}</>
}

export default FormChangesDetector
