import { LabeledFormSection } from '@lune-fe/lune-ui-lib'
import Stack from '@mui/material/Stack'
import { FC, ReactNode } from 'react'

const ProposalRequestLabeledFormSection: FC<{
    label: string
    subLabel?: string
    children: ReactNode
}> = ({ label, children, subLabel }) => {
    return (
        <LabeledFormSection title={label} subTile={subLabel}>
            <Stack
                direction="column"
                sx={{
                    width: '100%',
                }}
                spacing={{
                    xs: 1 / 2,
                }}
            >
                {children}
            </Stack>
        </LabeledFormSection>
    )
}

export default ProposalRequestLabeledFormSection
