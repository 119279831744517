import type { SxProps } from '@mui/material'
import MuiInput from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/system'
import React, { forwardRef, useMemo } from 'react'
import NumberFormat from 'react-number-format'

import { fontFamily, LuneTheme } from '../theme'

import FieldValidationError from './FieldValidationError'

// eslint-disable-next-line complexity
const Input = ({
    id,
    name,
    disabled,
    error,
    hint,
    placeholder,
    type,
    onChange,
    onKeyDown,
    onBlur,
    onFocus,
    className,
    wide,
    percentage,
    startAdornment,
    endAdornment,
    sx,
    highlightOnFocus,
    autoFocus,
    tabIndex,
    ...rest
}: {
    id?: string
    disabled?: boolean
    error?: string | boolean
    hint?: string
    placeholder?: string
    type?: 'text' | 'password' | 'checkbox' | 'number'
    name?: string
    onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement | HTMLInputElement>
    onBlur?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
    value?: string | number
    percentage?: boolean
    className?: string
    wide?: boolean
    startAdornment?: React.ReactNode
    endAdornment?: React.ReactNode
    sx?: SxProps
    highlightOnFocus?: boolean
    autoFocus?: boolean
    tabIndex?: number
}) => {
    const { palette } = LuneTheme

    const StyledInput = useMemo(
        () =>
            styled(MuiInput)(
                LuneTheme.unstable_sx({
                    fontFamily,
                    px: 1.5,
                    height: `56px`,
                    fontSize: `1rem`,
                    borderRadius: `8px`,
                    border: `none !Important`,
                    color: `Grey900`,
                    backgroundColor: `Grey100`,
                    ...(wide && {
                        width: '100%',
                    }),
                    input: {
                        fontSize: `16px`,
                        padding: '0',
                        lineHeight: '22px',
                        height: '22px',
                    },
                    '&:hover': {
                        backgroundColor: `Grey300`,
                    },
                    '&:focus-within': {
                        backgroundColor: `Grey100`,
                    },
                    '&.error': {
                        backgroundColor: `Red50`,
                    },
                    '*::placeholder': {
                        color: `${palette.Grey700} !important`,
                        opacity: '1 !important',
                    },
                    '&.disabled': {
                        backgroundColor: `Grey100`,
                        input: {
                            pointerEvents: 'none',
                            color: `Grey400`,
                            textFillColor: `${palette.Grey400} !important`,
                        },
                        '*::placeholder': {
                            color: `${palette.Grey400} !important`,
                        },
                    },
                }),
            ),
        [wide],
    )

    const StyledNumberFormat = useMemo(
        () =>
            styled(NumberFormat)(
                LuneTheme.unstable_sx({
                    width: `100%`,
                    height: `100%`,
                    border: 'none',
                    backgroundColor: 'transparent',
                    '&:focus': { outline: `none;` },
                }),
            ),
        [],
    )

    const NumberFormatPercent = useMemo(
        () =>
            forwardRef<
                NumberFormat<any>,
                {
                    onChange: (event: { target: { name: string; value: string } }) => void
                    onBlur: (event: { target: { name: string; value: string } }) => void
                    name: string
                }
            >(function NumberFormatCustom(props, ref) {
                const { onChange, onBlur, ...other } = props
                return (
                    <StyledNumberFormat
                        allowNegative={false}
                        allowEmptyFormatting={true}
                        allowLeadingZeros={false}
                        defaultValue={0}
                        onBlur={onBlur}
                        isAllowed={(values) => {
                            const { floatValue } = values
                            if (!floatValue) {
                                return true
                            }
                            return floatValue >= 0 && floatValue <= 100
                        }}
                        suffix="%"
                        {...other}
                        getInputRef={ref}
                        onValueChange={(values) => {
                            onChange({
                                target: {
                                    name: ``,
                                    value: values.value,
                                },
                            })
                        }}
                    />
                )
            }),
        [StyledNumberFormat],
    )

    return (
        <>
            <StyledInput
                autoFocus={autoFocus}
                onKeyDown={onKeyDown}
                {...(id && { id })}
                {...(placeholder && { placeholder })}
                {...(onChange && { onChange })}
                {...(onBlur && { onBlur })}
                {...(name && { name })}
                inputProps={{ tabIndex: tabIndex || 0 }}
                onFocus={(e) => {
                    if (highlightOnFocus) {
                        e.target.select()
                    }
                    onFocus?.(e)
                }}
                className={`${className} ${disabled ? 'disabled' : error ? 'error' : ''}`}
                type={type ?? 'text'}
                disabled={disabled ?? false}
                disableUnderline={true}
                sx={
                    type === `number` || percentage
                        ? {
                              ...sx,
                              input: { textAlign: 'right' },
                          }
                        : sx
                }
                {...(percentage && {
                    inputComponent: NumberFormatPercent as any,
                })}
                {...(startAdornment && {
                    startAdornment: (
                        <InputAdornment
                            position="start"
                            sx={{
                                '.MuiTypography-root': {
                                    color: palette.Grey600,
                                    fontSize: `16px`,
                                    lineHeight: '22px',
                                    height: '22px',
                                },
                            }}
                        >
                            {startAdornment}
                        </InputAdornment>
                    ),
                })}
                {...(endAdornment && {
                    endAdornment: (
                        <InputAdornment
                            position="end"
                            sx={{
                                '.MuiTypography-root': {
                                    color: palette.Grey600,
                                    fontSize: `16px`,
                                    lineHeight: '22px',
                                    height: '22px',
                                },
                            }}
                        >
                            {endAdornment}
                        </InputAdornment>
                    ),
                })}
                {...rest}
            />
            {(!error || typeof error !== `string`) && hint && (
                <Typography
                    variant={'caption'}
                    sx={{
                        display: 'block',
                        color: palette.Grey600,
                        mt: 1,
                    }}
                >
                    {hint}
                </Typography>
            )}
            {typeof error === `string` && <FieldValidationError error={error} />}
        </>
    )
}

export default Input
