import { Button, NoResultsPlaceholder, Text } from '@lune-fe/lune-ui-lib'
import InsertInvitationOutlinedIcon from '@mui/icons-material/InsertInvitationOutlined'
import { Box } from '@mui/material'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

const FailedProposal: FC = () => {
    const navigate = useNavigate()
    return (
        <>
            <Box sx={{ height: '504px' }}>
                <NoResultsPlaceholder
                    message={
                        <Text variant={'h6'} sx={{ textAlign: 'center' }}>
                            Unfortunately we cannot recommend <br />
                            any portfolios that match <br />
                            your requirements
                        </Text>
                    }
                />
            </Box>
            <Box>
                <Button
                    variant={'contained'}
                    leftIcon={<InsertInvitationOutlinedIcon />}
                    sx={{ mr: 1 }}
                    onClick={() =>
                        window.open('https://meetings-eu1.hubspot.com/erik-stadigh', '_blank')
                    }
                >
                    Book a meeting
                </Button>
                <Button variant={'outlined'} onClick={() => navigate('/proposal-request')}>
                    Request new proposal
                </Button>
            </Box>
        </>
    )
}

export default FailedProposal
