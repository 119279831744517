import { useEffect, useRef } from 'react'

export default function useUpdateEffect(cb: any, dependencyArray: any[]) {
    const isMounted = useRef(false)

    useEffect(() => {
        if (isMounted.current) {
            return cb()
        }
        isMounted.current = true
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencyArray)
}
