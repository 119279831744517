import { LuneTheme, RadioButtonGroup, useIsMobile } from '@lune-fe/lune-ui-lib'
import { ClickAwayListener } from '@mui/material'
import Box from '@mui/material/Box'
import Popper from '@mui/material/Popper'

import useBuyOffsetsState from 'hooks/useBuyOffsetsState'
import { EditType } from 'models/order'
import RoundDownToTonne from 'views/BuyOffsets/RoundDownToTonne'

/**
 * Advanced settings popup menu based on MUI's Popper component
 * @param anchorElement - the element to anchor the popup menu. The menu will be positioned right above this element
 * @param onClickAway - callback to close the popup menu
 * @constructor
 */
const AdvancedSettingsMenu = ({
    anchorElement,
    onClickAway,
    customizedPortfolio,
}: {
    anchorElement: HTMLElement | null
    onClickAway: () => void
    customizedPortfolio: boolean
}) => {
    const { palette } = LuneTheme
    const { buyOffsetProps, setBuyOffsetProps } = useBuyOffsetsState()
    const { truncateToTonnes } = buyOffsetProps
    const { isMobile } = useIsMobile()

    return (
        <Popper
            data-testid={`advanced-settings-menu`}
            placement={isMobile ? 'top' : 'top-start'}
            open={Boolean(anchorElement)}
            anchorEl={anchorElement}
            disablePortal
            sx={{
                backgroundColor: 'transparent',
                zIndex: 1400,
                position: 'relative',
                marginLeft: '-6px !important',
            }}
            modifiers={[
                {
                    name: 'offset',
                    options: {
                        offset: [8, 8],
                    },
                },
            ]}
        >
            <ClickAwayListener onClickAway={onClickAway}>
                <Box
                    sx={{
                        mr: 1,
                        p: 3,
                        boxShadow: 12,
                        borderRadius: '24px',
                        display: `flex`,
                        flexDirection: 'column',
                        width: isMobile ? 'initial' : '440px',
                        backgroundColor: 'white',
                    }}
                >
                    {customizedPortfolio && (
                        <Box
                            sx={{
                                borderBottom: `1px solid ${palette.Grey300}`,
                                mb: 2,
                                pb: 2,
                            }}
                        >
                            <RadioButtonGroup
                                name={'Edit type'}
                                defaultValue={EditType.EDIT_BY_PERCENTAGE}
                                value={buyOffsetProps.editType}
                                items={[
                                    {
                                        label: 'Edit by % of volume',
                                        value: EditType.EDIT_BY_PERCENTAGE,
                                    },
                                    {
                                        label: 'Edit by volume',
                                        value: EditType.EDIT_BY_VOLUME,
                                    },
                                ]}
                                onChange={(val) => {
                                    const selectedValue = (val.target as HTMLInputElement).value
                                    setBuyOffsetProps({
                                        editType: selectedValue as EditType,
                                    })
                                }}
                            />
                        </Box>
                    )}
                    <RoundDownToTonne
                        truncateToTonnes={!!truncateToTonnes}
                        setTruncateToTonnes={(truncate) =>
                            setBuyOffsetProps({
                                truncateToTonnes: truncate,
                                error: undefined,
                            })
                        }
                    />
                </Box>
            </ClickAwayListener>
        </Popper>
    )
}

export default AdvancedSettingsMenu
