import { Bundle } from '@lune-climate/lune'
import { LuneTheme } from '@lune-fe/lune-ui-lib'
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined'
import { Box } from '@mui/material'
import { ReactNode, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import Map from 'components/Map/Map'
import MapMarker from 'components/Map/MapMarker'
import { BundlesView } from 'views/Projects/ProjectBundles/ListMapSwitcher'

import 'mapbox-gl/dist/mapbox-gl.css'

interface MapState {
    zoom: number
    latitude: number
    longitude: number
}

const ProjectsMap = ({
    view,
    bundles,
    queryParams,
    emptyState,
}: {
    view: string
    bundles: Bundle[]
    queryParams: string | undefined
    emptyState?: ReactNode
}) => {
    const { palette } = LuneTheme
    const location = useLocation()
    const initialMapState = location.state?.mapState
    const navigate = useNavigate()
    const [mapState, setMapState] = useState<MapState>({
        zoom: initialMapState?.zoom || 2.2,
        latitude: initialMapState?.latitude || 25.7465,
        longitude: initialMapState?.longitude || -30.4629,
    })

    return (
        <Box
            sx={{
                opacity: view === BundlesView.BUNDLES_MAP ? '1' : '0',
                pointerEvents: view === BundlesView.BUNDLES_MAP ? 'all' : 'none',
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: view === BundlesView.BUNDLES_MAP ? '1' : '-1',
                background: 'lightblue',
                width: '100%',
                height: '100vh',
            }}
        >
            {emptyState && bundles.length === 0 && (
                <Box
                    sx={{
                        position: 'absolute',
                        margin: 'auto',
                        zIndex: 1,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        top: 0,
                        height: 'max-content',
                        width: 'max-content',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}
                >
                    {emptyState}
                </Box>
            )}
            <Map
                onMove={(moveEvent) => {
                    setMapState(moveEvent.viewState)
                }}
                mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN!}
                zoom={mapState.zoom}
                initialPosition={{
                    latitude: mapState.latitude,
                    longitude: mapState.longitude,
                }}
            >
                {bundles.map((bundle) => {
                    return bundle.projects.map((project) => {
                        return (
                            <MapMarker
                                key={`p-${project.id}`}
                                latitude={project.latitude}
                                longitude={project.longitude}
                                src={project.logo!}
                                title={project.name}
                                subTitle={bundle.name}
                                onClick={() =>
                                    navigate(
                                        `/projects/${project.slug}?mapView=true&${
                                            queryParams ?? ''
                                        }`,
                                        {
                                            state: {
                                                mapState,
                                            },
                                        },
                                    )
                                }
                            >
                                {project.logo ? undefined : (
                                    <PinDropOutlinedIcon
                                        sx={{
                                            width: '32px',
                                            height: '32px',
                                            margin: 'auto',
                                            borderRadius: '80px',
                                            backgroundColor: palette.Grey900,
                                            color: palette.White,
                                        }}
                                    />
                                )}
                            </MapMarker>
                        )
                    })
                })}
            </Map>
        </Box>
    )
}

export default ProjectsMap
