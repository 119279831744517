import { Url } from '@lune-climate/lune'
import { Button, MainLayoutContainer, Text } from '@lune-fe/lune-ui-lib'
import Check from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import { FormApi } from 'final-form'
import { useSnackbar } from 'notistack'
import { useEffect, useMemo, useState } from 'react'
import { flushSync } from 'react-dom'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import FormChangesDetector from 'components/FormChangesDetector'
import useHasUnsavedChanges from 'hooks/useHasUnsavedChanges'
import useIsTestMode from 'hooks/useIsTestMode'
import { useLuneClient } from 'hooks/useLuneClient'
import useMixpanel from 'hooks/useMixpanel'
import { queryKeys } from 'queryKeys'
import { SnackbarMessages } from 'SnackbarMessages'
import SecretTokenModal from 'views/Developers/SecretTokenModal'
import CreateWebhookForm, {
    WebhookFormKeys,
    WebhookFormModel,
} from 'views/Developers/Webhooks/CreateWebhookForm'

const CreateWebhook = () => {
    const navigate = useNavigate()
    const isTestMode = useIsTestMode()
    const reactQueryClient = useQueryClient()

    const { enqueueSnackbar: snackbar } = useSnackbar()
    const [secretToken, setSecretToken] = useState<string>()
    const [formApi, setFormApi] = useState<FormApi<WebhookFormModel>>()
    const [formValue, setFormValue] = useState<WebhookFormModel>()
    const [urlError, setUrlError] = useState<string>()
    const { setHasUnsavedChangesState } = useHasUnsavedChanges()
    const mixpanel = useMixpanel()
    const luneClient = useLuneClient()

    const onSubmit = async (values: WebhookFormModel) => {
        const url = values[WebhookFormKeys.URL] as any as Url
        luneClient
            .createWebhook({ url })
            .then((res) => {
                if (res.isOk()) {
                    mixpanel.track('webhook_created')
                    setSecretToken(res.value.secret)
                    reactQueryClient.invalidateQueries(queryKeys.GET_WEBHOOKS)
                } else {
                    const err =
                        'errors' in res.error && res.error.errors ? res.error.errors[0] : undefined
                    if (err?.errorCode === 'validation_error') {
                        setUrlError('Invalid URL')
                    } else if (err?.errorCode === 'webhook_limit_reached') {
                        snackbar(err.message)
                    }
                }
            })
            .catch(() => {
                snackbar(SnackbarMessages.GENERIC_ERROR)
            })
    }

    const shouldDisableSaveButton = useMemo(() => {
        return !formApi || !formApi.getState().dirty || !formApi.getState().valid || !!urlError
    }, [formApi, urlError])

    useEffect(() => {
        setUrlError(undefined)
    }, [formValue])

    return (
        <MainLayoutContainer
            headerComponent={
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            mb: 6,
                        }}
                    >
                        <Button
                            variant="text"
                            iconButton
                            leftIcon={<CloseIcon />}
                            sx={{
                                ml: -1.5,
                            }}
                            onClick={() => navigate('/developers')}
                        />
                        <Button
                            leftIcon={<Check />}
                            type="submit"
                            onClick={() => formApi?.submit()}
                            data-testid={`create-webhook-btn`}
                            disabled={shouldDisableSaveButton}
                        >
                            Save
                        </Button>
                    </Box>
                    <Text variant="h4">New {isTestMode ? 'Test' : 'Live'} Webhook</Text>
                </Box>
            }
        >
            <FormChangesDetector initialValues={{}} newValues={formValue}>
                <CreateWebhookForm
                    urlError={urlError}
                    initialValues={{}}
                    onSubmit={onSubmit}
                    setFormApi={setFormApi}
                    setFormValue={setFormValue}
                />
            </FormChangesDetector>
            {secretToken && (
                <SecretTokenModal
                    secretToken={secretToken}
                    onClose={() => {
                        // When you call a state update function, the state does not immediately change, but it's scheduled the to happen sometime in the future
                        // flushSync is used for synchronously flushing updates, meaning everything within flushSync is processed right away before moving to the next line of code.
                        // The flushSync call is forcing setHasUnsavedChangesState(false) to update synchronously.
                        // This means that it is fully executed and completed before the function call returns,
                        // ensuring that the state value hasUnsavedChangesState has been updated to false before moving on to the navigate function.
                        flushSync(() => {
                            setHasUnsavedChangesState(false)
                        })
                        navigate(`/developers#webhooks`)
                    }}
                />
            )}
        </MainLayoutContainer>
    )
}

export default CreateWebhook
