import {
    Button,
    LabeledFormSection,
    LoadingWrapper,
    MemberInfoCard,
    Modal,
    Select,
    StandardModalHeader,
    Text,
    Tile,
} from '@lune-fe/lune-ui-lib'
import ClearIcon from '@mui/icons-material/Clear'
import { useSnackbar } from 'notistack'
import { useCallback, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'

import ConditionalTooltip from 'components/ConditionalTooltip'
import NotAdminTooltip from 'components/NotAdminTooltip'
import {
    demoteOrganisationAdmin,
    promoteOrganisationAdmin,
    removeFromOrganisation,
    removeInviteFromOrganisation,
} from 'endpoints/dapi'
import useActiveOrganisation from 'hooks/useActiveOrganisation'
import useUserState, { useIsAuthorised } from 'hooks/useUserState'
import { Invite, UserWithLinkedStatus } from 'models/openDapi'
import { queryKeys } from 'queryKeys'
import { SnackbarMessages } from 'SnackbarMessages'
import { assertType } from 'utils'
import { Role } from 'views/Settings/OrganisationSettings/OrganisationSettings'

type RemovalCandidate = {
    type: 'user' | 'invite'
    id: string
}

const isUser = (obj: UserWithLinkedStatus | Invite): obj is UserWithLinkedStatus => {
    return 'user' in obj && 'linkedStatus' in obj
}

const fullname = (firstname: string | undefined, lastname: string | undefined): string => {
    return firstname && lastname ? `${firstname} ${lastname}` : `Unknown`
}

const userSorter = (l: UserWithLinkedStatus | Invite, r: UserWithLinkedStatus | Invite) => {
    // sort by firstname, lastname, email
    const normalise = (
        item: UserWithLinkedStatus | Invite,
    ): { firstname: string; lastname: string; email: string } => {
        const defaultValues = {
            firstname: 'zzzz',
            lastname: 'zzzz',
        }
        if (isUser(item)) {
            return {
                firstname: item.user.firstname || defaultValues.firstname,
                lastname: item.user.lastname || defaultValues.lastname,
                email: item.user.email,
            }
        }
        return {
            ...defaultValues,
            email: item.email,
        }
    }

    const lhs = normalise(l)
    const rhs = normalise(r)

    if (lhs.firstname < rhs.firstname) {
        return -1
    }
    if (lhs.lastname < rhs.lastname) {
        return -1
    }
    return lhs.email < rhs.email ? -1 : 1
}

const TeamUser = ({
    admins,
    user: {
        user: { id, email, firstname, lastname, avatar },
        linkedStatus: { isAdmin },
    },
    onUpdateRole,
    onRemove,
}: {
    admins?: UserWithLinkedStatus[]
    user: UserWithLinkedStatus
    onUpdateRole: (arg: { userId: string; role: Role }) => void
    onRemove: (arg: { id: string; type: 'user' }) => void
}) => {
    const { userState } = useUserState()
    const isAuthorised = useIsAuthorised()
    const selectRoleIsDisabled = useMemo(() => {
        return (!!admins?.length && id === admins[0].user.id) || !isAuthorised
    }, [admins, id, isAuthorised])

    return (
        <span>
            <Tile
                rightSide={
                    <ConditionalTooltip show={selectRoleIsDisabled} tooltip={NotAdminTooltip}>
                        <Select
                            disabled={selectRoleIsDisabled}
                            items={[
                                {
                                    label: 'Admin',
                                    value: Role.ADMIN,
                                },
                                {
                                    label: 'User',
                                    value: Role.USER,
                                },
                            ]}
                            value={isAdmin ? Role.ADMIN : Role.USER}
                            onChange={(value) =>
                                onUpdateRole({
                                    userId: id,
                                    role: value,
                                })
                            }
                            sx={{
                                mr: 2,
                            }}
                        />
                    </ConditionalTooltip>
                }
                button={
                    isAuthorised ? (
                        <Button
                            disabled={!isAuthorised}
                            variant="text"
                            iconButton
                            leftIcon={<ClearIcon />}
                            onClick={() => onRemove({ id, type: 'user' })}
                        />
                    ) : undefined
                }
            >
                <MemberInfoCard
                    src={avatar}
                    name={fullname(firstname, lastname)}
                    suffix={userState?.user.id === id ? `(You)` : undefined}
                    email={email}
                />
            </Tile>
        </span>
    )
}

const TeamInvite = ({
    invite: { id, email },
    onRemove,
}: {
    invite: { id: string; email: string }
    onRemove: (arg: { id: string; type: 'invite' }) => void
}) => {
    const isAuthorised = useIsAuthorised()
    return (
        <span>
            <Tile
                rightSide={
                    <Text
                        variant={'body2'}
                        sx={{
                            padding: '12px',
                            width: '160px',
                            mr: 2,
                            boxSizing: 'border-box',
                            color: `Grey900`,
                        }}
                    >
                        Invitation sent
                    </Text>
                }
                button={
                    isAuthorised ? (
                        <Button
                            variant="text"
                            iconButton
                            leftIcon={<ClearIcon />}
                            onClick={() => onRemove({ id, type: 'invite' })}
                        />
                    ) : undefined
                }
            >
                <MemberInfoCard name={``} email={email} />
            </Tile>
        </span>
    )
}

const ManageTeam = () => {
    const client = useQueryClient()
    const { activeOrgUsers, activeOrg, activeOrgInvites, loadingActiveOrg } =
        useActiveOrganisation()
    const [removalCandidate, setRemovalCandidate] = useState<RemovalCandidate>()
    const { enqueueSnackbar: snackbar } = useSnackbar()

    const attemptingToRemoveLastAdmin = useMemo(() => {
        const admins = activeOrgUsers?.filter((u) => u.linkedStatus.isAdmin === true)

        return (
            admins?.length === 1 &&
            removalCandidate?.type === 'user' &&
            removalCandidate.id === admins[0].user.id
        )
    }, [removalCandidate, activeOrgUsers])

    const removeUser = useCallback(
        ({ type, id }: RemovalCandidate) => {
            if (type === 'user') {
                const orgId = activeOrg?.id!
                removeFromOrganisation(orgId, id)
                    .then(() => {
                        snackbar(SnackbarMessages.MANAGE_TEAM_REMOVE_USER_SUCCESS)
                        client.invalidateQueries(queryKeys.GET_ORGANISATION_USERS)
                    })
                    .catch(() => {
                        snackbar(SnackbarMessages.MANAGE_TEAM_REMOVE_USER_FAIL)
                    })
            } else {
                assertType<'invite'>(type)
                const orgId = activeOrg?.id!
                removeInviteFromOrganisation(orgId, id)
                    .then(() => {
                        snackbar(SnackbarMessages.MANAGE_TEAM_REMOVE_INVITE_SUCCESS)
                        client.invalidateQueries(queryKeys.GET_ORGANISATION_USERS)
                    })
                    .catch(() => {
                        snackbar(SnackbarMessages.MANAGE_TEAM_REMOVE_INVITE_FAIL)
                    })
            }
        },
        [activeOrg, client, snackbar],
    )

    const updateUserRole = useCallback(
        ({ userId, role }: { userId: string; role: Role }) => {
            const orgId = activeOrg?.id!
            const updatePromise =
                role === Role.ADMIN
                    ? promoteOrganisationAdmin(orgId, userId)
                    : demoteOrganisationAdmin(orgId, userId)

            updatePromise
                .then(() => {
                    snackbar(SnackbarMessages.SETTINGS_SAVED)
                    client.invalidateQueries(queryKeys.GET_ORGANISATION_USERS)
                })
                .catch(() => {
                    snackbar(SnackbarMessages.MANAGE_TEAM_UPDATE_ROLE_FAIL)
                })
        },
        [activeOrg, snackbar, client],
    )

    const users: (UserWithLinkedStatus | Invite)[] = useMemo(
        () => [...(activeOrgUsers ?? []), ...(activeOrgInvites ?? [])].sort(userSorter),
        [activeOrgUsers, activeOrgInvites],
    )

    return (
        <>
            <LabeledFormSection
                title={`Team`}
                sx={{
                    mt: 10.5,
                    mb: 9,
                }}
            >
                <LoadingWrapper loading={loadingActiveOrg}>
                    {users.map((obj: UserWithLinkedStatus | Invite) => {
                        if (isUser(obj)) {
                            return (
                                <TeamUser
                                    key={`user-${obj.user.id}`}
                                    user={obj}
                                    onUpdateRole={updateUserRole}
                                    onRemove={setRemovalCandidate}
                                />
                            )
                        } else {
                            return (
                                <TeamInvite
                                    key={`invite-${obj.id}`}
                                    invite={obj}
                                    onRemove={setRemovalCandidate}
                                />
                            )
                        }
                    })}
                </LoadingWrapper>
            </LabeledFormSection>

            <Modal
                maxWidth={'sm'}
                fullWidth
                open={!!removalCandidate && !attemptingToRemoveLastAdmin}
                header={<Text variant={'h5'}>Remove user from this organisation?</Text>}
                onClose={() => setRemovalCandidate(undefined)}
                actions={
                    <>
                        <Button
                            variant={'outlined'}
                            wide
                            onClick={() => setRemovalCandidate(undefined)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant={'contained'}
                            onClick={() => {
                                removeUser(removalCandidate!)
                                setRemovalCandidate(undefined)
                            }}
                            wide
                        >
                            Remove
                        </Button>
                    </>
                }
            >
                <span />
            </Modal>

            <Modal
                maxWidth={'sm'}
                fullWidth
                onClose={() => setRemovalCandidate(undefined)}
                open={!!removalCandidate && attemptingToRemoveLastAdmin}
                header={<StandardModalHeader title={`Choose another admin first`} />}
                subheader={
                    <Text variant={'body1'}>
                        Before removing/demoting this person, choose another one as Admin.
                    </Text>
                }
                actions={
                    <>
                        <Button
                            variant={'outlined'}
                            wide
                            onClick={() => setRemovalCandidate(undefined)}
                        >
                            OK
                        </Button>
                    </>
                }
            >
                <span />
            </Modal>
        </>
    )
}

export default ManageTeam
