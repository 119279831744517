import { Webhook } from '@lune-climate/lune'
import { SuccessResponse } from '@lune-climate/lune/esm/core/SuccessResponse'
import { useQuery } from 'react-query'
import { UseQueryOptions } from 'react-query/types/react/types'

import { useLuneClient } from 'hooks/useLuneClient'
import { queryKeys } from 'queryKeys'

const useWebhooks = (options?: UseQueryOptions<any>) => {
    const luneClient = useLuneClient()
    const { isLoading, data, refetch } = useQuery<SuccessResponse<Webhook[]>>(
        queryKeys.GET_WEBHOOKS,
        // TODO filter by account type here..
        async () => (await luneClient.listAllWebhooks()).unwrap(),
        options,
    )

    const webhooks = data?.notAnObject ?? []

    return {
        refetch,
        isLoadingWebhooks: isLoading,
        webhooks,
    }
}

export default useWebhooks
