import { Button, ListItemLayout, Tile } from '@lune-fe/lune-ui-lib'
import ApprovalOutlinedIcon from '@mui/icons-material/ApprovalOutlined'

import ConditionalTooltip from 'components/ConditionalTooltip'
import NotAdminTooltip from 'components/NotAdminTooltip'

const BeneficiaryTile = ({
    beneficiary,
    onEdit,
    isReadOnly,
}: {
    isReadOnly?: boolean
    onEdit: () => void
    beneficiary?: string
}) => {
    const button = (
        <ConditionalTooltip show={!!isReadOnly} tooltip={NotAdminTooltip}>
            <Button
                data-testid={`change-beneficiary`}
                variant={'text'}
                onClick={onEdit}
                disabled={isReadOnly}
            >
                {beneficiary ? 'Change' : 'Add'}
            </Button>
        </ConditionalTooltip>
    )

    return (
        <Tile button={button}>
            <ListItemLayout
                image={<ApprovalOutlinedIcon />}
                title={beneficiary || `Add a beneficiary`}
            />
        </Tile>
    )
}

export default BeneficiaryTile
