import { RefObject } from 'react'

export enum ScrollPositionKeys {
    PROJECT_BUNDLES = 'projectBundlesScrollPosition',
    EMISSION_CALCULATION = 'emissionCalculationPosition',
    PROPOSAL_VIEW = 'proposalViewPosition',
}

const useScrollRestoration = () => {
    const clearScrollSession = (key: string) => {
        sessionStorage.removeItem(key)
    }

    const restoreScroll = (scrollRef: RefObject<HTMLDivElement>, key: string) => {
        const scrollPosition = sessionStorage.getItem(key)
        if (scrollPosition) {
            scrollRef.current!.scrollTo({ top: parseInt(scrollPosition) })
            // using timeout to clear scroll session after scrolling was performed
            setTimeout(() => {
                clearScrollSession(key)
            }, 300)
        }
    }
    const saveScrollPosition = (scrollRef: RefObject<HTMLDivElement>, key: string) => {
        const scrollTop = scrollRef.current!.scrollTop
        sessionStorage.setItem(key, scrollTop.toString())
    }

    return {
        clearScrollSession,
        restoreScroll,
        saveScrollPosition,
    }
}

export default useScrollRestoration
