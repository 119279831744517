import { ContainerShippingMethod, ShippingMethod } from '@lune-climate/lune'
import { Switch, Text } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'

import TradeLane from 'views/CalculateEmissions/Logistics/TradeLane'

const ContainerShippingComp = ({
    value,
    onChange,
}: {
    value: ContainerShippingMethod
    onChange: (v: ShippingMethod) => void
}) => {
    return (
        <>
            <TradeLane
                value={value.tradeLane}
                onChange={(tradeLane) =>
                    onChange({
                        ...value,
                        tradeLane: tradeLane as ContainerShippingMethod['tradeLane'],
                    })
                }
            />
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    height: '56px',
                    mt: 1 / 2,
                    px: 1,
                }}
            >
                <Text
                    variant={`body3`}
                    sx={{
                        fontSize: '16px',
                    }}
                >
                    Refrigerated
                </Text>
                <Switch
                    defaultChecked={value.refrigerated}
                    onChange={(event, checked) =>
                        setTimeout(() => {
                            onChange({
                                ...value,
                                refrigerated: checked,
                            })
                        }, 300)
                    }
                />
            </Box>
        </>
    )
}

export default ContainerShippingComp
