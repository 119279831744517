import type { SxProps } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import React, { FC } from 'react'

import { LuneTheme } from '../theme'

const BannerMessage: FC<
    (
        | {
              message: string
          }
        | { children: React.ReactNode }
    ) & {
        sx?: SxProps
        textSx?: SxProps
    }
> = (props) => {
    const { sx, textSx } = props
    const { palette } = LuneTheme

    return (
        <Box
            sx={{
                minHeight: '56px',
                backgroundColor: palette.Yellow100,
                padding: '8px 16px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                borderRadius: 1,
                mb: 6,
                boxSizing: 'border-box',
                ...(sx || {}),
            }}
        >
            {'message' in props ? (
                <Typography variant={'body2'} sx={textSx}>
                    {props.message}
                </Typography>
            ) : (
                props.children
            )}
        </Box>
    )
}

export default BannerMessage
