import { SeaportPicker } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'
import { memo } from 'react'
import { Field } from 'react-final-form'

const required = (value: any) => (value?.trim() ? undefined : 'This is a required field')

const RouteAsSeaportCode = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                gap: 1 / 2,
                width: '100%',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                '> div': {
                    width: '100%',
                },
            }}
        >
            <Field
                validate={required}
                name={`route.source.locode`}
                component={({ input: { onChange, value }, meta }: any) => (
                    <Box data-testid={`origin-port-code-dropdown-wrapper`}>
                        <SeaportPicker
                            placeholder={'Origin port code'}
                            value={value}
                            onChange={(e) => onChange(e)}
                            error={meta.touched && meta.error}
                        />
                    </Box>
                )}
            />
            <Field
                validate={required}
                name={`route.destination.locode`}
                component={({ input: { onChange, value }, meta }: any) => (
                    <Box data-testid={`destination-port-code-wrapper`}>
                        <SeaportPicker
                            placeholder={'Destination port code'}
                            value={value}
                            onChange={(e) => onChange(e)}
                            error={meta.touched && meta.error}
                        />
                    </Box>
                )}
            />
        </Box>
    )
}

export default memo(RouteAsSeaportCode)
