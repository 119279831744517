import {
    Button,
    CtaTile,
    LoadingWrapper,
    LuneTheme,
    MainLayoutContainer,
    SearchInput,
    Text,
} from '@lune-fe/lune-ui-lib'
import * as Icons from '@mui/icons-material'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined'
import { Box } from '@mui/material'
import { flatten } from 'lodash'
import { useEffect, useMemo, useState } from 'react'

import LinkTile from 'components/LinkTile'
import useClientAccounts from 'hooks/useClientAccounts'
import DocumentationImage from 'images/DocumentationImage.png'

const ClientAccounts = () => {
    const { palette } = LuneTheme
    const [searchKey, setSearchKey] = useState<string>()

    const { paginatedAccounts, hasNextPage, fetchNextPage, refetch } = useClientAccounts(searchKey)

    useEffect(() => {
        refetch()
    }, [refetch, searchKey])

    const handleOnSearch = (searchKey: string) => {
        setSearchKey(searchKey)
    }

    const handleOnClear = () => {
        setSearchKey(undefined)
    }

    const clientAccounts = useMemo(
        () => flatten((paginatedAccounts?.pages ?? []).map(({ data }) => data)),
        [paginatedAccounts],
    )

    return (
        <LoadingWrapper loading={!clientAccounts} sx={{ height: 1 }}>
            <MainLayoutContainer headerComponent={<Text variant="h4">Client Accounts</Text>}>
                {clientAccounts.length > 0 && (
                    <SearchInput onSearch={handleOnSearch} onClear={handleOnClear} />
                )}
                {searchKey && !clientAccounts.length ? (
                    <Text variant={'body3'} sx={{ textAlign: 'center', display: 'block' }}>
                        No Client Accounts found
                    </Text>
                ) : (
                    <>
                        {clientAccounts.map((acc) => (
                            <LinkTile
                                sx={{ height: '64px', borderRadius: '16px' }}
                                data-testid={`link-tile-${acc.id}`}
                                key={acc.id}
                                href={`/settings/client-accounts/${acc.id}`}
                                rightIcon={<Icons.ChevronRight sx={{ color: palette.Grey500 }} />}
                                icon={
                                    <Icons.AccountBalanceWalletOutlined
                                        sx={{ color: palette.Grey900 }}
                                    />
                                }
                            >
                                {acc.name}
                            </LinkTile>
                        ))}

                        {!!hasNextPage && (
                            <Button
                                sx={{ mt: 9 }}
                                data-testid={`load-more-button`}
                                variant={'outlined'}
                                leftIcon={<ArrowDownwardIcon />}
                                onClick={() => fetchNextPage()}
                            >
                                Load More
                            </Button>
                        )}
                    </>
                )}
                {clientAccounts.length > 0 && (
                    <Box sx={{ my: 9, borderTop: `1px solid ${palette.Grey300} ` }}></Box>
                )}
                <CtaTile
                    title={'Learn how to create and use Client Accounts '}
                    subTitle={'Via the Lune API'}
                    image={DocumentationImage}
                    icon={<OpenInNewOutlinedIcon sx={{ color: palette.Grey900 }} />}
                    href="https://docs.lune.co/key-concepts/client-accounts"
                    /* @ts-ignore: ... */
                    target="_blank"
                />
            </MainLayoutContainer>
        </LoadingWrapper>
    )
}

export default ClientAccounts
