import mixpanel from 'mixpanel-browser'
import { Userpilot } from 'userpilot'

import useIsTestMode from 'hooks/useIsTestMode'

const useMixpanel = () => {
    const isTestMode = useIsTestMode()

    const mode = isTestMode === undefined ? undefined : isTestMode === true ? 'test' : 'live'

    return {
        track: (event: string, properties: Record<string, any> = {}) => {
            mixpanel.track(event, { mode, ...properties })
            // we mirror mixpanel events to userpilot
            Userpilot.track(event)
        },
        alias: (id: string) => {
            mixpanel.alias(id)
        },
    }
}

export default useMixpanel
