import { Button, LuneTheme, MainLayoutContainer, Text } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'

const ErrorPage = () => {
    const { palette } = LuneTheme
    return (
        <MainLayoutContainer>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <img src="https://assets.lune.co/500.png" width="500px"></img>
                <Text variant="h4" sx={{ mt: 4 }}>
                    Oops, something went wrong
                </Text>
                <Text variant="body3" sx={{ mt: 1, color: palette.Grey600 }}>
                    Sorry, it looks like there was an error
                </Text>
                <Button
                    sx={{ mt: 6 }}
                    onClick={() => {
                        document.location.href = '/'
                    }}
                >
                    Go To Homepage
                </Button>
            </Box>
        </MainLayoutContainer>
    )
}

export default ErrorPage
