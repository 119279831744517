import { SustainabilityPage, SustainabilityPageTitle } from '@lune-climate/lune'
import { formatNumbers } from '@lune-fe/lune-components-lib'
import { LabeledFormSection, RadioButtonGroup } from '@lune-fe/lune-ui-lib'
import { Dispatch, FC, SetStateAction } from 'react'

import useAccountCurrency from 'hooks/useAccountCurrency'
import { TotalPlacedAnalytics } from 'views/SustainabilityPage/SustainabilityPageSettings'

const Title: FC<{
    options: SustainabilityPage
    setOptions: Dispatch<SetStateAction<SustainabilityPage>>
    analytics: TotalPlacedAnalytics
}> = ({ options, setOptions, analytics }) => {
    const toCurrency = useAccountCurrency(true)

    return (
        <LabeledFormSection title={`Title`} subTile={`Choose a stat for your title`}>
            <RadioButtonGroup
                name={'Title'}
                value={options.title}
                defaultValue={SustainabilityPageTitle.BY_PRICE}
                items={[
                    {
                        label: `We’ve offset ${formatNumbers(
                            analytics.totalPlacedOffsetQuantity,
                            2,
                        )} tCO₂. So far.`,
                        value: SustainabilityPageTitle.BY_VOLUME,
                    },
                    {
                        label: `We've contributed ${toCurrency?.(
                            analytics.totalPlacedOffsetValue,
                        )} to high-quality climate projects. So far.`,
                        value: SustainabilityPageTitle.BY_PRICE,
                    },
                ]}
                onChange={(val) =>
                    setOptions((prev) => {
                        return {
                            ...prev,
                            title: (val.target as HTMLInputElement)
                                .value as any as SustainabilityPageTitle,
                        }
                    })
                }
            />
        </LabeledFormSection>
    )
}

export default Title
