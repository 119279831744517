import { LuneTheme } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import { Map as ReactGlMap } from 'mapbox-gl'
import React, { FC, Ref, useEffect, useMemo, useState } from 'react'
import ReactMapGL, { MapRef, ViewStateChangeEvent } from 'react-map-gl'

export interface MapProps {
    mapboxAccessToken: string
    mapRef?: Ref<MapRef>
    children?: React.ReactNode[]
    zoom?: number
    initialPosition?: { longitude: number; latitude: number }
    onMove?: (move: ViewStateChangeEvent) => void
    globe?: boolean
    disableMapInteraction?: boolean
}

const Map: FC<MapProps> = ({
    zoom,
    mapRef,
    children,
    mapboxAccessToken,
    initialPosition,
    onMove,
    globe,
    disableMapInteraction,
}) => {
    const [mapLoaded, setMapLoaded] = useState<boolean>(false)
    const { palette } = LuneTheme
    const [mapElement, setMapElement] = useState<ReactGlMap>()

    useEffect(() => {
        if (mapElement) {
            if (disableMapInteraction) {
                mapElement.scrollZoom.disable()
                mapElement.boxZoom.disable()
                mapElement.dragRotate.disable()
                mapElement.dragPan.disable()
                mapElement.touchZoomRotate.disable()
                mapElement.doubleClickZoom.disable()
            } else {
                mapElement.scrollZoom.enable()
                mapElement.boxZoom.enable()
                mapElement.dragRotate.enable()
                mapElement.dragPan.enable()
                mapElement.touchZoomRotate.enable()
                mapElement.doubleClickZoom.enable()
            }
        }
    }, [disableMapInteraction, mapElement])

    const mapComponent = useMemo(() => {
        return (
            <ReactMapGL
                projection={globe ? { name: 'globe' } : { name: 'mercator' }}
                ref={mapRef}
                onLoad={(map) => {
                    setMapElement(map.target)
                    setMapLoaded(map.target.loaded())
                }}
                onMove={onMove}
                style={{
                    height: '100%',
                    width: '100%',
                    opacity: mapLoaded ? 1 : 0,
                    transition: 'opacity .3s cubic-bezier(0.25, 0.1, 0.25, 1)',
                }}
                mapboxAccessToken={mapboxAccessToken}
                mapStyle={'mapbox://styles/mapbox/navigation-day-v1'}
                maxZoom={20}
                initialViewState={{
                    zoom: zoom ?? 4,
                    ...(initialPosition ?? {}),
                }}
            >
                {children}
            </ReactMapGL>
        )
    }, [globe, mapRef, onMove, mapLoaded, mapboxAccessToken, zoom, initialPosition, children])

    return (
        <Box
            sx={{
                height: '100%',
                width: '100%',
                overflow: 'hidden',
                position: 'relative',
                '.mapboxgl-canvas': {
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 'absolute',
                    width: '100%',
                    height: '100%',
                },
                '.mapboxgl-marker:hover': {
                    position: 'relative',
                    zIndex: 9999,
                },
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    background: !mapLoaded ? palette.Grey300 : 'transparent',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    opacity: !mapLoaded ? 1 : 0,
                    transition: 'all .3s ease-in',
                }}
            />
            {mapComponent}
        </Box>
    )
}

export default Map
