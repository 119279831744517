import { Button, CountryPicker } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import Stack from '@mui/material/Stack'
import { ReactElement } from 'react'
import { Field, Form } from 'react-final-form'

import { FormInput } from 'components/FormInput'
import { Organisation } from 'models/openDapi'
import { BillingInfoPayload, FormKeys } from 'views/BillingInfo/BillingInfo'

const required = (value: string | undefined) =>
    value?.trim() ? undefined : 'This is a required field'

const BillingInfoForm = ({
    onSubmit,
    organisation,
    cancelButton,
}: {
    organisation: Organisation
    onSubmit: (payload: BillingInfoPayload) => void
    cancelButton?: ReactElement
}) => {
    const {
        billingName,
        billingEmail,
        legalName,
        street1,
        street2,
        postcode,
        city,
        countryCode,
        vat,
    } = organisation

    return (
        <Form
            sx={{
                width: '100%',
            }}
            onSubmit={(v: BillingInfoPayload) => onSubmit(v)}
            initialValues={{
                [FormKeys.BILLING_NAME]: billingName,
                [FormKeys.BILLING_EMAIL]: billingEmail,
                [FormKeys.LEGAL_NAME]: legalName,
                [FormKeys.ADDRESS_LINE_1]: street1,
                [FormKeys.ADDRESS_LINE_2]: street2,
                [FormKeys.POSTCODE]: postcode,
                [FormKeys.CITY]: city,
                [FormKeys.COUNTRY]: countryCode,
                [FormKeys.VAT]: vat,
            }}
            render={({ handleSubmit, valid, dirty }) => {
                return (
                    <form
                        style={{ position: `relative`, zIndex: 2, width: '100%' }}
                        onSubmit={handleSubmit}
                    >
                        <Stack
                            direction="column"
                            sx={{
                                width: '100%',
                            }}
                            spacing={{
                                xs: 4,
                            }}
                        >
                            <Box>
                                <Stack
                                    direction="column"
                                    sx={{
                                        width: '100%',
                                    }}
                                    spacing={{
                                        xs: 1,
                                    }}
                                >
                                    <Field
                                        name={FormKeys.BILLING_EMAIL}
                                        placeholder={'Email'}
                                        component={FormInput}
                                        validate={required}
                                    />
                                    <Field
                                        name={FormKeys.BILLING_NAME}
                                        placeholder={'Name and Surname'}
                                        component={FormInput}
                                        validate={required}
                                    />
                                </Stack>
                            </Box>
                            <Box>
                                <Stack
                                    direction="column"
                                    sx={{
                                        width: '100%',
                                    }}
                                    spacing={{
                                        xs: 1,
                                    }}
                                >
                                    <Field
                                        name={FormKeys.LEGAL_NAME}
                                        placeholder={'Company legal name'}
                                        component={FormInput}
                                    />
                                    <Field
                                        name={FormKeys.ADDRESS_LINE_1}
                                        placeholder={'Address line 1'}
                                        component={FormInput}
                                    />
                                    <Field
                                        name={FormKeys.ADDRESS_LINE_2}
                                        placeholder={'Address line 2'}
                                        component={FormInput}
                                    />
                                    <Field
                                        name={FormKeys.POSTCODE}
                                        placeholder={'Postcode'}
                                        component={FormInput}
                                    />
                                    <Field
                                        name={FormKeys.CITY}
                                        placeholder={'City'}
                                        component={FormInput}
                                    />
                                    <Field name={FormKeys.COUNTRY} validate={required}>
                                        {({ input: { onChange, value }, meta }: any) => (
                                            <CountryPicker
                                                data-testid={'billing-country-picker'}
                                                value={value}
                                                onChange={(e) => onChange(e)}
                                                error={meta.touched && meta.error}
                                            />
                                        )}
                                    </Field>
                                </Stack>
                            </Box>
                            <Box>
                                <Field
                                    name={FormKeys.VAT}
                                    placeholder={'VAT'}
                                    component={FormInput}
                                />
                            </Box>
                        </Stack>

                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            mt={6}
                            columnGap={1}
                        >
                            {cancelButton && cancelButton}
                            <Button
                                data-testid={`save-billing-info`}
                                variant={'contained'}
                                wide
                                disabled={!valid || !dirty}
                                type={'submit'}
                            >
                                Save
                            </Button>
                        </Box>
                    </form>
                )
            }}
        />
    )
}

export default BillingInfoForm
