import {
    CurrencyCode,
    Diet,
    TransactionEmissionEstimate,
    TransactionEstimateRequest,
} from '@lune-climate/lune'
import { useCSVFromUrl } from '@lune-fe/lune-components-lib'
import { AutocompleteSelect, BannerMessage, CountryPicker, Select } from '@lune-fe/lune-ui-lib'
import { snakeCase, startCase } from 'lodash'
import { useSnackbar } from 'notistack'
import { FC, RefObject, useCallback, useEffect, useMemo, useState } from 'react'
import { Field, FormSpy } from 'react-final-form'
import { useParams } from 'react-router-dom'

import { FormInput } from 'components/FormInput'
import useAccounts from 'hooks/useAccounts'
import { useLuneClient } from 'hooks/useLuneClient'
import { SnackbarMessages } from 'SnackbarMessages'
import {
    CalculateEmissionsFormWrapper,
    CalculateEmissionsLabeledFormSection,
    EstimateTypeEnum,
    InputWithCurrency,
} from 'views/CalculateEmissions'

export const CATEGORY_CODES_URL = 'https://assets.lune.co/transaction_codes.csv'

export interface Category {
    mcc: string
    description: string
    coicop: string
}

export enum FormKeys {
    NAME = 'name',
    MERCHANT_COUNTRY_CODE = 'merchant.countryCode',
    MERCHANT_CATEGORY_CODE = 'merchant.categoryCode',
    MERCHANT_NAME = 'merchant.name',
    DIET = 'diet',
    VALUE = 'value',
}

const required = (value: any) => (value ? undefined : 'This is a required field')

const EverydayPurchases: FC<{ scrollRef: RefObject<HTMLDivElement> }> = ({ scrollRef }) => {
    const { activeAccount } = useAccounts()
    const { loading: loadingCategoryData, data: categories } =
        useCSVFromUrl<Category>(CATEGORY_CODES_URL)
    const [loading, setLoading] = useState<boolean>(true)
    const [formValue, setFormValue] = useState<TransactionEstimateRequest>()
    const [emissionEstimate, setEmissionEstimate] = useState<TransactionEmissionEstimate>()
    const { enqueueSnackbar: snackbar } = useSnackbar()
    const luneClient = useLuneClient()
    const { id } = useParams<{ id: string }>()

    const isDietRelevant = useCallback(
        (values: TransactionEstimateRequest) => {
            if ('categoryCode' in values.merchant) {
                const cat = values.merchant.categoryCode
                const coicop = categories?.find((c) => c.mcc === cat)?.coicop
                // 01 is Food and non-alcoholic beverages, 11 is Restaurants and accommodation services.
                // This is where the diet will affect the results.
                // eslint-disable-next-line no-debugger
                return coicop && (coicop.startsWith('01') || coicop.startsWith('11'))
            }
        },
        [categories],
    )

    const categoryPicker = useMemo(
        () =>
            // eslint-disable-next-line react/display-name
            ({ input: { onChange, value }, meta }: any) => {
                const cat = categories?.find((c) => c.mcc === value)
                return (
                    <AutocompleteSelect<{
                        label: string | undefined
                        value: string | undefined
                    }>
                        error={meta.touched && meta.error}
                        label={'Product category'}
                        /* @ts-ignore: ... */
                        disableClearable
                        value={{
                            label: cat?.description || ``,
                            value: cat?.mcc || ``,
                        }}
                        onChange={(item) => onChange(item.value)}
                        items={
                            categories
                                ?.sort((a, b) => a.description.localeCompare(b.description))
                                .map((c: Category) => ({
                                    label: c.description,
                                    value: c.mcc,
                                })) || []
                        }
                    />
                )
            },
        [categories],
    )

    const defaultFormValues: TransactionEstimateRequest = useMemo(() => {
        const activeAccCurrency: CurrencyCode = activeAccount
            ? (activeAccount.currency as any as CurrencyCode)
            : CurrencyCode.GBP
        return {
            value: {
                value: `0`,
                currency: activeAccCurrency,
            },
            merchant: {
                categoryCode: ``,
                name: undefined,
                countryCode: `GBR`,
            },
            diet: undefined,
        }
    }, [activeAccount])

    useEffect(() => {
        if (id) {
            luneClient
                .getTransactionEstimate(id)
                .then((res) => {
                    if (res.isOk()) {
                        const estimate: TransactionEmissionEstimate = res.value
                        setEmissionEstimate(estimate)
                        setFormValue(estimate.request)
                    } else {
                        setFormValue(defaultFormValues)
                    }
                    setLoading(false)
                })
                .catch(() => {
                    snackbar(SnackbarMessages.GENERIC_ERROR)
                    setFormValue(defaultFormValues)
                })
        } else {
            setFormValue(defaultFormValues)
            setLoading(false)
        }
    }, [defaultFormValues, id, snackbar, luneClient])

    const createNewEstimate = async (
        luneClient: ReturnType<typeof useLuneClient>,
        values: TransactionEstimateRequest,
    ) => luneClient.createTransactionEstimate(values)

    const updateExistingEstimate = async (
        luneClient: ReturnType<typeof useLuneClient>,
        id: string,
        values: TransactionEstimateRequest,
    ) => luneClient.updateTransactionEstimate(id, values)

    return (
        <CalculateEmissionsFormWrapper<TransactionEstimateRequest>
            emissionType={EstimateTypeEnum.TRANSACTION}
            loading={!activeAccount || loading || loadingCategoryData}
            initialFormValue={formValue!}
            emissionId={id}
            onCreate={(v) => createNewEstimate(luneClient, v)}
            onUpdate={(id, v) => updateExistingEstimate(luneClient, id, v)}
            layoutScrollRef={scrollRef}
            emissionEstimateResult={emissionEstimate?.mass}
            emissionEstimateName={emissionEstimate?.request.name}
        >
            <BannerMessage
                textSx={{ whiteSpace: 'pre-line' }}
                sx={{ marginBottom: '0' }}
                message={`Dashboard calculations are simplified and only a subset of functionality is available. \nTo use Lune's full calculations functionality and all of our emissions factors, please use the API.`}
            />

            <CalculateEmissionsLabeledFormSection>
                <Field
                    sx={{ flex: '1 1 auto' }}
                    name={FormKeys.NAME}
                    placeholder={'Title (optional)'}
                    component={FormInput}
                />
            </CalculateEmissionsLabeledFormSection>

            <CalculateEmissionsLabeledFormSection label={'Product'}>
                <Field
                    sx={{ flex: '1 1 auto' }}
                    name={FormKeys.MERCHANT_CATEGORY_CODE}
                    component={categoryPicker}
                    validate={required}
                />
                <Field name={FormKeys.MERCHANT_COUNTRY_CODE} validate={required}>
                    {({ input: { onChange, value }, meta }: any) => (
                        <CountryPicker
                            value={value}
                            onChange={(e) => onChange(e)}
                            error={meta.touched && meta.error}
                        />
                    )}
                </Field>
            </CalculateEmissionsLabeledFormSection>

            <FormSpy>
                {({ values }: { values: TransactionEstimateRequest }) => {
                    return isDietRelevant(values) ? (
                        <CalculateEmissionsLabeledFormSection label={'Your diet'}>
                            <Field
                                name={FormKeys.DIET}
                                component={({ input: { onChange, value }, meta }: any) => (
                                    <Select
                                        label={'Diet'}
                                        sx={{
                                            width: '100%',
                                        }}
                                        value={value}
                                        onChange={onChange}
                                        items={Object.values(Diet).map((v) => ({
                                            label: startCase(v),
                                            value: snakeCase(v),
                                        }))}
                                        error={meta.touched && meta.error}
                                    />
                                )}
                                validate={required}
                            />
                        </CalculateEmissionsLabeledFormSection>
                    ) : (
                        <></>
                    )
                }}
            </FormSpy>

            <CalculateEmissionsLabeledFormSection label={'Amount spent'}>
                <Field
                    name={FormKeys.VALUE}
                    component={({ input: { onChange, value }, meta }: any) => (
                        <InputWithCurrency
                            onChange={onChange}
                            value={value}
                            error={meta.touched && meta.error}
                        />
                    )}
                    validate={(v) => {
                        if (!v.value || v.value <= 0) {
                            return `This should be a positive number`
                        }
                    }}
                />
            </CalculateEmissionsLabeledFormSection>
        </CalculateEmissionsFormWrapper>
    )
}

export default EverydayPurchases
