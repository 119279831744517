import { Account } from '@lune-climate/lune'
import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { useLuneClient } from 'hooks/useLuneClient'
import useUserState from 'hooks/useUserState'
import { queryKeys } from 'queryKeys'

const getAccounts = (luneClient: ReturnType<typeof useLuneClient>) =>
    luneClient.listAccounts({ limit: `100` }).then((r) => r.unwrap().data)

const useAccounts = () => {
    const luneClient = useLuneClient()
    const {
        isLoading: loading,
        data: accounts = [],
        refetch: refetchAccounts,
    } = useQuery<Account[]>(queryKeys.GET_ACCOUNTS, () => getAccounts(luneClient), {
        enabled: true,
    })
    const { userState } = useUserState()

    const activeAccount = useMemo(() => {
        return accounts.find((a) => a.id === userState?.account.id)
    }, [accounts, userState])

    return {
        refetchAccounts,
        loading,
        accounts,
        activeAccount,
    }
}

export default useAccounts
