import { Bundle } from '@lune-climate/lune'
import { Button, ButtonGroup, LuneTheme } from '@lune-fe/lune-ui-lib'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import Box from '@mui/material/Box'
import { Big } from 'big.js'
import { FC, useCallback, useMemo, useRef } from 'react'
import { Line } from 'react-chartjs-2'
import { Range } from 'react-date-range'

import useAccounts from 'hooks/useAccounts'
import useUserState from 'hooks/useUserState'
import { getCurrencySymbol } from 'models/currency'
import { OrderType } from 'models/order'
import { downloadImageFromHtml } from 'utils'
import { chartAreaBorder, chartColors, getGradient } from 'views/Dashboard/Charts/chartUtils'
import {
    BundleData,
    chartOptions,
    Dataset,
    getDateLabels,
    legendBottomMargin,
} from 'views/Dashboard/Charts/OrdersChartConfig'
import Totals from 'views/Dashboard/Totals'

import 'chart.js/auto'
import 'chartjs-adapter-moment'

const OrdersChart: FC<{
    analyticsType: OrderType
    data: BundleData[]
    dateRange: Range
    allBundles: Bundle[]
    setAnalyticsType: (type: OrderType) => void
}> = ({ analyticsType, data, dateRange, allBundles, setAnalyticsType }) => {
    const { userState } = useUserState()
    const { palette } = LuneTheme
    const { activeAccount } = useAccounts()
    const printRef = useRef<HTMLElement>(null)

    const getBundleColor = useCallback(
        (bundle: BundleData) => {
            const b = allBundles.find((b) => b.id === bundle.bundleId)
            return b ? b.backgroundColour : palette.Grey600
        },
        [allBundles, palette.Grey600],
    )

    const chartData = useMemo(() => {
        const valueOrQuantityData = (data: Dataset[]) => {
            return Array.from(
                data.map((d) => ({
                    x: d.date,
                    y: parseFloat(
                        Big(analyticsType === OrderType.VALUE ? d.value : d.quantity).toFixed(2),
                    ).toString(),
                })),
            )
        }

        return {
            labels: getDateLabels(data),
            datasets: data.map((bundle, index) => ({
                label: bundle.bundleName,
                data: valueOrQuantityData(bundle.timeseries),
                fill: true,
                borderColor: chartColors[index] || getBundleColor(bundle) || undefined,
                backgroundColor: (context: any) => {
                    const chart = context.chart
                    const { ctx, chartArea } = chart

                    if (!chartArea) {
                        // This case happens on initial chart load
                        return
                    }
                    return getGradient(
                        ctx,
                        chartArea,
                        chartColors[index] || getBundleColor(bundle)!,
                    )
                },
                cubicInterpolationMode: 'monotone' as const,
                tension: 0.8,
            })),
        }
    }, [analyticsType, data, getBundleColor])

    return (
        <Box>
            <Box ref={printRef}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Totals data={data} analyticsType={analyticsType} />
                    <ButtonGroup
                        sx={{
                            maxWidth: '320px',
                            maxHeight: '56px',
                        }}
                        items={[
                            {
                                label: 'tCO₂',
                                value: OrderType.QUANTITY,
                                dataTestId: 'by-volume-btn',
                            },
                            {
                                label: getCurrencySymbol(userState?.account.currency || `GBP`),
                                value: OrderType.VALUE,
                                dataTestId: 'by-value-btn',
                            },
                        ]}
                        onChange={(type) => {
                            setAnalyticsType(type as OrderType)
                        }}
                        value={analyticsType}
                    />
                </Box>
                <Box
                    sx={{
                        mt: 9,
                        position: 'relative',
                        height: '450px',
                        width: '100%',
                    }}
                >
                    <Box sx={{ height: '100%', wight: '100%' }}>
                        <Line
                            options={
                                chartOptions(
                                    analyticsType,
                                    chartData,
                                    activeAccount?.currency,
                                    dateRange,
                                ) as any
                            }
                            data={chartData}
                            plugins={[legendBottomMargin, chartAreaBorder]}
                        />
                    </Box>
                </Box>
            </Box>
            <Button
                sx={{ mt: 9 }}
                onClick={() => {
                    if (printRef.current) {
                        downloadImageFromHtml(printRef.current, `orders-graph`)
                    }
                }}
                leftIcon={<FileDownloadOutlinedIcon />}
                variant={'outlined'}
            >
                Download graph
            </Button>
        </Box>
    )
}

export default OrdersChart
