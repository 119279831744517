import { AnySourceData, Map as ReactGlMap } from 'mapbox-gl'

export enum Datasets {
    FOREST_LOSS = 'FOREST_LOSS',
    BIODIVERSITY = 'BIODIVERSITY',
    SOIL_CARBON_DIVERSITY = 'SOIL_CARBON_DIVERSITY',
    MANGROVES = 'MANGROVES',
    NET_FOREST_FLUX = 'NET_FOREST_FLUX',
    OIL_PALM_CONCESSION = 'OIL_PALM_CONCESSION',
    WOOD_FIBER_CONCESSION = 'WOOD_FIBER_CONCESSION',
    INDIGENOUS_LAND = 'INDIGENOUS_LAND',
}

export enum DatasetType {
    RASTER,
    VECTOR,
}

export interface DatasetsProps {
    dataset: Datasets
    description: string
    thumbnailUrl: string
}

export interface DatasetInfo {
    dataset: Datasets
    name: string
    year?: string
    type: DatasetType
    tiles: string | { year: string; tiles: string }[]
    yearly?: boolean
    // for vector tiles
    sourceName?: string
    color?: string
}

export interface MapLegendItem {
    legend: {
        color: string[]
        label: string[]
    }[]
}

export interface Coordinate {
    lat: number
    lon: number
}

export const DatasetsListWithInfo: DatasetInfo[] = [
    {
        dataset: Datasets.INDIGENOUS_LAND,
        name: 'Indigenous lands',
        type: DatasetType.VECTOR,
        year: '2021',
        tiles: 'https://tiles.globalforestwatch.org/landmark_indigenous_and_community_lands/v20210909/default/{z}/{x}/{y}.pbf',
        sourceName: 'landmark_indigenous_and_community_lands',
    },
    {
        dataset: Datasets.BIODIVERSITY,
        name: 'Biodiversity',
        year: '2018',
        tiles: 'https://tiles.globalforestwatch.org/birdlife_biodiversity_significance/latest/default/{z}/{x}/{y}.png',
        type: DatasetType.RASTER,
    },
    {
        dataset: Datasets.SOIL_CARBON_DIVERSITY,
        name: 'Soil carbon density',
        year: '2020',
        tiles: 'https://tiles.globalforestwatch.org/gfw_soil_carbon_stocks/latest/default/{z}/{x}/{y}.png',
        type: DatasetType.RASTER,
    },
    {
        dataset: Datasets.MANGROVES,
        name: 'Mangroves cover',
        year: '2016',
        tiles: 'https://tiles.globalforestwatch.org/gmw_global_mangrove_extent/latest/default/{z}/{x}/{y}.png',
        type: DatasetType.RASTER,
    },
    {
        dataset: Datasets.NET_FOREST_FLUX,
        name: 'CO₂e net flow',
        year: '2001 – 2020',
        tiles: 'https://tiles.globalforestwatch.org/gfw_forest_carbon_net_flux/latest/tcd_30/{z}/{x}/{y}.png',
        type: DatasetType.RASTER,
    },
    {
        dataset: Datasets.OIL_PALM_CONCESSION,
        name: 'Oil palm concessions',
        year: '2023',
        tiles: 'https://tiles.globalforestwatch.org/gfw_oil_palm/latest/default/{z}/{x}/{y}.pbf',
        type: DatasetType.VECTOR,
        sourceName: 'gfw_oil_palm',
        color: '#d5a28c',
    },
    {
        dataset: Datasets.WOOD_FIBER_CONCESSION,
        name: 'Wood fiber concessions',
        year: '2019',
        tiles: 'https://tiles.globalforestwatch.org/gfw_wood_fiber/latest/default/{z}/{x}/{y}.pbf',
        type: DatasetType.VECTOR,
        sourceName: 'gfw_wood_fiber',
        color: '#a3583a',
    },
    {
        dataset: Datasets.FOREST_LOSS,
        name: 'Forest Loss',
        year: '2001 – 2020',
        tiles: [
            {
                year: '2001',
                tiles: 'https://api.resourcewatch.org/v1/layer/c8d93d0e-a00f-4fd8-a944-1ffbc64fee69/tile/gee/{z}/{x}/{y}',
            },
            {
                year: '2002',
                tiles: 'https://api.resourcewatch.org/v1/layer/7c567361-3af0-4fa8-bf34-a53b30714f47/tile/gee/{z}/{x}/{y}',
            },
            {
                year: '2003',
                tiles: 'https://api.resourcewatch.org/v1/layer/ac301d2d-ee46-435b-8899-cc19970c5d3c/tile/gee/{z}/{x}/{y}',
            },
            {
                year: '2004',
                tiles: 'https://api.resourcewatch.org/v1/layer/b9a617e6-b434-44e1-87bb-4873917498f0/tile/gee/{z}/{x}/{y}',
            },
            {
                year: '2005',
                tiles: 'https://api.resourcewatch.org/v1/layer/c8012b53-8158-4198-820a-9d29dccffd79/tile/gee/{z}/{x}/{y}',
            },
            {
                year: '2006',
                tiles: 'https://api.resourcewatch.org/v1/layer/85cd8901-d050-49b2-914f-753651c4a690/tile/gee/{z}/{x}/{y}',
            },
            {
                year: '2007',
                tiles: 'https://api.resourcewatch.org/v1/layer/d8ca2f12-d1f9-4b14-94e8-7c55a3bcf66d/tile/gee/{z}/{x}/{y}',
            },
            {
                year: '2008',
                tiles: 'https://api.resourcewatch.org/v1/layer/cc2d80a2-db3d-4922-81cc-2d50aba201a7/tile/gee/{z}/{x}/{y}',
            },
            {
                year: '2009',
                tiles: 'https://api.resourcewatch.org/v1/layer/408e6b7d-f335-41a2-bce7-9677b514c1e0/tile/gee/{z}/{x}/{y}',
            },
            {
                year: '2010',
                tiles: 'https://api.resourcewatch.org/v1/layer/470d199a-56e3-45a3-9905-6bb88dd102a9/tile/gee/{z}/{x}/{y}',
            },
            {
                year: '2011',
                tiles: 'https://api.resourcewatch.org/v1/layer/87a9e844-7760-42f5-a92c-4d8e2833498d/tile/gee/{z}/{x}/{y}',
            },
            {
                year: '2012',
                tiles: 'https://api.resourcewatch.org/v1/layer/d0c36d15-a6d0-41dc-b44f-72b1309aa664/tile/gee/{z}/{x}/{y}',
            },
            {
                year: '2013',
                tiles: 'https://api.resourcewatch.org/v1/layer/f3b3cf11-587a-4600-9d46-8f3a03056fc8/tile/gee/{z}/{x}/{y}',
            },
            {
                year: '2014',
                tiles: 'https://api.resourcewatch.org/v1/layer/4c918229-2e97-478e-bcd9-704cd50bf444/tile/gee/{z}/{x}/{y}',
            },
            {
                year: '2015',
                tiles: 'https://api.resourcewatch.org/v1/layer/b7557ca6-3382-4426-aa7f-a4e11a1a3411/tile/gee/{z}/{x}/{y}',
            },
            {
                year: '2016',
                tiles: 'https://api.resourcewatch.org/v1/layer/22043999-9f4d-4d06-87f6-e8910c75bbcf/tile/gee/{z}/{x}/{y}',
            },
            {
                year: '2017',
                tiles: 'https://api.resourcewatch.org/v1/layer/053dff4b-9d05-4462-a6f8-90e945891246/tile/gee/{z}/{x}/{y}',
            },
            {
                year: '2018',
                tiles: 'https://api.resourcewatch.org/v1/layer/a37d1d19-3bf8-4d7d-8cce-1158f9ef6a12/tile/gee/{z}/{x}/{y}',
            },
            {
                year: '2019',
                tiles: 'https://api.resourcewatch.org/v1/layer/bc0a5617-c38b-4efd-805a-26e1811524b3/tile/gee/{z}/{x}/{y}',
            },
            {
                year: '2020',
                tiles: 'https://api.resourcewatch.org/v1/layer/5f6d1cd9-596d-49b9-93d8-326d3657f7a3/tile/gee/{z}/{x}/{y}',
            },
        ],
        type: DatasetType.RASTER,
    },
]

export const DatasetsSourceDisclaimer = new Map<string, string>([
    [Datasets.FOREST_LOSS, 'Source: Hansen/UMD/Google/USGS/NASA, powered by Resource Watch.'],
    [
        Datasets.NET_FOREST_FLUX,
        'Harris et al. (2021). Global maps of 21st century forest carbon fluxes. Accessed through Global Forest Watch.',
    ],
    [
        Datasets.WOOD_FIBER_CONCESSION,
        '“Wood fiber concessions.” Accessed through Global Forest Watch – Indonesia Ministry of Forestry, Asia Pulp and Paper, APRIL',
    ],
    [
        Datasets.INDIGENOUS_LAND,
        'LandMark, 2021. “Indigenous lands.”. Accessed through Global Forest Watch.',
    ],
    [
        Datasets.BIODIVERSITY,
        'IUCN, BirdLife International, and UNEP-WCMC (2019). “Biodiversity Significance”. Accessed through Global Forest Watch.',
    ],
    [
        Datasets.SOIL_CARBON_DIVERSITY,
        'Source: World Resources Institute, ISRIC, Sanderman et al. “Soil Carbon Density.” Accessed through Global Forest Watch.',
    ],
    [
        Datasets.MANGROVES,
        'Global Mangrove Watch, 2016. “Global Mangrove Extent (v2.0).” Accessed through Global Forest Watch.',
    ],
    [
        Datasets.OIL_PALM_CONCESSION,
        '“Oil palm concessions.” Accessed through Global Forest Watch – Indonesia Ministry of Forestry',
    ],
])

export function findSWAndNEPoints(coordinates: Coordinate[][]): {
    southwestern: Coordinate
    northeastern: Coordinate
} {
    if (
        coordinates.length === 0 ||
        coordinates.every((coordinateGroup) => coordinateGroup.length === 0)
    ) {
        console.log('failed to find SW and NE')
    }

    const allCoordinates: Coordinate[] = coordinates.flat()
    let southwestern: Coordinate = allCoordinates[0]
    let northeastern: Coordinate = allCoordinates[0]

    allCoordinates.forEach(({ lat, lon }) => {
        if (lat < southwestern.lat || (lat === southwestern.lat && lon < southwestern.lon)) {
            southwestern = { lat, lon }
        }

        if (lat > northeastern.lat || (lat === northeastern.lat && lon > northeastern.lon)) {
            northeastern = { lat, lon }
        }
    })

    return { southwestern, northeastern }
}

const removeAllLayersAndSources = (mapElement: ReactGlMap) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    mapElement.getLayer('dataset-tiles-layer') && mapElement.removeLayer('dataset-tiles-layer')
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    mapElement.getSource('dataset-tiles-source') && mapElement.removeSource('dataset-tiles-source')
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    mapElement.getLayer('layer-1') && mapElement.removeLayer('layer-1')
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    mapElement.getLayer('layer-2') && mapElement.removeLayer('layer-2')
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    mapElement.getLayer('layer-3') && mapElement.removeLayer('layer-3')
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    mapElement.getLayer('layer-4') && mapElement.removeLayer('layer-4')
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    mapElement.getLayer('layer-5') && mapElement.removeLayer('layer-5')
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    mapElement.getSource('dataset-source') && mapElement.removeSource('dataset-source')
}

export const addRasterLayer = (mapElement: ReactGlMap, tiles: string) => {
    removeAllLayersAndSources(mapElement)

    mapElement.addSource('dataset-tiles-source', {
        tiles: [tiles],
        type: 'raster',
        tileSize: 256,
    })

    mapElement.addLayer(
        {
            id: 'dataset-tiles-layer',
            type: 'raster',
            source: 'dataset-tiles-source',
            minzoom: 0,
            maxzoom: 22,
        },
        'state-label',
    )
}

export const addVectorLayer = (
    mapElement: ReactGlMap,
    tiles: string,
    source: string,
    color: string,
) => {
    removeAllLayersAndSources(mapElement)

    mapElement.addSource('dataset-tiles-source', {
        type: 'vector',
        tiles: [tiles],
    })

    mapElement.addLayer(
        {
            id: 'dataset-tiles-layer',
            type: 'fill',
            source: 'dataset-tiles-source',
            'source-layer': source,
            paint: {
                'fill-color': color,
                'fill-opacity': 0.6,
            },
        },
        'state-label',
    )
}

export const addIndigenousLandLayer = (mapElement: ReactGlMap) => {
    removeAllLayersAndSources(mapElement)

    mapElement.addSource('dataset-source', {
        type: 'vector',
        tiles: [
            'https://tiles.globalforestwatch.org/landmark_indigenous_and_community_lands/v20210909/default/{z}/{x}/{y}.pbf',
        ],
    })

    mapElement.addLayer(
        {
            id: 'layer-1',
            source: 'dataset-source',
            'source-layer': 'landmark_indigenous_and_community_lands',
            filter: ['==', 'type', 'Indicative Areas'],
            paint: {
                'fill-color': '#9c9c9c',
                'fill-opacity': 0.8,
            },
            type: 'fill',
        },
        'state-label',
    )

    mapElement.addLayer(
        {
            id: 'layer-2',
            type: 'fill',
            source: 'dataset-source',
            'source-layer': 'landmark_indigenous_and_community_lands',
            filter: [
                'all',
                ['==', 'form_rec', 'Acknowledged by govt'],
                ['==', 'identity', 'Indigenous'],
            ],
            paint: {
                'fill-color': '#bf6938',
                'fill-opacity': 0.8,
            },
        },
        'state-label',
    )
    mapElement.addLayer(
        {
            id: 'layer-3',
            type: 'fill',
            source: 'dataset-source',
            'source-layer': 'landmark_indigenous_and_community_lands',
            filter: [
                'all',
                ['==', 'form_rec', 'Not acknowledged by govt'],
                ['==', 'identity', 'Indigenous'],
            ],
            paint: {
                'fill-color': '#f3aa72',
                'fill-opacity': 0.8,
            },
        },
        'state-label',
    )
    mapElement.addLayer({
        id: 'layer-4',
        type: 'fill',
        source: 'dataset-source',
        'source-layer': 'landmark_indigenous_and_community_lands',
        filter: [
            'all',
            ['==', 'form_rec', 'Acknowledged by govt'],
            ['==', 'identity', 'Community'],
        ],
        paint: {
            'fill-color': '#2C5682',
            'fill-opacity': 0.8,
        },
    })
    mapElement.addLayer(
        {
            id: 'layer-5',
            type: 'fill',
            source: 'dataset-source',
            'source-layer': 'landmark_indigenous_and_community_lands',
            filter: [
                'all',
                ['==', 'form_rec', 'Not acknowledged by govt'],
                ['==', 'identity', 'Community'],
            ],
            paint: {
                'fill-color': '#407ebe',
                'fill-opacity': 0.8,
            },
        },
        'state-label',
    )
}

const addForestLossLayer = (mapElement: ReactGlMap, year: number) => {
    const forestLossDataset = DatasetsListWithInfo.find((d) => d.dataset === Datasets.FOREST_LOSS)
    const tiles =
        forestLossDataset &&
        (forestLossDataset.tiles as { year: string; tiles: string }[]).find(
            (t) => t.year === year.toString(),
        )
    if (tiles) {
        addRasterLayer(mapElement, tiles.tiles)
    }
}

export const drawDataset = (mapElement: ReactGlMap, dataset: DatasetInfo, year?: number) => {
    if (dataset.type === DatasetType.VECTOR) {
        if (dataset.dataset === Datasets.INDIGENOUS_LAND) {
            addIndigenousLandLayer(mapElement)
        } else {
            addVectorLayer(mapElement, dataset.tiles as string, dataset.sourceName!, dataset.color!)
        }
    } else {
        if (dataset.dataset === Datasets.FOREST_LOSS) {
            addForestLossLayer(mapElement, year || 2020)
        } else {
            addRasterLayer(mapElement, dataset.tiles as string)
        }
    }
}

export const DatasetsMapLegend = new Map<string, MapLegendItem>([
    [
        Datasets.MANGROVES,
        {
            legend: [
                {
                    color: ['#70F9BB'],
                    label: ['Mangroves'],
                },
            ],
        },
    ],
    [
        Datasets.WOOD_FIBER_CONCESSION,
        {
            legend: [
                {
                    color: ['#A35739'],
                    label: ['Wood fiber concessions'],
                },
            ],
        },
    ],
    [
        Datasets.OIL_PALM_CONCESSION,
        {
            legend: [
                {
                    color: ['#D5A28D'],
                    label: ['Oil palm concessions'],
                },
            ],
        },
    ],
    [
        Datasets.NET_FOREST_FLUX,
        {
            legend: [
                {
                    color: ['#151D44', '#52A384', '#DCD4E5', '#8C4FA0', '#39082A'],
                    label: ['CO₂e sink', 'CO₂e source'],
                },
            ],
        },
    ],
    [
        Datasets.BIODIVERSITY,
        {
            legend: [
                {
                    color: ['#3C00AB', '#F8EBFF'],
                    label: ['High importance to biodiversity', 'Low'],
                },
            ],
        },
    ],
    [
        Datasets.SOIL_CARBON_DIVERSITY,
        {
            legend: [
                {
                    color: ['#FFCE00', '#791D1C'],
                    label: ['<5 tonnes of carbon / Ha', '>400'],
                },
            ],
        },
    ],
    [
        Datasets.INDIGENOUS_LAND,
        {
            legend: [
                {
                    color: ['#AD6334'],
                    label: ['Indigenous lands, acknowledged by government'],
                },
                {
                    color: ['#E7A469'],
                    label: ['Indigenous lands, not acknowledged by government'],
                },
                {
                    color: ['#2F4B76'],
                    label: ['Community lands, acknowledged by government'],
                },
                {
                    color: ['#4571B5'],
                    label: ['Community lands, not acknowledged by government'],
                },
                {
                    color: ['#919191'],
                    label: ['Indicative areas of indigenous and community land rights'],
                },
            ],
        },
    ],
])

export const ProjectSlugsWithApproximatePerimeter = [
    'vichada-grasslands-afforestation',
    'kootznoowoo-forest',
    'shaan-seet',
]

export const drawPerimeter = (
    mapElement: ReactGlMap,
    perimeter: [number, number][][],
    dashedLine?: boolean,
) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (mapElement.getSource(`perimeter`) !== undefined) {
        mapElement.removeLayer(`perimeter`)
        mapElement.removeSource(`perimeter`)
        mapElement.removeLayer(`perimeter-shadow`)
        mapElement.removeSource(`perimeter-shadow`)
    }

    mapElement.addSource(`perimeter-shadow`, {
        type: 'geojson',
        data: {
            type: 'FeatureCollection',
            features: [
                {
                    type: 'Feature',
                    geometry: {
                        type: 'Polygon',
                        coordinates: perimeter,
                    },
                    properties: {},
                },
            ],
        },
    } as AnySourceData)
    mapElement.addSource(`perimeter`, {
        type: 'geojson',
        data: {
            type: 'FeatureCollection',
            features: [
                {
                    type: 'Feature',
                    geometry: {
                        type: 'Polygon',
                        coordinates: perimeter,
                    },
                    properties: {},
                },
            ],
        },
    } as AnySourceData)

    mapElement.addLayer({
        id: `perimeter-shadow`,
        type: 'line',
        source: `perimeter-shadow`,
        layout: {
            'line-join': 'round',
            'line-cap': 'round',
        },
        paint: {
            'line-color': '#000000',
            'line-width': 4,
            'line-opacity': 0.4,
        },
    })

    mapElement.addLayer({
        id: `perimeter`,
        type: 'line',
        source: `perimeter`,
        layout: {
            'line-join': 'round',
            'line-cap': 'round',
        },
        paint: {
            'line-color': '#ffffff',
            'line-width': 1,
            ...(dashedLine && {
                'line-dasharray': [4, 4],
            }),
        },
    })
}
