import { Account } from '@lune-climate/lune'
import { BackButton, Button, LuneTheme, Text, Tile } from '@lune-fe/lune-ui-lib'
import * as Icons from '@mui/icons-material'
import { Box } from '@mui/material'
import { styled } from '@mui/system'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import LinkTile from 'components/LinkTile'
import { MAIN_NAV_LOCATION_KEY } from 'components/MainNavigation'
import TestModeSwitch from 'components/TestModeSwitch'
import useAccounts from 'hooks/useAccounts'
import useActiveOrganisation from 'hooks/useActiveOrganisation'
import useIsTestMode from 'hooks/useIsTestMode'
import { useIsAuthorised } from 'hooks/useUserState'

import ConditionalTooltip from './ConditionalTooltip'
import NotAdminTooltip from './NotAdminTooltip'

const SettingsNavigation = () => {
    const isAuthorised = useIsAuthorised()
    const { palette, spacing } = LuneTheme
    const { accounts } = useAccounts()
    const isTestMode = useIsTestMode()
    const { activeOrg } = useActiveOrganisation()
    const navigate = useNavigate()
    const BackButtonContainer = styled(BackButton)(({ theme }) =>
        theme.unstable_sx({
            pt: 4,
            pl: 3 / 2,
        }),
    )

    const NavigationSection = useMemo(
        () =>
            styled('div')(({ theme }) =>
                theme.unstable_sx({
                    p: 2,
                }),
            ),
        [],
    )

    const StyledHr = styled('hr')(({ theme }) =>
        theme.unstable_sx({
            border: 0,
            height: '1px',
            width: 1,
            background: palette.Grey300,
            margin: 0,
        }),
    )

    const StyledTile = styled(LinkTile)(({ theme }) =>
        theme.unstable_sx({
            height: spacing(6),
            borderRadius: '24px',
        }),
    )

    return (
        <Box
            data-testId={'settings-navigation-list'}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
                overflow: 'hidden',
                backgroundColor: isTestMode ? palette.Yellow50 : `initial`,
            }}
        >
            <Box
                sx={{
                    flex: '1 1 auto',
                    height: '100%',
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box>
                    <BackButtonContainer
                        label="Settings"
                        onClick={() => navigate(localStorage.getItem(MAIN_NAV_LOCATION_KEY) || '/')}
                    />
                    <NavigationSection>
                        <StyledTile
                            href={'/settings'}
                            icon={
                                activeOrg?.logo ? (
                                    <Box
                                        sx={{
                                            maxWidth: '24px',
                                            maxHeight: '24px',
                                            img: {
                                                width: '100%',
                                            },
                                        }}
                                    >
                                        <img src={activeOrg.logo} alt={activeOrg.name} />
                                    </Box>
                                ) : (
                                    <Icons.CorporateFare sx={{ color: palette.Grey900 }} />
                                )
                            }
                        >
                            <Text variant={`body3`}> Organisation </Text>
                        </StyledTile>
                        <StyledTile
                            href={'/settings/billing'}
                            icon={<Icons.ReceiptLongOutlined sx={{ color: palette.Grey900 }} />}
                        >
                            <Text variant={`body3`}> Billing </Text>
                        </StyledTile>
                    </NavigationSection>
                    <StyledHr />
                </Box>
                <Box sx={{ overflow: 'auto' }}>
                    <NavigationSection>
                        <Tile
                            button={
                                <ConditionalTooltip show={!isAuthorised} tooltip={NotAdminTooltip}>
                                    <Button
                                        data-testId={'navigation-new-account-btn'}
                                        variant="text"
                                        iconButton
                                        leftIcon={<Icons.Add />}
                                        disabled={!isAuthorised}
                                        onClick={() => navigate('/settings/accounts/new')}
                                    />
                                </ConditionalTooltip>
                            }
                        >
                            <Text variant={`body3`} sx={{ fontWeight: 500 }}>
                                Accounts
                            </Text>
                        </Tile>
                        <div data-testid={`existing-accounts-list`}>
                            {accounts.map((acc: Account) => {
                                return (
                                    <StyledTile
                                        key={`item-${acc.id}`}
                                        href={`/settings/accounts/${acc.id}`}
                                        icon={
                                            <Icons.AccountBalanceWalletOutlined
                                                sx={{ color: palette.Grey900 }}
                                            />
                                        }
                                    >
                                        <Text variant={`body3`}> {acc.name} </Text>
                                    </StyledTile>
                                )
                            })}
                        </div>
                    </NavigationSection>
                    <StyledHr />
                    <NavigationSection>
                        <StyledTile
                            href={'/settings/client-accounts'}
                            icon={
                                <Icons.AccountBalanceWalletOutlined
                                    sx={{ color: palette.Grey900 }}
                                />
                            }
                        >
                            <Text variant={`body3`}> Client Accounts </Text>
                        </StyledTile>
                    </NavigationSection>
                </Box>
            </Box>
            <TestModeSwitch />
        </Box>
    )
}

export default SettingsNavigation
