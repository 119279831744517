import { LuneTheme, Text, Tile } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'
import { Big } from 'big.js'

import useAccountCurrency from 'hooks/useAccountCurrency'

const OrderSummaryTableFooter = ({
    fees,
    quantity,
    cost,
}: {
    quantity: string
    cost: string
    fees: string
}) => {
    const {
        palette: { Grey900 },
    } = LuneTheme

    const toCurrency = useAccountCurrency()

    return (
        <Box
            sx={{
                mt: 4,
            }}
        >
            <Tile
                rightSide={
                    <Text
                        variant={'body2'}
                        sx={{
                            color: Grey900,
                        }}
                        data-testid={'total-quantity'}
                    >
                        <div
                            style={{
                                whiteSpace: `nowrap`,
                            }}
                        >
                            {quantity
                                ? Big(quantity).toNumber() < 1
                                    ? Big(Big(quantity).toFixed(6)).toNumber()
                                    : Big(Big(quantity).toFixed(2)).toNumber()
                                : 0}{' '}
                            tCO₂
                        </div>
                    </Text>
                }
            >
                <Text variant={'body2'} sx={{ color: Grey900 }}>
                    Total quantity
                </Text>
            </Tile>
            <Tile
                rightSide={
                    <Text
                        variant={'body2'}
                        sx={{
                            color: Grey900,
                        }}
                        data-testid={'total-offset-cost'}
                    >
                        {toCurrency?.(cost)}
                    </Text>
                }
            >
                <Text variant={'body2'} sx={{ color: Grey900 }}>
                    Offset cost
                </Text>
            </Tile>
            <Tile
                rightSide={
                    <Text
                        variant={'body2'}
                        sx={{
                            color: Grey900,
                        }}
                        data-testid={'total-fees'}
                    >
                        {toCurrency?.(fees)}
                    </Text>
                }
            >
                <Text variant={'body2'} sx={{ color: Grey900 }}>
                    Fees
                </Text>
            </Tile>
        </Box>
    )
}

export default OrderSummaryTableFooter
