import { MassUnit } from '@lune-climate/lune'
import { ButtonGroup } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'
import { FormApi } from 'final-form'
import { useEffect, useState } from 'react'
import { Field } from 'react-final-form'

import { FormInput } from 'components/FormInput'

const validate = (value: number) => {
    if (!value) {
        return 'This is a required field'
    } else if (value <= 0) {
        return `This should be a positive number`
    } else {
        return undefined
    }
}

const Load = ({ form }: { form: FormApi }) => {
    const TEU_AS_UNIT = 'TEU'
    const [selectedUnit, setSelectedUnit] = useState<string>(MassUnit.KG)
    const [amount, setAmount] = useState<string>('0')

    useEffect(() => {
        const formValue = form.getState().values
        const unit = formValue.shipment?.mass?.unit
        const containers = formValue.shipment?.containers
        setAmount(
            form.getState().values.shipment?.mass?.amount ||
                form.getState().values.shipment?.containers ||
                '0',
        )
        if (containers) {
            setSelectedUnit(TEU_AS_UNIT)
        } else {
            setSelectedUnit(unit)
        }
    }, [form])
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                gap: 1 / 2,
            }}
        >
            <Box sx={{ width: '50%' }}>
                <Field
                    name={`shipment.${selectedUnit === TEU_AS_UNIT ? 'containers' : 'mass.amount'}`}
                    validate={validate}
                    highlightOnFocus
                    restrictToNumbers
                    component={FormInput}
                />
            </Box>
            <ButtonGroup
                onChange={(value) => {
                    setSelectedUnit(value)
                    if (value === TEU_AS_UNIT) {
                        form.mutators.setValue('shipment.containers', amount)
                        form.mutators.setValue('shipment.mass', undefined)
                    } else {
                        form.mutators.setValue('shipment.mass.amount', amount)
                        form.mutators.setValue('shipment.mass.unit', value)
                        form.mutators.setValue('shipment.containers', undefined)
                    }
                }}
                value={selectedUnit || MassUnit.KG}
                sx={{
                    width: '50%',
                    marginTop: 1 / 4,
                    ml: 1 / 4,
                    mr: 1 / 4,
                    '.MuiButton-root': {
                        height: '52px !important',
                    },
                }}
                items={[
                    {
                        label: 'kg',
                        value: MassUnit.KG,
                    },
                    {
                        label: 't',
                        value: MassUnit.T,
                    },
                    {
                        label: TEU_AS_UNIT,
                        value: TEU_AS_UNIT,
                    },
                ]}
            />
        </Box>
    )
}

export default Load
