import {
    Bundle,
    Error,
    MassUnit,
    OrderQuoteBase,
    OrderQuoteByQuantityRequest,
} from '@lune-climate/lune'
import { Big } from 'big.js'
import { useSnackbar } from 'notistack'
import { FC, useCallback, useEffect, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { debounce } from 'throttle-debounce'

import useBuyOffsetsState from 'hooks/useBuyOffsetsState'
import { useLuneClient } from 'hooks/useLuneClient'
import useMixpanel from 'hooks/useMixpanel'
import { OrderType } from 'models/order'
import { mapErrorsToMessage } from 'SnackbarMessages'
import {
    Allocation,
    BundleWithPercentage,
    BundleWithVolume,
    IOrderByMassPayload,
    IOrderByValuePayload,
} from 'views/BuyOffsets/BuyOffsetsTypes'
import {
    orderPayloadByMass,
    orderPayloadByValueWithCustomPercentage,
} from 'views/BuyOffsets/BuyOffsetsUtils'
import CardModalContent from 'views/BuyOffsets/CartModal/CartModalController/CardModalContent'

const ByPrice: FC<{
    allocation: Allocation[]
    customizedPortfolio: boolean
    onRemoveBundle?: (bundle: Bundle) => void
}> = ({ allocation, customizedPortfolio, onRemoveBundle }) => {
    const mixpanel = useMixpanel()
    const client = useQueryClient()
    const { enqueueSnackbar: snackbar } = useSnackbar()
    const { buyOffsetProps, setBuyOffsetProps } = useBuyOffsetsState()
    const luneClient = useLuneClient()

    const {
        error,
        orderType,
        amount,
        truncateToTonnes,
        isCustomBundleVolumeSet,
        isCustomBundlePercentageSet,
    } = buyOffsetProps

    // QUOTE CALCULATIONS
    const orderQuantityInvalidError = (errors: Error[]) => {
        const err = mapErrorsToMessage(errors)
        if (err.key !== Error.error_code.ORDER_QUANTITY_INVALID) {
            snackbar(err.message)
        }
    }

    const setQuote = (quote: OrderQuoteBase) => {
        const insufficientBundles = quote.bundles
            .filter((bundle) => bundle.insufficientAvailableQuantity)
            .map((b) => b.bundleName)
        const insufficientBundlesError = insufficientBundles.length
            ? {
                  key: 'insufficientBundles',
                  message: ` We don’t have enough ${insufficientBundles.join(
                      ', ',
                  )} credits to fulfill your request. We have allocated the maximum available.`,
              }
            : undefined
        setBuyOffsetProps({
            error: undefined,
            insufficientBundlesError,
            quote,
        })
        if (isCustomBundleVolumeSet) {
            setBuyOffsetProps({
                amount: quote.estimatedTotalCost,
            })
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const calculateQuoteDebounced = useCallback(
        debounce(
            250,
            async (
                payload: OrderQuoteByQuantityRequest | IOrderByValuePayload,
                byValue?: boolean,
            ) => {
                const result = !byValue
                    ? await client.fetchQuery(JSON.stringify(payload), () =>
                          luneClient.getOrderQuoteByMass({
                              orderQuoteByQuantityRequest: payload as OrderQuoteByQuantityRequest,
                          }),
                      )
                    : await client.fetchQuery(JSON.stringify(payload), () =>
                          luneClient.getOrderQuoteByValue(payload as IOrderByValuePayload),
                      )

                if (result.isOk()) {
                    setQuote(result.value)
                } else {
                    const errors = 'errors' in result.error ? result.error.errors : undefined
                    if (errors) {
                        orderQuantityInvalidError(errors)
                    }
                    setBuyOffsetProps({
                        error: mapErrorsToMessage(errors),
                        insufficientBundlesError: undefined,
                        quote: undefined,
                    })
                }
                mixpanel.track('Quote', {
                    type: 'by-value',
                    value: Big(amount || 0).toNumber(),
                })
            },
            { atBegin: false },
        ),
        [isCustomBundleVolumeSet],
    )

    const payloadByMass = useMemo<IOrderByMassPayload>((): IOrderByMassPayload => {
        return orderPayloadByMass(allocation, customizedPortfolio, amount || '0', truncateToTonnes)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        amount,
        allocation,
        truncateToTonnes,
        orderType,
        error,
        isCustomBundleVolumeSet,
        customizedPortfolio,
    ])

    const orderPayloadByValue = useMemo<IOrderByValuePayload>((): IOrderByValuePayload => {
        let totalPercentagesSum = 0
        const payload = {
            value: Big(amount || 0).toString(),
            bundleSelection: allocation.map((a) => {
                let percentage
                // workaround to avoid cases when we end up with percentages as 33.33, 33.33, 33.33, which don't add up to 100%
                if (allocation.indexOf(a) === allocation.length - 1) {
                    const remainingPercentage = 100 - totalPercentagesSum
                    percentage = remainingPercentage.toFixed(2) || '0'
                } else {
                    totalPercentagesSum += Number(Big(a.percentage).toFixed(2))
                    percentage = Big(a.percentage).toFixed(2) || '0'
                }
                return {
                    bundleId: a.bundle.id,
                    percentage,
                }
            }),
        }
        return truncateToTonnes ? { ...payload, quantityTrunc: MassUnit.T } : payload
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [amount, allocation, truncateToTonnes, orderType, error, isCustomBundleVolumeSet])

    /**
     * Get a quote from the API when the user has selected a bundle and an amount
     */
    useEffect(() => {
        // TODO - avoid this useEffect by triggering the quote calculation right from onBundlesVolumeChange / onBundlesPercentageChange
        if (
            ((amount && amount !== '0') || isCustomBundleVolumeSet) &&
            allocation.length &&
            !error
        ) {
            // If the user has provided a custom percentage for each bundle, we call the specific function for that
            if (isCustomBundlePercentageSet) {
                const payload = orderPayloadByValueWithCustomPercentage(allocation, amount)
                calculateQuoteDebounced(payload, orderType === OrderType.VALUE)
                return
            }

            const payload =
                orderType === OrderType.VALUE && !isCustomBundleVolumeSet
                    ? orderPayloadByValue
                    : payloadByMass
            calculateQuoteDebounced(
                payload,
                orderType === OrderType.VALUE && !isCustomBundleVolumeSet,
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payloadByMass, orderPayloadByValue])

    const onBundlesVolumeChange = (bundles: BundleWithVolume[]) => {
        setBuyOffsetProps({
            customVolumes: bundles,
            isCustomBundleVolumeSet: true,
            isCustomBundlePercentageSet: false,
            error: undefined,
        })
    }

    const onBundlesPercentChange = (bundles: BundleWithPercentage[]) => {
        setBuyOffsetProps({
            isCustomBundlePercentageSet: true,
            isCustomBundleVolumeSet: false,
            customPercentages: bundles,
            error: undefined,
        })
    }

    return (
        <CardModalContent
            onBundlesVolumeChange={onBundlesVolumeChange}
            onBundlesPercentageChange={onBundlesPercentChange}
            allocation={allocation}
            customizedPortfolio={customizedPortfolio}
            onRemoveBundle={onRemoveBundle}
        />
    )
}

export default ByPrice
