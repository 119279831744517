import { BundleSummary, CarbonPermanence } from '@lune-climate/lune'
import { Chip, LuneTheme } from '@lune-fe/lune-ui-lib'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import CloudOffOutlinedIcon from '@mui/icons-material/CloudOffOutlined'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined'
import { Avatar, Box } from '@mui/material'
import { FC } from 'react'

import useAccountCurrency from 'hooks/useAccountCurrency'
import { assertType } from 'utils'

interface ProjectSpecProps {
    registryName: string
    registryLink: string
    grossUnitPrice: string
    // eslint-disable-next-line camelcase
    offsetType: BundleSummary.offset_type | null
    carbonPermanence: CarbonPermanence | null
}

const registries = [
    { key: 'Verra', name: 'Verra', extension: 'verra' },
    { key: 'Puro.earth', name: 'Puro.earth', extension: 'puro-earth' },
    {
        key: 'American Carbon Registry',
        name: 'American Carbon Registry',
        extension: 'american-carbon-registry',
    },
    { key: 'Gold Standard', name: 'Gold Standard', extension: 'gold-standard' },
    {
        key: 'Woodland Carbon Code',
        name: 'Woodland Carbon Code',
        extension: 'woodland-carbon-code',
    },
    {
        key: 'Climate Action Reserve',
        name: 'Climate Action Reserve',
        extension: 'climate-action-reserve',
    },
    { key: 'ACCU', name: 'ACCU', extension: 'accu' },
    { key: 'Carbon Future', name: 'Carbon Future', extension: 'carbon-future' },
    { key: 'Isometric', name: 'Isometric', extension: 'isometric' },
    { key: 'Tuv Nord', name: 'Tüv Nord', extension: 'tuv-nord' },
]

/**
 * Get a human-friendly label for a nullable CarbonPermanenc value.
 */
function getCarbonPermanenceLabel(carbonPermanence: CarbonPermanence | null): string {
    if (!carbonPermanence) {
        return 'No carbon storage'
    } else if (carbonPermanence === 'long_term') {
        return 'Long-term carbon storage'
    } else if (carbonPermanence === 'short_term') {
        return 'Short-term carbon storage'
    } else {
        assertType<'no_storage'>(carbonPermanence)
        return 'No carbon storage'
    }
}

const ProjectSpecs: FC<ProjectSpecProps> = ({
    registryName,
    registryLink,
    grossUnitPrice,
    offsetType,
    carbonPermanence,
}) => {
    const { spacing } = LuneTheme
    const toCurrency = useAccountCurrency()

    const registry = registries.find((r) => r.key === registryName)

    return (
        <Box
            sx={{
                display: 'flex',
                gap: spacing(0.5),
                flexWrap: 'wrap',
            }}
        >
            {registryName && registry ? (
                <Chip
                    sx={{ textDecoration: 'underline' }}
                    href={registryLink}
                    icon={
                        <Avatar
                            alt={registry.name}
                            src={`https://assets.lune.co/registries/${registry.extension}-icon.png`}
                            sx={{ width: '24px', height: '24px' }}
                        />
                    }
                    label={registry.name}
                />
            ) : (
                <Chip label="Not certified" />
            )}
            <Chip
                icon={<PaymentsOutlinedIcon sx={{ width: '20px', height: '20px' }} />}
                label={toCurrency?.(grossUnitPrice) + ' / tCO₂'}
            />
            {offsetType && (
                <Chip
                    icon={
                        offsetType === 'emissions_reduction' ? (
                            <CloudOffOutlinedIcon sx={{ width: '20px', height: '20px' }} />
                        ) : (
                            <CloudDownloadOutlinedIcon sx={{ width: '20px', height: '20px' }} />
                        )
                    }
                    label={
                        offsetType === 'emissions_reduction'
                            ? 'Emissions Reduction'
                            : 'Carbon Removal'
                    }
                />
            )}

            <Chip
                icon={<LockOutlinedIcon sx={{ width: '20px', height: '20px' }} />}
                label={getCarbonPermanenceLabel(carbonPermanence)}
            />
        </Box>
    )
}

export default ProjectSpecs
