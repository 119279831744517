import { Bundle } from '@lune-climate/lune'
import { Box } from '@mui/material'
import { FC, useCallback } from 'react'

import useBuyOffsetsState from 'hooks/useBuyOffsetsState'
import { OrderType } from 'models/order'
import { Allocation } from 'views/BuyOffsets/BuyOffsetsTypes'
import AmountInput from 'views/BuyOffsets/CartModal/AmountInput'
import ByPrice from 'views/BuyOffsets/CartModal/CartModalController/ByPrice'
import ByVolume from 'views/BuyOffsets/CartModal/CartModalController/ByVolume'
import OrderTypeChooser from 'views/BuyOffsets/CartModal/OrderTypeChooser'

const CartModalController: FC<{
    allocation: Allocation[]
    customizedPortfolio: boolean
    onRemoveBundle?: (bundle: Bundle) => void
}> = ({ allocation, customizedPortfolio, onRemoveBundle }) => {
    const { buyOffsetProps, setBuyOffsetProps } = useBuyOffsetsState()
    const { orderType, amount, simpleModal } = buyOffsetProps

    const onChange = useCallback(
        (val: string) => {
            setBuyOffsetProps({
                amount: val || '',
                error: undefined,
                insufficientBundlesError: undefined,
            })
        },
        [setBuyOffsetProps],
    )

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                alignItems: 'stretch',
            }}
        >
            {!simpleModal && (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mb: 4,
                        width: '100%',
                        pr: 1 / 2,
                        boxSizing: 'border-box',
                    }}
                >
                    <AmountInput amount={amount || ''} onChange={onChange} />
                    <OrderTypeChooser
                        selectedType={orderType}
                        onChange={(type) =>
                            setBuyOffsetProps({
                                orderType: type,
                                error: undefined,
                                insufficientBundlesError: undefined,
                                isCustomBundleVolumeSet: false,
                            })
                        }
                    />
                </Box>
            )}
            {orderType === OrderType.QUANTITY ? (
                <ByVolume
                    allocation={allocation}
                    customizedPortfolio={customizedPortfolio}
                    onRemoveBundle={onRemoveBundle}
                />
            ) : (
                <ByPrice
                    allocation={allocation}
                    customizedPortfolio={customizedPortfolio}
                    onRemoveBundle={onRemoveBundle}
                />
            )}
        </Box>
    )
}

export default CartModalController
