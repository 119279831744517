import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined'
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined'
import FlightOutlinedIcon from '@mui/icons-material/FlightOutlined'
import HailOutlinedIcon from '@mui/icons-material/HailOutlined'
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined'
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined'
import TravelExploreIcon from '@mui/icons-material/TravelExplore'

import everydayPurchases from 'images/everyday-purchases.png'
import fintechSheet from 'images/fintech-sheet.png'
import logisticsEmissions from 'images/logistics-emissions.png'
import logisticsSheet from 'images/logistics-sheet.png'
import { EstimateWithAccountInfo } from 'models/openDapi'

export type EstimateType = EstimateWithAccountInfo['estimateType']

export enum EstimateTypeEnum {
    ELECTRICITY = 'electricity',
    FLIGHT = 'flight',
    SHIPPING = 'shipping',
    MULTI_LEG_SHIPPING = 'multi_leg_shipping',
    TRANSACTION = 'transaction',
    COMPANY = 'company',
    INDIVIDUAL = 'individual',
    PASSENGER_TRANSPORT = 'passenger_transportation',
    TRANSACTION_DOCUMENT = 'transaction_document',
    EMISSION_FACTOR = 'activity',
}

export const EstimateTypeIcon = (calculationType: EstimateType) => {
    const sx = { mr: 3 }
    switch (calculationType) {
        case 'flight':
            return <FlightOutlinedIcon sx={sx} />
        case 'company':
            return <BusinessOutlinedIcon sx={sx} />
        case 'shipping':
        case 'multi_leg_shipping':
            return <Inventory2OutlinedIcon sx={sx} />
        case 'transaction':
            return <ShoppingCartOutlinedIcon sx={sx} />
        case 'electricity':
            return <BoltOutlinedIcon sx={sx} />
        case 'individual':
            return <PersonOutlineOutlinedIcon sx={sx} />
        case 'passenger_transportation':
            return <HailOutlinedIcon sx={sx} />
        case 'activity':
            return <TravelExploreIcon sx={sx} />
        case 'transaction_document':
            return <ReceiptLongOutlinedIcon sx={sx} />
    }
}

export const EstimateTypeName = (calculationType: EstimateType): string => {
    switch (calculationType) {
        case 'flight':
            return 'Travel emissions'
        case 'company':
            return 'Simplified Company Carbon Footprint'
        case 'shipping':
            return 'Simplified logistics calculations'
        case 'multi_leg_shipping':
            return 'Multi-leg Logistics emissions'
        case 'transaction':
            return 'Everyday purchases'
        case 'electricity':
            return 'Electricity emissions'
        case 'individual':
            return 'Individual emissions'
        case 'passenger_transportation':
            return 'Passenger transport emissions'
        case 'activity':
            return 'Activity emissions'
        case 'transaction_document':
            return 'Transaction document emissions'
    }
}

export const EstimateTypeSlug = (calculationType: EstimateType): string | undefined => {
    switch (calculationType) {
        case 'company':
            return 'company-carbon-footprint'
        case 'shipping':
            return 'logistics-emissions'
        case 'transaction':
            return 'everyday-purchases'
        case 'multi_leg_shipping':
            return 'multi-leg-shipping'
        case 'activity':
            return 'activity'
        case 'passenger_transportation':
            return 'passenger-transportation'
        case 'flight':
        case 'electricity':
        case 'individual':
            return undefined
        case 'transaction_document':
            return 'transaction-documents'
    }
}

export const EmissionCalculationFlows: ({ logo: string; name: string; description: string } & (
    | { type: EstimateType }
    | { slug: string }
))[] = [
    {
        logo: logisticsSheet,
        name: 'Logistics spreadsheet calculations',
        description: 'Upload your logistics data using our template',
        slug: 'logistics-sheets',
    },
    {
        logo: fintechSheet,
        name: 'Spend spreadsheet calculations',
        description: 'Upload your transaction data using our template',
        slug: 'fintech-sheets',
    },
    {
        logo: logisticsEmissions,
        name: EstimateTypeName(EstimateTypeEnum.SHIPPING),
        description: 'GLEC accredited, based on shipment method, route and load',
        type: EstimateTypeEnum.SHIPPING,
    },
    {
        logo: everydayPurchases,
        name: EstimateTypeName(EstimateTypeEnum.TRANSACTION),
        description: 'Based on product category, country and spend',
        type: EstimateTypeEnum.TRANSACTION,
    },
]
