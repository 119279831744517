import { Webhook } from '@lune-climate/lune'
import { Table } from '@lune-fe/lune-ui-lib'
import LinkOffOutlinedIcon from '@mui/icons-material/LinkOffOutlined'
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined'
import { Box } from '@mui/material'
import moment from 'moment'
import { FC } from 'react'

import WebhookContextMenu from 'views/Developers/Webhooks/WebhookContextMenu'

const WebhooksTableRow: FC<{ item: Webhook }> = ({ item }) => {
    const { id, url, secret, enabled } = item

    return (
        <Table.TableRow key={id} data-testid={secret.slice(-4)}>
            <Table.TableCell colSize={7} disabled={!enabled}>
                <Box
                    sx={{
                        mr: 3,
                    }}
                >
                    {enabled ? <LinkOutlinedIcon /> : <LinkOffOutlinedIcon />}
                </Box>
                {url}
            </Table.TableCell>
            <Table.TableCell
                colSize={2}
                disabled={!enabled}
                align="right"
                sx={{ fontFamily: 'monospace' }}
            >
                {`...${secret}`}
            </Table.TableCell>
            <Table.TableCell colSize={2} disabled={!enabled} alignEnd align="right">
                {moment(item.createdAt).format('ll')}
            </Table.TableCell>
            <Table.TableCell shrink align="right">
                <WebhookContextMenu webhook={item} />
            </Table.TableCell>
        </Table.TableRow>
    )
}

export default WebhooksTableRow
