import { NoResultsPlaceholder, Text } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import { FC } from 'react'

const EmptyStatePlaceholder: FC = () => {
    return (
        <Box sx={{ height: `340px`, mb: 6 }}>
            <NoResultsPlaceholder
                message={
                    <Text
                        variant={`h6`}
                        sx={{
                            textAlign: 'center',
                            maxWidth: '250px',
                        }}
                    >
                        Please select at least one project bundle
                    </Text>
                }
            />
        </Box>
    )
}

export default EmptyStatePlaceholder
