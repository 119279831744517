import { Bundle } from '@lune-climate/lune'
import { Button, Card, Text, Tile } from '@lune-fe/lune-ui-lib'
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined'
import { FC, useEffect, useState } from 'react'

import useAccountCurrency from 'hooks/useAccountCurrency'

interface BundleCardProps {
    bundle?: Bundle
    key?: string
    onBuyNowClick: (bundle: Bundle) => void
    onAddToPortfolioClick: (bundle: Bundle) => void
    selectedBundles: Bundle[]
}

const BuyOffsetsBundleCard: FC<BundleCardProps> = ({
    bundle,
    key,
    onBuyNowClick,
    onAddToPortfolioClick,
    selectedBundles,
}) => {
    const toCurrency = useAccountCurrency()

    const [selected, setSelected] = useState<boolean>(false)

    useEffect(() => {
        setTimeout(() => {
            setSelected(selectedBundles.some((b) => b.id === bundle?.id))
        }, 200)
    }, [bundle?.id, selectedBundles])

    return bundle ? (
        <Card
            data-testid={`bundle-card-container`}
            sx={{
                width: '264px',
                margin: '0',
                mb: 2,
                display: 'inline-block',
            }}
            compact
            backgroundImage={
                bundle.primaryImage ??
                // Set background image to default in case no colour nor image present
                (!bundle.backgroundColour
                    ? 'https://lune-assets.s3.eu-west-1.amazonaws.com/bundles/default.png'
                    : '')
            }
            gradientColor={bundle.backgroundColour ?? ''}
            title={bundle.name}
            tiles={[
                <Tile
                    key={`price-tile-${key}`}
                    compact
                    variant="light"
                    icon={
                        <AccountBalanceWalletOutlinedIcon sx={{ width: '20px', height: '20px' }} />
                    }
                >
                    <Text data-testid={`bundle-price`} variant={'caption'}>
                        {toCurrency?.(bundle.grossUnitPrice)} / tCO₂
                    </Text>
                </Tile>,
            ]}
            buttons={[
                selected ? (
                    <Button
                        light
                        data-testId={`remove-from-portfolio-button-${bundle.id}`}
                        key={`remove-from-portfolio-button-${bundle.id}`}
                        variant={'outlined'}
                        onClick={() => onAddToPortfolioClick(bundle)}
                    >
                        Remove
                    </Button>
                ) : (
                    <Button
                        light
                        data-testId={`add-to-portfolio-button-${bundle.id}`}
                        key={`add-to-portfolio-button-${bundle.id}`}
                        variant={'contained'}
                        onClick={() => onAddToPortfolioClick(bundle)}
                    >
                        Add to portfolio
                    </Button>
                ),
                <Button
                    light
                    key={`buy-now-button-${bundle.id}`}
                    variant={'text'}
                    onClick={() => onBuyNowClick(bundle)}
                >
                    Buy now
                </Button>,
            ]}
        >
            <Text variant={'caption'} sx={{ color: 'white' }}>
                {bundle.description}
            </Text>
        </Card>
    ) : (
        <></>
    )
}

export default BuyOffsetsBundleCard
