import { EmissionFactor, EmissionFactorWithGasEmissions, GasEmissions } from '@lune-climate/lune'
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined'
import Co2OutlinedIcon from '@mui/icons-material/Co2Outlined'
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined'
import TagOutlinedIcon from '@mui/icons-material/TagOutlined'
import { Big } from 'big.js'
import { FC, useMemo } from 'react'

import { EmissionFactor as DapiEmissionFactor } from '../../../../../apps/lune-dashboard/src/models/openDapi'
import { formatNumbers } from '../../utils/utils'

import { emissionFactorLabel } from './utils/explanationUtils'
import { ResultLabeledSectionAndTiles, ResultTileProps } from './ResultLabeledSectionAndTiles'

export const EmissionFactorExplanation: FC<
    (
        | {
              emissionFactor: Omit<EmissionFactorWithGasEmissions, 'gasEmissions'> & {
                  gasEmissions: null
              }
              amount: string
          }
        | {
              emissionFactor: Omit<EmissionFactorWithGasEmissions, 'gasEmissions'> & {
                  gasEmissions: GasEmissions
              }
              amount: null
          }
        | {
              emissionFactor: EmissionFactor & {
                  gasEmissions: null
              }
              amount: string
          }
        | {
              emissionFactor: DapiEmissionFactor
              amount: null
          }
    ) & {
        hideId?: boolean
        label?: string
        href?: string
    }
> = ({ emissionFactor, amount, hideId, label, href }) => {
    const { id, region, source, publicationYear, numeratorUnit, denominatorUnit, gasEmissions } =
        emissionFactor

    const formattedAmount = useMemo(() => {
        if (gasEmissions === 'emission_factor_gated') {
            return '**********'
        }

        function _format(intesity: string) {
            return Big(intesity).lt(0.001) ? formatNumbers(intesity, 6) : formatNumbers(intesity)
        }

        // The API has started returning `gasEmissions` since March 2024.
        return _format(amount === null ? gasEmissions!.co2E : amount)
    }, [amount, gasEmissions])

    const tiles: ResultTileProps[] = [
        {
            label: `${formattedAmount} ${numeratorUnit}CO₂e / ${denominatorUnit}`,
            icon: <Co2OutlinedIcon />,
        },
        {
            label: `Source: ${emissionFactorLabel[source]}, ${publicationYear}`,
            icon: <AutoStoriesOutlinedIcon />,
        },
        {
            label: `Region: ${region}`,
            icon: <PublicOutlinedIcon />,
        },
        {
            label: `ID: ${id}`,
            icon: <TagOutlinedIcon />,
            href,
        },
    ]

    if (hideId) {
        tiles.pop()
    }
    return (
        <ResultLabeledSectionAndTiles
            label={label ? `Emission Factor: ${label}` : undefined}
            dataTestId={'emission-factor'}
            tiles={tiles}
        />
    )
}

export default EmissionFactorExplanation
