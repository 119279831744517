import { LuneClient } from '@lune-climate/lune'

const buildClient = () =>
    new LuneClient(localStorage.getItem('token')!, `${process.env.REACT_APP_API_URL}`)

/**
 * Use `useClient()` instead of directly importing this value.
 */
export let luneClientDoNotUseThisDirectly = buildClient()

// Whenever the session token is update, refresh the client
export const bootstrapApi = () => {
    luneClientDoNotUseThisDirectly = buildClient()
}
