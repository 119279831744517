import { TransactionEmissionEstimate } from '@lune-climate/lune'
import { SuccessResponse } from '@lune-climate/lune/cjs/core/SuccessResponse'
import { LuneTheme } from '@lune-fe/lune-ui-lib'
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined'
import CategoryIcon from '@mui/icons-material/CategoryOutlined'
import FastfoodIcon from '@mui/icons-material/FastfoodOutlined'
import PaymentsIcon from '@mui/icons-material/PaymentsOutlined'
import PublicIcon from '@mui/icons-material/PublicOutlined'
import { startCase } from 'lodash'
import { useMemo } from 'react'

import { formatToCurrency, getCountryNameByThreeLetterCode } from '../../utils'

import ResultLabeledSectionAndTiles, { ResultTileProps } from './ResultLabeledSectionAndTiles'
import useCSVFromUrl from './useCSVFromUrl'

export const CATEGORY_CODES_URL = 'https://assets.lune.co/transaction_codes.csv'

interface Category {
    mcc: string
    description: string
    coicop: string
}

export const TransactionExplanationInputSection = ({
    estimate,
}: {
    estimate: SuccessResponse<TransactionEmissionEstimate> | TransactionEmissionEstimate | undefined
}) => {
    const { palette } = LuneTheme
    const iconSx = {
        color: palette.Grey900,
    }
    const { data: categories } = useCSVFromUrl<Category>(CATEGORY_CODES_URL)
    const tiles: ResultTileProps[] = useMemo(() => {
        if (!estimate) {
            return []
        }
        const request = estimate.request
        const countryCode = request.merchant.countryCode
        const items = []

        if ('searchTerm' in request.merchant) {
            const searchTerm = request.merchant.searchTerm
            items.push({
                label: `Search term: ${searchTerm}`,
                icon: <BorderColorOutlinedIcon sx={iconSx} />,
            })
        }

        if ('categoryCode' in request.merchant) {
            const category = request.merchant.categoryCode
            const description = categories?.find((c) => c.mcc === category)?.description

            const appendDescription = description ? ` - ${description}` : ''
            items.push({
                label: `MCC ${request.merchant.categoryCode}` + appendDescription,
                icon: <CategoryIcon sx={iconSx} />,
            })
        }

        if ('category' in request.merchant) {
            const category = request.merchant.category
            items.push({
                label: `Category: ${category}`,
                icon: <CategoryIcon sx={iconSx} />,
            })
        }

        if (request.diet) {
            items.push({
                label: startCase(request.diet),
                icon: <FastfoodIcon sx={iconSx} />,
            })
        }

        return [
            ...items,
            {
                label: getCountryNameByThreeLetterCode(countryCode) ?? countryCode,
                icon: <PublicIcon sx={iconSx} />,
            },
            {
                label: formatToCurrency(request.value.value, request.value.currency),
                icon: <PaymentsIcon sx={iconSx} />,
            },
        ]
    }, [estimate])

    return <ResultLabeledSectionAndTiles label={`Your input`} tiles={tiles} />
}

export default TransactionExplanationInputSection
