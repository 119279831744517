import { useSnackbar } from 'notistack'

import useAccounts from 'hooks/useAccounts'
import { useLuneClient } from 'hooks/useLuneClient'
import useMixpanel from 'hooks/useMixpanel'
import { SnackbarMessages } from 'SnackbarMessages'
import BeneficiaryModal from 'views/BuyOffsets/OrderSummary/Beneficiary/BeneficiaryModal'

const ActiveAccountBeneficiary = () => {
    const mixpanel = useMixpanel()
    const { activeAccount, refetchAccounts } = useAccounts()
    const { enqueueSnackbar: snackbar } = useSnackbar()
    const luneClient = useLuneClient()

    const onSave = async (beneficiary: string) => {
        if (!activeAccount) {
            return
        }

        try {
            await luneClient.updateAccount(activeAccount.id, {
                name: activeAccount.name,
                beneficiary,
            })
            await refetchAccounts()
            snackbar(SnackbarMessages.SETTINGS_SAVED)
            mixpanel.track('order_summary_account_beneficiary_saved')
        } catch {
            snackbar(SnackbarMessages.SETTINGS_UPDATE_FAILED)
        }
    }

    return (
        <BeneficiaryModal
            key={activeAccount?.beneficiary}
            beneficiary={activeAccount?.beneficiary || ''}
            title={`Account beneficiary`}
            subTitle={`Applies to all purchases made with this account`}
            onSave={onSave}
        />
    )
}

export default ActiveAccountBeneficiary
