import { Bundle, BundleSummary, CarbonPermanence, ProjectSummary } from '@lune-climate/lune'
import { formatToCurrency } from '@lune-fe/lune-components-lib'
import { Card, Text, Tile } from '@lune-fe/lune-ui-lib'
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import CloudOffOutlinedIcon from '@mui/icons-material/CloudOffOutlined'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined'
import { styled } from '@mui/system'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import useAccountCurrency from 'hooks/useAccountCurrency'

interface BundleCardProps {
    bundle?: Bundle
    key?: string
    percentage?: string
    handleProjectClick?: (projectSlug: string) => void
    currency?: string
}

function permanenceCopy(permanence: CarbonPermanence | null): string {
    switch (permanence) {
        case CarbonPermanence.LONG_TERM:
            return 'Long-lived carbon storage'
        case CarbonPermanence.SHORT_TERM:
            return 'Short-lived carbon storage'
        case CarbonPermanence.NO_STORAGE:
        case null:
            return 'No carbon storage'
    }
}

const BundleCard: FC<BundleCardProps> = ({
    bundle,
    percentage,
    key,
    handleProjectClick,
    currency,
}) => {
    const toCurrency = useAccountCurrency()
    const navigate = useNavigate()

    const StyledTile = styled(Tile)(({ theme }) =>
        theme.unstable_sx({
            borderRadius: '24px',
        }),
    )

    return bundle ? (
        <Card
            sx={{
                width: '264px',
                margin: '0',
                mb: 2,
                display: 'inline-block',
            }}
            compact
            backgroundImage={
                bundle.primaryImage ??
                // Set background image to default in case no colour nor image present
                (!bundle.backgroundColour
                    ? 'https://lune-assets.s3.eu-west-1.amazonaws.com/bundles/default.png'
                    : '')
            }
            gradientColor={bundle.backgroundColour ?? ''}
            title={
                <>
                    {percentage && (
                        <>
                            {percentage}% <br />
                        </>
                    )}
                    {bundle.name}
                </>
            }
            tiles={[
                <StyledTile
                    sx={{
                        div: {
                            overflow: `visible`,
                        },
                    }}
                    key={`price-tile-${key}`}
                    compact
                    variant="light"
                    icon={
                        <AccountBalanceWalletOutlinedIcon sx={{ width: '20px', height: '20px' }} />
                    }
                >
                    <Text variant={'caption'}>
                        {currency
                            ? formatToCurrency(bundle.grossUnitPrice, currency)
                            : toCurrency?.(bundle.grossUnitPrice)}{' '}
                        / tCO₂
                    </Text>
                </StyledTile>,
                <StyledTile
                    key={`offset-tile-${key}`}
                    compact
                    variant="light"
                    icon={
                        bundle.offsetType === 'emissions_reduction' ? (
                            <CloudOffOutlinedIcon sx={{ width: '20px', height: '20px' }} />
                        ) : (
                            <CloudDownloadOutlinedIcon sx={{ width: '20px', height: '20px' }} />
                        )
                    }
                >
                    <Text variant={'caption'}>
                        {bundle.offsetType === BundleSummary.offset_type.EMISSIONS_REDUCTION
                            ? 'Emissions Reduction'
                            : 'Carbon Removal'}
                    </Text>
                </StyledTile>,
                <StyledTile
                    key={`carbon-tile-${key}`}
                    compact
                    variant="light"
                    icon={<LockOutlinedIcon sx={{ width: '20px', height: '20px' }} />}
                >
                    <Text variant={'caption'}>{permanenceCopy(bundle.carbonPermanence)}</Text>
                </StyledTile>,
            ]}
            extraTiles={bundle.projects.map((project: ProjectSummary, index: number) => {
                return (
                    <StyledTile
                        key={`bundle-project-tile-${index}`}
                        compact
                        icon={
                            project.logo ? (
                                <img width={20} height={20} src={project.logo} alt={project.name} />
                            ) : (
                                <PinDropOutlinedIcon sx={{ width: '20px', height: '20px' }} />
                            )
                        }
                        variant="light"
                        sx={{ zIndex: 99 }}
                        onClick={
                            handleProjectClick
                                ? () => handleProjectClick(project.slug)
                                : () => navigate(`/projects/${project.slug}`)
                        }
                    >
                        <Text variant={'caption'}>{project.name}</Text>
                        <Text
                            variant={'caption'}
                            sx={{
                                color: 'white !important',
                                opacity: '0.6',
                                display: 'block',
                            }}
                        >
                            {project.countryName}
                        </Text>
                    </StyledTile>
                )
            })}
        >
            <Text variant={'caption'} sx={{ color: 'white' }}>
                {bundle.description}
            </Text>
        </Card>
    ) : (
        <></>
    )
}

export default BundleCard
