import { Button, LoadingWrapper, LuneTheme, Text } from '@lune-fe/lune-ui-lib'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { SxProps } from '@mui/material'
import Box from '@mui/material/Box'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { Chart } from 'react-chartjs-2'

import { downloadImageFromHtml } from 'utils'
import { chartColors } from 'views/Dashboard/Charts/chartUtils'
import { doughnutChartOptions, DoughnutDatapoint } from 'views/Dashboard/Charts/DoughnutChartConfig'

import 'chart.js/auto'
import 'chartjs-adapter-moment'

const DoughnutChart: FC<{
    dataPoints: DoughnutDatapoint[]
    title?: string
    maxWidth?: number
    sx?: SxProps
    downloadable?: boolean
    reloadingData: boolean
}> = ({ dataPoints, maxWidth, sx, title, downloadable, reloadingData }) => {
    const { palette } = LuneTheme
    const printRef = useRef<HTMLElement>(null)
    const [chartData, setChartData] = useState<DoughnutDatapoint[]>([])
    const [legendItems, setLegendItems] = useState<DoughnutDatapoint[]>([])
    const [hasOther, setHasOther] = useState<boolean>(false)

    useEffect(() => {
        setChartData(dataPoints)
        if (dataPoints.length > 5) {
            const visible: DoughnutDatapoint[] = dataPoints.slice(0, 4)
            const other: DoughnutDatapoint[] = dataPoints.slice(4, dataPoints.length)
            const otherDatapoint: DoughnutDatapoint = {
                label: 'Other',
                value: other.reduce((partialSum, d) => partialSum + d.value, 0),
            }
            setLegendItems([...visible, otherDatapoint])
            setHasOther(true)
        } else {
            setLegendItems(dataPoints)
        }
    }, [dataPoints])

    const data = useMemo(() => {
        return {
            labels: chartData.map((d) => d.label),
            datasets: [
                {
                    data: chartData.map((d) => d.value),
                    backgroundColor: chartData.map((_, index) => chartColors[index]),
                    hoverBackgroundColor: chartData.map((_, index) => chartColors[index]),
                    hoverBorderColor: '#ffffff',
                    borderRadius: 6,
                    cutout: '87%',
                    hoverBorderWidth: 0,
                    borderWidth: 2,
                    hoverOffset: -4,
                },
            ],
        }
    }, [chartData])

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                maxWidth: maxWidth || 300,
                ...sx,
            }}
        >
            {downloadable && (
                <Button
                    sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                    }}
                    iconButton={true}
                    leftIcon={<FileDownloadOutlinedIcon />}
                    variant={'text'}
                    onClick={() => {
                        if (printRef.current) {
                            downloadImageFromHtml(printRef.current, `${title}-img`)
                        }
                    }}
                />
            )}
            <Box ref={printRef}>
                {title && (
                    <Text
                        variant={'h6'}
                        sx={{
                            mb: 4,
                            // to match download button height
                            lineHeight: '48px',
                        }}
                    >
                        {title}
                    </Text>
                )}
                <Box sx={{ position: 'relative', p: 1, height: '256px' }}>
                    <LoadingWrapper
                        loading={reloadingData}
                        sx={{ height: '256px', width: '256px' }}
                    >
                        <Chart
                            style={{ margin: '-8px' }}
                            type="doughnut"
                            data={data as any}
                            options={doughnutChartOptions as any}
                        />
                    </LoadingWrapper>
                </Box>
                <Box sx={{ mt: 5, mb: 1 }}>
                    {legendItems.map((datapoint, index) => (
                        <Box
                            key={`legend-item-${datapoint.label}-${index}`}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                gap: 3,
                                p: 1,
                                height: '48px',
                                boxSizing: 'border-box',
                                borderRadius: '24px',
                            }}
                        >
                            <Box
                                sx={{
                                    flex: '1 0 auto',
                                    width: '20px',
                                    height: '20px',
                                    borderRadius: '100%',
                                    border: '1px solid',
                                    borderColor:
                                        hasOther && index === legendItems.length - 1
                                            ? palette.Grey300
                                            : chartColors[index],
                                    backgroundColor:
                                        hasOther && index === legendItems.length - 1
                                            ? palette.White
                                            : chartColors[index],
                                }}
                            />
                            <Text
                                variant={'body3'}
                                sx={{
                                    flex: '1 1 auto',
                                    width: '100%',
                                }}
                            >
                                {datapoint.value < 1 && datapoint.value > 0
                                    ? `< 1%`
                                    : `${Math.round(datapoint.value)}%`}{' '}
                                {datapoint.label}
                            </Text>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    )
}

export default DoughnutChart
