import { LuneTheme, Text, Tile } from '@lune-fe/lune-ui-lib'
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined'
import { Box } from '@mui/material'
import { FC, ReactNode } from 'react'

import BundleAvatar from 'components/BundleAvatar'

const PortfolioButton: FC<{
    dataTestId?: string
    onClick: () => void
    custom?: boolean
    title: string
    subtitle: ReactNode
    price?: string
    images?: string[]
    icon?: ReactNode
}> = ({ custom, onClick, title, subtitle, price, images, icon, dataTestId }) => {
    const { palette } = LuneTheme

    const PORTFOLIO_TILE_WIDTH = 88
    const PORTFOLIO_TILE_ITEM_SIZE = 52

    return (
        <Tile onClick={onClick} sx={{ borderRadius: '8px' }} data-testid={dataTestId}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    pt: 1 / 2,
                    pb: 1 / 2,
                }}
            >
                <Box
                    sx={{
                        minWidth: `${PORTFOLIO_TILE_WIDTH}px`,
                        height: `${PORTFOLIO_TILE_ITEM_SIZE}px`,
                        mr: 3,
                        position: 'relative',
                    }}
                >
                    {images ? (
                        images.map((img, index) => (
                            <Box
                                key={`image-${index}`}
                                className={'test'}
                                sx={{
                                    width: `${PORTFOLIO_TILE_ITEM_SIZE}px`,
                                    height: `${PORTFOLIO_TILE_ITEM_SIZE}px`,
                                    borderRadius: '8px',
                                    border: `2px solid ${palette.White}`,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    overflow: 'hidden',
                                    position: 'absolute',
                                    top: 0,
                                    bottom: 0,
                                    margin: 'auto',
                                    zIndex: -index,
                                    boxSizing: 'border-box',
                                    left: `${
                                        ((PORTFOLIO_TILE_WIDTH - PORTFOLIO_TILE_ITEM_SIZE) /
                                            (images.length - 1)) *
                                        index
                                    }px`,
                                    img: {
                                        width: '100%',
                                    },
                                }}
                            >
                                <BundleAvatar name={title} image={img} />
                            </Box>
                        ))
                    ) : (
                        <Box
                            sx={{
                                width: `${PORTFOLIO_TILE_ITEM_SIZE - 6}px`,
                                height: `${PORTFOLIO_TILE_ITEM_SIZE - 6}px`,
                                borderRadius: '8px',
                                border: `1px solid ${palette.Grey300}`,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {icon}
                        </Box>
                    )}
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        whiteSpace: 'normal',
                        width: '100%',
                    }}
                >
                    <Text variant={'body2'}>{title}</Text>
                    <Text variant={'body3'} sx={{ color: `Grey600`, mt: 1 / 2 }}>
                        {subtitle}
                    </Text>
                    {price && (
                        <Text variant={'body3'} sx={{ color: `Grey600`, mt: 1 / 2 }}>
                            {price}
                        </Text>
                    )}
                </Box>
                <Box
                    sx={{
                        flex: `1 1 ${PORTFOLIO_TILE_ITEM_SIZE}px`,
                        width: `${PORTFOLIO_TILE_ITEM_SIZE}px`,
                        textAlign: 'center',
                    }}
                >
                    {custom ? (
                        <ChevronRightOutlinedIcon />
                    ) : (
                        <Text variant={'body3'} sx={{ fontWeight: '500' }}>
                            Buy
                        </Text>
                    )}
                </Box>
            </Box>
        </Tile>
    )
}
export default PortfolioButton
