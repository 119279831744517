import { Button, LoadingWrapper, LuneTheme, Text, Tile } from '@lune-fe/lune-ui-lib'
import ClearIcon from '@mui/icons-material/Clear'
import Radio from '@mui/material/Radio'
import { capitalize } from 'lodash'
import { useSnackbar } from 'notistack'
import { useState } from 'react'

import { deletePaymentMethod } from 'endpoints/dapi'
import useActiveOrganisation from 'hooks/useActiveOrganisation'
import usePaymentMethod from 'hooks/usePaymentMethod'
import { SnackbarMessages } from 'SnackbarMessages'

const CardsList = ({
    onSelect,
    selectedCardId,
}: {
    onSelect: (stripeId: string) => void
    selectedCardId: string | undefined
}) => {
    const { enqueueSnackbar: snackbar } = useSnackbar()
    const { refetchActiveOrg, activeOrg } = useActiveOrganisation()
    const { isLoading, paymentMethods, refetch } = usePaymentMethod()
    const { palette } = LuneTheme

    const [removingCardId, setRemovingCardId] = useState<string | undefined>()

    const removePaymentMethod = async (stripeId: string) => {
        setRemovingCardId(stripeId)

        await deletePaymentMethod(stripeId)
        snackbar(SnackbarMessages.PAYMENT_METHOD_REMOVE_SUCCESS)
        const dm = activeOrg?.defaultPaymentMethod
        if (dm && dm.type === 'card' && dm.value === stripeId) {
            snackbar(
                `A new default payment method has been automatically selected from the ones available.`,
                { persist: true },
            )
        }
        refetchActiveOrg()
        await refetch()
        setRemovingCardId(undefined)
    }

    return (
        <LoadingWrapper loading={isLoading}>
            {paymentMethods?.map((card, index) => {
                const isLast = index === paymentMethods.length - 1
                return (
                    <Tile
                        data-testid={`card-tile-${card.stripeId}`}
                        onClick={() => onSelect(card.stripeId)}
                        sx={{ mb: isLast ? 0 : 5, borderRadius: `8px` }}
                        key={card.stripeId}
                        icon={
                            <Radio
                                data-testid={`card-radio-${card.stripeId}`}
                                name={`select-existing-card`}
                                sx={{
                                    color: palette.Grey900,
                                    p: 0,
                                    '&.Mui-checked': {
                                        color: palette.Grey900,
                                    },
                                }}
                                checked={selectedCardId === card.stripeId}
                            />
                        }
                        button={
                            <Button
                                data-testid={`card-remove-${card.stripeId}`}
                                variant="text"
                                iconButton
                                loading={removingCardId === card.stripeId}
                                leftIcon={<ClearIcon />}
                                onClick={() => removePaymentMethod(card.stripeId)}
                            />
                        }
                    >
                        <Text
                            variant={'body2'}
                            sx={{
                                py: 2.5,
                                fontSize: '16px',
                                color: palette.Grey900,
                            }}
                        >
                            {`${capitalize(card.brand)} **** **** **** ${card.last4}`}
                        </Text>
                    </Tile>
                )
            })}
        </LoadingWrapper>
    )
}

export default CardsList
