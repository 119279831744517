import { Project, ProjectPerimeter } from '@lune-climate/lune'
import { Text } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'
import { FC, RefObject, useEffect, useState } from 'react'

import { useLuneClient } from 'hooks/useLuneClient'
import DatasetsCarousel from 'views/Projects/Project/MapWithDatasets/DatasetsCarousel'
import Map from 'views/Projects/Project/MapWithDatasets/Map'
import {
    Coordinate,
    Datasets,
    DatasetsProps,
    DatasetsSourceDisclaimer,
    findSWAndNEPoints,
    ProjectSlugsWithApproximatePerimeter,
} from 'views/Projects/Project/MapWithDatasets/utils'

const MapWithDatasets: FC<{
    datasets: DatasetsProps[]
    scrollRef: RefObject<HTMLDivElement>
    project: Project
}> = ({ datasets, scrollRef, project }) => {
    const [activeDataset, setActiveDataset] = useState<Datasets>(datasets[0].dataset)
    const [perimeterData, setPerimeterData] = useState<ProjectPerimeter>()
    const [SWPoint, setSWPoint] = useState<Coordinate>()
    const [NEPoint, setNEoint] = useState<Coordinate>()
    const luneClient = useLuneClient()
    const approximatePerimeter = ProjectSlugsWithApproximatePerimeter.includes(project.slug)

    useEffect(() => {
        luneClient.getProjectPerimeter(project.id).then((res) => {
            if (res.isOk()) {
                setPerimeterData(res.value)
                const { southwestern, northeastern } = findSWAndNEPoints(res.value.coordinates)
                setSWPoint(southwestern)
                setNEoint(northeastern)
            } else {
                console.log('Project perimeter not found')
            }
        })
    }, [project, luneClient])

    return perimeterData ? (
        <>
            <DatasetsCarousel
                scrollRef={scrollRef}
                datasets={datasets}
                onActiveDatasetChange={setActiveDataset}
            />
            <Box
                sx={{
                    height: '560px',
                    width: '100%',
                    borderRadius: '32px',
                    overflow: 'hidden',
                    mt: 5,
                }}
            >
                {SWPoint && NEPoint && (
                    <Map
                        selectedDataset={activeDataset}
                        SWPoint={SWPoint}
                        NEPoint={NEPoint}
                        zoom={10}
                        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN!}
                        initialPosition={{
                            latitude: project.latitude,
                            longitude: project.longitude,
                        }}
                        perimeterData={perimeterData}
                        approximatePerimeter={approximatePerimeter}
                    />
                )}
            </Box>
            <Text variant={'caption'} sx={{ mt: 4, display: 'block' }} color={`Grey600`}>
                {DatasetsSourceDisclaimer.get(activeDataset)}
                {approximatePerimeter && (
                    <Box sx={{ ml: 1 / 2, display: 'inline' }}>Approximate perimeter.</Box>
                )}
            </Text>
        </>
    ) : (
        <></>
    )
}

export default MapWithDatasets
