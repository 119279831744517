import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { LuneTheme, LuneThemeProvider } from '@lune-fe/lune-ui-lib'
import DashboardApp from 'app/DashboardApp'
import mixpanel from 'mixpanel-browser'
import ReactDOM from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import { configCatClient } from 'config/ConfigCat'

import '@fontsource/space-mono'

import SnackbarProvider from './components/SnackbarProvider'
import { getUserData } from './endpoints/dapi'

import '@fontsource/space-mono/400.css'
import './index.css'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 2,
            staleTime: 1000 * 60 * 15, // 15 mins
            cacheTime: 1000 * 60 * 15, // 15 mins
            refetchOnMount: true,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
        },
    },
})

// let's go
const app = (
    <QueryClientProvider client={queryClient}>
        <LuneThemeProvider theme={LuneTheme}>
            <SnackbarProvider>
                <DashboardApp />
            </SnackbarProvider>
        </LuneThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
)

if (process.env.REACT_APP_MIXPANEL_TOKEN && process.env.REACT_APP_MIXPANEL_PROXY) {
    // https://developer.mixpanel.com/docs/collection-via-a-proxy
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
        api_host: process.env.REACT_APP_MIXPANEL_PROXY,
    })
} else if (process.env.REACT_APP_MIXPANEL_TOKEN) {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN)
} else {
    mixpanel.init('<dummy>', { test: true })
    mixpanel.disable()
}

const root = ReactDOM.createRoot(document.getElementById('root')!)
root.render(app)

if (process.env.REACT_APP_BUGSNAG_KEY) {
    Bugsnag.start({
        apiKey: process.env.REACT_APP_BUGSNAG_KEY,
        plugins: [new BugsnagPluginReact()],
        onError: async (event) => {
            try {
                const data = await getUserData()
                event.setUser(
                    data.user.id,
                    data.user.email,
                    `${data.user.firstname} ${data.user.lastname}`,
                )
                event.addMetadata(`account`, data.account)
            } catch {
                console.warn(`Unable to get metadata to pass to bugsnag`)
            }
        },
    })
}

if (configCatClient) {
    configCatClient
        .forceRefreshAsync()
        .then(() =>
            console.log(`Latest feature flag and configuration values successfully downloaded.`),
        )
}
