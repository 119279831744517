import { Bundle } from '@lune-climate/lune'
import { formatNumbers, formatToCurrency } from '@lune-fe/lune-components-lib'
import { Input, ListItemLayout, LuneTheme, Table, Text } from '@lune-fe/lune-ui-lib'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLongOutlined'
import Box from '@mui/material/Box'
import { Big } from 'big.js'
import { isEqual } from 'lodash'
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react'

import { calculateBundleSelectionTotalSum } from 'utils'
import BundleListItemCell from 'views/Settings/BundlePicker/BundleListItemCell'
import { BundlePickerView, calculateAveragePrice } from 'views/Settings/BundlePicker/BundlePicker'
import BundleSwitcherList from 'views/Settings/BundlePicker/BundleSwitcherList'

const CustomBundleSelection: FC<{
    view: BundlePickerView
    readOnly?: boolean
    bundles: Bundle[]
    bundleSelection: Record<string, number | string>
    onChange: (allocation: Record<string, number | string>) => void
    onError?: (error: string | undefined) => void
    allocationVolume?: Record<string, number>
}> = ({ view, readOnly, onChange, onError, bundles, bundleSelection, allocationVolume }) => {
    const { palette } = LuneTheme

    const allocationTotalSum: Big = useMemo(() => {
        return calculateBundleSelectionTotalSum(bundleSelection)
    }, [bundleSelection])

    const allocationVolumeTotalSum: Big = useMemo(() => {
        if (!allocationVolume) {
            return Big(0)
        }
        return calculateBundleSelectionTotalSum(allocationVolume, true)
    }, [allocationVolume])

    const error = useMemo(
        () => (!allocationTotalSum.eq(Big(100)) ? `Please submit exactly 100%` : undefined),
        [allocationTotalSum],
    )

    useEffect(() => {
        onError?.(error)
    }, [error, onError])

    useEffect(() => {
        const allocationTotalSum = Object.values(bundleSelection).reduce(
            (sum, currentAllocation) => {
                try {
                    const amountAsBig = Big(currentAllocation)
                    if (amountAsBig.gte(Big(0)) && amountAsBig.lte(Big(100))) {
                        return sum.add(amountAsBig)
                    }
                } catch {}
                return sum
            },
            Big(0),
        )

        if (!allocationTotalSum.eq(Big(100))) {
            onError?.(`Please submit exactly 100%`)
        }
    }, [bundleSelection, onError])

    const onAllocationInputChange = (
        id: string,
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const value = e.target.value
        try {
            const newAllocation = parseInt(value)
            if (newAllocation <= 100 && newAllocation >= 0) {
                onChange({ ...bundleSelection, [id]: newAllocation })
            }
        } catch {}
    }

    const averageBundlePrice: Big = useMemo(
        () => calculateAveragePrice(bundles, bundleSelection),
        [bundleSelection, bundles],
    )

    // <!-- We use localBundleSelection as a workaround to disconnect the Switch list from the Form state
    // Thus avoiding unnecessary re-renders of the list and allowing for the switch animation to play
    const [localBundleSelection, setLocalBundleSelection] =
        useState<Record<string, number | string>>(bundleSelection)

    useEffect(() => {
        if (!isEqual(localBundleSelection, bundleSelection)) {
            setLocalBundleSelection(bundleSelection)
        }
        // Adding localBundleSelection to dependency below leads to endless loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bundleSelection])

    useEffect(() => {
        onChange(localBundleSelection)
    }, [localBundleSelection, onChange])

    const onSwitchChange = useCallback((id: string, checked: boolean) => {
        if (checked) {
            setLocalBundleSelection((prev: any) => ({ ...prev, [id]: 1 }))
        } else {
            setLocalBundleSelection((prev: any) => {
                const newSelection = { ...prev }
                delete newSelection[id]
                return newSelection
            })
        }
    }, [])
    // End of localBundleSelection workaround -- >

    return (
        <>
            {view === BundlePickerView.PERCENTAGES && (
                <Table.TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        {!!allocationVolume && (
                            <Table.TableHead>
                                <Table.TableRow>
                                    <Table.TableCell colSize={7}>Bundle</Table.TableCell>
                                    <Table.TableCell colSize={2} alignEnd align="right">
                                        % of volume
                                    </Table.TableCell>
                                    <Table.TableCell colSize={2} alignEnd align="right">
                                        Volume
                                    </Table.TableCell>
                                </Table.TableRow>
                            </Table.TableHead>
                        )}
                        <Table.TableBody>
                            {bundles
                                .filter((b) => Object.keys(bundleSelection as any).includes(b.id))
                                .map((bundle) => {
                                    const { id, name } = bundle
                                    return (
                                        <Table.TableRow
                                            key={id}
                                            data-testid={`bundle-${name}`}
                                            hover
                                            sx={{
                                                borderRadius: `8px`,
                                            }}
                                        >
                                            <BundleListItemCell bundle={bundle} />
                                            <Table.TableCell colSize={3} align="right" alignEnd>
                                                <Input
                                                    highlightOnFocus
                                                    data-testid={`${name
                                                        .toLocaleLowerCase()
                                                        .replaceAll(' ', '-')}-input`}
                                                    sx={{ maxWidth: '160px' }}
                                                    disabled={readOnly}
                                                    onChange={(e) => onAllocationInputChange(id, e)}
                                                    onBlur={(e) => {
                                                        if (!e.target.value) {
                                                            onChange({
                                                                ...bundleSelection,
                                                                [id]: 0,
                                                            })
                                                        }
                                                    }}
                                                    percentage
                                                    value={bundleSelection[id].toString() || ``}
                                                />
                                            </Table.TableCell>
                                            {!!allocationVolume && (
                                                <Table.TableCell colSize={2} align="right" alignEnd>
                                                    <Text variant={`body2`}>
                                                        {formatNumbers(
                                                            allocationVolume[bundle.id],
                                                        ) || 0}{' '}
                                                        tCO₂
                                                    </Text>
                                                </Table.TableCell>
                                            )}
                                        </Table.TableRow>
                                    )
                                })}
                            <Table.TableRow>
                                <Table.TableCell colSize={7}>
                                    {error ? (
                                        <Text variant={'body2'} color={palette.Red500}>
                                            {error}
                                        </Text>
                                    ) : (
                                        !allocationVolume && (
                                            <ListItemLayout
                                                image={
                                                    <Box
                                                        sx={{
                                                            width: `48px`,
                                                            height: `48px`,
                                                            display: `flex`,
                                                            justifyContent: `center`,
                                                            alignItems: `center`,
                                                            border: `thin solid`,
                                                            borderColor: palette.Grey300,
                                                            borderRadius: `8px`,
                                                        }}
                                                    >
                                                        <ReceiptLongIcon />
                                                    </Box>
                                                }
                                                title={
                                                    <Text variant={'body2'} color={palette.Grey900}>
                                                        Total
                                                    </Text>
                                                }
                                                subTitle={`Avg. ${
                                                    bundles[0]?.currency &&
                                                    formatToCurrency(
                                                        averageBundlePrice.toString(),
                                                        bundles[0]?.currency,
                                                    )
                                                } / tCO₂`}
                                            />
                                        )
                                    )}
                                </Table.TableCell>
                                <Table.TableCell colSize={3} align="right">
                                    <Box
                                        sx={{
                                            width: `100%`,
                                            p: 1.5,
                                        }}
                                    >
                                        <Text
                                            sx={{
                                                textAlign: `right`,
                                            }}
                                            variant={`body2`}
                                            color={error ? palette.Red500 : palette.Grey900}
                                        >
                                            {allocationTotalSum.toString()}%
                                        </Text>
                                    </Box>
                                </Table.TableCell>
                                {!!allocationVolume && (
                                    <Table.TableCell colSize={2} align="right" alignEnd>
                                        <Text variant={`body2`} data-testid={`allocation-volume`}>
                                            {formatNumbers(allocationVolumeTotalSum.toString())}{' '}
                                            tCO₂
                                        </Text>
                                    </Table.TableCell>
                                )}
                            </Table.TableRow>
                        </Table.TableBody>
                    </Table>
                </Table.TableContainer>
            )}
            {view === BundlePickerView.SWITCHES && (
                <BundleSwitcherList
                    bundles={bundles}
                    bundleSelection={bundleSelection}
                    onChangeItem={onSwitchChange}
                />
            )}
        </>
    )
}

export default CustomBundleSelection
