import { Input } from '@lune-fe/lune-ui-lib'
import { Big } from 'big.js'
import { FC, useCallback, useEffect, useState } from 'react'

import useBuyOffsetsState from 'hooks/useBuyOffsetsState'
import useQuery from 'hooks/useQuery'

const AmountInput: FC<{
    amount: string
    onChange: (amount: string) => void
}> = ({ amount, onChange }) => {
    const [predefinedAmount] = useQuery('amount')
    const [displayAmount, setDisplayAmount] = useState<string>('')
    const { setBuyOffsetProps } = useBuyOffsetsState()

    const onAmountChange = useCallback(
        (val: string) => {
            setDisplayAmount(val)
            if (!val) {
                onChange('')
            } else {
                try {
                    Big(val)
                    onChange(val)
                } catch {
                    setDisplayAmount('')
                }
            }
        },
        [onChange],
    )

    useEffect(() => {
        if (predefinedAmount || amount) {
            onAmountChange(amount || predefinedAmount)
        }
    }, [predefinedAmount, amount, onAmountChange])

    return (
        <Input
            autoFocus
            sx={{
                marginRight: '4px',
                flex: '1 1 50%',
            }}
            placeholder={'Amount'}
            value={displayAmount}
            onChange={(e) => {
                onAmountChange(e.target.value)
                setBuyOffsetProps({
                    isCustomBundleVolumeSet: false,
                })
            }}
            data-testid={`order-amount-input`}
        />
    )
}

export default AmountInput
