import { Text, Tile } from '@lune-fe/lune-ui-lib'
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined'
import Box from '@mui/material/Box'
import { FC, JSX } from 'react'

export interface ResultTileProps {
    icon: JSX.Element | null
    label: JSX.Element | string | null
    href?: string
}

export const ResultLabeledSectionAndTiles: FC<{
    label?: string
    tiles: ResultTileProps[]
    dataTestId?: string
}> = ({ label, tiles, dataTestId }) => {
    return (
        <Box>
            {label ? (
                <Text variant={'h6'} sx={{ mb: 4 }}>
                    {label}
                </Text>
            ) : (
                <></>
            )}
            {tiles.map((tile, index) => (
                <Box key={index}>
                    {tile.label && tile.icon && (
                        <Tile
                            sx={{ svg: { color: `Grey900` } }}
                            icon={tile.icon}
                            data-testId={`${dataTestId}-tile-${index}`}
                            href={tile.href}
                            rightIcon={tile.href ? <ChevronRightOutlinedIcon /> : undefined}
                        >
                            <Text variant={'body2'}>{tile.label}</Text>
                        </Tile>
                    )}
                </Box>
            ))}
        </Box>
    )
}

export default ResultLabeledSectionAndTiles
