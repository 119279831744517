import {
    SustainabilityPage,
    SustainabilityPageSection,
    SustainabilityPageSections,
} from '@lune-climate/lune'
import { LabeledFormSection, ListItemLayout, Switch, Tile } from '@lune-fe/lune-ui-lib'
import { Dispatch, FC, memo, SetStateAction } from 'react'

import bundlesImg from 'images/bundles.png'
import certificatedImg from 'images/certificates.png'
import unSustainabilityGoalsImg from 'images/un-sustainability-goals.png'

const Sections: FC<{
    setOptions: Dispatch<SetStateAction<SustainabilityPage>>
    initialSections: SustainabilityPageSections | null
}> = ({ setOptions, initialSections }) => {
    const sections: SustainabilityPageSections = initialSections
        ? [...initialSections.sort((a, b) => a.localeCompare(b))]
        : []

    const handleSectionsChange = (checked: boolean, section: SustainabilityPageSection) => {
        if (checked) {
            sections.push(section)
        } else {
            sections.splice(sections.indexOf(section), 1)
        }
        setOptions((prevState) => ({
            ...prevState,
            sections: sections.sort((a, b) => a.localeCompare(b)),
        }))
    }

    return (
        <LabeledFormSection
            title={`Sections`}
            subTile={`Choose which sections you’d like to show.`}
        >
            <Tile
                sx={{ height: '64px' }}
                rightSide={
                    <Switch
                        defaultChecked={sections.includes(
                            SustainabilityPageSection.BUNDLES_BREAKDOWN,
                        )}
                        onChange={(checked) =>
                            handleSectionsChange(
                                checked.target.checked,
                                SustainabilityPageSection.BUNDLES_BREAKDOWN,
                            )
                        }
                    />
                }
            >
                <ListItemLayout
                    image={<img width="48" height="48" src={bundlesImg} />}
                    title="Project Bundles breakdown"
                    subTitle="The Project Bundles you’ve chosen, and their percentage breakdown"
                />
            </Tile>
            <Tile
                sx={{ height: '64px' }}
                rightSide={
                    <Switch
                        defaultChecked={sections.includes(SustainabilityPageSection.CERTIFICATES)}
                        onChange={(checked) =>
                            handleSectionsChange(
                                checked.target.checked,
                                SustainabilityPageSection.CERTIFICATES,
                            )
                        }
                    />
                }
            >
                <ListItemLayout
                    image={<img width="48" height="48" src={certificatedImg} />}
                    title="Carbon Credit Certificates"
                    subTitle="The Certificates guaranteeing the credits you purchased have been retired"
                />
            </Tile>
            <Tile
                sx={{ height: '64px' }}
                rightSide={
                    <Switch
                        defaultChecked={sections.includes(SustainabilityPageSection.UNSDG)}
                        onChange={(checked) =>
                            handleSectionsChange(
                                checked.target.checked,
                                SustainabilityPageSection.UNSDG,
                            )
                        }
                    />
                }
            >
                <ListItemLayout
                    image={<img width="48" height="48" src={unSustainabilityGoalsImg} />}
                    title="UN Sustainable Development Goals"
                    subTitle="The SDGs you’re supporting via your Projects"
                />
            </Tile>
        </LabeledFormSection>
    )
}

export default memo(Sections)
