import { LuneTheme, Text } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import { FC, ReactNode } from 'react'

import IDEO from 'images/proposal-view-ideo.png'
import Salesforce from 'images/proposal-view-salesforce.png'
import Visa from 'images/proposal-view-visa.png'

const TrustedByGlobalInnovatorsBox: FC<{ text: ReactNode; logo: string }> = ({ text, logo }) => {
    const { palette } = LuneTheme
    return (
        <Box
            sx={{
                flex: '0 1 33.333%',
            }}
        >
            <Box
                sx={{
                    p: 1,
                    backgroundColor: palette.Grey100,
                    marginTop: '55px',
                    width: '30px',
                    height: '30px',
                    position: 'relative',
                    marginBottom: '-12px',
                }}
            >
                <Box
                    sx={{
                        borderRadius: '100%',
                        backgroundColor: palette.White,
                        width: '88px',
                        height: '88px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'absolute',
                        left: '-4px',
                        bottom: '12px',
                    }}
                >
                    <img width={72} height={72} src={logo} />
                </Box>
            </Box>
            <Box
                sx={{
                    p: 4,
                    backgroundColor: palette.Grey100,
                    borderRadius: 4,
                }}
            >
                <Text variant={'body3'}>{text}</Text>
            </Box>
        </Box>
    )
}

const TrustedByGlobalInnovatorsSection: FC = () => {
    return (
        <Box>
            <Text variant={'h5'}>Trusted by global innovators</Text>
            <Box sx={{ display: 'flex', gap: 4, mt: 4 }}>
                <TrustedByGlobalInnovatorsBox
                    text={
                        <>
                            <b>
                                Lune’s focus on sourcing high-impact carbon credits, combined with
                                the automation their software provides
                            </b>
                            , makes them an ideal partner for Salesforce’s Net Zero Marketplace. Now
                            we can bring companies, customers, and climate action together at scale.
                            <br />
                            <br />
                            Nina Schoen, Director of PM
                        </>
                    }
                    logo={Salesforce}
                />
                <TrustedByGlobalInnovatorsBox
                    text={
                        <>
                            Through our partnership with Lune,{' '}
                            <b>
                                we make it easier for our clients to take meaningful climate action
                            </b>
                            , by embedding emission calculations and carbon offsetting into their
                            product offering.
                            <br />
                            <br />
                            Jeni Mundy, Global SVP Merchant Sales & Acquiring
                        </>
                    }
                    logo={Visa}
                />
                <TrustedByGlobalInnovatorsBox
                    text={
                        <>
                            Lune is our preferred partner due to the{' '}
                            <b>emphasis on quality, real impact, and transparency –</b> something
                            that is generally lacking in the carbon markets. We are committed to
                            credible offsetting and, going forward, aim to increase our spend on
                            permanent carbon removal projects in line with the Oxford Offsetting
                            Principles.
                            <br />
                            <br />
                            Derek Robson, CEO
                        </>
                    }
                    logo={IDEO}
                />
            </Box>
        </Box>
    )
}

export default TrustedByGlobalInnovatorsSection
