import { ShippingEmissionTimeseries } from '@lune-climate/lune'
import { LoadingWrapper, NoResultsPlaceholder, Text } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'
import Chart, { ChartConfiguration } from 'chart.js/auto'
import moment from 'moment'
import { FC, useEffect, useMemo, useRef } from 'react'
import { Range } from 'react-date-range'

import {
    chartAreaBorder,
    chartOptions,
    emissionsChartColors,
    padLeftNumber,
    padLeftString,
} from 'views/Dashboard/Charts/chartUtils'
import ShippingEmissionsChartLegend from 'views/Dashboard/Charts/ShippingEmissionsChartLegend'

import 'chartjs-adapter-moment'

const ShippingEmissionsChart: FC<{
    data: ShippingEmissionTimeseries
    dateRange: Range
    loading: boolean
}> = ({ data, dateRange, loading }) => {
    const canvasEl = useRef<HTMLCanvasElement | null>(null)

    const chartData = useMemo(() => {
        const rangeDuration = moment.duration(moment(dateRange.endDate).diff(dateRange.startDate))
        const format =
            !dateRange.endDate || !dateRange.startDate || rangeDuration.asMonths() > 1
                ? 'MMM, YYYY'
                : 'DD MMM, YYYY'

        return {
            labels: padLeftString(
                data.map((d) => {
                    return moment(d.date).format(format)
                }),
            ),
            datasets: [
                {
                    data: padLeftNumber(data.map((d) => d.sea)),
                    backgroundColor: emissionsChartColors.sea,
                    barThickness: 12,
                },
                {
                    data: padLeftNumber(data.map((d) => d.air)),
                    backgroundColor: emissionsChartColors.air,
                    barThickness: 12,
                },
                {
                    data: padLeftNumber(data.map((d) => d.road)),
                    backgroundColor: emissionsChartColors.road,
                    barThickness: 12,
                },
                {
                    data: padLeftNumber(data.map((d) => d.rail)),
                    backgroundColor: emissionsChartColors.rail,
                    barThickness: 12,
                },
                {
                    data: padLeftNumber(data.map((d) => d.inlandWaterway)),
                    backgroundColor: emissionsChartColors.inlandWaterway,
                    barThickness: 12,
                },
                {
                    data: padLeftNumber(data.map((d) => d.logisticSite)),
                    backgroundColor: emissionsChartColors.logisticSite,
                    barThickness: 12,
                },
            ],
        }
    }, [data, dateRange])

    useEffect(() => {
        const ctx = canvasEl.current?.getContext('2d')
        const maxStackedValue = Math.max(
            ...chartData.labels.map((_, labelIndex) => {
                return chartData.datasets.reduce(
                    (sum, dataset) => sum + dataset.data[labelIndex],
                    0,
                )
            }),
        )
        const config = {
            type: 'bar',
            data: chartData,
            options: chartOptions('tCO₂e', true, maxStackedValue) as any,
            plugins: [chartAreaBorder],
        }
        if (ctx) {
            const barChart = new Chart(ctx, config as ChartConfiguration)

            return () => {
                barChart.destroy()
            }
        }
    }, [chartData])

    return (
        <>
            {data.length ? (
                <>
                    <ShippingEmissionsChartLegend />
                    <LoadingWrapper loading={loading} sx={{ height: '400px', width: '100%' }}>
                        <Box sx={{ height: '400px' }}>
                            <canvas id="emissionsChart" ref={canvasEl} height="100" />
                        </Box>
                    </LoadingWrapper>
                </>
            ) : (
                <Box sx={{ height: `450px` }}>
                    <NoResultsPlaceholder
                        message={
                            <Text
                                variant={`h6`}
                                sx={{
                                    textAlign: 'center',
                                    maxWidth: '250px',
                                }}
                            >
                                You don&apos;t have any emissions calculations for the selected time
                                period
                            </Text>
                        }
                    />
                </Box>
            )}
        </>
    )
}

export default ShippingEmissionsChart
