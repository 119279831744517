import { Button } from '@lune-fe/lune-ui-lib'
import AddIcon from '@mui/icons-material/Add'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

const BuyBundleButton: FC<{
    projectSlug: string
    bundleName: string
    bundleId: string
}> = ({ projectSlug, bundleName, bundleId }) => {
    const navigate = useNavigate()
    return (
        <>
            <Button
                data-testId={'project-buy-bundle-button'}
                sx={{
                    minWidth: '120px',
                    mb: 4,
                }}
                leftIcon={<AddIcon />}
                onClick={() =>
                    navigate(
                        `/order/build-portfolio?bundleId=${bundleId}&projectSlug=${projectSlug}`,
                    )
                }
            >
                Buy {bundleName}
            </Button>
        </>
    )
}

export default BuyBundleButton
