import { Project } from '@lune-climate/lune'
import { LuneTheme, Text, TwoColumnLayout, useIsMobile } from '@lune-fe/lune-ui-lib'
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined'
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined'
import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import { capitalize } from 'lodash'
import { ReactNode, useState } from 'react'

const fourCornerGradientBackground = `
linear-gradient( to top left, #8EC3EF, rgba(255, 153, 150, 0), #B7E4E7 ),
linear-gradient( to top right, #8DC7ED, #8EC3EF, #A6E0EB ), 
white
`

const iconSx = { width: '20px', height: '20px' }

export type ProjectQualities = keyof NonNullable<Project['qualityAssessment']>

const tabsData: Record<
    ProjectQualities,
    {
        title: string
        icon: ReactNode
        bg: string
    }
> = {
    durability: {
        title: `Durability`,
        icon: <LockOutlinedIcon sx={iconSx} />,
        bg: `https://assets.lune.co/project-quality-assessment/durability-original.png`,
    },
    additionality: {
        title: `Additionality`,
        icon: <ControlPointOutlinedIcon sx={iconSx} />,
        bg: `https://assets.lune.co/project-quality-assessment/additionality.png`,
    },
    measurement: {
        title: `Measurement`,
        icon: <BarChartOutlinedIcon sx={iconSx} />,
        bg: `https://assets.lune.co/project-quality-assessment/measurement.png`,
    },
    riskMitigation: {
        title: 'Risk mitigation',
        icon: <GppGoodOutlinedIcon sx={iconSx} />,
        bg: `https://assets.lune.co/project-quality-assessment/risk-mitigation.png`,
    },
    cobenefits: {
        title: `Co-benefits`,
        icon: <VolunteerActivismOutlinedIcon sx={iconSx} />,
        bg: `https://assets.lune.co/project-quality-assessment/coBenefits.png`,
    },
}

const ProjectQualityAssessment = ({
    dynamicSectionDescriptions,
}: {
    dynamicSectionDescriptions: Record<ProjectQualities, { title: string; description: string }>
}) => {
    const { isMobile } = useIsMobile()
    const { palette } = LuneTheme
    const [currentView, setCurrentView] = useState<ProjectQualities>(`durability`)

    return (
        <Box
            sx={{
                background: fourCornerGradientBackground,
                p: 4,
                borderRadius: 8,
                overflow: `hidden`,
                position: 'relative',
                display: `flex`,
                justifyContent: `center`,
                flexDirection: `column`,
                alignItems: `center`,
            }}
        >
            <ButtonGroup
                sx={{
                    width: `100%`,
                    borderRadius: `32px`,
                    border: `none`,
                    height: `56px`,
                    zIndex: `2`,
                }}
            >
                {Object.entries(tabsData).map(([section, { title, icon }]) => {
                    return (
                        <Button
                            key={section}
                            sx={{
                                textTransform: 'none',
                                width: `100%`,
                                border: `thin ${palette.Grey900} solid !important`,
                                borderRadius: `32px`,
                                color: palette.Grey900,
                                '&:hover': {
                                    background: `rgb(0, 0, 0, 0.05)`,
                                },
                                background:
                                    section === currentView
                                        ? `${palette.White} !important`
                                        : `initial`,
                            }}
                            onClick={() => setCurrentView(section as ProjectQualities)}
                        >
                            {icon}
                            {!isMobile && (
                                <Text
                                    sx={{
                                        ml: 2,
                                        fontSize: `14px`,
                                        whiteSpace: `nowrap`,
                                        fontWeight: 500,
                                    }}
                                >
                                    {capitalize(title)}
                                </Text>
                            )}
                        </Button>
                    )
                })}
            </ButtonGroup>

            <TwoColumnLayout
                columnWrapOrderOnMobile={`normal` as any}
                sx={{
                    p: 0,
                    display: `flex`,
                    justifyContent: `center`,
                }}
            >
                <Box
                    sx={{
                        mt: isMobile ? 4 : 0,
                        display: `flex`,
                        flexDirection: `column`,
                        alignItems: `start`,
                        justifyContent: `center`,
                        height: `100%`,
                    }}
                >
                    <Text
                        variant={'h6'}
                        sx={{
                            mb: 2,
                        }}
                    >
                        {dynamicSectionDescriptions[currentView].title}
                    </Text>
                    <Text variant={'body3'} component="pre">
                        {dynamicSectionDescriptions[currentView].description}
                    </Text>
                </Box>
                <Box
                    sx={{
                        height: `320px`,
                        width: `320px`,
                        display: `flex`,
                        justifyContent: `center`,
                        alignItems: `center`,
                        zIndex: `-1`,
                        pt: 2.5,
                    }}
                >
                    <img
                        style={{
                            position: `absolute`,
                            maxHeight: `384px`,
                            zIndex: `1`,
                            objectFit: `contain`,
                        }}
                        src={tabsData[currentView].bg}
                    />
                </Box>
            </TwoColumnLayout>
        </Box>
    )
}

export default ProjectQualityAssessment
