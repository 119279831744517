import { Button, CurrencyPicker, LoadingWrapper, Tooltip } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import Stack from '@mui/material/Stack'
import { AxiosError, AxiosResponse } from 'axios'
import { ConversionIds } from 'Linkedin'
import { useSnackbar } from 'notistack'
import queryString from 'query-string'
import { ReactElement, useCallback, useState } from 'react'
import { Field, Form } from 'react-final-form'
import LinkedInTag from 'react-linkedin-insight'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'

import { FormInput } from 'components/FormInput'
import { DapiErrors, GoogleSSOUserProfile, signup } from 'endpoints/dapi'
import useMixpanel from 'hooks/useMixpanel'
import { useWriteUserState } from 'hooks/useUserState'
import { SnackbarMessages } from 'SnackbarMessages'
import { capitalize } from 'utils'
import FormBox from 'views/Account/Shared/FormBox'
import GenericErrorMessage from 'views/Account/Shared/GenericErrorMessage'
import GoogleSSOButton from 'views/Account/Shared/GoogleSSOButton'
import GoogleSSOData from 'views/Account/Shared/GoogleSSOData'
import Layout from 'views/Account/Shared/Layout'
import RequestConfirmation from 'views/Account/Signup/RequestConfirmation'
import TermsAndPolicies from 'views/Account/Signup/TermsAndPolicies'

interface IFormData {
    email: string
    password: string
    password2: string
    currency: string
    firstname: string
    lastname: string
    organisationName?: string
    marketingConsent: boolean
}

interface ISignupProps {
    setToken: (args: { token: string; docsPublishableKey: string }) => void
}

// To prevent rerendering when the other form elements change define the component here
const CurrencyPickerComponent = ({ input: { onChange, value: value2 }, meta }: any) => (
    <CurrencyPicker
        placeholder={'Currency'}
        error={meta.touched && meta.error}
        value={value2}
        onChange={onChange}
        data-testid="currency-picker"
    />
)

export default function Signup({ setToken }: ISignupProps) {
    const navigate = useNavigate()
    const { setUserState } = useWriteUserState()
    const { enqueueSnackbar: snackbar } = useSnackbar()
    const [marketingConsent, setMarketingConsent] = useState(false)
    const [signedUp, setSignedUp] = useState<boolean>(false)
    const [blocked, setBlocked] = useState<boolean | undefined>() // currently users need to be whitelisted
    const [googleData, setGoogleData] = useState<GoogleSSOUserProfile>()
    const [loading, setLoading] = useState<boolean>(false)
    const mixpanel = useMixpanel()
    const validate = (value: any) => (value?.trim() ? undefined : SnackbarMessages.REQUIRED_FIELD)

    const location = useLocation()
    const {
        invitationId: invitationIdRaw,
        organisationName: organisationNameRaw,
        email: emailRaw,
    } = queryString.parse(location.search)
    const invitationId: string | undefined =
        invitationIdRaw && !(invitationIdRaw instanceof Array) ? invitationIdRaw : undefined
    const organisationName: string | undefined =
        organisationNameRaw && !(organisationNameRaw instanceof Array)
            ? organisationNameRaw
            : undefined
    const email: string | undefined =
        emailRaw && !(emailRaw instanceof Array) ? emailRaw : undefined
    const isOrganisationSignup = invitationId && organisationName

    const emailMustBeInviteTooltip = ({ children }: { children: ReactElement }) => {
        return (
            <Tooltip title="Invited email cannot be modified" placement="top">
                {children}
            </Tooltip>
        )
    }

    const onSubmit = async ({
        email,
        password,
        firstname,
        lastname,
        currency,
        organisationName,
    }: IFormData) => {
        signup({
            firstname,
            lastname,
            marketingConsent,
            password,
            accessToken: googleData?.accessToken,
            email: googleData?.email ? undefined : email,
            currency,
            organisationName,
            invitationId,
        }).then(
            (data) => {
                setToken({ token: data.token!, docsPublishableKey: data.docsPublishableKey })
                setSignedUp(true)
                // Currently on signup, user is always the org admin
                setUserState({
                    user: data.user,
                    account: data.account,
                    isAdmin: true,
                })
                mixpanel.alias(data.user.id)
                mixpanel.track('Signup', {
                    user_id: data.user.id,
                    email,
                })
            },
            (error) => {
                if (error instanceof AxiosError && error.response) {
                    const response = error.response as AxiosResponse<DapiErrors, any>
                    if (response.status === 403) {
                        mixpanel.track('Signup', {
                            email,
                            status: 'blocked',
                        })
                        mixpanel.track('dashboard_access_requested')
                        if (process.env.REACT_APP_TYPEFORM_URL) {
                            const partnerId = process.env.REACT_APP_LINKEDIN_PARTNER_ID
                            if (partnerId) {
                                LinkedInTag.init(partnerId)
                                LinkedInTag.track(ConversionIds.SIGNUP)
                            }

                            window.location.href = `${process.env.REACT_APP_TYPEFORM_URL}#email=${email}`
                        } else {
                            setBlocked(true)
                        }
                    } else if (response.status === 409) {
                        mixpanel.track('SignupFailure')
                        if (response.data.errors[0].message) {
                            snackbar(capitalize(response.data.errors[0].message), {
                                persist: true,
                                variant: 'error',
                            })
                        } else {
                            snackbar(<GenericErrorMessage />, {
                                persist: true,
                                variant: 'error',
                            })
                        }
                    }
                } else {
                    mixpanel.track('SignupFailure')
                    snackbar(<GenericErrorMessage />, {
                        persist: true,
                        variant: 'error',
                    })
                }
            },
        )
    }

    const onGoogleSSOSuccess = useCallback((profile: GoogleSSOUserProfile) => {
        setLoading(true)
        setGoogleData(profile)
        setLoading(false)
    }, [])

    if (blocked === true) {
        return <RequestConfirmation />
    }
    if (signedUp) {
        return <Navigate to={'/'} />
    }

    return (
        <Layout>
            <FormBox title={'Sign Up'}>
                {googleData && (
                    <GoogleSSOData
                        googleData={googleData}
                        setGoogleData={setGoogleData}
                        sx={{ mb: 4 }}
                    />
                )}
                <Form
                    initialValues={{
                        firstname: googleData?.firstname,
                        lastname: googleData?.lastname,
                    }}
                    sx={{
                        width: '100%',
                    }}
                    onSubmit={onSubmit}
                    render={({ handleSubmit, values }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <LoadingWrapper loading={loading}>
                                    <Stack
                                        direction="column"
                                        sx={{
                                            width: '100%',
                                        }}
                                        spacing={{
                                            xs: 1,
                                        }}
                                    >
                                        {!googleData && (
                                            <>
                                                <Field
                                                    disabled={isOrganisationSignup && email}
                                                    name={'email'}
                                                    placeholder={'Email'}
                                                    tooltip={
                                                        isOrganisationSignup && email
                                                            ? emailMustBeInviteTooltip
                                                            : undefined
                                                    }
                                                    component={FormInput}
                                                    defaultValue={
                                                        isOrganisationSignup && email
                                                            ? email
                                                            : undefined
                                                    }
                                                    validate={(value: any) => {
                                                        if (!value?.trim()) {
                                                            return SnackbarMessages.REQUIRED_FIELD
                                                        }
                                                        if (
                                                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                                                                value,
                                                            )
                                                        ) {
                                                            return SnackbarMessages.EMAIL_PATTERN_FAIL
                                                        }
                                                    }}
                                                />
                                                <Field
                                                    type="password"
                                                    name={'password'}
                                                    placeholder={'Password'}
                                                    component={FormInput}
                                                    validate={(value: any) => {
                                                        if (!value?.trim()) {
                                                            return SnackbarMessages.REQUIRED_FIELD
                                                        }
                                                        if (
                                                            !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/.test(
                                                                value,
                                                            )
                                                        ) {
                                                            return SnackbarMessages.PASSWORD_PATTERN
                                                        }
                                                    }}
                                                />
                                                <Field
                                                    type="password"
                                                    name={'password2'}
                                                    placeholder={'Re-enter password'}
                                                    component={FormInput}
                                                    validate={(value: any) => {
                                                        if (value !== values.password) {
                                                            return SnackbarMessages.PASSWORD_MATCH_FAIL
                                                        }
                                                        return value?.trim()
                                                            ? undefined
                                                            : SnackbarMessages.REQUIRED_FIELD
                                                    }}
                                                />
                                            </>
                                        )}
                                        <Field
                                            disabled={!!googleData?.firstname}
                                            name={'firstname'}
                                            placeholder={'First name'}
                                            component={FormInput}
                                            validate={validate}
                                        />
                                        <Field
                                            disabled={!!googleData?.lastname}
                                            name={'lastname'}
                                            placeholder={'Last name'}
                                            component={FormInput}
                                            validate={validate}
                                        />
                                    </Stack>
                                    {!isOrganisationSignup && (
                                        <Box sx={{ mt: 1 }}>
                                            <Field
                                                name={'currency'}
                                                validate={validate}
                                                component={CurrencyPickerComponent}
                                            />
                                        </Box>
                                    )}
                                    {!isOrganisationSignup && (
                                        <Box sx={{ mt: 1 }}>
                                            <Field
                                                name={'organisationName'}
                                                placeholder={'Organization'}
                                                component={FormInput}
                                                validate={validate}
                                            />
                                        </Box>
                                    )}
                                </LoadingWrapper>
                                <TermsAndPolicies
                                    marketingConsent={marketingConsent}
                                    setMarketingConsent={setMarketingConsent}
                                />
                                <Button
                                    data-testid={`submit-btn`}
                                    type={'submit'}
                                    sx={{ width: '100%', mb: 1, mt: 4 }}
                                >
                                    {isOrganisationSignup ? (
                                        <>Agree and sign up</>
                                    ) : (
                                        <>Agree and request access</>
                                    )}
                                </Button>
                                {!googleData && (
                                    <GoogleSSOButton
                                        sx={{ mb: 1 }}
                                        redirectUri={`${window.origin}/signup`}
                                        onSuccess={onGoogleSSOSuccess}
                                        onFailure={() => setLoading(false)}
                                        buttonLabel={'Sign up with Google'}
                                    />
                                )}
                                <Button
                                    variant={'text'}
                                    sx={{ width: '100%', mb: 1 }}
                                    onClick={() => navigate('/login')}
                                >
                                    Login
                                </Button>
                            </form>
                        )
                    }}
                />
            </FormBox>
        </Layout>
    )
}
