import type { SxProps } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import MuiSelect from '@mui/material/Select'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import Input from '../components/Input'

import FieldValidationError from './FieldValidationError'

export interface Props<T> {
    value: T
    items: {
        value: T
        label: string
    }[]
    onChange: (value: T) => void
    sx?: SxProps
    labelSx?: SxProps
    menuSx?: SxProps
    menuElevation?: number
    disabled?: boolean
    label?: string
    error?: string | boolean
}

/**
 * Controlled select component
 * Supports using objects as values (not just strings/numbers),
 * which is useful for complex forms
 * @param prop
 * @param value - the currently selected value - its inferred type is used to infer the type of the items' value
 * @param items - array of objects with value and label properties
 * @param onChange - callback to be called with the selected item's value
 * @param disabled - disables select
 * @param label - used as a placeholder
 * @param error - used to show error state
 * @param sx
 * @param placeholderSx - custom style for label
 * @constructor
 */
const Select = <T,>({
    value,
    items,
    onChange,
    sx,
    labelSx,
    menuSx,
    menuElevation,
    disabled,
    label,
    error,
    ...rest
}: Props<T>) => {
    const { palette, spacing } = useTheme()

    return (
        <>
            <MuiSelect<T>
                data-testid="combobox"
                MenuProps={{
                    PaperProps: {
                        elevation: menuElevation ?? 4,
                        sx: {
                            borderRadius: '8px',
                            '.MuiList-root': {
                                padding: `${spacing(2)} 0`,
                            },
                            '& .MuiMenuItem-root': {
                                padding: `0 ${spacing(2)}`,
                                minHeight: '48px',
                                '&.Mui-selected': {
                                    backgroundColor: palette.Grey100,
                                },
                            },
                            ...menuSx,
                        },
                    },
                }}
                sx={{
                    width: spacing(20),
                    height: spacing(7),
                    backgroundColor: error ? `Red50` : `Grey100`,
                    border: `none !important`,
                    borderRadius: `8px`,
                    '.MuiSelect-select': {
                        color: disabled ? `Grey400` : `Grey700`,
                        textFillColor: disabled
                            ? `${palette.Grey400} !important`
                            : `Grey700  !important`,
                        cursor: disabled ? 'default !important' : 'pointer !important',
                        zIndex: 1,
                    },
                    '.MuiTypography-body2': {
                        color: disabled
                            ? `${palette.Grey400} !important`
                            : `${palette.Grey900} !important`,
                    },
                    '.MuiList-root': {
                        backgroundColor: 'red !important',
                    },
                    '&::before': {
                        content: label && !value ? `"${label}"` : '""',
                        position: 'absolute',
                        display: 'block',
                        zIndex: 0,
                        color: disabled ? `Grey400` : `Grey700`,
                        textTransform: 'capitalize',
                        // TODO @festina check this again
                        ...((labelSx || {}) as any),
                    },
                    ...sx,
                }}
                value={value}
                onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                }}
                onChange={(event) => onChange(event.target.value as T)}
                disableUnderline
                disabled={disabled}
                input={<Input />}
                {...rest}
            >
                {items.map((item, index) => (
                    <MenuItem key={`item-${index}`} value={item.value as any}>
                        <Typography sx={{ color: palette.Grey900 }} variant={`body2`}>
                            {item.label}
                        </Typography>
                    </MenuItem>
                ))}
            </MuiSelect>
            {typeof error === `string` && <FieldValidationError error={error} />}
        </>
    )
}

export default Select
