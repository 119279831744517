import { LoadingWrapper } from '@lune-fe/lune-ui-lib'
import { useSnackbar } from 'notistack'
import { RefObject } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { getProposalById } from 'endpoints/dapi'
import { ProposalResponse } from 'models/openDapi'
import { ALWAYS_CHECK_FOR_NEW_DATA, queryKeys } from 'queryKeys'
import PendingProposal from 'views/Proposals/Proposal/PendingProposal'
import ProposalView from 'views/Proposals/Proposal/ProposalView'

const Proposal = ({ scrollRef }: { scrollRef: RefObject<HTMLDivElement> }) => {
    const navigate = useNavigate()
    const { enqueueSnackbar: snackbar } = useSnackbar()
    const { proposalId } = useParams<{ proposalId: string }>()

    const getProposal = async (id: string): Promise<ProposalResponse | undefined> => {
        try {
            return await getProposalById(id)
        } catch {
            navigate(`/proposal-request`)
            snackbar(`Requested proposal does not exist!`)
            return undefined
        }
    }

    const { isLoading: loadingProposal, data: proposal } = useQuery<ProposalResponse | undefined>(
        [queryKeys.GET_PROPOSAL_BY_ID, proposalId],
        () => (proposalId ? getProposal(proposalId) : undefined),
        {
            ...ALWAYS_CHECK_FOR_NEW_DATA,
        },
    )

    return (
        <LoadingWrapper loading={loadingProposal || !proposal} sx={{ minHeight: '100vh' }}>
            {proposal && (
                <>
                    {proposal.status === 'sent' && proposal.rankedPortfolios ? (
                        <ProposalView scrollRef={scrollRef} proposal={proposal} />
                    ) : (
                        <PendingProposal proposal={proposal} />
                    )}
                </>
            )}
        </LoadingWrapper>
    )
}

export default Proposal
