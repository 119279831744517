import { ShippingEmissionTimeseries } from '@lune-climate/lune'
import { formatNumbers } from '@lune-fe/lune-components-lib'
import { Button, Text } from '@lune-fe/lune-ui-lib'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import Box from '@mui/material/Box'
import { FC, useMemo, useRef } from 'react'
import { Range } from 'react-date-range'

import Divider from 'components/Divider'
import { downloadImageFromHtml } from 'utils'
import ShippingEmissionsChart from 'views/Dashboard/Charts/ShippingEmissionsChart'

const EmissionsSection: FC<{
    dateRange: Range
    data?: ShippingEmissionTimeseries
}> = ({ dateRange, data }) => {
    const printRef = useRef<HTMLElement>(null)

    const total = useMemo(() => {
        const total = data?.reduce((acc, item) => {
            return (
                acc +
                item.air +
                item.sea +
                item.road +
                item.rail +
                item.inlandWaterway +
                item.logisticSite
            )
        }, 0)
        return formatNumbers(total ?? 0, 2).toString()
    }, [data])

    return data && total !== '0' ? (
        <>
            <Box sx={{ mt: 9 }}>
                <Box ref={printRef} sx={{ mt: '0 !important' }}>
                    <Text variant={'h4'} sx={{ mb: 9 }}>
                        {total} tCO₂e shipping emissions
                    </Text>
                    <ShippingEmissionsChart loading={false} data={data} dateRange={dateRange} />
                </Box>
            </Box>
            <Button
                onClick={() => {
                    if (printRef.current) {
                        downloadImageFromHtml(printRef.current, `shipping-emissions-graph`)
                    }
                }}
                leftIcon={<FileDownloadOutlinedIcon />}
                variant={'outlined'}
            >
                Download graph
            </Button>
            <Divider />
        </>
    ) : (
        <></>
    )
}

export default EmissionsSection
