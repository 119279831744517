import moment from 'moment'
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { Range } from 'react-date-range'
import { useSearchParams } from 'react-router-dom'

import useAccounts from 'hooks/useAccounts'
import useUpdateEffect from 'hooks/useUpdateEffect'

export interface onLoadPropsWithType<T> {
    selectedAccountIds: string[]
    types?: T[]
    dateRange: Range
}

type FiltersWithQueryParamsWrapperProps<T> = {
    selectedAccountIds: string[] | undefined
    setSelectedAccountIds: Dispatch<SetStateAction<string[] | undefined>>
    dateRange: Range
    setDateRange: Dispatch<SetStateAction<Range>>
    dropDownFilterName?: string
    selectedTypes?: T[]
    setSelectedTypes?: Dispatch<SetStateAction<T[]>>
    setQueryParams?: (qp: string) => void
}

export const ACCOUNT_ID_QUERY_PARAM_KEY = 'account_id'

const getNewAccountIdsSelection = (
    activeAccount: string,
    currentlySelectedAccountIds: string[],
    hasUserManuallyChangedAccount: boolean,
): string[] => {
    // Case 1: User has manually changed the account (e.g. via the account switcher / TEST/LIVE toggle)
    // Discard all existing accountIds from the URL, use latest accountId
    if (hasUserManuallyChangedAccount) {
        return [activeAccount]
    }

    if (currentlySelectedAccountIds[0] === 'none') {
        // Case 2: Initial load of the page when 'none' has specifically been selected (de-selected all accounts)
        return []
    } else {
        // Case 3: Initial load of the page and no existing accountIds in the URL
        // Set the primary account as the selected account
        if (!currentlySelectedAccountIds.length) {
            return [activeAccount]
        }

        // Case 4: Initial load of the page, but with existing accountIds in the URL (like someone has shared a link with filters)
        return currentlySelectedAccountIds
    }
}

const FiltersWithQueryParamsWrapper = <T,>({
    dropDownFilterName,
    selectedAccountIds,
    dateRange,
    selectedTypes,
    setSelectedAccountIds,
    setDateRange,
    setQueryParams,
    setSelectedTypes,
}: FiltersWithQueryParamsWrapperProps<T>) => {
    const [, setSearchParams] = useSearchParams()
    const { activeAccount } = useAccounts()
    const [activeAccountChangeLog, setActiveAccountChangeLog] = useState<string[]>([])

    useEffect(() => {
        // Track primary account changes to determine if a non-initial account change has occurred
        // e.g. the user used the account UI controls (TEST/LIVE switcher or account dropdown)
        // NOT PERSISTENT --> log discarded on component unmount
        if (activeAccount) {
            // Only last 2 account changes are tracked to keep array small
            setActiveAccountChangeLog((prev) => [...prev.slice(-1), activeAccount.id])
        }
    }, [activeAccount])

    const addFiltersToUrl = useCallback(
        (selectedAccountIds: string[], dateRange: Range, selectedTypes?: T[]) => {
            let types
            if (dropDownFilterName) {
                types = selectedTypes?.map((type) => `${dropDownFilterName}=${type}`).join('&')
                if (types && types.length) {
                    types = '&'.concat(types)
                }
            }
            const accounts = selectedAccountIds.length
                ? selectedAccountIds
                      .map((accountId) => `${ACCOUNT_ID_QUERY_PARAM_KEY}=${accountId}`)
                      .join('&')
                : `${ACCOUNT_ID_QUERY_PARAM_KEY}=none`

            const { startDate, endDate } = dateRange
            const from = startDate ? `&from=${startDate.toISOString()}` : ''
            const through = endDate ? `&through=${endDate.toISOString()}` : ''
            const queryParams = `${accounts}${types ?? ''}${from}${through}`
            setQueryParams?.(queryParams)
            setSearchParams(queryParams)
        },
        [dropDownFilterName, setQueryParams, setSearchParams],
    )

    /**
     * Handle initial load of the page and user manually changing the account
     */
    useEffect(() => {
        if (!activeAccountChangeLog.length) {
            return
        }

        const params = new URLSearchParams(window.location.search)
        const accountIds = params.getAll(ACCOUNT_ID_QUERY_PARAM_KEY)
        const [from] = params.getAll('from')
        const [through] = params.getAll('through')

        if (dropDownFilterName) {
            const types = params.getAll(dropDownFilterName)
            setSelectedTypes?.(types as T[])
        }

        const newAccountIdsSelection = getNewAccountIdsSelection(
            activeAccountChangeLog.at(-1)!,
            accountIds,
            activeAccountChangeLog.length > 1,
        )

        setSelectedAccountIds(newAccountIdsSelection)
        if (from && through) {
            setDateRange({
                startDate: moment(from).toDate(),
                endDate: moment(through).toDate(),
            })
        }
    }, [
        activeAccountChangeLog,
        dropDownFilterName,
        setDateRange,
        setSelectedAccountIds,
        setSelectedTypes,
    ])

    useUpdateEffect(() => {
        if (selectedAccountIds) {
            addFiltersToUrl(selectedAccountIds, dateRange, selectedTypes)
        }
    }, [selectedAccountIds, dateRange, selectedTypes])

    return <></>
}

export default FiltersWithQueryParamsWrapper
