import useActiveAccount from 'hooks/useActiveAccount'
import { AccountType } from 'models/account'

/**
 * Using this simple hook allows us to easily centralize instances of
 * "branching" logic for LIVE/TEST mode
 */
const useIsTestMode = (): boolean | undefined => {
    const { activeAccount, isLoadingActiveAccount } = useActiveAccount()
    return isLoadingActiveAccount || activeAccount === undefined
        ? undefined
        : activeAccount.type === AccountType.TEST
}

export default useIsTestMode
