import { SustainabilityPage } from '@lune-climate/lune'
import { Button, Input, LabeledFormSection } from '@lune-fe/lune-ui-lib'
import ContentCopyIcon from '@mui/icons-material/ContentCopyOutlined'
import Box from '@mui/material/Box'
import { useSnackbar } from 'notistack'
import { Dispatch, FC, SetStateAction } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import slugify from 'slugify'

import useIsTestMode from 'hooks/useIsTestMode'
import useMixpanel from 'hooks/useMixpanel'

const SLUG_REGEX = /^[\w\s-]+$/

export function toSlug(str: string): string {
    return slugify(str, {
        replacement: '-',
        remove: /[^\w\s-]+/g,
        lower: true,
        strict: true,
    }).trim()
}

const PublicURL: FC<{
    options: SustainabilityPage
    setOptions: Dispatch<SetStateAction<SustainabilityPage>>
    error?: string
    setError: Dispatch<SetStateAction<string | undefined>>
}> = ({ options, setOptions, error, setError }) => {
    const isTestMode = useIsTestMode()
    const { enqueueSnackbar: snackbar } = useSnackbar()
    const mixpanel = useMixpanel()

    const sustainabilityPageUrl = process.env.REACT_APP_SUSTAINABILITY_URL
    const sustainabilityPageDomain = sustainabilityPageUrl!.replace(/(^\w+:|^)\/\//, '')

    return (
        <LabeledFormSection title={`Public URL`}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                }}
            >
                <Box sx={{ width: '100%', marginLeft: '4px' }}>
                    <Input
                        value={options.slug}
                        startAdornment={
                            isTestMode
                                ? `${sustainabilityPageDomain}/test/`
                                : `${sustainabilityPageDomain}/`
                        }
                        sx={{ width: '100%' }}
                        error={error}
                        onChange={(val) =>
                            setOptions((prev) => {
                                setError(undefined)
                                if (
                                    val.target.value.match(SLUG_REGEX) ||
                                    !val.target.value.length
                                ) {
                                    const slug = val.target.value
                                        .toLocaleLowerCase()
                                        .replaceAll(' ', '-')
                                    return {
                                        ...prev,
                                        slug,
                                    }
                                } else {
                                    return {
                                        ...prev,
                                    }
                                }
                            })
                        }
                    />
                </Box>
                <CopyToClipboard
                    text={
                        isTestMode
                            ? `${sustainabilityPageDomain}/test/${options.slug}`
                            : `${sustainabilityPageDomain}/${options.slug}`
                    }
                    onCopy={() => {
                        mixpanel.track('share_your_impact_url_copied')
                        return snackbar(`URL copied`)
                    }}
                >
                    <Button
                        sx={{ height: '56px', width: '56px' }}
                        iconButton
                        variant={'text'}
                        leftIcon={<ContentCopyIcon />}
                    />
                </CopyToClipboard>
            </Box>
        </LabeledFormSection>
    )
}

export default PublicURL
