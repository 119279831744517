import { Button } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { Field, Form } from 'react-final-form'

import { FormInput } from 'components/FormInput'

/**
 * A form to add a new card - powered by Stripe
 * Only meant to be used within a Stripe Elements component (i.e. AddCardForm)
 * @constructor
 */
const AddCardPaymentElement = ({
    onSuccess,
    onBack,
}: {
    onSuccess?: (stripeId?: string) => void
    onBack: () => void
}) => {
    const { enqueueSnackbar: snackbar } = useSnackbar()
    const [isReady, setIsReady] = useState<boolean>(false)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const stripe = useStripe()
    const elements = useElements()

    const submit = async (values: { name: string }) => {
        if (!stripe || !elements) {
            return
        }

        setIsSubmitting(true)

        const result = await stripe.confirmSetup({
            elements,
            confirmParams: {
                payment_method_data: {
                    billing_details: {
                        name: values.name,
                    },
                },
            },
            redirect: `if_required`,
        })

        if (result.setupIntent?.status === 'succeeded') {
            snackbar(`Your card was successfully added.`)
            const paymentMethod = result.setupIntent.payment_method
            onSuccess?.(typeof paymentMethod === 'string' ? paymentMethod : paymentMethod?.id)
        } else if (result.error) {
            snackbar(result.error.message || `Your card could not be added.`, {
                variant: 'error',
            })
        }

        setIsSubmitting(false)
    }

    return (
        <Form
            onSubmit={submit}
            render={({ handleSubmit }) => (
                <form style={{ width: '704px', minHeight: '300px' }} onSubmit={handleSubmit}>
                    {isReady && (
                        <Field
                            validate={(value: any) =>
                                value ? undefined : 'This is a required field'
                            }
                            placeholder={'Name'}
                            name={'name'}
                            highlightOnFocus
                            component={FormInput}
                        />
                    )}
                    <Box
                        sx={{
                            mt: 1.4,
                        }}
                    >
                        <PaymentElement onReady={() => setIsReady(true)} />
                    </Box>
                    {isReady && (
                        <Box sx={{ mt: 6, justifyContent: `center`, display: `flex` }}>
                            <Button
                                data-testid={'add-card-back-btn'}
                                variant={'outlined'}
                                wide
                                onClick={() => onBack()}
                                sx={{
                                    mr: 1,
                                }}
                            >
                                Back
                            </Button>
                            <Button
                                data-testid={'save-card-btn'}
                                loading={isSubmitting}
                                variant={'contained'}
                                wide
                                disabled={!stripe || !elements || !isReady}
                                type={`submit`}
                            >
                                Save
                            </Button>
                        </Box>
                    )}
                </form>
            )}
        />
    )
}

export default AddCardPaymentElement
