import { LabeledFormSection } from '@lune-fe/lune-ui-lib'
import Stack from '@mui/material/Stack'
import { FormApi } from 'final-form'
import { Dispatch, FC, memo, SetStateAction } from 'react'
import { Field, Form } from 'react-final-form'

import { FormInput } from 'components/FormInput'

const required = (value: any) => (value ? undefined : 'This is a required field')

export enum WebhookFormKeys {
    URL = 'URL',
}

export interface WebhookFormModel {
    [WebhookFormKeys.URL]: URL
}

const CreateWebhookForm: FC<{
    initialValues: WebhookFormModel | {}
    onSubmit: (data: WebhookFormModel) => Promise<any>
    setFormApi: Dispatch<SetStateAction<FormApi<WebhookFormModel> | undefined>>
    setFormValue: Dispatch<SetStateAction<WebhookFormModel | undefined>>
    urlError?: string
}> = ({ initialValues, onSubmit, setFormApi, setFormValue, urlError }) => {
    return (
        <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            render={({ form, values }) => {
                setFormValue(values)
                setFormApi(form)
                return (
                    <Stack
                        direction="column"
                        sx={{
                            mb: 9,
                            width: '100%',
                        }}
                        spacing={{
                            xs: 9,
                        }}
                    >
                        <LabeledFormSection
                            title={'URL'}
                            subTile={
                                'A valid URL that will be called as the webhook receives new events.'
                            }
                        >
                            <Field
                                name={WebhookFormKeys.URL}
                                placeholder={'URL'}
                                component={FormInput}
                                validate={required}
                                error={urlError}
                            />
                        </LabeledFormSection>
                    </Stack>
                )
            }}
        />
    )
}

export default memo(CreateWebhookForm, (prev, next) => {
    return (
        JSON.stringify(prev.initialValues) === JSON.stringify(next.initialValues) &&
        JSON.stringify(prev.urlError) === JSON.stringify(next.urlError)
    )
})
