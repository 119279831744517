import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import React, { forwardRef, useImperativeHandle, useMemo } from 'react'
import { DropzoneOptions, useDropzone } from 'react-dropzone'

import DragDropFocusBg from '../images/DragDropFocusBg.png'
import { LuneTheme } from '../theme'

const baseStyle = {
    borderRadius: '24px',
    backgroundColor: LuneTheme.palette.White,
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    gap: '10px',

    /* dashed border */
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='24' ry='24' stroke='rgb(224,224,224)' stroke-width='2' stroke-dasharray='24%2c24' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e")`,
}

const uploadStyle = {
    backgroundImage: 'unset',
    backgroundColor: 'unset',
}

const rejectedStyle = {
    backgroundColor: `#F8E9E9`,
    height: '100%',
    width: '100%',
    borderRadius: '24px',

    /* dashed border */
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='24' ry='24' stroke='%23D32F2F' stroke-width='8' stroke-dasharray='24%2c24' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e")`,
}

const ConditionalWrapper = ({
    condition,
    wrapper,
    children,
}: {
    condition: boolean
    children: React.ReactNode
    wrapper: (c: React.ReactNode) => React.ReactNode
}) => (condition ? wrapper(children) : children)

const BlendedBorder = () => (
    <Box
        sx={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='24' ry='24' stroke='%23000000' stroke-width='8' stroke-dasharray='24%2c24' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e")`,
            mixBlendMode: 'overlay',
        }}
    />
)

export interface DragAndDropFileUploadProps extends DropzoneOptions {
    description?: string
    children?: React.ReactNode
    isUploading?: boolean
    rejectDescription?: string
}

const DragAndDropFileUpload = forwardRef<
    Pick<HTMLInputElement, 'click'>,
    DragAndDropFileUploadProps
>((props, ref) => {
    const { palette } = LuneTheme
    const { isUploading, children } = props

    const {
        getRootProps,
        getInputProps,
        isFileDialogActive,
        isDragAccept,
        isDragReject,
        inputRef,
    } = useDropzone({
        ...props,
        ...(isUploading ? { disabled: true } : {}), // disable new file isUploading if a file is being uploaded
        maxFiles: 1, // for now, limit to 1 file at the time
    })

    // https://react.dev/reference/react/useImperativeHandle
    // https://blog.webdevsimplified.com/2022-06/use-imperative-handle/
    // Why is this needed?
    // https://react-dropzone.js.org/#section-opening-file-dialog-programmatically
    // describes how to open the upload modal programmatically,
    // but the example requires access to `useDropzone`, so how do I open the modal
    // from an external component?
    // `getInputProps()` already uses ref: I cannot pass one in, otherwise react-dropzone does not work.
    // I have to override it.
    if (ref) {
        useImperativeHandle(
            ref,
            () => ({
                click: () => {
                    if (inputRef.current) {
                        inputRef.current.click()
                    }
                },
            }),
            [inputRef],
        )
    }

    const isDragging = useMemo(() => {
        return isFileDialogActive || isDragAccept
    }, [isFileDialogActive, isDragAccept])

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragging && !isUploading ? uploadStyle : {}),
            ...(isDragReject && !isUploading ? rejectedStyle : {}),
        }),
        [isDragging, isDragReject],
    )

    DragAndDropFileUpload.displayName = 'DragAndDropFileUpload'

    return (
        <ConditionalWrapper
            condition={isDragging && !isUploading}
            wrapper={(children) => (
                <>
                    <Box
                        sx={{
                            position: 'relative',
                            width: '100%',
                            height: '100%',
                            borderRadius: '24px',
                            backgroundImage: `url(${DragDropFocusBg})`,
                            backgroundSize: 'cover',
                            overflow: 'hidden',
                            border: 'solid 1px #ffffff', // hack: the border and background result in an aliasing issue where tiny grey border is show.
                            boxSizing: 'border-box',
                        }}
                    >
                        {/* double border with mix blend mode overlay to make it double-dark */}
                        <BlendedBorder />
                        <BlendedBorder />
                        <Box
                            sx={{
                                position: 'absolute',
                                zIndex: '30',
                                height: '100%',
                                width: '100%',
                                borderRadius: '24px',
                            }}
                        >
                            {children}
                        </Box>
                    </Box>
                </>
            )}
        >
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                {children || (
                    <>
                        {isDragReject && props.rejectDescription ? (
                            <Typography sx={{ color: palette.Red500 }} variant="body1">
                                {props.rejectDescription}
                            </Typography>
                        ) : (
                            <Typography
                                sx={{ color: isDragging ? palette.Grey900 : palette.Grey400 }}
                                variant="body1"
                            >
                                {props.description
                                    ? props.description
                                    : !isDragReject && isUploading
                                      ? 'Uploading...'
                                      : 'Drop your file here'}
                            </Typography>
                        )}
                    </>
                )}
            </div>
        </ConditionalWrapper>
    )
})

// Resolves `error    Component definition is missing display name  react/display-name`
// https://stackoverflow.com/questions/67992894/component-definition-is-missing-display-name-for-forwardref
DragAndDropFileUpload.displayName = 'DragAndDropFileUpload'

export default DragAndDropFileUpload
