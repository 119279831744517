import { LuneTheme, ScrollableContainer, Text } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'
import React, { FC, useState } from 'react'

import {
    DatasetInfo,
    Datasets,
    DatasetsListWithInfo,
    DatasetsProps,
} from 'views/Projects/Project/MapWithDatasets/utils'

const DatasetCard: FC<{
    active: boolean
    dataset: Datasets
    thumbnailUrl: string
    description: string
    onCardClick: (dataset: Datasets) => void
}> = ({ active, dataset, onCardClick, thumbnailUrl, description }) => {
    const datasetWithDetails: DatasetInfo = DatasetsListWithInfo.find((d) => d.dataset === dataset)!

    return (
        <Box
            onClick={() => onCardClick(dataset)}
            sx={{
                cursor: 'pointer',
                mr: 3,
                width: '120px',
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                overflow: 'hidden',
                minHeight: '174px',
                transition: 'width .3s ease-in-out',
                ...(active && {
                    width: '400px',
                    flexDirection: 'row',
                }),
            }}
        >
            <Box
                sx={{
                    width: '120px',
                    height: '90px',
                    maxHeight: '90px',
                    backgroundImage: `url('${thumbnailUrl}')`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    borderRadius: '16px',
                    transition: 'height .3s ease-in-out',
                    flex: '1 0 auto',
                    ...(active && {
                        flex: '1 0 auto',
                        height: '174px',
                        maxHeight: '174px',
                    }),
                }}
            />
            {active ? (
                <Box
                    sx={{
                        display: 'flex',
                        gap: 1,
                        flexDirection: 'column',
                        // 400 - 120 + 16
                        width: '264px',
                        flex: '1 0 auto',
                    }}
                >
                    <Text variant={'h6'}>{datasetWithDetails.name}</Text>
                    {datasetWithDetails.year && (
                        <Text variant={'body2'} sx={{ color: `Grey600` }}>
                            {datasetWithDetails.year}
                        </Text>
                    )}
                    <Text variant={'body3'} sx={{ color: `Grey600` }}>
                        {description}
                    </Text>
                </Box>
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        gap: 1 / 2,
                        width: '120px',
                        flexDirection: 'column',
                    }}
                >
                    <Text variant={'body3'}>{datasetWithDetails.name}</Text>
                    {datasetWithDetails.year && (
                        <Text variant={'caption'} sx={{ color: `Grey600` }}>
                            {datasetWithDetails.year}
                        </Text>
                    )}
                </Box>
            )}
        </Box>
    )
}

const DatasetsCarousel: FC<{
    scrollRef: React.RefObject<HTMLDivElement>
    datasets: DatasetsProps[]
    onActiveDatasetChange: (dataset: Datasets) => void
}> = ({ scrollRef, datasets, onActiveDatasetChange }) => {
    const [activeDataset, setActiveDataset] = useState<Datasets>(datasets[0].dataset)
    const { palette } = LuneTheme

    return (
        <Box
            sx={{
                display: 'block',
                mt: 5,
                width: '100%',
            }}
        >
            <ScrollableContainer containerRef={scrollRef}>
                {datasets.map((item, index) => {
                    const isActive = activeDataset === item.dataset
                    return (
                        <Box
                            key={index}
                            sx={{
                                display: 'flex',
                            }}
                        >
                            {isActive && index !== 0 && (
                                <Box
                                    sx={{
                                        height: '100%',
                                        width: '1px',
                                        backgroundColor: palette.Grey300,
                                        mr: 3,
                                    }}
                                />
                            )}
                            <DatasetCard
                                active={isActive}
                                onCardClick={(d) => {
                                    setActiveDataset(d)
                                    onActiveDatasetChange(d)
                                }}
                                dataset={item.dataset}
                                thumbnailUrl={item.thumbnailUrl}
                                description={item.description}
                            />
                            {isActive && index !== datasets.length - 1 && (
                                <Box
                                    sx={{
                                        height: '100%',
                                        width: '1px',
                                        backgroundColor: palette.Grey300,
                                        mr: 3,
                                    }}
                                />
                            )}
                        </Box>
                    )
                })}
            </ScrollableContainer>
        </Box>
    )
}

export default DatasetsCarousel
