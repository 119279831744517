import { Input, Text } from '@lune-fe/lune-ui-lib'
import { FC, ReactElement, ReactNode } from 'react'

import ConditionalTooltip from 'components/ConditionalTooltip'
import NotAdminTooltip from 'components/NotAdminTooltip'

type FormInputProps = {
    disabled?: boolean
    input: any
    error?: boolean | string
    meta: any
    placeholder?: string
    endAdornment: ReactNode
    number: boolean
    restrictToNumbers: boolean
    highlightOnFocus?: boolean
    tooltip?: FC<{ children: ReactElement }>
}

export const FormInput = ({
    disabled,
    input: { onChange, value, type, name },
    error,
    meta,
    placeholder,
    endAdornment,
    number,
    restrictToNumbers,
    highlightOnFocus,
    tooltip,
}: FormInputProps) => {
    const validateNumber = (value: string) => {
        if (value === '' || value === '-' || !isNaN(Number(value))) {
            onChange(value)
        }
    }

    return (
        <ConditionalTooltip show={!!disabled} tooltip={tooltip || NotAdminTooltip}>
            <Input
                data-testid={`input-${name}`}
                type={type}
                wide
                highlightOnFocus={highlightOnFocus}
                placeholder={placeholder}
                error={error || (meta.touched && meta.error)}
                value={value}
                onBlur={() => {
                    // this is a workaround to handle number value emitting values of type 'number'
                    // we need to convert it onBlur because doing so on change causes issues when typing decimal numbers
                    // Number('1.0') is converted to 1, and while typing it should not be converted
                    if (number) {
                        onChange(Number(value))
                    }
                }}
                onChange={(e) => {
                    if (number || restrictToNumbers) {
                        validateNumber(e.target.value)
                    } else {
                        return onChange(e)
                    }
                }}
                disabled={disabled}
                endAdornment={<Text variant={'body2'}>{endAdornment}</Text>}
            />
        </ConditionalTooltip>
    )
}
