import { CloudProvider } from '@lune-climate/lune'
import { Select } from '@lune-fe/lune-ui-lib'

export const getCloudProviderName = (name: CloudProvider) => {
    switch (name) {
        case CloudProvider.AWS:
            return 'Amazon Web Services'
        case CloudProvider.GCP:
            return 'Google Cloud Platform'
        case CloudProvider.AZURE:
            return 'Azure'
        case CloudProvider.OVH:
            return 'OVH Cloud'
        case CloudProvider.OTHER:
            return 'other services'
    }
}

export const CloudInfrastructureProviderPicker = ({ onChange, value }: any) => {
    const providers = Object.values(CloudProvider).map((value) => ({
        label: getCloudProviderName(value),
        value,
    }))
    return <Select sx={{ width: '100%' }} onChange={onChange} value={value} items={providers} />
}
