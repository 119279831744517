import { useEffect } from 'react'

import { initialiseAnalyticTools } from 'config/analytics'
import useUserState from 'hooks/useUserState'

/**
 * This is a special component within the DashboardApp (the "root component"), rendered as sibling of Dashboard
 * within the Router.
 *
 * Use it run effects and initialisation code that only runs once, when the Dashboard is first mounted.
 * Having this component avoids unnecessary re-renders of the entire app (a potential side effect of using useEffect()
 * and hooks in the "root component")
 */
const OnDashboardInitialRender = () => {
    const { userState } = useUserState()

    useEffect(() => {
        initialiseAnalyticTools(userState)
    }, [userState])

    return null
}

export default OnDashboardInitialRender
