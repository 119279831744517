import { Button, Text } from '@lune-fe/lune-ui-lib'
import { isEmpty, isNil } from 'lodash'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { verifyEmail } from 'endpoints/dapi'
import { SnackbarMessages } from 'SnackbarMessages'
import FormBox from 'views/Account/Shared/FormBox'
import Layout from 'views/Account/Shared/Layout'

function extractToken(path: string): string {
    const items = path.split('/')
    return items[2]
}

const VerifyEmailConfirmation = () => {
    const location = useLocation()
    const [success, setSuccess] = useState<boolean | undefined>(undefined)
    const { enqueueSnackbar: snackbar } = useSnackbar()

    useEffect(() => {
        const token = extractToken(location.pathname)
        const submitToken = async (token: string) => {
            verifyEmail(token).then(
                () => setSuccess(true),
                () => {
                    setSuccess(false)
                    snackbar(SnackbarMessages.EMAIL_VERIFICATION_FAIL)
                },
            )
        }

        if (!isNil(token) && !isEmpty(token)) {
            submitToken(token)
        }
    }, [location, snackbar])

    return (
        <Layout>
            {success === true && (
                <FormBox title={'Your email is verified'}>
                    <Text variant={'body1'}>You may now place orders.</Text>
                    <Link to="/login">
                        <Button sx={{ width: '100%', mb: 1, mt: 4 }}>Login</Button>
                    </Link>
                </FormBox>
            )}
        </Layout>
    )
}

export default VerifyEmailConfirmation
