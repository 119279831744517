import { EmissionFactorWithGasEmissions, GasEmissions } from '@lune-climate/lune'
import { EmissionFactorExplanation } from '@lune-fe/lune-components-lib'
import {
    BackButton,
    ButtonGroup,
    LoadingWrapper,
    MainLayoutContainer,
    Text,
} from '@lune-fe/lune-ui-lib'
import { Stack } from '@mui/material'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import useEmissionFactorVersions from 'hooks/useEmisssionFactorVersions'
import useNavigatedWithinTheApp from 'hooks/useNavigatedWithinTheApp'

const EmissionFactorDetails = () => {
    const navigate = useNavigate()
    const { id } = useParams<{ id: string }>()
    const { emissionFactorVersions } = useEmissionFactorVersions(id)
    // Tracks changing tab after the initial load.
    const [activeTabIndex, setActiveTabIndex] = useState<number | undefined>()

    const shouldShowTheBackButton = useNavigatedWithinTheApp()

    let contents: JSX.Element | undefined
    if (emissionFactorVersions) {
        // A 0-based index of the currently shown emission factor. If we haven't switched tabs
        // yet we default to the emission factor selected via the id.
        const effectiveIndex = activeTabIndex
            ? activeTabIndex - 1
            : emissionFactorVersions.identifiedIndex
        const activeFactor = emissionFactorVersions.versions[effectiveIndex]
        contents = (
            <MainLayoutContainer
                headerComponent={
                    <>
                        {shouldShowTheBackButton && (
                            <BackButton
                                sx={{ mb: 3, ml: -2 }}
                                onClick={() => {
                                    return navigate(-1)
                                }}
                            />
                        )}
                        <Text variant={'h4'}>{activeFactor.name}</Text>
                    </>
                }
            >
                <Stack spacing={9}>
                    <ButtonGroup
                        items={emissionFactorVersions.versions.map((factor, index) => {
                            return {
                                value: (index + 1).toString(),
                                label: `v ${factor.sourceVersion}`,
                            }
                        })}
                        onChange={(active) => {
                            setActiveTabIndex(Number(active))
                        }}
                        value={`${effectiveIndex + 1}`}
                    />
                    <EmissionFactorExplanation
                        emissionFactor={
                            activeFactor as Omit<EmissionFactorWithGasEmissions, 'gasEmissions'> & {
                                gasEmissions: GasEmissions
                            }
                        }
                        amount={null}
                    />
                </Stack>
            </MainLayoutContainer>
        )
    }

    return (
        <LoadingWrapper loading={contents === undefined} sx={{ height: 1 }}>
            {contents}
        </LoadingWrapper>
    )
}

export default EmissionFactorDetails
