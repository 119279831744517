import { LuneTheme, Media, Text } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import Stack from '@mui/material/Stack'
import { FC, ReactElement, useMemo } from 'react'

import { luneAssetsToDynamicAssets } from 'utils'

export interface CardItemProps {
    imageUrl: string
    title: string
    description: string
    badge?: {
        icon: ReactElement
        label: string
    }
    link?: {
        icon?: ReactElement
        url: string
        label: string
    }
    date?: string
}

const CardItem: FC<CardItemProps> = ({ imageUrl, link, badge, date, description, title }) => {
    const { palette } = LuneTheme

    const resizedImageUrl = useMemo(() => {
        return luneAssetsToDynamicAssets(imageUrl, 720, 480)
    }, [imageUrl])

    return (
        <Box
            component={link ? 'a' : 'div'}
            draggable={false}
            href={link?.url}
            target={link ? '_blank' : undefined}
            sx={{
                width: '360px',
                mr: 5,
                userSelect: 'text',
                '.cart-item__description, .cart-item__title': {
                    cursor: 'text',
                },
                ...(link && {
                    color: `${palette.Grey900} !important`,
                    textDecoration: 'none',
                    '&: hover, &:focus': {
                        outline: 0,
                        textDecoration: 'none',
                        '.cart-item__title': {
                            textDecoration: 'underline',
                        },
                    },
                }),
            }}
        >
            <Stack spacing={3} direction={'column'}>
                <Box sx={{ position: 'relative' }}>
                    <Media
                        type={'image'}
                        src={resizedImageUrl}
                        width={360}
                        height={240}
                        dynamicPlaceholder={{
                            width: 360,
                            height: 240,
                        }}
                        backgroundImage={true}
                        sx={{
                            width: '360px',
                            height: '240px',
                            borderRadius: '32px',
                            overflow: 'hidden',
                        }}
                    />
                    {badge && (
                        <Box
                            sx={{
                                position: 'absolute',
                                left: '24px',
                                top: '24px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                padding: '8px 16px',
                                pl: '12px',
                                backgroundColor: `white`,
                                borderRadius: '40px',
                            }}
                        >
                            {badge.icon}
                            <Text variant={'body3'} sx={{ ml: 1, fontWeight: '500' }}>
                                {badge.label}
                            </Text>
                        </Box>
                    )}
                </Box>
                {link && (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            'img, svg': {
                                maxWidth: '16px',
                                maxHeight: '16px',
                            },
                        }}
                    >
                        {link.icon}
                        <Text
                            variant={'caption'}
                            sx={{
                                ml: link.icon ? 1 : 0,
                                textDecoration: 'underline',
                            }}
                        >
                            {link.label}
                        </Text>
                    </Box>
                )}
                <Text variant={'h6'} className={'cart-item__title'}>
                    {title}
                </Text>
                <Text variant={'body3'} className={'cart-item__description'}>
                    {description}
                </Text>
                {date && <Text variant={'caption'}>{date}</Text>}
            </Stack>
        </Box>
    )
}

export default CardItem
