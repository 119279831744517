import { AccountType } from '@lune-climate/lune'
import { useState } from 'react'

import { bootstrapApi } from 'endpoints/api'
import { bootstrapDapi, getApiKeys } from 'endpoints/dapi'

function populateTestAPIKey(sessionToken: string | null, cookieDomain: string) {
    if (sessionToken !== null) {
        getApiKeys().then((apiKeys) => {
            const testAPIKey = apiKeys.find((apiKey) => apiKey.type === AccountType.TEST)
            if (testAPIKey) {
                // We remove the cookie at logout, so just have a big enough expireTime to prevent
                // the cookie to be removed when browser closes. 1 year seems reasonable.
                const expireTime = new Date(Date.now() + 365 * 24 * 60 * 60 * 1000).toUTCString()
                document.cookie = `docs_test_api_key=${testAPIKey.fullSecret}; Secure; SameSite=Strict${cookieDomain}; Expires=${expireTime}`
                localStorage.setItem('docs_test_api_key', JSON.stringify(testAPIKey))
            }
        })
    }
}

function populateDocsPublishableKey(docsPublishableKey: string | null, cookieDomain: string) {
    if (docsPublishableKey !== null) {
        const expireTime = new Date(Date.now() + 365 * 24 * 60 * 60 * 1000).toUTCString()
        document.cookie = `docs_publishable_key=${encodeURIComponent(
            docsPublishableKey,
        )}; Secure; SameSite=Strict${cookieDomain}; Expires=${expireTime}`
    }
}

export default function useToken(): {
    setToken: (args: { token: string; docsPublishableKey: string } | null) => void
    token: string | null
    isLoggedIn: boolean
} {
    const getToken = (): string | null => {
        return localStorage.getItem('token')
    }

    const [token, setToken] = useState<string | null>(getToken())

    const saveToken = (args: { token: string; docsPublishableKey: string } | null) => {
        const cookieDomain = process.env.REACT_APP_DOCS_COOKIE_DOMAIN
            ? `; domain=${process.env.REACT_APP_DOCS_COOKIE_DOMAIN}`
            : ''

        const [sessionToken, docsPublishableKey] =
            args === null ? [null, null] : [args.token, args.docsPublishableKey]

        if (sessionToken === null) {
            localStorage.removeItem('token')
            // Clean the test api key provided to the docs as well
            document.cookie = `docs_test_api_key=; max-age=-1${cookieDomain}`
            localStorage.removeItem('docs_test_api_key')

            // Expire the cookie
            document.cookie = `docs_publishable_key=; max-age=-1${cookieDomain}`
        } else {
            localStorage.setItem('token', sessionToken)
        }
        bootstrapApi()
        bootstrapDapi()
        // Populate test_api_key local storage to provide it to the docs application if possible
        populateTestAPIKey(sessionToken, cookieDomain)

        populateDocsPublishableKey(docsPublishableKey, cookieDomain)

        setToken(sessionToken)
    }

    return {
        setToken: saveToken,
        token,
        isLoggedIn: token !== null,
    }
}
