import { ButtonGroup } from '@lune-fe/lune-ui-lib'

export enum BundlesView {
    BUNDLES_LIST = 'bundlesList',
    BUNDLES_MAP = 'bundlesMap',
}

const ListMapSwitcher = ({
    view,
    setView,
}: {
    view: string
    setView: (selected: string) => void
}) => {
    return (
        <ButtonGroup
            sx={{ width: '240px' }}
            value={view}
            onChange={(selected) => setView(selected)}
            items={[
                {
                    label: 'Bundles',
                    value: BundlesView.BUNDLES_LIST,
                },
                {
                    label: 'Map',
                    value: BundlesView.BUNDLES_MAP,
                },
            ]}
        />
    )
}

export default ListMapSwitcher
