import { IConfigCatClient } from 'configcat-common'
// This is commented out as we don't need to use feature flags for the time being
// To re-enable, uncomment the lines below and use together with useFlags hook
import { createConsoleLogger, getClient, PollingMode } from 'configcat-js'

// This is our Feature Flag SaaS configuration

const CONFIG_CAT_API_KEY = 'DC3aCP3bz0SJ8PkIwuc6uA/8BtubNdwGkm2u0vDp0nj3g'
const options =
    process.env.NODE_ENV === 'development'
        ? {
              logger: createConsoleLogger(3),
          }
        : {}

export let configCatClient: IConfigCatClient | undefined

try {
    configCatClient = getClient(CONFIG_CAT_API_KEY, PollingMode.ManualPoll, options)
} catch {
    console.error(`Failed to create ConfigCat client.`)
}
