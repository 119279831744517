import type { SxProps } from '@mui/material'
import { styled } from '@mui/system'

import { LuneTheme } from '../theme'

import Loading from './Loading'

const LoadingWrapper = ({
    loading,
    children,
    className,
    bigLoader,
    light,
    sx,
    ...rest
}: {
    loading?: boolean
    children: React.ReactNode
    className?: string
    bigLoader?: boolean
    light?: boolean
    sx?: SxProps
}) => {
    const spacing = LuneTheme.spacing
    const LoaderWrapper = styled('div')(
        LuneTheme.unstable_sx({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }),
    )

    const StyledLoading = styled(Loading)(
        LuneTheme.unstable_sx({
            height: bigLoader ? spacing(8) : spacing(4),
            width: bigLoader ? spacing(8) : spacing(4),
        }),
    )

    return loading ? (
        <LoaderWrapper className={className} {...rest} sx={sx}>
            <StyledLoading light={light} />
        </LoaderWrapper>
    ) : (
        <>{children}</>
    )
}

export default LoadingWrapper
