import { CtaTile, LuneTheme, MainLayoutContainer, Text, ToggleTabs } from '@lune-fe/lune-ui-lib'
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined'
import { Box } from '@mui/material'
import Stack from '@mui/material/Stack'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import ConditionalTooltip from 'components/ConditionalTooltip'
import NotAdminTooltip from 'components/NotAdminTooltip'
import useHasUnsavedChanges from 'hooks/useHasUnsavedChanges'
import useIsTestMode from 'hooks/useIsTestMode'
import useMixpanel from 'hooks/useMixpanel'
import { useIsAuthorised } from 'hooks/useUserState'
import DocumentationImage from 'images/DocumentationImage.png'
import ApiKeyImage from 'images/NewApiKeyCtaImage.png'
import WebhookImage from 'images/NewWebhookCtaImage.png'
import ApiKeys from 'views/Developers/APIKeys/ApiKeysTable'
import WebhooksTable from 'views/Developers/Webhooks/WebhooksTable'

const Developers = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const isAdmin = useIsAuthorised()
    const isTestMode = useIsTestMode()
    const mixpanel = useMixpanel()
    const { palette } = LuneTheme
    const { hasUnsavedChanges, setHasUnsavedChangesState } = useHasUnsavedChanges()

    useEffect(() => {
        if (hasUnsavedChanges) {
            setHasUnsavedChangesState(false)
        }
    }, [hasUnsavedChanges, setHasUnsavedChangesState])

    return (
        <MainLayoutContainer headerComponent={<Text variant={'h4'}>Developers</Text>}>
            <Box
                sx={{
                    mb: 9,
                }}
            >
                <Stack spacing={4}>
                    <Box data-testid={`new-api-key-btn`}>
                        <ConditionalTooltip show={!isAdmin} tooltip={NotAdminTooltip}>
                            <CtaTile
                                title={`New ${isTestMode ? 'Test' : 'Live'} API Key`}
                                image={ApiKeyImage}
                                onClick={() => {
                                    mixpanel.track('api_key_clicked')
                                    navigate(`/developers/new-api-key`)
                                }}
                                disabled={!isAdmin}
                            />
                        </ConditionalTooltip>
                    </Box>
                    <Box data-testid={`new-webhook-btn`}>
                        <ConditionalTooltip show={!isAdmin} tooltip={NotAdminTooltip}>
                            <CtaTile
                                title={`New ${isTestMode ? 'Test' : 'Live'} Webhook`}
                                image={WebhookImage}
                                onClick={() => {
                                    mixpanel.track('webhook_clicked')
                                    navigate(`/developers/new-webhook`)
                                }}
                                disabled={!isAdmin}
                            />
                        </ConditionalTooltip>
                    </Box>
                    <Box data-testid={`docs-btn`}>
                        <CtaTile
                            title={'Documentation'}
                            image={DocumentationImage}
                            icon={<OpenInNewOutlinedIcon sx={{ color: palette.Grey900 }} />}
                            href="https://docs.lune.co"
                            /* @ts-ignore: ... */
                            target="_blank"
                        />
                    </Box>
                </Stack>
            </Box>
            <ToggleTabs
                defaultActive={location.hash.slice(1)}
                onChange={(selected) =>
                    navigate({ pathname: location.pathname, hash: selected?.value })
                }
            >
                <ToggleTabs.Tab label={'API keys'} value={'apiKeys'}>
                    <ApiKeys />
                </ToggleTabs.Tab>
                <ToggleTabs.Tab label={'Webhooks'} value={'webhooks'}>
                    <WebhooksTable />
                </ToggleTabs.Tab>
            </ToggleTabs>
        </MainLayoutContainer>
    )
}

export default Developers
