import nativeMixpanel from 'mixpanel-browser'
import { hotjar } from 'react-hotjar'
import LinkedInTag from 'react-linkedin-insight'
import { Userpilot } from 'userpilot'

import { IUserState } from '../UserState'

export function initialiseAnalyticTools(userState?: IUserState) {
    const userPilotToken = process.env.REACT_APP_USERPILOT_TOKEN
    if (userPilotToken) {
        Userpilot.initialize(userPilotToken)
    }

    if (userState) {
        const partnerId = process.env.REACT_APP_LINKEDIN_PARTNER_ID
        if (partnerId) {
            LinkedInTag.init(partnerId)
        }

        const { id, email, firstname, lastname } = userState.user

        const hotjarId = process.env.REACT_APP_HOTJAR_ID
        if (hotjarId) {
            if (!email.endsWith('@lune.co')) {
                hotjar.initialize({ id: parseInt(hotjarId, 10), sv: 6 }) // version 6
                if (hotjar.initialized()) {
                    hotjar.identify(id, { email })
                }
            }
        }
        nativeMixpanel.identify(id)

        Userpilot.identify(id, {
            name: `${firstname ?? ''} ${lastname ?? ''}`.trim(),
            email,
            created_at: '2018-07-11', // we don't have it
        })
    }
}
