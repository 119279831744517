import type { SxProps } from '@mui/material'
import Box from '@mui/material/Box'
import MUICard from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import { styled } from '@mui/material/styles'
import React, { FC, MouseEventHandler, useMemo } from 'react'

import { LuneTheme } from '../theme'

import Media from './Media'

export interface CardProps {
    children?: React.ReactNode
    sx?: SxProps
    backgroundImage: string
    gradientColor: string
    onClick?: MouseEventHandler<HTMLElement>
    compact?: boolean
}

const CardContainer: FC<CardProps> = ({
    children,
    sx,
    backgroundImage,
    gradientColor,
    onClick,
    compact,
}) => {
    const { spacing } = LuneTheme
    const gradient = `linear-gradient(0deg, ${gradientColor} 0%, ${gradientColor} 50%, transparent 100%)`

    const StyledCard = useMemo(
        () =>
            styled(MUICard)(
                LuneTheme.unstable_sx({
                    backgroundColor: 'transparent',
                    borderRadius: spacing(4),
                    zIndex: 1,
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    boxShadow: 'none',
                    ':before': {
                        content: "''",
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        left: 0,
                        background: gradient,
                    },
                }),
            ),
        [],
    )

    const sharedStyled = {
        padding: compact ? '20px' : spacing(3),
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        boxSizing: 'border-box',
        minHeight: 'fit-content',
        position: 'relative',
    }

    const StyledCardClickableWrapper = useMemo(
        () =>
            styled(CardActionArea)(
                LuneTheme.unstable_sx({
                    ...sharedStyled,
                }),
            ),
        [],
    )

    const StyledCaredWrapper = useMemo(
        () =>
            styled(Box)(
                LuneTheme.unstable_sx({
                    ...sharedStyled,
                }),
            ),
        [],
    )

    const BackgroundImage = useMemo(() => {
        return (
            <Media
                sx={{
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    margin: 'auto',
                    zIndex: -1,
                    '> img': {
                        objectFit: 'cover',
                        height: '100% !important',
                    },
                }}
                src={backgroundImage}
                type={'image'}
                height={1000}
                dynamicPlaceholder={{
                    width: 400,
                    height: 800,
                }}
            />
        )
    }, [backgroundImage])

    return (
        <StyledCard sx={sx}>
            {onClick ? (
                <StyledCardClickableWrapper onClick={onClick}>
                    {BackgroundImage}
                    {children}
                </StyledCardClickableWrapper>
            ) : (
                <StyledCaredWrapper>
                    {BackgroundImage}
                    {children}
                </StyledCaredWrapper>
            )}
        </StyledCard>
    )
}
export default CardContainer
