import Box from '@mui/material/Box'
import { styled, useTheme } from '@mui/material/styles'
import MUITab, { TabProps } from '@mui/material/Tab'
import MUITabs, { TabsProps } from '@mui/material/Tabs'
import React, { FC, useEffect, useMemo, useState } from 'react'

import { LuneTheme } from '../theme'

const Tab: FC<TabProps> = (props) => {
    const { palette, typography } = useTheme()
    const StyledTab = useMemo(() => {
        return styled(MUITab)(
            LuneTheme.unstable_sx({
                ...typography.button,
                padding: '8px 12px 8px 12px',
                minWidth: 'unset',
                color: palette.Grey500,
                '&.Mui-selected': {
                    color: palette.Grey900,
                },
            }),
        )
    }, [])

    return <StyledTab {...props} />
}

const StyledTabs = styled((props: TabsProps) => (
    <MUITabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))(({ theme }) => ({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        width: 'calc(100% - 24px)', // 100% minus double padding: to fit the label
        height: '1px',
        backgroundColor: theme.palette.Grey900,
    },
    '.MuiTabs-flexContainer': {
        width: 'max-content !important',
    },
}))

function Tabs(props: TabsProps) {
    const { value: defaultValue, children, onChange } = props
    const [value, setValue] = useState<any>(defaultValue ?? 0)
    const handleChange = (_event: React.SyntheticEvent, newValue: any) => {
        if (onChange) {
            onChange(newValue, value)
        }
        setValue(newValue)
    }

    const [showShadows, setShowShadows] = useState<boolean>(false)
    const [showStartShadow, setShowStartShadow] = useState<boolean>(false)
    const [showEndShadow, setShowEndShadow] = useState<boolean>(false)

    const tabsContainer: React.RefObject<HTMLDivElement> = React.useRef(null)

    useEffect(() => {
        if (!tabsContainer.current) {
            return
        }
        const tabsContainerElement = tabsContainer.current
        // The arrays may actually be empty, presumably
        const scrollableElement = tabsContainerElement.getElementsByClassName(
            'MuiTabs-scroller',
        )[0] as Element | undefined
        const content = tabsContainerElement.getElementsByClassName('MuiTabs-flexContainer')[0] as
            | Element
            | undefined

        const handleScroll = () => {
            if (scrollableElement && content) {
                setShowShadows(content.clientWidth > scrollableElement.clientWidth)
                // 12 -> button's padding
                setShowStartShadow(scrollableElement.scrollLeft > 12)
                setShowEndShadow(
                    scrollableElement.scrollLeft + scrollableElement.clientWidth + 12 <
                        content.clientWidth,
                )
            }
        }

        if (scrollableElement && content) {
            scrollableElement.addEventListener('scroll', handleScroll)
        }

        function handleResize() {
            handleScroll()
        }

        window.addEventListener('resize', handleResize)

        handleResize()
        handleScroll()

        return () => {
            if (scrollableElement) {
                scrollableElement.removeEventListener('scroll', handleScroll)
            }
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return (
        <Box
            ref={tabsContainer}
            sx={{
                position: 'relative',
                ...(showShadows &&
                    showStartShadow && {
                        maskImage: 'linear-gradient(to right, #0000 0%, #000 20%, #000 100%)',
                    }),
                ...(showShadows &&
                    showEndShadow && {
                        maskImage: 'linear-gradient(to left,#0000 0%, #000 20%, #000 100%)',
                    }),
                ...(showShadows &&
                    showEndShadow &&
                    showStartShadow && {
                        maskImage:
                            'linear-gradient(to left,#0000 0%, #000 20%, #000 80%, #0000 100%)',
                    }),

                '.MuiTab-root:hover': {
                    zIndex: 9,
                },
            }}
        >
            <StyledTabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons={false}
                sx={props.sx}
            >
                {children}
            </StyledTabs>
        </Box>
    )
}

Tabs.Tab = Tab
export default Tabs
