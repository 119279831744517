import { LuneTheme, Text } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'

interface CarbonCreditsListItemProps {
    title: string
    description: string
    Icon: any
}

const CarbonCreditsListItem = ({ Icon, title, description }: CarbonCreditsListItemProps) => {
    const { palette, spacing } = LuneTheme

    return (
        <Box
            sx={{
                display: `flex`,
                flexDirection: `column`,
                alignItems: `center`,
                justifyContent: `center`,
                flex: 1,
                height: `144px`,
                background: palette.Grey50,
                borderRadius: `16px`,
                p: 2,
            }}
        >
            <Icon sx={{ height: spacing(4), width: spacing(4), mb: 2, color: palette.Grey900 }} />
            <Text variant={`button`} sx={{ color: palette.Grey900, mb: 1, textAlign: `center` }}>
                {title}
            </Text>
            <Text
                component={`pre`}
                sx={{
                    textAlign: `center`,
                    color: palette.Grey900,
                }}
                variant={`caption`}
            >
                {description}
            </Text>
        </Box>
    )
}

export default CarbonCreditsListItem
