import { IconButtonDropdown } from '@lune-fe/lune-ui-lib'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import KeyOffIcon from '@mui/icons-material/KeyOffOutlined'
import KeyIcon from '@mui/icons-material/KeyOutlined'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined'
import { useSnackbar } from 'notistack'
import { FC, useState } from 'react'

import ContextMenuItem from 'components/ContextMenuItem'
import { deleteApiKey, rotateApiKeys, updateApiKeyStatus } from 'endpoints/dapi'
import useApiKeys from 'hooks/useApiKeys'
import { useIsAuthorised } from 'hooks/useUserState'
import { ApiKeyStatus } from 'models/apiKey'
import { SnackbarMessages } from 'SnackbarMessages'
import { refreshDocsTestApiKeyIfPossible } from 'utils'
import SecretTokenModal from 'views/Developers/SecretTokenModal'

const ApiKeyContextMenu: FC<{ id: string; status: ApiKeyStatus }> = ({ id, status }) => {
    const { refetch } = useApiKeys()
    const { enqueueSnackbar: snackbar } = useSnackbar()
    const [secretToken, setSecretToken] = useState<string>()
    const isAdmin = useIsAuthorised()

    return (
        <>
            <IconButtonDropdown icon={<MoreVertOutlinedIcon />}>
                <ContextMenuItem
                    icon={<AutorenewIcon />}
                    title={`Rotate`}
                    onClick={() => {
                        rotateApiKeys(id)
                            .then((apiKeyInfo) => {
                                setSecretToken(apiKeyInfo.secret)
                                refreshDocsTestApiKeyIfPossible(id, apiKeyInfo.apiKey)
                            })
                            .catch(() => snackbar(SnackbarMessages.API_KEY_ROTATE_FAIL))
                    }}
                    disabled={!isAdmin}
                />
                {status === ApiKeyStatus.ACTIVE ? (
                    <ContextMenuItem
                        icon={<KeyOffIcon />}
                        title={`Disable`}
                        onClick={() =>
                            updateApiKeyStatus(id, ApiKeyStatus.DISABLED)
                                .then(() => {
                                    snackbar(SnackbarMessages.API_KEY_UPDATE_SUCCESS)
                                    return refetch()
                                })
                                .catch(() => snackbar(SnackbarMessages.API_KEY_UPDATE_FAIL))
                        }
                        disabled={!isAdmin}
                    />
                ) : (
                    <ContextMenuItem
                        data-testid={`api-key-enable`}
                        icon={<KeyIcon />}
                        title={`Enable`}
                        onClick={() =>
                            updateApiKeyStatus(id, ApiKeyStatus.ACTIVE)
                                .then(() => {
                                    snackbar(SnackbarMessages.API_KEY_UPDATE_SUCCESS)
                                    return refetch()
                                })
                                .catch(() => snackbar(SnackbarMessages.API_KEY_UPDATE_FAIL))
                        }
                        disabled={!isAdmin}
                    />
                )}
                <ContextMenuItem
                    icon={<DeleteIcon />}
                    title={`Delete`}
                    onClick={() =>
                        deleteApiKey(id)
                            .then(() => {
                                snackbar(SnackbarMessages.API_KEY_DELETE_SUCCESS)
                                return refetch()
                            })
                            .catch(() => {
                                snackbar(SnackbarMessages.API_KEY_DELETE_FAIL)
                            })
                    }
                    disabled={!isAdmin}
                />
            </IconButtonDropdown>
            {secretToken && (
                <SecretTokenModal
                    secretToken={secretToken}
                    onClose={() => {
                        setSecretToken(undefined)
                        return refetch()
                    }}
                />
            )}
        </>
    )
}

export default ApiKeyContextMenu
