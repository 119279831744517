import { useMemo } from 'react'

import useActiveOrganisation from 'hooks/useActiveOrganisation'

export default function useIsBillingReady(): {
    isBillingReady: boolean
    isMissingBillingInfo: boolean
    isMissingPaymentInfo: boolean
} {
    const { activeOrg } = useActiveOrganisation()

    const isMissingBillingInfo = useMemo(() => {
        if (!activeOrg) {
            return false
        }
        const { billingName, billingEmail, countryCode } = activeOrg
        return !billingEmail || !billingName || !countryCode
    }, [activeOrg])

    const isMissingPaymentInfo = activeOrg && !activeOrg.defaultPaymentMethod

    return {
        isBillingReady: isMissingBillingInfo === false && isMissingPaymentInfo === false,
        isMissingBillingInfo: !!isMissingBillingInfo,
        isMissingPaymentInfo: !!isMissingPaymentInfo,
    }
}
