import { CompanyEmissionEstimate } from '@lune-climate/lune'
import { CalculationResults, formatNumbers } from '@lune-fe/lune-components-lib'
import { ButtonGroup, HorizontalBarChart } from '@lune-fe/lune-ui-lib'
import { Stack } from '@mui/material'
import { useSnackbar } from 'notistack'
import { FC, RefObject, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { dontHandle, useLuneClient } from 'hooks/useLuneClient'
import { SnackbarMessages } from 'SnackbarMessages'
import { pluralize } from 'utils'
import { EstimateTypeEnum, EstimateTypeName, ResultsWrapper } from 'views/CalculateEmissions'
import CompanyCarbonFootprintResultsBreakdown from 'views/CalculateEmissions/CompanyCarbonFootprint/CompanyCarbonFootprintResultsBreakdown'

export enum ChartType {
    BY_SCOPE = 'by_scope',
    BY_CATEGORY = 'by_category',
}

type ChartDate = {
    label: string
    percentage: number
    subLabel?: string
    color?: string
}

export const Categories = {
    travelAndCommute: {
        name: 'Travel & commute',
        inputs: [
            'companyCars',
            'averageCarDistanceTravelled',
            'employeesUsingPublicTransport',
            'longFlights',
            'mediumFlights',
            'shortFlights',
            'firstOrBusinessClassPercentage',
            'employeesUsingPrivateTransport',
        ],
    },
    energy: {
        name: 'Electricity & heating',
        inputs: ['electricityConsumption', 'greenElectricityUsed', 'gasConsumption'],
    },
    remoteEmployee: {
        name: 'Remote work',
        inputs: ['employees', 'remoteEmployeesPercentage'],
    },
    materialAndWaste: {
        name: 'Devices & waste',
        inputs: ['electronicDeviceExpenses', 'garbage', 'recycledGarbagePercentage'],
    },
    foodAndDrink: {
        name: 'Food & drinks',
        inputs: ['foodAndDrinksExpenses', 'vegetarianAndVeganPercentage'],
    },
    tech: {
        name: 'Tech infrastructure',
        inputs: ['tech.onPremise', 'tech.cloud'],
    },
}

export const Scopes = {
    scope1: {
        name: 'Scope 1',
        inputs: ['companyCars', 'averageCarDistanceTravelled', 'gasConsumption'],
    },
    scope2: {
        name: 'Scope 2',
        inputs: ['electricityConsumption', 'greenElectricityUsed', 'tech.onPremise'],
    },
    scope3: {
        name: 'Scope 3',
        inputs: [
            'employees',
            'remoteEmployeesPercentage',
            'employeesUsingPublicTransport',
            'longFlights',
            'mediumFlights',
            'shortFlights',
            'firstOrBusinessClassPercentage',
            'foodAndDrinksExpenses',
            'vegetarianAndVeganPercentage',
            'electronicDeviceExpenses',
            'garbage',
            'recycledGarbagePercentage',
            'tech.cloud',
        ],
    },
}

const toPercentage = (totalAmount: string, amount: string) => {
    return (Number(amount) * 100) / Number(totalAmount)
}

// eslint-disable-next-line no-undef
const CompanyCarbonFootprintResults: FC<{ scrollRef: RefObject<HTMLDivElement> }> = ({
    scrollRef,
}) => {
    const { enqueueSnackbar: snackbar } = useSnackbar()
    const navigate = useNavigate()
    const { id } = useParams<{ id: string }>()
    const [chartType, setChartType] = useState<string>(ChartType.BY_SCOPE)
    const [chartData, setChartData] = useState<ChartDate[]>([])
    const [chartDataByCategory, setChartDataByCategory] = useState<ChartDate[]>([])
    const [chartDataByScope, setChartDataByScope] = useState<ChartDate[]>([])
    const [estimates, setEstimates] = useState<CompanyEmissionEstimate>()
    const luneClient = useLuneClient()

    const toChartData = (subLabel: string, amount: string, totalAmount: string) => {
        const percentage = toPercentage(totalAmount, amount)
        return {
            label: `${formatNumbers(amount, 2)} tCO₂ (${
                percentage > 0 && percentage < 1 ? `<1` : `${Math.round(percentage)}`
            }%)`,
            subLabel,
            percentage,
        }
    }

    useEffect(() => {
        if (id) {
            luneClient
                .getCompanyEstimate(id, undefined, dontHandle([404]))
                .then((res) => {
                    if (res.isOk()) {
                        const e = res.value
                        setEstimates(e)
                    } else if ('statusCode' in res.error && res.error.statusCode === 404) {
                        navigate('/404')
                    }
                })
                .catch(() => snackbar(SnackbarMessages.GENERIC_ERROR))
        }
    }, [id, snackbar, navigate, luneClient])

    useEffect(() => {
        if (estimates) {
            const categoryData: ChartDate[] = Object.entries(estimates.components).map(
                ([key, value]) => {
                    return toChartData(
                        Categories[key as keyof typeof Categories].name,
                        value.amount,
                        estimates.mass.amount,
                    )
                },
            )
            const scopeData: ChartDate[] = Object.entries(Scopes).map(([key, value]) => {
                const scopeValue = estimates[key as keyof typeof Scopes]
                return toChartData(value.name, scopeValue.amount, estimates.mass.amount)
            })
            setChartDataByCategory(categoryData)
            setChartDataByScope(scopeData)
        }
    }, [estimates])

    useEffect(() => {
        setChartData(chartType === ChartType.BY_SCOPE ? chartDataByScope : chartDataByCategory)
    }, [chartType, chartDataByScope, chartDataByCategory])

    return (
        <ResultsWrapper
            loading={!estimates}
            scrollRef={scrollRef}
            calculatedAmount={estimates?.mass.amount!}
        >
            {estimates && (
                <>
                    <CalculationResults
                        title={estimates.request.name || EstimateTypeName(EstimateTypeEnum.COMPANY)}
                        amount={estimates.mass.amount || ''}
                        subtitle={`${estimates.request.months || 12} ${pluralize(
                            'month',
                            estimates.request.months || 12,
                        )}`}
                    />
                    <Stack direction={'column'} spacing={9}>
                        <ButtonGroup
                            items={[
                                {
                                    label: 'By scope',
                                    value: ChartType.BY_SCOPE,
                                },
                                {
                                    label: 'By category',
                                    value: ChartType.BY_CATEGORY,
                                },
                            ]}
                            onChange={(e) => {
                                // workaround to rerender chart animations
                                if (e !== chartType) {
                                    setChartData([])
                                    setChartType(e)
                                }
                            }}
                            value={chartType}
                        />
                        <HorizontalBarChart items={chartData} />
                        <CompanyCarbonFootprintResultsBreakdown
                            chartType={chartType}
                            estimation={estimates}
                        />
                    </Stack>
                </>
            )}
        </ResultsWrapper>
    )
}

export default CompanyCarbonFootprintResults
