import { AccountType } from 'models/account'

export enum ApiKeyStatus {
    ACTIVE = 'active',
    DISABLED = 'disabled',
}

export interface IApiKey {
    id: string
    accountId: string
    accountName: string
    accountType: AccountType
    name: string
    key: string
    type: AccountType
    status: ApiKeyStatus
    createdAt: string
    maskedSecret: string
    fullSecret: string
}

export interface IApiKeyWithSecret {
    secret: string
    apiKey: IApiKey
}
