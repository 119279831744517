import { Account } from '@lune-climate/lune'
import { useQuery } from 'react-query'

import { useLuneClient } from 'hooks/useLuneClient'
import useToken from 'hooks/useToken'
import { queryKeys } from 'queryKeys'

const getAccount = (luneClient: ReturnType<typeof useLuneClient>) =>
    luneClient.getAccount().then((r) => r.unwrap())

const useActiveAccount = (): {
    activeAccount: Account | undefined
    isLoadingActiveAccount: boolean
    refetchActiveAccount: () => void
} => {
    const { isLoggedIn } = useToken()
    const luneClient = useLuneClient()

    const {
        isLoading: isLoadingActiveAccount,
        data,
        refetch: refetchActiveAccount,
    } = useQuery<Account>(queryKeys.GET_ACTIVE_ACCOUNT, () => getAccount(luneClient), {
        enabled: isLoggedIn,
    })

    return isLoggedIn
        ? {
              activeAccount: data,
              refetchActiveAccount,
              isLoadingActiveAccount,
          }
        : {
              activeAccount: undefined,
              refetchActiveAccount,
              isLoadingActiveAccount: true,
          }
}

export default useActiveAccount
