import { LoadingWrapper, MainLayoutContainer, Text } from '@lune-fe/lune-ui-lib'
import StyleOutlinedIcon from '@mui/icons-material/StyleOutlined'
import { Stack } from '@mui/material'
import { RefObject } from 'react'
import { useNavigate } from 'react-router-dom'

import useBundles from 'hooks/useBundles'
import useBundlesPortfolios from 'hooks/useBundlesPortfolios'
import useBuyOffsetsState from 'hooks/useBuyOffsetsState'
import useMountEffect from 'hooks/useMountEffect'
import BuyPredefinedPortfolio from 'views/BuyOffsets/BuyPredefinedPortfolio'
import CreateProposalRequest from 'views/BuyOffsets/CreateProposalRequest'
import PortfolioButton from 'views/BuyOffsets/PortfolioButton'

const BuyOffsets = ({ scrollRef }: { scrollRef: RefObject<HTMLDivElement> }) => {
    const navigate = useNavigate()
    const { loading } = useBundles()

    const { buyOffsetProps, resetBuyOffsetProps } = useBuyOffsetsState()
    const { selectedPortfolioId } = buyOffsetProps
    const { bundlesPortfolios: predefinedPortfolios, loading: loadingPredefinedPortfolios } =
        useBundlesPortfolios()

    // Buy offsets flow should always start with a clear empty state
    // We want to save the buyOffsetProps state ONLY when navigating inside the buy offsets flow
    // for example: navigating to checkout and returning to the previous page
    // selectedPortfolioId is set when navigating to checkout and coming back to previous the state
    // we can use that prop to define when to keep the state and when to discard everything
    useMountEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTo({ top: 0 })
        }
        if (!selectedPortfolioId) {
            resetBuyOffsetProps()
        }
    })

    return (
        <LoadingWrapper
            loading={loading || loadingPredefinedPortfolios}
            sx={{ minHeight: '100vh' }}
        >
            <MainLayoutContainer
                headerComponent={
                    <Stack spacing={1}>
                        <Text variant="h4">Fund Climate Projects</Text>
                        <Text variant="body3" sx={{ color: `Grey700`, display: 'block' }}>
                            Contribute to a pre-built portfolio of carbon projects or easily select
                            your preferred projects.
                        </Text>
                    </Stack>
                }
            >
                <BuyPredefinedPortfolio predefinedPortfolios={predefinedPortfolios} />
                <PortfolioButton
                    dataTestId={'custom-portfolio-btn'}
                    icon={<StyleOutlinedIcon />}
                    title={'Buy one or more project bundles'}
                    subtitle={'The simplest way to buy carbon with confidence'}
                    onClick={() => navigate('/order/build-portfolio')}
                    custom
                />
                <CreateProposalRequest />
            </MainLayoutContainer>
        </LoadingWrapper>
    )
}

export default BuyOffsets
