import { useQuery, useQueryClient } from 'react-query'

import { Organisation, OrganisationUsers } from 'models/openDapi'

import { getOrganisation, getOrganisationUsers } from '../endpoints/dapi'
import { queryKeys } from '../queryKeys'

import useAccounts from './useAccounts'

const useActiveOrganisation = () => {
    const reactQueryClient = useQueryClient()
    const { activeAccount } = useAccounts()

    const { isLoading: loadingOrg, data: activeOrg } = useQuery<Organisation>(
        queryKeys.GET_ORGANISATION,
        () => {
            return getOrganisation(activeAccount ? activeAccount.organisationId : '')
        },
        {
            enabled: !!activeAccount,
        },
    )

    const { isLoading: loadingOrgUsers, data: organisationUsers } = useQuery<OrganisationUsers>(
        queryKeys.GET_ORGANISATION_USERS,
        () => getOrganisationUsers(activeAccount?.organisationId || ``),
        {
            enabled: !!activeAccount,
        },
    )

    const activeOrgUsers = organisationUsers?.users
    const activeOrgInvites = organisationUsers?.invites

    return {
        loadingActiveOrg: loadingOrg && loadingOrgUsers,
        refetchActiveOrg: () => {
            reactQueryClient.invalidateQueries(queryKeys.GET_ORGANISATION)
            reactQueryClient.invalidateQueries(queryKeys.GET_ORGANISATION_USERS)
        },
        activeOrg,
        activeOrgUsers,
        activeOrgInvites,
    }
}

export default useActiveOrganisation
