import {
    BackButton,
    CtaTile,
    MainLayoutContainer,
    ProgressSteps,
    ProgressStepsState,
    Text,
} from '@lune-fe/lune-ui-lib'
import { Stack } from '@mui/material'
import { FC } from 'react'
import { useQueryClient } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'

import Divider from 'components/Divider'
import { setUserPrimaryAccountId } from 'endpoints/dapi'
import useActiveAccount from 'hooks/useActiveAccount'
import useIsTestMode from 'hooks/useIsTestMode'
import browseBundlesImage from 'images/browse-bundles.png'
import testOrderImage from 'images/test-order.png'
import { ProposalResponse } from 'models/openDapi'
import { queryKeys } from 'queryKeys'

const PendingProposal: FC<{ proposal: ProposalResponse }> = ({ proposal }) => {
    const navigate = useNavigate()
    const isTestMode = useIsTestMode()
    const activeAccount = useActiveAccount()
    const queryClient = useQueryClient()
    const { status } = proposal
    const location = useLocation()
    const showBackButton = location.state?.fromProposalsList

    const switchToTestModeAndNavigate = () => {
        const newPrimaryAccountId = activeAccount.activeAccount?.siblingId
        setUserPrimaryAccountId(newPrimaryAccountId!).then(async () => {
            await queryClient.invalidateQueries({
                predicate: (query) =>
                    query.queryKey !== queryKeys.GET_ACTIVE_ACCOUNT_FOR_TEST_SWITCH,
            })
            navigate(`/order`)
        })
    }

    return (
        <MainLayoutContainer
            headerComponent={
                <>
                    {showBackButton && (
                        <BackButton sx={{ mb: 3, ml: -2 }} onClick={() => navigate(-1)} />
                    )}
                    <Text variant={'h4'}>
                        We’re reviewing your input, and we’ll share a proposal in a few hours.
                    </Text>
                </>
            }
        >
            <Stack spacing={9}>
                <Text variant={'h6'}>Our process</Text>
                <ProgressSteps
                    steps={[
                        {
                            label: 'Select the best projects, fitting your requirements',
                            state: ProgressStepsState.COMPLETED,
                        },
                        {
                            label: 'Check credits availability',
                            state: ProgressStepsState.COMPLETED,
                        },
                        {
                            label: 'Design portfolios that fit your budget',
                            state:
                                status === 'pending'
                                    ? ProgressStepsState.ACTIVE
                                    : ProgressStepsState.COMPLETED,
                        },
                        {
                            label: 'Share our proposal with you',
                            state:
                                status === 'ready'
                                    ? ProgressStepsState.ACTIVE
                                    : ProgressStepsState.PENDING,
                        },
                    ]}
                />
                <Divider />
                <Stack spacing={4}>
                    <Text variant={'h6'}>In the meantime</Text>
                    <CtaTile
                        title={'Explore our project bundles'}
                        image={browseBundlesImage}
                        onClick={() => navigate(`/`)}
                    />
                    <CtaTile
                        title={'Try making a test order'}
                        image={testOrderImage}
                        onClick={() => {
                            if (isTestMode) {
                                navigate(`/order`)
                            } else {
                                switchToTestModeAndNavigate()
                            }
                        }}
                    />
                </Stack>
            </Stack>
        </MainLayoutContainer>
    )
}

export default PendingProposal
