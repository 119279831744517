import { SingleShippingEmissionEstimate } from '@lune-climate/lune'
import { Text } from '@lune-fe/lune-ui-lib'
import LogoutIcon from '@mui/icons-material/Logout'
import { Stack } from '@mui/material'

import { ResultLabeledSectionAndTiles } from './emissionsCalculationExplanation'
import RequestResponseSnippets, { RequestMeta } from './RequestResponseSnippets'

interface RequestAndResponseProps<Req, Res> {
    path: string
    request: Req
    response: Res
    idempotencyKey?: string
    metadata?: Record<string, string>
    source?: SingleShippingEmissionEstimate['source']
    isTestMode?: boolean
    testApiKey?: string
}

function toDisplaySource(source?: SingleShippingEmissionEstimate['source']) {
    if (source) {
        const mapping: Record<
            Exclude<SingleShippingEmissionEstimate['source'], undefined>,
            string
        > = {
            api: 'Lune API',
            csv: 'Lune CSV',
            dashboard: 'Lune dashboard',
            ecotransit_csv: 'EcoTransIT CSV',
            widget: 'Lune widget',
            chain_io: 'Chain.io',
            ecotransit: 'EcoTransIT',
        }
        const tiles = [{ label: `Source: ${mapping[source]}`, icon: <LogoutIcon /> }]
        return <ResultLabeledSectionAndTiles tiles={tiles}></ResultLabeledSectionAndTiles>
    } else {
        return <></>
    }
}

export function RequestAndResponse<Req, Res>({
    path,
    request,
    response,
    idempotencyKey,
    metadata,
    source,
    isTestMode,
    testApiKey,
}: RequestAndResponseProps<Req, Res>) {
    return (
        <Stack spacing={4} direction={'column'}>
            <Text variant={'h6'}>{`Request & response`}</Text>
            <RequestMeta idempotencyKey={idempotencyKey} metadata={metadata} />
            {toDisplaySource(source)}
            <RequestResponseSnippets
                request={`curl ${process.env.REACT_APP_API_URL}${path} \\
  -X POST \\
  -H "Content-Type: application/json" \\
  -H "Authorization: Bearer $API_KEY" \\
  -d '${JSON.stringify(request, null, 2)}'`}
                response={JSON.stringify(response, null, 2)}
                isTestMode={isTestMode}
                testApiKey={testApiKey}
            />
        </Stack>
    )
}

export default RequestAndResponse
