import { IdentifiedVesselShippingMethod, ShippingMethod } from '@lune-climate/lune'
import { Input } from '@lune-fe/lune-ui-lib'
import { useCallback } from 'react'

const IdentifiedVesselShippingComp = ({
    value,
    error,
    onChange,
}: {
    onChange: (v: ShippingMethod) => void
    value: IdentifiedVesselShippingMethod
    error?: { key: string; message: string }
}) => {
    const onInputChange = useCallback(
        (e: { target: { value: any } }) => {
            onChange({
                ...value,
                ...('vesselImoNumber' in value ? { vesselImoNumber: e.target.value } : {}),
                ...('vesselName' in value ? { vesselName: e.target.value } : {}),
            })
            // Work around FinalForm re-rendering losing focus on this field
            setTimeout(() => document.getElementById('vesselImoNumber')?.focus(), 50)
        },
        [onChange, value],
    )

    return (
        <Input
            id={`vesselImoNumber`}
            startAdornment={'vesselImoNumber' in value ? 'IMO' : 'Name'}
            value={'vesselImoNumber' in value ? value.vesselImoNumber : value.vesselName}
            error={
                error?.key === 'ERROR_IMO_NUMBER' || error?.key === 'required' ? error.message : ''
            }
            onChange={onInputChange}
        />
    )
}

export default IdentifiedVesselShippingComp
