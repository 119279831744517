import { Text } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'
import { FC } from 'react'

import { chartColors } from 'views/Projects/Project/CarbonStorageOverTime/ChartConfig'

const LineLegend: FC<{ color: string; label: string }> = ({ color, label }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                mr: 2,
            }}
        >
            <Box sx={{ width: '20px', height: '2px', backgroundColor: color, mr: '12px' }} />
            <Text variant={'body3'}>{label}</Text>
        </Box>
    )
}

const RoundLegend: FC<{ color: string; label: string }> = ({ color, label }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                mr: 2,
            }}
        >
            <Box
                sx={{
                    width: '20px',
                    height: '20px',
                    borderRadius: '100%',
                    backgroundColor: color,
                    mr: '12px',
                }}
            />
            <Text variant={'body3'}>{label}</Text>
        </Box>
    )
}

const ChartLegend: FC = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 2,
            }}
        >
            <LineLegend color={chartColors.carbonStock} label={'Project carbon stock'} />
            <LineLegend color={chartColors.baseline} label={'Baseline projection'} />
            <RoundLegend color={chartColors.buffer} label={'Buffer'} />
            <RoundLegend color={chartColors.assumedLeakage} label={'Assumed leakage'} />
            <RoundLegend color={chartColors.creditsIssued} label={'Credits issued'} />
        </Box>
    )
}

export default ChartLegend
