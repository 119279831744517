import { Bundle, BundleMass, BundleSelection, MassUnit } from '@lune-climate/lune'
import { Big } from 'big.js'

import {
    Allocation,
    IOrderByMassPayload,
    IOrderByValuePayload,
} from 'views/BuyOffsets/BuyOffsetsTypes'

export const bundleSelectionToRecord = (bundleSelection: BundleSelection) => {
    return bundleSelection.reduce(
        (acc, curr) => {
            acc[curr.bundleId] = curr.percentage.toString()
            return acc
        },
        {} as Record<string, string>,
    )
}
export const allocationToRecord = (allocations: Allocation[]) => {
    return allocations.reduce(
        (acc, curr) => {
            acc[curr.bundle.id] = curr.percentage || '0'
            return acc
        },
        {} as Record<string, string>,
    )
}
export const calculateAveragePrice = (
    bundles: Bundle[],
    bundleSelection: Record<string, number | string>,
) => {
    return Object.entries(bundleSelection).reduce((acc: Big, [id, percentage]) => {
        const ratio = new Big(percentage).div(new Big(100))
        const price = bundles.find((b) => b.id === id)?.grossUnitPrice || 0
        return acc.add(ratio.mul(new Big(price)))
    }, new Big(0))
}
export const orderPayloadByMass = (
    allocation: Allocation[],
    customizedPortfolio: boolean,
    amount: string,
    truncateToTonnes?: boolean,
) => {
    const payload: IOrderByMassPayload = customizedPortfolio
        ? {
              bundleMasses: allocation.map(
                  (a): BundleMass => ({
                      bundleId: a.bundle.id,
                      mass: {
                          amount: a.volume.toString(),
                          unit: MassUnit.T,
                      },
                  }),
              ),
          }
        : {
              mass: {
                  amount: Big(amount || 0).toString(),
                  unit: MassUnit.T,
              },
              bundleSelection: allocation.map((a) => ({
                  bundleId: a.bundle.id,
                  percentage: a.percentage,
              })),
          }
    return truncateToTonnes ? { ...payload, quantityTrunc: MassUnit.T } : payload
}

/**
 * Build a payload by mass for the quote/order API call when the user has provided a custom percentage for each bundle
 * @param allocation - the allocations for each bundle
 * @param amount - the total amount by volume of the order
 * @param truncateToTonnes - whether to truncate the order to tonnes
 */
export const orderPayloadByMassWithCustomPercentage = (
    allocation: Allocation[],
    amount: string,
    truncateToTonnes?: boolean,
) => {
    const payload: IOrderByMassPayload = {
        mass: {
            amount: Big(amount || 0).toString(),
            unit: MassUnit.T,
        },
        bundleSelection: allocation.map((a) => ({
            bundleId: a.bundle.id,
            percentage: a.percentage,
        })),
    }

    return truncateToTonnes ? { ...payload, quantityTrunc: MassUnit.T } : payload
}

/**
 * Build a payload by value for the quote/order API call when the user has provided a custom percentage for each bundle
 * @param allocation - the allocations for each bundle
 * @param amount - the total value of the order
 * @param truncateToTonnes - whether to truncate the order to tonnes
 */
export const orderPayloadByValueWithCustomPercentage = (
    allocation: Allocation[],
    amount: string,
    truncateToTonnes?: boolean,
) => {
    const payload: IOrderByValuePayload = {
        value: Big(amount || 0).toString(),
        bundleSelection: allocation.map((a) => ({
            bundleId: a.bundle.id,
            // The API only supports integer percentages
            percentage: Math.round(parseFloat(a.percentage)).toString(),
        })),
    }

    return truncateToTonnes ? { ...payload, quantityTrunc: MassUnit.T } : payload
}
