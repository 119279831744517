import {
    RoRoSeaShippingMethod,
    SeaShippingMethod,
    ShippingMethod,
    VariableFuelSeaShippingMethod,
    VariableFuelVariableLoadSeaShippingMethod,
} from '@lune-climate/lune'
import {
    getLoadLabel,
    isRoRoSeaShippingMethod,
    isVariableFuelSeaShippingMethod,
    isVariableFuelVariableLoadSeaShippingMethod,
} from '@lune-fe/lune-components-lib'
import { Select } from '@lune-fe/lune-ui-lib'
import { useMemo } from 'react'

import ShippingMethods from 'views/CalculateEmissions/Logistics/ShippingMethods'

const SeaShippingComp = ({
    value,
    onChange,
    error,
}: {
    value: SeaShippingMethod
    onChange: (v: ShippingMethod) => void
    error?: { key: string; message: string }
}) => {
    const fuelList = useMemo(() => {
        if (isVariableFuelSeaShippingMethod(value)) {
            return Object.values(VariableFuelSeaShippingMethod.fuel)
        }

        if (isVariableFuelVariableLoadSeaShippingMethod(value)) {
            return Object.values(VariableFuelVariableLoadSeaShippingMethod.fuel)
        }

        if (isRoRoSeaShippingMethod(value)) {
            return Object.values(RoRoSeaShippingMethod.fuel)
        }

        return []
    }, [value])

    const loadList:
        | RoRoSeaShippingMethod.load[]
        | VariableFuelVariableLoadSeaShippingMethod.load[] = useMemo(() => {
        if (isVariableFuelVariableLoadSeaShippingMethod(value)) {
            return Object.values(VariableFuelVariableLoadSeaShippingMethod.load)
        }

        if (isRoRoSeaShippingMethod(value)) {
            return Object.values(RoRoSeaShippingMethod.load)
        }

        return []
    }, [value])

    return (
        <>
            <ShippingMethods
                error={error}
                value={value.vesselType}
                onChange={(vesselType) => {
                    if (vesselType) {
                        onChange({
                            ...value,
                            vesselType: vesselType as any,
                        })
                    }
                }}
            />
            {!!fuelList.length && (
                <Select
                    sx={{ width: '100%' }}
                    label={'Fuel (Optional)'}
                    items={fuelList.map((item) => ({
                        label: item,
                        value: item,
                    }))}
                    onChange={(fuel) => {
                        onChange({
                            ...(value as any),
                            fuel,
                        })
                    }}
                    value={value.fuel as any}
                />
            )}
            {!!loadList.length && (
                <Select
                    sx={{ width: '100%' }}
                    label={'Load (Optional)'}
                    items={loadList.map((item) => ({
                        label: getLoadLabel(item),
                        value: item as any,
                    }))}
                    onChange={(load) => {
                        onChange({
                            ...value,
                            load,
                        } as any)
                    }}
                    value={
                        (value as VariableFuelVariableLoadSeaShippingMethod | RoRoSeaShippingMethod)
                            .load as any
                    }
                />
            )}
        </>
    )
}

export default SeaShippingComp
