import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { FC, useEffect, useState } from 'react'

export type BarProps = {
    label: string
    percentage: number
    subLabel?: string
    color?: string
}

export type HorizontalBarChartProps = {
    items: BarProps[]
    sort?: 'asc' | 'desc'
}

export const chartColors = [
    '#0099F1',
    '#3BC9F0',
    '#69DFC9',
    '#C4EB9E',
    '#FDF07D',
    '#FFE175',
    '#FFB967',
    '#FF7759',
    '#FF4343',
    '#FF8BD8',
    '#E57EFF',
]

const HorizontalBarChart: FC<HorizontalBarChartProps> = ({ items, sort }) => {
    const [sortedItems, setSortedItems] = useState<BarProps[]>([])

    useEffect(() => {
        setSortedItems(
            sort
                ? items.sort((a, b) => {
                      return sort === 'desc'
                          ? b.percentage - a.percentage
                          : a.percentage - b.percentage
                  })
                : items,
        )
    }, [items, sort])

    return (
        <Stack
            direction="column"
            sx={{
                width: '100%',
            }}
            spacing={{
                xs: 1,
            }}
        >
            {sortedItems.map((i, index) => (
                <Box
                    key={`horizontalBarChart-item-${index}`}
                    sx={{
                        position: 'relative',
                        width: `100%`,
                        height: '72px',
                        pl: 2,
                        pr: 2,
                        boxSizing: 'border-box',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            bottom: 0,
                            borderRadius: '8px',
                            backgroundColor:
                                i.color || chartColors[index] || chartColors[index - 11],
                            zIndex: '-1',
                            animation: 'fillBar .5s ease-out 0ms',
                            maxWidth: `${i.percentage}%`,
                            width: `100%`,
                            '@keyframes fillBar': {
                                '0%': {
                                    width: '0%',
                                },
                                '100%': {
                                    width: `100%`,
                                },
                            },
                        }}
                    />
                    <Box>
                        <Typography variant={'caption'} sx={{ mb: 1 / 2 }}>
                            {i.subLabel}
                        </Typography>
                        <Typography variant={'h6'}>{i.label}</Typography>
                    </Box>
                </Box>
            ))}
        </Stack>
    )
}
export default HorizontalBarChart
