import { Button, Modal, Text } from '@lune-fe/lune-ui-lib'
import { FC } from 'react'

interface DiscardChangesConfirmationModalProps {
    onConfirm: () => void
    onCancel: () => void
}

const DiscardChangesConfirmation: FC<DiscardChangesConfirmationModalProps> = ({
    onConfirm,
    onCancel,
}) => {
    return (
        <Modal
            maxWidth={'sm'}
            fullWidth
            open={true}
            onClose={onCancel}
            header={
                <Text variant={'h5'} sx={{ marginBottom: '-48px' }}>
                    Discard Changes?
                </Text>
            }
            actions={
                <>
                    <Button variant={'outlined'} wide onClick={onCancel}>
                        Keep Editing
                    </Button>
                    <Button variant={'contained'} wide onClick={onConfirm}>
                        Discard
                    </Button>
                </>
            }
        >
            <></>
        </Modal>
    )
}

export default DiscardChangesConfirmation
