import type { SxProps } from '@mui/material'
import Box from '@mui/material/Box'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import React, { useMemo } from 'react'

import { LuneTheme } from '../theme'

export type TileVariant = 'default' | 'light'
export const TileVariants = {
    DEFAULT: 'default',
    LIGHT: 'light',
}

const Tile = ({
    children,
    button,
    icon,
    rightSide,
    rightIcon,
    onClick,
    href,
    target,
    variant,
    selected,
    sx,
    compact,
    ...rest
}: {
    children: React.ReactNode
    button?: React.ReactNode
    icon?: JSX.Element
    rightIcon?: JSX.Element
    rightSide?: JSX.Element
    onClick?: (f: React.MouseEvent<HTMLElement>) => void
    href?: string
    target?: string
    variant?: TileVariant
    selected?: boolean
    compact?: boolean
    sx?: SxProps
}) => {
    const { palette, spacing } = LuneTheme

    const lightSX = {
        color: palette.White,
        '*': {
            color: palette.White,
        },
    }

    const selectedSX = {
        backgroundColor: variant === TileVariants.LIGHT ? 'rgba(0, 0, 0, 0.04)' : palette.Grey100,
    }

    const StyledListItem = useMemo(() => {
        const innerStyle = {
            maxWidth: '100%',
            opacity: '1 !important',
            height: '100%',
            fontStyle: 'normal',
            pl: 1,
            pr: 1,
            ...(selected ? selectedSX : ''),
            ...(variant === TileVariants.LIGHT ? lightSX : ''),
        }

        return styled(ListItem)(
            LuneTheme.unstable_sx({
                overflow: 'hidden',
                padding: 0,
                pr: button ? 6 : 0,
                minHeight: button ? spacing(6) : 'auto',
                '.MuiListItemSecondaryAction-root': {
                    right: 0,
                },
                '.MuiListItemButton-root ': innerStyle,
                '> .MuiBox-root': innerStyle,
            }),
        )
    }, [button, selected, variant])

    const TileContent = () => {
        return (
            <>
                {icon && (
                    <ListItemIcon
                        sx={{
                            minWidth: compact ? '20px' : spacing(3),
                            marginRight: compact ? spacing(2) : spacing(3),
                        }}
                    >
                        {icon}
                    </ListItemIcon>
                )}
                <ListItemText>
                    <Typography
                        variant={compact ? 'caption' : 'body3'}
                        component={'div'}
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {children}
                    </Typography>
                </ListItemText>
                {rightIcon}
            </>
        )
    }

    return (
        <StyledListItem secondaryAction={button} sx={sx} {...rest}>
            {onClick || href ? (
                <ListItemButton
                    component={href ? 'a' : 'div'}
                    target={target}
                    href={href}
                    onClick={onClick}
                >
                    <TileContent />
                </ListItemButton>
            ) : (
                <Box sx={{ p: 1, display: `flex`, alignItems: `center`, width: `100%` }}>
                    <TileContent />
                </Box>
            )}
            {rightSide}
        </StyledListItem>
    )
}
export default Tile
