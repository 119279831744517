import { Table } from '@lune-fe/lune-ui-lib'
import KeyOff from '@mui/icons-material/KeyOffOutlined'
import KeyIcon from '@mui/icons-material/KeyOutlined'
import { Box } from '@mui/material'
import moment from 'moment'
import { FC } from 'react'

import { IApiKey } from 'models/apiKey'
import ApiKeyContextMenu from 'views/Developers/APIKeys/ApiKeyContextMenu'

const ApiKeyItem: FC<{ item: IApiKey }> = ({ item }) => {
    const { id, name, maskedSecret, accountName, createdAt, status } = item
    const isDisabled = status === 'disabled'

    return (
        <Table.TableRow key={id} data-testid={maskedSecret.slice(-4)}>
            <Table.TableCell colSize={6} disabled={isDisabled}>
                <Box
                    sx={{
                        mr: 3,
                    }}
                >
                    {isDisabled ? <KeyOff /> : <KeyIcon />}
                </Box>
                {name}
            </Table.TableCell>
            <Table.TableCell
                colSize={2}
                disabled={isDisabled}
                align="right"
                sx={{ fontFamily: 'monospace' }}
            >
                {maskedSecret.slice(maskedSecret.length - 7, maskedSecret.length)}
            </Table.TableCell>
            <Table.TableCell colSize={2} disabled={isDisabled} alignEnd align="right">
                {accountName}
            </Table.TableCell>
            <Table.TableCell colSize={2} disabled={isDisabled} alignEnd align="right">
                {moment(createdAt).format('ll')}
            </Table.TableCell>
            <Table.TableCell shrink align="right">
                <ApiKeyContextMenu id={id} status={status} />
            </Table.TableCell>
        </Table.TableRow>
    )
}

export default ApiKeyItem
