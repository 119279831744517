import { Snippet, SnippetItem } from '@lune-fe/lune-ui-lib'
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined'
import TagOutlinedIcon from '@mui/icons-material/TagOutlined'
import { FC, useMemo } from 'react'
import { useDeepCompareMemo } from 'use-deep-compare'

import { ResultLabeledSectionAndTiles } from './emissionsCalculationExplanation'

export const RequestMeta: FC<{ idempotencyKey?: string; metadata?: Record<string, string> }> = ({
    idempotencyKey,
    metadata,
}) => {
    const hasMetadata = useDeepCompareMemo(
        () => idempotencyKey || Object.keys(metadata ?? {}).length > 0,
        [idempotencyKey, metadata],
    )

    return (
        <>
            {hasMetadata && (
                <ResultLabeledSectionAndTiles
                    tiles={[
                        ...(idempotencyKey
                            ? [
                                  {
                                      label: `Idempotency key: ${idempotencyKey}`,
                                      icon: <KeyOutlinedIcon />,
                                  },
                              ]
                            : []),
                        ...Object.entries(metadata ?? {}).map(([k, v]) => ({
                            label: `${k}: ${v}`,
                            icon: <TagOutlinedIcon />,
                        })),
                    ]}
                />
            )}
        </>
    )
}

const RequestResponseSnippets: FC<{
    request: string
    response: string
    isTestMode?: boolean
    testApiKey?: string
}> = ({ request, response, isTestMode, testApiKey }) => {
    const req = useMemo(
        () => (testApiKey && isTestMode ? request.replace('$API_KEY', testApiKey) : request),
        [request, testApiKey, isTestMode],
    )

    // "plain" means no syntax highlighting. This is intentional per the designs.
    //
    // There's also a performance factor we have to be aware of if we ever want to have
    // syntax highlighting here: with somewhat large but realistic API snippets the
    // syntax highlighting process can freeze the page for tens of seconds on Chromium-based
    // browsers. As such, if ever desired, it likely can't be enabled unconditionally.
    //
    // See also:
    //
    // * https://linear.app/lune/issue/LUN-3663/[dashboard]-logistics-emissions-result-page-is-extremely-slow-on-sea
    // * https://github.com/PrismJS/prism/issues/2130
    const SYNTAX_HIGHLIGHT_LANGUAGE = 'plain'

    // using an empty fragment to encapsulate Request and Response enables us to use the parents spacing
    return (
        <>
            {/* @ts-ignore: ... */}
            <Snippet className="snippet" header="Request" sx={{ maxHeight: '480px' }}>
                <SnippetItem language={SYNTAX_HIGHLIGHT_LANGUAGE} toCopy={req}>
                    {req}
                </SnippetItem>
            </Snippet>
            {/* @ts-ignore: ... */}
            <Snippet className="snippet" header="Response body" sx={{ maxHeight: '480px' }}>
                <SnippetItem language={SYNTAX_HIGHLIGHT_LANGUAGE} toCopy={response}>
                    {response}
                </SnippetItem>
            </Snippet>
        </>
    )
}

export default RequestResponseSnippets
