import MUITable, { TableProps } from '@mui/material/Table'
import MUITableBody, { TableBodyProps } from '@mui/material/TableBody'
import MUITableCell, { TableCellProps } from '@mui/material/TableCell'
import MUITableContainer, { TableContainerProps } from '@mui/material/TableContainer'
import MUITableHead, { TableHeadProps } from '@mui/material/TableHead'
import MUITableRow, { TableRowProps } from '@mui/material/TableRow'

const Table = (props: TableProps) => {
    return (
        <MUITable
            {...props}
            sx={{
                fontFamily: 'Helvetica Neue, Arial, sans-serif',
                fontStyle: 'normal',
                letterSpacing: '0em',
                tableLayout: 'auto',
                fontSize: '14px',
                lineHeight: '17px',
                ...(props.sx ? props.sx : ''),
            }}
        />
    )
}
const TableHead = (props: TableHeadProps) => {
    return (
        <MUITableHead
            sx={{
                fontWeight: '500',
                '*': {
                    boxSizing: 'border-box !important',
                },
                ...(props.sx ? props.sx : ''),
            }}
            {...props}
        />
    )
}
const TableBody = (props: TableBodyProps) => {
    return (
        <MUITableBody
            {...props}
            sx={{
                fontWeight: '400',
                ...(props.sx ? props.sx : ''),
            }}
        />
    )
}

export interface CellProps extends TableCellProps {
    disabled?: boolean
    shrink?: boolean
    alignEnd?: boolean
    colSize?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
}

const TableCell = (props: CellProps) => {
    const calcCellSize = props.colSize ? (props.colSize / 12) * 100 : 0
    const size = {
        width: props.shrink ? 'max-content' : props.colSize ? `${calcCellSize}%` : 'auto',
    }

    const { alignEnd: _, ...cellProps } = props

    return (
        <MUITableCell
            {...cellProps}
            sx={{
                border: 'none',
                py: 0,
                px: 1,
                fontSize: 'inherit',
                fontWeight: 'inherit',
                letterSpacing: 'inherit',
                ...size,
                ...(props.sx ? props.sx : {}),
                color: props.disabled ? 'Grey500' : 'initial',
            }}
        >
            <div
                style={{
                    minHeight: '64px',
                    display: 'flex',
                    alignItems: 'center',
                    ...(props.alignEnd ? { justifyContent: 'flex-end' } : ''),
                }}
            >
                {props.children}
            </div>
        </MUITableCell>
    )
}
const TableRow = (props: TableRowProps) => {
    return (
        <MUITableRow
            {...props}
            sx={{
                ...(props.hover ? { cursor: 'pointer' } : ''),
                ...(props.sx ? props.sx : {}),
                '&.MuiTableRow-hover > td:first-child': { borderRadius: '8px 0px 0px 8px' },
                '&.MuiTableRow-hover > td:last-child': {
                    borderRadius: '0px 8px 8px 0px',
                },
                borderRadius: '8px',
            }}
        />
    )
}
const TableContainer = (props: TableContainerProps) => {
    return (
        <MUITableContainer
            {...props}
            sx={{
                maxHeight: 1,
                ...(props.sx ? props.sx : ''),
            }}
        />
    )
}

Table.TableCell = TableCell
Table.TableHead = TableHead
Table.TableBody = TableBody
Table.TableRow = TableRow
Table.TableContainer = TableContainer
export default Table
