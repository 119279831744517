import { LuneTheme, Text } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'

export const ExplanationChip = ({ text, testId }: { text: string; testId?: string }) => {
    const { palette } = LuneTheme
    return (
        <Box
            sx={{
                backgroundColor: `${palette.Grey100}`,
                marginX: 1,
                paddingX: 1,
                paddingY: '6px',
                borderRadius: '20px',
            }}
        >
            <Text variant={'body2'} sx={{ whiteSpace: 'nowrap' }} data-testid={testId}>
                {text}
            </Text>
        </Box>
    )
}

export default ExplanationChip
