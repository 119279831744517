import {
    ContainerShippingMethod,
    IdentifiedVesselShippingMethod,
    SeaShippingMethod,
    ShippingMethod,
} from '@lune-climate/lune'
import {
    getShippingMethodMainTypeFromValue,
    LogisticsSiteMethodType,
    ShippingMethodType,
} from '@lune-fe/lune-components-lib'
import { Box } from '@mui/material'
import { FC, useMemo } from 'react'

import ContainerShippingComp from 'views/CalculateEmissions/Logistics/Method/ContainerShippingComp'
import IdentifiedVesselShippingComp from 'views/CalculateEmissions/Logistics/Method/IdentifiedVesselShippingComp'
import SeaShippingComp from 'views/CalculateEmissions/Logistics/Method/SeaShippingComp'
import ShipmentMethods from 'views/CalculateEmissions/Logistics/ShipmentMethods'

const Method: FC<{
    value: ShippingMethod | undefined
    onChange: (value: ShippingMethod | undefined) => void
    error?: { key: string; message: string }
}> = ({ value, error, onChange }) => {
    const mainType: ShippingMethodType | LogisticsSiteMethodType | undefined = useMemo(
        () => value && getShippingMethodMainTypeFromValue(value),
        [value],
    )

    return (
        <>
            <Box data-testid={`shipment-methods-wrapper`}>
                <ShipmentMethods value={value} onChange={onChange} error={error} />
            </Box>
            {mainType === 'ContainerShippingMethod' && (
                <ContainerShippingComp
                    onChange={onChange}
                    value={value as ContainerShippingMethod}
                />
            )}
            {mainType === 'IdentifiedVesselShippingMethod' && (
                <IdentifiedVesselShippingComp
                    onChange={onChange}
                    value={value as IdentifiedVesselShippingMethod}
                    error={error}
                />
            )}
            {mainType === 'SeaShippingMethod' && (
                <SeaShippingComp
                    value={value as SeaShippingMethod}
                    onChange={onChange}
                    error={error}
                />
            )}
        </>
    )
}

export default Method
