import { AccountType } from '@lune-climate/lune'
import { LoadingWrapper, NoResultsPlaceholder, Table, Text } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'
import { useEffect, useState } from 'react'

import useApiKeys from 'hooks/useApiKeys'
import useIsTestMode from 'hooks/useIsTestMode'
import { IApiKey } from 'models/apiKey'
import ApiKeyItem from 'views/Developers/APIKeys/ApiKeyItem'

const ApiKeysTable = () => {
    const isTestMode = useIsTestMode()
    const { isLoadingApiKeys, apiKeys } = useApiKeys({
        refetchOnMount: true,
    })
    const [localApiKeys, setLocalApiKeys] = useState<IApiKey[]>()

    useEffect(() => {
        if (apiKeys) {
            setLocalApiKeys(
                apiKeys.filter((k) =>
                    isTestMode ? k.type === AccountType.TEST : k.type === AccountType.LIVE,
                ),
            )
        }
    }, [apiKeys, isTestMode])

    return (
        <LoadingWrapper loading={isLoadingApiKeys} sx={{ height: '554px', mt: 3 }}>
            {!localApiKeys || localApiKeys.length === 0 ? (
                <Box sx={{ height: `554px`, mt: 3 }}>
                    <NoResultsPlaceholder
                        message={
                            <Text
                                variant={`h6`}
                                sx={{
                                    textAlign: 'center',
                                    maxWidth: '250px',
                                }}
                            >
                                You don&apos;t have any API keys yet
                            </Text>
                        }
                    />
                </Box>
            ) : (
                <Table.TableContainer sx={{ mt: 3 }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <Table.TableHead>
                            <Table.TableRow>
                                <Table.TableCell colSize={6}>
                                    {isTestMode ? `Test` : `Live`} API Keys
                                </Table.TableCell>
                                <Table.TableCell colSize={2} align="right">
                                    Secret
                                </Table.TableCell>
                                <Table.TableCell colSize={2} alignEnd align="right">
                                    Default Account
                                </Table.TableCell>
                                <Table.TableCell colSize={2} alignEnd align="right">
                                    Created
                                </Table.TableCell>
                            </Table.TableRow>
                        </Table.TableHead>
                        <Table.TableBody>
                            {localApiKeys.map((k) => (
                                <ApiKeyItem key={k.id} item={k} />
                            ))}
                        </Table.TableBody>
                    </Table>
                </Table.TableContainer>
            )}
        </LoadingWrapper>
    )
}

export default ApiKeysTable
