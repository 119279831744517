import { CtaTile, LuneTheme, MainLayoutContainer, Text } from '@lune-fe/lune-ui-lib'
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined'
import Stack from '@mui/material/Stack'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { Userpilot } from 'userpilot'

import { MAIN_NAV_LOCATION_KEY } from 'components/MainNavigation'
import goToMarket from 'images/go-to-market.png'
import shareYourImpact from 'images/share-your-impact.png'

const GoToMarket: FC = () => {
    const navigate = useNavigate()
    const { palette } = LuneTheme

    return (
        <MainLayoutContainer headerComponent={<Text variant={'h4'}>Go-to-market</Text>}>
            <Stack spacing={{ xs: 4 }}>
                <CtaTile
                    key={`go-to-market-share-your-impact`}
                    title={'Share your impact'}
                    image={shareYourImpact}
                    subTitle={'Make your sustainability efforts visible'}
                    onClick={() => {
                        navigate(`/share-your-impact`)
                    }}
                />
                {process.env.REACT_APP_USERPILOT_TOKEN && (
                    <CtaTile
                        key={`go-to-market-resources`}
                        title={'Go-to-market resources'}
                        image={goToMarket}
                        subTitle={'Explore FAQs, marketing templates and more'}
                        icon={
                            <OpenInNewOutlinedIcon
                                sx={{
                                    color: palette.Grey900,
                                }}
                            />
                        }
                        onClick={() => {
                            localStorage.setItem(MAIN_NAV_LOCATION_KEY, location.pathname)
                            Userpilot.trigger('resource_center:nofXa6hcqU')
                        }}
                    />
                )}
            </Stack>
        </MainLayoutContainer>
    )
}

export default GoToMarket
