import { ButtonGroup, DateRangePicker, Dropdown } from '@lune-fe/lune-ui-lib'
import { SxProps } from '@mui/material'
import Box from '@mui/material/Box'
import moment from 'moment'
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react'
import { Range } from 'react-date-range'

import useUpdateEffect from 'hooks/useUpdateEffect'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

export enum PredefinedRange {
    ALL_TIME = 'allTime',
    THREE_MONTHS = '3',
    TWELVE_MONTHS = '12',
    CUSTOM = 'custom',
}

const predefinedRanges = [
    {
        label: 'Last 3 months',
        value: PredefinedRange.THREE_MONTHS,
    },
    {
        label: 'Last 12 months',
        value: PredefinedRange.TWELVE_MONTHS,
    },
    {
        label: 'All time',
        value: PredefinedRange.ALL_TIME,
    },
]

const DATE_FORMAT = 'DD MMM YYYY'

const DateRangeFilter: FC<{
    setDateRange: Dispatch<SetStateAction<Range>>
    defaultRange: PredefinedRange
    defaultLabel?: string
    dateDisplayFormat?: string
    sx?: SxProps
    labelSx?: SxProps
    predefinedDateRange?: Range
}> = ({
    setDateRange,
    defaultRange,
    defaultLabel,
    dateDisplayFormat,
    sx,
    labelSx,
    predefinedDateRange,
}) => {
    const today = useMemo(() => moment(), [])
    const [predefinedRange, setPredefinedRange] = useState<string | undefined>(defaultRange)
    const [buttonLabel, setButtonLabel] = useState<string>()
    const [localDateRange, setLocalDateRange] = useState<Range>({
        startDate: undefined,
        endDate: undefined,
    })
    const [initLoad, setInitLoad] = useState<boolean>(true)

    const getButtonLabel = (selectedRange: string, range?: Range) => {
        const selectedPredefinedRange = predefinedRanges.find(
            (range) => range.value === selectedRange,
        )
        if (!selectedPredefinedRange) {
            return `${moment((range || localDateRange).startDate).format(
                dateDisplayFormat || DATE_FORMAT,
            )} - ${moment((range || localDateRange).endDate).format(
                dateDisplayFormat || DATE_FORMAT,
            )}`
        } else {
            if (defaultLabel && selectedPredefinedRange.value === defaultRange) {
                return defaultLabel
            }
            return selectedPredefinedRange.label
        }
    }

    const updateRanges = (start: Date | undefined, end: Date | undefined) => {
        setDateRange({
            startDate: start,
            endDate: end,
        })
        setLocalDateRange({
            startDate: start,
            endDate: end,
        })
    }

    useEffect(() => {
        const start = predefinedDateRange?.startDate
        const end = predefinedDateRange?.endDate
        if (start && end) {
            setLocalDateRange(predefinedDateRange)
            const timePeriod = moment(start).diff(moment(end), 'months', true)
            const endsToday = moment(end).toDate().toDateString() === new Date().toDateString()
            if (timePeriod === -3 && endsToday) {
                setButtonLabel(getButtonLabel(PredefinedRange.THREE_MONTHS, predefinedDateRange))
                setPredefinedRange(PredefinedRange.THREE_MONTHS)
            } else if (timePeriod === -12 && endsToday) {
                setButtonLabel(getButtonLabel(PredefinedRange.TWELVE_MONTHS, predefinedDateRange))
                setPredefinedRange(PredefinedRange.TWELVE_MONTHS)
            } else {
                setButtonLabel(getButtonLabel(PredefinedRange.CUSTOM, predefinedDateRange))
                setPredefinedRange(PredefinedRange.CUSTOM)
            }
        } else {
            setButtonLabel(getButtonLabel(defaultRange))
        }
        setTimeout(() => {
            setInitLoad(false)
        }, 0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultRange, predefinedDateRange])

    useUpdateEffect(() => {
        if (predefinedRange && !initLoad) {
            if (predefinedRange === PredefinedRange.ALL_TIME) {
                updateRanges(undefined, undefined)
            }
            if (
                predefinedRange !== PredefinedRange.CUSTOM &&
                predefinedRange !== PredefinedRange.ALL_TIME
            ) {
                updateRanges(
                    today.clone().subtract(predefinedRange, `months`).toDate(),
                    today.toDate(),
                )
            }
        }
    }, [predefinedRange])

    return (
        <Dropdown
            buttonLabel={buttonLabel || ''}
            closeOnClick={false}
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                ...sx,
            }}
            labelSx={labelSx}
        >
            <Box sx={{ padding: '16px 24px' }}>
                <ButtonGroup
                    sx={{
                        height: '54px !important',
                        width: '100%',
                        overflow: 'hidden',
                        mb: 4,
                    }}
                    items={predefinedRanges}
                    onChange={(predefinedRange) => {
                        setPredefinedRange(predefinedRange)
                        setButtonLabel(getButtonLabel(predefinedRange))
                    }}
                    value={predefinedRange || ''}
                />
                <DateRangePicker
                    onChange={(range) => {
                        setPredefinedRange(PredefinedRange.CUSTOM)
                        setButtonLabel(getButtonLabel(PredefinedRange.CUSTOM, range))
                        updateRanges(range.startDate, range.endDate)
                    }}
                    predefinedRange={{
                        startDate: localDateRange.startDate!,
                        endDate: localDateRange.endDate!,
                    }}
                />
            </Box>
        </Dropdown>
    )
}
export default DateRangeFilter
