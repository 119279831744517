import { Bundle } from '@lune-climate/lune'
import { ScrollableContainer } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import { FC, RefObject } from 'react'
import { useNavigate } from 'react-router-dom'

import BundleCard from 'components/BundleCard'
import useScrollRestoration, { ScrollPositionKeys } from 'hooks/useScrollRestoration'

const BundlesList: FC<{
    scrollRef?: RefObject<HTMLDivElement>
    selectedBundles: {
        bundle: Bundle
        percentage: string
    }[]
    currency?: string
    viewOnly?: boolean
}> = ({ selectedBundles, scrollRef, currency, viewOnly }) => {
    const navigate = useNavigate()
    const { saveScrollPosition } = useScrollRestoration()

    return (
        <ScrollableContainer containerRef={scrollRef}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'stretch',
                    justifyContent: 'flex-start',
                    height: '560px',
                    gap: 2,
                    mr: 2,
                }}
            >
                {selectedBundles.map(({ bundle, percentage }) => {
                    return (
                        <BundleCard
                            currency={currency}
                            percentage={percentage}
                            key={`bundle-grid-item-${bundle.id}`}
                            bundle={bundle}
                            handleProjectClick={(projectSlug) => {
                                if (!viewOnly && scrollRef) {
                                    saveScrollPosition(scrollRef, ScrollPositionKeys.PROPOSAL_VIEW)
                                    navigate(`/projects/${projectSlug}`, {
                                        state: {
                                            fromProposalView: true,
                                        },
                                    })
                                }
                            }}
                        />
                    )
                })}
            </Box>
        </ScrollableContainer>
    )
}

export default BundlesList
