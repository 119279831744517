import { RadioButtonGroup } from '@lune-fe/lune-ui-lib'
import { FC } from 'react'
import { Field } from 'react-final-form'

import { ProposalRequest } from 'models/openDapi'
import ProposalRequestLabeledFormSection from 'views/Proposals/ProposalRequestForm/ProposalRequestLabeledFormSection'

export const GoalsStep: FC<{ formValues?: ProposalRequest }> = ({ formValues }) => {
    return (
        <>
            <ProposalRequestLabeledFormSection label={'Anything else?'}>
                <Field name={'readyToBuy'}>
                    {({ input: { onChange, value } }: any) => (
                        <RadioButtonGroup
                            name={'proposal-request-goal'}
                            defaultValue={formValues?.readyToBuy}
                            // onChange value is string by default
                            onChange={(val) => onChange(val.target.value === 'true')}
                            value={value}
                            items={[
                                {
                                    label: "I'm ready to buy, I want to see portfolio options",
                                    value: true,
                                },
                                {
                                    label: "I'm browsing, will need to seek approval",
                                    value: false,
                                },
                            ]}
                        />
                    )}
                </Field>
            </ProposalRequestLabeledFormSection>
        </>
    )
}

export default GoalsStep
