import * as MUIcon from '@mui/icons-material'
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined'
import { SxProps } from '@mui/material'
import { FC } from 'react'

interface IconProps {
    iconName?: keyof typeof MUIcon
    sx?: SxProps
}

const ProjectIcon: FC<IconProps> = ({ iconName, sx }) => {
    const Icon = iconName && MUIcon[iconName]
    return Icon ? <Icon sx={sx} /> : <VolunteerActivismOutlinedIcon sx={sx} />
}
export default ProjectIcon
