import { ContainerShippingMethod, ShippingMethod, SimpleShippingMethod } from '@lune-climate/lune'
import {
    CalculateEmissionsIcons,
    getShippingMethodLabel,
    ShippingMethodType,
} from '@lune-fe/lune-components-lib'
import { AutocompleteSelect, LuneTheme, Text, Tile } from '@lune-fe/lune-ui-lib'
import { isString } from 'lodash'
import { FC, memo, useMemo } from 'react'

type ListOption = {
    group: string
    label: string
    value: ShippingMethod
    type: ShippingMethodType
}

interface Props {
    value?: ShippingMethod
    onChange: (value: ShippingMethod | undefined) => void
    error?: { key: string; message: string }
    disabled?: boolean
}

export enum OtherShipmentMethods {
    OTHER = 'OTHER',
    IMO = 'IMO',
}

const doTheyHaveTheSameKeys = (a: ShippingMethod, b: ShippingMethod) => {
    const aKeys = Object.keys(a)
    const bKeys = Object.keys(b)
    return aKeys.every((key) => bKeys.includes(key))
}

const ShipmentMethodsList: ListOption[] = [
    {
        group: 'ship',
        label: getShippingMethodLabel(ContainerShippingMethod.vessel_type.CONTAINER_SHIP),
        value: {
            vesselType: ContainerShippingMethod.vessel_type.CONTAINER_SHIP,
            refrigerated: false,
        },
        type: `ContainerShippingMethod`,
    },
    {
        group: 'ship',
        label: 'Ship by IMO number',
        value: {
            vesselImoNumber: '',
        },
        type: `IdentifiedVesselShippingMethod`,
    },
    {
        group: 'ship',
        label: 'Ship by vessel name',
        value: {
            vesselName: '',
        },
        type: `IdentifiedVesselShippingMethod`,
    },
    {
        group: 'ship',
        label: 'Other shipping methods',
        value: {
            vesselType: '' as any,
        },
        type: `SeaShippingMethod`,
    },
    {
        group: 'plane',
        label: getShippingMethodLabel(SimpleShippingMethod.CARGO_PLANE),
        value: SimpleShippingMethod.CARGO_PLANE,
        type: `SimpleShippingMethod`,
    },
    {
        group: 'plane',
        label: getShippingMethodLabel(SimpleShippingMethod.PASSENGER_PLANE),
        value: SimpleShippingMethod.PASSENGER_PLANE,
        type: `SimpleShippingMethod`,
    },
    {
        group: 'truck',
        label: getShippingMethodLabel(SimpleShippingMethod.DIESEL_TRUCK),
        value: SimpleShippingMethod.DIESEL_TRUCK,
        type: `SimpleShippingMethod`,
    },
    {
        group: 'truck',
        label: getShippingMethodLabel(SimpleShippingMethod.TRUCK_RIGID_7_5T_ELECTRIC),
        value: SimpleShippingMethod.TRUCK_RIGID_7_5T_ELECTRIC,
        type: `SimpleShippingMethod`,
    },
    {
        group: 'truck',
        label: getShippingMethodLabel(SimpleShippingMethod.TRUCK_GENERIC_VAN_ELECTRIC),
        value: SimpleShippingMethod.TRUCK_GENERIC_VAN_ELECTRIC,
        type: `SimpleShippingMethod`,
    },
    {
        group: 'train',
        label: getShippingMethodLabel(SimpleShippingMethod.DIESEL_FREIGHT_TRAIN),
        value: SimpleShippingMethod.DIESEL_FREIGHT_TRAIN,
        type: `SimpleShippingMethod`,
    },
    {
        group: 'train',
        label: getShippingMethodLabel(SimpleShippingMethod.ELECTRIC_FREIGHT_TRAIN),
        value: SimpleShippingMethod.ELECTRIC_FREIGHT_TRAIN,
        type: `SimpleShippingMethod`,
    },
]

const ShipmentMethods: FC<Props> = ({ value, onChange, error, disabled }) => {
    const { palette } = LuneTheme

    const getGroupIcon = (group: string) => {
        switch (group) {
            case 'ship': {
                return CalculateEmissionsIcons.ship
            }
            case 'plane': {
                return CalculateEmissionsIcons.plane
            }
            case 'truck': {
                return CalculateEmissionsIcons.truck
            }
            case 'train': {
                return CalculateEmissionsIcons.train
            }
        }
    }

    const selectedValue = useMemo(() => {
        const selectedMethod = ShipmentMethodsList.find((method) => {
            return (
                method.value === value ||
                (value && !isString(value) && doTheyHaveTheSameKeys(method.value, value))
            )
        })
        return selectedMethod
            ? {
                  label: selectedMethod.label,
                  value: selectedMethod.value,
                  group: selectedMethod.group,
              }
            : {
                  label: '',
                  value: '',
                  group: '',
              }
    }, [value])

    return (
        <AutocompleteSelect
            disabled={disabled}
            label={'Shipment method'}
            error={
                error?.key === 'SHIPMENT_METHOD' || error?.key === 'required' ? error.message : ''
            }
            onChange={(selected: ListOption | undefined) => onChange(selected?.value)}
            value={selectedValue as ListOption}
            items={ShipmentMethodsList}
            groupBy={(option: ListOption) => option.group}
            showGroupName={false}
            customOptions={(option) => (
                <Tile icon={getGroupIcon(option.group)} sx={{ '*': { color: palette.Grey900 } }}>
                    <Text variant={'body3'}>{option.label}</Text>
                </Tile>
            )}
        />
    )
}

export default memo(ShipmentMethods)
