import { AutocompleteSelect, LuneTheme, Text, Tile } from '@lune-fe/lune-ui-lib'
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive'
import DirectionsBoatFilledOutlinedIcon from '@mui/icons-material/DirectionsBoatFilledOutlined'
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined'
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined'
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined'
import { FC, useMemo } from 'react'

type ListOption = {
    label: string
    value: RouteType
}

interface Props {
    value?: RouteType
    onChange: (value: RouteType | undefined) => void
    error?: string
    disabled?: boolean
    showAirportCode?: boolean
    showPortCode?: boolean
}

export enum RouteType {
    DISTANCE = 'distance',
    ADDRESS = 'address',
    GEO_COORDINATES = 'coordinate',
    SEAPORT = 'portCode',
    AIRPORT = 'airportCode',
}

const RoutesList = [
    {
        label: 'Distance',
        value: RouteType.DISTANCE,
    },
    {
        label: 'Address',
        value: RouteType.ADDRESS,
    },
    {
        label: 'Geo coordinates',
        value: RouteType.GEO_COORDINATES,
    },
]

const RouteTypes: FC<Props> = ({
    value,
    onChange,
    error,
    disabled,
    showAirportCode,
    showPortCode,
}) => {
    const { palette } = LuneTheme

    const routesList = useMemo(() => {
        return [
            ...RoutesList,
            ...(showAirportCode ? [{ label: 'Airport code', value: RouteType.AIRPORT }] : []),
            ...(showPortCode ? [{ label: 'Port code', value: RouteType.SEAPORT }] : []),
        ]
    }, [showAirportCode, showPortCode])

    const getTypeIcon = (type: string) => {
        switch (type) {
            case 'coordinate': {
                return <PinDropOutlinedIcon width={24} sx={{ color: palette.Grey900 }} />
            }
            case 'portCode': {
                return (
                    <DirectionsBoatFilledOutlinedIcon width={24} sx={{ color: palette.Grey900 }} />
                )
            }
            case 'airportCode': {
                return <AirplanemodeActiveIcon width={24} sx={{ color: palette.Grey900 }} />
            }
            case 'address': {
                return <HomeWorkOutlinedIcon width={24} sx={{ color: palette.Grey900 }} />
            }
            case 'distance': {
                return <RouteOutlinedIcon width={24} sx={{ color: palette.Grey900 }} />
            }
        }
    }
    const selectedValue = useMemo(() => {
        const selectedMethod = routesList.find((route) => route.value === value)
        return selectedMethod
            ? {
                  label: selectedMethod.label,
                  value: selectedMethod.value,
              }
            : {
                  label: '',
                  value: '',
              }
    }, [value, routesList])

    return (
        <AutocompleteSelect
            disabled={disabled}
            label={'Route'}
            error={error}
            onChange={(selected: ListOption | undefined) => onChange(selected?.value)}
            value={selectedValue as ListOption}
            items={routesList}
            customOptions={(option) => (
                <Tile icon={getTypeIcon(option.value)}>
                    <Text variant={'body3'}>{option.label}</Text>
                </Tile>
            )}
        />
    )
}

export default RouteTypes
