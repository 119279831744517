import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { LuneTheme } from '../theme'

import CardContainer, { CardProps } from './CardContainer'

export interface LuneCardProps extends CardProps {
    title: React.ReactNode
    tiles?: JSX.Element[]
    extraTiles?: JSX.Element[]
    description?: string
    map?: JSX.Element
    buttons?: JSX.Element[]
    removeTopPadding?: boolean
    compact?: boolean
}

const Card = (props: LuneCardProps): JSX.Element => {
    const { palette, spacing } = LuneTheme

    return (
        <CardContainer
            compact={props.compact}
            backgroundImage={props.backgroundImage}
            gradientColor={props.gradientColor}
            sx={props.sx}
            onClick={props.onClick}
        >
            <Typography
                data-testid={`card-title`}
                variant={props.compact ? 'h5' : 'h4'}
                sx={{
                    color: palette.White,
                    marginBottom: spacing(0),
                    marginTop: props.removeTopPadding
                        ? spacing(2)
                        : props.compact
                          ? '76px'
                          : spacing(17),
                }}
            >
                {props.title}
            </Typography>
            <Stack
                direction="column"
                sx={{
                    mt: 2,
                    width: '100%',
                }}
                spacing={{
                    xs: 2,
                }}
            >
                {props.tiles && (
                    <div
                        style={{
                            marginLeft: spacing(-1),
                            marginRight: spacing(-1),
                        }}
                    >
                        {props.tiles}
                    </div>
                )}
                {props.map}
                {props.children}
                {props.extraTiles && (
                    <div
                        style={{
                            marginLeft: spacing(-1),
                            marginRight: spacing(-1),
                        }}
                    >
                        {props.extraTiles}
                    </div>
                )}
                {props.description && (
                    <Typography
                        variant={props.compact ? 'caption' : 'body3'}
                        sx={{ color: palette.White }}
                    >
                        {props.description}
                    </Typography>
                )}
            </Stack>
            {props.buttons && (
                <Stack
                    direction="column"
                    sx={{
                        mt: 2,
                        width: '100%',
                        button: {
                            width: '100%',
                        },
                    }}
                    spacing={{
                        xs: 1,
                    }}
                >
                    {props.buttons}
                </Stack>
            )}
        </CardContainer>
    )
}
export default Card
