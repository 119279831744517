import { Button, Loading, Modal, StandardModalHeader, Textarea } from '@lune-fe/lune-ui-lib'
import AddIcon from '@mui/icons-material/Add'
import { parseAddressList, ParsedMailbox } from 'email-addresses'
import { FORM_ERROR } from 'final-form'
import { useSnackbar } from 'notistack'
import { useState } from 'react'

import ConditionalTooltip from 'components/ConditionalTooltip'
import NotAdminTooltip from 'components/NotAdminTooltip'
import { inviteToOrganisation } from 'endpoints/dapi'
import useActiveOrganisation from 'hooks/useActiveOrganisation'
import useMixpanel from 'hooks/useMixpanel'
import { useIsAuthorised } from 'hooks/useUserState'
import { SnackbarMessages } from 'SnackbarMessages'

const InviteTeamMembers = () => {
    const isAuthorised = useIsAuthorised()
    const { activeOrg, refetchActiveOrg } = useActiveOrganisation()
    const [showInviteDialog, setShowInviteDialog] = useState(false)
    const [emails, setEmails] = useState('')
    const [isSubmitting, setIsSubmitting] = useState(false)
    const mixpanel = useMixpanel()
    const { enqueueSnackbar: snackbar } = useSnackbar()

    const onInviteBtn = async (emails: string) => {
        const addresses = parseAddressList(emails.trim())
        if (!addresses?.length) {
            snackbar(SnackbarMessages.INVITE_TEAM_MEMBER_INVALID_EMAIL)
            return
        }

        const parsedAddresses = addresses.map((a) => (a as ParsedMailbox).address)
        const promises = parsedAddresses.map((a) => inviteToOrganisation(activeOrg?.id!, a))

        setIsSubmitting(true)
        try {
            await Promise.all(promises)
        } catch {
            const msg = SnackbarMessages.INVITE_TEAM_MEMBER_FAIL
            snackbar(msg)
            return {
                [FORM_ERROR]: msg,
            }
        } finally {
            setIsSubmitting(false)
            setShowInviteDialog(false)
            refetchActiveOrg()
            setEmails('')
            mixpanel.track('user_invited')
            snackbar(SnackbarMessages.INVITE_TEAM_MEMBER_SUCCESS)
        }
    }

    return (
        <>
            <Modal
                maxWidth={'sm'}
                fullWidth
                open={showInviteDialog}
                onClose={() => setShowInviteDialog(false)}
                header={<StandardModalHeader title={'Invite people to organisation'} />}
                actions={
                    <>
                        <Button
                            variant={'outlined'}
                            wide
                            onClick={() => setShowInviteDialog(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant={'contained'}
                            wide
                            onClick={() => onInviteBtn(emails)}
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? <Loading /> : 'Invite'}
                        </Button>
                    </>
                }
            >
                <Textarea
                    value={emails}
                    onChange={(e: any) => setEmails(e.target.value)}
                    wide
                    rows={3}
                    autofocus
                    placeholder={'Enter any email address, comma separated.'}
                />
            </Modal>

            <ConditionalTooltip show={!isAuthorised} tooltip={NotAdminTooltip} contentWidth>
                <Button
                    sx={{
                        minWidth: '120px',
                    }}
                    leftIcon={<AddIcon />}
                    onClick={() => setShowInviteDialog(true)}
                    disabled={!isAuthorised}
                >
                    Invite
                </Button>
            </ConditionalTooltip>
        </>
    )
}

export default InviteTeamMembers
