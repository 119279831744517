import { Dropdown, DropdownItem, ListItemLayout, LuneTheme } from '@lune-fe/lune-ui-lib'
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined'
import LogoutIcon from '@mui/icons-material/Logout'
import { styled } from '@mui/system'
import { flatten } from 'lodash'
import { useSnackbar } from 'notistack'
import { useEffect, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'

import DiscardChangesConfirmation from 'components/DiscardChangesConfirmation'
import { setUserPrimaryAccountId } from 'endpoints/dapi'
import useAccounts from 'hooks/useAccounts'
import useAllOrganisationAccounts from 'hooks/useAllOrganisationAccounts'
import useHasUnsavedChanges from 'hooks/useHasUnsavedChanges'
import useLogout from 'hooks/useLogout'
import useOrganisations from 'hooks/useOrganisations'
import { IAccount } from 'models/account'
import { getCurrencySymbol } from 'models/currency'
import { queryKeys } from 'queryKeys'
import { SnackbarMessages } from 'SnackbarMessages'

const AccountSwitcher = () => {
    const { palette, spacing } = LuneTheme
    const queryClient = useQueryClient()
    const { logout } = useLogout()
    const { hasUnsavedChanges, setHasUnsavedChangesState } = useHasUnsavedChanges()
    const [openModal, setOpenModal] = useState<boolean>(false)

    const { activeAccount } = useAccounts()

    const [accounts, setAccounts] = useState<IAccount[]>([])
    const { refetching, paginatedAccounts, hasNextPage, fetchNextPage } =
        useAllOrganisationAccounts({
            limit: 100,
        })

    // go through all pages: programmatically trigger `fetchNextPage()`
    useEffect(() => {
        if (refetching) {
            return
        }

        if (hasNextPage) {
            fetchNextPage()
        } else {
            setAccounts(flatten((paginatedAccounts?.pages ?? []).map(({ data }) => data)))
        }
        // The eslint exception is required to avoid having to pass `fetchNextPage` in the dependency array
        // because it causes infinite loops
    }, [paginatedAccounts?.pages.length, hasNextPage, refetching]) // eslint-disable-line react-hooks/exhaustive-deps

    const { enqueueSnackbar: snackbar } = useSnackbar()
    const { organisations } = useOrganisations()

    const [newAccount, setNewAccount] = useState<{
        accId: string
        orgId: string
    }>({
        accId: '',
        orgId: '',
    })

    const changeAccount = (id: string, orgId: string) => {
        const activeAccOrgId = activeAccount?.organisationId
        if (id === activeAccount?.id) {
            return
        }
        setUserPrimaryAccountId(id as any as number).then(() => {
            if (orgId === activeAccOrgId) {
                queryClient.invalidateQueries({
                    predicate: (query) => query.queryKey !== queryKeys.GET_ACCOUNTS,
                })
            } else {
                queryClient.invalidateQueries()
            }
            snackbar(SnackbarMessages.ACCOUNT_CHANGE)
        })
    }

    const switchAccount = (id: string, orgId: string) => {
        if (hasUnsavedChanges) {
            setNewAccount({ accId: id, orgId })
            setOpenModal(true)
        } else {
            changeAccount(id, orgId)
        }
    }

    const StyledHr = styled('hr')(({ theme }) =>
        theme.unstable_sx({
            border: 0,
            height: '1px',
            width: 1,
            background: palette.Grey300,
            margin: `${spacing(1)} 0`,
        }),
    )

    const DisabledDropdownItem = useMemo(
        () =>
            styled(DropdownItem)(({ theme }) =>
                theme.unstable_sx({
                    cursor: 'pointer',
                    pointerEvents: 'none !important',
                    fontWeight: 500,
                    height: '48px',
                    minWidth: '240px',
                    padding: `0 ${spacing(3)}`,
                }),
            ),
        [spacing],
    )

    return (
        <>
            <Dropdown
                dataTestId={`account-switch-dropdown`}
                buttonLabel={activeAccount?.name || ``}
                compact
                menuAutoWidth
                sx={{
                    ml: 1,
                    backgroundColor: `rgba(0, 0, 0, 0.04)`,
                }}
            >
                {organisations.map((org) => {
                    return [
                        <DisabledDropdownItem key={`account-switch-organisation-${org.id}`}>
                            {org.name}
                        </DisabledDropdownItem>,
                        [
                            accounts
                                .filter((a) => a.organisationId === org.id)
                                .map((acc) => (
                                    <DropdownItem
                                        sx={{ padding: `0 ${spacing(3)}` }}
                                        selected={acc.id === activeAccount?.id}
                                        onClick={() => switchAccount(acc.id, acc.organisationId)}
                                        key={`account-switch-account-${acc.id}`}
                                    >
                                        <ListItemLayout
                                            key={`account-switch-account-tile-${acc.id}`}
                                            compact
                                            image={<AccountBalanceWalletOutlinedIcon />}
                                            title={acc.name || ''}
                                            subTitle={
                                                <>
                                                    {getCurrencySymbol(acc.currency) && (
                                                        <>{getCurrencySymbol(acc.currency)} • </>
                                                    )}
                                                    {acc.currency}
                                                </>
                                            }
                                        />
                                    </DropdownItem>
                                )),
                        ],
                        <StyledHr key={`StyledHr`} />,
                    ]
                })}
                <DropdownItem
                    data-testid={`logout-btn`}
                    sx={{ padding: `0 ${spacing(3)}`, height: '48px' }}
                    onClick={() => logout()}
                >
                    <ListItemLayout compact image={<LogoutIcon />} title={'Logout'} />
                </DropdownItem>
            </Dropdown>
            {openModal && (
                <DiscardChangesConfirmation
                    onConfirm={() => {
                        setOpenModal(false)
                        setHasUnsavedChangesState(false)
                        changeAccount(newAccount.accId, newAccount.orgId)
                    }}
                    onCancel={() => {
                        setOpenModal(false)
                    }}
                />
            )}
        </>
    )
}

export default AccountSwitcher
