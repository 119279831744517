import ChevronLeft from '@mui/icons-material/ChevronLeft'
import ChevronRight from '@mui/icons-material/ChevronRight'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import Fade from '@mui/material/Fade'
import Grow from '@mui/material/Grow'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import Slide from '@mui/material/Slide'
import Zoom from '@mui/material/Zoom'
import {
    HTMLAttributes,
    KeyboardEventHandler,
    MouseEventHandler,
    useCallback,
    useEffect,
    useState,
} from 'react'

import { LuneTheme } from '../theme'

type Props = { url: string; attributionUrl?: string; attributionText?: string }
type ImageProps = Props & { type: 'image' } & HTMLAttributes<HTMLImageElement>
type VideoProps = Props & { type: 'video' } & HTMLAttributes<HTMLVideoElement>
type MediaProps = ImageProps | VideoProps

export interface ControlledLightboxProps {
    onPrevious(): unknown
    onNext(): unknown
    activeIndex: number
}

export interface LightboxProps extends Omit<DialogProps, 'children'> {
    media: MediaProps[]
    TransitionComponent?: typeof Collapse | typeof Fade | typeof Slide | typeof Grow | typeof Zoom
}

const LightboxBase = ({
    media,
    TransitionComponent = Fade,
    onPrevious,
    onNext,
    activeIndex,
    onKeyDown,
    ...props
}: LightboxProps & ControlledLightboxProps) => {
    const theme = LuneTheme

    const handlePreviousButtonClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
        () => onPrevious(),
        [],
    )
    const handleNextButtonClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
        () => onNext(),
        [],
    )

    const handleKeyDown = useCallback<KeyboardEventHandler<HTMLDivElement>>((e) => {
        switch (e.which) {
            case 37:
                onPrevious()
                if (onKeyDown) {
                    onKeyDown(e)
                }
                break
            case 39:
                onNext()
                if (onKeyDown) {
                    onKeyDown(e)
                }
                break
            default:
                if (onKeyDown) {
                    onKeyDown(e)
                }
        }
    }, [])

    return (
        <Dialog maxWidth="xl" {...props} onKeyDown={handleKeyDown}>
            {media.map((mediaItem, index) => {
                if (index !== activeIndex) {
                    return null
                }

                return (
                    <TransitionComponent
                        key={`${index}-${mediaItem.url}`}
                        in={activeIndex === index}
                    >
                        <div style={{ position: 'relative' }}>
                            {mediaItem.type === 'image' ? (
                                <img
                                    style={{
                                        display: 'block',
                                        maxWidth: '100%',
                                        maxHeight: `calc(100vh - ${theme.spacing(8)})`,
                                    }}
                                    {...mediaItem}
                                    src={mediaItem.url}
                                />
                            ) : (
                                <video
                                    autoPlay
                                    muted
                                    loop
                                    {...mediaItem}
                                    style={{
                                        display: 'block',
                                        maxWidth: '100%',
                                        maxHeight: `calc(100vh - ${theme.spacing(8)})`,
                                    }}
                                >
                                    <source src={mediaItem.url} type="video/mp4" />
                                    Your browser does not support this video.
                                </video>
                            )}

                            {mediaItem.attributionUrl && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '16px',
                                        position: 'absolute',
                                        left: '0px',
                                        right: '0px',
                                        bottom: '0px',
                                        background:
                                            'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%)',
                                    }}
                                >
                                    <Link
                                        variant={'caption'}
                                        color="White"
                                        href={mediaItem.attributionUrl}
                                        target="_blank"
                                        underline="always"
                                    >
                                        {mediaItem.attributionText ?? mediaItem.attributionUrl}
                                    </Link>
                                </Box>
                            )}
                        </div>
                    </TransitionComponent>
                )
            })}
            <IconButton
                sx={{
                    position: 'absolute',
                    left: theme.spacing(2),
                    top: '50%',
                    transform: 'translateY(-50%)',
                    background: theme.palette.Grey100,
                    boxShadow: theme.shadows[7],
                    '&:hover': { background: theme.palette.Grey200 },
                }}
                onClick={handlePreviousButtonClick}
            >
                <ChevronLeft />
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                sx={{
                    position: 'absolute',
                    right: theme.spacing(2),
                    top: '50%',
                    transform: 'translateY(-50%)',
                    background: theme.palette.Grey100,
                    boxShadow: theme.shadows[7],
                    '&:hover': { background: theme.palette.Grey200 },
                }}
            >
                <ChevronRight />
            </IconButton>
        </Dialog>
    )
}

const Lightbox = (props: LightboxProps & Partial<ControlledLightboxProps>) => {
    const { media, onPrevious, onNext, activeIndex, ...restProps } = props
    const [selected, setSelected] = useState<number>(activeIndex ?? 0)

    useEffect(() => {
        if (activeIndex !== undefined) {
            setSelected(activeIndex)
        }
    }, [activeIndex])

    const handlePreviousMediaRequest = useCallback(() => {
        setSelected((sel) => (sel + media.length - 1) % media.length)
    }, [media])

    const handleNextMediaRequest = useCallback(() => {
        setSelected((sel) => (sel + 1) % media.length)
    }, [media])

    return (
        <LightboxBase
            media={media}
            onPrevious={onPrevious || handlePreviousMediaRequest}
            onNext={onNext || handleNextMediaRequest}
            activeIndex={selected}
            {...restProps}
        />
    )
}

export default Lightbox
