import { Webhook } from '@lune-climate/lune'
import { IconButtonDropdown } from '@lune-fe/lune-ui-lib'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import LinkOffOutlinedIcon from '@mui/icons-material/LinkOffOutlined'
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined'
import { useSnackbar } from 'notistack'
import { FC, useState } from 'react'

import ContextMenuItem from 'components/ContextMenuItem'
import { useLuneClient } from 'hooks/useLuneClient'
import { useIsAuthorised } from 'hooks/useUserState'
import useWebhooks from 'hooks/useWebhooks'
import { SnackbarMessages } from 'SnackbarMessages'
import SecretTokenModal from 'views/Developers/SecretTokenModal'

const WebhookContextMenu: FC<{ webhook: Webhook }> = ({ webhook }) => {
    const { refetch } = useWebhooks()
    const { enqueueSnackbar: snackbar } = useSnackbar()
    const [secretToken, setSecretToken] = useState<string>()
    const isAdmin = useIsAuthorised()
    const { id, url, enabled } = webhook
    const luneClient = useLuneClient()

    const rotateWebhookSecret = () => {
        luneClient
            .rotateWebhookSecret(id)
            .then((res) => {
                if (res.isOk()) {
                    setSecretToken(res.value.secret)
                }
            })
            .catch(() => snackbar(SnackbarMessages.WEBHOOK_ROTATE_FAIL))
    }

    const changeWebhookStatus = () => {
        luneClient
            .updateWebhook(id, { url, enabled: !enabled })
            .then((res) => {
                if (res.isOk()) {
                    snackbar(SnackbarMessages.WEBHOOK_UPDATE_SUCCESS)
                    return refetch()
                }
            })
            .catch(() => snackbar(SnackbarMessages.WEBHOOK_UPDATE_FAIL))
    }

    const deleteWebhook = () => {
        luneClient
            .deleteWebhook(id)
            .then((res) => {
                if (res.isOk()) {
                    snackbar(SnackbarMessages.WEBHOOK_DELETE_SUCCESS)
                    return refetch()
                }
            })
            .catch(() => snackbar(SnackbarMessages.WEBHOOK_DELETE_FAIL))
    }

    return (
        <>
            <IconButtonDropdown icon={<MoreVertOutlinedIcon />}>
                <ContextMenuItem
                    icon={<AutorenewIcon />}
                    title={`Rotate`}
                    onClick={rotateWebhookSecret}
                    disabled={!isAdmin}
                />
                {enabled ? (
                    <ContextMenuItem
                        icon={<LinkOffOutlinedIcon />}
                        title={`Disable`}
                        onClick={changeWebhookStatus}
                        disabled={!isAdmin}
                    />
                ) : (
                    <ContextMenuItem
                        data-testid={`webhook-key-enable`}
                        icon={<LinkOutlinedIcon />}
                        title={`Enable`}
                        onClick={changeWebhookStatus}
                        disabled={!isAdmin}
                    />
                )}
                <ContextMenuItem
                    icon={<DeleteIcon />}
                    title={`Delete`}
                    onClick={deleteWebhook}
                    disabled={!isAdmin}
                />
            </IconButtonDropdown>
            {secretToken && (
                <SecretTokenModal
                    secretToken={secretToken}
                    onClose={() => {
                        setSecretToken(undefined)
                        return refetch()
                    }}
                />
            )}
        </>
    )
}

export default WebhookContextMenu
