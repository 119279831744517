import { ScrollableContainer } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import { FC, RefObject } from 'react'

import ProjectTimelineItem from 'views/Projects/Project/ProjectTimeline/ProjectTimelineItem'

const ProjectTimeline: FC<{
    timeline: Array<{
        year: number
        description: string
    }>
    scrollRef: RefObject<HTMLDivElement>
}> = ({ timeline, scrollRef }) => {
    return (
        <Box
            sx={{
                display: 'block',
                width: '100%',
                mt: 9,
                '.scroll-container > .MuiBox-root': {
                    alignItems: 'flex-end',
                },
            }}
        >
            <ScrollableContainer containerRef={scrollRef}>
                {timeline.map((item, index) => {
                    const { year, description } = item
                    return (
                        <ProjectTimelineItem
                            key={`timeline-${index}`}
                            year={year}
                            description={description}
                            lastItem={index === timeline.length - 1}
                        />
                    )
                })}
            </ScrollableContainer>
        </Box>
    )
}

export default ProjectTimeline
