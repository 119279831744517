import { Text } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'
import { FC } from 'react'

import { emissionsChartColors } from 'views/Dashboard/Charts/chartUtils'

const ShippingEmissionsChartLegend: FC = () => {
    const labels = [
        { label: 'Sea', color: emissionsChartColors.sea },
        { label: 'Air', color: emissionsChartColors.air },
        { label: 'Road', color: emissionsChartColors.road },
        { label: 'Rail', color: emissionsChartColors.rail },
        { label: 'Inland waterway', color: emissionsChartColors.inlandWaterway },
        { label: 'Logistics sites', color: emissionsChartColors.logisticSite },
    ]

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 2,
                flexWrap: 'wrap',
                gap: 1,
            }}
        >
            {labels.map(({ color, label }, index) => (
                <Box
                    key={index}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mr: 2,
                    }}
                >
                    <Box
                        sx={{
                            width: '20px',
                            height: '20px',
                            borderRadius: '100%',
                            backgroundColor: color,
                            mr: '12px',
                        }}
                    />
                    <Text variant={'body3'}>{label}</Text>
                </Box>
            ))}
        </Box>
    )
}

export default ShippingEmissionsChartLegend
