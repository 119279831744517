import { Box } from '@mui/material'
import { FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'

import SVGLogo from 'components/SVGLogo'
import GradientWrapper from 'views/Account/Shared/GradientWrapper'

const Layout: FC<{ children: ReactNode }> = ({ children }) => {
    return (
        <Box
            sx={{
                height: '100vh',
                overflow: 'hidden',
                overflowY: 'auto',
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'stretch',
                p: 2,
                pt: 0,
                a: {
                    textDecoration: 'none !important',
                },
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flex: '1 1 auto',
                    maxHeight: '72px',
                    minHeight: '72px',
                }}
            >
                <Link to={{ pathname: 'https://lune.co' }}>
                    <SVGLogo />
                </Link>
            </Box>
            <GradientWrapper>{children}</GradientWrapper>
        </Box>
    )
}

export default Layout
