import {
    CabinClass,
    PassengerFlightEstimateRequest,
    PassengerRailEstimateRequest,
    PassengerRoadEstimateRequest,
    PassengerTransportationEstimateRequest,
} from '@lune-climate/lune'
import * as icons from '@mui/icons-material'

import { assertType } from '../../../utils'

export const passengerTransportationRailLabels: Record<
    PassengerRailEstimateRequest['railType'],
    string
> = {
    [PassengerRailEstimateRequest.rail_type.NATIONAL_RAIL]: 'National rail',
    [PassengerRailEstimateRequest.rail_type.INTERNATIONAL_RAIL]: 'International rail',
    [PassengerRailEstimateRequest.rail_type.LIGHT_RAIL_OR_TRAM]: 'Light rail or tram',
}

export const passengerTransportationRoadLabels: Record<
    PassengerRoadEstimateRequest['vehicleType'],
    string
> = {
    [PassengerRoadEstimateRequest.vehicle_type.CAR_MINI]: 'Car mini',
    [PassengerRoadEstimateRequest.vehicle_type.CAR_SUPERMINI]: 'Car supermini',
    [PassengerRoadEstimateRequest.vehicle_type.CAR_LOWER_MEDIUM]: 'Car lower medium',
    [PassengerRoadEstimateRequest.vehicle_type.CAR_UPPER_MEDIUM]: 'Car upper medium',
    [PassengerRoadEstimateRequest.vehicle_type.CAR_EXECUTIVE]: 'Car executive',
    [PassengerRoadEstimateRequest.vehicle_type.CAR_LUXURY]: 'Car luxury',
    [PassengerRoadEstimateRequest.vehicle_type.CAR_SPORTS]: 'Car sports',
    [PassengerRoadEstimateRequest.vehicle_type.CAR_DUAL_PURPOSE_4X4]: 'Car dual purpose 4x4',
    [PassengerRoadEstimateRequest.vehicle_type.CAR_MPV]: 'Car mpv',
    [PassengerRoadEstimateRequest.vehicle_type.MOTORCYCLE_SMALL]: 'Motorcycle small',
    [PassengerRoadEstimateRequest.vehicle_type.MOTORCYCLE_MEDIUM]: 'Motorcycle medium',
    [PassengerRoadEstimateRequest.vehicle_type.MOTORCYCLE_LARGE]: 'Motorcycle large',
    [PassengerRoadEstimateRequest.vehicle_type.MOTORCYCLE_AVERAGE]: 'Motorcycle average',
}

const passengerTransportationFlightCabinLabels: Record<
    PassengerFlightEstimateRequest['cabinClass'],
    string
> = {
    [CabinClass.ECONOMY]: 'economy class',
    [CabinClass.BUSINESS]: 'business class',
    [CabinClass.FIRST]: 'first class',
}

export const passengerTransportationIcons: Record<
    PassengerTransportationTypes,
    icons.SvgIconComponent
> = {
    rail: icons.TrainOutlined,
    road: icons.DirectionsCarOutlined,
    air: icons.FlightOutlined,
}

type PassengerTransportationTypes = 'road' | 'rail' | 'air'
export function getPassengerTransportationType(
    leg: PassengerTransportationEstimateRequest['legs'][0],
): PassengerTransportationTypes {
    if ('railType' in leg) {
        return 'rail'
    } else if ('vehicleType' in leg) {
        return 'road'
    } else {
        assertType<PassengerFlightEstimateRequest>(leg)
        return 'air'
    }
}

export function getPassengerTransportationVehicleTypeLabel(
    leg: PassengerTransportationEstimateRequest['legs'][0],
): string {
    if ('railType' in leg) {
        return passengerTransportationRailLabels[leg.railType]
    } else if ('vehicleType' in leg) {
        return passengerTransportationRoadLabels[leg.vehicleType]
    } else {
        assertType<PassengerFlightEstimateRequest>(leg)
        return 'Passenger flight'
    }
}

export function getPassengerTransportationPassengerInfoLabel(
    nrPassengers: number,
    leg: PassengerTransportationEstimateRequest['legs'][0],
): string {
    const baseString = `${nrPassengers} passenger${nrPassengers > 1 ? 's' : ''}`
    if ('railType' in leg || 'vehicleType' in leg) {
        return baseString
    } else {
        assertType<PassengerFlightEstimateRequest>(leg)
        return `${baseString}, ${passengerTransportationFlightCabinLabels[leg.cabinClass]}`
    }
}
