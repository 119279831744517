import { useQuery, useQueryClient } from 'react-query'

import { getUserData } from 'endpoints/dapi'
import useToken from 'hooks/useToken'
import { queryKeys } from 'queryKeys'

import { IUserState } from '../UserState'

export function useWriteUserState() {
    const queryClient = useQueryClient()

    const setUserState = (userState: IUserState) => {
        queryClient.setQueryData(queryKeys.GET_USER, userState)
    }

    const refreshUserState = () => {
        queryClient.invalidateQueries(queryKeys.GET_USER)
    }

    return { setUserState, refreshUserState }
}

export default function useUserState() {
    const { token } = useToken()

    const { data: userState } = useQuery<IUserState | undefined>({
        queryKey: queryKeys.GET_USER,
        enabled: !!token,
        queryFn: async () => {
            return getUserData().then((data: any) => {
                return { user: data.user, account: data.account, isAdmin: data.isAdmin }
            })
        },
    })

    const { setUserState, refreshUserState } = useWriteUserState()
    return { userState, setUserState, refreshUserState }
}

/**
 * This is a rudimentary "centralisation" of the user permissions.
 * We can expand if/when permissions become more complex.
 */
export function useIsAuthorised() {
    const { userState } = useUserState()
    return userState?.isAdmin
}
