import { IconButtonDropdown } from '@lune-fe/lune-ui-lib'
import { FC, ReactElement } from 'react'

import ConditionalTooltip from './ConditionalTooltip'
import NotAdminTooltip from './NotAdminTooltip'

const ContextMenuItem = ({
    icon,
    title,
    onClick,
    disabled,
    tooltip,
}: {
    icon: ReactElement
    title: string
    onClick: () => void
    disabled?: boolean
    tooltip?: FC<{ children: ReactElement }>
}) => {
    return (
        <ConditionalTooltip show={!!disabled} tooltip={tooltip || NotAdminTooltip}>
            <IconButtonDropdown.MenuItem
                icon={icon}
                title={title}
                onClick={onClick}
                disabled={disabled}
            />
        </ConditionalTooltip>
    )
}

export default ContextMenuItem
