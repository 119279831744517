import { Project } from '@lune-climate/lune'
import { Chip, ScrollableContainer } from '@lune-fe/lune-ui-lib'
import { Avatar, Box } from '@mui/material'
import { FC, RefObject } from 'react'

export type LinkProps = NonNullable<Project['linksAndNews']>['links']

const Links: FC<{ links: LinkProps; scrollRef: RefObject<HTMLDivElement> }> = ({
    links,
    scrollRef,
}) => {
    return (
        <Box
            sx={{
                display: 'block',
                mt: 4,
                mb: 9,
                width: '100%',
            }}
        >
            <ScrollableContainer containerRef={scrollRef}>
                {links.map(({ destinationUrl, title, faviconUrl }, index) => {
                    return (
                        <div key={`link-${index}`}>
                            <Chip
                                sx={{ textDecoration: 'underline', mr: 1 / 2 }}
                                href={destinationUrl}
                                label={title}
                                {...(faviconUrl && {
                                    icon: (
                                        <Avatar
                                            alt={title}
                                            src={faviconUrl}
                                            sx={{ maxWidth: '24px', maxHeight: '24px' }}
                                        />
                                    ),
                                })}
                            />
                        </div>
                    )
                })}
            </ScrollableContainer>
        </Box>
    )
}

export default Links
