import { OrderBase, OrderByEstimate, OrderByQuantity, OrderByValue } from '@lune-climate/lune'
import {
    BackButton,
    Button,
    LoadingWrapper,
    LuneTheme,
    MainLayoutContainer,
    Text,
    Tile,
} from '@lune-fe/lune-ui-lib'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import { Box } from '@mui/material'
import Divider from '@mui/material/Divider'
import { AxiosError } from 'axios'
import { Big } from 'big.js'
import { sum } from 'lodash'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import useAccountCurrency from 'hooks/useAccountCurrency'
import useActiveOrganisation from 'hooks/useActiveOrganisation'
import useBuyOffsetsState from 'hooks/useBuyOffsetsState'
import { useLuneClient } from 'hooks/useLuneClient'
import useMixpanel from 'hooks/useMixpanel'
import useToken from 'hooks/useToken'
import useUserState from 'hooks/useUserState'
import OrderSummaryTable from 'views/BuyOffsets/OrderSummary/OrderSummaryTable'
import OrderSummaryTableFooter from 'views/BuyOffsets/OrderSummary/OrderSummaryTableFooter'
import OrderStatus, { Step } from 'views/Orders/Order/OrderStatus'
import OrderTitle from 'views/Orders/Order/OrderTitle'
import RetirementSummaryTable from 'views/Orders/Order/RetirementSummaryTable'

const Order = () => {
    const [loading, setLoading] = useState<boolean>(true)
    const [order, setOrder] = useState<OrderByQuantity | OrderByValue | OrderByEstimate>()
    const { orderId } = useParams<{ orderId: string }>()
    const { token } = useToken()
    const { palette } = LuneTheme
    const location = useLocation()
    const { resetBuyOffsetProps } = useBuyOffsetsState()

    const navigate = useNavigate()
    const toCurrency = useAccountCurrency()
    const { enqueueSnackbar: snackbar } = useSnackbar()

    const { userState } = useUserState()
    const { activeOrgUsers } = useActiveOrganisation()
    const luneClient = useLuneClient()
    const mixpanel = useMixpanel()

    useEffect(() => {
        // 'success' state is set in a successful buy offsets flow
        // If we're coming from buyOffsets flow we need to reset all props here
        if (location.state?.success) {
            resetBuyOffsetProps()
        }
        // disabling this to prevent infinite calls of resetBuyOffsetProps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (location.state?.success) {
            mixpanel.track('order_summary_viewed', { orderStatus: order?.status })
        } else {
            mixpanel.track('order_confirmation_viewed')
        }
    }, [location.state?.success, mixpanel, order?.status])

    useEffect(() => {
        if (!orderId) {
            return
        }

        luneClient
            .getOrder(orderId)
            .then((res) => {
                setOrder(res.unwrap())
            })
            .catch((e) => {
                if (e instanceof AxiosError && e.status === 404) {
                    navigate(`/orders`)
                    snackbar(`Requested order does not exist!`)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }, [navigate, orderId, snackbar, luneClient])

    const retiredCredits = useMemo(() => {
        return sum(
            order?.projects
                ?.filter(({ status }) => status === 'retired')
                .map(({ quantity }) => Number(quantity)),
        )
    }, [order])

    const orderStatusStep = useMemo(() => {
        return order?.status === OrderBase.status.RECEIVED
            ? Step.RECEIVED
            : order?.status === OrderBase.status.PLACED
              ? Step.PLACED
              : order?.status === OrderBase.status.PAID
                ? Step.PAID
                : order?.status === OrderBase.status.RETIRING
                  ? Step.RETIRING
                  : order?.status === OrderBase.status.COMPLETE
                    ? Step.COMPLETE
                    : order?.status === OrderBase.status.CANCELLED
                      ? Step.CANCELLED
                      : Step.FAILED
    }, [order])

    const user = useMemo(() => {
        return activeOrgUsers?.find((u) => u.user.id === userState?.user.id)?.user
    }, [userState, activeOrgUsers])

    const onDownloadCarbonCertificate = () => {
        const href = `${
            process.env.REACT_APP_API_URL
        }/v1/orders/${order?.id}/certificate?access_token=${encodeURIComponent(token!)}`
        mixpanel.track('certificate_downloaded')
        window.open(href, '_blank')
    }

    return (
        <LoadingWrapper loading={loading || !user} sx={{ height: '100%' }}>
            {order && (
                <MainLayoutContainer
                    headerComponent={
                        <>
                            <BackButton
                                sx={{ mb: 3, ml: -2 }}
                                onClick={() => {
                                    // Preserve the search params from the previous page if any were passed.
                                    //
                                    // location.state doesn't actually have to be defined (we may have only
                                    // just landed on this page without going through any other pages)
                                    // in which case its value is null and we have to handle that.
                                    return navigate(
                                        `/orders?${location.state?.originSearchParams ?? ''}`,
                                    )
                                }}
                                data-testid="order-details-back-button"
                            />
                            <Text variant={'h6'}>
                                Order #{order.id} •{' '}
                                {moment(order.createdAt).format('DD MMMM YYYY, hh:mm a')}
                            </Text>
                            <OrderTitle
                                orderStatusStep={orderStatusStep}
                                user={user}
                                retiredCredits={retiredCredits}
                                order={order}
                            />
                            <OrderStatus orderStatusStep={orderStatusStep} />
                        </>
                    }
                >
                    {orderStatusStep === Step.FAILED ? (
                        <Button
                            onClick={() => window.open('mailto:support@lune.co', '_blank')}
                            leftIcon={<MailOutlineIcon />}
                        >
                            Contact us
                        </Button>
                    ) : (
                        <Button
                            data-testid="download-certificate-btn"
                            onClick={onDownloadCarbonCertificate}
                            disabled={
                                orderStatusStep !== Step.RETIRING &&
                                orderStatusStep !== Step.COMPLETE
                            }
                            leftIcon={<FileDownloadOutlinedIcon />}
                        >
                            Download the Carbon Offset Certificate
                        </Button>
                    )}
                    <Box
                        sx={{
                            mb: 9,
                            mt: 9,
                            backgroundColor: palette.Grey300,
                            height: '1px',
                            width: '100%',
                        }}
                    />
                    {orderStatusStep > Step.PLACED && order.projects && (
                        <RetirementSummaryTable order={order} />
                    )}
                    <Text variant={'h6'} sx={{ mb: 4 }}>
                        Order recap
                    </Text>

                    <OrderSummaryTable
                        bundlesInfo={
                            order.bundles?.map((b) => ({
                                id: b.bundleId,
                                primaryImage: b.primaryImage!,
                                name: b.bundleName,
                                quantity: parseFloat(b.quantity),
                                grossUnitPrice: Big(b.grossUnitPrice),
                            })) || []
                        }
                    />
                    <OrderSummaryTableFooter
                        cost={order.offsetCost!}
                        fees={order.commission!}
                        quantity={order.quantity!}
                    />
                    <Divider
                        sx={{
                            my: 4,
                        }}
                    />
                    <Tile
                        rightSide={
                            <Text
                                data-testid="order-total"
                                sx={{
                                    color: palette.Grey900,
                                    fontWeight: `700`,
                                    fontSize: `16px`,
                                }}
                            >
                                {toCurrency?.(order.totalCost ?? undefined) || ''}
                            </Text>
                        }
                    >
                        <Text
                            variant={'body2'}
                            sx={{ color: palette.Grey900, fontWeight: `700`, fontSize: `16px` }}
                        >
                            Total
                        </Text>
                    </Tile>
                </MainLayoutContainer>
            )}
        </LoadingWrapper>
    )
}

export default Order
