import { Address, AirportCode, Distance, GeographicCoordinates, Locode } from '@lune-climate/lune'
import { LuneTheme } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import { Map as ReactGlMap } from 'mapbox-gl'
import { FC, useEffect, useMemo, useState } from 'react'
import ReactMapGL, { Marker } from 'react-map-gl'

import { getDistanceToKM } from '../../utils/utils'
import {
    convertDDCoordinatesToDMS,
    formatAddress,
} from '../emissionsCalculationExplanation/utils/logisticsUtils'

import { coordinatesToMapboxPosition, routeWithIntermediatePoints } from './mapWithRouteUtils'

import 'mapbox-gl/dist/mapbox-gl.css'

export interface AnimatedMapMarker {
    title: string
    subtitle: string
    iconSrc: string
    lat: number
    lng: number
    active?: boolean
}

const MapMarker: FC<{
    label: string
    latitude: number
    longitude: number
}> = ({ label, latitude, longitude }) => {
    return (
        <Marker anchor="bottom-left" latitude={latitude} longitude={longitude}>
            <Box
                sx={{
                    background: ' rgb(0, 0, 0)',
                    borderRadius: '4px',
                    color: 'white',
                    opacity: 1,
                    fontSize: '12px',
                    maxWidth: '200px',
                    padding: '6px',
                    position: 'relative',
                    transform: 'translateX(-50%)',
                    mb: '12px',
                    '&:after': {
                        content: '""',
                        width: '8px',
                        height: '8px',
                        backgroundColor: 'black',
                        position: 'absolute',
                        bottom: '-4px',
                        left: '0px',
                        right: '0px',
                        margin: 'auto',
                        zIndex: '-1',
                        transform: 'rotate(45deg)',
                    },
                    '&:before': {
                        content: '""',
                        width: '8px',
                        height: '8px',
                        backgroundColor: 'black',
                        position: 'absolute',
                        bottom: '-16px',
                        left: '0px',
                        right: '0px',
                        margin: 'auto',
                        zIndex: '-1',
                        borderRadius: '100%',
                        border: '2px solid white',
                    },
                }}
            >
                {label}
            </Box>
        </Marker>
    )
}

const formatSourceAndDestinationLabels = (
    location: Address | GeographicCoordinates | Locode | AirportCode,
): string => {
    // this will not be used now, because map will not be shown when user inputs addresses
    // as stated in this ticket: https://linear.app/lune/issue/LUN-2799/create-a-map-component-route-map-visualising-dummy-data-in-emission
    if ('streetLine1' in location && location.streetLine1) {
        return formatAddress(location)
    }
    if ('lat' in location) {
        return `${convertDDCoordinatesToDMS(location.lat)}, ${convertDDCoordinatesToDMS(
            location.lon,
            true,
        )}`
    }
    if ('locode' in location) {
        return location.locode
    }
    if ('code' in location) {
        return location.code
    }
    return ''
}

export interface MapWithRouteProps {
    mapboxAccessToken: string
    source: Address | GeographicCoordinates | Locode | AirportCode
    destination: Address | GeographicCoordinates | Locode | AirportCode
    /**
     * There have to be at least two items in the array.
     *
     * Providing fewer than two is a programming error and will result in an exception.
     */
    route: GeographicCoordinates[]
    distance: Distance
}

export const MapWithRoute: FC<MapWithRouteProps> = ({
    mapboxAccessToken,
    source,
    destination,
    route,
    distance,
}) => {
    const [mapElement, setMapElement] = useState<ReactGlMap>()
    const [mapLoaded, setMapLoaded] = useState<boolean>(false)
    const { palette } = LuneTheme

    if (route.length < 2) {
        throw new Error(`The route has to have at least two items`)
    }
    const sourceLngAndLat = route[0]
    const destLngAndLat = route.at(-1)!
    const middleLngAndLat = route.length > 2 ? route[Math.floor(route.length / 2)] : sourceLngAndLat

    const sourceLabel = formatSourceAndDestinationLabels(source)
    const destinationLabel = formatSourceAndDestinationLabels(destination)

    // A method to calculate zoom level based on route distance
    // Generated by chatGPT
    const calculatedZoom = useMemo((): number => {
        const distanceToKm = getDistanceToKM(distance).toNumber()
        const a: number = 10 / (Math.log(4) - Math.log(1000))
        const b: number = 14 - a * Math.log(4)

        const rawZoom: number = a * Math.log(distanceToKm) + b
        return Math.max(0, Math.min(20, rawZoom))
    }, [distance])

    useEffect(() => {
        if (mapElement) {
            // In multi-leg shipping estimates we're effectively reusing the same map component
            // (to some extent) between multiple legs.
            //
            // That means that when switching between legs we need to clean up the previous leg's
            // data before adding our own.
            //
            // If we just call addSource() unconditionally the application will crash.
            //
            // We have to silence eslint too because the types are wrong and getSource()
            // can return undefined.
            //
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (mapElement.getSource('route') !== undefined) {
                // The layer has to be removed before the source as the layer uses the source
                // and we'd get an exception otherwise.
                mapElement.removeLayer('route')
                mapElement.removeSource('route')
            }

            mapElement.addSource('route', {
                type: 'geojson',
                data: {
                    type: 'Feature',
                    properties: {},
                    geometry: {
                        type: 'LineString',
                        coordinates: routeWithIntermediatePoints(route).map((location) =>
                            coordinatesToMapboxPosition(location),
                        ),
                    },
                },
            })
            mapElement.addLayer({
                id: 'route',
                type: 'line',
                source: 'route',
                layout: {
                    'line-join': 'round',
                    'line-cap': 'round',
                },
                paint: {
                    'line-color': '#000000',
                    'line-width': 2,
                },
            })
            mapElement.flyTo({
                zoom: calculatedZoom,
                center: [middleLngAndLat.lon, middleLngAndLat.lat],
            })
        }
    }, [mapElement, route, calculatedZoom, middleLngAndLat])

    return (
        <Box
            sx={{
                height: '500px',
                width: '100%',
                overflow: 'hidden',
                position: 'relative',
                '.mapboxgl-canvas': {
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 'absolute',
                    width: '100%',
                    height: '100%',
                },
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    background: !mapLoaded ? palette.Grey300 : 'transparent',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    opacity: !mapLoaded ? 1 : 0,
                    transition: 'all .3s ease-in',
                }}
            />
            <ReactMapGL
                onLoad={(map) => {
                    setMapElement(map.target)
                    setMapLoaded(map.target.loaded())
                }}
                style={{
                    height: '100%',
                    width: '100%',
                    opacity: mapLoaded ? 1 : 0,
                    transition: 'opacity .3s cubic-bezier(0.25, 0.1, 0.25, 1)',
                    borderRadius: '32px',
                }}
                mapboxAccessToken={mapboxAccessToken}
                mapStyle={'mapbox://styles/mapbox/navigation-day-v1'}
                minZoom={1}
                maxZoom={20}
                initialViewState={{
                    zoom: calculatedZoom,
                    longitude: middleLngAndLat.lon,
                    latitude: middleLngAndLat.lat,
                }}
            >
                <MapMarker
                    latitude={sourceLngAndLat.lat}
                    longitude={sourceLngAndLat.lon}
                    label={`From ${sourceLabel}`}
                />
                <MapMarker
                    latitude={destLngAndLat.lat}
                    longitude={destLngAndLat.lon}
                    label={`To ${destinationLabel}`}
                />
            </ReactMapGL>
        </Box>
    )
}

export default MapWithRoute
