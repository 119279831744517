/* eslint-disable camelcase */
import { Error } from '@lune-climate/lune'

export enum SnackbarMessages {
    SETTINGS_SAVED = 'Settings saved',
    SETTINGS_UPDATE_FAILED = ' Settings update failed',
    ACCOUNT_CHANGE = 'Account changed',
    ACCOUNT_CREATE = 'Account created',
    ORGANISATION_NAME_UPDATE_FAIL = 'Organisation name cannot be empty',
    ORGANISATION_BENEFICIARY_UPDATE_FAIL = 'Beneficiary cannot be empty',
    INVITE_TEAM_MEMBER_INVALID_EMAIL = 'Please add at least one valid email address',
    INVITE_TEAM_MEMBER_SUCCESS = 'Invitation sent',
    INVITE_TEAM_MEMBER_FAIL = 'We couldn’t send the invitation. Contact us if this keeps happening.',
    MANAGE_TEAM_REMOVE_USER_SUCCESS = 'User successfully removed',
    MANAGE_TEAM_REMOVE_USER_FAIL = 'We couldn’t remove the user. Contact us if this keeps happening.',
    MANAGE_TEAM_REMOVE_INVITE_SUCCESS = 'Invited user successfully removed',
    MANAGE_TEAM_REMOVE_INVITE_FAIL = 'We couldn’t remove the invited user. Contact us if this keeps happening.',
    MANAGE_TEAM_UPDATE_ROLE_FAIL = `We couldn’t change the user role. Contact us if this keeps happening.`,
    ORGANISATION_LOGO_UPLOAD_SUCCESS = 'Logo successfully uploaded',
    ORGANISATION_LOGO_UPLOAD_FAIL = 'We couldn’t upload the logo. Contact us if this keeps happening.',
    PAYMENT_METHOD_ADD_SUCCESS = 'Payment method successfully added',
    PAYMENT_METHOD_ADD_FAIL = `We couldn't add your payment method. Contact us if this keeps happening.`,
    PAYMENT_METHOD_REMOVE_SUCCESS = 'Payment method successfully removed',
    PROJECT_NOT_FOUND = `Project not found.`,
    API_KEY_DELETE_SUCCESS = 'API key successfully deleted',
    API_KEY_DELETE_FAIL = 'Failed to delete API key',
    API_KEY_ROTATE_FAIL = 'Failed to rotate API key',
    API_KEY_UPDATE_FAIL = 'Failed to update API key',
    API_KEY_UPDATE_SUCCESS = 'API key successfully updated',
    WEBHOOK_DELETE_SUCCESS = 'Webhook successfully deleted',
    WEBHOOK_DELETE_FAIL = 'Failed to delete Webhook',
    WEBHOOK_ROTATE_FAIL = 'Failed to rotate Webhook',
    WEBHOOK_UPDATE_FAIL = 'Failed to update Webhook',
    WEBHOOK_UPDATE_SUCCESS = 'Webhook successfully updated',
    REQUIRED_FIELD = 'This is a required field',
    PASSWORD_RESET_LINK_SENT = 'Reset link sent',
    PASSWORD_RESET_SUCCESS = 'Password has been reset. Please login.',
    PASSWORD_RESET_LINK_EXPIRED = 'The reset link has expired. Please start again',
    PASSWORD_PATTERN = 'Password requires at least one uppercase character, one lowercase character, one numeric character and be at least 6 characters long',
    EMAIL_PATTERN_FAIL = 'Email is not formatted correctly',
    PASSWORD_MATCH_FAIL = 'The passwords do not match',
    EMAIL_VERIFICATION_FAIL = 'Could not verify your email',
    LOAD_ANALYTICS_FAIL = 'Failed to load analytics for the selected date range',
    GENERIC_ERROR = 'Something went wrong. Contact us if this keeps happening.',
    RATE_LIMIT_ERROR = `You've exceeded your assigned rate limit. Please retry in a few seconds.`,
}

// transform API error code to a message fit for display
function errorCodeToMessageMap(
    code: Error.error_code,
): { key: string; message: string } | undefined {
    switch (code) {
        case Error.error_code.ORDER_QUANTITY_INVALID:
            return {
                key: Error.error_code.ORDER_QUANTITY_INVALID,
                message: 'Increase purchase amount or turn off "Round Down" in Advanced Settings',
            }
        case Error.error_code.BUNDLE_SELECTION_RATIOS_INVALID:
            return {
                key: Error.error_code.BUNDLE_SELECTION_RATIOS_INVALID,
                message: 'Please add up to 100%',
            }
        default:
            return undefined
    }
}

export function mapErrorsToMessage(
    errors: Error[] | undefined,
    defaultMessage: string = SnackbarMessages.GENERIC_ERROR,
): { key: string; message: string } {
    if (!errors) {
        return {
            key: '',
            message: defaultMessage,
        }
    }

    // For now, we only return at most one error, therefore display the first.
    return (
        errorCodeToMessageMap(errors[0].errorCode) ?? {
            key: '',
            message: defaultMessage,
        }
    )
}
