import { Bundle } from '@lune-climate/lune'
import { Input } from '@lune-fe/lune-ui-lib'
import { Big } from 'big.js'
import _ from 'lodash'
import { FC, memo, useEffect, useState } from 'react'
import { debounce } from 'throttle-debounce'

import { Allocation, BundleWithVolume } from 'views/BuyOffsets/BuyOffsetsTypes'

const BundleAmountInput: FC<{
    bundle: Bundle
    volume: number
    allocation: Allocation[]
    onBundlesVolumeChange: (items: BundleWithVolume[]) => void
}> = ({ bundle, volume, allocation, onBundlesVolumeChange }) => {
    const [displayVolume, setDisplayVolume] = useState<string>(volume.toString())

    useEffect(() => {
        const volumeAsString = volume.toString()
        if (volumeAsString !== displayVolume) {
            setDisplayVolume(volumeAsString)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [volume, allocation])

    const updateBuyOffsetPropsDebounced = debounce(
        250,
        (val) => {
            const updatedBundles: BundleWithVolume[] = allocation.map((a) => {
                if (a.bundle.id === bundle.id) {
                    return {
                        bundleId: a.bundle.id,
                        volume: val,
                    }
                } else {
                    return {
                        bundleId: a.bundle.id,
                        volume: a.volume,
                    }
                }
            })
            onBundlesVolumeChange(updatedBundles)
        },
        { atBegin: true },
    )

    return (
        <Input
            value={displayVolume}
            highlightOnFocus
            endAdornment={'tCO₂'}
            onChange={(e) => {
                const val = e.target.value
                try {
                    Big(val)
                    updateBuyOffsetPropsDebounced(val)
                    setDisplayVolume(val)
                } catch {
                    updateBuyOffsetPropsDebounced(0)
                    setDisplayVolume('0')
                }
            }}
            sx={{
                input: {
                    textAlign: 'right !important',
                },
            }}
            data-testid={`bundle-volume-input-${bundle.id}`}
        />
    )
}

export default memo(BundleAmountInput, (prev, next) => {
    return _.isEqual(prev.volume, next.volume) && _.isEqual(prev.allocation, next.allocation)
})
