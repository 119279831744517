import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { memo } from 'react'

import AddressInputs from 'views/CalculateEmissions/Logistics/Route/AddressInputs'
import CalculateEmissionsLabeledFormSection from 'views/CalculateEmissions/Shared/CalculateEmissionsLabeledFormSection'

const RouteAsAddress = () => {
    return (
        <Box>
            <Stack spacing={{ xs: 4 }} sx={{ mt: 4 }}>
                <CalculateEmissionsLabeledFormSection label={'Origin'}>
                    <AddressInputs source />
                </CalculateEmissionsLabeledFormSection>
                <CalculateEmissionsLabeledFormSection label={'Destination'}>
                    <AddressInputs />
                </CalculateEmissionsLabeledFormSection>
            </Stack>
        </Box>
    )
}

export default memo(RouteAsAddress)
