import { useQuery } from 'react-query'

import { getEmissionFactorVersions } from 'endpoints/dapi'
import { queryKeys } from 'queryKeys'

const useEmissionFactorVersions = (id: string | undefined) => {
    const {
        isLoading: loading,
        data: emissionFactorVersions,
        refetch,
    } = useQuery<Awaited<ReturnType<typeof getEmissionFactorVersions>> | undefined>(
        [queryKeys.GET_EMISSION_FACTOR_VERSIONS, id],
        () => (id ? getEmissionFactorVersions(id) : undefined),
        {
            enabled: !!id,
            retry: false,
        },
    )

    return {
        refetch,
        loading,
        emissionFactorVersions,
    }
}

export default useEmissionFactorVersions
