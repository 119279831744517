import { Project } from '@lune-climate/lune'
import { useSnackbar } from 'notistack'
import { useQuery } from 'react-query'

import { dontHandle, useLuneClient } from 'hooks/useLuneClient'
import { queryKeys } from 'queryKeys'
import { SnackbarMessages } from 'SnackbarMessages'

const useProject = (slug: string | undefined) => {
    const { enqueueSnackbar: snackbar } = useSnackbar()
    const luneClient = useLuneClient()

    const getProjectBySlug = (slug: string) =>
        luneClient.getProjectBySlug(slug, undefined, dontHandle([404])).then((res) => {
            if (res.isOk()) {
                return res.value
            } else {
                const e = res.error
                if ('statusCode' in e && e.statusCode === 404) {
                    snackbar(SnackbarMessages.PROJECT_NOT_FOUND)
                } else {
                    snackbar(SnackbarMessages.GENERIC_ERROR)
                }
            }
        })

    const {
        isLoading: projectLoading,
        data: project,
        refetch,
    } = useQuery<Project | undefined>([queryKeys.GET_PROJECT_BY_SLUG, slug], () =>
        slug ? getProjectBySlug(slug) : undefined,
    )

    return slug
        ? {
              projectLoading,
              project,
              refetch,
          }
        : {
              projectLoading: false,
              project: undefined,
          }
}

export default useProject
