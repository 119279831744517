import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import type { SxProps } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import React, { FC, useCallback } from 'react'

import Textarea from './Textarea'
import Tile from './Tile'

export interface MultiSelectProps<T> {
    options: T[]
    getOptionLabel: (option: T) => string
    onChange: (value: readonly T[]) => void
    value?: T[]
    sx?: SxProps
    disabled?: boolean
    label?: string
    error?: string | boolean
    showAllSelectedItems?: boolean
    loadingOptions?: boolean
}

const AutocompleteMultiSelect = <T,>({
    options,
    getOptionLabel,
    onChange,
    value,
    sx,
    disabled,
    label,
    error,
    showAllSelectedItems,
    loadingOptions,
    ...rest
}: MultiSelectProps<T>): React.ReactElement => {
    const { spacing, palette } = useTheme()

    const CustomPaper: FC = useCallback((props) => {
        return (
            <Paper
                {...props}
                elevation={4}
                sx={{
                    minWidth: '300px',
                    mt: 1,
                    borderRadius: '8px',
                    '.MuiAutocomplete-listbox': {
                        padding: `${spacing(2)} 0`,
                        '& .MuiAutocomplete-option': {
                            '&[aria-selected="true"]': {
                                backgroundColor: `${palette.Grey100} !important`,
                            },
                        },
                    },
                }}
            />
        )
    }, [])

    return (
        <Autocomplete
            noOptionsText={
                <Box
                    sx={{
                        marginLeft: '-16px !important',
                        marginRight: '-16px !important',
                        padding: `0 ${spacing(2)} !important`,
                        height: '48px',
                        lineHeight: '48px',
                    }}
                >
                    <Typography variant={`body3`}>
                        {loadingOptions ? 'Loading options...' : 'No options'}
                    </Typography>
                </Box>
            }
            PaperComponent={CustomPaper}
            multiple
            options={loadingOptions ? [] : options}
            onChange={(event, selectedOptions) => onChange(selectedOptions)}
            value={value}
            disabled={disabled}
            disableCloseOnSelect={true}
            getOptionLabel={getOptionLabel}
            limitTags={showAllSelectedItems ? 20 : 1}
            getLimitTagsText={(more) => `+${more}`}
            renderInput={(params) => (
                <Textarea
                    wide
                    rows={1}
                    sx={{
                        textarea: {
                            width: '0 !important',
                            padding: '0 !important',
                        },
                    }}
                    error={error}
                    {...params}
                    placeholder={label}
                />
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        sx={{
                            backgroundColor: palette.Grey900,
                            color: palette.White,
                            borderRadius: '4px',
                            fontSize: '14px',
                        }}
                        label={getOptionLabel(option)}
                        deleteIcon={<ClearOutlinedIcon style={{ color: palette.White }} />}
                        {...getTagProps({ index })}
                        key={index}
                    />
                ))
            }
            renderOption={(props, option, state) => (
                <MenuItem
                    {...props}
                    sx={{
                        padding: `0 ${spacing(2)} !important`,
                        height: '48px',
                    }}
                >
                    <Tile rightIcon={state.selected ? <CheckOutlinedIcon /> : <></>}>
                        <Typography variant={`body3`}>{getOptionLabel(option)}</Typography>
                    </Tile>
                </MenuItem>
            )}
            {...rest}
            sx={{
                width: spacing(20),
                borderRadius: `8px`,
                position: 'relative',
                '.MuiFormControl-root': {
                    border: `none !important`,
                },
                '& > div > div': {
                    paddingRight: '88px !important',
                    ...(!showAllSelectedItems && {
                        flexWrap: 'nowrap  !important',
                    }),
                },
                '&:focus-within': {
                    '& > div > div': {
                        flexWrap: 'wrap !important',
                    },
                },
                ...(!showAllSelectedItems && {
                    '& > div > div': {
                        flexWrap: 'wrap !important',
                    },
                    '&:focus-within': {
                        height: spacing(7),
                        position: 'relative',
                        '.MuiFormControl-root': {
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 9,
                        },
                    },
                }),
                ...sx,
            }}
        />
    )
}

export default AutocompleteMultiSelect
