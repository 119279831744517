import { useSnackbar } from 'notistack'

import { patchOrganisation } from 'endpoints/dapi'
import useActiveOrganisation from 'hooks/useActiveOrganisation'
import useMixpanel from 'hooks/useMixpanel'
import { SnackbarMessages } from 'SnackbarMessages'
import BeneficiaryModal from 'views/BuyOffsets/OrderSummary/Beneficiary/BeneficiaryModal'

const OrganisationBeneficiary = () => {
    const mixpanel = useMixpanel()
    const { enqueueSnackbar: snackbar } = useSnackbar()
    const { activeOrg, refetchActiveOrg } = useActiveOrganisation()

    const onSave = (beneficiary: string) =>
        patchOrganisation(activeOrg?.id || ``, {
            beneficiary,
        })
            .then(() => {
                refetchActiveOrg()
                snackbar(SnackbarMessages.SETTINGS_SAVED)
                if (beneficiary !== activeOrg?.beneficiary) {
                    mixpanel.track('order_summary_org_beneficiary_saved')
                }
            })
            .catch(() => {
                snackbar(SnackbarMessages.SETTINGS_UPDATE_FAILED)
            })

    return (
        <BeneficiaryModal
            key={activeOrg?.beneficiary}
            beneficiary={activeOrg?.beneficiary}
            title={`Organisation beneficiary`}
            subTitle={`Applies to all purchases made with this organisation`}
            onSave={onSave}
        />
    )
}

export default OrganisationBeneficiary
