import useMediaQuery from '@mui/material/useMediaQuery'

import { LuneTheme } from '../theme'

export const useIsMobile = () => {
    const checkMobile = () => {
        const theme = LuneTheme
        return useMediaQuery(theme.breakpoints.down('sm'))
    }

    const isMobile = checkMobile()

    return { isMobile }
}

export default useIsMobile
