import { styled } from '@mui/material/styles'
import MUITooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import React, { FC } from 'react'

import { LuneTheme } from '../theme'

type ITooltip = TooltipProps & {
    contentWidth?: boolean
}

const Tooltip: FC<ITooltip & { style?: React.CSSProperties }> = (props) => {
    const { palette, typography } = LuneTheme

    const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
        <MUITooltip {...props} arrow classes={{ popper: className }} />
    ))(() => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: palette.Grey900,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            ...typography.caption,
            backgroundColor: palette.Grey900,
            padding: '8px',
            borderRadius: '4px',
        },
    }))

    return (
        <StyledTooltip {...props} data-testid="tooltip">
            <span
                style={{
                    display: 'block',
                    maxWidth: props.contentWidth ? 'max-content' : 'none',
                    ...(props.style ? props.style : {}),
                }}
            >
                {props.children}
            </span>
        </StyledTooltip>
    )
}

export const TooltipList: FC<{ items: React.ReactNode[] | string[] }> = ({ items }) => {
    return (
        <ul
            style={{
                listStyleType: 'disc',
                marginLeft: '12px',
                paddingLeft: '0',
                fontSize: 'small',
            }}
        >
            {items.map((item, i) => (
                <li
                    style={{
                        marginLeft: '8px',
                        lineHeight: '16px',
                    }}
                    key={i}
                >
                    {item}
                </li>
            ))}
        </ul>
    )
}

export default Tooltip
