const airports = [
    ['GKA', 'Goroka Airport'],
    ['MAG', 'Madang Airport'],
    ['HGU', 'Mount Hagen Kagamuga Airport'],
    ['LAE', 'Nadzab Airport'],
    ['POM', 'Port Moresby Jacksons International Airport'],
    ['WWK', 'Wewak International Airport'],
    ['UAK', 'Narsarsuaq Airport'],
    ['GOH', 'Godthaab / Nuuk Airport'],
    ['SFJ', 'Kangerlussuaq Airport'],
    ['THU', 'Thule Air Base'],
    ['AEY', 'Akureyri Airport'],
    ['EGS', 'Egilsstaðir Airport'],
    ['HFN', 'Hornafjörður Airport'],
    ['HZK', 'Húsavík Airport'],
    ['IFJ', 'Ísafjörður Airport'],
    ['KEF', 'Keflavik International Airport'],
    ['PFJ', 'Patreksfjörður Airport'],
    ['RKV', 'Reykjavik Airport'],
    ['SIJ', 'Siglufjörður Airport'],
    ['VEY', 'Vestmannaeyjar Airport'],
    ['YAM', 'Sault Ste Marie Airport'],
    ['YAY', 'St. Anthony Airport'],
    ['YAZ', 'Tofino / Long Beach Airport'],
    ['YBB', 'Kugaaruk Airport'],
    ['YBC', 'Baie Comeau Airport'],
    ['YBG', 'CFB Bagotville'],
    ['YBK', 'Baker Lake Airport'],
    ['YBL', 'Campbell River Airport'],
    ['YBR', 'Brandon Municipal Airport'],
    ['YCB', 'Cambridge Bay Airport'],
    ['YCD', 'Nanaimo Airport'],
    ['YCG', 'Castlegar/West Kootenay Regional Airport'],
    ['YCH', 'Miramichi Airport'],
    ['YCL', 'Charlo Airport'],
    ['YCO', 'Kugluktuk Airport'],
    ['YCT', 'Coronation Airport'],
    ['YCW', 'Chilliwack Airport'],
    ['YCY', 'Clyde River Airport'],
    ['YZS', 'Coral Harbour Airport'],
    ['YDA', 'Dawson City Airport'],
    ['YDB', 'Burwash Airport'],
    ['YDF', 'Deer Lake Airport'],
    ['YDL', 'Dease Lake Airport'],
    ['YDN', 'Dauphin Barker Airport'],
    ['YDQ', 'Dawson Creek Airport'],
    ['YEG', 'Edmonton International Airport'],
    ['YEK', 'Arviat Airport'],
    ['YEN', 'Estevan Airport'],
    ['YET', 'Edson Airport'],
    ['YEU', 'Eureka Airport'],
    ['YEV', 'Inuvik Mike Zubko Airport'],
    ['YFB', 'Iqaluit Airport'],
    ['YFC', 'Fredericton Airport'],
    ['YFE', 'Forestville Airport'],
    ['YFO', 'Flin Flon Airport'],
    ['YFR', 'Fort Resolution Airport'],
    ['YFS', 'Fort Simpson Airport'],
    ['YGK', 'Kingston Norman Rogers Airport'],
    ['YGL', 'La Grande Rivière Airport'],
    ['YGP', 'Gaspé (Michel-Pouliot) Airport'],
    ['YGQ', 'Geraldton Greenstone Regional Airport'],
    ['YGR', 'Îles-de-la-Madeleine Airport'],
    ['YHB', 'Hudson Bay Airport'],
    ['YHD', 'Dryden Regional Airport'],
    ['YHI', 'Ulukhaktok Holman Airport'],
    ['YHK', 'Gjoa Haven Airport'],
    ['YHM', 'John C. Munro Hamilton International Airport'],
    ['YHU', 'Montréal / Saint-Hubert Airport'],
    ['YHY', 'Hay River / Merlyn Carter Airport'],
    ['YHZ', 'Halifax / Stanfield International Airport'],
    ['YIB', 'Atikokan Municipal Airport'],
    ['YIO', 'Pond Inlet Airport'],
    ['YJN', 'St Jean Airport'],
    ['YJT', 'Stephenville Airport'],
    ['YKA', 'Kamloops Airport'],
    ['YKF', 'Waterloo Airport'],
    ['YKL', 'Schefferville Airport'],
    ['YKY', 'Kindersley Airport'],
    ['YKZ', 'Buttonville Municipal Airport'],
    ['YLD', 'Chapleau Airport'],
    ['YLJ', 'Meadow Lake Airport'],
    ['YLL', 'Lloydminster Airport'],
    ['YLT', 'Alert Airport'],
    ['YLW', 'Kelowna International Airport'],
    ['YMA', 'Mayo Airport'],
    ['YMJ', 'Moose Jaw Air Vice Marshal C. M. McEwen Airport'],
    ['YMM', 'Fort McMurray Airport'],
    ['YMO', 'Moosonee Airport'],
    ['YMW', 'Maniwaki Airport'],
    ['YMX', 'Montreal International (Mirabel) Airport'],
    ['YNA', 'Natashquan Airport'],
    ['YND', 'Ottawa / Gatineau Airport'],
    ['YNM', 'Matagami Airport'],
    ['YOC', 'Old Crow Airport'],
    ['YOD', 'CFB Cold Lake'],
    ['YOJ', 'High Level Airport'],
    ['YOW', 'Ottawa Macdonald-Cartier International Airport'],
    ['YPA', 'Prince Albert Glass Field'],
    ['YPE', 'Peace River Airport'],
    ['YPG', 'Southport Airport'],
    ['YPL', 'Pickle Lake Airport'],
    ['YPN', 'Port Menier Airport'],
    ['YPQ', 'Peterborough Airport'],
    ['YPR', 'Prince Rupert Airport'],
    ['YPY', 'Fort Chipewyan Airport'],
    ['YQA', 'Muskoka Airport'],
    ['YQB', 'Quebec Jean Lesage International Airport'],
    ['YQF', 'Red Deer Regional Airport'],
    ['YQG', 'Windsor Airport'],
    ['YQH', 'Watson Lake Airport'],
    ['YQK', 'Kenora Airport'],
    ['YQL', 'Lethbridge County Airport'],
    ['YQM', 'Greater Moncton International Airport'],
    ['YQQ', 'Comox Airport'],
    ['YQR', 'Regina International Airport'],
    ['YQT', 'Thunder Bay Airport'],
    ['YQU', 'Grande Prairie Airport'],
    ['YQV', 'Yorkton Municipal Airport'],
    ['YQW', 'North Battleford Airport'],
    ['YQX', 'Gander International Airport'],
    ['YQY', 'Sydney / J.A. Douglas McCurdy Airport'],
    ['YQZ', 'Quesnel Airport'],
    ['YRB', 'Resolute Bay Airport'],
    ['YRI', 'Rivière-du-Loup Airport'],
    ['YRJ', 'Roberval Airport'],
    ['YRM', 'Rocky Mountain House Airport'],
    ['YRT', 'Rankin Inlet Airport'],
    ['YSB', 'Sudbury Airport'],
    ['YSC', 'Sherbrooke Airport'],
    ['YSJ', 'Saint John Airport'],
    ['YSM', 'Fort Smith Airport'],
    ['YSR', 'Nanisivik Airport'],
    ['YSU', 'Summerside Airport'],
    ['YSY', 'Sachs Harbour (David Nasogaluak Jr. Saaryuaq) Airport'],
    ['YTE', 'Cape Dorset Airport'],
    ['YTH', 'Thompson Airport'],
    ['YTR', 'CFB Trenton'],
    ['YTS', 'Timmins/Victor M. Power'],
    ['YTZ', 'Billy Bishop Toronto City Centre Airport'],
    ['YUB', 'Tuktoyaktuk Airport'],
    ['YUL', 'Montreal / Pierre Elliott Trudeau International Airport'],
    ['YUT', 'Repulse Bay Airport'],
    ['YUX', 'Hall Beach Airport'],
    ['YUY', 'Rouyn Noranda Airport'],
    ['YVC', 'La Ronge Airport'],
    ['YVG', 'Vermilion Airport'],
    ['YVM', 'Qikiqtarjuaq Airport'],
    ['YVO', "Val-d'Or Airport"],
    ['YVP', 'Kuujjuaq Airport'],
    ['YVQ', 'Norman Wells Airport'],
    ['YVR', 'Vancouver International Airport'],
    ['YVT', 'Buffalo Narrows Airport'],
    ['YVV', 'Wiarton Airport'],
    ['YWA', 'Petawawa Airport'],
    ['YWG', 'Winnipeg / James Armstrong Richardson International Airport'],
    ['YWK', 'Wabush Airport'],
    ['YWL', 'Williams Lake Airport'],
    ['YWY', 'Wrigley Airport'],
    ['YXC', 'Cranbrook/Canadian Rockies International Airport'],
    ['YXD', 'Edmonton City Centre (Blatchford Field) Airport'],
    ['YXE', 'Saskatoon John G. Diefenbaker International Airport'],
    ['YXH', 'Medicine Hat Airport'],
    ['YXJ', 'Fort St John Airport'],
    ['YXL', 'Sioux Lookout Airport'],
    ['YXP', 'Pangnirtung Airport'],
    ['YXR', 'Earlton (Timiskaming Regional) Airport'],
    ['YXS', 'Prince George Airport'],
    ['YXT', 'Northwest Regional Airport Terrace-Kitimat'],
    ['YXU', 'London Airport'],
    ['YXX', 'Abbotsford Airport'],
    ['YXY', 'Whitehorse / Erik Nielsen International Airport'],
    ['YYB', 'North Bay Jack Garland Airport'],
    ['YYC', 'Calgary International Airport'],
    ['YYD', 'Smithers Airport'],
    ['YYE', 'Fort Nelson Airport'],
    ['YYF', 'Penticton Airport'],
    ['YYG', 'Charlottetown Airport'],
    ['YYH', 'Taloyoak Airport'],
    ['YYJ', 'Victoria International Airport'],
    ['YYL', 'Lynn Lake Airport'],
    ['YYN', 'Swift Current Airport'],
    ['YYQ', 'Churchill Airport'],
    ['YYR', 'Goose Bay Airport'],
    ['YYT', "St. John's International Airport"],
    ['YYU', 'Kapuskasing Airport'],
    ['YYW', 'Armstrong Airport'],
    ['YYY', 'Mont Joli Airport'],
    ['YYZ', 'Lester B. Pearson International Airport'],
    ['YZD', 'Downsview Airport'],
    ['YZE', 'Gore Bay Manitoulin Airport'],
    ['YZF', 'Yellowknife Airport'],
    ['YZH', 'Slave Lake Airport'],
    ['YZP', 'Sandspit Airport'],
    ['YZR', 'Chris Hadfield Airport'],
    ['YZT', 'Port Hardy Airport'],
    ['YZU', 'Whitecourt Airport'],
    ['YZV', 'Sept-Îles Airport'],
    ['YZW', 'Teslin Airport'],
    ['YZX', 'CFB Greenwood'],
    ['ZFA', 'Faro Airport'],
    ['ZFM', 'Fort Mcpherson Airport'],
    ['QLD', 'Blida Airport'],
    ['BUJ', 'Bou Saada Airport'],
    ['BJA', 'Soummam Airport'],
    ['ALG', 'Houari Boumediene Airport'],
    ['DJG', 'Djanet Inedbirene Airport'],
    ['VVZ', 'Illizi Takhamalt Airport'],
    ['TMR', 'Aguenar - Hadj Bey Akhamok Airport'],
    ['GJL', 'Jijel Ferhat Abbas Airport'],
    ['MZW', 'Mecheria Airport'],
    ['AAE', 'Rabah Bitat Airport'],
    ['CZL', 'Mohamed Boudiaf International Airport'],
    ['TEE', 'Cheikh Larbi Tébessi Airport'],
    ['HRM', "Hassi R'Mel Airport"],
    ['TID', 'Bou Chekif Airport'],
    ['TIN', 'Tindouf Airport'],
    ['CFK', 'Ech Cheliff Airport'],
    ['TAF', 'Tafaraoui Airport'],
    ['TLM', 'Zenata - Messali El Hadj Airport'],
    ['ORN', 'Es Senia Airport'],
    ['BFW', 'Sidi Bel Abbes Airport'],
    ['MUW', 'Ghriss Airport'],
    ['AZR', 'Touat Cheikh Sidi Mohamed Belkebir Airport'],
    ['BSK', 'Biskra Airport'],
    ['ELG', 'El Golea Airport'],
    ['GHA', 'Noumérat - Moufdi Zakaria Airport'],
    ['HME', 'Oued Irara Airport'],
    ['INZ', 'In Salah Airport'],
    ['TGR', 'Touggourt Sidi Madhi Airport'],
    ['LOO', 'Laghouat Airport'],
    ['TMX', 'Timimoun Airport'],
    ['OGX', 'Ain el Beida Airport'],
    ['IAM', 'In Aménas Airport'],
    ['COO', 'Cadjehoun Airport'],
    ['OUA', 'Ouagadougou Airport'],
    ['BOY', 'Bobo Dioulasso Airport'],
    ['ACC', 'Kotoka International Airport'],
    ['TML', 'Tamale Airport'],
    ['NYI', 'Sunyani Airport'],
    ['TKD', 'Takoradi Airport'],
    ['ABJ', 'Port Bouet Airport'],
    ['BYK', 'Bouaké Airport'],
    ['DJO', 'Daloa Airport'],
    ['HGO', 'Korhogo Airport'],
    ['MJC', 'Man Airport'],
    ['SPY', 'San Pedro Airport'],
    ['ASK', 'Yamoussoukro Airport'],
    ['ABV', 'Nnamdi Azikiwe International Airport'],
    ['AKR', 'Akure Airport'],
    ['BNI', 'Benin Airport'],
    ['CBQ', 'Margaret Ekpo International Airport'],
    ['ENU', 'Akanu Ibiam International Airport'],
    ['IBA', 'Ibadan Airport'],
    ['ILR', 'Ilorin International Airport'],
    ['JOS', 'Yakubu Gowon Airport'],
    ['KAD', 'Kaduna Airport'],
    ['KAN', 'Mallam Aminu International Airport'],
    ['MIU', 'Maiduguri International Airport'],
    ['MDI', 'Makurdi Airport'],
    ['LOS', 'Murtala Muhammed International Airport'],
    ['MXJ', 'Minna Airport'],
    ['PHC', 'Port Harcourt International Airport'],
    ['SKO', 'Sadiq Abubakar III International Airport'],
    ['YOL', 'Yola Airport'],
    ['ZAR', 'Zaria Airport'],
    ['MFQ', 'Maradi Airport'],
    ['NIM', 'Diori Hamani International Airport'],
    ['THZ', 'Tahoua Airport'],
    ['AJY', 'Mano Dayak International Airport'],
    ['ZND', 'Zinder Airport'],
    ['MIR', 'Monastir Habib Bourguiba International Airport'],
    ['TUN', 'Tunis Carthage International Airport'],
    ['GAF', 'Gafsa Ksar International Airport'],
    ['GAE', 'Gabès Matmata International Airport'],
    ['DJE', 'Djerba Zarzis International Airport'],
    ['EBM', 'El Borma Airport'],
    ['SFA', 'Sfax Thyna International Airport'],
    ['TOE', 'Tozeur Nefta International Airport'],
    ['LRL', 'Niamtougou International Airport'],
    ['LFW', 'Lomé-Tokoin Airport'],
    ['ANR', 'Antwerp International Airport (Deurne)'],
    ['BRU', 'Brussels Airport'],
    ['CRL', 'Brussels South Charleroi Airport'],
    ['KJK', 'Wevelgem Airport'],
    ['LGG', 'Liège Airport'],
    ['OST', 'Ostend-Bruges International Airport'],
    ['OBL', 'Zoersel (Oostmalle) Airfield'],
    ['AOC', 'Altenburg-Nobitz Airport'],
    ['IES', 'Riesa-Göhlis Airport'],
    ['REB', 'Rechlin-Lärz Airport'],
    ['QXH', 'Schönhagen Airport'],
    ['BBH', 'Barth Airport'],
    ['ZMG', 'Magdeburg City Airport'],
    ['CBU', 'Cottbus-Drewitz Airport'],
    ['SXF', 'Berlin-Schönefeld Airport'],
    ['DRS', 'Dresden Airport'],
    ['ERF', 'Erfurt Airport'],
    ['FRA', 'Frankfurt am Main Airport'],
    ['FMO', 'Münster Osnabrück Airport'],
    ['HAM', 'Hamburg Airport'],
    ['THF', 'Berlin-Tempelhof International Airport'],
    ['CGN', 'Cologne Bonn Airport'],
    ['DUS', 'Düsseldorf Airport'],
    ['MUC', 'Munich Airport'],
    ['NUE', 'Nuremberg Airport'],
    ['LEJ', 'Leipzig/Halle Airport'],
    ['SCN', 'Saarbrücken Airport'],
    ['STR', 'Stuttgart Airport'],
    ['TXL', 'Berlin-Tegel Airport'],
    ['HAJ', 'Hannover Airport'],
    ['BRE', 'Bremen Airport'],
    ['QEF', 'Frankfurt-Egelsbach Airport'],
    ['HHN', 'Frankfurt-Hahn Airport'],
    ['MHG', 'Mannheim-City Airport'],
    ['EIB', 'Eisenach-Kindel Airport'],
    ['SGE', 'Siegerland Airport'],
    ['XFW', 'Hamburg-Finkenwerder Airport'],
    ['KEL', 'Kiel-Holtenau Airport'],
    ['LBC', 'Lübeck Blankensee Airport'],
    ['ESS', 'Essen Mulheim Airport'],
    ['BFE', 'Bielefeld Airport'],
    ['MGL', 'Mönchengladbach Airport'],
    ['PAD', 'Paderborn Lippstadt Airport'],
    ['DTM', 'Dortmund Airport'],
    ['AGB', 'Augsburg Airport'],
    ['OBF', 'Oberpfaffenhofen Airport'],
    ['RBM', 'Straubing Airport'],
    ['FDH', 'Friedrichshafen Airport'],
    ['SZW', 'Schwerin Parchim Airport'],
    ['BYU', 'Bayreuth Airport'],
    ['URD', 'Burg Feuerstein Airport'],
    ['HOQ', 'Hof-Plauen Airport'],
    ['ZQW', 'Zweibrücken Airport'],
    ['ZQL', 'Donaueschingen-Villingen Airport'],
    ['BWE', 'Braunschweig-Wolfsburg Airport'],
    ['KSF', 'Kassel-Calden Airport'],
    ['BRV', 'Bremerhaven Airport'],
    ['EME', 'Emden Airport'],
    ['WVN', 'Wilhelmshaven-Mariensiel Airport'],
    ['BMK', 'Borkum Airport'],
    ['NRD', 'Norderney Airport'],
    ['FLF', 'Flensburg-Schäferhaus Airport'],
    ['GWT', 'Westerland Sylt Airport'],
    ['KDL', 'Kärdla Airport'],
    ['URE', 'Kuressaare Airport'],
    ['EPU', 'Pärnu Airport'],
    ['TLL', 'Lennart Meri Tallinn Airport'],
    ['TAY', 'Tartu Airport'],
    ['ENF', 'Enontekio Airport'],
    ['KEV', 'Halli Airport'],
    ['HEM', 'Helsinki Malmi Airport'],
    ['HEL', 'Helsinki Vantaa Airport'],
    ['HYV', 'Hyvinkää Airfield'],
    ['KTQ', 'Kitee Airport'],
    ['IVL', 'Ivalo Airport'],
    ['JOE', 'Joensuu Airport'],
    ['JYV', 'Jyvaskyla Airport'],
    ['KAU', 'Kauhava Airport'],
    ['KEM', 'Kemi-Tornio Airport'],
    ['KAJ', 'Kajaani Airport'],
    ['KHJ', 'Kauhajoki Airport'],
    ['KOK', 'Kokkola-Pietarsaari Airport'],
    ['KAO', 'Kuusamo Airport'],
    ['KTT', 'Kittilä Airport'],
    ['KUO', 'Kuopio Airport'],
    ['QLF', 'Lahti Vesivehmaa Airport'],
    ['LPP', 'Lappeenranta Airport'],
    ['MHQ', 'Mariehamn Airport'],
    ['MIK', 'Mikkeli Airport'],
    ['OUL', 'Oulu Airport'],
    ['POR', 'Pori Airport'],
    ['RVN', 'Rovaniemi Airport'],
    ['SVL', 'Savonlinna Airport'],
    ['SOT', 'Sodankyla Airport'],
    ['TMP', 'Tampere-Pirkkala Airport'],
    ['TKU', 'Turku Airport'],
    ['UTI', 'Utti Air Base'],
    ['VAA', 'Vaasa Airport'],
    ['VRK', 'Varkaus Airport'],
    ['YLI', 'Ylivieska Airfield'],
    ['BFS', 'Belfast International Airport'],
    ['ENK', 'St Angelo Airport'],
    ['BHD', 'George Best Belfast City Airport'],
    ['LDY', 'City of Derry Airport'],
    ['BHX', 'Birmingham International Airport'],
    ['CVT', 'Coventry Airport'],
    ['GLO', 'Gloucestershire Airport'],
    ['GBA', 'Cotswold Airport'],
    ['MAN', 'Manchester Airport'],
    ['NQY', 'Newquay Cornwall Airport'],
    ['LYE', 'RAF Lyneham'],
    ['YEO', 'RNAS Yeovilton'],
    ['HAW', 'Haverfordwest Airport'],
    ['CWL', 'Cardiff International Airport'],
    ['SWS', 'Swansea Airport'],
    ['BRS', 'Bristol Airport'],
    ['LPL', 'Liverpool John Lennon Airport'],
    ['LTN', 'London Luton Airport'],
    ['PLH', 'Plymouth City Airport'],
    ['BOH', 'Bournemouth Airport'],
    ['SOU', 'Southampton Airport'],
    ['QLA', 'Lasham Airport'],
    ['ACI', 'Alderney Airport'],
    ['GCI', 'Guernsey Airport'],
    ['JER', 'Jersey Airport'],
    ['ESH', 'Shoreham Airport'],
    ['BQH', 'London Biggin Hill Airport'],
    ['LGW', 'London Gatwick Airport'],
    ['LCY', 'London City Airport'],
    ['FAB', 'Farnborough Airport'],
    ['BBS', 'Blackbushe Airport'],
    ['LHR', 'London Heathrow Airport'],
    ['SEN', 'Southend Airport'],
    ['LYX', 'Lydd Airport'],
    ['MSE', 'Kent International Airport'],
    ['CAX', 'Carlisle Airport'],
    ['BLK', 'Blackpool International Airport'],
    ['HUY', 'Humberside Airport'],
    ['BWF', 'Barrow Walney Island Airport'],
    ['LBA', 'Leeds Bradford Airport'],
    ['WRT', 'Warton Airport'],
    ['CEG', 'Hawarden Airport'],
    ['IOM', 'Isle of Man Airport'],
    ['NCL', 'Newcastle Airport'],
    ['MME', 'Durham Tees Valley Airport'],
    ['EMA', 'East Midlands Airport'],
    ['KOI', 'Kirkwall Airport'],
    ['LSI', 'Sumburgh Airport'],
    ['WIC', 'Wick Airport'],
    ['ABZ', 'Aberdeen Dyce Airport'],
    ['INV', 'Inverness Airport'],
    ['GLA', 'Glasgow International Airport'],
    ['EDI', 'Edinburgh Airport'],
    ['ILY', 'Islay Airport'],
    ['PIK', 'Glasgow Prestwick Airport'],
    ['BEB', 'Benbecula Airport'],
    ['SCS', 'Scatsta Airport'],
    ['DND', 'Dundee Airport'],
    ['SYY', 'Stornoway Airport'],
    ['TRE', 'Tiree Airport'],
    ['ADX', 'RAF Leuchars'],
    ['LMO', 'RAF Lossiemouth'],
    ['CBG', 'Cambridge Airport'],
    ['NWI', 'Norwich International Airport'],
    ['STN', 'London Stansted Airport'],
    ['EXT', 'Exeter International Airport'],
    ['FZO', 'Bristol Filton Airport'],
    ['OXF', 'Oxford (Kidlington) Airport'],
    ['BEX', 'RAF Benson'],
    ['LKZ', 'RAF Lakenheath'],
    ['MHZ', 'RAF Mildenhall'],
    ['QUY', 'RAF Wyton'],
    ['FFD', 'RAF Fairford'],
    ['BZZ', 'RAF Brize Norton'],
    ['ODH', 'RAF Odiham'],
    ['NHT', 'RAF Northolt'],
    ['QCY', 'RAF Coningsby'],
    ['BEQ', 'RAF Honington'],
    ['SQZ', 'RAF Scampton'],
    ['HRT', 'RAF Linton-On-Ouse'],
    ['WTN', 'RAF Waddington'],
    ['KNF', 'RAF Marham'],
    ['MPN', 'Mount Pleasant Airport'],
    ['AMS', 'Amsterdam Airport Schiphol'],
    ['MST', 'Maastricht Aachen Airport'],
    ['EIN', 'Eindhoven Airport'],
    ['GRQ', 'Eelde Airport'],
    ['GLZ', 'Gilze Rijen Air Base'],
    ['DHR', 'De Kooy Airport'],
    ['LEY', 'Lelystad Airport'],
    ['LWR', 'Leeuwarden Air Base'],
    ['RTM', 'Rotterdam The Hague Airport'],
    ['UTC', 'Soesterberg Air Base'],
    ['ENS', 'Twente Airport'],
    ['LID', 'Valkenburg Naval Air Base'],
    ['WOE', 'Woensdrecht Air Base'],
    ['ORK', 'Cork Airport'],
    ['GWY', 'Galway Airport'],
    ['DUB', 'Dublin Airport'],
    ['NOC', 'Ireland West Knock Airport'],
    ['KIR', 'Kerry Airport'],
    ['SNN', 'Shannon Airport'],
    ['SXL', 'Sligo Airport'],
    ['WAT', 'Waterford Airport'],
    ['AAR', 'Aarhus Airport'],
    ['BLL', 'Billund Airport'],
    ['CPH', 'Copenhagen Kastrup Airport'],
    ['EBJ', 'Esbjerg Airport'],
    ['KRP', 'Karup Airport'],
    ['BYR', 'Læsø Airport'],
    ['MRW', 'Lolland Falster Maribo Airport'],
    ['ODE', 'Odense Airport'],
    ['RKE', 'Copenhagen Roskilde Airport'],
    ['RNN', 'Bornholm Airport'],
    ['SGD', 'Sønderborg Airport'],
    ['SKS', 'Skrydstrup Air Base'],
    ['SQW', 'Skive Airport'],
    ['TED', 'Thisted Airport'],
    ['FAE', 'Vagar Airport'],
    ['STA', 'Stauning Airport'],
    ['AAL', 'Aalborg Airport'],
    ['LUX', 'Luxembourg-Findel International Airport'],
    ['AES', 'Ålesund Airport'],
    ['ANX', 'Andøya Airport'],
    ['ALF', 'Alta Airport'],
    ['BNN', 'Brønnøysund Airport'],
    ['BOO', 'Bodø Airport'],
    ['BGO', 'Bergen Airport Flesland'],
    ['BJF', 'Båtsfjord Airport'],
    ['KRS', 'Kristiansand Airport'],
    ['DLD', 'Geilo Airport Dagali'],
    ['BDU', 'Bardufoss Airport'],
    ['EVE', 'Harstad/Narvik Airport, Evenes'],
    ['VDB', 'Leirin Airport'],
    ['FRO', 'Florø Airport'],
    ['OSL', 'Oslo Lufthavn'],
    ['HAU', 'Haugesund Airport'],
    ['HAA', 'Hasvik Airport'],
    ['KSU', 'Kristiansund Airport (Kvernberget)'],
    ['KKN', 'Kirkenes Airport (Høybuktmoen)'],
    ['FAN', 'Lista Airport'],
    ['MOL', 'Molde Airport'],
    ['MJF', 'Mosjøen Airport (Kjærstad)'],
    ['LKL', 'Banak Airport'],
    ['NTB', 'Notodden Airport'],
    ['OLA', 'Ørland Airport'],
    ['RRS', 'Røros Airport'],
    ['RYG', 'Moss Airport, Rygge'],
    ['LYR', 'Svalbard Airport, Longyear'],
    ['SKE', 'Skien Airport'],
    ['SRP', 'Stord Airport'],
    ['SSJ', 'Sandnessjøen Airport (Stokka)'],
    ['TOS', 'Tromsø Airport,'],
    ['TRF', 'Sandefjord Airport, Torp'],
    ['TRD', 'Trondheim Airport Værnes'],
    ['SVG', 'Stavanger Airport Sola'],
    ['GDN', 'Gdańsk Lech Wałęsa Airport'],
    ['KRK', 'Kraków John Paul II International Airport'],
    ['KTW', 'Katowice International Airport'],
    ['POZ', 'Poznań-Ławica Airport'],
    ['RZE', 'Rzeszów-Jasionka Airport'],
    ['SZZ', "Szczecin-Goleniów 'Solidarność' Airport"],
    ['OSP', 'Redzikowo Air Base'],
    ['WAW', 'Warsaw Chopin Airport'],
    ['WRO', 'Copernicus Wrocław Airport'],
    ['IEG', 'Zielona Góra-Babimost Airport'],
    ['RNB', 'Ronneby Airport'],
    ['GOT', 'Gothenburg-Landvetter Airport'],
    ['JKG', 'Jönköping Airport'],
    ['LDK', 'Lidköping-Hovby Airport'],
    ['GSE', 'Gothenburg City Airport'],
    ['KVB', 'Skövde Airport'],
    ['THN', 'Trollhättan-Vänersborg Airport'],
    ['KSK', 'Karlskoga Airport'],
    ['MXX', 'Mora Airport'],
    ['NYO', 'Stockholm Skavsta Airport'],
    ['KID', 'Kristianstad Airport'],
    ['OSK', 'Oskarshamn Airport'],
    ['KLR', 'Kalmar Airport'],
    ['MMX', 'Malmö Sturup Airport'],
    ['HAD', 'Halmstad Airport'],
    ['VXO', 'Växjö Kronoberg Airport'],
    ['EVG', 'Sveg Airport'],
    ['GEV', 'Gällivare Airport'],
    ['HUV', 'Hudiksvall Airport'],
    ['KRF', 'Kramfors Sollefteå Airport'],
    ['LYC', 'Lycksele Airport'],
    ['SDL', 'Sundsvall-Härnösand Airport'],
    ['OER', 'Örnsköldsvik Airport'],
    ['KRN', 'Kiruna Airport'],
    ['SFT', 'Skellefteå Airport'],
    ['UME', 'Umeå Airport'],
    ['VHM', 'Vilhelmina Airport'],
    ['AJR', 'Arvidsjaur Airport'],
    ['ORB', 'Örebro Airport'],
    ['VST', 'Stockholm Västerås Airport'],
    ['LLA', 'Luleå Airport'],
    ['ARN', 'Stockholm-Arlanda Airport'],
    ['BMA', 'Stockholm-Bromma Airport'],
    ['BLE', 'Borlange Airport'],
    ['HLF', 'Hultsfred Airport'],
    ['GVX', 'Gävle Sandviken Airport'],
    ['LPI', 'Linköping City Airport'],
    ['NRK', 'Norrköping Airport'],
    ['EKT', 'Eskilstuna Airport'],
    ['VBY', 'Visby Airport'],
    ['SPM', 'Spangdahlem Air Base'],
    ['RMS', 'Ramstein Air Base'],
    ['GHF', '[Duplicate] Giebelstadt Army Air Field'],
    ['ZCN', 'Celle Airport'],
    ['FRZ', 'Fritzlar Airport'],
    ['ZNF', 'Hanau Army Air Field'],
    ['KZG', 'Flugplatz Kitzingen'],
    ['FCN', 'Nordholz Naval Airbase'],
    ['GKE', 'Geilenkirchen Air Base'],
    ['RLG', 'Rostock-Laage Airport'],
    ['WBG', 'Schleswig Air Base'],
    ['WIE', 'Wiesbaden Army Airfield'],
    ['FEL', 'Fürstenfeldbruck Air Base'],
    ['IGS', 'Ingolstadt Manching Airport'],
    ['GUT', 'Gütersloh Air Base'],
    ['ALJ', 'Alexander Bay Airport'],
    ['AGZ', 'Aggeneys Airport'],
    ['BIY', 'Bisho Airport'],
    ['BFN', 'Bram Fischer International Airport'],
    ['CPT', 'Cape Town International Airport'],
    ['DUR', 'King Shaka International Airport'],
    ['ELS', 'Ben Schoeman Airport'],
    ['FCB', 'Ficksburg Sentraoes Airport'],
    ['GCJ', 'Grand Central Airport'],
    ['GRJ', 'George Airport'],
    ['HRS', 'Harrismith Airport'],
    ['HDS', 'Hoedspruit Air Force Base Airport'],
    ['JNB', 'OR Tambo International Airport'],
    ['KXE', 'P C Pelser Airport'],
    ['KIM', 'Kimberley Airport'],
    ['KMH', 'Johan Pienaar Airport'],
    ['KLZ', 'Kleinsee Airport'],
    ['HLA', 'Lanseria Airport'],
    ['SDB', 'Langebaanweg Airport'],
    ['LAY', 'Ladysmith Airport'],
    ['MGH', 'Margate Airport'],
    ['LLE', 'Riverside Airport'],
    ['MZQ', 'Mkuze Airport'],
    ['NCS', 'Newcastle Airport'],
    ['OVG', 'Overberg Airport'],
    ['OUH', 'Oudtshoorn Airport'],
    ['PLZ', 'Port Elizabeth Airport'],
    ['PBZ', 'Plettenberg Bay Airport'],
    ['PHW', 'Hendrik Van Eck Airport'],
    ['JOH', 'Port St Johns Airport'],
    ['PZB', 'Pietermaritzburg Airport'],
    ['NTY', 'Pilanesberg International Airport'],
    ['PTG', 'Polokwane International Airport'],
    ['PCF', 'Potchefstroom Airport'],
    ['UTW', 'Queenstown Airport'],
    ['RCB', 'Richards Bay Airport'],
    ['ROD', 'Robertson Airport'],
    ['SBU', 'Springbok Airport'],
    ['ZEC', 'Secunda Airport'],
    ['SIS', 'Sishen Airport'],
    ['SZK', 'Skukuza Airport'],
    ['LTA', 'Tzaneen Airport'],
    ['ULD', 'Prince Mangosuthu Buthelezi Airport'],
    ['UTN', 'Pierre Van Ryneveld Airport'],
    ['UTT', 'K. D. Matanzima Airport'],
    ['VRU', 'Vryburg Airport'],
    ['VIR', 'Virginia Airport'],
    ['VRE', 'Vredendal Airport'],
    ['PRY', 'Wonderboom Airport'],
    ['WKF', 'Waterkloof Air Force Base'],
    ['FRW', 'Francistown Airport'],
    ['JWA', 'Jwaneng Airport'],
    ['BBK', 'Kasane Airport'],
    ['MUB', 'Maun Airport'],
    ['GBE', 'Sir Seretse Khama International Airport'],
    ['PKW', 'Selebi Phikwe Airport'],
    ['BZV', 'Maya-Maya Airport'],
    ['FTX', 'Owando Airport'],
    ['OUE', 'Ouesso Airport'],
    ['PNR', 'Pointe Noire Airport'],
    ['MTS', 'Matsapha Airport'],
    ['BGF', "Bangui M'Poko International Airport"],
    ['BBT', 'Berbérati Airport'],
    ['BSG', 'Bata Airport'],
    ['SSG', 'Malabo Airport'],
    ['ASI', 'RAF Ascension Island'],
    ['MRU', 'Sir Seewoosagur Ramgoolam International Airport'],
    ['RRG', 'Sir Charles Gaetan Duval Airport'],
    ['NKW', 'Diego Garcia Naval Support Facility'],
    ['TKC', 'Tiko Airport'],
    ['DLA', 'Douala International Airport'],
    ['MVR', 'Salak Airport'],
    ['FOM', 'Foumban Nkounja Airport'],
    ['NGE', "N'Gaoundéré Airport"],
    ['GOU', 'Garoua International Airport'],
    ['BFX', 'Bafoussam Airport'],
    ['BPC', 'Bamenda Airport'],
    ['YAO', 'Yaoundé Airport'],
    ['CGJ', 'Kasompe Airport'],
    ['LVI', 'Livingstone Airport'],
    ['LUN', 'Kenneth Kaunda International Airport Lusaka'],
    ['MFU', 'Mfuwe Airport'],
    ['MNR', 'Mongu Airport'],
    ['NLA', 'Simon Mwansa Kapwepwe International Airport'],
    ['KIW', 'Southdowns Airport'],
    ['HAH', 'Prince Said Ibrahim International Airport'],
    ['NWA', 'Mohéli Bandar Es Eslam Airport'],
    ['AJN', 'Ouani Airport'],
    ['DZA', 'Dzaoudzi Pamandzi International Airport'],
    ['RUN', 'Roland Garros Airport'],
    ['ZSE', 'Pierrefonds Airport'],
    ['TNR', 'Ivato Airport'],
    ['ZVA', 'Miandrivazo Airport'],
    ['SMS', 'Sainte Marie Airport'],
    ['TMM', 'Toamasina Airport'],
    ['MOQ', 'Morondava Airport'],
    ['DIE', 'Arrachart Airport'],
    ['WMR', 'Mananara Nord Airport'],
    ['ZWA', 'Andapa Airport'],
    ['AMB', 'Ambilobe Airport'],
    ['ANM', 'Antsirabato Airport'],
    ['HVA', 'Analalava Airport'],
    ['MJN', 'Amborovy Airport'],
    ['NOS', 'Fascene Airport'],
    ['BPY', 'Besalampy Airport'],
    ['WMN', 'Maroantsetra Airport'],
    ['SVB', 'Sambava Airport'],
    ['VOH', 'Vohimarina Airport'],
    ['WAI', 'Ambalabe Airport'],
    ['IVA', 'Ampampamena Airport'],
    ['FTU', 'Tôlanaro Airport'],
    ['WFI', 'Fianarantsoa Airport'],
    ['RVA', 'Farafangana Airport'],
    ['WVK', 'Manakara Airport'],
    ['MNJ', 'Mananjary Airport'],
    ['MXM', 'Morombe Airport'],
    ['TLE', 'Toliara Airport'],
    ['SSY', 'Mbanza Congo Airport'],
    ['BUG', 'Benguela Airport'],
    ['CAB', 'Cabinda Airport'],
    ['NOV', 'Nova Lisboa Airport'],
    ['SVP', 'Kuito Airport'],
    ['LAD', 'Quatro de Fevereiro Airport'],
    ['MEG', 'Malanje Airport'],
    ['SPP', 'Menongue Airport'],
    ['GXG', 'Negage Airport'],
    ['PBN', 'Porto Amboim Airport'],
    ['VHC', 'Saurimo Airport'],
    ['SZA', 'Soyo Airport'],
    ['SDD', 'Lubango Airport'],
    ['LUO', 'Luena Airport'],
    ['UGO', 'Uige Airport'],
    ['XGN', 'Xangongo Airport'],
    ['OYE', 'Oyem Airport'],
    ['OKN', 'Okondja Airport'],
    ['LBQ', 'Lambarene Airport'],
    ['BMM', 'Bitam Airport'],
    ['POG', 'Port Gentil Airport'],
    ['OMB', 'Omboue Hopital Airport'],
    ['MKU', 'Makokou Airport'],
    ['LBV', "Libreville Leon M'ba International Airport"],
    ['MVB', "M'Vengue El Hadj Omar Bongo Ondimba International Airport"],
    ['PCP', 'Principe Airport'],
    ['TMS', 'São Tomé International Airport'],
    ['BEW', 'Beira Airport'],
    ['INH', 'Inhambane Airport'],
    ['VXC', 'Lichinga Airport'],
    ['LFB', 'Lumbo Airport'],
    ['MPM', 'Maputo Airport'],
    ['MUD', 'Mueda Airport'],
    ['MZB', 'Mocímboa da Praia Airport'],
    ['MNC', 'Nacala Airport'],
    ['APL', 'Nampula Airport'],
    ['POL', 'Pemba Airport'],
    ['UEL', 'Quelimane Airport'],
    ['TET', 'Chingozi Airport'],
    ['VNX', 'Vilankulo Airport'],
    ['DES', 'Desroches Airport'],
    ['SEZ', 'Seychelles International Airport'],
    ['PRI', 'Praslin Airport'],
    ['AEH', 'Abeche Airport'],
    ['MQQ', 'Moundou Airport'],
    ['NDJ', "N'Djamena International Airport"],
    ['FYT', 'Faya Largeau Airport'],
    ['BUQ', 'Joshua Mqabuko Nkomo International Airport'],
    ['BFO', 'Buffalo Range Airport'],
    ['VFA', 'Victoria Falls International Airport'],
    ['HRE', 'Robert Gabriel Mugabe International Airport'],
    ['KAB', 'Kariba International Airport'],
    ['UTA', 'Mutare Airport'],
    ['MVZ', 'Masvingo International Airport'],
    ['GWE', 'Thornhill Air Base'],
    ['HWN', 'Hwange National Park Airport'],
    ['BLZ', 'Chileka International Airport'],
    ['KGJ', 'Karonga Airport'],
    ['KBQ', 'Kasungu Airport'],
    ['LLW', 'Lilongwe International Airport'],
    ['ZZU', 'Mzuzu Airport'],
    ['MSU', 'Moshoeshoe I International Airport'],
    ['FIH', 'Ndjili International Airport'],
    ['NLO', 'Ndolo Airport'],
    ['MNB', 'Muanda Airport'],
    ['FDU', 'Bandundu Airport'],
    ['KKW', 'Kikwit Airport'],
    ['MDK', 'Mbandaka Airport'],
    ['BDT', 'Gbadolite Airport'],
    ['GMA', 'Gemena Airport'],
    ['KLI', 'Kotakoli Airport'],
    ['LIQ', 'Lisala Airport'],
    ['FKI', 'Bangoka International Airport'],
    ['IRP', 'Matari Airport'],
    ['BUX', 'Bunia Airport'],
    ['BZU', 'Buta Zega Airport'],
    ['BKY', 'Bukavu Kavumu Airport'],
    ['GOM', 'Goma International Airport'],
    ['KND', 'Kindu Airport'],
    ['FBM', 'Lubumbashi International Airport'],
    ['KWZ', 'Kolwezi Airport'],
    ['FMI', 'Kalemie Airport'],
    ['KMN', 'Kamina Base Airport'],
    ['KGA', 'Kananga Airport'],
    ['MJM', 'Mbuji Mayi Airport'],
    ['BKO', 'Modibo Keita International Airport'],
    ['GAQ', 'Gao Airport'],
    ['KYS', 'Kayes Dag Dag Airport'],
    ['MZI', 'Mopti Airport'],
    ['TOM', 'Timbuktu Airport'],
    ['BJL', 'Banjul International Airport'],
    ['FUE', 'Fuerteventura Airport'],
    ['VDE', 'Hierro Airport'],
    ['SPC', 'La Palma Airport'],
    ['LPA', 'Gran Canaria Airport'],
    ['ACE', 'Lanzarote Airport'],
    ['TFS', 'Tenerife South Airport'],
    ['TFN', 'Tenerife Norte Airport'],
    ['MLN', 'Melilla Airport'],
    ['FNA', 'Lungi International Airport'],
    ['MLW', 'Spriggs Payne Airport'],
    ['ROB', 'Roberts International Airport'],
    ['AGA', 'Al Massira Airport'],
    ['TTA', 'Tan Tan Airport'],
    ['FEZ', 'Saïss Airport'],
    ['ERH', 'Moulay Ali Cherif Airport'],
    ['MEK', 'Bassatine Airport'],
    ['OUD', 'Angads Airport'],
    ['GMD', 'Ben Slimane Airport'],
    ['RBA', 'Rabat-Salé Airport'],
    ['CMN', 'Mohammed V International Airport'],
    ['RAK', 'Menara Airport'],
    ['NNA', 'Kenitra Airport'],
    ['OZZ', 'Ouarzazate Airport'],
    ['AHU', 'Cherif Al Idrissi Airport'],
    ['TTU', "Saniat R'mel Airport"],
    ['TNG', 'Ibn Batouta Airport'],
    ['ZIG', 'Ziguinchor Airport'],
    ['CSK', 'Cap Skirring Airport'],
    ['KLC', 'Kaolack Airport'],
    ['DKR', 'Léopold Sédar Senghor International Airport'],
    ['XLS', 'Saint Louis Airport'],
    ['BXE', 'Bakel Airport'],
    ['KGG', 'Kédougou Airport'],
    ['TUD', 'Tambacounda Airport'],
    ['AEO', 'Aioun el Atrouss Airport'],
    ['TIY', 'Tidjikja Airport'],
    ['KFA', 'Kiffa Airport'],
    ['EMN', 'Néma Airport'],
    ['KED', 'Kaédi Airport'],
    ['NKC', 'Nouakchott–Oumtounsy International Airport'],
    ['SEY', 'Sélibaby Airport'],
    ['ATR', 'Atar International Airport'],
    ['NDB', 'Nouadhibou International Airport'],
    ['FIG', 'Fria Airport'],
    ['FAA', 'Faranah Airport'],
    ['LEK', 'Tata Airport'],
    ['SID', 'Amílcar Cabral International Airport'],
    ['BVC', 'Rabil Airport'],
    ['MMO', 'Maio Airport'],
    ['SNE', 'Preguiça Airport'],
    ['VXE', 'São Pedro Airport'],
    ['ADD', 'Addis Ababa Bole International Airport'],
    ['AMH', 'Arba Minch Airport'],
    ['AXU', 'Axum Airport'],
    ['BJR', 'Bahir Dar Airport'],
    ['DIR', 'Aba Tenna Dejazmach Yilma International Airport'],
    ['GMB', 'Gambella Airport'],
    ['GDQ', 'Gonder Airport'],
    ['JIM', 'Jimma Airport'],
    ['LLI', 'Lalibella Airport'],
    ['MQX', 'Mekele Airport'],
    ['ASO', 'Asosa Airport'],
    ['BJM', 'Bujumbura International Airport'],
    ['HGA', 'Egal International Airport'],
    ['BBO', 'Berbera Airport'],
    ['KMU', 'Kisimayu Airport'],
    ['ALY', 'El Nouzha Airport'],
    ['ABS', 'Abu Simbel Airport'],
    ['CAI', 'Cairo International Airport'],
    ['CWE', 'Cairo West Airport'],
    ['HRG', 'Hurghada International Airport'],
    ['EGH', 'El Gora Airport'],
    ['LXR', 'Luxor International Airport'],
    ['MUH', 'Mersa Matruh Airport'],
    ['PSD', 'Port Said Airport'],
    ['SKV', 'St Catherine International Airport'],
    ['ASW', 'Aswan International Airport'],
    ['ELT', 'El Tor Airport'],
    ['EDL', 'Eldoret International Airport'],
    ['GGM', 'Kakamega Airport'],
    ['KIS', 'Kisumu Airport'],
    ['KTL', 'Kitale Airport'],
    ['LOK', 'Lodwar Airport'],
    ['LAU', 'Manda Airstrip'],
    ['MBA', 'Mombasa Moi International Airport'],
    ['WIL', 'Nairobi Wilson Airport'],
    ['WJR', 'Wajir Airport'],
    ['GHT', 'Ghat Airport'],
    ['AKF', 'Kufra Airport'],
    ['BEN', 'Benina International Airport'],
    ['SEB', 'Sabha Airport'],
    ['TIP', 'Tripoli International Airport'],
    ['LMQ', 'Marsa Brega Airport'],
    ['HUQ', 'Hon Airport'],
    ['LTD', 'Ghadames East Airport'],
    ['GYI', 'Gisenyi Airport'],
    ['KGL', 'Kigali International Airport'],
    ['KME', 'Kamembe Airport'],
    ['DOG', 'Dongola Airport'],
    ['RSS', 'Damazin Airport'],
    ['ELF', 'El Fasher Airport'],
    ['KSL', 'Kassala Airport'],
    ['KDX', 'Kadugli Airport'],
    ['EBD', 'El Obeid Airport'],
    ['JUB', 'Juba International Airport'],
    ['MAK', 'Malakal Airport'],
    ['KRT', 'Khartoum International Airport'],
    ['ARK', 'Arusha Airport'],
    ['DAR', 'Julius Nyerere International Airport'],
    ['DOD', 'Dodoma Airport'],
    ['IRI', 'Iringa Airport'],
    ['JRO', 'Kilimanjaro International Airport'],
    ['LKY', 'Lake Manyara Airport'],
    ['MYW', 'Mtwara Airport'],
    ['MWZ', 'Mwanza Airport'],
    ['PMA', 'Pemba Airport'],
    ['TGT', 'Tanga Airport'],
    ['ZNZ', 'Abeid Amani Karume International Airport'],
    ['EBB', 'Entebbe International Airport'],
    ['SRT', 'Soroti Airport'],
    ['TIA', 'Tirana International Airport Mother Teresa'],
    ['BOJ', 'Burgas Airport'],
    ['GOZ', 'Gorna Oryahovitsa Airport'],
    ['PDV', 'Plovdiv International Airport'],
    ['SOF', 'Sofia Airport'],
    ['SZR', 'Stara Zagora Airport'],
    ['VAR', 'Varna Airport'],
    ['LCA', 'Larnaca International Airport'],
    ['PFO', 'Paphos International Airport'],
    ['AKT', 'RAF Akrotiri'],
    ['DBV', 'Dubrovnik Airport'],
    ['OSI', 'Osijek Airport'],
    ['PUY', 'Pula Airport'],
    ['RJK', 'Rijeka Airport'],
    ['SPU', 'Split Airport'],
    ['ZAG', 'Zagreb Airport'],
    ['ZAD', 'Zadar Airport'],
    ['ABC', 'Albacete-Los Llanos Airport'],
    ['ALC', 'Alicante International Airport'],
    ['LEI', 'Almería International Airport'],
    ['OVD', 'Asturias Airport'],
    ['ODB', 'Córdoba Airport'],
    ['BIO', 'Bilbao Airport'],
    ['BCN', 'Barcelona International Airport'],
    ['BJZ', 'Badajoz Airport'],
    ['LCG', 'A Coruña Airport'],
    ['GRO', 'Girona Airport'],
    ['GRX', 'Federico Garcia Lorca Airport'],
    ['IBZ', 'Ibiza Airport'],
    ['XRY', 'Jerez Airport'],
    ['MJV', 'San Javier Airport'],
    ['MAD', 'Adolfo Suárez Madrid–Barajas Airport'],
    ['AGP', 'Málaga Airport'],
    ['MAH', 'Menorca Airport'],
    ['OZP', 'Moron Air Base'],
    ['PNA', 'Pamplona Airport'],
    ['REU', 'Reus Air Base'],
    ['ROZ', 'Rota Naval Station Airport'],
    ['SLM', 'Salamanca Airport'],
    ['EAS', 'San Sebastian Airport'],
    ['SCQ', 'Santiago de Compostela Airport'],
    ['LEU', "Pirineus - la Seu d'Urgel Airport"],
    ['TOJ', 'Torrejón Airport'],
    ['VLC', 'Valencia Airport'],
    ['VLL', 'Valladolid Airport'],
    ['VIT', 'Vitoria/Foronda Airport'],
    ['VGO', 'Vigo Airport'],
    ['SDR', 'Santander Airport'],
    ['ZAZ', 'Zaragoza Air Base'],
    ['SVQ', 'Sevilla Airport'],
    ['CQF', 'Calais-Dunkerque Airport'],
    ['BYF', 'Albert-Bray Airport'],
    ['LTQ', "Le Touquet-Côte d'Opale Airport"],
    ['XVS', 'Valenciennes-Denain Airport'],
    ['AGF', 'Agen-La Garenne Airport'],
    ['BOD', 'Bordeaux-Mérignac Airport'],
    ['EGC', 'Bergerac-Roumanière Airport'],
    ['CNG', 'Cognac-Châteaubernard (BA 709) Air Base'],
    ['PIS', 'Poitiers-Biard Airport'],
    ['MCU', 'Montluçon-Guéret Airport'],
    ['LIG', 'Limoges Airport'],
    ['NIT', 'Niort-Souché Airport'],
    ['TLS', 'Toulouse-Blagnac Airport'],
    ['PUF', 'Pau Pyrénées Airport'],
    ['LDE', 'Tarbes-Lourdes-Pyrénées Airport'],
    ['ANG', 'Angoulême-Brie-Champniers Airport'],
    ['BVE', 'Brive Souillac Airport'],
    ['PGX', 'Périgueux-Bassillac Airport'],
    ['BIQ', 'Biarritz-Anglet-Bayonne Airport'],
    ['ZAO', 'Cahors-Lalbenque Airport'],
    ['LBI', 'Albi-Le Séquestre Airport'],
    ['DCM', 'Castres-Mazamet Airport'],
    ['RDZ', 'Rodez-Marcillac Airport'],
    ['RYN', 'Royan-Médis Airport'],
    ['XMW', 'Montauban Airport'],
    ['RCO', 'Rochefort-Saint-Agnant (BA 721) Airport'],
    ['CMR', 'Colmar-Houssen Airport'],
    ['DLE', 'Dole-Tavaux Airport'],
    ['OBS', 'Aubenas-Ardèche Méridional Airport'],
    ['LPY', 'Le Puy-Loudes Airport'],
    ['ETZ', 'Metz-Nancy-Lorraine Airport'],
    ['BIA', 'Bastia-Poretta Airport'],
    ['CLY', 'Calvi-Sainte-Catherine Airport'],
    ['FSC', 'Figari Sud-Corse Airport'],
    ['AJA', 'Ajaccio-Napoléon Bonaparte Airport'],
    ['PRP', 'Propriano Airport'],
    ['SOZ', 'Solenzara (BA 126) Air Base'],
    ['AUF', 'Auxerre-Branches Airport'],
    ['CMF', 'Chambéry-Savoie Airport'],
    ['CFE', 'Clermont-Ferrand Auvergne Airport'],
    ['BOU', 'Bourges Airport'],
    ['QNJ', 'Annemasse Airport'],
    ['LYS', 'Lyon Saint-Exupéry Airport'],
    ['SYT', 'Saint-Yan Airport'],
    ['RNE', 'Roanne-Renaison Airport'],
    ['NCY', 'Annecy-Haute-Savoie-Mont Blanc Airport'],
    ['GNB', 'Grenoble-Isère Airport'],
    ['VAF', 'Valence-Chabeuil Airport'],
    ['VHY', 'Vichy-Charmeil Airport'],
    ['AUR', 'Aurillac Airport'],
    ['CHR', "Châteauroux-Déols 'Marcel Dassault' Airport"],
    ['LYN', 'Lyon-Bron Airport'],
    ['CEQ', 'Cannes-Mandelieu Airport'],
    ['EBU', 'Saint-Étienne-Bouthéon Airport'],
    ['CCF', 'Carcassonne Airport'],
    ['MRS', 'Marseille Provence Airport'],
    ['NCE', "Nice-Côte d'Azur Airport"],
    ['XOG', 'Orange-Caritat (BA 115) Air Base'],
    ['PGF', 'Perpignan-Rivesaltes (Llabanère) Airport'],
    ['CTT', 'Le Castellet Airport'],
    ['MPL', 'Montpellier-Méditerranée Airport'],
    ['BZR', 'Béziers-Vias Airport'],
    ['AVN', 'Avignon-Caumont Airport'],
    ['MEN', 'Mende-Brenoux Airfield'],
    ['BVA', 'Paris Beauvais Tillé Airport'],
    ['EVX', 'Évreux-Fauville (BA 105) Air Base'],
    ['LEH', 'Le Havre Octeville Airport'],
    ['XAB', 'Abbeville'],
    ['ORE', 'Orléans-Bricy (BA 123) Air Base'],
    ['XCR', 'Châlons-Vatry Airport'],
    ['URO', 'Rouen Airport'],
    ['TUF', 'Tours-Val-de-Loire Airport'],
    ['CET', 'Cholet Le Pontreau Airport'],
    ['LVA', 'Laval-Entrammes Airport'],
    ['LBG', 'Paris-Le Bourget Airport'],
    ['CSF', 'Creil Air Base'],
    ['CDG', 'Charles de Gaulle International Airport'],
    ['TNF', 'Toussus-le-Noble Airport'],
    ['ORY', 'Paris-Orly Airport'],
    ['POX', 'Pontoise - Cormeilles-en-Vexin Airport'],
    ['VIY', 'Villacoublay-Vélizy (BA 107) Air Base'],
    ['NVS', 'Nevers-Fourchambault Airport'],
    ['XME', 'Maubeuge-Élesmes Airport'],
    ['LIL', 'Lille-Lesquin Airport'],
    ['HZB', 'Merville-Calonne Airport'],
    ['XCZ', 'Charleville-Mézières Airport'],
    ['BES', 'Brest Bretagne Airport'],
    ['CER', 'Cherbourg-Maupertus Airport'],
    ['DNR', 'Dinard-Pleurtuit-Saint-Malo Airport'],
    ['LBY', 'La Baule-Escoublac Airport'],
    ['GFR', 'Granville Airport'],
    ['DOL', 'Deauville-Saint-Gatien Airport'],
    ['LRT', 'Lorient South Brittany (Bretagne Sud) Airport'],
    ['EDM', 'La Roche-sur-Yon Airport'],
    ['LDV', 'Landivisiau Air Base'],
    ['CFR', 'Caen-Carpiquet Airport'],
    ['LME', 'Le Mans-Arnage Airport'],
    ['RNS', 'Rennes-Saint-Jacques Airport'],
    ['LAI', 'Lannion-Côte de Granit Airport'],
    ['UIP', 'Quimper-Cornouaille Airport'],
    ['NTE', 'Nantes Atlantique Airport'],
    ['SBK', 'Saint-Brieuc-Armor Airport'],
    ['MXN', 'Morlaix-Ploujean Airport'],
    ['VNE', 'Vannes-Meucon Airport'],
    ['SNR', 'Saint-Nazaire-Montoir Airport'],
    ['BSL', 'EuroAirport Basel-Mulhouse-Freiburg Airport'],
    ['DIJ', 'Dijon-Bourgogne Airport'],
    ['MZM', 'Metz-Frescaty (BA 128) Air Base'],
    ['EPL', 'Épinal-Mirecourt Airport'],
    ['ENC', 'Nancy-Essey Airport'],
    ['RHE', 'Reims-Champagne (BA 112) Air Base'],
    ['SXB', 'Strasbourg Airport'],
    ['TLN', 'Toulon-Hyères Airport'],
    ['FNI', 'Nîmes-Arles-Camargue Airport'],
    ['MQC', 'Miquelon Airport'],
    ['FSP', 'St Pierre Airport'],
    ['PYR', 'Andravida Air Base'],
    ['AGQ', 'Agrinion Air Base'],
    ['AXD', 'Dimokritos Airport'],
    ['VOL', 'Nea Anchialos Airport'],
    ['JKH', 'Chios Island National Airport'],
    ['IOA', 'Ioannina Airport'],
    ['HER', 'Heraklion International Nikos Kazantzakis Airport'],
    ['KSO', 'Kastoria National Airport'],
    ['KIT', 'Kithira Airport'],
    ['EFL', 'Kefallinia Airport'],
    ['KLX', 'Kalamata Airport'],
    ['KGS', 'Kos Airport'],
    ['AOK', 'Karpathos Airport'],
    ['CFU', 'Ioannis Kapodistrias International Airport'],
    ['KSJ', 'Kasos Airport'],
    ['KVA', 'Alexander the Great International Airport'],
    ['KZI', 'Filippos Airport'],
    ['LRS', 'Leros Airport'],
    ['LXS', 'Limnos Airport'],
    ['LRA', 'Larisa Airport'],
    ['JMK', 'Mikonos Airport'],
    ['MJT', 'Mytilene International Airport'],
    ['PVK', 'Aktion National Airport'],
    ['RHO', 'Diagoras Airport'],
    ['GPA', 'Araxos Airport'],
    ['CHQ', 'Chania International Airport'],
    ['JSI', 'Skiathos Island National Airport'],
    ['SMI', 'Samos Airport'],
    ['SPJ', 'Sparti Airport'],
    ['JTR', 'Santorini Airport'],
    ['JSH', 'Sitia Airport'],
    ['SKU', 'Skiros Airport'],
    ['SKG', 'Thessaloniki Macedonia International Airport'],
    ['ZTH', "Zakynthos International Airport 'Dionysios Solomos'"],
    ['BUD', 'Budapest Liszt Ferenc International Airport'],
    ['DEB', 'Debrecen International Airport'],
    ['CRV', 'Crotone Airport'],
    ['BRI', 'Bari Karol Wojtyła Airport'],
    ['FOG', "Foggia 'Gino Lisa' Airport"],
    ['TAR', "Taranto-Grottaglie 'Marcello Arlotta' Airport"],
    ['LCC', 'Lecce Galatina Air Base'],
    ['PSR', 'Pescara International Airport'],
    ['BDS', 'Brindisi – Salento Airport'],
    ['SUF', 'Lamezia Terme Airport'],
    ['CTA', 'Catania-Fontanarossa Airport'],
    ['LMP', 'Lampedusa Airport'],
    ['PNL', 'Pantelleria Airport'],
    ['PMO', 'Falcone-Borsellino Airport'],
    ['REG', 'Reggio Calabria Airport'],
    ['TPS', 'Vincenzo Florio Airport Trapani-Birgi'],
    ['NSY', 'Sigonella Navy Air Base'],
    ['AHO', 'Alghero-Fertilia Airport'],
    ['DCI', 'Decimomannu Air Base'],
    ['CAG', 'Cagliari Elmas Airport'],
    ['OLB', 'Olbia Costa Smeralda Airport'],
    ['TTB', 'Tortolì Airport'],
    ['MXP', 'Malpensa International Airport'],
    ['BGY', 'Il Caravaggio International Airport'],
    ['TRN', 'Turin Airport'],
    ['ALL', "Villanova D'Albenga International Airport"],
    ['GOA', 'Genoa Cristoforo Colombo Airport'],
    ['LIN', 'Milano Linate Airport'],
    ['PMF', 'Parma Airport'],
    ['CUF', 'Cuneo International Airport'],
    ['AVB', 'Aviano Air Base'],
    ['BZO', 'Bolzano Airport'],
    ['BLQ', 'Bologna Guglielmo Marconi Airport'],
    ['TSF', "Treviso-Sant'Angelo Airport"],
    ['FRL', 'Forlì Airport'],
    ['VBS', 'Brescia Airport'],
    ['TRS', 'Trieste-Friuli Venezia Giulia Airport'],
    ['RMI', 'Federico Fellini International Airport'],
    ['VIC', 'Vicenza Airport'],
    ['QPA', 'Padova Airport'],
    ['VRN', 'Verona Villafranca Airport'],
    ['VCE', 'Venice Marco Polo Airport'],
    ['SAY', 'Siena-Ampugnano Airport'],
    ['CIA', 'Ciampino-G. B. Pastine International Airport'],
    ['FCO', 'Leonardo da Vinci-Fiumicino Airport'],
    ['EBA', 'Marina Di Campo Airport'],
    ['QLT', 'Latina Air Base'],
    ['NAP', 'Naples International Airport'],
    ['PSA', 'Pisa International Airport'],
    ['FLR', 'Peretola Airport'],
    ['GRS', 'Grosseto Air Base'],
    ['PEG', "Perugia San Francesco d'Assisi - Umbria International Airport"],
    ['LJU', 'Ljubljana Jože Pučnik Airport'],
    ['MBX', 'Maribor Airport'],
    ['POW', 'Portoroz Airport'],
    ['UHE', 'Kunovice Airport'],
    ['KLV', 'Karlovy Vary International Airport'],
    ['OSR', 'Ostrava Leos Janáček Airport'],
    ['PED', 'Pardubice Airport'],
    ['PRV', 'Přerov Air Base'],
    ['PRG', 'Václav Havel Airport Prague'],
    ['BRQ', 'Brno-Tuřany Airport'],
    ['VOD', 'Vodochody Airport'],
    ['TLV', 'Ben Gurion International Airport'],
    ['BEV', 'Beersheba (Teyman) Airport'],
    ['ETH', 'Eilat Airport'],
    ['EIY', 'Ein Yahav Airfield'],
    ['HFA', 'Haifa International Airport'],
    ['RPN', "Ben Ya'akov Airport"],
    ['MTZ', 'Bar Yehuda Airfield'],
    ['VTM', 'Nevatim Air Base'],
    ['VDA', 'Ovda International Airport'],
    ['MIP', 'Ramon Air Base'],
    ['SDV', 'Sde Dov Airport'],
    ['MLA', 'Malta International Airport'],
    ['GRZ', 'Graz Airport'],
    ['INN', 'Innsbruck Airport'],
    ['LNZ', 'Linz Hörsching Airport'],
    ['SZG', 'Salzburg Airport'],
    ['VIE', 'Vienna International Airport'],
    ['AVR', 'Alverca Air Base'],
    ['SMA', 'Santa Maria Airport'],
    ['BGC', 'Bragança Airport'],
    ['BYJ', 'Beja Airport / Airbase'],
    ['BGZ', 'Braga Municipal Aerodrome'],
    ['CAT', 'Cascais Airport'],
    ['FLW', 'Flores Airport'],
    ['FAO', 'Faro Airport'],
    ['GRW', 'Graciosa Airport'],
    ['HOR', 'Horta Airport'],
    ['TER', 'Lajes Airport'],
    ['QLR', 'Monte Real Air Base'],
    ['PDL', 'João Paulo II Airport'],
    ['PIX', 'Pico Airport'],
    ['PRM', 'Portimão Airport'],
    ['OPO', 'Francisco de Sá Carneiro Airport'],
    ['PXO', 'Porto Santo Airport'],
    ['LIS', 'Humberto Delgado Airport (Lisbon Portela Airport)'],
    ['SJZ', 'São Jorge Airport'],
    ['VRL', 'Vila Real Airport'],
    ['VSE', 'Aerodromo Goncalves Lobato (Viseu Airport)'],
    ['OMO', 'Mostar International Airport'],
    ['SJJ', 'Sarajevo International Airport'],
    ['ARW', 'Arad International Airport'],
    ['BCM', 'Bacău Airport'],
    ['BAY', 'Tautii Magheraus Airport'],
    ['BBU', 'Băneasa International Airport'],
    ['CND', 'Mihail Kogălniceanu International Airport'],
    ['CLJ', 'Cluj-Napoca International Airport'],
    ['CSB', 'Caransebeş Airport'],
    ['CRA', 'Craiova Airport'],
    ['IAS', 'Iaşi Airport'],
    ['OMR', 'Oradea International Airport'],
    ['OTP', 'Henri Coandă International Airport'],
    ['SBZ', 'Sibiu International Airport'],
    ['SUJ', 'Satu Mare Airport'],
    ['SCV', 'Suceava Stefan cel Mare Airport'],
    ['TCE', 'Tulcea Airport'],
    ['TGM', 'Transilvania Târgu Mureş International Airport'],
    ['TSR', 'Timişoara Traian Vuia Airport'],
    ['GVA', 'Geneva Cointrin International Airport'],
    ['SIR', 'Sion Airport'],
    ['EML', 'Emmen Air Base'],
    ['LUG', 'Lugano Airport'],
    ['BRN', 'Bern Belp Airport'],
    ['ZHI', 'Grenchen Airport'],
    ['ZRH', 'Zürich Airport'],
    ['ACH', 'St Gallen Altenrhein Airport'],
    ['SMV', 'Samedan Airport'],
    ['ESB', 'Esenboğa International Airport'],
    ['ANK', 'Etimesgut Air Base'],
    ['ADA', 'Adana Airport'],
    ['UAB', 'İncirlik Air Base'],
    ['AFY', 'Afyon Airport'],
    ['AYT', 'Antalya International Airport'],
    ['GZT', 'Gaziantep International Airport'],
    ['KYA', 'Konya Airport'],
    ['MZH', 'Amasya Merzifon Airport'],
    ['VAS', 'Sivas Nuri Demirağ Airport'],
    ['MLX', 'Malatya Erhaç Airport'],
    ['ASR', 'Kayseri Erkilet Airport'],
    ['TJK', 'Tokat Airport'],
    ['DNZ', 'Çardak Airport'],
    ['ISL', 'Atatürk International Airport'],
    ['BZI', 'Balıkesir Merkez Airport'],
    ['BDM', 'Bandırma Airport'],
    ['ESK', 'Eskişehir Air Base'],
    ['ADB', 'Adnan Menderes International Airport'],
    ['IGL', 'Çiğli Airport'],
    ['KCO', 'Cengiz Topel Airport'],
    ['DLM', 'Dalaman International Airport'],
    ['BXN', 'Imsık Airport'],
    ['EZS', 'Elazığ Airport'],
    ['DIY', 'Diyarbakir Airport'],
    ['ERC', 'Erzincan Airport'],
    ['ERZ', 'Erzurum International Airport'],
    ['TZX', 'Trabzon International Airport'],
    ['VAN', 'Van Ferit Melen Airport'],
    ['BAL', 'Batman Airport'],
    ['SXZ', 'Siirt Airport'],
    ['BZY', 'Bălți International Airport'],
    ['KIV', 'Chişinău International Airport'],
    ['OHD', 'Ohrid St. Paul the Apostle Airport'],
    ['SKP', 'Skopje Alexander the Great Airport'],
    ['GIB', 'Gibraltar Airport'],
    ['BEG', 'Belgrade Nikola Tesla Airport'],
    ['INI', 'Nis Airport'],
    ['TGD', 'Podgorica Airport'],
    ['PRN', 'Priština International Airport'],
    ['TIV', 'Tivat Airport'],
    ['BTS', 'M. R. Štefánik Airport'],
    ['KSC', 'Košice Airport'],
    ['PZY', 'Piešťany Airport'],
    ['SLD', 'Sliač Airport'],
    ['TAT', 'Poprad-Tatry Airport'],
    ['NCA', 'North Caicos Airport'],
    ['PLS', 'Providenciales Airport'],
    ['XSC', 'South Caicos Airport'],
    ['BRX', 'Maria Montez International Airport'],
    ['CBJ', 'Cabo Rojo Airport'],
    ['LRM', 'Casa De Campo International Airport'],
    ['PUJ', 'Punta Cana International Airport'],
    ['POP', 'Gregorio Luperon International Airport'],
    ['SDQ', 'Las Américas International Airport'],
    ['STI', 'Cibao International Airport'],
    ['CBV', 'Coban Airport'],
    ['GUA', 'La Aurora Airport'],
    ['RER', 'Retalhuleu Airport'],
    ['GSJ', 'San José Airport'],
    ['LCE', 'Goloson International Airport'],
    ['SAP', 'Ramón Villeda Morales International Airport'],
    ['GJA', 'La Laguna Airport'],
    ['RTB', 'Juan Manuel Galvez International Airport'],
    ['TEA', 'Tela Airport'],
    ['TGU', 'Toncontín International Airport'],
    ['TJI', 'Trujillo Airport'],
    ['OCJ', 'Boscobel Aerodrome'],
    ['KIN', 'Norman Manley International Airport'],
    ['MBJ', 'Sangster International Airport'],
    ['POT', 'Ken Jones Airport'],
    ['KTP', 'Tinson Pen Airport'],
    ['ACA', 'General Juan N Alvarez International Airport'],
    ['NTR', 'Del Norte International Airport'],
    ['AGU', 'Jesús Terán Paredo International Airport'],
    ['HUX', 'Bahías de Huatulco International Airport'],
    ['CVJ', 'General Mariano Matamoros Airport'],
    ['ACN', 'Ciudad Acuña New International Airport'],
    ['CME', 'Ciudad del Carmen International Airport'],
    ['NCG', 'Nuevo Casas Grandes Airport'],
    ['CUL', 'Bachigualato Federal International Airport'],
    ['CTM', 'Chetumal International Airport'],
    ['CEN', 'Ciudad Obregón International Airport'],
    ['CPE', 'Ingeniero Alberto Acuña Ongay International Airport'],
    ['CJS', 'Abraham González International Airport'],
    ['CUU', 'General Roberto Fierro Villalobos International Airport'],
    ['CVM', 'General Pedro Jose Mendez International Airport'],
    ['CZM', 'Cozumel International Airport'],
    ['DGO', 'General Guadalupe Victoria International Airport'],
    ['TPQ', 'Amado Nervo National Airport'],
    ['ESE', 'Ensenada International Airport'],
    ['GDL', 'Don Miguel Hidalgo Y Costilla International Airport'],
    ['GYM', 'General José María Yáñez International Airport'],
    ['TCN', 'Tehuacan Airport'],
    ['HMO', 'General Ignacio P. Garcia International Airport'],
    ['CLQ', 'Licenciado Miguel de la Madrid Airport'],
    ['ISJ', 'Isla Mujeres Airport'],
    ['SLW', 'Plan De Guadalupe International Airport'],
    ['IZT', 'Ixtepec Airport'],
    ['LZC', 'Lázaro Cárdenas Airport'],
    ['LMM', 'Valle del Fuerte International Airport'],
    ['BJX', 'Del Bajío International Airport'],
    ['LAP', 'Manuel Márquez de León International Airport'],
    ['LTO', 'Loreto International Airport'],
    ['MAM', 'General Servando Canales International Airport'],
    ['MID', 'Licenciado Manuel Crescencio Rejon Int Airport'],
    ['MXL', 'General Rodolfo Sánchez Taboada International Airport'],
    ['MLM', 'General Francisco J. Mujica International Airport'],
    ['MTT', 'Minatitlán/Coatzacoalcos National Airport'],
    ['LOV', 'Monclova International Airport'],
    ['MEX', 'Licenciado Benito Juarez International Airport'],
    ['MTY', 'General Mariano Escobedo International Airport'],
    ['MZT', 'General Rafael Buelna International Airport'],
    ['NOG', 'Nogales International Airport'],
    ['NLD', 'Quetzalcóatl International Airport'],
    ['OAX', 'Xoxocotlán International Airport'],
    ['PAZ', 'El Tajín National Airport'],
    ['PBC', 'Hermanos Serdán International Airport'],
    ['PPE', 'Mar de Cortés International Airport'],
    ['PDS', 'Piedras Negras International Airport'],
    ['UPN', 'Licenciado y General Ignacio Lopez Rayon Airport'],
    ['PVR', 'Licenciado Gustavo Díaz Ordaz International Airport'],
    ['PXM', 'Puerto Escondido International Airport'],
    ['QRO', 'Querétaro Intercontinental Airport'],
    ['REX', 'General Lucio Blanco International Airport'],
    ['SJD', 'Los Cabos International Airport'],
    ['SFH', 'San Felipe International Airport'],
    ['SLP', 'Ponciano Arriaga International Airport'],
    ['TRC', 'Francisco Sarabia International Airport'],
    ['TGZ', 'Angel Albino Corzo International Airport'],
    ['TIJ', 'General Abelardo L. Rodríguez International Airport'],
    ['TAM', 'General Francisco Javier Mina International Airport'],
    ['TSL', 'Tamuin Airport'],
    ['TLC', 'Licenciado Adolfo Lopez Mateos International Airport'],
    ['TAP', 'Tapachula International Airport'],
    ['CUN', 'Cancún International Airport'],
    ['VSA', 'Carlos Rovirosa Pérez International Airport'],
    ['VER', 'General Heriberto Jara International Airport'],
    ['ZCL', 'General Leobardo C. Ruiz International Airport'],
    ['ZIH', 'Ixtapa Zihuatanejo International Airport'],
    ['ZMM', 'Zamora Airport'],
    ['ZLO', 'Playa De Oro International Airport'],
    ['BEF', 'Bluefields Airport'],
    ['MGA', 'Augusto C. Sandino (Managua) International Airport'],
    ['PUZ', 'Puerto Cabezas Airport'],
    ['BOC', 'Bocas Del Toro International Airport'],
    ['CHX', 'Cap Manuel Niño International Airport'],
    ['DAV', 'Enrique Malek International Airport'],
    ['BLB', 'Panama Pacific International Airport'],
    ['PAC', 'Marcos A. Gelabert International Airport'],
    ['SYP', 'Ruben Cantu Airport'],
    ['PTY', 'Tocumen International Airport'],
    ['BAI', 'Buenos Aires Airport'],
    ['OTR', 'Coto 47 Airport'],
    ['JAP', 'Chacarita Airport'],
    ['GLF', 'Golfito Airport'],
    ['GPL', 'Guapiles Airport'],
    ['LIR', 'Daniel Oduber Quiros International Airport'],
    ['LSL', 'Los Chiles Airport'],
    ['LIO', 'Limon International Airport'],
    ['NOB', 'Nosara Airport'],
    ['SJO', 'Juan Santamaria International Airport'],
    ['PMZ', 'Palmar Sur Airport'],
    ['XQP', 'Quepos Managua Airport'],
    ['TOO', 'San Vito De Java Airport'],
    ['SAL', 'Monseñor Óscar Arnulfo Romero International Airport'],
    ['CYA', 'Les Cayes Airport'],
    ['CAP', 'Cap Haitien International Airport'],
    ['JAK', 'Jacmel Airport'],
    ['PAP', 'Toussaint Louverture International Airport'],
    ['BCA', 'Gustavo Rizo Airport'],
    ['BYM', 'Carlos Manuel de Cespedes Airport'],
    ['AVI', 'Maximo Gomez Airport'],
    ['CCC', 'Jardines Del Rey Airport'],
    ['CFG', 'Jaime Gonzalez Airport'],
    ['CYO', 'Vilo Acuña International Airport'],
    ['CMW', 'Ignacio Agramonte International Airport'],
    ['SCU', 'Antonio Maceo International Airport'],
    ['NBW', 'Leeward Point Field'],
    ['GAO', 'Mariana Grajales Airport'],
    ['HAV', 'José Martí International Airport'],
    ['HOG', 'Frank Pais International Airport'],
    ['LCL', 'La Coloma Airport'],
    ['MOA', 'Orestes Acosta Airport'],
    ['MZO', 'Sierra Maestra Airport'],
    ['GER', 'Rafael Cabrera Airport'],
    ['UPB', 'Playa Baracoa Airport'],
    ['QPD', 'Pinar Del Rio Airport'],
    ['SNU', 'Abel Santamaria Airport'],
    ['SZJ', 'Siguanea Airport'],
    ['USS', 'Sancti Spiritus Airport'],
    ['VRA', 'Juan Gualberto Gomez International Airport'],
    ['VTU', 'Hermanos Ameijeiras Airport'],
    ['CYB', 'Gerrard Smith International Airport'],
    ['GCM', 'Owen Roberts International Airport'],
    ['MAY', 'Clarence A. Bain Airport'],
    ['ASD', 'Andros Town Airport'],
    ['MHH', 'Leonard M Thompson International Airport'],
    ['SAQ', 'San Andros Airport'],
    ['AXP', 'Spring Point Airport'],
    ['TCB', 'Treasure Cay Airport'],
    ['CCZ', 'Chub Cay Airport'],
    ['GHC', 'Great Harbour Cay Airport'],
    ['BIM', 'South Bimini Airport'],
    ['GGT', 'Exuma International Airport'],
    ['ELH', 'North Eleuthera Airport'],
    ['GHB', "Governor's Harbour Airport"],
    ['NMC', 'Normans Cay Airport'],
    ['RSD', 'Rock Sound Airport'],
    ['TYM', 'Staniel Cay Airport'],
    ['FPO', 'Grand Bahama International Airport'],
    ['IGA', 'Inagua Airport'],
    ['LGI', "Deadman's Cay Airport"],
    ['SML', 'Stella Maris Airport'],
    ['MYG', 'Mayaguana Airport'],
    ['NAS', 'Lynden Pindling International Airport'],
    ['DCT', 'Duncan Town Airport'],
    ['RCY', 'Rum Cay Airport'],
    ['ZSA', 'San Salvador Airport'],
    ['BZE', 'Philip S. W. Goldson International Airport'],
    ['AIT', 'Aitutaki Airport'],
    ['RAR', 'Rarotonga International Airport'],
    ['NAN', 'Nadi International Airport'],
    ['SUV', 'Nausori International Airport'],
    ['TBU', "Fua'amotu International Airport"],
    ['VAV', "Vava'u International Airport"],
    ['TRW', 'Bonriki International Airport'],
    ['TBF', 'Tabiteuea North Airport'],
    ['WLS', 'Hihifo Airport'],
    ['APW', 'Faleolo International Airport'],
    ['PPG', 'Pago Pago International Airport'],
    ['RUR', 'Rurutu Airport'],
    ['TUB', 'Tubuai Airport'],
    ['AAA', 'Anaa Airport'],
    ['FGU', 'Fangatau Airport'],
    ['TIH', 'Tikehau Airport'],
    ['REA', 'Reao Airport'],
    ['FAV', 'Fakarava Airport'],
    ['XMH', 'Manihi Airport'],
    ['GMR', 'Totegegie Airport'],
    ['KKR', 'Kaukura Airport'],
    ['MKP', 'Makemo Airport'],
    ['PKP', 'Puka Puka Airport'],
    ['TKP', 'Takapoto Airport'],
    ['AXR', 'Arutua Airport'],
    ['MVT', 'Mataiva Airport'],
    ['TKX', 'Takaroa Airport'],
    ['NHV', 'Nuku Hiva Airport'],
    ['BOB', 'Bora Bora Airport'],
    ['RGI', 'Rangiroa Airport'],
    ['HUH', 'Huahine-Fare Airport'],
    ['MOZ', 'Moorea Airport'],
    ['HOI', 'Hao Airport'],
    ['MAU', 'Maupiti Airport'],
    ['RFP', 'Raiatea Airport'],
    ['VLI', 'Bauerfield International Airport'],
    ['KNQ', 'Koné Airport'],
    ['KOC', 'Koumac Airport'],
    ['LIF', 'Lifou Airport'],
    ['GEA', 'Nouméa Magenta Airport'],
    ['MEE', 'Maré Airport'],
    ['TOU', 'Touho Airport'],
    ['UVE', 'Ouvéa Airport'],
    ['NOU', 'La Tontouta International Airport'],
    ['AKL', 'Auckland International Airport'],
    ['TUO', 'Taupo Airport'],
    ['AMZ', 'Ardmore Airport'],
    ['CHC', 'Christchurch International Airport'],
    ['CHT', 'Chatham Islands-Tuuta Airport'],
    ['DUD', 'Dunedin Airport'],
    ['GIS', 'Gisborne Airport'],
    ['GTN', 'Glentanner Airport'],
    ['HKK', 'Hokitika Airfield'],
    ['HLZ', 'Hamilton International Airport'],
    ['KKE', 'Kerikeri Airport'],
    ['KAT', 'Kaitaia Airport'],
    ['ALR', 'Alexandra Airport'],
    ['MON', 'Mount Cook Airport'],
    ['TEU', 'Manapouri Airport'],
    ['MRO', 'Hood Airport'],
    ['NPL', 'New Plymouth Airport'],
    ['NSN', 'Nelson Airport'],
    ['IVC', 'Invercargill Airport'],
    ['OHA', 'RNZAF Base Ohakea'],
    ['OAM', 'Oamaru Airport'],
    ['PMR', 'Palmerston North Airport'],
    ['PPQ', 'Paraparaumu Airport'],
    ['ZQN', 'Queenstown International Airport'],
    ['ROT', 'Rotorua Regional Airport'],
    ['TRG', 'Tauranga Airport'],
    ['TIU', 'Timaru Airport'],
    ['TWZ', 'Pukaki Airport'],
    ['BHE', 'Woodbourne Airport'],
    ['WKA', 'Wanaka Airport'],
    ['WHK', 'Whakatane Airport'],
    ['WLG', 'Wellington International Airport'],
    ['WIR', 'Wairoa Airport'],
    ['WRE', 'Whangarei Airport'],
    ['WSZ', 'Westport Airport'],
    ['WAG', 'Wanganui Airport'],
    ['HEA', 'Herat Airport'],
    ['JAA', 'Jalalabad Airport'],
    ['KBL', 'Hamid Karzai International Airport'],
    ['KDH', 'Kandahar Airport'],
    ['MMZ', 'Maimana Airport'],
    ['MZR', 'Mazar I Sharif Airport'],
    ['OAH', 'Shindand Airport'],
    ['UND', 'Konduz Airport'],
    ['BAH', 'Bahrain International Airport'],
    ['AHB', 'Abha Regional Airport'],
    ['HOF', 'Al Ahsa Airport'],
    ['ABT', 'Al Baha Airport'],
    ['BHH', 'Bisha Airport'],
    ['DMM', 'King Fahd International Airport'],
    ['DHA', 'King Abdulaziz Air Base'],
    ['GIZ', 'Jizan Regional Airport'],
    ['ELQ', 'Gassim Airport'],
    ['URY', 'Gurayat Domestic Airport'],
    ['HAS', "Ha'il Airport"],
    ['QJB', 'Jubail Airport'],
    ['JED', 'King Abdulaziz International Airport'],
    ['KMC', 'King Khaled Military City Airport'],
    ['MED', 'Prince Mohammad Bin Abdulaziz Airport'],
    ['EAM', 'Nejran Airport'],
    ['AQI', 'Al Qaisumah/Hafr Al Batin Airport'],
    ['RAH', 'Rafha Domestic Airport'],
    ['RUH', 'King Khaled International Airport'],
    ['RAE', 'Arar Domestic Airport'],
    ['SHW', 'Sharurah Airport'],
    ['SLF', 'Sulayel Airport'],
    ['TUU', 'Tabuk Airport'],
    ['TIF', 'Ta’if Regional Airport'],
    ['TUI', 'Turaif Domestic Airport'],
    ['EJH', 'Al Wajh Domestic Airport'],
    ['YNB', 'Prince Abdulmohsin Bin Abdulaziz Airport'],
    ['ABD', 'Abadan Airport'],
    ['DEF', 'Dezful Airport'],
    ['AKW', 'Aghajari Airport'],
    ['GCH', 'Gachsaran Airport'],
    ['OMI', 'Omidiyeh Airport'],
    ['MRX', 'Mahshahr Airport'],
    ['AWZ', 'Ahwaz Airport'],
    ['AEU', 'Abu Musa Island Airport'],
    ['BUZ', 'Bushehr Airport'],
    ['KIH', 'Kish International Airport'],
    ['BDH', 'Bandar Lengeh Airport'],
    ['KHK', 'Khark Island Airport'],
    ['SXI', 'Sirri Island Airport'],
    ['LVP', 'Lavan Island Airport'],
    ['KSH', 'Shahid Ashrafi Esfahani Airport'],
    ['SDG', 'Sanandaj Airport'],
    ['IFH', 'Hesa Airport'],
    ['KKS', 'Kashan Airport'],
    ['IFN', 'Esfahan Shahid Beheshti International Airport'],
    ['RAS', 'Sardar-e-Jangal Airport'],
    ['AJK', 'Arak Airport'],
    ['THR', 'Mehrabad International Airport'],
    ['GZW', 'Qazvin Airport'],
    ['BND', 'Bandar Abbas International Airport'],
    ['JYR', 'Jiroft Airport'],
    ['KER', 'Kerman Airport'],
    ['HDR', 'Havadarya Airport'],
    ['SYJ', 'Sirjan Airport'],
    ['XBJ', 'Birjand Airport'],
    ['CKT', 'Sarakhs Airport'],
    ['RUD', 'Shahroud Airport'],
    ['TCX', 'Tabas Airport'],
    ['KLM', 'Kalaleh Airport'],
    ['RZR', 'Ramsar Airport'],
    ['FAZ', 'Fasa Airport'],
    ['JAR', 'Jahrom Airport'],
    ['LFM', 'Lamerd Airport'],
    ['SYZ', 'Shiraz Shahid Dastghaib International Airport'],
    ['KHY', 'Khoy Airport'],
    ['TBZ', 'Tabriz International Airport'],
    ['JWN', 'Zanjan Airport'],
    ['AZD', 'Shahid Sadooghi Airport'],
    ['ACZ', 'Zabol Airport'],
    ['ZBR', 'Konarak Airport'],
    ['ZAH', 'Zahedan International Airport'],
    ['IHR', 'Iran Shahr Airport'],
    ['AMM', 'Queen Alia International Airport'],
    ['ADJ', 'Amman-Marka International Airport'],
    ['AQJ', 'Aqaba King Hussein International Airport'],
    ['OMF', 'King Hussein Air College'],
    ['KWI', 'Kuwait International Airport'],
    ['BEY', 'Beirut Rafic Hariri International Airport'],
    ['KYE', 'Rene Mouawad Air Base'],
    ['AUH', 'Abu Dhabi International Airport'],
    ['AZI', 'Bateen Airport'],
    ['DHF', 'Al Dhafra Air Base'],
    ['DXB', 'Dubai International Airport'],
    ['FJR', 'Fujairah International Airport'],
    ['RKT', 'Ras Al Khaimah International Airport'],
    ['SHJ', 'Sharjah International Airport'],
    ['KHS', 'Khasab Air Base'],
    ['MSH', 'Masirah Air Base'],
    ['MCT', 'Muscat International Airport'],
    ['SLL', 'Salalah Airport'],
    ['TTH', 'Thumrait Air Base'],
    ['BHW', 'Bhagatanwala Airport'],
    ['LYP', 'Faisalabad International Airport'],
    ['GWD', 'Gwadar International Airport'],
    ['GIL', 'Gilgit Airport'],
    ['JAG', 'Shahbaz Air Base'],
    ['KHI', 'Jinnah International Airport'],
    ['LHE', 'Alama Iqbal International Airport'],
    ['XJM', 'Mangla Airport'],
    ['MFG', 'Muzaffarabad Airport'],
    ['MWD', 'Mianwali Air Base'],
    ['MJD', 'Moenjodaro Airport'],
    ['MUX', 'Multan International Airport'],
    ['WNS', 'Shaheed Benazirabad Airport'],
    ['PJG', 'Panjgur Airport'],
    ['PSI', 'Pasni Airport'],
    ['PEW', 'Peshawar International Airport'],
    ['UET', 'Quetta International Airport'],
    ['RYK', 'Shaikh Zaid Airport'],
    ['RAZ', 'Rawalakot Airport'],
    ['SKZ', 'Sukkur Airport'],
    ['SDT', 'Saidu Sharif Airport'],
    ['SUL', 'Sui Airport'],
    ['BDN', 'Talhar Airport'],
    ['WAF', 'Wana Airport'],
    ['PZH', 'Zhob Airport'],
    ['BSR', 'Basrah International Airport'],
    ['ALP', 'Aleppo International Airport'],
    ['DAM', 'Damascus International Airport'],
    ['DEZ', 'Deir ez-Zor Air Base'],
    ['LTK', 'Bassel Al-Assad International Airport'],
    ['PMS', 'Palmyra Airport'],
    ['DIA', 'Doha International Airport'],
    ['CIS', 'Canton Island Airport'],
    ['ROP', 'Rota International Airport'],
    ['SPN', 'Saipan International Airport'],
    ['UAM', 'Andersen Air Force Base'],
    ['GUM', 'Antonio B. Won Pat International Airport'],
    ['TIQ', 'Tinian International Airport'],
    ['MAJ', 'Marshall Islands International Airport'],
    ['KWA', 'Bucholz Army Air Field'],
    ['CXI', 'Cassidy International Airport'],
    ['MDY', 'Henderson Field'],
    ['TKK', 'Chuuk International Airport'],
    ['PNI', 'Pohnpei International Airport'],
    ['ROR', 'Babelthuap Airport'],
    ['KSA', 'Kosrae International Airport'],
    ['YAP', 'Yap International Airport'],
    ['KNH', 'Kinmen Airport'],
    ['TTT', 'Taitung Airport'],
    ['GNI', 'Lyudao Airport'],
    ['KHH', 'Kaohsiung International Airport'],
    ['CYI', 'Chiayi Airport'],
    ['KYD', 'Lanyu Airport'],
    ['RMQ', 'Taichung Ching Chuang Kang Airport'],
    ['TNN', 'Tainan Airport'],
    ['HSZ', 'Hsinchu Air Base'],
    ['MZG', 'Makung Airport'],
    ['PIF', 'Pingtung North Airport'],
    ['TSA', 'Taipei Songshan Airport'],
    ['TPE', 'Taiwan Taoyuan International Airport'],
    ['WOT', 'Wang-an Airport'],
    ['HUN', 'Hualien Airport'],
    ['NRT', 'Narita International Airport'],
    ['MMJ', 'Matsumoto Airport'],
    ['IBR', 'Hyakuri Airport'],
    ['MUS', 'Minami Torishima Airport'],
    ['IWO', 'Iwo Jima Airport'],
    ['SHM', 'Nanki Shirahama Airport'],
    ['OBO', 'Tokachi-Obihiro Airport'],
    ['CTS', 'New Chitose Airport'],
    ['HKD', 'Hakodate Airport'],
    ['MMB', 'Memanbetsu Airport'],
    ['SHB', 'Nakashibetsu Airport'],
    ['WKJ', 'Wakkanai Airport'],
    ['IKI', 'Iki Airport'],
    ['UBJ', 'Yamaguchi Ube Airport'],
    ['TSJ', 'Tsushima Airport'],
    ['MBE', 'Monbetsu Airport'],
    ['AKJ', 'Asahikawa Airport'],
    ['OIR', 'Okushiri Airport'],
    ['RIS', 'Rishiri Airport'],
    ['KUM', 'Yakushima Airport'],
    ['FUJ', 'Fukue Airport'],
    ['FUK', 'Fukuoka Airport'],
    ['TNE', 'New Tanegashima Airport'],
    ['KOJ', 'Kagoshima Airport'],
    ['KMI', 'Miyazaki Airport'],
    ['OIT', 'Oita Airport'],
    ['KKJ', 'Kitakyūshū Airport'],
    ['KMJ', 'Kumamoto Airport'],
    ['NGS', 'Nagasaki Airport'],
    ['ASJ', 'Amami Airport'],
    ['OKE', 'Okierabu Airport'],
    ['TKN', 'Tokunoshima Airport'],
    ['FKJ', 'Fukui Airport'],
    ['QGU', 'Gifu Airport'],
    ['KMQ', 'Komatsu Airport'],
    ['OKI', 'Oki Airport'],
    ['TOY', 'Toyama Airport'],
    ['HIJ', 'Hiroshima Airport'],
    ['OKJ', 'Okayama Airport'],
    ['IZO', 'Izumo Airport'],
    ['YGJ', 'Miho Yonago Airport'],
    ['KCZ', 'Kōchi Ryōma Airport'],
    ['MYJ', 'Matsuyama Airport'],
    ['ITM', 'Osaka International Airport'],
    ['TTJ', 'Tottori Airport'],
    ['TKS', 'Tokushima Airport/JMSDF Air Base'],
    ['TAK', 'Takamatsu Airport'],
    ['AOJ', 'Aomori Airport'],
    ['GAJ', 'Yamagata Airport'],
    ['SDS', 'Sado Airport'],
    ['HHE', 'Hachinohe Airport'],
    ['HNA', 'Hanamaki Airport'],
    ['AXT', 'Akita Airport'],
    ['MSJ', 'Misawa Air Base'],
    ['SDJ', 'Sendai Airport'],
    ['NJA', 'Atsugi Naval Air Facility'],
    ['HAC', 'Hachijojima Airport'],
    ['OIM', 'Oshima Airport'],
    ['HND', 'Tokyo Haneda International Airport'],
    ['OKO', 'Yokota Air Base'],
    ['KWJ', 'Gwangju Airport'],
    ['CHN', 'Jeon Ju Airport (G-703)'],
    ['RSU', 'Yeosu Airport'],
    ['KAG', 'Gangneung Airport (K-18)'],
    ['CJU', 'Jeju International Airport'],
    ['CHF', 'Jinhae Airbase/Airport (G-813/K-10)'],
    ['PUS', 'Gimhae International Airport'],
    ['USN', 'Ulsan Airport'],
    ['SSN', 'Seoul Air Base (K-16)'],
    ['OSN', 'Osan Air Base'],
    ['GMP', 'Gimpo International Airport'],
    ['SWU', 'Suwon Airport'],
    ['KPO', 'Pohang Airport (G-815/K-3)'],
    ['TAE', 'Daegu Airport'],
    ['YEC', 'Yecheon Airbase'],
    ['OKA', 'Naha Airport'],
    ['DNA', 'Kadena Air Base'],
    ['ISG', 'New Ishigaki Airport'],
    ['UEO', 'Kumejima Airport'],
    ['MMD', 'Minami-Daito Airport'],
    ['MMY', 'Miyako Airport'],
    ['KTD', 'Kitadaito Airport'],
    ['SHI', 'Shimojishima Airport'],
    ['TRA', 'Tarama Airport'],
    ['RNJ', 'Yoron Airport'],
    ['OGN', 'Yonaguni Airport'],
    ['MNL', 'Ninoy Aquino International Airport'],
    ['CBO', 'Awang Airport'],
    ['PAG', 'Pagadian Airport'],
    ['GES', 'General Santos International Airport'],
    ['ZAM', 'Zamboanga International Airport'],
    ['BAG', 'Loakan Airport'],
    ['DTE', 'Daet Airport'],
    ['SJI', 'San Jose Airport'],
    ['MBO', 'Mamburao Airport'],
    ['BQA', 'Dr.Juan C. Angara Airport'],
    ['TAC', 'Daniel Z. Romualdez Airport'],
    ['BCD', 'Bacolod-Silay Airport'],
    ['DGT', 'Sibulan Airport'],
    ['MPH', 'Godofredo P. Ramos Airport'],
    ['ILO', 'Iloilo International Airport'],
    ['KLO', 'Kalibo International Airport'],
    ['PPS', 'Puerto Princesa Airport'],
    ['EUQ', 'Evelio Javier Airport'],
    ['COC', 'Comodoro Pierrestegui Airport'],
    ['GHU', 'Gualeguaychu Airport'],
    ['JNI', 'Junin Airport'],
    ['PRA', 'General Urquiza Airport'],
    ['ROS', 'Islas Malvinas Airport'],
    ['SFN', 'Sauce Viejo Airport'],
    ['AEP', 'Jorge Newbery Airpark'],
    ['COR', 'Ingeniero Ambrosio Taravella Airport'],
    ['FDO', 'San Fernando Airport'],
    ['LPG', 'La Plata Airport'],
    ['EPA', 'El Palomar Airport'],
    ['HOS', 'Chos Malal Airport'],
    ['GNR', 'Dr. Arturo H. Illia Airport'],
    ['MDZ', 'El Plumerillo Airport'],
    ['LGS', 'Comodoro D.R. Salomón Airport'],
    ['AFA', 'Suboficial Ay Santiago Germano Airport'],
    ['CTC', 'Catamarca Airport'],
    ['SDE', 'Vicecomodoro Angel D. La Paz Aragonés Airport'],
    ['IRJ', 'Capitan V A Almonacid Airport'],
    ['TUC', 'Teniente Benjamin Matienzo Airport'],
    ['UAQ', 'Domingo Faustino Sarmiento Airport'],
    ['RCU', 'Area De Material Airport'],
    ['VDR', 'Villa Dolores Airport'],
    ['VME', 'Villa Reynolds Airport'],
    ['LUQ', 'Brigadier Mayor D Cesar Raul Ojeda Airport'],
    ['CNQ', 'Corrientes Airport'],
    ['RES', 'Resistencia International Airport'],
    ['FMA', 'Formosa Airport'],
    ['IGR', 'Cataratas Del Iguazú International Airport'],
    ['AOL', 'Paso De Los Libres Airport'],
    ['MCS', 'Monte Caseros Airport'],
    ['PSS', 'Libertador Gral D Jose De San Martin Airport'],
    ['PRQ', 'Termal Airport'],
    ['SLA', 'Martin Miguel De Guemes International Airport'],
    ['JUJ', 'Gobernador Horacio Guzman International Airport'],
    ['ORA', 'Orán Airport'],
    ['ELO', 'El Dorado Airport'],
    ['OYA', 'Goya Airport'],
    ['RCQ', 'Reconquista Airport'],
    ['UZU', 'Curuzu Cuatia Airport'],
    ['EHL', 'El Bolson Airport'],
    ['CRD', 'General E. Mosconi Airport'],
    ['EQS', 'Brigadier Antonio Parodi Airport'],
    ['REL', 'Almirante Marco Andres Zar Airport'],
    ['VDM', 'Gobernador Castello Airport'],
    ['PMY', 'El Tehuelche Airport'],
    ['PUD', 'Puerto Deseado Airport'],
    ['RGA', 'Hermes Quijada International Airport'],
    ['RGL', 'Piloto Civil N. Fernández Airport'],
    ['USH', 'Malvinas Argentinas Airport'],
    ['ULA', 'Capitan D Daniel Vazquez Airport'],
    ['PMQ', 'Perito Moreno Airport'],
    ['RZA', 'Santa Cruz Airport'],
    ['BHI', 'Comandante Espora Airport'],
    ['CSZ', 'Brigadier D.H.E. Ruiz Airport'],
    ['OVR', 'Olavarria Airport'],
    ['GPO', 'General Pico Airport'],
    ['OYO', 'Tres Arroyos Airport'],
    ['MDQ', 'Ástor Piazzola International Airport'],
    ['NQN', 'Presidente Peron Airport'],
    ['PEH', 'Comodoro Pedro Zanni Airport'],
    ['RSA', 'Santa Rosa Airport'],
    ['BRC', 'San Carlos De Bariloche Airport'],
    ['TDL', 'Héroes De Malvinas Airport'],
    ['VLG', 'Villa Gesell Airport'],
    ['CUT', 'Cutral-Co Airport'],
    ['CPC', 'Aviador C. Campos Airport'],
    ['CDJ', 'Conceição do Araguaia Airport'],
    ['AQA', 'Araraquara Airport'],
    ['AJU', 'Santa Maria Airport'],
    ['AFL', 'Piloto Osvaldo Marques Dias Airport'],
    ['ARU', 'Araçatuba Airport'],
    ['BEL', 'Val de Cans/Júlio Cezar Ribeiro International Airport'],
    ['BGX', 'Comandante Gustavo Kraemer Airport'],
    ['PLU', 'Pampulha - Carlos Drummond de Andrade Airport'],
    ['BFH', 'Bacacheri Airport'],
    ['BSB', 'Presidente Juscelino Kubistschek International Airport'],
    ['BAU', 'Bauru Airport'],
    ['BVB', 'Atlas Brasil Cantanhede Airport'],
    ['BPG', 'Barra do Garças Airport'],
    ['CAC', 'Cascavel Airport'],
    ['CNF', 'Tancredo Neves International Airport'],
    ['CGR', 'Campo Grande Airport'],
    ['XAP', 'Serafin Enoss Bertaso Airport'],
    ['CLN', 'Brig. Lysias Augusto Rodrigues Airport'],
    ['CCM', 'Diomício Freitas Airport'],
    ['CAW', 'Bartolomeu Lisandro Airport'],
    ['CMG', 'Corumbá International Airport'],
    ['CWB', 'Afonso Pena Airport'],
    ['CRQ', 'Caravelas Airport'],
    ['CXJ', 'Hugo Cantergiani Regional Airport'],
    ['CGB', 'Marechal Rondon Airport'],
    ['CZS', 'Cruzeiro do Sul Airport'],
    ['PPB', 'Presidente Prudente Airport'],
    ['MAO', 'Eduardo Gomes International Airport'],
    ['JCR', 'Jacareacanga Airport'],
    ['IGU', 'Cataratas International Airport'],
    ['FLN', 'Hercílio Luz International Airport'],
    ['FEN', 'Fernando de Noronha Airport'],
    ['FOR', 'Pinto Martins International Airport'],
    ['GIG', 'Rio Galeão – Tom Jobim International Airport'],
    ['GJM', 'Guajará-Mirim Airport'],
    ['GYN', 'Santa Genoveva Airport'],
    ['GRU', 'Guarulhos - Governador André Franco Montoro International Airport'],
    ['GUJ', 'Guaratinguetá Airport'],
    ['ATM', 'Altamira Airport'],
    ['ITA', 'Itacoatiara Airport'],
    ['ITB', 'Itaituba Airport'],
    ['IOS', 'Bahia - Jorge Amado Airport'],
    ['IPN', 'Usiminas Airport'],
    ['ITR', 'Francisco Vilela do Amaral Airport'],
    ['IMP', 'Prefeito Renato Moreira Airport'],
    ['JDF', 'Francisco de Assis Airport'],
    ['JPA', 'Presidente Castro Pinto International Airport'],
    ['JOI', 'Lauro Carneiro de Loyola Airport'],
    ['CPV', 'Presidente João Suassuna Airport'],
    ['VCP', 'Viracopos International Airport'],
    ['LAJ', 'Lages Airport'],
    ['LIP', 'Lins Airport'],
    ['LDB', 'Governador José Richa Airport'],
    ['LAZ', 'Bom Jesus da Lapa Airport'],
    ['MAB', 'João Correa da Rocha Airport'],
    ['MEU', 'Monte Dourado Airport'],
    ['MGF', 'Regional de Maringá - Sílvio Nane Junior Airport'],
    ['MOC', 'Mário Ribeiro Airport'],
    ['PLL', 'Ponta Pelada Airport'],
    ['MCZ', 'Zumbi dos Palmares Airport'],
    ['MCP', 'Alberto Alcolumbre Airport'],
    ['MVF', 'Dix-Sept Rosado Airport'],
    ['MNX', 'Manicoré Airport'],
    ['NVT', 'Ministro Victor Konder International Airport'],
    ['GEL', 'Santo Ângelo Airport'],
    ['NAT', 'Governador Aluízio Alves International Airport'],
    ['OYK', 'Oiapoque Airport'],
    ['POA', 'Salgado Filho Airport'],
    ['PHB', 'Prefeito Doutor João Silva Filho Airport'],
    ['POO', 'Poços de Caldas - Embaixador Walther Moreira Salles Airport'],
    ['PFB', 'Lauro Kurtz Airport'],
    ['PET', 'João Simões Lopes Neto International Airport'],
    ['PNZ', 'Senador Nilo Coelho Airport'],
    ['PNB', 'Porto Nacional Airport'],
    ['PMG', 'Ponta Porã Airport'],
    ['PVH', 'Governador Jorge Teixeira de Oliveira Airport'],
    ['RBR', 'Plácido de Castro Airport'],
    ['REC', 'Guararapes - Gilberto Freyre International Airport'],
    ['SDU', 'Santos Dumont Airport'],
    ['RAO', 'Leite Lopes Airport'],
    ['SNZ', 'Santa Cruz Air Force Base'],
    ['SJK', 'Professor Urbano Ernesto Stumpf Airport'],
    ['SLZ', 'Marechal Cunha Machado International Airport'],
    ['CGH', 'Congonhas Airport'],
    ['SJP', 'Prof. Eribelto Manoel Reino State Airport'],
    ['SSZ', 'Base Aérea de Santos Airport'],
    ['SSA', 'Deputado Luiz Eduardo Magalhães International Airport'],
    ['TMT', 'Trombetas Airport'],
    ['THE', 'Senador Petrônio Portela Airport'],
    ['TFF', 'Tefé Airport'],
    ['TRQ', 'Tarauacá Airport'],
    ['TEC', 'Telêmaco Borba Airport'],
    ['TBT', 'Tabatinga Airport'],
    ['TUR', 'Tucuruí Airport'],
    ['SJL', 'São Gabriel da Cachoeira Airport'],
    ['PAV', 'Paulo Afonso Airport'],
    ['URG', 'Rubem Berta Airport'],
    ['UDI', 'Ten. Cel. Aviador César Bombonato Airport'],
    ['UBA', 'Mário de Almeida Franco Airport'],
    ['VAG', 'Major Brigadeiro Trompowsky Airport'],
    ['BVH', 'Brigadeiro Camarão Airport'],
    ['VIX', 'Eurico de Aguiar Salles Airport'],
    ['QPS', 'Campo Fontenelle Airport'],
    ['ARI', 'Chacalluta Airport'],
    ['BBA', 'Balmaceda Airport'],
    ['CCH', 'Chile Chico Airport'],
    ['CJC', 'El Loa Airport'],
    ['YAI', 'Gral. Bernardo O´Higgins Airport'],
    ['PUQ', 'Pdte. Carlos Ibañez del Campo Airport'],
    ['GXQ', 'Teniente Vidal Airport'],
    ['IQQ', 'Diego Aracena Airport'],
    ['SCL', 'Comodoro Arturo Merino Benítez International Airport'],
    ['ANF', 'Andrés Sabella Gálvez International Airport'],
    ['WPR', 'Capitan Fuentes Martinez Airport Airport'],
    ['FFU', 'Futaleufú Airport'],
    ['LSQ', 'María Dolores Airport'],
    ['WPU', 'Guardiamarina Zañartu Airport'],
    ['CCP', 'Carriel Sur Airport'],
    ['IPC', 'Mataveri Airport'],
    ['ZOS', 'Cañal Bajo Carlos - Hott Siebert Airport'],
    ['VLR', 'Vallenar Airport'],
    ['QRC', 'De La Independencia Airport'],
    ['TNM', 'Teniente Rodolfo Marsh Martin Base'],
    ['LSC', 'La Florida Airport'],
    ['PZS', 'Maquehue Airport'],
    ['PMC', 'El Tepual Airport'],
    ['WCH', 'Chaitén Airport'],
    ['ZAL', 'Pichoy Airport'],
    ['ATF', 'Chachoán Airport'],
    ['OCC', 'Francisco De Orellana Airport'],
    ['CUE', 'Mariscal Lamar Airport'],
    ['GPS', 'Seymour Airport'],
    ['GYE', 'José Joaquín de Olmedo International Airport'],
    ['LTX', 'Cotopaxi International Airport'],
    ['MRR', 'Jose Maria Velasco Ibarra Airport'],
    ['XMS', 'Coronel E Carvajal Airport'],
    ['MCH', 'General Manuel Serrano Airport'],
    ['MEC', 'Eloy Alfaro International Airport'],
    ['PVO', 'Reales Tamarindos Airport'],
    ['UIO', 'Mariscal Sucre International Airport'],
    ['ETR', 'Santa Rosa International Airport'],
    ['SNC', 'General Ulpiano Paez Airport'],
    ['TPC', 'Tarapoa Airport'],
    ['TUA', 'Teniente Coronel Luis a Mantilla Airport'],
    ['ASU', 'Silvio Pettirossi International Airport'],
    ['AYO', 'Juan De Ayolas Airport'],
    ['CIO', 'Teniente Col Carmelo Peralta Airport'],
    ['ESG', 'Dr. Luis Maria Argaña International Airport'],
    ['PIL', 'Carlos Miguel Gimenez Airport'],
    ['AXM', 'El Eden Airport'],
    ['PUU', 'Tres De Mayo Airport'],
    ['ELB', 'Las Flores Airport'],
    ['BGA', 'Palonegro Airport'],
    ['BOG', 'El Dorado International Airport'],
    ['BAQ', 'Ernesto Cortissoz International Airport'],
    ['BSC', 'José Celestino Mutis Airport'],
    ['BUN', 'Gerardo Tobar López Airport'],
    ['CUC', 'Camilo Daza International Airport'],
    ['CTG', 'Rafael Nuñez International Airport'],
    ['CLO', 'Alfonso Bonilla Aragon International Airport'],
    ['TCO', 'La Florida Airport'],
    ['CZU', 'Las Brujas Airport'],
    ['EJA', 'Yariguíes Airport'],
    ['FLA', 'Gustavo Artunduaga Paredes Airport'],
    ['GIR', 'Santiago Vila Airport'],
    ['GPI', 'Juan Casiano Airport'],
    ['IBE', 'Perales Airport'],
    ['IPI', 'San Luis Airport'],
    ['APO', 'Antonio Roldan Betancourt Airport'],
    ['MCJ', 'Jorge Isaac Airport'],
    ['LET', 'Alfredo Vásquez Cobo International Airport'],
    ['EOH', 'Enrique Olaya Herrera Airport'],
    ['MGN', 'Baracoa Airport'],
    ['MTR', 'Los Garzones Airport'],
    ['MVP', 'Fabio Alberto Leon Bentley Airport'],
    ['MZL', 'La Nubia Airport'],
    ['NVA', 'Benito Salas Airport'],
    ['OCV', 'Aguas Claras Airport'],
    ['OTU', 'Otu Airport'],
    ['PCR', 'German Olano Airport'],
    ['PEI', 'Matecaña International Airport'],
    ['PTX', 'Pitalito Airport'],
    ['PPN', 'Guillermo León Valencia Airport'],
    ['PSO', 'Antonio Narino Airport'],
    ['PVA', 'El Embrujo Airport'],
    ['MQU', 'Mariquita Airport'],
    ['MDE', 'Jose Maria Córdova International Airport'],
    ['RCH', 'Almirante Padilla Airport'],
    ['SJE', 'Jorge E. Gonzalez Torres Airport'],
    ['SMR', 'Simón Bolívar International Airport'],
    ['ADZ', 'Gustavo Rojas Pinilla International Airport'],
    ['SVI', 'Eduardo Falla Solano Airport'],
    ['TME', 'Gustavo Vargas Airport'],
    ['AUC', 'Santiago Perez Airport'],
    ['UIB', 'El Caraño Airport'],
    ['ULQ', 'Heriberto Gíl Martínez Airport'],
    ['VUP', 'Alfonso López Pumarejo Airport'],
    ['VVC', 'Vanguardia Airport'],
    ['BJO', 'Bermejo Airport'],
    ['CBB', 'Jorge Wilsterman International Airport'],
    ['CCA', 'Chimore Airport'],
    ['CIJ', 'Capitán Aníbal Arab Airport'],
    ['LPB', 'El Alto International Airport'],
    ['ORU', 'Juan Mendoza Airport'],
    ['POI', 'Capitan Nicolas Rojas Airport'],
    ['PSZ', 'Capitán Av. Salvador Ogaya G. airport'],
    ['SBL', 'Santa Ana Del Yacuma Airport'],
    ['SRE', 'Juana Azurduy De Padilla Airport'],
    ['TJA', 'Capitan Oriel Lea Plaza Airport'],
    ['TDD', 'Teniente Av. Jorge Henrich Arauz Airport'],
    ['VLM', 'Teniente Coronel Rafael Pabón Airport'],
    ['VVI', 'Viru Viru International Airport'],
    ['BYC', 'Yacuiba Airport'],
    ['PBM', 'Johan Adolf Pengel International Airport'],
    ['CAY', 'Cayenne-Rochambeau Airport'],
    ['OYP', "Saint-Georges-de-l'Oyapock Airport"],
    ['AOP', 'Alferez FAP Alfredo Vladimir Sara Bauer Airport'],
    ['IBP', 'Iberia Airport'],
    ['PCL', 'Cap FAP David Abenzur Rengifo International Airport'],
    ['CHM', 'Teniente FAP Jaime A De Montreuil Morales Airport'],
    ['CIX', 'Capitan FAP Jose A Quinones Gonzales International Airport'],
    ['AYP', 'Coronel FAP Alfredo Mendivil Duarte Airport'],
    ['ANS', 'Andahuaylas Airport'],
    ['ATA', 'Comandante FAP German Arias Graziani Airport'],
    ['LIM', 'Jorge Chávez International Airport'],
    ['JJI', 'Juanjui Airport'],
    ['JAU', 'Francisco Carle Airport'],
    ['JUL', 'Inca Manco Capac International Airport'],
    ['ILQ', 'Ilo Airport'],
    ['TBP', 'Capitan FAP Pedro Canga Rodriguez Airport'],
    ['YMS', 'Moises Benzaquen Rengifo Airport'],
    ['CHH', 'Chachapoyas Airport'],
    ['IQT', 'Coronel FAP Francisco Secada Vignetta International Airport'],
    ['AQP', 'Rodríguez Ballón International Airport'],
    ['TRU', 'Capitan FAP Carlos Martinez De Pinillos International Airport'],
    ['PIO', 'Capitán FAP Renán Elías Olivera International Airport'],
    ['TPP', 'Cadete FAP Guillermo Del Castillo Paredes Airport'],
    ['TCQ', 'Coronel FAP Carlos Ciriani Santa Rosa International Airport'],
    ['PEM', 'Padre Aldamiz International Airport'],
    ['PIU', 'Capitán FAP Guillermo Concha Iberico International Airport'],
    ['TYL', 'Capitan Montes Airport'],
    ['CUZ', 'Alejandro Velasco Astete International Airport'],
    ['DZO', 'Santa Bernardina International Airport'],
    ['MVD', 'Carrasco International /General C L Berisso Airport'],
    ['STY', 'Nueva Hesperides International Airport'],
    ['AGV', 'Oswaldo Guevara Mujica Airport'],
    ['AAO', 'Anaco Airport'],
    ['BLA', 'General José Antonio Anzoategui International Airport'],
    ['BNS', 'Barinas Airport'],
    ['BRM', 'Barquisimeto International Airport'],
    ['CBL', "Aeropuerto 'General Tomas de Heres' Ciudad Bolivar"],
    ['CXA', 'Caicara del Orinoco Airport'],
    ['CLZ', 'Calabozo Airport'],
    ['CAJ', 'Canaima Airport'],
    ['VCR', 'Carora Airport'],
    ['CUP', 'General Francisco Bermúdez Airport'],
    ['CZE', 'José Leonardo Chirinos Airport'],
    ['CUM', 'Cumaná (Antonio José de Sucre) Airport'],
    ['EOR', 'El Dorado Airport'],
    ['EOZ', 'Elorza Airport'],
    ['GDO', 'Guasdalito Airport'],
    ['GUI', 'Guiria Airport'],
    ['GUQ', 'Guanare Airport'],
    ['HGE', 'Higuerote Airport'],
    ['ICC', 'Andrés Miguel Salazar Marcano Airport'],
    ['LSP', 'Josefa Camejo International Airport'],
    ['LFR', 'La Fria Airport'],
    ['MAR', 'La Chinita International Airport'],
    ['MRD', 'Alberto Carnevalli Airport'],
    ['PMV', 'Del Caribe Santiago Mariño International Airport'],
    ['CCS', 'Simón Bolívar International Airport'],
    ['MUN', 'Maturín Airport'],
    ['PYH', 'Cacique Aramare Airport'],
    ['PBL', 'General Bartolome Salom International Airport'],
    ['SCI', 'Paramillo Airport'],
    ['PZO', 'General Manuel Carlos Piar International Airport'],
    ['PTM', 'Palmarito Airport'],
    ['SVZ', 'San Antonio Del Tachira Airport'],
    ['SBB', 'Santa Bárbara de Barinas Airport'],
    ['SNV', 'Santa Elena de Uairen Airport'],
    ['STD', 'Mayor Buenaventura Vivas International Airport'],
    ['SNF', 'Sub Teniente Nestor Arias Airport'],
    ['SFD', 'San Fernando De Apure Airport'],
    ['SOM', 'San Tomé Airport'],
    ['STB', 'Santa Bárbara del Zulia Airport'],
    ['TUV', 'Tucupita Airport'],
    ['TMO', 'Tumeremo Airport'],
    ['VLN', 'Arturo Michelena International Airport'],
    ['VLV', 'Dr. Antonio Nicolás Briceño Airport'],
    ['VDP', 'Valle de La Pascua Airport'],
    ['LTM', 'Lethem Airport'],
    ['ANU', 'V.C. Bird International Airport'],
    ['BGI', 'Sir Grantley Adams International Airport'],
    ['DCF', 'Canefield Airport'],
    ['DOM', 'Douglas-Charles Airport'],
    ['FDF', 'Martinique Aimé Césaire International Airport'],
    ['SFG', "L'Espérance Airport"],
    ['PTP', 'Pointe-à-Pitre Le Raizet'],
    ['GND', 'Point Salines International Airport'],
    ['STT', 'Cyril E. King Airport'],
    ['STX', 'Henry E Rohlsen Airport'],
    ['BQN', 'Rafael Hernandez Airport'],
    ['FAJ', 'Diego Jimenez Torres Airport'],
    ['SIG', 'Fernando Luis Ribas Dominicci Airport'],
    ['MAZ', 'Eugenio Maria De Hostos Airport'],
    ['PSE', 'Mercedita Airport'],
    ['SJU', 'Luis Munoz Marin International Airport'],
    ['SKB', 'Robert L. Bradshaw International Airport'],
    ['SLU', 'George F. L. Charles Airport'],
    ['UVF', 'Hewanorra International Airport'],
    ['AUA', 'Queen Beatrix International Airport'],
    ['BON', 'Flamingo International Airport'],
    ['CUR', 'Hato International Airport'],
    ['EUX', 'F. D. Roosevelt Airport'],
    ['SXM', 'Princess Juliana International Airport'],
    ['AXA', 'Clayton J Lloyd International Airport'],
    ['TAB', 'Tobago-Crown Point Airport'],
    ['POS', 'Piarco International Airport'],
    ['EIS', 'Terrance B. Lettsome International Airport'],
    ['CIW', 'Canouan Airport'],
    ['MQS', 'Mustique Airport'],
    ['SVD', 'Argyle International Airport'],
    ['ALA', 'Almaty Airport'],
    ['BXH', 'Balkhash Airport'],
    ['TSE', 'Astana International Airport'],
    ['DMB', 'Taraz Airport'],
    ['FRU', 'Manas International Airport'],
    ['OSS', 'Osh Airport'],
    ['CIT', 'Shymkent Airport'],
    ['URA', 'Uralsk Airport'],
    ['PWQ', 'Pavlodar Airport'],
    ['PLX', 'Semipalatinsk Airport'],
    ['AKX', 'Aktobe Airport'],
    ['GYD', 'Heydar Aliyev International Airport'],
    ['YKS', 'Yakutsk Airport'],
    ['MJZ', 'Mirny Airport'],
    ['BQS', 'Ignatyevo Airport'],
    ['KHV', 'Khabarovsk-Novy Airport'],
    ['PVS', 'Provideniya Bay Airport'],
    ['GDX', 'Sokol Airport'],
    ['PWE', 'Pevek Airport'],
    ['PKC', 'Yelizovo Airport'],
    ['UUS', 'Yuzhno-Sakhalinsk Airport'],
    ['VVO', 'Vladivostok International Airport'],
    ['HTA', 'Chita-Kadala Airport'],
    ['BTK', 'Bratsk Airport'],
    ['IKT', 'Irkutsk Airport'],
    ['UUD', 'Ulan-Ude Airport (Mukhino)'],
    ['KBP', 'Boryspil International Airport'],
    ['DOK', 'Donetsk International Airport'],
    ['DNK', 'Dnipropetrovsk International Airport'],
    ['SIP', 'Simferopol International Airport'],
    ['IEV', 'Kiev Zhuliany International Airport'],
    ['LWO', 'Lviv International Airport'],
    ['ODS', 'Odessa International Airport'],
    ['LED', 'Pulkovo Airport'],
    ['MMK', 'Murmansk Airport'],
    ['GME', 'Gomel Airport'],
    ['VTB', 'Vitebsk Vostochny Airport'],
    ['KGD', 'Khrabrovo Airport'],
    ['MHP', 'Minsk 1 Airport'],
    ['MSQ', 'Minsk National Airport'],
    ['ABA', 'Abakan Airport'],
    ['BAX', 'Barnaul Airport'],
    ['KEJ', 'Kemerovo Airport'],
    ['OMS', 'Omsk Central Airport'],
    ['KRR', 'Krasnodar Pashkovsky International Airport'],
    ['MCX', 'Uytash Airport'],
    ['MRV', 'Mineralnyye Vody Airport'],
    ['STW', 'Stavropol Shpakovskoye Airport'],
    ['ROV', 'Platov International Airport'],
    ['AER', 'Sochi International Airport'],
    ['ASF', 'Astrakhan Airport'],
    ['VOG', 'Volgograd International Airport'],
    ['CEK', 'Chelyabinsk Balandino Airport'],
    ['MQF', 'Magnitogorsk International Airport'],
    ['NJC', 'Nizhnevartovsk Airport'],
    ['PEE', 'Bolshoye Savino Airport'],
    ['SGC', 'Surgut Airport'],
    ['SVX', 'Koltsovo Airport'],
    ['ASB', 'Ashgabat International Airport'],
    ['KRW', 'Turkmenbashi Airport'],
    ['CRZ', 'Turkmenabat Airport'],
    ['DYU', 'Dushanbe Airport'],
    ['BHK', 'Bukhara Airport'],
    ['SKD', 'Samarkand Airport'],
    ['TAS', 'Tashkent International Airport'],
    ['BZK', 'Bryansk Airport'],
    ['SVO', 'Sheremetyevo International Airport'],
    ['KLD', 'Migalovo Air Base'],
    ['VOZ', 'Voronezh International Airport'],
    ['VKO', 'Vnukovo International Airport'],
    ['SCW', 'Syktyvkar Airport'],
    ['KZN', 'Kazan International Airport'],
    ['REN', 'Orenburg Central Airport'],
    ['UFA', 'Ufa International Airport'],
    ['KUF', 'Kurumoch International Airport'],
    ['AMD', 'Sardar Vallabhbhai Patel International Airport'],
    ['AKD', 'Akola Airport'],
    ['IXU', 'Aurangabad Airport'],
    ['BOM', 'Chhatrapati Shivaji International Airport'],
    ['PAB', 'Bilaspur Airport'],
    ['BHJ', 'Bhuj Airport'],
    ['IXG', 'Belgaum Airport'],
    ['BDQ', 'Vadodara Airport'],
    ['BHO', 'Raja Bhoj International Airport'],
    ['BHU', 'Bhavnagar Airport'],
    ['NMB', 'Daman Airport'],
    ['GUX', 'Guna Airport'],
    ['GOI', 'Dabolim Airport'],
    ['IDR', 'Devi Ahilyabai Holkar Airport'],
    ['JLR', 'Jabalpur Airport'],
    ['JGA', 'Jamnagar Airport'],
    ['IXY', 'Kandla Airport'],
    ['HJR', 'Khajuraho Airport'],
    ['KLH', 'Kolhapur Airport'],
    ['IXK', 'Keshod Airport'],
    ['NAG', 'Dr. Babasaheb Ambedkar International Airport'],
    ['ISK', 'Nashik Airport'],
    ['PNQ', 'Pune Airport'],
    ['PBD', 'Porbandar Airport'],
    ['RAJ', 'Rajkot Airport'],
    ['RPR', 'Raipur Airport'],
    ['SSE', 'Solapur Airport'],
    ['STV', 'Surat Airport'],
    ['UDR', 'Maharana Pratap Airport'],
    ['CMB', 'Bandaranaike International Colombo Airport'],
    ['ACJ', 'Anuradhapura Air Force Base'],
    ['BTC', 'Batticaloa Airport'],
    ['RML', 'Colombo Ratmalana Airport'],
    ['ADP', 'Ampara Airport'],
    ['JAF', 'Kankesanturai Airport'],
    ['TRR', 'China Bay Airport'],
    ['KZC', 'Kampong Chhnang Airport'],
    ['PNH', 'Phnom Penh International Airport'],
    ['REP', 'Siem Reap International Airport'],
    ['TNX', 'Stung Treng Airport'],
    ['IXV', 'Along Airport'],
    ['IXA', 'Agartala Airport'],
    ['AJL', 'Lengpui Airport'],
    ['IXB', 'Bagdogra Airport'],
    ['BBI', 'Biju Patnaik Airport'],
    ['CCU', 'Netaji Subhash Chandra Bose International Airport'],
    ['COH', 'Cooch Behar Airport'],
    ['DBD', 'Dhanbad Airport'],
    ['GAY', 'Gaya Airport'],
    ['IMF', 'Imphal Airport'],
    ['IXW', 'Sonari Airport'],
    ['JRH', 'Jorhat Airport'],
    ['IXH', 'Kailashahar Airport'],
    ['IXS', 'Silchar Airport'],
    ['IXI', 'North Lakhimpur Airport'],
    ['DIB', 'Dibrugarh Airport'],
    ['MZU', 'Muzaffarpur Airport'],
    ['PAT', 'Lok Nayak Jayaprakash Airport'],
    ['IXR', 'Birsa Munda Airport'],
    ['RRK', 'Rourkela Airport'],
    ['VTZ', 'Vishakhapatnam Airport'],
    ['ZER', 'Ziro Airport'],
    ['CXB', "Cox's Bazar Airport"],
    ['CGP', 'Shah Amanat International Airport'],
    ['IRD', 'Ishurdi Airport'],
    ['JSR', 'Jessore Airport'],
    ['RJH', 'Shah Mokhdum Airport'],
    ['SPD', 'Saidpur Airport'],
    ['ZYL', 'Osmany International Airport'],
    ['DAC', 'Hazrat Shahjalal International Airport'],
    ['HKG', 'Hong Kong International Airport'],
    ['AGR', 'Agra Airport'],
    ['IXD', 'Allahabad Airport'],
    ['ATQ', 'Sri Guru Ram Dass Jee International Airport'],
    ['BKB', 'Nal Airport'],
    ['VNS', 'Lal Bahadur Shastri Airport'],
    ['KUU', 'Kullu Manali Airport'],
    ['BUP', 'Bhatinda Air Force Station'],
    ['BEK', 'Bareilly Air Force Station'],
    ['IXC', 'Chandigarh Airport'],
    ['KNU', 'Kanpur Airport'],
    ['DED', 'Dehradun Airport'],
    ['DEL', 'Indira Gandhi International Airport'],
    ['GWL', 'Gwalior Airport'],
    ['HSS', 'Hissar Airport'],
    ['JDH', 'Jodhpur Airport'],
    ['JAI', 'Jaipur International Airport'],
    ['JSA', 'Jaisalmer Airport'],
    ['IXJ', 'Jammu Airport'],
    ['KTU', 'Kota Airport'],
    ['LUH', 'Ludhiana Airport'],
    ['IXL', 'Leh Kushok Bakula Rimpochee Airport'],
    ['LKO', 'Chaudhary Charan Singh International Airport'],
    ['IXP', 'Pathankot Airport'],
    ['PGH', 'Pantnagar Airport'],
    ['SXR', 'Sheikh ul Alam Airport'],
    ['TNI', 'Satna Airport'],
    ['LPQ', 'Luang Phabang International Airport'],
    ['PKZ', 'Pakse International Airport'],
    ['ZVK', 'Savannakhet Airport'],
    ['NEU', 'Sam Neua Airport'],
    ['VTE', 'Wattay International Airport'],
    ['MFM', 'Macau International Airport'],
    ['BWA', 'Gautam Buddha Airport'],
    ['JKR', 'Janakpur Airport'],
    ['KTM', 'Tribhuvan International Airport'],
    ['PKR', 'Pokhara Airport'],
    ['SIF', 'Simara Airport'],
    ['BIR', 'Biratnagar Airport'],
    ['AGX', 'Agatti Airport'],
    ['BLR', 'Kempegowda International Airport'],
    ['BEP', 'Bellary Airport'],
    ['VGA', 'Vijayawada Airport'],
    ['CJB', 'Coimbatore International Airport'],
    ['COK', 'Cochin International Airport'],
    ['CCJ', 'Calicut International Airport'],
    ['CDP', 'Kadapa Airport'],
    ['CBD', 'Car Nicobar Air Force Station'],
    ['BPM', 'Begumpet Airport'],
    ['IXM', 'Madurai Airport'],
    ['IXE', 'Mangalore International Airport'],
    ['MAA', 'Chennai International Airport'],
    ['IXZ', 'Vir Savarkar International Airport'],
    ['PNY', 'Pondicherry Airport'],
    ['RJA', 'Rajahmundry Airport'],
    ['SXV', 'Salem Airport'],
    ['TJV', 'Tanjore Air Force Base'],
    ['TIR', 'Tirupati Airport'],
    ['TRZ', 'Tiruchirapally Civil Airport Airport'],
    ['TRV', 'Trivandrum International Airport'],
    ['PBH', 'Paro Airport'],
    ['MLE', 'Malé International Airport'],
    ['DMK', 'Don Mueang International Airport'],
    ['KDT', 'Kamphaeng Saen Airport'],
    ['UTP', 'U-Tapao International Airport'],
    ['LPT', 'Lampang Airport'],
    ['PRH', 'Phrae Airport'],
    ['HHQ', 'Hua Hin Airport'],
    ['TKH', 'Takhli Airport'],
    ['PHS', 'Phitsanulok Airport'],
    ['NAW', 'Narathiwat Airport'],
    ['KBV', 'Krabi Airport'],
    ['SGZ', 'Songkhla Airport'],
    ['PAN', 'Pattani Airport'],
    ['USM', 'Samui Airport'],
    ['HKT', 'Phuket International Airport'],
    ['UNN', 'Ranong Airport'],
    ['HDY', 'Hat Yai International Airport'],
    ['TST', 'Trang Airport'],
    ['UTH', 'Udon Thani Airport'],
    ['SNO', 'Sakon Nakhon Airport'],
    ['PXR', 'Surin Airport'],
    ['LOE', 'Loei Airport'],
    ['DAD', 'Da Nang International Airport'],
    ['HAN', 'Noi Bai International Airport'],
    ['NHA', 'Nha Trang Air Base'],
    ['HUI', 'Phu Bai Airport'],
    ['PQC', 'Phu Quoc International Airport'],
    ['SGN', 'Tan Son Nhat International Airport'],
    ['VBA', 'Ann Airport'],
    ['NYU', 'Bagan Airport'],
    ['HEH', 'Heho Airport'],
    ['HOX', 'Hommalinn Airport'],
    ['KET', 'Kengtung Airport'],
    ['KYP', 'Kyaukpyu Airport'],
    ['LSH', 'Lashio Airport'],
    ['MDL', 'Mandalay International Airport'],
    ['MGZ', 'Myeik Airport'],
    ['MYT', 'Myitkyina Airport'],
    ['MOE', 'Momeik Airport'],
    ['MOG', 'Mong Hsat Airport'],
    ['NMS', 'Namsang Airport'],
    ['PAA', 'Hpa-N Airport'],
    ['PBU', 'Putao Airport'],
    ['PRU', 'Pyay Airport'],
    ['AKY', 'Sittwe Airport'],
    ['SNW', 'Thandwe Airport'],
    ['THL', 'Tachileik Airport'],
    ['RGN', 'Yangon International Airport'],
    ['UPG', 'Hasanuddin International Airport'],
    ['BIK', 'Frans Kaisiepo Airport'],
    ['NBX', 'Nabire Airport'],
    ['TIM', 'Moses Kilangin Airport'],
    ['DJJ', 'Sentani International Airport'],
    ['WMX', 'Wamena Airport'],
    ['MKQ', 'Mopah Airport'],
    ['GTO', 'Jalaluddin Airport'],
    ['PLW', 'Mutiara Airport'],
    ['MDC', 'Sam Ratulangi Airport'],
    ['PSJ', 'Kasiguncu Airport'],
    ['OTI', 'Pitu Airport'],
    ['TTE', 'Sultan Khairun Babullah Airport'],
    ['LUW', 'Syukuran Aminuddin Amir Airport'],
    ['AMQ', 'Pattimura Airport, Ambon'],
    ['FKQ', 'Fakfak Airport'],
    ['KNG', 'Kaimana Airport'],
    ['BXB', 'Babo Airport'],
    ['MKW', 'Rendani Airport'],
    ['SOQ', 'Dominique Edward Osok Airport'],
    ['BTU', 'Bintulu Airport'],
    ['KCH', 'Kuching International Airport'],
    ['LMN', 'Limbang Airport'],
    ['MUR', 'Marudi Airport'],
    ['MYY', 'Miri Airport'],
    ['SBW', 'Sibu Airport'],
    ['LDU', 'Lahad Datu Airport'],
    ['BKI', 'Kota Kinabalu International Airport'],
    ['LBU', 'Labuan Airport'],
    ['TWU', 'Tawau Airport'],
    ['BWN', 'Brunei International Airport'],
    ['PKU', 'Sultan Syarif Kasim Ii (Simpang Tiga) Airport'],
    ['DUM', 'Pinang Kampai Airport'],
    ['CGK', 'Soekarno-Hatta International Airport'],
    ['GNS', 'Binaka Airport'],
    ['AEG', 'Aek Godang Airport'],
    ['PDG', 'Minangkabau International Airport'],
    ['MES', 'Soewondo Air Force Base'],
    ['FLZ', 'Dr Ferdinand Lumban Tobing Airport'],
    ['NPO', 'Nanga Pinoh Airport'],
    ['KTG', 'Ketapang(Rahadi Usman) Airport'],
    ['PNK', 'Supadio Airport'],
    ['DJB', 'Sultan Thaha Airport'],
    ['BKS', 'Fatmawati Soekarno Airport'],
    ['PLM', 'Sultan Mahmud Badaruddin II Airport'],
    ['RGT', 'Japura Airport'],
    ['LSX', 'Lhok Sukon Airport'],
    ['BTJ', 'Sultan Iskandar Muda International Airport'],
    ['AOR', 'Sultan Abdul Halim Airport'],
    ['BWH', 'Butterworth Airport'],
    ['KBR', 'Sultan Ismail Petra Airport'],
    ['KUA', 'Kuantan Airport'],
    ['KTE', 'Kerteh Airport'],
    ['IPH', 'Sultan Azlan Shah Airport'],
    ['JHB', 'Senai International Airport'],
    ['KUL', 'Kuala Lumpur International Airport'],
    ['LGK', 'Langkawi International Airport'],
    ['MKZ', 'Malacca Airport'],
    ['TGG', 'Sultan Mahmud Airport'],
    ['PEN', 'Penang International Airport'],
    ['UAI', 'Suai Airport'],
    ['DIL', 'Presidente Nicolau Lobato International Airport'],
    ['BCH', 'Cakung Airport'],
    ['QPG', 'Paya Lebar Air Base'],
    ['TGA', 'Tengah Air Base'],
    ['XSP', 'Seletar Airport'],
    ['SIN', 'Singapore Changi Airport'],
    ['ACF', 'Brisbane Archerfield Airport'],
    ['ABM', 'Northern Peninsula Airport'],
    ['ASP', 'Alice Springs Airport'],
    ['BNE', 'Brisbane International Airport'],
    ['OOL', 'Gold Coast Airport'],
    ['CNS', 'Cairns International Airport'],
    ['CTL', 'Charleville Airport'],
    ['ISA', 'Mount Isa Airport'],
    ['MCY', 'Sunshine Coast Airport'],
    ['MKY', 'Mackay Airport'],
    ['PPP', 'Proserpine Whitsunday Coast Airport'],
    ['ROK', 'Rockhampton Airport'],
    ['TSV', 'Townsville Airport'],
    ['WEI', 'Weipa Airport'],
    ['AVV', 'Avalon Airport'],
    ['ABX', 'Albury Airport'],
    ['MEB', 'Melbourne Essendon Airport'],
    ['HBA', 'Hobart International Airport'],
    ['LST', 'Launceston Airport'],
    ['MBW', 'Melbourne Moorabbin Airport'],
    ['MEL', 'Melbourne International Airport'],
    ['ADL', 'Adelaide International Airport'],
    ['JAD', 'Perth Jandakot Airport'],
    ['KTA', 'Karratha Airport'],
    ['KGI', 'Kalgoorlie Boulder Airport'],
    ['KNX', 'Kununurra Airport'],
    ['LEA', 'Learmonth Airport'],
    ['PHE', 'Port Hedland International Airport'],
    ['PER', 'Perth International Airport'],
    ['UMR', 'Woomera Airfield'],
    ['XCH', 'Christmas Island Airport'],
    ['BWU', 'Sydney Bankstown Airport'],
    ['CBR', 'Canberra International Airport'],
    ['CFS', 'Coffs Harbour Airport'],
    ['CDU', 'Camden Airport'],
    ['DBO', 'Dubbo City Regional Airport'],
    ['NLK', 'Norfolk Island International Airport'],
    ['XRH', 'RAAF Base Richmond'],
    ['SYD', 'Sydney Kingsford Smith International Airport'],
    ['TMW', 'Tamworth Airport'],
    ['WGA', 'Wagga Wagga City Airport'],
    ['PEK', 'Beijing Capital International Airport'],
    ['HLD', 'Dongshan Airport'],
    ['TSN', 'Tianjin Binhai International Airport'],
    ['TYN', 'Taiyuan Wusu Airport'],
    ['CAN', 'Guangzhou Baiyun International Airport'],
    ['CSX', 'Changsha Huanghua International Airport'],
    ['KWL', 'Guilin Liangjiang International Airport'],
    ['NNG', 'Nanning Wuxu Airport'],
    ['SZX', "Shenzhen Bao'an International Airport"],
    ['CGO', 'Zhengzhou Xinzheng International Airport'],
    ['WUH', 'Wuhan Tianhe International Airport'],
    ['FNJ', 'Pyongyang Sunan International Airport'],
    ['LHW', 'Lanzhou Zhongchuan Airport'],
    ['XIY', "Xi'an Xianyang International Airport"],
    ['ULN', 'Chinggis Khaan International Airport'],
    ['JHG', 'Xishuangbanna Gasa Airport'],
    ['KMG', 'Kunming Changshui International Airport'],
    ['XMN', 'Xiamen Gaoqi International Airport'],
    ['KHN', 'Nanchang Changbei International Airport'],
    ['FOC', 'Fuzhou Changle International Airport'],
    ['HGH', 'Hangzhou Xiaoshan International Airport'],
    ['NGB', 'Ningbo Lishe International Airport'],
    ['NKG', 'Nanjing Lukou Airport'],
    ['HFE', 'Hefei Luogang International Airport'],
    ['TAO', 'Liuting Airport'],
    ['SHA', 'Shanghai Hongqiao International Airport'],
    ['YNT', 'Yantai Laishan Airport'],
    ['CKG', 'Chongqing Jiangbei International Airport'],
    ['KWE', 'Longdongbao Airport'],
    ['CTU', 'Chengdu Shuangliu International Airport'],
    ['XIC', 'Xichang Qingshan Airport'],
    ['KHG', 'Kashgar Airport'],
    ['HTN', 'Hotan Airport'],
    ['URC', 'Ürümqi Diwopu International Airport'],
    ['HRB', 'Taiping Airport'],
    ['MDG', 'Mudanjiang Hailang International Airport'],
    ['DLC', 'Zhoushuizi Airport'],
    ['PVG', 'Shanghai Pudong International Airport'],
    ['TOD', 'Pulau Tioman Airport'],
    ['SZB', 'Sultan Abdul Aziz Shah International Airport'],
    ['NTQ', 'Noto Airport'],
    ['HBE', 'Borg El Arab International Airport'],
    ['BTI', 'Barter Island LRRS Airport'],
    ['LUR', 'Cape Lisburne LRRS Airport'],
    ['PIZ', 'Point Lay LRRS Airport'],
    ['ITO', 'Hilo International Airport'],
    ['ORL', 'Orlando Executive Airport'],
    ['BTT', 'Bettles Airport'],
    ['UTO', 'Indian Mountain LRRS Airport'],
    ['FYU', 'Fort Yukon Airport'],
    ['SVW', 'Sparrevohn LRRS Airport'],
    ['FRN', 'Bryant Army Heliport'],
    ['TLJ', 'Tatalina LRRS Airport'],
    ['CZF', 'Cape Romanzof LRRS Airport'],
    ['BED', 'Laurence G Hanscom Field'],
    ['SNP', 'St Paul Island Airport'],
    ['EHM', 'Cape Newenham LRRS Airport'],
    ['STG', 'St George Airport'],
    ['ILI', 'Iliamna Airport'],
    ['PTU', 'Platinum Airport'],
    ['BMX', 'Big Mountain Airport'],
    ['OSC', 'Oscoda Wurtsmith Airport'],
    ['OAR', 'Marina Municipal Airport'],
    ['MHR', 'Sacramento Mather Airport'],
    ['BYS', 'Bicycle Lake Army Air Field'],
    ['FSM', 'Fort Smith Regional Airport'],
    ['MRI', 'Merrill Field'],
    ['GNT', 'Grants-Milan Municipal Airport'],
    ['PNC', 'Ponca City Regional Airport'],
    ['SVN', 'Hunter Army Air Field'],
    ['GFK', 'Grand Forks International Airport'],
    ['PBF', 'Pine Bluff Regional Airport, Grider Field'],
    ['NSE', 'Whiting Field Naval Air Station - North'],
    ['HNM', 'Hana Airport'],
    ['PRC', 'Ernest A. Love Field'],
    ['TTN', 'Trenton Mercer Airport'],
    ['BOS', 'General Edward Lawrence Logan International Airport'],
    ['SUU', 'Travis Air Force Base'],
    ['RME', 'Griffiss International Airport'],
    ['ENV', 'Wendover Airport'],
    ['BFM', 'Mobile Downtown Airport'],
    ['OAK', 'Metropolitan Oakland International Airport'],
    ['OMA', 'Eppley Airfield'],
    ['OGG', 'Kahului Airport'],
    ['ICT', 'Wichita Eisenhower National Airport'],
    ['MCI', 'Kansas City International Airport'],
    ['MSN', 'Dane County Regional Truax Field'],
    ['DLG', 'Dillingham Airport'],
    ['HRO', 'Boone County Airport'],
    ['PHX', 'Phoenix Sky Harbor International Airport'],
    ['BGR', 'Bangor International Airport'],
    ['FXE', 'Fort Lauderdale Executive Airport'],
    ['GGG', 'East Texas Regional Airport'],
    ['AND', 'Anderson Regional Airport'],
    ['GEG', 'Spokane International Airport'],
    ['HWO', 'North Perry Airport'],
    ['SFO', 'San Francisco International Airport'],
    ['CTB', 'Cut Bank International Airport'],
    ['ARA', 'Acadiana Regional Airport'],
    ['GNV', 'Gainesville Regional Airport'],
    ['MEM', 'Memphis International Airport'],
    ['DUG', 'Bisbee Douglas International Airport'],
    ['BIG', 'Allen Army Airfield'],
    ['CNW', 'TSTC Waco Airport'],
    ['ANN', 'Annette Island Airport'],
    ['CAR', 'Caribou Municipal Airport'],
    ['LRF', 'Little Rock Air Force Base'],
    ['HUA', 'Redstone Army Air Field'],
    ['POB', 'Pope Field'],
    ['DHT', 'Dalhart Municipal Airport'],
    ['DLF', 'DLF Airport'],
    ['LAX', 'Los Angeles International Airport'],
    ['ANB', 'Anniston Regional Airport'],
    ['CLE', 'Cleveland Hopkins International Airport'],
    ['DOV', 'Dover Air Force Base'],
    ['CVG', 'Cincinnati Northern Kentucky International Airport'],
    ['FME', 'Tipton Airport'],
    ['HON', 'Huron Regional Airport'],
    ['JNU', 'Juneau International Airport'],
    ['LFT', 'Lafayette Regional Airport'],
    ['EWR', 'Newark Liberty International Airport'],
    ['BOI', 'Boise Air Terminal/Gowen Field'],
    ['INS', 'Creech Air Force Base'],
    ['GCK', 'Garden City Regional Airport'],
    ['MOT', 'Minot International Airport'],
    ['HHI', 'Wheeler Army Airfield'],
    ['MXF', 'Maxwell Air Force Base'],
    ['DAL', 'Dallas Love Field'],
    ['FCS', 'Butts AAF (Fort Carson) Air Field'],
    ['HLN', 'Helena Regional Airport'],
    ['NKX', 'Miramar Marine Corps Air Station - Mitscher Field'],
    ['LUF', 'Luke Air Force Base'],
    ['HHR', 'Jack Northrop Field Hawthorne Municipal Airport'],
    ['HUL', 'Houlton International Airport'],
    ['END', 'Vance Air Force Base'],
    ['NTD', 'Point Mugu Naval Air Station (Naval Base Ventura Co)'],
    ['EDW', 'Edwards Air Force Base'],
    ['LCH', 'Lake Charles Regional Airport'],
    ['KOA', 'Ellison Onizuka Kona International At Keahole Airport'],
    ['MYR', 'Myrtle Beach International Airport'],
    ['NLC', 'Lemoore Naval Air Station (Reeves Field) Airport'],
    ['ACK', 'Nantucket Memorial Airport'],
    ['FAF', 'Felker Army Air Field'],
    ['HOP', 'Campbell AAF (Fort Campbell) Air Field'],
    ['DCA', 'Ronald Reagan Washington National Airport'],
    ['NHK', 'Patuxent River Naval Air Station (Trapnell Field)'],
    ['PSX', 'Palacios Municipal Airport'],
    ['BYH', 'Arkansas International Airport'],
    ['ACY', 'Atlantic City International Airport'],
    ['TIK', 'Tinker Air Force Base'],
    ['ECG', 'Elizabeth City Regional Airport & Coast Guard Air Station'],
    ['PUB', 'Pueblo Memorial Airport'],
    ['PQI', 'Northern Maine Regional Airport at Presque Isle'],
    ['GRF', 'Gray Army Air Field'],
    ['ADQ', 'Kodiak Airport'],
    ['UPP', 'Upolu Airport'],
    ['FLL', 'Fort Lauderdale Hollywood International Airport'],
    ['INL', 'Falls International Airport'],
    ['SLC', 'Salt Lake City International Airport'],
    ['CDS', 'Childress Municipal Airport'],
    ['BIX', 'Keesler Air Force Base'],
    ['LSF', 'Lawson Army Air Field (Fort Benning)'],
    ['NQI', 'Kingsville Naval Air Station'],
    ['FRI', 'Marshall Army Air Field'],
    ['MDT', 'Harrisburg International Airport'],
    ['LNK', 'Lincoln Airport'],
    ['LAN', 'Capital City Airport'],
    ['MUE', 'Waimea Kohala Airport'],
    ['MSS', 'Massena International Richards Field'],
    ['HKY', 'Hickory Regional Airport'],
    ['SPG', 'Albert Whitted Airport'],
    ['FMY', 'Page Field'],
    ['IAH', 'George Bush Intercontinental Houston Airport'],
    ['ADW', 'Joint Base Andrews'],
    ['INT', 'Smith Reynolds Airport'],
    ['VCV', 'Southern California Logistics Airport'],
    ['CEW', 'Bob Sikes Airport'],
    ['PHN', 'St Clair County International Airport'],
    ['BFL', 'Meadows Field'],
    ['ELP', 'El Paso International Airport'],
    ['HRL', 'Valley International Airport'],
    ['CAE', 'Columbia Metropolitan Airport'],
    ['DMA', 'Davis Monthan Air Force Base'],
    ['NPA', 'Pensacola Naval Air Station/Forrest Sherman Field'],
    ['PNS', 'Pensacola Regional Airport'],
    ['RDR', 'Grand Forks Air Force Base'],
    ['HOU', 'William P Hobby Airport'],
    ['BFK', 'Buckley Air Force Base'],
    ['ORT', 'Northway Airport'],
    ['PAQ', "Warren 'Bud' Woods Palmer Municipal Airport"],
    ['PIT', 'Pittsburgh International Airport'],
    ['BRW', 'Wiley Post Will Rogers Memorial Airport'],
    ['EFD', 'Ellington Airport'],
    ['NUW', 'Whidbey Island Naval Air Station (Ault Field)'],
    ['ALI', 'Alice International Airport'],
    ['VAD', 'Moody Air Force Base'],
    ['MIA', 'Miami International Airport'],
    ['SEA', 'Seattle Tacoma International Airport'],
    ['CHA', 'Lovell Field'],
    ['BDR', 'Igor I Sikorsky Memorial Airport'],
    ['JAN', 'Jackson-Medgar Wiley Evers International Airport'],
    ['GLS', 'Scholes International At Galveston Airport'],
    ['LGB', 'Long Beach /Daugherty Field/ Airport'],
    ['HDH', 'Dillingham Airfield'],
    ['IPT', 'Williamsport Regional Airport'],
    ['IND', 'Indianapolis International Airport'],
    ['SZL', 'Whiteman Air Force Base'],
    ['AKC', 'Akron Fulton International Airport'],
    ['GWO', 'Greenwood–Leflore Airport'],
    ['HPN', 'Westchester County Airport'],
    ['FOK', 'Francis S Gabreski Airport'],
    ['JBR', 'Jonesboro Municipal Airport'],
    ['XSD', 'Tonopah Test Range Airport'],
    ['LNA', 'Palm Beach County Park Airport'],
    ['NZY', 'North Island Naval Air Station-Halsey Field'],
    ['BIF', 'Biggs Army Air Field (Fort Bliss)'],
    ['YUM', 'Yuma MCAS/Yuma International Airport'],
    ['CNM', 'Cavern City Air Terminal'],
    ['DLH', 'Duluth International Airport'],
    ['BET', 'Bethel Airport'],
    ['LOU', 'Bowman Field'],
    ['FHU', 'Sierra Vista Municipal Libby Army Air Field'],
    ['LIH', 'Lihue Airport'],
    ['HUF', 'Terre Haute Regional Airport, Hulman Field'],
    ['HVR', 'Havre City County Airport'],
    ['MWH', 'Grant County International Airport'],
    ['MPV', 'Edward F Knapp State Airport'],
    ['RIC', 'Richmond International Airport'],
    ['SHV', 'Shreveport Regional Airport'],
    ['CDV', 'Merle K (Mudhole) Smith Airport'],
    ['ORF', 'Norfolk International Airport'],
    ['BPT', 'Southeast Texas Regional Airport'],
    ['SAV', 'Savannah Hilton Head International Airport'],
    ['HIF', 'Hill Air Force Base'],
    ['OME', 'Nome Airport'],
    ['PIE', 'St Petersburg Clearwater International Airport'],
    ['MNM', 'Menominee Regional Airport'],
    ['CXO', 'Conroe-North Houston Regional Airport'],
    ['SCC', 'Deadhorse Airport'],
    ['SAT', 'San Antonio International Airport'],
    ['ROC', 'Greater Rochester International Airport'],
    ['COF', 'Patrick Air Force Base'],
    ['TEB', 'Teterboro Airport'],
    ['RCA', 'Ellsworth Air Force Base'],
    ['RDU', 'Raleigh Durham International Airport'],
    ['DAY', 'James M Cox Dayton International Airport'],
    ['ENA', 'Kenai Municipal Airport'],
    ['MLC', 'Mc Alester Regional Airport'],
    ['IAG', 'Niagara Falls International Airport'],
    ['CFD', 'Coulter Field'],
    ['LIY', 'Wright AAF (Fort Stewart)/Midcoast Regional Airport'],
    ['PHF', 'Newport News Williamsburg International Airport'],
    ['ESF', 'Esler Regional Airport'],
    ['LTS', 'Altus Air Force Base'],
    ['TUS', 'Tucson International Airport'],
    ['MIB', 'Minot Air Force Base'],
    ['BAB', 'Beale Air Force Base'],
    ['IKK', 'Greater Kankakee Airport'],
    ['GSB', 'Seymour Johnson Air Force Base'],
    ['PVD', 'Theodore Francis Green State Airport'],
    ['SBY', 'Salisbury Ocean City Wicomico Regional Airport'],
    ['BUR', 'Bob Hope Airport'],
    ['DTW', 'Detroit Metropolitan Wayne County Airport'],
    ['TPA', 'Tampa International Airport'],
    ['PMB', 'Pembina Municipal Airport'],
    ['POE', 'Polk Army Air Field'],
    ['EIL', 'Eielson Air Force Base'],
    ['HIB', 'Range Regional Airport'],
    ['LFK', 'Angelina County Airport'],
    ['MAF', 'Midland International Airport'],
    ['GRB', 'Austin Straubel International Airport'],
    ['ADM', 'Ardmore Municipal Airport'],
    ['WRI', 'Mc Guire Air Force Base'],
    ['AGS', 'Augusta Regional At Bush Field'],
    ['ISN', 'Sloulin Field International Airport'],
    ['LIT', 'Bill & Hillary Clinton National Airport/Adams Field'],
    ['SWF', 'Stewart International Airport'],
    ['BDE', 'Baudette International Airport'],
    ['SAC', 'Sacramento Executive Airport'],
    ['HOM', 'Homer Airport'],
    ['TBN', 'Waynesville-St. Robert Regional Forney field'],
    ['MGE', 'Dobbins Air Reserve Base'],
    ['SKA', 'Fairchild Air Force Base'],
    ['HTL', 'Roscommon County - Blodgett Memorial Airport'],
    ['PAM', 'Tyndall Air Force Base'],
    ['DFW', 'Dallas Fort Worth International Airport'],
    ['MLB', 'Melbourne International Airport'],
    ['TCM', 'McChord Air Force Base'],
    ['AUS', 'Austin Bergstrom International Airport'],
    ['LCK', 'Rickenbacker International Airport'],
    ['MQT', 'Sawyer International Airport'],
    ['TYS', 'McGhee Tyson Airport'],
    ['HLR', 'Hood Army Air Field'],
    ['STL', 'St Louis Lambert International Airport'],
    ['MIV', 'Millville Municipal Airport'],
    ['SPS', 'Sheppard Air Force Base-Wichita Falls Municipal Airport'],
    ['LUK', 'Cincinnati Municipal Airport Lunken Field'],
    ['ATL', 'Hartsfield Jackson Atlanta International Airport'],
    ['MER', 'Castle Airport'],
    ['MCC', 'Mc Clellan Airfield'],
    ['GRR', 'Gerald R. Ford International Airport'],
    ['INK', 'Winkler County Airport'],
    ['FAT', 'Fresno Yosemite International Airport'],
    ['VRB', 'Vero Beach Regional Airport'],
    ['IPL', 'Imperial County Airport'],
    ['BNA', 'Nashville International Airport'],
    ['LRD', 'Laredo International Airport'],
    ['EDF', 'Elmendorf Air Force Base'],
    ['OTZ', 'Ralph Wien Memorial Airport'],
    ['AOO', 'Altoona Blair County Airport'],
    ['DYS', 'Dyess Air Force Base'],
    ['ELD', 'South Arkansas Regional At Goodwin Field'],
    ['LGA', 'La Guardia Airport'],
    ['TLH', 'Tallahassee Regional Airport'],
    ['DPA', 'Dupage Airport'],
    ['ACT', 'Waco Regional Airport'],
    ['AUG', 'Augusta State Airport'],
    ['NIP', 'Jacksonville Naval Air Station (Towers Field)'],
    ['MKL', 'McKellar-Sipes Regional Airport'],
    ['MKK', 'Molokai Airport'],
    ['FTK', 'Godman Army Air Field'],
    ['SJT', 'San Angelo Regional Mathis Field'],
    ['CXL', 'Calexico International Airport'],
    ['CIC', 'Chico Municipal Airport'],
    ['BTV', 'Burlington International Airport'],
    ['JAX', 'Jacksonville International Airport'],
    ['DRO', 'Durango La Plata County Airport'],
    ['IAD', 'Washington Dulles International Airport'],
    ['CLL', 'Easterwood Field'],
    ['SFF', 'Felts Field'],
    ['MKE', 'General Mitchell International Airport'],
    ['ABI', 'Abilene Regional Airport'],
    ['COU', 'Columbia Regional Airport'],
    ['PDX', 'Portland International Airport'],
    ['TNT', 'Dade Collier Training and Transition Airport'],
    ['PBI', 'Palm Beach International Airport'],
    ['FTW', 'Fort Worth Meacham International Airport'],
    ['OGS', 'Ogdensburg International Airport'],
    ['FMH', 'Cape Cod Coast Guard Air Station'],
    ['BFI', 'Boeing Field King County International Airport'],
    ['SKF', 'Lackland Air Force Base'],
    ['HNL', 'Daniel K Inouye International Airport'],
    ['DSM', 'Des Moines International Airport'],
    ['EWN', 'Coastal Carolina Regional Airport'],
    ['SAN', 'San Diego International Airport'],
    ['MLU', 'Monroe Regional Airport'],
    ['SSC', 'Shaw Air Force Base'],
    ['ONT', 'Ontario International Airport'],
    ['GVT', 'Majors Airport'],
    ['ROW', 'Roswell International Air Center Airport'],
    ['DET', 'Coleman A. Young Municipal Airport'],
    ['BRO', 'Brownsville South Padre Island International Airport'],
    ['DHN', 'Dothan Regional Airport'],
    ['WWD', 'Cape May County Airport'],
    ['NFL', 'Fallon Naval Air Station'],
    ['MTC', 'Selfridge Air National Guard Base Airport'],
    ['FMN', 'Four Corners Regional Airport'],
    ['CRP', 'Corpus Christi International Airport'],
    ['SYR', 'Syracuse Hancock International Airport'],
    ['NQX', 'Naval Air Station Key West/Boca Chica Field'],
    ['MDW', 'Chicago Midway International Airport'],
    ['SJC', 'Norman Y. Mineta San Jose International Airport'],
    ['HOB', 'Lea County Regional Airport'],
    ['PNE', 'Northeast Philadelphia Airport'],
    ['DEN', 'Denver International Airport'],
    ['PHL', 'Philadelphia International Airport'],
    ['SUX', 'Sioux Gateway Col. Bud Day Field'],
    ['MCN', 'Middle Georgia Regional Airport'],
    ['TCS', 'Truth Or Consequences Municipal Airport'],
    ['PMD', 'Palmdale Regional/USAF Plant 42 Airport'],
    ['RND', 'Randolph Air Force Base'],
    ['NJK', 'El Centro NAF Airport (Vraciu Field)'],
    ['CMH', 'John Glenn Columbus International Airport'],
    ['FYV', 'Drake Field'],
    ['FSI', 'Henry Post Army Air Field (Fort Sill)'],
    ['FFO', 'Wright-Patterson Air Force Base'],
    ['GAL', 'Edward G. Pitka Sr Airport'],
    ['MWL', 'Mineral Wells Airport'],
    ['IAB', 'Mc Connell Air Force Base'],
    ['NBG', 'New Orleans NAS JRB/Alvin Callender Field'],
    ['BFT', 'Beaufort County Airport'],
    ['TXK', 'Texarkana Regional Webb Field'],
    ['PBG', 'Plattsburgh International Airport'],
    ['APG', 'Phillips Army Air Field'],
    ['TCC', 'Tucumcari Municipal Airport'],
    ['ANC', 'Ted Stevens Anchorage International Airport'],
    ['GRK', 'Robert Gray  Army Air Field Airport'],
    ['BLI', 'Bellingham International Airport'],
    ['NQA', 'Millington-Memphis Airport'],
    ['EKN', 'Elkins-Randolph Co-Jennings Randolph Field'],
    ['HFD', 'Hartford Brainard Airport'],
    ['SFZ', 'North Central State Airport'],
    ['MOB', 'Mobile Regional Airport'],
    ['NUQ', 'Moffett Federal Airfield'],
    ['SAF', 'Santa Fe Municipal Airport'],
    ['BKH', 'Barking Sands Airport'],
    ['DRI', 'Beauregard Regional Airport'],
    ['BSF', 'Bradshaw Army Airfield'],
    ['OLS', 'Nogales International Airport'],
    ['MCF', 'Mac Dill Air Force Base'],
    ['BLV', 'Scott AFB/Midamerica Airport'],
    ['OPF', 'Opa-locka Executive Airport'],
    ['DRT', 'Del Rio International Airport'],
    ['RSW', 'Southwest Florida International Airport'],
    ['AKN', 'King Salmon Airport'],
    ['MUI', 'Muir Army Air Field (Fort Indiantown Gap) Airport'],
    ['JHM', 'Kapalua Airport'],
    ['JFK', 'John F Kennedy International Airport'],
    ['HST', 'Homestead ARB Airport'],
    ['RAL', 'Riverside Municipal Airport'],
    ['FLV', 'Sherman Army Air Field'],
    ['WAL', 'Wallops Flight Facility Airport'],
    ['HMN', 'Holloman Air Force Base'],
    ['NXX', 'Willow Grove Naval Air Station/Joint Reserve Base'],
    ['CYS', 'Cheyenne Regional Jerry Olson Field'],
    ['SCK', 'Stockton Metropolitan Airport'],
    ['CHS', 'Charleston Air Force Base-International Airport'],
    ['RNO', 'Reno Tahoe International Airport'],
    ['KTN', 'Ketchikan International Airport'],
    ['YIP', 'Willow Run Airport'],
    ['VBG', 'Vandenberg Air Force Base'],
    ['BHM', 'Birmingham-Shuttlesworth International Airport'],
    ['NEL', 'Lakehurst Maxfield Field Airport'],
    ['SYA', 'Eareckson Air Station'],
    ['LSV', 'Nellis Air Force Base'],
    ['RIV', 'March ARB Airport'],
    ['MOD', 'Modesto City Co-Harry Sham Field'],
    ['SMF', 'Sacramento International Airport'],
    ['UGN', 'Waukegan National Airport'],
    ['COS', 'City of Colorado Springs Municipal Airport'],
    ['BUF', 'Buffalo Niagara International Airport'],
    ['SKY', 'Griffing Sandusky Airport'],
    ['PAE', 'Snohomish County (Paine Field) Airport'],
    ['MUO', 'Mountain Home Air Force Base'],
    ['CDC', 'Cedar City Regional Airport'],
    ['BDL', 'Bradley International Airport'],
    ['MFE', 'Mc Allen Miller International Airport'],
    ['NGU', 'Norfolk Naval Station (Chambers Field)'],
    ['CEF', 'Westover ARB/Metropolitan Airport'],
    ['LBB', 'Lubbock Preston Smith International Airport'],
    ['ORD', "Chicago O'Hare International Airport"],
    ['BCT', 'Boca Raton Airport'],
    ['FAI', 'Fairbanks International Airport'],
    ['CVS', 'Cannon Air Force Base'],
    ['NGF', 'Kaneohe Bay MCAS (Marion E. Carl Field) Airport'],
    ['OFF', 'Offutt Air Force Base'],
    ['GKN', 'Gulkana Airport'],
    ['ART', 'Watertown International Airport'],
    ['PSP', 'Palm Springs International Airport'],
    ['AMA', 'Rick Husband Amarillo International Airport'],
    ['FOD', 'Fort Dodge Regional Airport'],
    ['BAD', 'Barksdale Air Force Base'],
    ['FOE', 'Topeka Regional Airport - Forbes Field'],
    ['COT', 'Cotulla-La Salle County Airport'],
    ['ILM', 'Wilmington International Airport'],
    ['BTR', 'Baton Rouge Metropolitan Airport'],
    ['TYR', 'Tyler Pounds Regional Airport'],
    ['BWI', 'Baltimore/Washington International Thurgood Marshall Airport'],
    ['HBR', 'Hobart Regional Airport'],
    ['LNY', 'Lanai Airport'],
    ['AEX', 'Alexandria International Airport'],
    ['WSD', 'Condron Army Air Field'],
    ['CDB', 'Cold Bay Airport'],
    ['TUL', 'Tulsa International Airport'],
    ['SIT', 'Sitka Rocky Gutierrez Airport'],
    ['ISP', 'Long Island Mac Arthur Airport'],
    ['MSP', 'Minneapolis-St Paul International/Wold-Chamberlain Airport'],
    ['ILG', 'New Castle Airport'],
    ['DUT', 'Unalaska Airport'],
    ['MSY', 'Louis Armstrong New Orleans International Airport'],
    ['PWM', 'Portland International Jetport Airport'],
    ['OKC', 'Will Rogers World Airport'],
    ['ALB', 'Albany International Airport'],
    ['VDZ', 'Valdez Pioneer Field'],
    ['LFI', 'Langley Air Force Base'],
    ['SNA', 'John Wayne Airport-Orange County Airport'],
    ['CBM', 'Columbus Air Force Base'],
    ['TMB', 'Kendall-Tamiami Executive Airport'],
    ['NTU', 'Oceana Naval Air Station'],
    ['GUS', 'Grissom Air Reserve Base'],
    ['CPR', 'Casper-Natrona County International Airport'],
    ['VPS', 'Destin-Ft Walton Beach Airport'],
    ['SEM', 'Craig Field'],
    ['EYW', 'Key West International Airport'],
    ['CLT', 'Charlotte Douglas International Airport'],
    ['LAS', 'McCarran International Airport'],
    ['MCO', 'Orlando International Airport'],
    ['FLO', 'Florence Regional Airport'],
    ['GTF', 'Great Falls International Airport'],
    ['YNG', 'Youngstown Warren Regional Airport'],
    ['FBK', 'Ladd AAF Airfield'],
    ['WRB', 'Robins Air Force Base'],
    ['BKK', 'Suvarnabhumi Airport'],
    ['NAH', 'Naha Airport'],
    ['MXB', 'Andi Jemma Airport'],
    ['SQR', 'Soroako Airport'],
    ['TTR', 'Pongtiku Airport'],
    ['KDI', 'Wolter Monginsidi Airport'],
    ['SBG', 'Maimun Saleh Airport'],
    ['TSY', 'Cibeureum Airport'],
    ['MLG', 'Abdul Rachman Saleh Airport'],
    ['BDO', 'Husein Sastranegara International Airport'],
    ['CBN', 'Penggung Airport'],
    ['JOG', 'Adi Sutjipto International Airport'],
    ['CXP', 'Tunggul Wulung Airport'],
    ['PCB', 'Pondok Cabe Air Base'],
    ['SRG', 'Achmad Yani Airport'],
    ['BTH', 'Hang Nadim International Airport'],
    ['TJQ', 'Buluh Tumbang (H A S Hanandjoeddin) Airport'],
    ['PGK', 'Pangkal Pinang (Depati Amir) Airport'],
    ['TNJ', 'Raja Haji Fisabilillah International Airport'],
    ['SIQ', 'Dabo Airport'],
    ['BDJ', 'Syamsudin Noor Airport'],
    ['BTW', 'Batu Licin Airport'],
    ['PKN', 'Iskandar Airport'],
    ['PKY', 'Tjilik Riwut Airport'],
    ['MOF', 'Maumere(Wai Oti) Airport'],
    ['ENE', 'Ende (H Hasan Aroeboesman) Airport'],
    ['RTG', 'Frans Sales Lega Airport'],
    ['KOE', 'El Tari Airport'],
    ['LBJ', 'Komodo Airport'],
    ['BPN', 'Sultan Aji Muhamad Sulaiman Airport'],
    ['TRK', 'Juwata Airport'],
    ['SRI', 'Temindung Airport'],
    ['TSX', 'Tanjung Santan Airport'],
    ['AMI', 'Selaparang Airport'],
    ['BMU', 'Muhammad Salahuddin Airport'],
    ['WGP', 'Umbu Mehang Kunda Airport'],
    ['SUB', 'Juanda International Airport'],
    ['SOC', 'Adi Sumarmo Wiryokusumo Airport'],
    ['ICN', 'Incheon International Airport'],
    ['CNX', 'Chiang Mai International Airport'],
    ['CEI', 'Chiang Rai International Airport'],
    ['NST', 'Nakhon Si Thammarat Airport'],
    ['NAK', 'Nakhon Ratchasima Airport'],
    ['KOP', 'Nakhon Phanom Airport'],
    ['UBP', 'Ubon Ratchathani Airport'],
    ['KKC', 'Khon Kaen Airport'],
    ['THS', 'Sukhothai Airport'],
    ['DPS', 'Ngurah Rai (Bali) International Airport'],
    ['ATH', 'Eleftherios Venizelos International Airport'],
    ['NGO', 'Chubu Centrair International Airport'],
    ['UKB', 'Kobe Airport'],
    ['PUW', 'Pullman Moscow Regional Airport'],
    ['LWS', 'Lewiston Nez Perce County Airport'],
    ['ELM', 'Elmira Corning Regional Airport'],
    ['ITH', 'Ithaca Tompkins Regional Airport'],
    ['MRY', 'Monterey Peninsula Airport'],
    ['SBA', 'Santa Barbara Municipal Airport'],
    ['DAB', 'Daytona Beach International Airport'],
    ['LPX', 'Liepāja International Airport'],
    ['RIX', 'Riga International Airport'],
    ['SQQ', 'Šiauliai International Airport'],
    ['HLJ', 'Barysiai Airport'],
    ['KUN', 'Kaunas International Airport'],
    ['PLQ', 'Palanga International Airport'],
    ['VNO', 'Vilnius International Airport'],
    ['PNV', 'Panevėžys Air Base'],
    ['EVN', 'Zvartnots International Airport'],
    ['LWN', 'Gyumri Shirak Airport'],
    ['ASA', 'Assab International Airport'],
    ['ASM', 'Asmara International Airport'],
    ['MSW', 'Massawa International Airport'],
    ['GZA', 'Yasser Arafat International Airport'],
    ['BUS', 'Batumi International Airport'],
    ['KUT', 'Kopitnari Airport'],
    ['TBS', 'Tbilisi International Airport'],
    ['RIY', 'Mukalla International Airport'],
    ['TAI', "Ta'izz International Airport"],
    ['HOD', 'Hodeidah International Airport'],
    ['ADE', 'Aden International Airport'],
    ['AXK', 'Ataq Airport'],
    ['AAY', 'Al Ghaidah International Airport'],
    ['SAH', "Sana'a International Airport"],
    ['BHN', 'Beihan Airport'],
    ['SCT', 'Socotra International Airport'],
    ['FMM', 'Memmingen Allgau Airport'],
    ['NAV', 'Nevşehir Kapadokya Airport'],
    ['EZE', 'Ministro Pistarini International Airport'],
    ['EBL', 'Erbil International Airport'],
    ['EMD', 'Emerald Airport'],
    ['HEW', 'Athen Helenikon Airport'],
    ['KIX', 'Kansai International Airport'],
    ['JRB', 'Downtown-Manhattan/Wall St Heliport'],
    ['TAG', 'Tagbilaran Airport'],
    ['JAV', 'Ilulissat Airport'],
    ['JCH', 'Qasigiannguit Heliport'],
    ['JEG', 'Aasiaat Airport'],
    ['PMI', 'Palma De Mallorca Airport'],
    ['DRW', 'Darwin International Airport'],
    ['URT', 'Surat Thani Airport'],
    ['TKA', 'Talkeetna Airport'],
    ['GZM', 'Xewkija Heliport'],
    ['HVN', 'Tweed New Haven Airport'],
    ['AVL', 'Asheville Regional Airport'],
    ['GSO', 'Piedmont Triad International Airport'],
    ['FSD', 'Joe Foss Field Airport'],
    ['AYQ', 'Ayers Rock Connellan Airport'],
    ['MHT', 'Manchester-Boston Regional Airport'],
    ['APF', 'Naples Municipal Airport'],
    ['RDN', 'LTS Pulau Redang Airport'],
    ['SDF', 'Louisville International Standiford Field'],
    ['CHO', 'Charlottesville Albemarle Airport'],
    ['ROA', 'Roanoke–Blacksburg Regional Airport'],
    ['LEX', 'Blue Grass Airport'],
    ['EVV', 'Evansville Regional Airport'],
    ['ABQ', 'Albuquerque International Sunport'],
    ['BZN', 'Gallatin Field'],
    ['BIL', 'Billings Logan International Airport'],
    ['BTM', 'Bert Mooney Airport'],
    ['TVC', 'Cherry Capital Airport'],
    ['FRS', 'Mundo Maya International Airport'],
    ['BHB', 'Hancock County-Bar Harbor Airport'],
    ['RKD', 'Knox County Regional Airport'],
    ['JAC', 'Jackson Hole Airport'],
    ['RFD', 'Chicago Rockford International Airport'],
    ['DME', 'Domodedovo International Airport'],
    ['SYX', 'Sanya Phoenix International Airport'],
    ['MFN', 'Milford Sound Airport'],
    ['LJG', 'Lijiang Airport'],
    ['GSP', 'Greenville Spartanburg International Airport'],
    ['BMI', 'Central Illinois Regional Airport at Bloomington-Normal'],
    ['GPT', 'Gulfport Biloxi International Airport'],
    ['AZO', 'Kalamazoo Battle Creek International Airport'],
    ['TOL', 'Toledo Express Airport'],
    ['FWA', 'Fort Wayne International Airport'],
    ['DEC', 'Decatur Airport'],
    ['CID', 'The Eastern Iowa Airport'],
    ['LSE', 'La Crosse Municipal Airport'],
    ['CWA', 'Central Wisconsin Airport'],
    ['PIA', 'General Wayne A. Downing Peoria International Airport'],
    ['ATW', 'Appleton International Airport'],
    ['RST', 'Rochester International Airport'],
    ['CMI', 'University of Illinois Willard Airport'],
    ['MHK', 'Manhattan Regional Airport'],
    ['KGC', 'Kingscote Airport'],
    ['HVB', 'Hervey Bay Airport'],
    ['DLU', 'Dali Airport'],
    ['MZV', 'Mulu Airport'],
    ['SSH', 'Sharm El Sheikh International Airport'],
    ['FKL', 'Venango Regional Airport'],
    ['NBO', 'Jomo Kenyatta International Airport'],
    ['SEU', 'Seronera Airport'],
    ['FTE', 'El Calafate Airport'],
    ['ARM', 'Armidale Airport'],
    ['GJT', 'Grand Junction Regional Airport'],
    ['SGU', 'St George Municipal Airport'],
    ['DWH', 'David Wayne Hooks Memorial Airport'],
    ['SRQ', 'Sarasota Bradenton International Airport'],
    ['BDA', 'L.F. Wade International International Airport'],
    ['VNY', 'Van Nuys Airport'],
    ['MLI', 'Quad City International Airport'],
    ['PFN', 'Panama City-Bay Co International Airport'],
    ['HIR', 'Honiara International Airport'],
    ['PPT', "Faa'a International Airport"],
    ['INU', 'Nauru International Airport'],
    ['FUN', 'Funafuti International Airport'],
    ['OVB', 'Tolmachevo Airport'],
    ['XKH', 'Xieng Khouang Airport'],
    ['BIS', 'Bismarck Municipal Airport'],
    ['TEX', 'Telluride Regional Airport'],
    ['HGN', 'Mae Hong Son Airport'],
    ['RAP', 'Rapid City Regional Airport'],
    ['CLD', 'Mc Clellan-Palomar Airport'],
    ['FNT', 'Bishop International Airport'],
    ['DVO', 'Francisco Bangoy International Airport'],
    ['FNC', 'Madeira Airport'],
    ['STM', 'Maestro Wilson Fonseca Airport'],
    ['KOS', 'Sihanoukville International Airport'],
    ['YOA', 'Ekati Airport'],
    ['NPE', "Hawke's Bay Airport"],
    ['LEV', 'Levuka Airfield'],
    ['LXA', 'Lhasa Gonggar Airport'],
    ['RDD', 'Redding Municipal Airport'],
    ['EUG', 'Mahlon Sweet Field'],
    ['IDA', 'Idaho Falls Regional Airport'],
    ['MFR', 'Rogue Valley International Medford Airport'],
    ['KBZ', 'Kaikoura Airport'],
    ['RDM', 'Roberts Field'],
    ['PCN', 'Picton Aerodrome'],
    ['WDH', 'Hosea Kutako International Airport'],
    ['YWH', 'Victoria Harbour Seaplane Base'],
    ['TNA', 'Yaoqiang Airport'],
    ['CZX', 'Changzhou Benniu Airport'],
    ['YBP', 'Yibin Caiba Airport'],
    ['TJM', 'Roshchino International Airport'],
    ['CAK', 'Akron Canton Regional Airport'],
    ['HSV', 'Huntsville International Carl T Jones Field'],
    ['PKB', 'Mid Ohio Valley Regional Airport'],
    ['MGM', 'Montgomery Regional (Dannelly Field) Airport'],
    ['TRI', 'Tri-Cities Regional TN/VA Airport'],
    ['PAH', 'Barkley Regional Airport'],
    ['JIB', 'Djibouti-Ambouli Airport'],
    ['HAK', 'Haikou Meilan International Airport'],
    ['MFA', 'Mafia Island Airport'],
    ['PGA', 'Page Municipal Airport'],
    ['UII', 'Utila Airport'],
    ['FCA', 'Glacier Park International Airport'],
    ['MBS', 'MBS International Airport'],
    ['BGM', 'Greater Binghamton/Edwin A Link field'],
    ['BGW', 'Baghdad International Airport'],
    ['NNT', 'Nan Airport'],
    ['ROI', 'Roi Et Airport'],
    ['BFV', 'Buri Ram Airport'],
    ['TDX', 'Trat Airport'],
    ['BLH', 'Blythe Airport'],
    ['IQA', 'Al Asad Air Base'],
    ['TQD', 'Al Taqaddum Air Base'],
    ['XQC', 'Joint Base Balad'],
    ['CRK', 'Diosdado Macapagal International Airport'],
    ['SDK', 'Sandakan Airport'],
    ['LXG', 'Luang Namtha Airport'],
    ['ODY', 'Oudomsay Airport'],
    ['SHE', 'Taoxian Airport'],
    ['DOY', 'Dongying Shengli Airport'],
    ['MNI', 'John A. Osborne Airport'],
    ['PSG', 'Petersburg James A Johnson Airport'],
    ['LYA', 'Luoyang Airport'],
    ['XUZ', 'Xuzhou Guanyin Airport'],
    ['MWQ', 'Magway Airport'],
    ['KHM', 'Kanti Airport'],
    ['DLI', 'Lien Khuong Airport'],
    ['VDH', 'Dong Hoi Airport'],
    ['VKG', 'Rach Gia Airport'],
    ['CAH', 'Cà Mau Airport'],
    ['VCL', 'Chu Lai International Airport'],
    ['TBB', 'Dong Tac Airport'],
    ['PYY', 'Mae Hong Son Airport'],
    ['BWK', 'Bol Airport'],
    ['NSI', 'Yaoundé Nsimalen International Airport'],
    ['CKY', 'Conakry International Airport'],
    ['AAH', 'Aachen-Merzbrück Airport'],
    ['FKB', 'Karlsruhe Baden-Baden Airport'],
    ['SFB', 'Orlando Sanford International Airport'],
    ['JST', 'John Murtha Johnstown Cambria County Airport'],
    ['LUA', 'Lukla Airport'],
    ['BHP', 'Bhojpur Airport'],
    ['LDN', 'Lamidanda Airport'],
    ['JMO', 'Jomsom Airport'],
    ['NGX', 'Manang Airport'],
    ['PPL', 'Phaplu Airport'],
    ['RUM', 'Rumjatar Airport'],
    ['DNP', 'Tulsipur Airport'],
    ['RUK', 'Rukum Chaurjahari Airport'],
    ['JUM', 'Jumla Airport'],
    ['TPJ', 'Taplejung Airport'],
    ['TMI', 'Tumling Tar Airport'],
    ['SKH', 'Surkhet Airport'],
    ['IMK', 'Simikot Airport'],
    ['DOP', 'Dolpa Airport'],
    ['BJH', 'Bajhang Airport'],
    ['DHI', 'Dhangarhi Airport'],
    ['MWX', 'Muan International Airport'],
    ['JTY', 'Astypalaia Airport'],
    ['JIK', 'Ikaria Airport'],
    ['JKL', 'Kalymnos Airport'],
    ['MLO', 'Milos Airport'],
    ['JNX', 'Naxos Airport'],
    ['PAS', 'Paros National Airport'],
    ['KZS', 'Kastelorizo Airport'],
    ['RMF', 'Marsa Alam International Airport'],
    ['NRN', 'Weeze Airport'],
    ['USU', 'Francisco B. Reyes Airport'],
    ['BXU', 'Bancasi Airport'],
    ['DPL', 'Dipolog Airport'],
    ['LAO', 'Laoag International Airport'],
    ['LGP', 'Legazpi City International Airport'],
    ['OZC', 'Labo Airport'],
    ['CEB', 'Mactan Cebu International Airport'],
    ['NOD', 'Norden-Norddeich Airport'],
    ['JUI', 'Juist Airport'],
    ['BPS', 'Porto Seguro Airport'],
    ['QIG', 'Iguatu Airport'],
    ['PMW', 'Brigadeiro Lysias Rodrigues Airport'],
    ['CLV', 'Nelson Ribeiro Guimarães Airport'],
    ['MSO', 'Missoula International Airport'],
    ['BKQ', 'Blackall Airport'],
    ['BDB', 'Bundaberg Airport'],
    ['GCN', 'Grand Canyon National Park Airport'],
    ['SGR', 'Sugar Land Regional Airport'],
    ['APA', 'Centennial Airport'],
    ['CVN', 'Clovis Municipal Airport'],
    ['FST', 'Fort Stockton Pecos County Airport'],
    ['LVS', 'Las Vegas Municipal Airport'],
    ['IWS', 'West Houston Airport'],
    ['LRU', 'Las Cruces International Airport'],
    ['BKD', 'Stephens County Airport'],
    ['TPL', 'Draughon Miller Central Texas Regional Airport'],
    ['OZA', 'Ozona Municipal Airport'],
    ['KDM', 'Kaadedhdhoo Airport'],
    ['LAK', 'Aklavik/Freddie Carmichael Airport'],
    ['YWJ', 'Déline Airport'],
    ['ZFN', 'Tulita Airport'],
    ['YGH', 'Fort Good Hope Airport'],
    ['TAH', 'Tanna Airport'],
    ['YPC', 'Paulatuk (Nora Aliqatchialuk Ruben) Airport'],
    ['SRZ', 'El Trompillo Airport'],
    ['SAB', 'Juancho E. Yrausquin Airport'],
    ['EGE', 'Eagle County Regional Airport'],
    ['SKN', 'Stokmarknes Skagen Airport'],
    ['CGF', 'Cuyahoga County Airport'],
    ['MFD', 'Mansfield Lahm Regional Airport'],
    ['CSG', 'Columbus Metropolitan Airport'],
    ['LAW', 'Lawton Fort Sill Regional Airport'],
    ['FNL', 'Northern Colorado Regional Airport'],
    ['FLG', 'Flagstaff Pulliam Airport'],
    ['TVL', 'Lake Tahoe Airport'],
    ['TWF', 'Joslin Field Magic Valley Regional Airport'],
    ['MVY', "Martha's Vineyard Airport"],
    ['CON', 'Concord Municipal Airport'],
    ['GON', 'Groton New London Airport'],
    ['STC', 'St Cloud Regional Airport'],
    ['BPE', 'Qinhuangdao Beidaihe Airport'],
    ['GTR', 'Golden Triangle Regional Airport'],
    ['GOJ', 'Nizhny Novgorod Strigino International Airport'],
    ['HQM', 'Bowerman Airport'],
    ['ERI', 'Erie International Tom Ridge Field'],
    ['HYA', 'Barnstable Municipal Boardman Polando Field'],
    ['SPR', 'San Pedro Airport'],
    ['SDX', 'Sedona Airport'],
    ['MGW', 'Morgantown Municipal Walter L. Bill Hart Field'],
    ['CRW', 'Yeager Airport'],
    ['AVP', 'Wilkes Barre Scranton International Airport'],
    ['BJI', 'Bemidji Regional Airport'],
    ['THG', 'Thangool Airport'],
    ['FGI', "Fagali'i Airport"],
    ['BNK', 'Ballina Byron Gateway Airport'],
    ['FAR', 'Hector International Airport'],
    ['MKC', 'Charles B. Wheeler Downtown Airport'],
    ['RBE', 'Ratanakiri Airport'],
    ['GCC', 'Gillette Campbell County Airport'],
    ['TOF', 'Bogashevo Airport'],
    ['NZJ', 'El Toro Marine Corps Air Station'],
    ['PHY', 'Phetchabun Airport'],
    ['CJM', 'Chumphon Airport'],
    ['JZH', 'Jiuzhai Huanglong Airport'],
    ['SWA', 'Jieyang Chaoshan International Airport'],
    ['GEO', 'Cheddi Jagan International Airport'],
    ['AGT', 'Guarani International Airport'],
    ['OGL', 'Eugene F. Correira International Airport'],
    ['KAI', 'Kaieteur International Airport'],
    ['DNH', 'Dunhuang Airport'],
    ['AOI', 'Ancona Falconara Airport'],
    ['TCP', 'Taba International Airport'],
    ['LYB', 'Edward Bodden Airfield'],
    ['BJV', 'Milas Bodrum International Airport'],
    ['TBJ', 'Tabarka 7 Novembre Airport'],
    ['SAW', 'Sabiha Gökçen International Airport'],
    ['SCE', 'University Park Airport'],
    ['BME', 'Broome International Airport'],
    ['NTL', 'Newcastle Airport'],
    ['KLU', 'Klagenfurt Airport'],
    ['HFT', 'Hammerfest Airport'],
    ['HVG', 'Valan Airport'],
    ['MEH', 'Mehamn Airport'],
    ['VDS', 'Vadsø Airport'],
    ['IKA', 'Imam Khomeini International Airport'],
    ['MHD', 'Mashhad International Airport'],
    ['UIK', 'Ust-Ilimsk Airport'],
    ['MEI', 'Key Field'],
    ['SPI', 'Abraham Lincoln Capital Airport'],
    ['CEZ', 'Cortez Municipal Airport'],
    ['HDN', 'Yampa Valley Airport'],
    ['GUP', 'Gallup Municipal Airport'],
    ['LBL', 'Liberal Mid-America Regional Airport'],
    ['LAA', 'Lamar Municipal Airport'],
    ['GLD', 'Renner Field-Goodland Municipal Airport'],
    ['COD', 'Yellowstone Regional Airport'],
    ['HOV', 'Ørsta-Volda Airport, Hovden'],
    ['ISC', "St. Mary's Airport"],
    ['SGF', 'Springfield Branson National Airport'],
    ['NVK', 'Narvik Framnes Airport'],
    ['BVG', 'Berlevåg Airport'],
    ['FBU', 'Oslo, Fornebu Airport'],
    ['NSK', 'Norilsk-Alykel Airport'],
    ['AAQ', 'Anapa Vityazevo Airport'],
    ['JLN', 'Joplin Regional Airport'],
    ['ABE', 'Lehigh Valley International Airport'],
    ['XNA', 'Northwest Arkansas Regional Airport'],
    ['GUW', 'Atyrau Airport'],
    ['KZO', 'Kzyl-Orda Southwest Airport'],
    ['SBN', 'South Bend Regional Airport'],
    ['BKA', 'Bykovo Airport'],
    ['ARH', 'Talagi Airport'],
    ['RTW', 'Saratov Central Airport'],
    ['NUX', 'Novy Urengoy Airport'],
    ['NOJ', 'Noyabrsk Airport'],
    ['SCO', 'Aktau Airport'],
    ['UCT', 'Ukhta Airport'],
    ['USK', 'Usinsk Airport'],
    ['PEX', 'Pechora Airport'],
    ['NNM', 'Naryan Mar Airport'],
    ['PKV', 'Pskov Airport'],
    ['KGP', 'Kogalym International Airport'],
    ['KJA', 'Yemelyanovo Airport'],
    ['KGF', 'Sary-Arka Airport'],
    ['URJ', 'Uray Airport'],
    ['IWA', 'Ivanovo South Airport'],
    ['CGQ', 'Longjia Airport'],
    ['KIJ', 'Niigata Airport'],
    ['JON', 'Johnston Atoll Airport'],
    ['SMD', 'Smith Field'],
    ['ACV', 'California Redwood Coast-Humboldt County Airport'],
    ['OAJ', 'Albert J Ellis Airport'],
    ['TCL', 'Tuscaloosa Regional Airport'],
    ['DBQ', 'Dubuque Regional Airport'],
    ['HHP', 'Shun Tak Heliport'],
    ['ATD', 'Uru Harbour Airport'],
    ['AKS', "Gwaunaru'u Airport"],
    ['BAS', 'Ballalae Airport'],
    ['FRE', 'Fera/Maringe Airport'],
    ['MBU', 'Babanakira Airport'],
    ['IRA', 'Ngorangora Airport'],
    ['SCZ', 'Santa Cruz/Graciosa Bay/Luova Airport'],
    ['MUA', 'Munda Airport'],
    ['GZO', 'Nusatupe Airport'],
    ['MNY', 'Mono Airport'],
    ['RNL', 'Rennell/Tingoa Airport'],
    ['RUS', 'Marau Airport'],
    ['VAO', 'Suavanao Airport'],
    ['KGE', 'Kaghau Airport'],
    ['RBV', 'Ramata Airport'],
    ['BUA', 'Buka Airport'],
    ['CMU', 'Chimbu Airport'],
    ['DAU', 'Daru Airport'],
    ['GUR', 'Gurney Airport'],
    ['PNP', 'Girua Airport'],
    ['HKN', 'Kimbe Airport'],
    ['UNG', 'Kiunga Airport'],
    ['KRI', 'Kikori Airport'],
    ['KMA', 'Kerema Airport'],
    ['KVG', 'Kavieng Airport'],
    ['MDU', 'Mendi Airport'],
    ['MAS', 'Momote Airport'],
    ['MXH', 'Moro Airport'],
    ['MIS', 'Misima Island Airport'],
    ['TIZ', 'Tari Airport'],
    ['TBG', 'Tabubil Airport'],
    ['RAB', 'Tokua Airport'],
    ['VAI', 'Vanimo Airport'],
    ['WBM', 'Wapenamanda Airport'],
    ['LLU', 'Alluitsup Paa Heliport'],
    ['CNP', 'Neerlerit Inaat Airport'],
    ['JFR', 'Paamiut Heliport'],
    ['JGO', 'Qeqertarsuaq Heliport'],
    ['JJU', 'Qaqortoq Heliport'],
    ['JSU', 'Maniitsoq Airport'],
    ['JNN', 'Nanortalik Heliport'],
    ['JNS', 'Narsaq Heliport'],
    ['NAQ', 'Qaanaaq Airport'],
    ['JHS', 'Sisimiut Airport'],
    ['JUV', 'Upernavik Airport'],
    ['JQA', 'Qaarsut Airport'],
    ['GRY', 'Grímsey Airport'],
    ['THO', 'Thorshofn Airport'],
    ['VPN', 'Vopnafjörður Airport'],
    ['YWS', 'Whistler/Green Lake Water Aerodrome'],
    ['YAA', 'Anahim Lake Airport'],
    ['YWM', 'Williams Harbour Airport'],
    ['YFX', 'St. Lewis (Fox Harbour) Airport'],
    ['YHA', 'Port Hope Simpson Airport'],
    ['YRG', 'Rigolet Airport'],
    ['YCK', 'Colville Lake Airport'],
    ['YLE', 'Whatì Airport'],
    ['SUR', 'Summer Beaver Airport'],
    ['YAX', 'Wapekeka Airport'],
    ['WNN', 'Wunnumin Lake Airport'],
    ['YNO', 'North Spirit Lake Airport'],
    ['XBE', 'Bearskin Lake Airport'],
    ['KIF', 'Kingfisher Lake Airport'],
    ['YOG', 'Ogoki Post Airport'],
    ['YHP', 'Poplar Hill Airport'],
    ['YKU', 'Chisasibi Airport'],
    ['ZTB', 'Tête-à-la-Baleine Airport'],
    ['ZLT', 'La Tabatière Airport'],
    ['YAC', 'Cat Lake Airport'],
    ['YAG', 'Fort Frances Municipal Airport'],
    ['XKS', 'Kasabonika Airport'],
    ['YKG', 'Kangirsuk Airport'],
    ['YAT', 'Attawapiskat Airport'],
    ['YBE', 'Uranium City Airport'],
    ['YBX', 'Lourdes de Blanc Sablon Airport'],
    ['YRF', 'Cartwright Airport'],
    ['YCS', 'Chesterfield Inlet Airport'],
    ['YDP', 'Nain Airport'],
    ['YER', 'Fort Severn Airport'],
    ['YFA', 'Fort Albany Airport'],
    ['YFH', 'Fort Hope Airport'],
    ['YMN', 'Makkovik Airport'],
    ['YGB', 'Texada Gillies Bay Airport'],
    ['YGO', 'Gods Lake Narrows Airport'],
    ['YGT', 'Igloolik Airport'],
    ['YGW', 'Kuujjuarapik Airport'],
    ['YGX', 'Gillam Airport'],
    ['YGZ', 'Grise Fiord Airport'],
    ['YQC', 'Quaqtaq Airport'],
    ['CXH', 'Vancouver Harbour Water Aerodrome'],
    ['YNS', 'Nemiscau Airport'],
    ['YHO', 'Hopedale Airport'],
    ['YHR', 'Chevery Airport'],
    ['YIK', 'Ivujivik Airport'],
    ['YIV', 'Island Lake Airport'],
    ['AKV', 'Akulivik Airport'],
    ['YKQ', 'Waskaganish Airport'],
    ['YPJ', 'Aupaluk Airport'],
    ['YLC', 'Kimmirut Airport'],
    ['YLH', 'Lansdowne House Airport'],
    ['XGR', 'Kangiqsualujjuaq (Georges River) Airport'],
    ['YMH', "Mary's Harbour Airport"],
    ['YMT', 'Chapais Airport'],
    ['YUD', 'Umiujaq Airport'],
    ['YNC', 'Wemindji Airport'],
    ['YNE', 'Norway House Airport'],
    ['YNL', 'Points North Landing Airport'],
    ['YOH', 'Oxford House Airport'],
    ['YPH', 'Inukjuak Airport'],
    ['YPM', 'Pikangikum Airport'],
    ['YPO', 'Peawanuck Airport'],
    ['YPW', 'Powell River Airport'],
    ['YQD', 'The Pas Airport'],
    ['YQN', 'Nakina Airport'],
    ['YRA', 'Rae Lakes Airport'],
    ['YRL', 'Red Lake Airport'],
    ['YSF', 'Stony Rapids Airport'],
    ['YSK', 'Sanikiluaq Airport'],
    ['YST', 'St. Theresa Point Airport'],
    ['YTL', 'Big Trout Lake Airport'],
    ['YVZ', 'Deer Lake Airport'],
    ['YWP', 'Webequie Airport'],
    ['YXN', 'Whale Cove Airport'],
    ['YZG', 'Salluit Airport'],
    ['ZAC', 'York Landing Airport'],
    ['ILF', 'Ilford Airport'],
    ['ZBF', 'Bathurst Airport'],
    ['ZEM', 'Eastmain River Airport'],
    ['ZFD', 'Fond-Du-Lac Airport'],
    ['ZGI', 'Gods River Airport'],
    ['ZJN', 'Swan River Airport'],
    ['ZKE', 'Kashechewan Airport'],
    ['MSA', 'Muskrat Dam Airport'],
    ['ZMT', 'Masset Airport'],
    ['ZPB', 'Sachigo Lake Airport'],
    ['ZRJ', 'Round Lake (Weagamow Lake) Airport'],
    ['ZSJ', 'Sandy Lake Airport'],
    ['ZTM', 'Shamattawa Airport'],
    ['ZUM', 'Churchill Falls Airport'],
    ['ZWL', 'Wollaston Lake Airport'],
    ['BLJ', 'Batna Airport'],
    ['CBH', 'Béchar Boudghene Ben Ali Lotfi Airport'],
    ['BMW', 'Bordj Badji Mokhtar Airport'],
    ['ELU', 'Guemar Airport'],
    ['KMS', 'Kumasi Airport'],
    ['HDF', 'Heringsdorf Airport'],
    ['HEI', 'Heide-Büsum Airport'],
    ['HGL', 'Helgoland-Düne Airport'],
    ['SJY', 'Seinäjoki Airport'],
    ['NQT', 'Nottingham Airport'],
    ['DSA', 'Robin Hood Doncaster Sheffield Airport'],
    ['CAL', 'Campbeltown Airport'],
    ['EOI', 'Eday Airport'],
    ['FIE', 'Fair Isle Airport'],
    ['NRL', 'North Ronaldsay Airport'],
    ['PPW', 'Papa Westray Airport'],
    ['SOY', 'Stronsay Airport'],
    ['NDY', 'Sanday Airport'],
    ['LWK', 'Lerwick / Tingwall Airport'],
    ['WRY', 'Westray Airport'],
    ['LEQ', "Land's End Airport"],
    ['PZE', 'Penzance Heliport'],
    ['VLY', 'Anglesey Airport'],
    ['BRR', 'Barra Airport'],
    ['CFN', 'Donegal Airport'],
    ['CNL', 'Sindal Airport'],
    ['LKN', 'Leknes Airport'],
    ['OSY', 'Namsos Høknesøra Airport'],
    ['MQN', 'Mo i Rana Airport, Røssvoll'],
    ['RVK', 'Rørvik Airport, Ryum'],
    ['RET', 'Røst Airport'],
    ['SDN', 'Sandane Airport (Anda)'],
    ['SOG', 'Sogndal Airport'],
    ['SVJ', 'Svolvær Helle Airport'],
    ['SOJ', 'Sørkjosen Airport'],
    ['VAW', 'Vardø Airport, Svartnes'],
    ['VRY', 'Værøy Heliport'],
    ['BZG', 'Bydgoszcz Ignacy Jan Paderewski Airport'],
    ['LCJ', 'Łódź Władysław Reymont Airport'],
    ['OSD', 'Åre Östersund Airport'],
    ['HFS', 'Hagfors Airport'],
    ['KSD', 'Karlstad Airport'],
    ['TYF', 'Torsby Airport'],
    ['AGH', 'Ängelholm-Helsingborg Airport'],
    ['SQO', 'Storuman Airport'],
    ['HMV', 'Hemavan Airport'],
    ['VNT', 'Ventspils International Airport'],
    ['QRA', 'Rand Airport'],
    ['MQP', 'Kruger Mpumalanga International Airport'],
    ['AAM', 'Malamala Airport'],
    ['MBD', 'Mmabatho International Airport'],
    ['GNZ', 'Ghanzi Airport'],
    ['ORP', 'Orapa Airport'],
    ['SWX', 'Shakawe Airport'],
    ['TLD', 'Limpopo Valley Airport'],
    ['DIS', 'Ngot Nzoungou Airport'],
    ['CIP', 'Chipata Airport'],
    ['YVA', 'Iconi Airport'],
    ['WAQ', 'Antsalova Airport'],
    ['JVA', 'Ankavandra Airport'],
    ['BMD', 'Belo sur Tsiribihina Airport'],
    ['MXT', 'Maintirano Airport'],
    ['TVA', 'Morafenobe Airport'],
    ['WTA', 'Tambohorano Airport'],
    ['WTS', 'Tsiroanomandidy Airport'],
    ['WAM', 'Ambatondrazaka Airport'],
    ['WPB', 'Port Bergé Airport'],
    ['DWB', 'Soalala Airport'],
    ['WMP', 'Mampikony Airport'],
    ['WMA', 'Mandritsara Airport'],
    ['MJA', 'Manja Airport'],
    ['CBT', 'Catumbela Airport'],
    ['DUE', 'Dundo Airport'],
    ['VPE', 'Ngjiva Pereira Airport'],
    ['MSZ', 'Namibe Airport'],
    ['KOU', 'Koulamoutou Mabimbi Airport'],
    ['MJL', 'Mouilla Ville Airport'],
    ['TCH', 'Tchibanga Airport'],
    ['VPY', 'Chimoio Airport'],
    ['SRH', 'Sarh Airport'],
    ['CMK', 'Club Makokola Airport'],
    ['LUD', 'Luderitz Airport'],
    ['OND', 'Ondangwa Airport'],
    ['OMD', 'Oranjemund Airport'],
    ['SWP', 'Swakopmund Airport'],
    ['ERS', 'Eros Airport'],
    ['BOA', 'Boma Airport'],
    ['MAT', 'Tshimpi Airport'],
    ['INO', 'Inongo Airport'],
    ['NIO', 'Nioki Airport'],
    ['KRZ', 'Basango Mboliasa Airport'],
    ['BSU', 'Basankusu Airport'],
    ['TSH', 'Tshikapa Airport'],
    ['LJA', 'Lodja Airport'],
    ['PFR', 'Ilebo Airport'],
    ['OUK', 'Outer Skerries Airport'],
    ['GMZ', 'La Gomera Airport'],
    ['BTE', 'Sherbro International Airport'],
    ['KBS', 'Bo Airport'],
    ['KEN', 'Kenema Airport'],
    ['OXB', 'Osvaldo Vieira International Airport'],
    ['SMW', 'Smara Airport'],
    ['VIL', 'Dakhla Airport'],
    ['ESU', 'Mogador Airport'],
    ['EUN', 'Hassan I Airport'],
    ['NDR', 'Nador International Airport'],
    ['RAI', 'Praia International Airport'],
    ['SFL', 'São Filipe Airport'],
    ['BCO', 'Baco Airport'],
    ['BEI', 'Beica Airport'],
    ['DSE', 'Combolcha Airport'],
    ['DEM', 'Dembidollo Airport'],
    ['GDE', 'Gode Airport'],
    ['GOR', 'Gore Airport'],
    ['ABK', 'Kabri Dehar Airport'],
    ['MTF', 'Mizan Teferi Airport'],
    ['TIE', 'Tippi Airport'],
    ['ALU', 'Alula Airport'],
    ['BSA', 'Bosaso Airport'],
    ['MGQ', 'Aden Adde International Airport'],
    ['GLK', 'Galcaio Airport'],
    ['BUO', 'Burao Airport'],
    ['AAC', 'El Arish International Airport'],
    ['ATZ', 'Assiut International Airport'],
    ['ASV', 'Amboseli Airport'],
    ['LKG', 'Lokichoggio Airport'],
    ['MYD', 'Malindi Airport'],
    ['NYK', 'Nanyuki Airport'],
    ['SRX', 'Gardabya Airport'],
    ['TOB', 'Gamal Abdel Nasser Airport'],
    ['MJI', 'Mitiga Airport'],
    ['LAQ', 'La Abraq Airport'],
    ['ATB', 'Atbara Airport'],
    ['UYL', 'Nyala Airport'],
    ['PZU', 'Port Sudan New International Airport'],
    ['BKZ', 'Bukoba Airport'],
    ['TKQ', 'Kigoma Airport'],
    ['LDI', 'Lindi Airport'],
    ['MUZ', 'Musoma Airport'],
    ['SHY', 'Shinyanga Airport'],
    ['TBO', 'Tabora Airport'],
    ['RUA', 'Arua Airport'],
    ['ULU', 'Gulu Airport'],
    ['DIU', 'Diu Airport'],
    ['ABR', 'Aberdeen Regional Airport'],
    ['ABY', 'Southwest Georgia Regional Airport'],
    ['AHN', 'Athens Ben Epps Airport'],
    ['ALM', 'Alamogordo White Sands Regional Airport'],
    ['ALO', 'Waterloo Regional Airport'],
    ['ALW', 'Walla Walla Regional Airport'],
    ['APN', 'Alpena County Regional Airport'],
    ['ATY', 'Watertown Regional Airport'],
    ['BFD', 'Bradford Regional Airport'],
    ['BFF', 'Western Neb. Rgnl/William B. Heilig Airport'],
    ['BKW', 'Raleigh County Memorial Airport'],
    ['BQK', 'Brunswick Golden Isles Airport'],
    ['BRL', 'Southeast Iowa Regional Airport'],
    ['CEC', 'Jack Mc Namara Field Airport'],
    ['CGI', 'Cape Girardeau Regional Airport'],
    ['CIU', 'Chippewa County International Airport'],
    ['CKB', 'North Central West Virginia Airport'],
    ['CLM', 'William R Fairchild International Airport'],
    ['CMX', 'Houghton County Memorial Airport'],
    ['DDC', 'Dodge City Regional Airport'],
    ['DUJ', 'DuBois Regional Airport'],
    ['EAU', 'Chippewa Valley Regional Airport'],
    ['EKO', 'Elko Regional Airport'],
    ['EWB', 'New Bedford Regional Airport'],
    ['FAY', 'Fayetteville Regional Grannis Field'],
    ['GGW', 'Wokal Field Glasgow International Airport'],
    ['GRI', 'Central Nebraska Regional Airport'],
    ['HOT', 'Memorial Field'],
    ['HTS', 'Tri-State/Milton J. Ferguson Field'],
    ['KIO', 'Kili Airport'],
    ['IRK', 'Kirksville Regional Airport'],
    ['JMS', 'Jamestown Regional Airport'],
    ['LAR', 'Laramie Regional Airport'],
    ['LBE', 'Arnold Palmer Regional Airport'],
    ['LBF', 'North Platte Regional Airport Lee Bird Field'],
    ['LEB', 'Lebanon Municipal Airport'],
    ['LMT', 'Crater Lake-Klamath Regional Airport'],
    ['LNS', 'Lancaster Airport'],
    ['LWT', 'Lewistown Municipal Airport'],
    ['LYH', 'Lynchburg Regional Preston Glenn Field'],
    ['MKG', 'Muskegon County Airport'],
    ['MLS', 'Frank Wiley Field'],
    ['MSL', 'Northwest Alabama Regional Airport'],
    ['OTH', 'Southwest Oregon Regional Airport'],
    ['OWB', 'Owensboro Daviess County Airport'],
    ['PIB', 'Hattiesburg Laurel Regional Airport'],
    ['PIH', 'Pocatello Regional Airport'],
    ['PIR', 'Pierre Regional Airport'],
    ['PLN', 'Pellston Regional Airport of Emmet County Airport'],
    ['PSM', 'Portsmouth International at Pease Airport'],
    ['RDG', 'Reading Regional Carl A Spaatz Field'],
    ['RHI', 'Rhinelander Oneida County Airport'],
    ['RKS', 'Southwest Wyoming Regional Airport'],
    ['RUT', 'Rutland - Southern Vermont Regional Airport'],
    ['SBP', 'San Luis County Regional Airport'],
    ['SHR', 'Sheridan County Airport'],
    ['SLK', 'Adirondack Regional Airport'],
    ['SLN', 'Salina Municipal Airport'],
    ['SMX', 'Santa Maria Pub/Capt G Allan Hancock Field'],
    ['TUP', 'Tupelo Regional Airport'],
    ['UIN', 'Quincy Regional Baldwin Field'],
    ['VCT', 'Victoria Regional Airport'],
    ['VLD', 'Valdosta Regional Airport'],
    ['WRL', 'Worland Municipal Airport'],
    ['YKM', 'Yakima Air Terminal McAllister Field'],
    ['ECN', 'Ercan International Airport'],
    ['RJL', 'Logroño-Agoncillo Airport'],
    ['IDY', "Île d'Yeu Airport"],
    ['ANE', 'Angers-Loire Airport'],
    ['LTT', 'La Môle Airport'],
    ['JSY', 'Syros Airport'],
    ['PEV', 'Pécs-Pogány Airport'],
    ['SOB', 'Sármellék International Airport'],
    ['AOT', 'Aosta Airport'],
    ['QSR', "Salerno Costa d'Amalfi Airport"],
    ['CVU', 'Corvo Airport'],
    ['BNX', 'Banja Luka International Airport'],
    ['USQ', 'Uşak Airport'],
    ['KSY', 'Kars Airport'],
    ['SFQ', 'Şanlıurfa Airport'],
    ['KCM', 'Kahramanmaraş Airport'],
    ['AJI', 'Ağrı Airport'],
    ['ADF', 'Adıyaman Airport'],
    ['ISE', 'Süleyman Demirel International Airport'],
    ['EDO', 'Balıkesir Körfez Airport'],
    ['SZF', 'Samsun Çarşamba Airport'],
    ['ILZ', 'Žilina Airport'],
    ['GDT', 'JAGS McCartney International Airport'],
    ['MDS', 'Middle Caicos Airport'],
    ['SLX', 'Salt Cay Airport'],
    ['AZS', 'Samaná El Catey International Airport'],
    ['JBQ', 'La Isabela International Airport'],
    ['PBR', 'Puerto Barrios Airport'],
    ['AAZ', 'Quezaltenango Airport'],
    ['UTK', 'Utirik Airport'],
    ['AHS', 'Ahuas Airport'],
    ['PEU', 'Puerto Lempira Airport'],
    ['MIJ', 'Mili Island Airport'],
    ['CYW', 'Captain Rogelio Castillo National Airport'],
    ['CUA', 'Ciudad Constitución Airport'],
    ['GUB', 'Guerrero Negro Airport'],
    ['JAL', 'El Lencero Airport'],
    ['CTD', 'Alonso Valderrama Airport'],
    ['ONX', 'Enrique Adolfo Jimenez Airport'],
    ['JQE', 'Jaqué Airport'],
    ['PLP', 'Captain Ramon Xatruch Airport'],
    ['TTQ', 'Aerotortuguero Airport'],
    ['BCL', 'Barra del Colorado Airport'],
    ['PBP', 'Islita Airport'],
    ['PJM', 'Puerto Jimenez Airport'],
    ['SYQ', 'Tobias Bolanos International Airport'],
    ['JEE', 'Jérémie Airport'],
    ['PAX', 'Port-de-Paix Airport'],
    ['TND', 'Alberto Delgado Airport'],
    ['COX', 'Congo Town Airport'],
    ['ATC', "Arthur's Town Airport"],
    ['TBI', 'New Bight Airport'],
    ['CRI', 'Colonel Hill Airport'],
    ['PID', 'Nassau Paradise Island Airport'],
    ['AIU', 'Enua Airport'],
    ['MGS', 'Mangaia Island Airport'],
    ['MHX', 'Manihiki Island Airport'],
    ['MUK', 'Mauke Airport'],
    ['MOI', 'Mitiaro Island Airport'],
    ['PYE', 'Tongareva Airport'],
    ['ICI', 'Cicia Airport'],
    ['PTF', 'Malolo Lailai Island Airport'],
    ['KDV', 'Vunisea Airport'],
    ['MNF', 'Mana Island Airport'],
    ['MFJ', 'Moala Airport'],
    ['NGI', 'Ngau Airport'],
    ['LKB', 'Lakeba Island Airport'],
    ['LBS', 'Labasa Airport'],
    ['TVU', 'Matei Airport'],
    ['KXF', 'Koro Island Airport'],
    ['RTA', 'Rotuma Airport'],
    ['SVU', 'Savusavu Airport'],
    ['EUA', 'Kaufana Airport'],
    ['HPA', 'Lifuka Island Airport'],
    ['NFO', "Mata'aho Airport"],
    ['NTT', 'Kuini Lavenia Airport'],
    ['VBV', 'Vanua Balavu Airport'],
    ['IUE', 'Niue International Airport'],
    ['FUT', 'Pointe Vele Airport'],
    ['MXS', 'Maota Airport'],
    ['APK', 'Apataki Airport'],
    ['AHE', 'Ahe Airport'],
    ['AUQ', 'Hiva Oa-Atuona Airport'],
    ['UAP', 'Ua Pou Airport'],
    ['UAH', 'Ua Huka Airport'],
    ['MTV', 'Mota Lava Airport'],
    ['SLH', 'Sola Airport'],
    ['TOH', 'Torres Airstrip'],
    ['EAE', 'Siwo Airport'],
    ['CCV', 'Craig Cove Airport'],
    ['LOD', 'Longana Airport'],
    ['SSR', 'Sara Airport'],
    ['PBJ', 'Tavie Airport'],
    ['LPM', 'Lamap Airport'],
    ['LNB', 'Lamen Bay Airport'],
    ['MWF', 'Maewo-Naone Airport'],
    ['LNE', 'Lonorore Airport'],
    ['NUS', 'Norsup Airport'],
    ['ZGU', 'Gaua Island Airport'],
    ['RCL', 'Redcliffe Airport'],
    ['SON', 'Santo Pekoa International Airport'],
    ['TGH', 'Tongoa Airport'],
    ['ULB', 'Uléi Airport'],
    ['VLS', 'Valesdir Airport'],
    ['SWJ', 'Southwest Bay Airport'],
    ['OLJ', 'North West Santo Airport'],
    ['AUY', 'Aneityum Airport'],
    ['AWD', 'Aniwa Airport'],
    ['DLY', "Dillon's Bay Airport"],
    ['FTA', 'Futuna Airport'],
    ['IPA', 'Ipota Airport'],
    ['TGJ', 'Tiga Airport'],
    ['BMY', 'Île Art - Waala Airport'],
    ['ILP', 'Île des Pins Airport'],
    ['FBD', 'Fayzabad Airport'],
    ['AJF', 'Al-Jawf Domestic Airport'],
    ['WAE', 'Wadi Al Dawasir Airport'],
    ['KHD', 'Khoram Abad Airport'],
    ['BXR', 'Bam Airport'],
    ['RJN', 'Rafsanjan Airport'],
    ['BJB', 'Bojnord Airport'],
    ['AFZ', 'Sabzevar National Airport'],
    ['NSH', 'Noshahr Airport'],
    ['SRY', 'Dasht-e Naz Airport'],
    ['LRR', 'Lar Airport'],
    ['ADU', 'Ardabil Airport'],
    ['OMH', 'Urmia Airport'],
    ['AAN', 'Al Ain International Airport'],
    ['BNP', 'Bannu Airport'],
    ['BHV', 'Bahawalpur Airport'],
    ['CJL', 'Chitral Airport'],
    ['DBA', 'Dalbandin Airport'],
    ['DEA', 'Dera Ghazi Khan Airport'],
    ['DSK', 'Dera Ismael Khan Airport'],
    ['JIW', 'Jiwani Airport'],
    ['HDD', 'Hyderabad Airport'],
    ['KDD', 'Khuzdar Airport'],
    ['ORW', 'Ormara Airport'],
    ['PAJ', 'Parachinar Airport'],
    ['KDU', 'Skardu Airport'],
    ['SYW', 'Sehwan Sharif Airport'],
    ['TUK', 'Turbat International Airport'],
    ['ISU', 'Sulaymaniyah International Airport'],
    ['KAC', 'Kamishly Airport'],
    ['GXF', 'Sayun International Airport'],
    ['ADK', 'Adak Airport'],
    ['GST', 'Gustavus Airport'],
    ['SGY', 'Skagway Airport'],
    ['HCR', 'Holy Cross Airport'],
    ['HNS', 'Haines Airport'],
    ['KLG', 'Kalskag Airport'],
    ['MCG', 'McGrath Airport'],
    ['MOU', 'Mountain Village Airport'],
    ['ANI', 'Aniak Airport'],
    ['VAK', 'Chevak Airport'],
    ['WRG', 'Wrangell Airport'],
    ['OPU', 'Balimo Airport'],
    ['VMU', 'Baimuru Airport'],
    ['LUP', 'Kalaupapa Airport'],
    ['ENT', 'Eniwetok Airport'],
    ['LZN', 'Matsu Nangan Airport'],
    ['HCN', 'Hengchun Airport'],
    ['MFK', 'Matsu Beigan Airport'],
    ['KUH', 'Kushiro Airport'],
    ['OKD', 'Okadama Airport'],
    ['HSG', 'Saga Airport'],
    ['NKM', 'Nagoya Airport'],
    ['IWJ', 'Iwami Airport'],
    ['FKS', 'Fukushima Airport'],
    ['ONJ', 'Odate Noshiro Airport'],
    ['SYO', 'Shonai Airport'],
    ['MYE', 'Miyakejima Airport'],
    ['KUV', 'Kunsan Air Base'],
    ['MPK', 'Mokpo Heliport'],
    ['WJU', 'Wonju/Hoengseong Air Base (K-38/K-46)'],
    ['YNY', 'Yangyang International Airport'],
    ['HIN', 'Sacheon Air Base/Airport'],
    ['CJJ', 'Cheongju International Airport/Cheongju Air Base (K-59/G-513)'],
    ['SFS', 'Subic Bay International Airport'],
    ['CYU', 'Cuyo Airport'],
    ['CGM', 'Camiguin Airport'],
    ['JOL', 'Jolo Airport'],
    ['TWT', 'Sanga Sanga Airport'],
    ['SUG', 'Surigao Airport'],
    ['TDG', 'Tandag Airport'],
    ['WNP', 'Naga Airport'],
    ['BSO', 'Basco Airport'],
    ['SFE', 'San Fernando Airport'],
    ['TUG', 'Tuguegarao Airport'],
    ['VRC', 'Virac Airport'],
    ['CYP', 'Calbayog Airport'],
    ['CRM', 'Catarman National Airport'],
    ['MBT', 'Moises R. Espinosa Airport'],
    ['RXS', 'Roxas Airport'],
    ['TTG', 'General Enrique Mosconi Airport'],
    ['LHS', 'Las Heras Airport'],
    ['OES', 'Antoine de Saint Exupéry Airport'],
    ['ING', 'Lago Argentino Airport'],
    ['GGS', 'Gobernador Gregores Airport'],
    ['SST', 'Santa Teresita Airport'],
    ['NEC', 'Necochea Airport'],
    ['JDO', 'Orlando Bezerra de Menezes Airport'],
    ['LEC', 'Coronel Horácio de Mattos Airport'],
    ['MEA', 'Macaé Airport'],
    ['MII', 'Frank Miloye Milenkowichi–Marília State Airport'],
    ['VDC', 'Vitória da Conquista Airport'],
    ['RIA', 'Santa Maria Airport'],
    ['TOW', 'Toledo Airport'],
    ['ESR', 'Ricardo García Posada Airport'],
    ['ZPC', 'Pucón Airport'],
    ['SOD', 'Sorocaba Airport'],
    ['SCY', 'San Cristóbal Airport'],
    ['LOH', 'Camilo Ponce Enriquez Airport'],
    ['ESM', 'General Rivadeneira Airport'],
    ['PSY', 'Port Stanley Airport'],
    ['CRC', 'Santa Ana Airport'],
    ['LQM', 'Caucaya Airport'],
    ['LPD', 'La Pedrera Airport'],
    ['NQU', 'Reyes Murillo Airport'],
    ['PDA', 'Obando Airport'],
    ['EYP', 'El Yopal Airport'],
    ['GYA', 'Capitán de Av. Emilio Beltrán Airport'],
    ['PUR', 'Puerto Rico Airport'],
    ['RIB', 'Capitán Av. Selin Zeitun Lopez Airport'],
    ['REY', 'Reyes Airport'],
    ['SRJ', 'Capitán Av. German Quiroga G. Airport'],
    ['ORG', 'Zorg en Hoop Airport'],
    ['MVS', 'Mucuri Airport'],
    ['CJA', 'Mayor General FAP Armando Revoredo Iglesias Airport'],
    ['HUU', 'Alferez Fap David Figueroa Fernandini Airport'],
    ['NZC', 'Maria Reiche Neuman Airport'],
    ['SRA', 'Santa Rosa Airport'],
    ['MYC', 'Escuela Mariscal Sucre Airport'],
    ['VIG', 'Juan Pablo Pérez Alfonso Airport'],
    ['JPR', 'Ji-Paraná Airport'],
    ['BBQ', 'Codrington Airport'],
    ['DSD', 'La Désirade Airport'],
    ['BBR', 'Baillif Airport'],
    ['SFC', 'St-François Airport'],
    ['GBJ', 'Les Bases Airport'],
    ['NEV', 'Vance W. Amory International Airport'],
    ['VIJ', 'Virgin Gorda Airport'],
    ['BQU', 'J F Mitchell Airport'],
    ['UNI', 'Union Island International Airport'],
    ['KOV', 'Kokshetau Airport'],
    ['PPK', 'Petropavlosk South Airport'],
    ['DZN', 'Zhezkazgan Airport'],
    ['UKK', 'Ust-Kamennogorsk Airport'],
    ['KSN', 'Kostanay West Airport'],
    ['KVD', 'Ganja Airport'],
    ['NAJ', 'Nakhchivan Airport'],
    ['NER', 'Chulman Airport'],
    ['PYJ', 'Polyarny Airport'],
    ['CKH', 'Chokurdakh Airport'],
    ['CYX', 'Cherskiy Airport'],
    ['IKS', 'Tiksi Airport'],
    ['KXK', 'Komsomolsk-on-Amur Airport'],
    ['DYR', 'Ugolny Airport'],
    ['OHO', 'Okhotsk Airport'],
    ['UJE', 'Ujae Atoll Airport'],
    ['MPW', 'Mariupol International Airport'],
    ['VSG', 'Luhansk International Airport'],
    ['OZH', 'Zaporizhzhia International Airport'],
    ['KWG', 'Kryvyi Rih International Airport'],
    ['HRK', 'Kharkiv International Airport'],
    ['IFO', 'Ivano-Frankivsk International Airport'],
    ['CWC', 'Chernivtsi International Airport'],
    ['RWN', 'Rivne International Airport'],
    ['UDJ', 'Uzhhorod International Airport'],
    ['CSH', 'Solovki Airport'],
    ['CEE', 'Cherepovets Airport'],
    ['AMV', 'Amderma Airport'],
    ['KSZ', 'Kotlas Airport'],
    ['PES', 'Petrozavodsk Airport'],
    ['GNA', 'Hrodna Airport'],
    ['MVQ', 'Mogilev Airport'],
    ['EIE', 'Yeniseysk Airport'],
    ['KYZ', 'Kyzyl Airport'],
    ['NOZ', 'Spichenkovo Airport'],
    ['HTG', 'Khatanga Airport'],
    ['IAA', 'Igarka Airport'],
    ['NAL', 'Nalchik Airport'],
    ['OGZ', 'Beslan Airport'],
    ['ESL', 'Elista Airport'],
    ['WKK', 'Aleknagik / New Airport'],
    ['BLF', 'Mercer County Airport'],
    ['GLH', 'Mid Delta Regional Airport'],
    ['PSC', 'Tri Cities Airport'],
    ['KQA', 'Akutan Seaplane Base'],
    ['LPS', 'Lopez Island Airport'],
    ['SLY', 'Salekhard Airport'],
    ['HMA', 'Khanty Mansiysk Airport'],
    ['NYA', 'Nyagan Airport'],
    ['OVS', 'Sovetskiy Airport'],
    ['IJK', 'Izhevsk Airport'],
    ['KVX', 'Pobedilovo Airport'],
    ['NYM', 'Nadym Airport'],
    ['RAT', 'Raduzhny Airport'],
    ['NFG', 'Nefteyugansk Airport'],
    ['KRO', 'Kurgan Airport'],
    ['LBD', 'Khudzhand Airport'],
    ['AZN', 'Andizhan Airport'],
    ['FEG', 'Fergana International Airport'],
    ['NMA', 'Namangan Airport'],
    ['NCU', 'Nukus Airport'],
    ['UGC', 'Urgench Airport'],
    ['KSQ', 'Karshi Khanabad Airport'],
    ['TMJ', 'Termez Airport'],
    ['RYB', 'Staroselye Airport'],
    ['EGO', 'Belgorod International Airport'],
    ['URS', 'Kursk East Airport'],
    ['LPK', 'Lipetsk Airport'],
    ['VKT', 'Vorkuta Airport'],
    ['UUA', 'Bugulma Airport'],
    ['JOK', 'Yoshkar-Ola Airport'],
    ['CSY', 'Cheboksary Airport'],
    ['ULY', 'Ulyanovsk East Airport'],
    ['OSW', 'Orsk Airport'],
    ['PEZ', 'Penza Airport'],
    ['SKX', 'Saransk Airport'],
    ['BWO', 'Balakovo Airport'],
    ['HBX', 'Hubli Airport'],
    ['KCT', 'Koggala Airport'],
    ['WRZ', 'Weerawila Airport'],
    ['BBM', 'Battambang Airport'],
    ['SHL', 'Shillong Airport'],
    ['GAU', 'Lokpriya Gopinath Bordoloi International Airport'],
    ['DMU', 'Dimapur Airport'],
    ['TEZ', 'Tezpur Airport'],
    ['BZL', 'Barisal Airport'],
    ['HOE', 'Ban Huoeisay Airport'],
    ['BHR', 'Bharatpur Airport'],
    ['BDP', 'Bhadrapur Airport'],
    ['MEY', 'Meghauli Airport'],
    ['KEP', 'Nepalgunj Airport'],
    ['GAN', 'Gan International Airport'],
    ['HAQ', 'Hanimaadhoo Airport'],
    ['KDO', 'Kadhdhoo Airport'],
    ['MAQ', 'Mae Sot Airport'],
    ['BMV', 'Buon Ma Thuot Airport'],
    ['HPH', 'Cat Bi International Airport'],
    ['CXR', 'Cam Ranh Airport'],
    ['VCS', 'Co Ong Airport'],
    ['VCA', 'Can Tho International Airport'],
    ['DIN', 'Dien Bien Phu Airport'],
    ['UIH', 'Phu Cat Airport'],
    ['PXU', 'Pleiku Airport'],
    ['VII', 'Vinh Airport'],
    ['BMO', 'Banmaw Airport'],
    ['TVY', 'Dawei Airport'],
    ['KAW', 'Kawthoung Airport'],
    ['LIW', 'Loikaw Airport'],
    ['MNU', 'Mawlamyine Airport'],
    ['BSX', 'Pathein Airport'],
    ['PKK', 'Pakhokku Airport'],
    ['SWQ', 'Sumbawa Besar Airport'],
    ['TMC', 'Tambolaka Airport'],
    ['BUI', 'Bokondini Airport'],
    ['SEH', 'Senggeh Airport'],
    ['TJS', 'Tanjung Harapan Airport'],
    ['DTD', 'Datadawai Airport'],
    ['BEJ', 'Kalimarau Airport'],
    ['TJG', 'Warukin Airport'],
    ['SMQ', 'Sampit(Hasan) Airport'],
    ['LUV', 'Dumatumbun Airport'],
    ['ARD', 'Mali Airport'],
    ['BLG', 'Belaga Airport'],
    ['LGL', 'Long Lellang Airport'],
    ['ODN', 'Long Seridan Airport'],
    ['MKM', 'Mukah Airport'],
    ['BKM', 'Bakalalan Airport'],
    ['LWY', 'Lawas Airport'],
    ['BBN', 'Bario Airport'],
    ['TMG', 'Tomanggong Airport'],
    ['KUD', 'Kudat Airport'],
    ['TKG', 'Radin Inten II (Branti) Airport'],
    ['HLP', 'Halim Perdanakusuma International Airport'],
    ['NTX', 'Ranai Airport'],
    ['PSU', 'Pangsuma Airport'],
    ['SQG', 'Sintang(Susilo) Airport'],
    ['PDO', 'Pendopo Airport'],
    ['LSW', 'Malikus Saleh Airport'],
    ['PKG', 'Pulau Pangkor Airport'],
    ['LBW', 'Long Bawan Airport'],
    ['NNX', 'Nunukan Airport'],
    ['LPU', 'Long Apung Airport'],
    ['ALH', 'Albany Airport'],
    ['GYL', 'Argyle Airport'],
    ['AUU', 'Aurukun Airport'],
    ['BCI', 'Barcaldine Airport'],
    ['BDD', 'Badu Island Airport'],
    ['BVI', 'Birdsville Airport'],
    ['BHQ', 'Broken Hill Airport'],
    ['HTI', 'Hamilton Island Airport'],
    ['BEU', 'Bedourie Airport'],
    ['BRK', 'Bourke Airport'],
    ['BUC', 'Burketown Airport'],
    ['GIC', 'Boigu Airport'],
    ['OKY', 'Oakey Airport'],
    ['BQL', 'Boulia Airport'],
    ['BHS', 'Bathurst Airport'],
    ['BLT', 'Blackwater Airport'],
    ['CVQ', 'Carnarvon Airport'],
    ['CAZ', 'Cobar Airport'],
    ['CPD', 'Coober Pedy Airport'],
    ['CNC', 'Coconut Island Airport'],
    ['CNJ', 'Cloncurry Airport'],
    ['CED', 'Ceduna Airport'],
    ['CTN', 'Cooktown Airport'],
    ['CMA', 'Cunnamulla Airport'],
    ['CNB', 'Coonamble Airport'],
    ['CUQ', 'Coen Airport'],
    ['OOM', 'Cooma Snowy Mountains Airport'],
    ['DMD', 'Doomadgee Airport'],
    ['NLF', 'Darnley Island Airport'],
    ['DPO', 'Devonport Airport'],
    ['ELC', 'Elcho Island Airport'],
    ['EPR', 'Esperance Airport'],
    ['FLS', 'Flinders Island Airport'],
    ['GET', 'Geraldton Airport'],
    ['GLT', 'Gladstone Airport'],
    ['GTE', 'Groote Eylandt Airport'],
    ['GFF', 'Griffith Airport'],
    ['HID', 'Horn Island Airport'],
    ['HOK', 'Hooker Creek Airport'],
    ['MHU', 'Mount Hotham Airport'],
    ['HGD', 'Hughenden Airport'],
    ['JCK', 'Julia Creek Airport'],
    ['KAX', 'Kalbarri Airport'],
    ['KNS', 'King Island Airport'],
    ['KFG', 'Kalkgurung Airport'],
    ['KRB', 'Karumba Airport'],
    ['KWM', 'Kowanyama Airport'],
    ['KUG', 'Kubin Airport'],
    ['LNO', 'Leonora Airport'],
    ['LEL', 'Lake Evella Airport'],
    ['LDH', 'Lord Howe Island Airport'],
    ['IRG', 'Lockhart River Airport'],
    ['LSY', 'Lismore Airport'],
    ['LHG', 'Lightning Ridge Airport'],
    ['LRE', 'Longreach Airport'],
    ['LER', 'Leinster Airport'],
    ['LVO', 'Laverton Airport'],
    ['UBB', 'Mabuiag Island Airport'],
    ['MKR', 'Meekatharra Airport'],
    ['MIM', 'Merimbula Airport'],
    ['MGT', 'Milingimbi Airport'],
    ['MNG', 'Maningrida Airport'],
    ['MCV', 'McArthur River Mine Airport'],
    ['MQL', 'Mildura Airport'],
    ['MMG', 'Mount Magnet Airport'],
    ['MRZ', 'Moree Airport'],
    ['MOV', 'Moranbah Airport'],
    ['MYA', 'Moruya Airport'],
    ['MGB', 'Mount Gambier Airport'],
    ['ONG', 'Mornington Island Airport'],
    ['MYI', 'Murray Island Airport'],
    ['MBH', 'Maryborough Airport'],
    ['NRA', 'Narrandera Airport'],
    ['NAA', 'Narrabri Airport'],
    ['NTN', 'Normanton Airport'],
    ['ZNE', 'Newman Airport'],
    ['OLP', 'Olympic Dam Airport'],
    ['PUG', 'Port Augusta Airport'],
    ['PMK', 'Palm Island Airport'],
    ['PBO', 'Paraburdoo Airport'],
    ['CCK', 'Cocos (Keeling) Islands Airport'],
    ['GOV', 'Gove Airport'],
    ['PKE', 'Parkes Airport'],
    ['PLO', 'Port Lincoln Airport'],
    ['EDR', 'Pormpuraaw Airport'],
    ['PQQ', 'Port Macquarie Airport'],
    ['PTJ', 'Portland Airport'],
    ['ULP', 'Quilpie Airport'],
    ['RAM', 'Ramingining Airport'],
    ['RMA', 'Roma Airport'],
    ['SGO', 'St George Airport'],
    ['MJK', 'Shark Bay Airport'],
    ['SBR', 'Saibai Island Airport'],
    ['SRN', 'Strahan Airport'],
    ['XTG', 'Thargomindah Airport'],
    ['TCA', 'Tennant Creek Airport'],
    ['VCD', 'Victoria River Downs Airport'],
    ['SYU', 'Warraber Island Airport'],
    ['WNR', 'Windorah Airport'],
    ['WYA', 'Whyalla Airport'],
    ['WUN', 'Wiluna Airport'],
    ['WOL', 'Wollongong Airport'],
    ['WIN', 'Winton Airport'],
    ['BWT', 'Wynyard Airport'],
    ['OKR', 'Yorke Island Airport'],
    ['XMY', 'Yam Island Airport'],
    ['NAY', 'Beijing Nanyuan Airport'],
    ['CIF', 'Chifeng Airport'],
    ['CIH', 'Changzhi Airport'],
    ['DAT', 'Datong Airport'],
    ['HET', 'Baita International Airport'],
    ['BAV', 'Baotou Airport'],
    ['SJW', 'Shijiazhuang Daguocun International Airport'],
    ['TGO', 'Tongliao Airport'],
    ['HLH', 'Ulanhot Airport'],
    ['XIL', 'Xilinhot Airport'],
    ['BHY', 'Beihai Airport'],
    ['CGD', 'Changde Airport'],
    ['DYG', 'Dayong Airport'],
    ['MXZ', 'Meixian Airport'],
    ['ZUH', 'Zhuhai Jinwan Airport'],
    ['LZH', 'Liuzhou Bailian Airport'],
    ['ZHA', 'Zhanjiang Airport'],
    ['ENH', 'Enshi Airport'],
    ['NNY', 'Nanyang Jiangying Airport'],
    ['XFN', 'Xiangyang Liuji Airport'],
    ['YIH', 'Yichang Sanxia Airport'],
    ['AKA', 'Ankang Wulipu Airport'],
    ['GOQ', 'Golmud Airport'],
    ['HZG', 'Hanzhong Chenggu Airport'],
    ['IQN', 'Qingyang Airport'],
    ['XNN', 'Xining Caojiabu Airport'],
    ['ENY', "Yan'an Ershilipu Airport"],
    ['UYN', 'Yulin Yuyang Airport'],
    ['AVK', 'Arvaikheer Airport'],
    ['LTI', 'Altai Airport'],
    ['BYN', 'Bayankhongor Airport'],
    ['DLZ', 'Dalanzadgad Airport'],
    ['HVD', 'Khovd Airport'],
    ['MXV', 'Mörön Airport'],
    ['DIG', 'Diqing Airport'],
    ['LUM', 'Mangshi Airport'],
    ['SYM', "Pu'er Simao Airport"],
    ['ZAT', 'Zhaotong Airport'],
    ['KOW', 'Ganzhou Airport'],
    ['JDZ', 'Jingdezhen Airport'],
    ['JIU', 'Jiujiang Lushan Airport'],
    ['JUZ', 'Quzhou Airport'],
    ['LYG', 'Lianyungang Airport'],
    ['HYN', 'Huangyan Luqiao Airport'],
    ['LYI', 'Shubuling Airport'],
    ['JJN', 'Quanzhou Jinjiang International Airport'],
    ['TXN', 'Tunxi International Airport'],
    ['WEF', 'Weifang Airport'],
    ['WEH', 'Weihai Airport'],
    ['WUX', 'Sunan Shuofang International Airport'],
    ['WUS', 'Nanping Wuyishan Airport'],
    ['WNZ', 'Wenzhou Longwan International Airport'],
    ['YNZ', 'Yancheng Airport'],
    ['YIW', 'Yiwu Airport'],
    ['HSN', 'Zhoushan Airport'],
    ['BPX', 'Qamdo Bangda Airport'],
    ['DAX', 'Dachuan Airport'],
    ['GYS', 'Guangyuan Airport'],
    ['LZO', 'Luzhou Airport'],
    ['MIG', 'Mianyang Airport'],
    ['NAO', 'Nanchong Airport'],
    ['LZY', 'Nyingchi Airport'],
    ['WXN', 'Wanxian Airport'],
    ['AKU', 'Aksu Airport'],
    ['IQM', 'Qiemo Yudu Airport'],
    ['KCA', 'Kuqa Airport'],
    ['KRL', 'Korla Airport'],
    ['KRY', 'Karamay Airport'],
    ['YIN', 'Yining Airport'],
    ['HEK', 'Heihe Airport'],
    ['JMU', 'Jiamusi Airport'],
    ['JNZ', 'Jinzhou Airport'],
    ['NDG', 'Qiqihar Sanjiazi Airport'],
    ['YNJ', 'Yanji Chaoyangchuan Airport'],
    ['WKL', 'Waikoloa Heliport'],
    ['WME', 'Mount Keith Airport'],
    ['LRV', 'Los Roques Airport'],
    ['IOR', 'Inishmore Aerodrome'],
    ['NNR', 'Connemara Regional Airport'],
    ['GTI', 'Rügen Airport'],
    ['EZV', 'Berezovo Airport'],
    ['ORH', 'Worcester Regional Airport'],
    ['AQG', 'Anqing Tianzhushan Airport'],
    ['SHP', 'Shanhaiguan Airport'],
    ['YCU', 'Yuncheng Guangong Airport'],
    ['JGN', 'Jiayuguan Airport'],
    ['DDG', 'Dandong Airport'],
    ['DSN', 'Ordos Ejin Horo Airport'],
    ['PZI', "Bao'anying Airport"],
    ['PWT', 'Bremerton National Airport'],
    ['SPW', 'Spencer Municipal Airport'],
    ['JEF', 'Jefferson City Memorial Airport'],
    ['UNT', 'Unst Airport'],
    ['PVC', 'Provincetown Municipal Airport'],
    ['SBH', 'Gustaf III Airport'],
    ['KMW', 'Kostroma Sokerkino Airport'],
    ['SUI', 'Sukhumi Dranda Airport'],
    ['TBW', 'Donskoye Airport'],
    ['OBN', 'Oban Airport'],
    ['ERM', 'Erechim Airport'],
    ['CVF', 'Courchevel Airport'],
    ['FUL', 'Fullerton Municipal Airport'],
    ['USA', 'Concord-Padgett Regional Airport'],
    ['NVI', 'Navoi Airport'],
    ['QSF', 'Ain Arnat Airport'],
    ['LRH', 'La Rochelle-Île de Ré Airport'],
    ['SUN', 'Friedman Memorial Airport'],
    ['MCW', 'Mason City Municipal Airport'],
    ['AZA', 'Phoenix-Mesa-Gateway Airport'],
    ['XAU', 'Saúl Airport'],
    ['AKP', 'Anaktuvuk Pass Airport'],
    ['ANV', 'Anvik Airport'],
    ['ATK', 'Atqasuk Edward Burnell Sr Memorial Airport'],
    ['GAM', 'Gambell Airport'],
    ['HPB', 'Hooper Bay Airport'],
    ['KAL', 'Kaltag Airport'],
    ['KSM', "St Mary's Airport"],
    ['KVL', 'Kivalina Airport'],
    ['MYU', 'Mekoryuk Airport'],
    ['RBY', 'Ruby Airport'],
    ['SHH', 'Shishmaref Airport'],
    ['SVA', 'Savoonga Airport'],
    ['WTK', 'Noatak Airport'],
    ['OMC', 'Ormoc Airport'],
    ['YPX', 'Puvirnituq Airport'],
    ['YTQ', 'Tasiujaq Airport'],
    ['ARC', 'Arctic Village Airport'],
    ['QOW', 'Sam Mbakwe International Airport'],
    ['FON', 'Arenal Airport'],
    ['TMU', 'Tambor Airport'],
    ['CYZ', 'Cauayan Airport'],
    ['KVK', 'Kirovsk-Apatity Airport'],
    ['GVR', 'Coronel Altino Machado de Oliveira Airport'],
    ['KPC', 'Port Clarence Coast Guard Station'],
    ['PJA', 'Pajala Airport'],
    ['QBC', 'Bella Coola Airport'],
    ['HGR', 'Hagerstown Regional Richard A Henson Field'],
    ['ACR', 'Araracuara Airport'],
    ['GOP', 'Gorakhpur Airport'],
    ['SDP', 'Sand Point Airport'],
    ['HMI', 'Hami Airport'],
    ['WUZ', 'Wuzhou Changzhoudao Airport'],
    ['TBH', 'Tugdan Airport'],
    ['ACP', 'Sahand Airport'],
    ['GBT', 'Gorgan Airport'],
    ['IIL', 'Ilam Airport'],
    ['PFQ', 'Parsabade Moghan Airport'],
    ['TCG', 'Tacheng Airport'],
    ['MQM', 'Mardin Airport'],
    ['AFS', 'Sugraly Airport'],
    ['DRG', 'Deering Airport'],
    ['LEN', 'Leon Airport'],
    ['RGS', 'Burgos Airport'],
    ['EGM', 'Sege Airport'],
    ['CQD', 'Shahrekord Airport'],
    ['DHM', 'Kangra Airport'],
    ['NDC', 'Nanded Airport'],
    ['SLV', 'Shimla Airport'],
    ['IGG', 'Igiugig Airport'],
    ['KNW', 'New Stuyahok Airport'],
    ['KVC', 'King Cove Airport'],
    ['PTH', 'Port Heiden Airport'],
    ['TOG', 'Togiak Airport'],
    ['EGN', 'Geneina Airport'],
    ['LKH', 'Long Akah Airport'],
    ['WLH', 'Walaha Airport'],
    ['CHG', 'Chaoyang Airport'],
    ['UAS', 'Buffalo Spring'],
    ['BHG', 'Brus Laguna Airport'],
    ['YVB', 'Bonaventure Airport'],
    ['SKT', 'Sialkot Airport'],
    ['PDP', 'Capitan Corbeta CA Curbelo International Airport'],
    ['WVB', 'Walvis Bay Airport'],
    ['MPA', 'Katima Mulilo Airport'],
    ['AOE', 'Anadolu Airport'],
    ['CKZ', 'Çanakkale Airport'],
    ['MSR', 'Muş Airport'],
    ['NOP', 'Sinop Airport'],
    ['TEQ', 'Tekirdağ Çorlu Airport'],
    ['YEI', 'Bursa Yenişehir Airport'],
    ['LSS', 'Terre-de-Haut Airport'],
    ['KMV', 'Kalay Airport'],
    ['VQS', 'Vieques Airport'],
    ['YIF', 'St Augustin Airport'],
    ['HDM', 'Hamadan Airport'],
    ['MRQ', 'Marinduque Airport'],
    ['GFN', 'Grafton Airport'],
    ['OAG', 'Orange Airport'],
    ['TRO', 'Taree Airport'],
    ['COQ', 'Choibalsan Airport'],
    ['HOH', 'Hohenems-Dornbirn Airport'],
    ['ESC', 'Delta County Airport'],
    ['YAK', 'Yakutat Airport'],
    ['GUL', 'Goulburn Airport'],
    ['CES', 'Cessnock Airport'],
    ['NSO', 'Scone Airport'],
    ['DGE', 'Mudgee Airport'],
    ['MTL', 'Maitland Airport'],
    ['CPX', 'Benjamin Rivera Noriega Airport'],
    ['MWA', 'Williamson County Regional Airport'],
    ['OCN', 'Oceanside Municipal Airport'],
    ['KIK', 'Kirkuk Air Base'],
    ['XJD', 'Al Udeid Air Base'],
    ['GBZ', 'Great Barrier Aerodrome'],
    ['IMT', 'Ford Airport'],
    ['AET', 'Allakaket Airport'],
    ['MGC', 'Michigan City Municipal Airport'],
    ['SWD', 'Seward Airport'],
    ['GRM', 'Grand Marais Cook County Airport'],
    ['AUW', 'Wausau Downtown Airport'],
    ['MYP', 'Mary Airport'],
    ['MVA', 'Reykjahlíð Airport'],
    ['QSA', 'Sabadell Airport'],
    ['WSY', 'Whitsunday Island Airport'],
    ['MIE', 'Delaware County Johnson Field'],
    ['LAF', 'Purdue University Airport'],
    ['VGT', 'North Las Vegas Airport'],
    ['ENW', 'Kenosha Regional Airport'],
    ['MTJ', 'Montrose Regional Airport'],
    ['RIW', 'Riverton Regional Airport'],
    ['PDT', 'Eastern Oregon Regional At Pendleton Airport'],
    ['LYM', 'Lympne Airport'],
    ['PKH', 'Porto Cheli Airport'],
    ['KTR', 'Tindal Airport'],
    ['NOA', 'Nowra Airport'],
    ['UCK', 'Lutsk Airport'],
    ['CEJ', 'Chernihiv Shestovitsa Airport'],
    ['BQT', 'Brest Airport'],
    ['OSH', 'Wittman Regional Airport'],
    ['AGE', 'Wangerooge Airport'],
    ['BXG', 'Bendigo Airport'],
    ['EAT', 'Pangborn Memorial Airport'],
    ['ARE', 'Antonio Nery Juarbe Pol Airport'],
    ['RIN', 'Ringi Cove Airport'],
    ['KCK', 'Kirensk Airport'],
    ['UKX', 'Ust-Kut Airport'],
    ['RMT', 'Rimatara Airport'],
    ['QLS', 'Lausanne-Blécherette Airport'],
    ['ZJI', 'Locarno Airport'],
    ['QNC', 'Neuchatel Airport'],
    ['TGK', 'Taganrog Yuzhny Airport'],
    ['GDZ', 'Gelendzhik Airport'],
    ['ZIA', 'Zhukovsky International Airport'],
    ['IAR', 'Tunoshna Airport'],
    ['OHE', 'Gu-Lian Airport'],
    ['JNG', 'Jining Qufu Airport'],
    ['DRK', 'Drake Bay Airport'],
    ['AAT', 'Altay Air Base'],
    ['TZL', 'Tuzla International Airport'],
    ['FWH', 'NAS Fort Worth JRB/Carswell Field'],
    ['NYT', 'Naypyidaw Airport'],
    ['VBP', 'Bokpyinn Airport'],
    ['NZH', 'Manzhouli Xijiao Airport'],
    ['WUA', 'Wuhai Airport'],
    ['GYY', 'Gary Chicago International Airport'],
    ['BRD', 'Brainerd Lakes Regional Airport'],
    ['LWB', 'Greenbrier Valley Airport'],
    ['PGV', 'Pitt Greenville Airport'],
    ['CYF', 'Chefornak Airport'],
    ['OXR', 'Oxnard Airport'],
    ['BKG', 'Branson Airport'],
    ['TEN', 'Tongren Fenghuang Airport'],
    ['JGS', 'Jinggangshan Airport'],
    ['NIU', 'Naiu Airport'],
    ['SCH', 'Schenectady County Airport'],
    ['NBC', 'Begishevo Airport'],
    ['QRW', 'Warri Airport'],
    ['IAO', 'Siargao Airport'],
    ['LGO', 'Langeoog Airport'],
    ['NLP', 'Nelspruit Airport'],
    ['CKC', 'Cherkasy International Airport'],
    ['UST', 'Northeast Florida Regional Airport'],
    ['NLV', 'Mykolaiv International Airport'],
    ['RHP', 'Ramechhap Airport'],
    ['STS', 'Charles M. Schulz Sonoma County Airport'],
    ['ISM', 'Kissimmee Gateway Airport'],
    ['LCQ', 'Lake City Gateway Airport'],
    ['LGU', 'Logan-Cache Airport'],
    ['BMC', 'Brigham City Regional Airport'],
    ['ASE', 'Aspen-Pitkin Co/Sardy Field'],
    ['ULV', 'Ulyanovsk Baratayevka Airport'],
    ['ERV', 'Kerrville Municipal Louis Schreiner Field'],
    ['GED', 'Sussex County Airport'],
    ['ZSW', 'Prince Rupert/Seal Cove Seaplane Base'],
    ['GBD', 'Great Bend Municipal Airport'],
    ['HYS', 'Hays Regional Airport'],
    ['SUS', 'Spirit of St Louis Airport'],
    ['LYU', 'Ely Municipal Airport'],
    ['GPZ', 'Grand Rapids Itasca Co-Gordon Newstrom field'],
    ['TVF', 'Thief River Falls Regional Airport'],
    ['EGV', 'Eagle River Union Airport'],
    ['ARV', 'Lakeland-Noble F. Lee Memorial field'],
    ['YBV', 'Berens River Airport'],
    ['AVX', 'Catalina Airport'],
    ['MHV', 'Mojave Airport'],
    ['ZIN', 'Interlaken Air Base'],
    ['INQ', 'Inisheer Aerodrome'],
    ['SWT', 'Strezhevoy Airport'],
    ['HUT', 'Hutchinson Municipal Airport'],
    ['OAI', 'Bagram Air Base'],
    ['AKH', 'Prince Sultan Air Base'],
    ['STJ', 'Rosecrans Memorial Airport'],
    ['VOK', 'Volk Field'],
    ['GUC', 'Gunnison Crested Butte Regional Airport'],
    ['SIA', "Xi'an Xiguan Airport"],
    ['TOA', 'Zamperini Field'],
    ['MBL', 'Manistee Co Blacker Airport'],
    ['PGD', 'Charlotte County Airport'],
    ['WFK', 'Northern Aroostook Regional Airport'],
    ['JHW', 'Chautauqua County-Jamestown Airport'],
    ['YTM', 'La Macaza / Mont-Tremblant International Inc Airport'],
    ['SME', 'Lake Cumberland Regional Airport'],
    ['SHD', 'Shenandoah Valley Regional Airport'],
    ['DVL', 'Devils Lake Regional Airport'],
    ['DIK', 'Dickinson Theodore Roosevelt Regional Airport'],
    ['SDY', 'Sidney - Richland Regional Airport'],
    ['CDR', 'Chadron Municipal Airport'],
    ['AIA', 'Alliance Municipal Airport'],
    ['MCK', 'Mc Cook Ben Nelson Regional Airport'],
    ['MTH', 'The Florida Keys Marathon Airport'],
    ['GDV', 'Dawson Community Airport'],
    ['OLF', 'L M Clayton Airport'],
    ['WYS', 'Yellowstone Airport'],
    ['ALS', 'San Luis Valley Regional Bergman Field'],
    ['CNY', 'Canyonlands Field'],
    ['ELY', 'Ely Airport Yelland Field'],
    ['VEL', 'Vernal Regional Airport'],
    ['RUI', 'Sierra Blanca Regional Airport'],
    ['SOW', 'Show Low Regional Airport'],
    ['MYL', 'McCall Municipal Airport'],
    ['SMN', 'Lemhi County Airport'],
    ['MMH', 'Mammoth Yosemite Airport'],
    ['FRD', 'Friday Harbor Airport'],
    ['ESD', 'Orcas Island Airport'],
    ['AST', 'Astoria Regional Airport'],
    ['ONP', 'Newport Municipal Airport'],
    ['EMK', 'Emmonak Airport'],
    ['UNK', 'Unalakleet Airport'],
    ['UUK', 'Ugnu-Kuparuk Airport'],
    ['SHX', 'Shageluk Airport'],
    ['CHU', 'Chuathbaluk Airport'],
    ['NUI', 'Nuiqsut Airport'],
    ['EEK', 'Eek Airport'],
    ['KUK', 'Kasigluk Airport'],
    ['KWT', 'Kwethluk Airport'],
    ['KWK', 'Kwigillingok Airport'],
    ['MLL', 'Marshall Don Hunter Sr Airport'],
    ['RSH', 'Russian Mission Airport'],
    ['KGK', 'Koliganek Airport'],
    ['KMO', 'Manokotak Airport'],
    ['CIK', 'Chalkyitsik Airport'],
    ['EAA', 'Eagle Airport'],
    ['HUS', 'Hughes Airport'],
    ['HSL', 'Huslia Airport'],
    ['NUL', 'Nulato Airport'],
    ['VEE', 'Venetie Airport'],
    ['WBQ', 'Beaver Airport'],
    ['CEM', 'Central Airport'],
    ['SHG', 'Shungnak Airport'],
    ['IYK', 'Inyokern Airport'],
    ['VIS', 'Visalia Municipal Airport'],
    ['MCE', 'Merced Regional Macready Field'],
    ['CYR', 'Laguna de Los Patos International Airport'],
    ['CPQ', 'Amarais Airport'],
    ['GYR', 'Phoenix Goodyear Airport'],
    ['TWB', 'Toowoomba Airport'],
    ['BBL', 'Ballera Airport'],
    ['AYK', 'Arkalyk North Airport'],
    ['AGN', 'Angoon Seaplane Base'],
    ['ELV', 'Elfin Cove Seaplane Base'],
    ['FNR', 'Funter Bay Seaplane Base'],
    ['HNH', 'Hoonah Airport'],
    ['MTM', 'Metlakatla Seaplane Base'],
    ['HYG', 'Hydaburg Seaplane Base'],
    ['EGX', 'Egegik Airport'],
    ['KPV', 'Perryville Airport'],
    ['PIP', 'Pilot Point Airport'],
    ['WSN', 'South Naknek Nr 2 Airport'],
    ['AKK', 'Akhiok Airport'],
    ['KYK', 'Karluk Airport'],
    ['KLN', 'Larsen Bay Airport'],
    ['ABL', 'Ambler Airport'],
    ['BKC', 'Buckland Airport'],
    ['IAN', 'Bob Baker Memorial Airport'],
    ['OBU', 'Kobuk Airport'],
    ['ORV', 'Robert (Bob) Curtis Memorial Airport'],
    ['WLK', 'Selawik Airport'],
    ['KTS', 'Brevig Mission Airport'],
    ['ELI', 'Elim Airport'],
    ['GLV', 'Golovin Airport'],
    ['TLA', 'Teller Airport'],
    ['WAA', 'Wales Airport'],
    ['WMO', 'White Mountain Airport'],
    ['KKA', 'Koyuk Alfred Adams Airport'],
    ['SMK', 'St Michael Airport'],
    ['SKK', 'Shaktoolik Airport'],
    ['TNC', 'Tin City Long Range Radar Station Airport'],
    ['AKB', 'Atka Airport'],
    ['IKO', 'Nikolski Air Station'],
    ['CYT', 'Yakataga Airport'],
    ['AUK', 'Alakanuk Airport'],
    ['KPN', 'Kipnuk Airport'],
    ['KFP', 'False Pass Airport'],
    ['NLG', 'Nelson Lagoon Airport'],
    ['PML', 'Port Moller Airport'],
    ['KLW', 'Klawock Airport'],
    ['KWN', 'Quinhagak Airport'],
    ['KOT', 'Kotlik Airport'],
    ['KYU', 'Koyukuk Airport'],
    ['SCM', 'Scammon Bay Airport'],
    ['NNL', 'Nondalton Airport'],
    ['KKH', 'Kongiganak Airport'],
    ['NIB', 'Nikolai Airport'],
    ['AKI', 'Akiak Airport'],
    ['AIN', 'Wainwright Airport'],
    ['APZ', 'Zapala Airport'],
    ['RDS', 'Rincon De Los Sauces Airport'],
    ['PNT', 'Tte. Julio Gallardo Airport'],
    ['SGV', 'Sierra Grande Airport'],
    ['IGB', 'Cabo F.A.A. H. R. Bordón Airport'],
    ['NCN', 'Chenega Bay Airport'],
    ['TKJ', 'Tok Junction Airport'],
    ['IRC', 'Circle City /New/ Airport'],
    ['SLQ', 'Sleetmute Airport'],
    ['LMA', 'Minchumina Airport'],
    ['MLY', 'Manley Hot Springs Airport'],
    ['YNP', 'Natuashish Airport'],
    ['YSO', 'Postville Airport'],
    ['YWB', 'Kangiqsujuaq (Wakeham Bay) Airport'],
    ['YTF', 'Alma Airport'],
    ['YGV', 'Havre St Pierre Airport'],
    ['YXK', 'Rimouski Airport'],
    ['XTL', 'Tadoule Lake Airport'],
    ['XLB', 'Lac Brochet Airport'],
    ['XSI', 'South Indian Lake Airport'],
    ['YBT', 'Brochet Airport'],
    ['ZGR', 'Little Grand Rapids Airport'],
    ['YCR', 'Cross Lake (Charlie Sinclair Memorial) Airport'],
    ['YRS', 'Red Sucker Lake Airport'],
    ['YOP', 'Rainbow Lake Airport'],
    ['YBY', 'Bonnyville Airport'],
    ['ZNA', 'Nanaimo Harbour Water Airport'],
    ['YGG', 'Ganges Seaplane Base'],
    ['YDT', 'Boundary Bay Airport'],
    ['YLY', 'Langley Airport'],
    ['YFJ', 'Wekweètì Airport'],
    ['RNI', 'Corn Island'],
    ['BZA', 'San Pedro Airport'],
    ['RFS', 'Rosita Airport'],
    ['SIU', 'Siuna'],
    ['WSP', 'Waspam Airport'],
    ['NCR', 'San Carlos'],
    ['PLD', 'Playa Samara/Carrillo Airport'],
    ['COZ', 'Constanza - Expedición 14 de Junio National Airport'],
    ['NEG', 'Negril Airport'],
    ['NRR', 'José Aponte de la Torre Airport'],
    ['SPB', 'Charlotte Amalie Harbor Seaplane Base'],
    ['ARR', 'D. Casimiro Szlapelis Airport'],
    ['JSM', 'Jose De San Martin Airport'],
    ['UYU', 'Uyuni Airport'],
    ['RBQ', 'Rurenabaque Airport'],
    ['ABF', 'Abaiang Airport'],
    ['ABN', 'Albina Airport'],
    ['DRJ', 'Drietabbetje Airport'],
    ['MOJ', 'Moengo Airstrip'],
    ['ICK', 'Nieuw Nickerie Airport'],
    ['OEM', 'Vincent Fayks Airport'],
    ['SMZ', 'Stoelmanseiland Airport'],
    ['TOT', 'Totness Airport'],
    ['AGI', 'Wageningen Airstrip'],
    ['ORJ', 'Orinduik Airport'],
    ['NAI', 'Annai Airport'],
    ['IMB', 'Imbaimadai Airport'],
    ['KAR', 'Kamarang Airport'],
    ['USI', 'Mabaruma Airport'],
    ['MHA', 'Mahdia Airport'],
    ['PJC', 'Dr Augusto Roberto Fuster International Airport'],
    ['ACD', 'Alcides Fernández Airport'],
    ['RVE', 'Los Colonizadores Airport'],
    ['BQJ', 'Batagay Airport'],
    ['VGZ', 'Villa Garzón Airport'],
    ['EBG', 'El Bagre Airport'],
    ['CAQ', 'Juan H White Airport'],
    ['COG', 'Mandinga Airport'],
    ['TLU', 'Golfo de Morrosquillo Airport'],
    ['CFB', 'Cabo Frio Airport'],
    ['OPS', 'Presidente João Batista Figueiredo Airport'],
    ['GRP', 'Gurupi Airport'],
    ['CMP', 'Santana do Araguaia Airport'],
    ['BVS', 'Breves Airport'],
    ['SFK', 'Soure Airport'],
    ['PIN', 'Parintins Airport'],
    ['BRA', 'Barreiras Airport'],
    ['STZ', 'Santa Terezinha Airport'],
    ['MQH', 'Minaçu Airport'],
    ['AUX', 'Araguaína Airport'],
    ['NVP', 'Novo Aripuanã Airport'],
    ['FRC', 'Tenente Lund Pressoto Airport'],
    ['DOU', 'Dourados Airport'],
    ['LBR', 'Lábrea Airport'],
    ['ROO', 'Maestro Marinho Franco Airport'],
    ['GPB', 'Tancredo Thomas de Faria Airport'],
    ['JCB', 'Santa Terezinha Airport'],
    ['RVD', 'General Leite de Castro Airport'],
    ['AAX', 'Romeu Zema Airport'],
    ['MBZ', 'Maués Airport'],
    ['RBB', 'Borba Airport'],
    ['CIZ', 'Coari Airport'],
    ['BAZ', 'Barcelos Airport'],
    ['DMT', 'Diamantino Airport'],
    ['GNM', 'Guanambi Airport'],
    ['QDJ', 'Tsletsi Airport'],
    ['NZA', 'Nzagi Airport'],
    ['LBZ', 'Lucapa Airport'],
    ['KNP', 'Capanda Airport'],
    ['AMC', 'Am Timan Airport'],
    ['GSQ', 'Shark El Oweinat International Airport'],
    ['MRB', 'Eastern WV Regional Airport/Shepherd Field'],
    ['AWA', 'Awassa Airport'],
    ['JIJ', 'Wilwal International Airport'],
    ['MKS', 'Mekane Selam Airport'],
    ['DBM', 'Debra Marcos Airport'],
    ['DBT', 'Debre Tabor Airport'],
    ['QHR', 'Harar Meda Airport'],
    ['GOB', 'Robe Airport'],
    ['MYB', 'Mayumba Airport'],
    ['MRE', 'Mara Serena Lodge Airstrip'],
    ['JJM', 'Mulika Lodge Airport'],
    ['RBX', 'Rumbek Airport'],
    ['CPA', 'Cape Palmas Airport'],
    ['IHC', 'Inhaca Airport'],
    ['MAX', 'Ouro Sogui Airport'],
    ['BDI', 'Bird Island Airport'],
    ['WHF', 'Wadi Halfa Airport'],
    ['NBE', 'Enfidha - Hammamet International Airport'],
    ['HTY', 'Hatay Airport'],
    ['RVV', 'Raivavae Airport'],
    ['FUO', 'Foshan Shadi Airport'],
    ['HUZ', 'Huizhou Airport'],
    ['ILD', 'Lleida-Alguaire Airport'],
    ['BIU', 'Bildudalur Airport'],
    ['GJR', 'Gjögur Airport'],
    ['SAK', 'Sauðárkrókur Airport'],
    ['IIA', 'Inishmaan Aerodrome'],
    ['ULG', 'Ulgii Mongolei Airport'],
    ['KQT', 'Qurghonteppa International Airport'],
    ['VGD', 'Vologda Airport'],
    ['ONK', 'Olenyok Airport'],
    ['SYS', 'Saskylakh Airport'],
    ['LDG', 'Leshukonskoye Airport'],
    ['HSK', 'Huesca/Pirineos Airport'],
    ['CQM', 'Ciudad Real Central Airport'],
    ['NJF', 'Al Najaf International Airport'],
    ['CSA', 'Colonsay Airstrip'],
    ['RKH', 'Rock Hill - York County Airport'],
    ['AGC', 'Allegheny County Airport'],
    ['VQQ', 'Cecil Airport'],
    ['FTY', 'Fulton County Airport Brown Field'],
    ['TII', 'Tarin Kowt Airport'],
    ['ZAJ', 'Zaranj Airport'],
    ['CCN', 'Chakcharan Airport'],
    ['FUG', 'Fuyang Xiguan Airport'],
    ['LCX', 'Longyan Guanzhishan Airport'],
    ['BSD', 'Baoshan Yunduan Airport'],
    ['ACX', 'Xingyi Airport'],
    ['HZH', 'Liping Airport'],
    ['OSU', 'The Ohio State University Airport - Don Scott Field'],
    ['ADS', 'Addison Airport'],
    ['DSI', 'Destin Executive Airport'],
    ['KHE', 'Kherson International Airport'],
    ['SZS', "Ryan's Creek Aerodrome"],
    ['HJJ', 'Zhijiang Airport'],
    ['YQI', 'Yarmouth Airport'],
    ['ISO', 'Kinston Regional Jetport At Stallings Field'],
    ['FFA', 'First Flight Airport'],
    ['LNJ', 'Lintsang Airfield'],
    ['CKS', 'Carajás Airport'],
    ['MWK', 'Tarempa Airport'],
    ['PGU', 'Persian Gulf International Airport'],
    ['YES', 'Yasouj Airport'],
    ['OSM', 'Mosul International Airport'],
    ['TJH', 'Tajima Airport'],
    ['AXJ', 'Amakusa Airport'],
    ['KKX', 'Kikai Airport'],
    ['AGJ', 'Aguni Airport'],
    ['ULZ', 'Donoi Airport'],
    ['UGA', 'Bulgan Airport'],
    ['ULO', 'Ulaangom Airport'],
    ['LBX', 'Lubang Airport'],
    ['TJU', 'Kulob Airport'],
    ['CMJ', 'Chi Mei Airport'],
    ['TAZ', 'Daşoguz Airport'],
    ['BWB', 'Barrow Island Airport'],
    ['DRB', 'Derby Airport'],
    ['WGE', 'Walgett Airport'],
    ['BRT', 'Bathurst Island Airport'],
    ['DKI', 'Dunk Island Airport'],
    ['LZR', 'Lizard Island Airport'],
    ['HLT', 'Hamilton Airport'],
    ['HCQ', 'Halls Creek Airport'],
    ['FIZ', 'Fitzroy Crossing Airport'],
    ['RVT', 'Ravensthorpe Airport'],
    ['PVU', 'Provo Municipal Airport'],
    ['SBS', 'Steamboat Springs Bob Adams Field'],
    ['DTA', 'Delta Municipal Airport'],
    ['PUC', 'Carbon County Regional/Buck Davis Field'],
    ['LAM', 'Los Alamos Airport'],
    ['HII', 'Lake Havasu City Airport'],
    ['INW', 'Winslow Lindbergh Regional Airport'],
    ['DGL', 'Douglas Municipal Airport'],
    ['MZK', 'Marakei Airport'],
    ['AEA', 'Abemama Atoll Airport'],
    ['AAK', 'Buariki Airport'],
    ['KUC', 'Kuria Airport'],
    ['AIS', 'Arorae Island Airport'],
    ['TMN', 'Tamana Island Airport'],
    ['BEZ', 'Beru Airport'],
    ['NIG', 'Nikunau Airport'],
    ['BBG', 'Butaritari Atoll Airport'],
    ['MTK', 'Makin Island Airport'],
    ['MNK', 'Maiana Airport'],
    ['NON', 'Nonouti Airport'],
    ['TSU', 'Tabiteuea South Airport'],
    ['WTZ', 'Whitianga Airport'],
    ['KTF', 'Takaka Airport'],
    ['AFT', 'Afutara Aerodrome'],
    ['RNA', 'Ulawa Airport'],
    ['CHY', 'Choiseul Bay Airport'],
    ['NNB', 'Santa Ana Airport'],
    ['XYA', 'Yandina Airport'],
    ['BPF', 'Batuna Aerodrome'],
    ['BOW', 'Bartow Municipal Airport'],
    ['FTI', 'Fitiuta Airport'],
    ['LVK', 'Livermore Municipal Airport'],
    ['RMY', 'Mariposa Yosemite Airport'],
    ['GFY', 'Grootfontein Airport'],
    ['NDU', 'Rundu Airport'],
    ['TRM', 'Jacqueline Cochran Regional Airport'],
    ['SMO', 'Santa Monica Municipal Airport'],
    ['UDD', 'Bermuda Dunes Airport'],
    ['SCF', 'Scottsdale Airport'],
    ['OLM', 'Olympia Regional Airport'],
    ['RIL', 'Garfield County Regional Airport'],
    ['SAA', 'Shively Field'],
    ['PDK', 'DeKalb Peachtree Airport'],
    ['BMG', 'Monroe County Airport'],
    ['SUA', 'Witham Field'],
    ['MMU', 'Morristown Municipal Airport'],
    ['APC', 'Napa County Airport'],
    ['SDM', 'Brown Field Municipal Airport'],
    ['VNC', 'Venice Municipal Airport'],
    ['PHK', 'Palm Beach County Glades Airport'],
    ['ECP', 'Northwest Florida Beaches International Airport'],
    ['SBD', 'San Bernardino International Airport'],
    ['VAL', 'Valença Airport'],
    ['CAU', 'Caruaru Airport'],
    ['AWK', 'Wake Island Airfield'],
    ['QNV', 'Aeroclube Airport'],
    ['SQL', 'San Carlos Airport'],
    ['RWI', 'Rocky Mount Wilson Regional Airport'],
    ['SXQ', 'Soldotna Airport'],
    ['SEE', 'Gillespie Field'],
    ['PHA', 'Phan Rang Airport'],
    ['SQH', 'Na-San Airport'],
    ['TKF', 'Truckee Tahoe Airport'],
    ['FRJ', 'Fréjus Airport'],
    ['GEX', 'Geelong Airport'],
    ['LVM', 'Mission Field'],
    ['GMV', 'Monument Valley Airport'],
    ['JRA', 'West 30th St. Heliport'],
    ['LAL', 'Lakeland Linder International Airport'],
    ['SYH', 'Syangboche Airport'],
    ['RBK', 'French Valley Airport'],
    ['FNU', 'Oristano-Fenosu Airport'],
    ['MYQ', 'Mysore Airport'],
    ['MGY', 'Dayton-Wright Brothers Airport'],
    ['FDY', 'Findlay Airport'],
    ['PEA', 'Penneshaw Airport'],
    ['EMP', 'Emporia Municipal Airport'],
    ['HYC', 'Wycombe Air Park'],
    ['BBP', 'Bembridge Airport'],
    ['SPF', 'Black Hills Airport-Clyde Ice Field'],
    ['QYD', 'Oksywie Military Air Base'],
    ['OLV', 'Olive Branch Airport'],
    ['KNA', 'Viña del mar Airport'],
    ['ONQ', 'Zonguldak Airport'],
    ['BJC', 'Rocky Mountain Metropolitan Airport'],
    ['SLE', 'Salem Municipal Airport/McNary Field'],
    ['UTM', 'Tunica Municipal Airport'],
    ['ZKB', 'Kasaba Bay Airport'],
    ['LND', 'Hunt Field'],
    ['MWC', 'Lawrence J Timmerman Airport'],
    ['JVL', 'Southern Wisconsin Regional Airport'],
    ['LZU', 'Gwinnett County Briscoe Field'],
    ['BWG', 'Bowling Green Warren County Regional Airport'],
    ['RVS', 'Richard Lloyd Jones Jr Airport'],
    ['NHD', 'Al Minhad Air Base'],
    ['KGO', 'Kirovograd Airport'],
    ['DBB', 'El Alamein International Airport'],
    ['BCE', 'Bryce Canyon Airport'],
    ['CKL', 'Chkalovskiy Air Base'],
    ['TCZ', 'Tengchong Tuofeng Airport'],
    ['UKS', 'Belbek Airport'],
    ['OAZ', 'Camp Bastion Airport'],
    ['JCI', 'New Century Aircenter Airport'],
    ['ESN', 'Easton Newnam Field'],
    ['HMR', 'Stafsberg Airport'],
    ['MYV', 'Yuba County Airport'],
    ['DUC', 'Halliburton Field'],
    ['UVA', 'Garner Field'],
    ['LOT', 'Lewis University Airport'],
    ['CCR', 'Buchanan Field'],
    ['OCA', 'Ocean Reef Club Airport'],
    ['YUS', 'Yushu Batang Airport'],
    ['HIA', 'Lianshui Airport'],
    ['YOO', 'Toronto/Oshawa Executive Airport'],
    ['LHA', 'Lahr Airport'],
    ['NYW', 'Monywar Airport'],
    ['ATO', 'Ohio University Snyder Field'],
    ['SGH', 'Springfield-Beckley Municipal Airport'],
    ['HEX', 'Herrera Airport'],
    ['CDA', 'Cooinda Airport'],
    ['JAB', 'Jabiru Airport'],
    ['HGS', 'Hastings Airport'],
    ['TOP', 'Philip Billard Municipal Airport'],
    ['MQY', 'Smyrna Airport'],
    ['UOS', 'Franklin County Airport'],
    ['NGQ', 'Ngari Gunsa Airport'],
    ['CSO', 'Cochstedt Airport'],
    ['PWK', 'Chicago Executive Airport'],
    ['KLS', 'Southwest Washington Regional Airport'],
    ['ZTA', 'Tureia Airport'],
    ['PUE', 'Puerto Obaldia Airport'],
    ['KHC', 'Kerch Airport'],
    ['UKA', 'Ukunda Airstrip'],
    ['ILN', 'Wilmington Airpark'],
    ['AVW', 'Marana Regional Airport'],
    ['CGZ', 'Casa Grande Municipal Airport'],
    ['BXK', 'Buckeye Municipal Airport'],
    ['MMI', 'McMinn County Airport'],
    ['STK', 'Sterling Municipal Airport'],
    ['RWL', 'Rawlins Municipal Airport/Harvey Field'],
    ['CDW', 'Essex County Airport'],
    ['AIZ', 'Lee C Fine Memorial Airport'],
    ['TVI', 'Thomasville Regional Airport'],
    ['HSH', 'Henderson Executive Airport'],
    ['GML', 'Gostomel Airport'],
    ['TMA', 'Henry Tift Myers Airport'],
    ['RDO', 'Radom Airport'],
    ['DVT', 'Phoenix Deer Valley Airport'],
    ['YRV', 'Revelstoke Airport'],
    ['FRG', 'Republic Airport'],
    ['ZHY', 'Zhongwei Shapotou Airport'],
    ['MCL', 'McKinley National Park Airport'],
    ['PPC', 'Prospect Creek Airport'],
    ['KHW', 'Khwai River Lodge Airport'],
    ['TXG', 'Taichung Airport'],
    ['HLG', 'Wheeling Ohio County Airport'],
    ['XYE', 'Ye Airport'],
    ['DWC', 'Al Maktoum International Airport'],
    ['RKP', 'Aransas County Airport'],
    ['MVV', 'Megève Airport'],
    ['MFX', 'Méribel Altiport'],
    ['AEB', 'Baise Youjiang Airport'],
    ['OKF', 'Okaukuejo Airport'],
    ['OKU', 'Mokuti Lodge Airport'],
    ['KOQ', 'Köthen Airport'],
    ['PSH', 'St. Peter-Ording Airport'],
    ['TTD', 'Portland Troutdale Airport'],
    ['HIO', 'Portland Hillsboro Airport'],
    ['KHT', 'Khost Airport'],
    ['NMT', 'Namtu Airport'],
    ['BNO', 'Burns Municipal Airport'],
    ['PRZ', 'Prineville Airport'],
    ['RBL', 'Red Bluff Municipal Airport'],
    ['NOT', 'Marin County Airport - Gnoss Field'],
    ['LKV', 'Lake County Airport'],
    ['OTK', 'Tillamook Airport'],
    ['ONO', 'Ontario Municipal Airport'],
    ['DLS', 'Columbia Gorge Regional the Dalles Municipal Airport'],
    ['GAI', 'Montgomery County Airpark'],
    ['OAS', 'Sharana Airstrip'],
    ['YTA', 'Pembroke Airport'],
    ['TSB', 'Tsumeb Airport'],
    ['YSD', 'Suffield Heliport'],
    ['BNU', 'Blumenau Airport'],
    ['YCC', 'Cornwall Regional Airport'],
    ['IZA', 'Zona da Mata Regional Airport'],
    ['MVL', 'Morrisville Stowe State Airport'],
    ['RBD', 'Dallas Executive Airport'],
    ['BXY', 'Krainiy Airport'],
    ['WST', 'Westerly State Airport'],
    ['BID', 'Block Island State Airport'],
    ['NME', 'Nightmute Airport'],
    ['OOK', 'Toksook Bay Airport'],
    ['OBY', 'Ittoqqortoormiit Heliport'],
    ['VIN', 'Vinnytsia/Gavyryshivka Airport'],
    ['BGE', 'Decatur County Industrial Air Park'],
    ['ZGS', 'La Romaine Airport'],
    ['ZKG', 'Kegaska Airport'],
    ['YBI', 'Black Tickle Airport'],
    ['WHP', 'Whiteman Airport'],
    ['MAE', 'Madera Municipal Airport'],
    ['YZZ', 'Trail Airport'],
    ['YAB', 'Old Arctic Bay Airport'],
    ['GSI', 'Grand-Santi Airport'],
    ['MPY', 'Maripasoula Airport'],
    ['LDX', 'Saint-Laurent-du-Maroni Airport'],
    ['KJI', 'Kanas Airport'],
    ['CPB', 'Capurganá Airport'],
    ['HMB', 'Sohag International Airport'],
    ['RVY', 'Presidente General Don Oscar D. Gestido International Airport'],
    ['POJ', 'Patos de Minas Airport'],
    ['JTC', 'Bauru - Arealva Airport'],
    ['OIA', 'Ourilândia do Norte Airport'],
    ['RDC', 'Redenção Airport'],
    ['SXX', 'São Félix do Xingu Airport'],
    ['BYO', 'Bonito Airport'],
    ['SXO', 'São Félix do Araguaia Airport'],
    ['CFC', 'Caçador Airport'],
    ['CAF', 'Carauari Airport'],
    ['ERN', 'Eirunepé Airport'],
    ['CCI', 'Concórdia Airport'],
    ['FBE', 'Francisco Beltrão Airport'],
    ['CFO', 'Confresa Airport'],
    ['AAF', 'Apalachicola Regional Airport'],
    ['UMU', 'Umuarama Airport'],
    ['DTI', 'Diamantina Airport'],
    ['FBA', 'Fonte Boa Airport'],
    ['OLC', 'Senadora Eunice Micheles Airport'],
    ['HUW', 'Humaitá Airport'],
    ['IRZ', 'Tapuruquara Airport'],
    ['ORX', 'Oriximiná Airport'],
    ['UNA', 'Hotel Transamérica Airport'],
    ['TEF', 'Telfer Airport'],
    ['GZP', 'Gazipaşa Airport'],
    ['OAA', 'Shank Air Base'],
    ['FPR', 'St Lucie County International Airport'],
    ['PYM', 'Plymouth Municipal Airport'],
    ['NCO', 'Quonset State Airport'],
    ['OWD', 'Norwood Memorial Airport'],
    ['BAF', 'Westfield-Barnes Regional Airport'],
    ['MGJ', 'Orange County Airport'],
    ['HAR', 'Capital City Airport'],
    ['DXR', 'Danbury Municipal Airport'],
    ['ASH', 'Boire Field'],
    ['LWM', 'Lawrence Municipal Airport'],
    ['OXC', 'Waterbury Oxford Airport'],
    ['RMG', 'Richard B Russell Airport'],
    ['GAD', 'Northeast Alabama Regional Airport'],
    ['WDR', 'Barrow County Airport'],
    ['DNN', 'Dalton Municipal Airport'],
    ['LGC', 'LaGrange Callaway Airport'],
    ['PIM', 'Harris County Airport'],
    ['GVL', 'Lee Gilmer Memorial Airport'],
    ['PHD', 'Harry Clever Field'],
    ['HHH', 'Hilton Head Airport'],
    ['DNL', 'Daniel Field'],
    ['MRN', 'Foothills Regional Airport'],
    ['PVL', 'Pike County-Hatcher Field'],
    ['TOC', 'Toccoa Airport - R.G. Letourneau Field'],
    ['PLV', 'Suprunovka Airport'],
    ['WUU', 'Wau Airport'],
    ['HUE', 'Humera Airport'],
    ['OYL', 'Moyale Airport'],
    ['OZG', 'Zagora Airport'],
    ['WYE', 'Yengema Airport'],
    ['GBK', 'Gbangbatok Airport'],
    ['THX', 'Turukhansk Airport'],
    ['TGP', 'Podkamennaya Tunguska Airport'],
    ['AFW', 'Fort Worth Alliance Airport'],
    ['RMK', 'Renmark Airport'],
    ['LGH', 'Leigh Creek Airport'],
    ['RTS', 'Rottnest Island Airport'],
    ['FOS', 'Forrest Airport'],
    ['KEW', 'Keewaywin Airport'],
    ['YSP', 'Marathon Airport'],
    ['YHF', 'Hearst René Fontaine Municipal Airport'],
    ['YHN', 'Hornepayne Municipal Airport'],
    ['YKX', 'Kirkland Lake Airport'],
    ['YMG', 'Manitouwadge Airport'],
    ['YXZ', 'Wawa Airport'],
    ['YEM', 'Manitoulin East Municipal Airport'],
    ['LWC', 'Lawrence Municipal Airport'],
    ['PPM', 'Pompano Beach Airpark'],
    ['XMC', 'Mallacoota Airport'],
    ['ULH', 'Majeed Bin Abdulaziz Airport'],
    ['YUE', 'Yuendumu Airport'],
    ['LOP', 'Lombok International Airport'],
    ['ZMH', 'South Cariboo Region / 108 Mile Airport'],
    ['HDG', 'Handan Airport'],
    ['LOZ', 'London-Corbin Airport/Magee Field'],
    ['FBG', 'Simmons Army Air Field'],
    ['WMI', 'Modlin Airport'],
    ['JXA', 'Jixi Xingkaihu Airport'],
    ['JDG', 'Jeongseok Airport'],
    ['YGM', 'Gimli Industrial Park Airport'],
    ['EYK', 'Beloyarskiy Airport'],
    ['RAC', 'John H Batten Airport'],
    ['RZP', 'Cesar Lim Rodriguez Airport'],
    ['RKZ', 'Shigatse Air Base'],
    ['TIW', 'Tacoma Narrows Airport'],
    ['GUF', 'Jack Edwards Airport'],
    ['IBB', 'General Villamil Airport'],
    ['HMJ', 'Khmelnytskyi Airport'],
    ['HIW', 'Hiroshimanishi Airport'],
    ['KYI', 'Yalata Mission Airport'],
    ['HZL', 'Hazleton Municipal Airport'],
    ['CBE', 'Greater Cumberland Regional Airport'],
    ['WYN', 'Wyndham Airport'],
    ['YBO', 'Bob Quinn Lake Airport'],
    ['KLF', 'Grabtsevo Airport'],
    ['LNR', 'Tri-County Regional Airport'],
    ['JOT', 'Joliet Regional Airport'],
    ['VYS', 'Illinois Valley Regional Airport-Walter A Duncan Field'],
    ['JXN', 'Jackson County Reynolds Field'],
    ['BBX', 'Wings Field'],
    ['OBE', 'Okeechobee County Airport'],
    ['SEF', 'Sebring Regional Airport'],
    ['AVO', 'Avon Park Executive Airport'],
    ['GIF', 'Winter Haven Regional Airport - Gilbert Field'],
    ['ZPH', 'Zephyrhills Municipal Airport'],
    ['OCF', 'Ocala International Airport - Jim Taylor Field'],
    ['AIK', 'Aiken Regional Airport'],
    ['CDN', 'Woodward Field'],
    ['LBT', 'Lumberton Regional Airport'],
    ['SOP', 'Moore County Airport'],
    ['SVH', 'Statesville Regional Airport'],
    ['LHV', 'William T. Piper Memorial Airport'],
    ['BKL', 'Burke Lakefront Airport'],
    ['DKK', 'Chautauqua County-Dunkirk Airport'],
    ['LLY', 'South Jersey Regional Airport'],
    ['LDJ', 'Linden Airport'],
    ['ANQ', 'Tri State Steuben County Airport'],
    ['CLW', 'Clearwater Air Park'],
    ['CGX', 'Chicago Meigs Airport'],
    ['CRE', 'Grand Strand Airport'],
    ['BXO', 'Buochs Airport'],
    ['WBW', 'Wilkes Barre Wyoming Valley Airport'],
    ['LNN', 'Willoughby Lost Nation Municipal Airport'],
    ['UMD', 'Uummannaq Heliport'],
    ['RLK', 'Bayannur Tianjitai Airport'],
    ['FFT', 'Capital City Airport'],
    ['LEW', 'Auburn Lewiston Municipal Airport'],
    ['MRK', 'Marco Island Executive Airport'],
    ['DRE', 'Drummond Island Airport'],
    ['GDW', 'Gladwin Zettel Memorial Airport'],
    ['MFI', 'Marshfield Municipal Airport'],
    ['ISW', 'Alexander Field South Wood County Airport'],
    ['CWI', 'Clinton Municipal Airport'],
    ['BVY', 'Beverly Municipal Airport'],
    ['OSF', 'Ostafyevo International Airport'],
    ['YRQ', 'Trois-Rivières Airport'],
    ['POF', 'Poplar Bluff Municipal Airport'],
    ['EOK', 'Keokuk Municipal Airport'],
    ['PSL', 'Perth/Scone Airport'],
    ['STP', 'St Paul Downtown Holman Field'],
    ['SOO', 'Söderhamn Airport'],
    ['VNA', 'Saravane Airport'],
    ['DKS', 'Dikson Airport'],
    ['BYT', 'Bantry Aerodrome'],
    ['ADY', 'Alldays Airport'],
    ['HAO', 'Butler Co Regional Airport - Hogan Field'],
    ['GAS', 'Garissa Airport'],
    ['HOA', 'Hola Airport'],
    ['KEY', 'Kericho Airport'],
    ['ILU', 'Kilaguni Airport'],
    ['ATJ', 'Antsirabe Airport'],
    ['OVA', 'Bekily Airport'],
    ['UTS', 'Ust-Tsylma Airport'],
    ['RGK', 'Gorno-Altaysk Airport'],
    ['FLD', 'Fond du Lac County Airport'],
    ['STE', 'Stevens Point Municipal Airport'],
    ['MQJ', 'Moma Airport'],
    ['PEF', 'Peenemünde Airport'],
    ['CJN', 'Nusawiru Airport'],
    ['GQQ', 'Galion Municipal Airport'],
    ['TPN', 'Tiputini Airport'],
    ['PTZ', 'Rio Amazonas Airport'],
    ['CKV', 'Clarksville–Montgomery County Regional Airport'],
    ['LPC', 'Lompoc Airport'],
    ['CTH', 'Chester County G O Carlson Airport'],
    ['BST', 'Bost Airport'],
    ['LLK', 'Lankaran International Airport'],
    ['GBB', 'Gabala International Airport'],
    ['ZTU', 'Zaqatala International Airport'],
    ['LKP', 'Lake Placid Airport'],
    ['KDY', 'Typliy Klyuch Airport'],
    ['GYG', 'Magan Airport'],
    ['JIQ', 'Qianjiang Wulingshan Airport'],
    ['AOH', 'Lima Allen County Airport'],
    ['DSO', 'Sondok Airport'],
    ['SSI', 'Malcolm McKinnon Airport'],
    ['BFP', 'Beaver County Airport'],
    ['GGE', 'Georgetown County Airport'],
    ['HDI', 'Hardwick Field'],
    ['RNT', 'Renton Municipal Airport'],
    ['POC', 'Brackett Field'],
    ['CTY', 'Cross City Airport'],
    ['CEU', 'Oconee County Regional Airport'],
    ['BEC', 'Beech Factory Airport'],
    ['QFO', 'Duxford Aerodrome'],
    ['SNY', 'Sidney Municipal-Lloyd W Carr Field'],
    ['GKL', 'Great Keppel Is Airport'],
    ['RPB', 'Roper Bar Airport'],
    ['IFL', 'Innisfail Airport'],
    ['JRF', 'Kalaeloa Airport'],
    ['BIN', 'Bamiyan Airport'],
    ['NBS', 'Changbaishan Airport'],
    ['RGO', 'Orang Airport'],
    ['MOO', 'Moomba Airport'],
    ['LUZ', 'Lublin Airport'],
    ['ECA', 'Iosco County Airport'],
    ['VAM', 'Villa Airport'],
    ['LLF', 'Lingling Airport'],
    ['LSZ', 'Lošinj Island Airport'],
    ['ONS', 'Onslow Airport'],
    ['TDR', 'Theodore Airport'],
    ['WBU', 'Boulder Municipal Airport'],
    ['BBJ', 'Bitburg Airport'],
    ['PAO', 'Palo Alto Airport of Santa Clara County'],
    ['USR', 'Ust-Nera Airport'],
    ['MSC', 'Falcon Field'],
    ['YTY', 'Yangzhou Taizhou Airport'],
    ['PTK', 'Oakland County International Airport'],
    ['KSI', 'Kissidougou Airport'],
    ['EEN', 'Dillant Hopkins Airport'],
    ['THQ', 'Tianshui Maijishan Airport'],
    ['VRO', 'Kawama Airport'],
    ['GKK', 'Kooddoo Airport'],
    ['RCS', 'Rochester Airport'],
    ['RHD', 'Termas de Río Hondo international Airport'],
    ['KMP', 'Keetmanshoop Airport'],
    ['KGT', 'Kangding Airport'],
    ['VUS', 'Velikiy Ustyug Airport'],
    ['IOW', 'Iowa City Municipal Airport'],
    ['TLQ', 'Turpan Jiaohe Airport'],
    ['ANP', 'Lee Airport'],
    ['FXO', 'Cuamba Airport'],
    ['ODO', 'Bodaybo Airport'],
    ['ZTR', 'Zhytomyr Airport'],
    ['HRI', 'Mattala Rajapaksa International Airport'],
    ['PEQ', 'Pecos Municipal Airport'],
    ['HBG', 'Hattiesburg Bobby L Chain Municipal Airport'],
    ['QCJ', 'Botucatu - Tancredo de Almeida Neves Airport'],
    ['QSC', 'Mário Pereira Lopes–São Carlos Airport'],
    ['YKN', 'Chan Gurney Municipal Airport'],
    ['XSB', 'Sir Bani Yas Airport'],
    ['ZBM', 'Bromont (Roland Desourdy) Airport'],
    ['KTI', 'Kratie Airport'],
    ['GYU', 'Guyuan Liupanshan Airport'],
    ['CNI', 'Changhai Airport'],
    ['KRH', 'Redhill Aerodrome'],
    ['JGD', 'Jiagedaqi Airport'],
    ['CCL', 'Chinchilla Airport'],
    ['HWD', 'Hayward Executive Airport'],
    ['MZP', 'Motueka Airport'],
    ['JHQ', 'Shute Harbour Airport'],
    ['ARB', 'Ann Arbor Municipal Airport'],
    ['SHT', 'Shepparton Airport'],
    ['TEM', 'Temora Airport'],
    ['GAH', 'Gayndah Airport'],
    ['WIO', 'Wilcannia Airport'],
    ['BFJ', 'Bijie Feixiong Airport'],
    ['ULK', 'Lensk Airport'],
    ['KVR', 'Kavalerovo Airport'],
    ['IGD', 'Iğdır Airport'],
    ['GNY', 'Şanlıurfa GAP Airport'],
    ['KZR', 'Zafer Airport'],
    ['VLU', 'Velikiye Luki Airport'],
    ['BEO', 'Lake Macquarie Airport'],
    ['BMP', 'Brampton Island Airport'],
    ['NGZ', 'Alameda Naval Air Station'],
    ['YCN', 'Cochrane Airport'],
    ['BJP', 'Estadual Arthur Siqueira Airport'],
    ['BQB', 'Busselton Regional Airport'],
    ['SEK', 'Srednekolymsk Airport'],
    ['IVR', 'Inverell Airport'],
    ['GLI', 'Glen Innes Airport'],
    ['IMM', 'Immokalee Regional Airport'],
    ['TQQ', 'Maranggo Airport'],
    ['YIC', 'Yichun Mingyueshan Airport'],
    ['PTB', 'Dinwiddie County Airport'],
    ['SBM', 'Sheboygan County Memorial Airport'],
    ['KFE', 'Fortescue - Dave Forrest Aerodrome'],
    ['BJU', 'Bajura Airport'],
    ['MZJ', 'Pinal Airpark'],
    ['SAD', 'Safford Regional Airport'],
    ['SLJ', 'Solomon Airport'],
    ['KJP', 'Kerama Airport'],
    ['EKB', 'Ekibastuz Airport'],
    ['SIK', 'Sikeston Memorial Municipal Airport'],
    ['TTI', 'Tetiaroa Airport'],
    ['GFL', 'Floyd Bennett Memorial Airport'],
    ['MTN', 'Martin State Airport'],
    ['FRY', 'Eastern Slopes Regional Airport'],
    ['NEW', 'Lakefront Airport'],
    ['COE', "Coeur D'Alene - Pappy Boyington Field"],
    ['BMT', 'Beaumont Municipal Airport'],
    ['DNV', 'Vermilion Regional Airport'],
    ['COJ', 'Coonabarabran Airport'],
    ['TIX', 'Space Coast Regional Airport'],
    ['BZH', 'Bumi Airport'],
    ['UAR', 'Bouarfa Airport'],
    ['NYE', 'Nyeri Airport'],
    ['AAP', 'Andrau Airpark'],
    ['FCM', 'Flying Cloud Airport'],
    ['LIX', 'Likoma Island Airport'],
    ['OJC', 'Johnson County Executive Airport'],
    ['GIU', 'Sigiriya Air Force Base'],
    ['EUM', 'Neumünster Airport'],
    ['TKT', 'Tak Airport'],
    ['YLK', 'Barrie-Orillia (Lake Simcoe Regional Airport)'],
    ['YCM', 'Niagara District Airport'],
    ['YPD', 'Parry Sound Area Municipal Airport'],
    ['MNZ', 'Manassas Regional Airport/Harry P. Davis Field'],
    ['LJN', 'Texas Gulf Coast Regional Airport'],
    ['BGG', 'Bingöl Çeltiksuyu Airport'],
    ['KFS', 'Kastamonu Airport'],
    ['LLV', 'Lüliang Airport'],
    ['DCY', 'Daocheng Yading Airport'],
    ['GXH', 'Gannan Xiahe Airport'],
    ['CIY', 'Comiso Airport'],
    ['KVM', 'Markovo Airport'],
    ['ZKP', 'Zyryanka Airport'],
    ['UMS', 'Ust-Maya Airport'],
    ['ADH', 'Aldan Airport'],
    ['OLZ', 'Olyokminsk Airport'],
    ['NLT', 'Xinyuan Nalati Airport'],
    ['PTA', 'Port Alsworth Airport'],
    ['BOR', 'Fontaine Airport'],
    ['OBC', 'Obock Airport'],
    ['TDJ', 'Tadjoura Airport'],
    ['AQB', 'Santa Cruz del Quiche Airport'],
    ['NOR', 'Norðfjörður Airport'],
    ['BTZ', 'Bursa Airport'],
    ['WAR', 'Waris Airport'],
    ['EWK', 'Newton City-County Airport'],
    ['BSJ', 'Bairnsdale Airport'],
    ['TZR', 'Taszár Air Base'],
    ['FBR', 'Fort Bridger Airport'],
    ['CLS', 'Chehalis Centralia Airport'],
    ['EVW', 'Evanston-Uinta County Airport-Burns Field'],
    ['EUF', 'Weedon Field'],
    ['MEO', 'Dare County Regional Airport'],
    ['AUO', 'Auburn University Regional Airport'],
    ['DBN', "W H 'Bud' Barron Airport"],
    ['PUK', 'Pukarua Airport'],
    ['CVO', 'Corvallis Municipal Airport'],
    ['PXH', 'Prominent Hill Airport'],
    ['CWT', 'Cowra Airport'],
    ['OGD', 'Ogden Hinckley Airport'],
    ['AKO', 'Colorado Plains Regional Airport'],
    ['SHN', 'Sanderson Field'],
    ['WNA', 'Napakiak Airport'],
    ['PKA', 'Napaskiak Airport'],
    ['YBW', 'Bedwell Harbour Seaplane Base'],
    ['WSO', 'Washabo Airport'],
    ['WKR', 'Abaco I Walker C Airport'],
    ['GFO', 'Bartica A Airport'],
    ['DYL', 'Doylestown Airport'],
    ['TGI', 'Tingo Maria Airport'],
    ['TJL', 'Plínio Alarcom Airport'],
    ['YZY', 'Zhangye Ganzhou Airport'],
    ['OAL', 'Cacoal Airport'],
    ['OCW', 'Warren Field'],
    ['MHC', 'Mocopulli Airport'],
    ['SWO', 'Stillwater Regional Airport'],
    ['OKM', 'Okmulgee Regional Airport'],
    ['CUH', 'Cushing Municipal Airport'],
    ['CSM', 'Clinton Sherman Airport'],
    ['WLD', 'Strother Field'],
    ['PWA', 'Wiley Post Airport'],
    ['DTN', 'Shreveport Downtown Airport'],
    ['SEP', 'Stephenville Clark Regional Airport'],
    ['ADT', 'Ada Regional Airport'],
    ['IRB', 'Iraan Municipal Airport'],
    ['YEL', 'Elliot Lake Municipal Airport'],
    ['IKB', 'Wilkes County Airport'],
    ['DAN', 'Danville Regional Airport'],
    ['ERG', 'Yerbogachen Airport'],
    ['HCW', 'Cheraw Municipal Airport/Lynch Bellinger Field'],
    ['BEM', 'Beni Mellal Airport'],
    ['NKT', 'Şırnak Şerafettin Elçi Airport'],
    ['SUY', 'Suntar Airport'],
    ['OUZ', 'Tazadit Airport'],
    ['ABB', 'Asaba International Airport'],
    ['QUO', 'Akwa Ibom International Airport'],
    ['KAA', 'Kasama Airport'],
    ['SGX', 'Songea Airport'],
    ['JUH', 'Jiuhuashan Airport'],
    ['AOG', 'Anshan Air Base'],
    ['DQA', 'Saertu Airport'],
    ['ZYI', 'Zunyi Xinzhou Airport'],
    ['LDS', 'Lindu Airport'],
    ['AVA', 'Anshun Huangguoshu Airport'],
    ['KSS', 'Sikasso Airport'],
    ['WTB', 'Toowoomba Wellcamp Airport'],
    ['TNH', 'Tonghua Sanyuanpu Airport'],
    ['SZV', 'Suzhou Guangfu Airport'],
    ['LII', 'Mulia Airport'],
    ['NTI', 'Stenkol Airport'],
    ['WSR', 'Wasior Airport'],
    ['DTB', 'Silangit Airport'],
    ['MEQ', 'Seunagan Airport'],
    ['BUW', 'Betoambari Airport'],
    ['KAZ', 'Kao Airport'],
    ['MNA', 'Melangguane Airport'],
    ['SGQ', 'Sanggata/Sangkimah Airport'],
    ['BUU', 'Muara Bungo Airport'],
    ['ILA', 'Illaga Airport'],
    ['OKL', 'Oksibil Airport'],
    ['KOX', 'Kokonau Airport'],
    ['CMQ', 'Clermont Airport'],
    ['WMB', 'Warrnambool Airport'],
    ['RCM', 'Richmond Airport'],
    ['DCN', 'RAAF Base Curtin'],
    ['KNO', 'Kualanamu International Airport'],
    ['AMN', 'Gratiot Community Airport'],
    ['HMY', 'Seosan Air Base'],
    ['EMT', 'San Gabriel Valley Airport'],
    ['FAH', 'Farah Airport'],
    ['IXT', 'Pasighat Airport'],
    ['KRQ', 'Kramatorsk Airport'],
    ['QKX', 'Kautokeino Air Base'],
    ['SSF', 'Stinson Municipal Airport'],
    ['JAS', 'Jasper County Airport-Bell Field'],
    ['MRF', 'Marfa Municipal Airport'],
    ['ALE', 'Alpine Casparis Municipal Airport'],
    ['BQE', 'Bubaque Airport'],
    ['CZA', 'Chichen Itza International Airport'],
    ['BUY', 'Bunbury Airport'],
    ['CCB', 'Cable Airport'],
    ['EKI', 'Elkhart Municipal Airport'],
    ['CUB', 'Jim Hamilton L.B. Owens Airport'],
    ['GDC', 'Donaldson Field Airport'],
    ['HVS', 'Hartsville Regional Airport'],
    ['SZT', 'San Cristobal de las Casas Airport'],
    ['DU9', 'Dunnville Airport'],
    ['RIH', 'Scarlett Martinez International Airport'],
    ['LEE', 'Leesburg International Airport'],
    ['PPY', 'Pouso Alegre Airport'],
    ['DIQ', 'Brigadeiro Cabral Airport'],
    ['EIK', 'Yeysk Airport'],
    ['ERD', 'Berdyansk Airport'],
    ['BWX', 'Blimbingsari Airport'],
    ['ERL', 'Erenhot Saiwusu International Airport'],
    ['CNO', 'Chino Airport'],
    ['HTR', 'Hateruma Airport'],
    ['BWW', 'Las Brujas Airport'],
    ['PRB', 'Paso Robles Municipal Airport'],
    ['PKX', 'Beijing Daxing International Airport'],
    ['HAF', 'Half Moon Bay Airport'],
    ['HCJ', 'Hechi Jinchengjiang Airport'],
    ['WJF', 'General WM J Fox Airfield'],
    ['CJF', 'Coondewanna Airport'],
    ['GUZ', 'Guarapari Airport'],
    ['UBT', 'Ubatuba Airport'],
    ['BOX', 'Borroloola Airport'],
    ['QUG', 'Chichester/Goodwood Airport'],
    ['TNW', 'Jumandy Airport'],
    ['FYJ', 'Dongji Aiport'],
    ['PZL', 'Zulu Inyala Airport'],
    ['LPF', 'Liupanshui Yuezhao Airport'],
    ['KJH', 'Kaili Airport'],
    ['HPG', 'Shennongjia Hongping Airport'],
    ['ZQZ', 'Zhangjiakou Ningyuan Airport'],
    ['YIE', "Arxan Yi'ershi Airport"],
    ['HNY', 'Hengyang Nanyue Airport'],
    ['AHJ', 'Hongyuan Airport'],
    ['WOS', 'Wonsan Kalma International Airport'],
    ['IGT', 'Magas Airport'],
    ['ASN', 'Talladega Municipal Airport'],
    ['GMU', 'Greenville Downtown Airport'],
    ['NGD', 'Captain Auguste George Airport'],
    ['TOI', 'Troy Municipal Airport at N Kenneth Campbell Field'],
    ['ETS', 'Enterprise Municipal Airport'],
    ['EFG', 'Efogi Airport'],
    ['KGW', 'Kagi Airport'],
    ['NDN', 'Nadunumu Airport'],
    ['BNM', 'Bodinumu Airport'],
    ['ALX', 'Thomas C Russell Field'],
    ['PKT', 'Port Keats Airport'],
    ['GPN', 'Garden Point Airport'],
    ['DOH', 'Hamad International Airport'],
    ['HZP', 'Fort Mackay / Horizon Airport'],
    ['HDE', 'Brewster Field'],
    ['PTT', 'Pratt Regional Airport'],
    ['LXN', 'Jim Kelly Field'],
    ['CBF', 'Council Bluffs Municipal Airport'],
    ['OKK', 'Kokomo Municipal Airport'],
    ['GBG', 'Galesburg Municipal Airport'],
    ['GUY', 'Guymon Municipal Airport'],
    ['IDP', 'Independence Municipal Airport'],
    ['BBC', 'Bay City Municipal Airport'],
    ['PRX', 'Cox Field'],
    ['CFV', 'Coffeyville Municipal Airport'],
    ['GXY', 'Greeley–Weld County Airport'],
    ['OEL', 'Oryol Yuzhny Airport'],
    ['FET', 'Fremont Municipal Airport'],
    ['LGD', 'La Grande/Union County Airport'],
    ['SZY', 'Olsztyn-Mazury Airport'],
    ['MPO', 'Pocono Mountains Municipal Airport'],
    ['UKT', 'Quakertown Airport'],
    ['YBA', 'Banff Airport'],
    ['BNG', 'Banning Municipal Airport'],
    ['OFK', 'Karl Stefan Memorial Airport'],
    ['TFL', 'Juscelino Kubitscheck Airport'],
    ['TPF', 'Peter O Knight Airport'],
    ['BZC', 'Umberto Modiano Airport'],
    ['ITP', 'Itaperuna Airport'],
    ['REZ', 'Resende Airport'],
    ['KBN', 'Tunta Airport'],
    ['IKL', 'Ikela Airport'],
    ['AIR', 'Aripuanã Airport'],
    ['JRN', 'Juruena Airport'],
    ['JIA', 'Juína Airport'],
    ['VLP', 'Vila Rica Airport'],
    ['JUA', 'Inácio Luís do Nascimento Airport'],
    ['CCX', 'Cáceres Airport'],
    ['TGQ', 'Tangará da Serra Airport'],
    ['CQA', 'Canarana Airport'],
    ['MTG', 'Vila Bela da Santíssima Trindade Airport'],
    ['BMB', 'Bumbar Airport'],
    ['APQ', 'Arapiraca Airport'],
    ['FLB', 'Cangapara Airport'],
    ['PCS', 'Picos Airport'],
    ['BNC', 'Beni Airport'],
    ['BNB', 'Boende Airport'],
    ['MTP', 'Montauk Airport'],
    ['VPZ', 'Porter County Municipal Airport'],
    ['DRV', 'Dharavandhoo Airport'],
    ['SXK', 'Saumlaki/Olilit Airport'],
    ['MLZ', 'Cerro Largo International Airport'],
    ['PDU', 'Tydeo Larre Borges Airport'],
    ['ATI', 'Artigas International Airport'],
    ['HSM', 'Horsham Airport'],
    ['SWH', 'Swan Hill Airport'],
    ['TTL', 'Turtle Island Seaplane Base'],
    ['KWB', 'Dewadaru - Kemujan Island'],
    ['KOO', 'Kongolo Airport'],
    ['AOU', 'Attopeu Airport'],
    ['SQX', 'São Miguel do Oeste Airport'],
    ['LDM', 'Mason County Airport'],
    ['RHV', 'Reid-Hillview Airport of Santa Clara County'],
    ['OHS', 'Sohar Airport'],
    ['KCF', 'Kadanwari Airport'],
    ['RZS', 'Sawan Airport'],
    ['TMF', 'Thimarafushi Airport'],
    ['IFU', 'Ifuru Airport'],
    ['KZF', 'Kaintiba Airport'],
    ['OGU', 'Ordu Giresun Airport'],
    ['YKO', 'Hakkari Yüksekova Airport'],
    ['BUT', 'Bathpalathang Airport'],
    ['TLI', 'Sultan Bantilan Airport'],
    ['TQL', 'Tarko-Sale Airport'],
    ['JIC', 'Jinchuan Airport'],
    ['BPL', 'Alashankou Bole (Bortala) airport'],
    ['FYN', 'Fuyun Koktokay Airport'],
    ['ACS', 'Achinsk Airport'],
    ['LFQ', 'Linfen Qiaoli Airport'],
    ['YJP', 'Hinton/Jasper-Hinton Airport'],
    ['WVI', 'Watsonville Municipal Airport'],
    ['GLU', 'Gelephu Airport'],
    ['HLI', 'Hollister Municipal Airport'],
    ['DLK', 'Dulkaninna Airport'],
    ['YBS', 'Opapimiskan Lake Airport'],
    ['RIZ', 'Rizhao Shanzihe Airport'],
    ['SQJ', 'Shaxian Airport'],
    ['XTO', 'Taroom Airport'],
    ['YSE', 'Squamish Airport'],
    ['YAH', 'La Grande-4 Airport'],
    ['YAL', 'Alert Bay Airport'],
    ['YCE', 'Centralia / James T. Field Memorial Aerodrome'],
    ['YCQ', 'Chetwynd Airport'],
    ['XRR', 'Ross River Airport'],
    ['YDO', 'Dolbeau St Felicien Airport'],
    ['YEY', 'Amos/Magny Airport'],
    ['YHE', 'Hope Airport'],
    ['YHT', 'Haines Junction Airport'],
    ['YDG', 'Digby / Annapolis Regional Airport'],
    ['YJF', 'Fort Liard Airport'],
    ['YKJ', 'Key Lake Airport'],
    ['YLR', 'Leaf Rapids Airport'],
    ['YME', 'Matane Airport'],
    ['YML', 'Charlevoix Airport'],
    ['YOS', 'Owen Sound / Billy Bishop Regional Airport'],
    ['YPS', 'Port Hawkesbury Airport'],
    ['YQS', 'St Thomas Municipal Airport'],
    ['YRO', 'Ottawa / Rockcliffe Airport'],
    ['YSH', 'Smiths Falls-Montague (Russ Beach) Airport'],
    ['YSL', 'St Leonard Airport'],
    ['YVE', 'Vernon Airport'],
    ['YXQ', 'Beaver Creek Airport'],
    ['YSN', 'Shuswap Regional Airport'],
    ['KES', 'Kelsey Airport'],
    ['XPK', 'Pukatawagan Airport'],
    ['ZGF', 'Grand Forks Airport'],
    ['ZJG', 'Jenpeg Airport'],
    ['YTD', 'Thicket Portage Airport'],
    ['PIW', 'Pikwitonei Airport'],
    ['XPP', 'Poplar River Airport'],
    ['WPC', 'Pincher Creek Airport'],
    ['ZST', 'Stewart Airport'],
    ['ZUC', 'Ignace Municipal Airport'],
    ['FNB', 'Neubrandenburg Airport'],
    ['FSS', 'RAF Kinloss'],
    ['BXP', 'Biała Podlaska Airfield'],
    ['DGP', 'Daugavpils Intrenational Airport'],
    ['LMR', 'Lime Acres Finsch Mine Airport'],
    ['SXN', 'Sua Pan Airport'],
    ['NDD', 'Sumbe Airport'],
    ['MAI', 'Mangochi Airport'],
    ['ADI', 'Arandis Airport'],
    ['MWE', 'Merowe New Airport'],
    ['ALN', 'St Louis Regional Airport'],
    ['AXN', 'Chandler Field'],
    ['CLU', 'Columbus Municipal Airport'],
    ['BBD', 'Curtis Field'],
    ['BIH', 'Eastern Sierra Regional Airport'],
    ['BKE', 'Baker City Municipal Airport'],
    ['BPI', 'Miley Memorial Field'],
    ['WMH', 'Ozark Regional Airport'],
    ['BTL', 'W K Kellogg Airport'],
    ['BYI', 'Burley Municipal Airport'],
    ['CCY', 'Northeast Iowa Regional Airport'],
    ['CNU', 'Chanute Martin Johnson Airport'],
    ['CRG', 'Jacksonville Executive at Craig Airport'],
    ['CSV', 'Crossville Memorial Whitson Field'],
    ['DAA', 'Davison Army Air Field'],
    ['DAG', 'Barstow Daggett Airport'],
    ['DMN', 'Deming Municipal Airport'],
    ['DRA', 'Desert Rock Airport'],
    ['EED', 'Needles Airport'],
    ['EGI', 'Duke Field'],
    ['EKA', 'Murray Field'],
    ['HYR', 'Sawyer County Airport'],
    ['JCT', 'Kimble County Airport'],
    ['LOL', 'Derby Field'],
    ['MBG', 'Mobridge Municipal Airport'],
    ['MCB', 'Mc Comb/Pike County Airport/John E Lewis Field'],
    ['MDH', 'Southern Illinois Airport'],
    ['MMT', 'Mc Entire Joint National Guard Base'],
    ['NHZ', 'Brunswick Executive Airport'],
    ['NRB', 'Naval Station Mayport (Admiral David L. Mcdonald Field)'],
    ['OGB', 'Orangeburg Municipal Airport'],
    ['OTM', 'Ottumwa Regional Airport'],
    ['OZR', 'Cairns AAF (Fort Rucker) Air Field'],
    ['PWY', 'Ralph Wenz Field'],
    ['POU', 'Dutchess County Airport'],
    ['RSL', 'Russell Municipal Airport'],
    ['RWF', 'Redwood Falls Municipal Airport'],
    ['SNS', 'Salinas Municipal Airport'],
    ['TPH', 'Tonopah Airport'],
    ['UKI', 'Ukiah Municipal Airport'],
    ['UOX', 'University Oxford Airport'],
    ['HTV', 'Huntsville Regional Airport'],
    ['VTN', 'Miller Field'],
    ['WMC', 'Winnemucca Municipal Airport'],
    ['WWR', 'West Woodward Airport'],
    ['ZZV', 'Zanesville Municipal Airport'],
    ['ECV', 'Cuatro Vientos Airport'],
    ['CDT', 'Castellón-Costa Azahar Airport'],
    ['TEV', 'Teruel Airport'],
    ['QLP', 'Sarzana-Luni Air Base'],
    ['BJY', 'Batajnica Air Base'],
    ['RUV', 'Rubelsanto Airport'],
    ['XPL', 'Coronel Enrique Soto Cano Air Base'],
    ['UPL', 'Upala Airport'],
    ['QSN', 'San Nicolas De Bari Airport'],
    ['SNJ', 'San Julian Air Base'],
    ['DWD', 'King Salman Abdulaziz Airport'],
    ['KMX', 'King Khaled Air Base'],
    ['XXN', 'Riyadh Air Base'],
    ['KNR', 'Jam Airport'],
    ['PYK', 'Payam International Airport'],
    ['XIJ', 'Ahmed Al Jaber Air Base'],
    ['ATG', 'Minhas Air Base'],
    ['SGI', 'Mushaf Air Base'],
    ['RQW', 'Qayyarah West Airport'],
    ['ENN', 'Nenana Municipal Airport'],
    ['WWA', 'Wasilla Airport'],
    ['IWK', 'Iwakuni Marine Corps Air Station'],
    ['IEJ', 'Ie Jima Airport'],
    ['AAV', 'Allah Valley Airport'],
    ['BPH', 'Bislig Airport'],
    ['MXI', 'Mati National Airport'],
    ['BAT', 'Chafei Amsei Airport'],
    ['QHP', 'Base de Aviação de Taubaté Airport'],
    ['TOQ', 'Barriles Airport'],
    ['CNR', 'Chañaral Airport'],
    ['TLX', 'Panguilemo Airport'],
    ['ZIC', 'Victoria Airport'],
    ['TTC', 'Las Breas Airport'],
    ['API', 'Gomez Nino Apiay Air Base'],
    ['CVE', 'Coveñas Airport'],
    ['PAL', 'German Olano Air Base'],
    ['PZA', 'Paz De Ariporo Airport'],
    ['TQS', 'Tres Esquinas Air Base'],
    ['RIJ', 'Juan Simons Vela Airport'],
    ['JAE', 'Shumba Airport'],
    ['IKU', 'Issyk-Kul International Airport'],
    ['VYI', 'Vilyuisk Airport'],
    ['BGN', 'Belaya Gora Airport'],
    ['GVN', 'Maygatka Airport.'],
    ['NLI', 'Nikolayevsk-na-Amure Airport'],
    ['VLK', 'Volgodonsk Airport'],
    ['INA', 'Inta Airport'],
    ['ZIX', 'Zhigansk Airport'],
    ['RTC', 'Ratnagiri Airport'],
    ['HIM', 'Hingurakgoda Air Force Base'],
    ['RDP', 'Kazi Nazrul Islam Airport'],
    ['PUT', 'Sri Sathya Sai Airport'],
    ['LNX', 'Smolensk North Airport'],
    ['ABH', 'Alpha Airport'],
    ['ARY', 'Ararat Airport'],
    ['BLN', 'Benalla Airport'],
    ['BZD', 'Balranald Airport'],
    ['BWQ', 'Brewarrina Airport'],
    ['CVC', 'Cleve Airport'],
    ['CWW', 'Corowa Airport'],
    ['CYG', 'Corryong Airport'],
    ['CMD', 'Cootamundra Airport'],
    ['DRN', 'Dirranbandi Airport'],
    ['DNQ', 'Deniliquin Airport'],
    ['DYA', 'Dysart Airport'],
    ['ECH', 'Echuca Airport'],
    ['FRB', 'Forbes Airport'],
    ['GUH', 'Gunnedah Airport'],
    ['HXX', 'Hay Airport'],
    ['HTU', 'Hopetoun Airport'],
    ['KRA', 'Kerang Airport'],
    ['KPS', 'Kempsey Airport'],
    ['KGY', 'Kingaroy Airport'],
    ['TGN', 'Latrobe Valley Airport'],
    ['MRG', 'Mareeba Airport'],
    ['RPM', 'Ngukurr Airport'],
    ['QRM', 'Narromine Airport'],
    ['PPI', 'Port Pirie Airport'],
    ['SIO', 'Smithton Airport'],
    ['SNB', 'Snake Bay Airport'],
    ['SWC', 'Stawell Airport'],
    ['TYB', 'Tibooburra Airport'],
    ['TUM', 'Tumut Airport'],
    ['WGT', 'Wangaratta Airport'],
    ['WKB', 'Warracknabeal Airport'],
    ['QRR', 'Warren Airport'],
    ['SXE', 'West Sale Airport'],
    ['WWY', 'West Wyalong Airport'],
    ['NGA', 'Young Airport'],
    ['LHK', 'Guangzhou MR Air Base'],
    ['WDS', 'Shiyan Wudangshan Airport'],
    ['HTT', 'Huatugou Airport'],
    ['UUN', 'Baruun Urt Airport'],
    ['BFU', 'Bengbu Airport'],
    ['RUG', 'Rugao Air Base'],
    ['WHU', 'Wuhu Air Base'],
    ['SXJ', 'Shanshan Airport'],
    ['YKH', 'Yingkou Lanqi Airport'],
    ['BQG', 'Bogorodskoye Airport'],
    ['HYD', 'Rajiv Gandhi International Airport'],
    ['PKO', 'Parakou Airport'],
    ['KDC', 'Kandi Airport'],
    ['HMG', 'Hermannsburg Airport'],
    ['BIB', 'Baidoa Airport'],
    ['KCS', 'Kings Creek Airport'],
    ['BMR', 'Baltrum Airport'],
    ['MVW', 'Skagit Regional Airport'],
    ['GOO', 'Goondiwindi Airport'],
    ['APT', 'Marion County Brown Field'],
    ['DCU', 'Pryor Field Regional Airport'],
    ['GLW', 'Glasgow Municipal Airport'],
    ['DKV', 'Docker River Airport'],
    ['RNZ', 'Jasper County Airport'],
    ['SBT', 'Sabetta International Airport'],
    ['AXF', 'Alxa Left Banner Bayanhot Airport'],
    ['NIS', 'Simberi Airport'],
    ['BUL', 'Bulolo Airport'],
    ['TBR', 'Statesboro Bulloch County Airport'],
    ['AMT', 'Amata Airport'],
    ['EDD', 'Erldunda Airport'],
    ['FIK', 'Finke Airport'],
    ['JJG', 'Humberto Ghizzo Bortoluzzi Regional Airport'],
    ['WKI', 'Hwange (Town) Airport'],
    ['KLJ', 'Klaipėda Airport'],
    ['ETB', 'West Bend Municipal Airport'],
    ['GLR', 'Gaylord Regional Airport'],
    ['AID', 'Anderson Municipal Darlington Field'],
    ['QND', 'Cenej Airport'],
    ['GUU', 'Grundarfjörður Airport'],
    ['PCD', 'Prairie Du Chien Municipal Airport'],
    ['OTJ', 'Otjiwarongo Airport'],
    ['TSM', 'Taos Regional Airport'],
    ['RTN', 'Raton Municipal-Crews Field'],
    ['PPA', 'Perry Lefors Field'],
    ['FLP', 'Marion County Regional Airport'],
    ['BGD', 'Hutchinson County Airport'],
    ['HLE', 'St. Helena Airport'],
    ['BNJ', 'Bonn-Hangelar Airport'],
    ['NGK', 'Nogliki Airport'],
    ['SQA', 'Santa Ynez Airport'],
    ['HXD', 'Delingha Airport'],
    ['BAR', "Qionghai Bo'ao Airport"],
    ['ZBO', 'Bowen Airport'],
    ['UCB', 'Ulanqab Jining Airport'],
    ['KEO', 'Odienne Airport'],
    ['GII', 'Siguiri Airport'],
    ['NZE', 'Nzérékoré Airport'],
    ['OCM', 'Boolgeeda'],
    ['WGN', 'Shaoyang Wugang Airport'],
    ['TXF', '9 de Maio - Teixeira de Freitas Airport'],
    ['SPA', 'Spartanburg Downtown Memorial Airport'],
    ['BJW', 'Bajawa Soa Airport'],
    ['NBN', 'Annobón Airport'],
    ['OLL', 'Oyo Ollombo Airport'],
    ['PPF', 'Tri-City Airport'],
    ['PCQ', 'Boun Neau Airport'],
    ['AYS', 'Waycross Ware County Airport'],
    ['DSS', 'Blaise Diagne International Airport'],
    ['PMH', 'Greater Portsmouth Regional Airport'],
    ['NAC', 'Naracoorte Airport'],
    ['PGZ', 'Ponta Grossa Airport - Comandante Antonio Amilton Beraldo'],
    ['PQM', 'Palenque International Airport'],
    ['CUD', 'Caloundra Airport'],
    ['CLP', 'Clarks Point Airport'],
    ['JOJ', 'Doris Lake'],
    ['ECI', 'Costa Esmeralda Airport'],
    ['QGQ', 'Attu Heliport'],
    ['QPW', 'Kangaatsiaq Heliport'],
    ['QJE', 'Kitsissuarsuit Heliport'],
    ['XIQ', 'Ilimanaq Heliport'],
    ['QQT', 'Qeqertaq Heliport'],
    ['QJI', 'Ikamiut Heliport'],
    ['QFG', 'Eqalugaarsuit Heliport'],
    ['QRY', 'Ikerassaarsuk Heliport'],
    ['NPR', 'Novo Progresso Airport'],
    ['SMT', 'Adolino Bedin Regional Airport'],
    ['ENO', 'Encarnación Airport'],
    ['UZR', 'Urzhar Airport'],
    ['NYR', 'Nyurba Airport'],
    ['SUK', 'Sakkyryr Airport'],
    ['UKG', 'Ust-Kuyga Airport'],
    ['VHV', 'Verkhnevilyuisk Airport'],
    ['DEE', 'Mendeleyevo Airport'],
    ['EKS', 'Shakhtyorsk Airport'],
    ['TLK', 'Talakan Airport'],
    ['RDB', 'Red Dog Airport'],
    ['ION', 'Impfondo Airport'],
    ['EBH', 'El Bayadh Airport'],
    ['ZIS', 'Alzintan Airport'],
    ['MYZ', 'Monkey Bay Airport'],
    ['BCU', 'Sir Abubakar Tafawa Balewa International Airport'],
    ['GMO', 'Gombe Lawanti International Airport'],
    ['KDA', 'Kolda North Airport'],
    ['SHO', 'King Mswati III International Airport'],
    ['KIY', 'Kilwa Masoko Airport'],
    ['QSI', 'Moshi Airport'],
    ['MNS', 'Mansa Airport'],
    ['GZI', 'Ghazni Airport'],
    ['DBC', "Baicheng Chang'an Airport"],
    ['LNL', 'Longnan Chengzhou Airport'],
    ['SQD', 'Shangrao Sanqingshan Airport'],
    ['YSQ', 'Songyuan Chaganhu Airport'],
    ['JSJ', 'Jiansanjiang Airport'],
    ['WMT', 'Zunyi Maotai Airport'],
    ['LLB', 'Libo Airport'],
    ['CDE', 'Chengde Puning Airport'],
    ['DTU', 'Wudalianchi Dedu Airport'],
    ['EJN', 'Ejin Banner-Taolai Airport'],
    ['RHT', 'Alxa Right Banner Badanjilin Airport'],
    ['HUO', 'Holingol Huolinhe Airport'],
    ['GMQ', 'Golog Maqin Airport'],
    ['QSZ', 'Yeerqiang Airport'],
    ['TEI', 'Tezu Airport'],
    ['CWJ', 'Cangyuan Washan Airport'],
    ['JMJ', 'Lancang Jingmai Airport'],
    ['NLH', 'Ninglang Luguhu Airport'],
    ['PBQ', 'Pimenta Bueno Airport'],
    ['WUT', 'Xinzhou Wutaishan Airport'],
    ['NZL', 'Chengjisihan Airport'],
    ['AIP', 'Adampur Airport'],
    ['VDY', 'Vijayanagar Aerodrome (JSW)'],
    ['SAG', 'Shirdi Airport'],
    ['PYB', 'Jeypore Airport'],
    ['SUP', 'Trunojoyo Airport'],
    ['RKO', 'Rokot Airport'],
    ['PPR', 'Pasir Pangaraan Airport'],
    ['TJB', 'Sei Bati Airport'],
    ['KRC', 'Departi Parbo Airport'],
    ['NRE', 'Namrole Airport'],
    ['NAM', 'Namlea Airport'],
    ['DOB', 'Rar Gwamar Airport'],
    ['SQN', 'Emalamo Sanana Airport'],
    ['AYW', 'Ayawasi Airport'],
    ['BYQ', 'Bunyu Airport'],
    ['UOL', 'Buol Airport'],
    ['RAQ', 'Sugimanuru Airport'],
    ['JSK', 'Jask Airport'],
    ['ZBY', 'Sayaboury Airport'],
    ['BGL', 'Baglung Airport'],
    ['RJB', 'Rajbiraj Airport'],
    ['DQM', 'Duqm International Airport'],
    ['CKI', 'Croker Island Airport'],
    ['BYP', 'Barimunya Airport'],
    ['LUC', 'Laucala Island Airport'],
    ['YAS', 'Yasawa Island Airport'],
    ['WIK', 'Waiheke Reeve Airport'],
    ['DEX', 'Nop Goliat Airport'],
    ['NAU', 'Napuka Island Airport'],
    ['FAC', 'Faaite Airport'],
    ['NUK', 'Nukutavake Airport'],
    ['VHZ', 'Vahitahi Airport'],
    ['HHZ', 'Hikueru Atoll Airport'],
    ['RRR', 'Raroia Airport'],
    ['KHZ', 'Kauehi Airport'],
    ['TKV', 'Tatakoto Airport'],
    ['TDS', 'Sasereme Airport'],
    ['TMH', 'Tanah Merah Airport'],
    ['NKB', 'Noonkanbah Airport'],
    ['AEI', 'Algeciras Heliport'],
    ['KSE', 'Kasese Airport'],
    ['NCJ', 'Sunchales Aeroclub Airport'],
    ['IST', 'Istanbul Airport'],
    ['SOV', 'Seldovia Airport'],
    ['YSG', "Lutselk'e Airport"],
    ['DWA', 'Dwangwa Airport'],
    ['HGI', 'Paloich Airport, Heliport'],
    ['KQH', 'Kishangarh Airport'],
    ['CNN', 'Kannur International Airport'],
    ['ZCO', 'La Araucanía Airport'],
    ['ASS', 'Arathusa Safari Lodge Airport'],
    ['VDI', 'Vidalia Regional Airport'],
    ['ISB', 'New Islamabad International Airport'],
    ['MHE', 'Mitchell Municipal Airport'],
    ['GIT', 'Mchauru Airport'],
    ['GID', 'Gitega Airport'],
    ['RMU', 'Región de Murcia International Airport'],
    ['CQS', 'Costa Marques Airport'],
    ['ZXT', 'Zabrat Airport'],
    ['JAM', 'Bezmer Air Base'],
    ['YUA', 'Yuanmou Air Base'],
    ['XEN', 'Xingcheng Air Base'],
    ['GEC', 'Lefkoniko Airport'],
    ['MBI', 'Songwe Airport'],
    ['UGU', 'Bilogai-Sugapa Airport'],
    ['ETM', 'Ramon Airport'],
    ['MNH', 'Rustaq Airport'],
    ['CGY', 'Laguindingan Airport'],
    ['CPO', 'Desierto de Atacama Airport'],
]

export default airports
