import { LoadingWrapper, LuneTheme } from '@lune-fe/lune-ui-lib'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useEffect, useState } from 'react'

import { setupPaymentIntent } from 'endpoints/dapi'
import AddCardPaymentElement from 'views/PaymentMethod/AddCardPaymentElement'

/*
Stripe needs a string key (it can crash otherwise) so we have a choice:

1. Always crash here if the key is not set (this way the crashes are reliable)
2. Default to something (an empty string?)

Consider we do provide the environment variable in our preprod, prod and dev
configurations (.env.dev for dev) it's safer to crash so we'll know right away
if we remove the environment variable by accident or something.

An empty string is a valid dummy value for this variable as long as one doesn't
actually plan to interact with Stripe.
*/
if (process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY === undefined) {
    throw new Error('The REACT_APP_STRIPE_PUBLISHABLE_KEY environment variable has to be provided')
}
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

const AddCardForm = ({
    onSuccess,
    onBack,
}: {
    onSuccess?: (stripeId?: string) => void
    onBack: () => void
}) => {
    const [clientSecret, setClientSecret] = useState<string>()
    const { palette } = LuneTheme

    const stripeAppearanceConfiguration = {
        theme: 'none' as any,
        variables: {
            borderRadius: '8px',
            fontFamily: 'Helvetica Neue',
            colorText: palette.Grey600!,
            colorBackground: palette.Grey100!,
            spacingGridColumn: '8px',
        },
        rules: {
            '.Input': {
                color: palette.Grey900!,
                padding: '16px 12px 16px 12px',
            },
            '.Input:focus': {
                outline: 'none',
            },
            '.Label': {
                opacity: '0',
                marginTop: '-26px',
            },
            '.Input--invalid': {
                backgroundColor: palette.Red50!,
            },
            '.Error': {
                color: 'red',
                fontSize: '12.8px',
                fontFamily: 'Helvetica Neue',
            },
        },
    }

    useEffect(() => {
        const addClientSecret = async () => {
            const response = await setupPaymentIntent()
            setClientSecret(response.secret)
        }

        addClientSecret()
    }, [])

    return (
        <LoadingWrapper
            loading={!clientSecret}
            sx={{
                height: `300px`,
                width: `736px`,
            }}
        >
            <Elements
                key={clientSecret}
                stripe={stripePromise}
                options={{
                    clientSecret,
                    appearance: stripeAppearanceConfiguration,
                }}
            >
                <AddCardPaymentElement onSuccess={onSuccess} onBack={onBack} />
            </Elements>
        </LoadingWrapper>
    )
}

export default AddCardForm
