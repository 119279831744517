import { Button, ListItemLayout, Tile } from '@lune-fe/lune-ui-lib'
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined'
import InsertInvitationOutlinedIcon from '@mui/icons-material/InsertInvitationOutlined'
import { useCallback } from 'react'

import ConditionalTooltip from 'components/ConditionalTooltip'
import NotAdminTooltip from 'components/NotAdminTooltip'
import PaymentMethodLogo, { Brands } from 'components/PaymentMethodLogo'
import { PaymentMethod as CardPaymentMethod, PaymentMethod } from 'models/openDapi'

const PaymentMethodTile = ({
    paymentMethod,
    isReadOnly,
    onEdit,
}: {
    isReadOnly?: boolean
    paymentMethod?: `invoice` | CardPaymentMethod
    onEdit: () => void
}) => {
    const getBrandIcon = useCallback((method: PaymentMethod) => {
        const { brand, last4 } = method
        switch (brand) {
            case Brands.Amex:
                return {
                    image: <PaymentMethodLogo paymentMethod={method} />,
                    title: `American Express **** **** **** ${last4}`,
                }
            case Brands.MasterCard:
                return {
                    image: <PaymentMethodLogo paymentMethod={method} />,
                    title: `Mastercard **** **** **** ${last4}`,
                }
            case Brands.Discover:
                return {
                    image: <PaymentMethodLogo paymentMethod={method} />,
                    title: `Discover **** **** **** ${last4}`,
                }
            case Brands.Visa:
                return {
                    image: <PaymentMethodLogo paymentMethod={method} />,
                    title: `Visa **** **** **** ${last4}`,
                }
            case Brands.Diners:
                return {
                    image: <PaymentMethodLogo paymentMethod={method} />,
                    title: `Diners Club **** **** **** ${last4}`,
                }
            default:
                return { image: ``, title: `` }
        }
    }, [])

    return (
        <>
            {paymentMethod === `invoice` && (
                <Tile
                    button={
                        <ConditionalTooltip show={!!isReadOnly} tooltip={NotAdminTooltip}>
                            <Button
                                data-testid={`change-payment-method-btn`}
                                variant={'text'}
                                onClick={() => onEdit()}
                                disabled={isReadOnly}
                            >
                                Change
                            </Button>
                        </ConditionalTooltip>
                    }
                >
                    <ListItemLayout image={<InsertInvitationOutlinedIcon />} title={`Invoice`} />
                </Tile>
            )}
            {paymentMethod && paymentMethod !== `invoice` && (
                <Tile
                    button={
                        <ConditionalTooltip show={!!isReadOnly} tooltip={NotAdminTooltip}>
                            <Button
                                data-testid={`change-payment-method-btn`}
                                variant={'text'}
                                onClick={() => onEdit()}
                                disabled={isReadOnly}
                            >
                                Change
                            </Button>
                        </ConditionalTooltip>
                    }
                >
                    <ListItemLayout {...getBrandIcon(paymentMethod)} />
                </Tile>
            )}
            {paymentMethod === undefined && (
                <Tile
                    button={
                        <ConditionalTooltip show={!!isReadOnly} tooltip={NotAdminTooltip}>
                            <Button
                                data-testid={`change-payment-method-btn`}
                                variant={'text'}
                                onClick={() => onEdit()}
                                disabled={isReadOnly}
                            >
                                Set up
                            </Button>
                        </ConditionalTooltip>
                    }
                >
                    <ListItemLayout
                        image={<AddCardOutlinedIcon />}
                        title={`Set up organisation payment method`}
                        subTitle={`Pay by card or with your next invoice`}
                    />
                </Tile>
            )}
        </>
    )
}

export default PaymentMethodTile
