import { ButtonGroup } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import { FormApi } from 'final-form'
import { FC, useEffect, useState } from 'react'
import { Field, FormSpy } from 'react-final-form'

import useAccounts from 'hooks/useAccounts'
import { getCurrencySymbol } from 'models/currency'
import { ProposalRequest } from 'models/openDapi'
import { InputWithIDontKnowOption } from 'views/Proposals/ProposalRequestForm/InputWithIDontKnowOption'
import ProposalRequestLabeledFormSection from 'views/Proposals/ProposalRequestForm/ProposalRequestLabeledFormSection'

enum PRICE_TYPE {
    price = 'price',
    pricePerTonne = 'pricePerTonne',
}

export const BudgetStep: FC<{ formValues?: ProposalRequest }> = ({ formValues }) => {
    const [selectedPriceType, setSelectedPriceType] = useState<PRICE_TYPE>(PRICE_TYPE.price)
    const { activeAccount } = useAccounts()

    useEffect(() => {
        if (formValues?.pricePerTonne || formValues?.price) {
            setSelectedPriceType(
                formValues.pricePerTonne ? PRICE_TYPE.pricePerTonne : PRICE_TYPE.price,
            )
        }
    }, [formValues])

    return (
        <FormSpy>
            {({ form }: { form: FormApi }) => {
                return (
                    <>
                        <ProposalRequestLabeledFormSection
                            label={'How many tonnes do you want to buy?'}
                            subLabel={'Ballpark figures are sufficient'}
                        >
                            <Field name={'quantity'}>
                                {({ input: { onChange, value } }: any) => (
                                    <InputWithIDontKnowOption
                                        placeholder={'Amount'}
                                        endAdornment={'tonnes of CO₂'}
                                        dataTestId={'prop-request-quantity-input'}
                                        onChange={(val) => {
                                            if (val) {
                                                onChange({
                                                    amount: val,
                                                    unit: 't',
                                                })
                                            }
                                        }}
                                        value={value.amount}
                                    />
                                )}
                            </Field>
                        </ProposalRequestLabeledFormSection>
                        <ProposalRequestLabeledFormSection
                            label={'What’s your budget?'}
                            subLabel={'Ballpark figures are sufficient'}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    gap: 1 / 2,
                                }}
                            >
                                <Box sx={{ width: '50%' }}>
                                    {selectedPriceType === PRICE_TYPE.pricePerTonne ||
                                    formValues?.pricePerTonne ? (
                                        <Field name={'pricePerTonne'}>
                                            {({ input: { onChange, value } }: any) => (
                                                <InputWithIDontKnowOption
                                                    placeholder={'Amount'}
                                                    dataTestId={
                                                        'prop-request-price-per-tonne-input'
                                                    }
                                                    onChange={(val) => {
                                                        onChange(val)
                                                    }}
                                                    value={value}
                                                />
                                            )}
                                        </Field>
                                    ) : (
                                        <Field name={'price'}>
                                            {({ input: { onChange, value } }: any) => (
                                                <InputWithIDontKnowOption
                                                    dataTestId={'prop-request-total-price-input'}
                                                    placeholder={'Amount'}
                                                    onChange={(val) => {
                                                        onChange(val)
                                                    }}
                                                    value={value}
                                                />
                                            )}
                                        </Field>
                                    )}
                                </Box>
                                <ButtonGroup
                                    sx={{ width: '50%' }}
                                    items={[
                                        {
                                            label: getCurrencySymbol(activeAccount?.currency || ''),
                                            value: PRICE_TYPE.price,
                                        },
                                        {
                                            label: `${getCurrencySymbol(
                                                activeAccount?.currency || '',
                                            )}/CO₂`,
                                            value: PRICE_TYPE.pricePerTonne,
                                        },
                                    ]}
                                    onChange={(e) => {
                                        if (e !== selectedPriceType) {
                                            setSelectedPriceType(e as PRICE_TYPE)
                                            if (e === PRICE_TYPE.pricePerTonne) {
                                                form.mutators.setValue(
                                                    'pricePerTonne',
                                                    formValues?.price,
                                                )
                                                form.mutators.setValue('price', undefined)
                                            } else {
                                                form.mutators.setValue(
                                                    'price',
                                                    formValues?.pricePerTonne,
                                                )
                                                form.mutators.setValue('pricePerTonne', undefined)
                                            }
                                        }
                                    }}
                                    value={selectedPriceType}
                                />
                            </Box>
                        </ProposalRequestLabeledFormSection>
                    </>
                )
            }}
        </FormSpy>
    )
}

export default BudgetStep
