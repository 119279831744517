import { FC, RefObject, useState } from 'react'

import { CalculateEmissionsFormWrapper, EstimateTypeEnum } from 'views/CalculateEmissions'

const TravelEmissions: FC<{ scrollRef: RefObject<HTMLDivElement> }> = ({ scrollRef }) => {
    const [loading] = useState<boolean>(false)
    const [initialFormValue] = useState<any>()
    const onSubmit = async () => {
        // temp
    }
    return (
        <CalculateEmissionsFormWrapper<any>
            emissionType={EstimateTypeEnum.FLIGHT}
            loading={loading}
            initialFormValue={initialFormValue}
            onCreate={onSubmit}
            layoutScrollRef={scrollRef}
        >
            TravelEmissions
        </CalculateEmissionsFormWrapper>
    )
}

export default TravelEmissions
