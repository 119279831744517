import { Button, LuneTheme } from '@lune-fe/lune-ui-lib'
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined'
import { Box } from '@mui/material'
import { FC, useEffect, useRef } from 'react'

const CartButton: FC<{ numberOfSelectedBundles: number; onClick: () => void }> = ({
    numberOfSelectedBundles,
    onClick,
}) => {
    const { palette } = LuneTheme
    const rippleRef: any = useRef(null)

    const triggerRipple = () => {
        rippleRef.current?.start({ clientX: 0, clientY: 0 }, { center: false })
    }

    useEffect(() => {
        if (numberOfSelectedBundles !== 0) {
            triggerRipple()
            // when starting ripple effect a span element is added to the button to create the visual effect
            // this animation lasts 200s, and after that ripple effect should be stopped to remove added element and animation
            const timeoutId = setTimeout(() => rippleRef.current?.stop({}), 200)
            return () => clearTimeout(timeoutId)
        }
    }, [numberOfSelectedBundles])

    return (
        <Button
            data-testId={'open-cart-modal-button'}
            sx={{ position: 'relative' }}
            variant={'contained'}
            onClick={onClick}
            leftIcon={<ShoppingCartOutlinedIcon />}
            /* @ts-ignore: ... */
            touchRippleRef={rippleRef}
        >
            {numberOfSelectedBundles > 0 && (
                <Box
                    key={numberOfSelectedBundles}
                    sx={{
                        background: palette.White,
                        borderRadius: '100%',
                        color: palette.Grey900,
                        height: '16px',
                        minWidth: '16px',
                        position: 'absolute',
                        textAlign: 'center',
                        top: '8px',
                        left: '24px',
                        lineHeight: '16px',
                        animation: 'pulse 300ms linear',
                        willChange: 'transform',
                        transform: 'translateZ(0)',
                        backfaceVisibility: 'hidden',
                        fontSize: '12px',
                        '@keyframes pulse': {
                            '0%': {
                                transform: 'scale(1)',
                            },
                            '50%': {
                                transform: 'scale(1.2)',
                            },
                            '100%': {
                                transform: 'scale(1)',
                            },
                        },
                    }}
                >
                    {numberOfSelectedBundles.toString()}
                </Box>
            )}
            Your portfolio
        </Button>
    )
}

export default CartButton
