import type { SxProps } from '@mui/material'
import Box from '@mui/material/Box'
import React, { FC, useEffect, useState } from 'react'

import ButtonGroup from './ButtonGroup'

export interface TabProps {
    label: string
    value: string
    dataTestId: string
}

type TabsSubcomponent = {
    Tab: typeof Tab
}

const Tab: FC<{ children: React.ReactNode } & { label: string; value: string }> = ({
    children,
}) => {
    return <>{children}</>
}

const ToggleTabs: FC<{
    children: React.ReactNode[]
    defaultActive?: string
    sx?: SxProps
    onChange?: (selected: TabProps | undefined) => void
}> &
    TabsSubcomponent = ({ children, defaultActive, sx, onChange }) => {
    const titles: TabProps[] = children.map((c: any) => {
        return {
            value: c.props.value.toLowerCase(),
            label: c.props.label,
            dataTestId: `tab-testId-${c.props.label}`,
        }
    })
    const [active, setActive] = useState<string>(titles[0].value)

    useEffect(() => {
        const tabToSelect = titles.find(
            (title) => title.value.trim() === defaultActive?.toLowerCase(),
        )
        if (tabToSelect) {
            setActive(tabToSelect.value)
        }
    }, [defaultActive])

    return (
        <Box sx={{ ...sx }}>
            <ButtonGroup
                items={titles}
                sx={{ marginBottom: '2px' }}
                onChange={(activeTab) => {
                    setActive(activeTab)
                    onChange?.(titles.find((t) => t.value === activeTab))
                }}
                value={active}
            />
            {children.find((c: any) => c.props.value.toLowerCase() === active)}
        </Box>
    )
}

ToggleTabs.Tab = Tab
export default ToggleTabs
