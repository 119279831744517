import { Button } from '@lune-fe/lune-ui-lib'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { Field, Form } from 'react-final-form'
import { Link } from 'react-router-dom'

import { FormInput } from 'components/FormInput'
import { requestPasswordReset } from 'endpoints/dapi'
import useMixpanel from 'hooks/useMixpanel'
import { SnackbarMessages } from 'SnackbarMessages'
import FormBox from 'views/Account/Shared/FormBox'
import GenericErrorMessage from 'views/Account/Shared/GenericErrorMessage'
import Layout from 'views/Account/Shared/Layout'

interface IFormData {
    email: string
}

export default function ForgotPassword() {
    const [requestSent, setRequestSent] = useState<boolean>(false)
    const validate = (value: any) => (value?.trim() ? undefined : SnackbarMessages.REQUIRED_FIELD)
    const { enqueueSnackbar: snackbar } = useSnackbar()
    const mixpanel = useMixpanel()

    const onSubmit = async ({ email }: IFormData) => {
        requestPasswordReset(email).then(
            () => {
                snackbar(SnackbarMessages.PASSWORD_RESET_LINK_SENT)
                setRequestSent(true)
                mixpanel.track('PasswordResetIntructionsSent', {
                    email,
                })
            },
            (response) => {
                if (response.status > 299) {
                    snackbar(<GenericErrorMessage />, {
                        persist: true,
                        variant: 'error',
                    })
                }
                mixpanel.track('PasswordResetIntructionsFailure', {
                    email,
                    status: response.status,
                })
            },
        )
    }

    return (
        <Layout>
            <FormBox title={'Forgot password'}>
                <Form
                    sx={{ width: '100%' }}
                    onSubmit={onSubmit}
                    render={({ handleSubmit }) => {
                        return (
                            <form
                                onSubmit={handleSubmit}
                                onChange={() => {
                                    setRequestSent(false)
                                }}
                            >
                                <Field
                                    name={'email'}
                                    placeholder={'Email'}
                                    component={FormInput}
                                    validate={validate}
                                />
                                <Button
                                    data-testid={'reset-password-button'}
                                    type={'submit'}
                                    sx={{ width: '100%', mb: 1, mt: 4 }}
                                    disabled={requestSent}
                                >
                                    Reset password
                                </Button>
                                <Link to="/login">
                                    <Button variant={'text'} sx={{ width: '100%', mb: 1 }}>
                                        Log in
                                    </Button>
                                </Link>
                                <Link to="/signup">
                                    <Button variant={'text'} sx={{ width: '100%' }}>
                                        Sign up
                                    </Button>
                                </Link>
                            </form>
                        )
                    }}
                />
            </FormBox>
        </Layout>
    )
}
