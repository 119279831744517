import { Project } from '@lune-climate/lune'
import Box from '@mui/material/Box'
import { FC, useEffect, useMemo, useState } from 'react'
import { Chart } from 'react-chartjs-2'

import {
    chartAreaBorder,
    chartColors,
    chartOptions,
} from 'views/Projects/Project/CarbonStorageOverTime/ChartConfig'
import ChartLegend from 'views/Projects/Project/CarbonStorageOverTime/ChartLegend'

import 'chart.js/auto'
import 'chartjs-adapter-moment'

export type CarbonStorageOverTimeProps = NonNullable<Project[`carbonStorageOverTime`]>[0]

const bufferDataPoint = (chartData: CarbonStorageOverTimeProps) => {
    const x = chartData.carbonStock
    const y = chartData.baseline + chartData.assumedLeakage + chartData.creditsIssued
    return [x, y]
}

const assumedLeakageDataPoint = (chartData: CarbonStorageOverTimeProps) => {
    const x = chartData.baseline + chartData.assumedLeakage + chartData.creditsIssued
    const y = chartData.baseline + chartData.creditsIssued
    return [x, y]
}

const creditsIssuedDataPoint = (chartData: CarbonStorageOverTimeProps) => {
    const x = chartData.baseline + chartData.creditsIssued
    const y = chartData.baseline
    return [x, y]
}

const CarbonStorageChart: FC<{
    carbonStorageOverTime: CarbonStorageOverTimeProps[]
}> = ({ carbonStorageOverTime }) => {
    const [chartData, setChartData] = useState<CarbonStorageOverTimeProps[]>([])

    useEffect(() => {
        setChartData(carbonStorageOverTime)
    }, [carbonStorageOverTime])

    const data = useMemo(() => {
        return {
            labels: chartData.map((d) => d.year),
            cubicInterpolationMode: 'monotone' as const,
            tension: 0.8,
            datasets: [
                {
                    label: 'buffer',
                    data: chartData.map((d, index) => bufferDataPoint(chartData[index])),
                    backgroundColor: chartColors.buffer,
                    barThickness: 4,
                    order: 1,
                },
                {
                    label: 'assumedLeakage',
                    data: chartData.map((d, index) => assumedLeakageDataPoint(chartData[index])),
                    backgroundColor: chartColors.assumedLeakage,
                    barThickness: 4,
                    order: 1,
                },
                {
                    label: 'creditsIssued',
                    data: chartData.map((d, index) => creditsIssuedDataPoint(chartData[index])),
                    backgroundColor: chartColors.creditsIssued,
                    barThickness: 4,
                    order: 1,
                },
                // LINES
                {
                    label: 'carbonStock',
                    data: chartData.map((d) => d.carbonStock),
                    borderColor: chartColors.carbonStock,
                    backgroundColor: chartColors.carbonStock,
                    type: 'line',
                    order: 0,
                },
                {
                    label: 'baseline',
                    data: chartData.map((d) => d.baseline),
                    borderColor: chartColors.baseline,
                    backgroundColor: chartColors.baseline,
                    type: 'line',
                    order: 0,
                },
            ],
        }
    }, [chartData])

    return (
        <Box>
            <ChartLegend />
            <Box
                sx={{
                    position: 'relative',
                    height: '400px',
                    width: '100%',
                }}
            >
                <Chart
                    type="bar"
                    data={data as any}
                    options={chartOptions(chartData) as any}
                    plugins={[chartAreaBorder]}
                />
            </Box>
        </Box>
    )
}

export default CarbonStorageChart
