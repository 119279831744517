import {
    EmissionFactorWithGasEmissions,
    GasEmissions,
    TransactionDocumentEmissionEstimateLineItem,
} from '@lune-climate/lune'
import { Text, TooltipList } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import Big from 'big.js'
import moment from 'moment'
import { FC, useMemo } from 'react'

import { formatToCurrency, getCountryNameByThreeLetterCode } from '../../utils/utils'

import EmissionFactorExplanation from './EmissionFactorExplanation'
import ExplanationChip from './ExplanationChip'
import ExplanationSteps, { ExplanationStep, ExplanationText } from './ExplanationSteps'

interface TransactionDocumentEmissionsExplanationLineItemSectionProps {
    lineItem: TransactionDocumentEmissionEstimateLineItem
    enableEmissionFactorLink: boolean
}

export const TransactionDocumentEmissionsExplanationLineItemSection: FC<
    TransactionDocumentEmissionsExplanationLineItemSectionProps
> = ({ lineItem, enableEmissionFactorLink }) => {
    const {
        countryCode,
        dietFactor,
        item,
        category,
        categoryCode,
        emissionFactor: maybeEmissionFactor,
        value,
        exchangeRateDate,
        exchangeRate,
    } = lineItem

    // Cannot be null. Only very old estimate types can have a null emission factor
    const emissionFactor = maybeEmissionFactor!

    const itemStep = useMemo((): JSX.Element | undefined => {
        if (!item) {
            return undefined
        }
        return (
            <>
                <ExplanationText text="Extracted the" />
                <ExplanationChip text={item} />
                <ExplanationText text="line item based on your input" />
            </>
        )
    }, [item])

    const categoryStep = useMemo((): JSX.Element | undefined => {
        if (!category) {
            return undefined
        }
        return (
            <>
                <ExplanationText text="Extracted the" />
                <ExplanationChip text={category} />
                <ExplanationText text="category based on your input" />
            </>
        )
    }, [item])

    const mccStep = useMemo((): JSX.Element | undefined => {
        if (!categoryCode) {
            return undefined
        }
        return (
            <>
                <ExplanationText text="Extracted the" />
                <ExplanationChip text={categoryCode} />
                <ExplanationText text="merchant category code based on your input" />
            </>
        )
    }, [categoryCode])

    const genericTextExtractionInfo = useMemo((): JSX.Element => {
        return (
            <>
                <Text variant={'caption'} component={'p'} sx={{ color: 'white' }}>
                    Extracted using AI:
                </Text>
                <TooltipList items={['Model: Llama 3.1:8b']} />
            </>
        )
    }, [])

    const amountStep = useMemo((): JSX.Element => {
        const amountInInputCurrency = formatToCurrency(value.value, value.currency)
        return (
            <>
                <ExplanationText text="Extracted the amount of" />
                <ExplanationChip text={amountInInputCurrency} />
                <ExplanationText text="based on your input" />
            </>
        )
    }, [value])

    const countryStep = useMemo((): JSX.Element => {
        return (
            <>
                <ExplanationText text="Selected the" />
                <ExplanationChip
                    text={getCountryNameByThreeLetterCode(countryCode) ?? countryCode}
                />
                <ExplanationText text="country based on your input" />
            </>
        )
    }, [countryCode])

    const countryTextExtractionInfo = useMemo((): JSX.Element => {
        return (
            <>
                <Text variant={'caption'} component={'p'} sx={{ color: 'white' }}>
                    {`We matched your input where, to Lune's country:`}
                </Text>
                <TooltipList items={['Model: Llama 3.1:8b']} />
            </>
        )
    }, [])

    const emissionFactorStep = useMemo((): JSX.Element => {
        return (
            <>
                <ExplanationText text="Selected the" />
                <ExplanationChip text={emissionFactor.name} />
                <ExplanationText text="Emission Factor" />
            </>
        )
    }, [emissionFactor])

    const emissionFactorInfo = useMemo((): JSX.Element => {
        return (
            <>
                <Text variant={'caption'} component={'p'} sx={{ color: 'white' }}>
                    Based on line item description and category:
                </Text>
                <TooltipList items={['Model: GTE-small']} />
            </>
        )
    }, [])

    const exchangeRateStep = useMemo((): JSX.Element | undefined => {
        if (!exchangeRate || !exchangeRateDate) {
            return undefined
        }

        const amountInInputCurrency = formatToCurrency(
            lineItem.value.value,
            lineItem.value.currency,
        )
        const amountInConvertedCurrency = formatToCurrency(
            Big(lineItem.value.value).mul(exchangeRate).toString(),
            emissionFactor.denominatorUnit,
        )
        const formattedDate = moment(exchangeRateDate).format('D MMMM YYYY')

        return (
            <>
                <ExplanationText text="Converted" />
                <ExplanationChip text={amountInInputCurrency} />
                <ExplanationText text="to" />
                <ExplanationChip text={amountInConvertedCurrency} />
                <ExplanationText text="based on the" />
                <ExplanationChip text={formattedDate} />
                <ExplanationText text="exchange rate of" />
                <ExplanationChip text={exchangeRate} />
            </>
        )
    }, [exchangeRate, exchangeRateDate, emissionFactor, value])

    const multiplicationStep = useMemo((): JSX.Element => {
        return dietFactor ? (
            <ExplanationText text="Multiplied the adjusted Emission Factor by the amount" />
        ) : (
            <ExplanationText text="Multiplied the Emission Factor by the amount" />
        )
    }, [dietFactor])

    const steps: ExplanationStep[] = [
        ...(itemStep ? [{ description: itemStep, info: genericTextExtractionInfo }] : []),
        ...(categoryStep ? [{ description: categoryStep, info: genericTextExtractionInfo }] : []),
        ...(mccStep ? [{ description: mccStep, info: genericTextExtractionInfo }] : []),
        { description: amountStep, info: genericTextExtractionInfo },
        { description: countryStep, info: countryTextExtractionInfo },
        { description: emissionFactorStep, info: emissionFactorInfo },
        ...(exchangeRateStep ? [{ description: exchangeRateStep }] : []),
        { description: multiplicationStep },
    ]

    return (
        <>
            <Box>
                <ExplanationSteps steps={steps} />
            </Box>
            <Box>
                <EmissionFactorExplanation
                    emissionFactor={
                        emissionFactor as Omit<EmissionFactorWithGasEmissions, 'gasEmissions'> & {
                            gasEmissions: GasEmissions
                        }
                    }
                    amount={null}
                    label={emissionFactor.name}
                    {...(enableEmissionFactorLink
                        ? {
                              href: `/emission-factors/${emissionFactor.id}`,
                          }
                        : {})}
                />
            </Box>
        </>
    )
}
