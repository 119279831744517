import { LuneTheme, Text } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import { FC, useState } from 'react'

export interface ProjectTimelineProps {
    year: number
    description: string
    lastItem?: boolean
    key?: string
}

const WavyCircleSvg: FC<{ showWavyCircle: boolean }> = ({ showWavyCircle }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
            <rect width="256" height="256" fill="none" />
            {showWavyCircle ? (
                <path
                    className="svg-circle--wavy"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="8"
                    d="M54.46089,201.53911c-9.204-9.204-3.09935-28.52745-7.78412-39.85C41.82037,149.95168,24,140.50492,24,127.99963,24,115.4945,41.82047,106.048,46.67683,94.31079c4.68477-11.32253-1.41993-30.6459,7.78406-39.8499s28.52746-3.09935,39.85-7.78412C106.04832,41.82037,115.49508,24,128.00037,24c12.50513,0,21.95163,17.82047,33.68884,22.67683,11.32253,4.68477,30.6459-1.41993,39.8499,7.78406s3.09935,28.52746,7.78412,39.85C214.17963,106.04832,232,115.49508,232,128.00037c0,12.50513-17.82047,21.95163-22.67683,33.68884-4.68477,11.32253,1.41993,30.6459-7.78406,39.8499s-28.52745,3.09935-39.85,7.78412C149.95168,214.17963,140.50492,232,127.99963,232c-12.50513,0-21.95163-17.82047-33.68884-22.67683C82.98826,204.6384,63.66489,210.7431,54.46089,201.53911Z"
                />
            ) : (
                <circle
                    className="svg-circle"
                    cx="128"
                    cy="128"
                    r="100"
                    stroke="black"
                    strokeWidth="8"
                    fill="none"
                />
            )}
        </svg>
    )
}

const ProjectTimelineItem: FC<ProjectTimelineProps> = ({ year, description, lastItem, key }) => {
    const { palette } = LuneTheme

    const [spin, setSpin] = useState<boolean>(false)
    const [showWavyCircle, setShowWavyCircle] = useState<boolean>(false)
    const [spinReverse, setSpinReverse] = useState<boolean>(false)

    const itemSX = {
        width: '200px',
        mr: 3,
        mb: 1,
        pt: 2,
        transition: 'padding 200ms ease-in-out',
        '@keyframes rotating': {
            from: {
                transform: 'rotate(0deg)',
            },
            to: {
                transform: 'rotate(360deg)',
            },
        },
        '@keyframes rotating-reverse': {
            from: {
                transform: 'rotate(180deg)',
                opacity: 1,
            },
            to: {
                transform: 'rotate(0)',
                opacity: 0.9,
            },
        },
        '.timeline-item__description': {
            margin: '32px 0',
            display: 'block',
            transition: 'margin 200ms ease-in-out',
        },
        '.timeline-item__circle': {
            width: '32px',
            height: '32px',
            transform: 'rotate(0deg)',
            transition: 'transform 300ms ease-in',
            '&.timeline-item__circle--rotate-reverse': {
                transition: 'transform 300ms ease-in',
                animation: 'rotating-reverse 300ms linear',
            },
            '&.timeline-item__circle--rotate': {
                animation: 'rotating 3s linear infinite 300ms',
            },
        },
        '&:hover': {
            pt: 0,
            '.timeline-item__description': {
                margin: '40px 0',
            },
            '.timeline-item__circle': {
                transition: 'transform 300ms ease-out',
                transform: 'rotate(180deg)',
            },
        },
    }

    return (
        <Box
            key={key}
            onMouseEnter={() => {
                setSpin(true)
                setShowWavyCircle(true)
                setSpinReverse(false)
            }}
            onMouseLeave={() => {
                setSpinReverse(true)
                setSpin(false)
                setTimeout(() => {
                    setSpinReverse(false)
                    setShowWavyCircle(false)
                }, 300)
            }}
            sx={itemSX}
        >
            <Text variant={'h6'}>{year}</Text>
            <Text variant={'body3'} className={'timeline-item__description'}>
                {description}
            </Text>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    overflow: 'hidden',
                }}
            >
                <Box
                    className={`timeline-item__circle ${
                        spin ? 'timeline-item__circle--rotate' : ''
                    } ${spinReverse ? 'timeline-item__circle--rotate-reverse' : ''}`}
                >
                    <WavyCircleSvg showWavyCircle={showWavyCircle} />
                </Box>
                {!lastItem && (
                    <Box
                        sx={{
                            height: '1px',
                            width: '152px',
                            backgroundColor: palette.Grey900,
                        }}
                    />
                )}
            </Box>
        </Box>
    )
}

export default ProjectTimelineItem
