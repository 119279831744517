import type { SxProps } from '@mui/material'
import MuiButton from '@mui/material/Button'
import MUIButtonGroup from '@mui/material/ButtonGroup'
import { styled, useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { FC, ReactNode } from 'react'

import { LuneTheme } from '../theme'

const btnStyle = {
    height: '56px',
    padding: '0 12px',
    borderRadius: '8px',
    border: `none !important`,
    flex: 1,
}

const SelectedBtn = styled(MuiButton)(
    LuneTheme.unstable_sx({
        ...btnStyle,
        backgroundColor: `Grey100`,
        '&.Mui-disabled': {
            backgroundColor: `Grey100`,
        },
    }),
)

const Btn = styled(MuiButton)(
    LuneTheme.unstable_sx({
        ...btnStyle,
        backgroundColor: `White`,
        '&.Mui-disabled': {
            backgroundColor: `White`,
        },
    }),
)

/**
 * ButtonGroup uses width:100% and resizes its child buttons to be of equal size and to fit the available space.
 * @param value
 * @param items
 * @param onChange
 * @param disabled
 * @constructor
 */
const ButtonGroup: FC<{
    value: string
    items: {
        value: string
        label: string | ReactNode
        dataTestId?: string
    }[]
    onChange?: (value: string) => void
    disabled?: boolean
    sx?: SxProps
}> = ({ value, items, onChange, disabled, sx }) => {
    const theme = useTheme()

    return (
        <>
            <MUIButtonGroup
                sx={{
                    borderRadius: '8px',
                    outline: `2px ${theme.palette.Grey100} solid`,
                    width: '100%',
                    '.MuiButton-root:not(:last-of-type)': {
                        borderRight: `2px solid ${theme.palette.Grey100} !important`,
                    },
                    ...sx,
                }}
                variant="contained"
                color="inherit"
                disableElevation
                aria-label="outlined primary button group"
            >
                {items.map(({ value: btnValue, label, dataTestId }) =>
                    value === btnValue ? (
                        <SelectedBtn
                            data-testid={dataTestId}
                            key={btnValue}
                            onClick={() => onChange?.(btnValue)}
                            disabled={disabled}
                        >
                            <Typography
                                variant={`button`}
                                color={disabled ? `Grey500` : `Grey900`}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                    flexDirection: 'row',
                                }}
                            >
                                {label}
                            </Typography>
                        </SelectedBtn>
                    ) : (
                        <Btn
                            data-testid={dataTestId}
                            key={btnValue}
                            onClick={() => onChange?.(btnValue)}
                            disabled={disabled}
                        >
                            <Typography
                                variant={`button`}
                                color={`Grey500`}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                    flexDirection: 'row',
                                }}
                            >
                                {label}
                            </Typography>
                        </Btn>
                    ),
                )}
            </MUIButtonGroup>
        </>
    )
}

export default ButtonGroup
