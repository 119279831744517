import { useQuery } from 'react-query'

import { getUserOrganisations } from 'endpoints/dapi'
import { Organisation } from 'models/openDapi'
import { queryKeys } from 'queryKeys'

const useOrganisations = () => {
    const { isLoading: loading, data: organisations = [] } = useQuery<Organisation[]>(
        queryKeys.GET_USER_ORGANISATIONS,
        getUserOrganisations,
        {
            enabled: true,
        },
    )

    return {
        loading,
        organisations,
    }
}

export default useOrganisations
