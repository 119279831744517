import { InfiniteData, useInfiniteQuery } from 'react-query'

import { getCsvSheets } from 'endpoints/dapi'
import { CsvSheet } from 'models/openDapi'
import { queryKeys } from 'queryKeys'

// TODO: Run the codegen script to update types once dapi is up to date
type PaginatedCsvSheets = { hasMore: boolean; data: CsvSheet[] }

const useCsvSheets = (
    type: 'fintech' | 'logistics',
): {
    paginatedCsvSheets: InfiniteData<PaginatedCsvSheets> | undefined
    loading: boolean
    fetchNextPage: () => void
    refetch: () => void
    hasNextPage: boolean | undefined
} => {
    const {
        data: paginatedCsvSheets,
        isLoading,
        fetchNextPage,
        hasNextPage,
        refetch,
    } = useInfiniteQuery<PaginatedCsvSheets>({
        queryKey: queryKeys.GET_CSV_SHEETS,
        queryFn: async ({ pageParam = { cursor: undefined } }) => {
            return getCsvSheets({ after: pageParam.cursor }, type)
        },
        getNextPageParam: (lastPage) => {
            return lastPage.hasMore
                ? { cursor: lastPage.data[lastPage.data.length - 1].id }
                : undefined
        },
        refetchOnMount: true,
    })

    return {
        paginatedCsvSheets,
        loading: isLoading,
        fetchNextPage,
        refetch,
        hasNextPage,
    }
}

export default useCsvSheets
