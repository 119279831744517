import { once } from 'lodash'
import { useEffect, useState } from 'react'
import { QueryObserver, useQueryClient } from 'react-query'

import { queryKeys } from 'queryKeys'

export default function useHasUnsavedChanges() {
    const queryClient = useQueryClient()

    const refreshHasUnsavedChangesState = () => {
        queryClient.invalidateQueries(queryKeys.HAS_UNSAVED_CHANGES)
    }

    /**
     * Store whether there are unsaved changes in a globally accessible state
     * This state can then be accessed by DiscardChangesConfirmation-type components
     * @param hasChanges - Whether there are unsaved changes
     * @param unsavedChangesCallback - optional callback for cleanup actions. Needs to be called explicitly.
     * The callback can only be called once - subsequent calls will be ignored - you'll have to register it again.
     * This prevents unexpected behavior if we forget to unregister it.
     * BEWARE: callbacks with side effects (e.g. triggering re-renders in components) can lead to unexpected behaviour
     */
    const setHasUnsavedChangesState = (
        hasChanges: boolean,
        unsavedChangesCallback?: () => void,
    ) => {
        queryClient.setQueryData(queryKeys.HAS_UNSAVED_CHANGES, {
            hasChanges,
            unsavedChangesCallback,
        })
    }

    const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false)
    const [unsavedChangesCallback, setUnsavedChangesCallback] = useState<(() => void) | undefined>()

    useEffect(() => {
        setHasUnsavedChanges(false)
        const observer = new QueryObserver(queryClient, {
            queryKey: queryKeys.HAS_UNSAVED_CHANGES,
            queryFn: () => Promise.resolve(),
        })
        const unsubscribe = observer.subscribe((result) => {
            const data = result.data as
                | {
                      hasChanges: boolean
                      unsavedChangesCallback?: () => void
                  }
                | undefined

            if (!data) {
                return
            }
            setHasUnsavedChanges(data.hasChanges)
            if (data.unsavedChangesCallback !== undefined) {
                // Designing the unsavedChangesCallback to be called only once
                // This prevents unexpected behavior if we forget to unregister it
                const onConfirmCallbackCallableOnlyOnce = once(data.unsavedChangesCallback)
                setUnsavedChangesCallback(() => onConfirmCallbackCallableOnlyOnce)
            }
        })
        return () => {
            unsubscribe()
        }
    }, [queryClient])

    return {
        hasUnsavedChanges,
        setHasUnsavedChangesState,
        refreshHasUnsavedChangesState,
        unsavedChangesCallback,
    }
}
