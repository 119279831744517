import { EditType, OrderType } from 'models/order'
import { BuyOffsetProps } from 'views/BuyOffsets/BuyOffsetsTypes'

// Initial global state object for BuyOffsets
export const initialState: BuyOffsetProps = {
    amount: '',
    orderType: OrderType.QUANTITY,
    editType: EditType.EDIT_BY_PERCENTAGE as string,
    truncateToTonnes: true,
    quote: undefined,
    error: undefined,
    allocation: [],
    insufficientBundlesError: undefined,
    isCustomBundleVolumeSet: false,
    customVolumes: [],
    isCustomBundlePercentageSet: false,
    customPercentages: [],
    selectedBundles: [],
    orderTotalAmount: 0,
    orderTotalPercentage: 0,
    isCustomPortfolioCartOpen: false,
    selectedPortfolioId: undefined,
}

export default initialState
