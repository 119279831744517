import {
    EmissionFactorWithGasEmissions,
    GasEmissions,
    PassengerTransportationEmissionEstimate,
} from '@lune-climate/lune'
import { Box } from '@mui/material'
import { FC, useMemo } from 'react'

import { formatNumbers } from '../../utils/utils'

import EmissionFactorExplanation from './EmissionFactorExplanation'
import ExplanationChip from './ExplanationChip'
import ExplanationSteps, { ExplanationText } from './ExplanationSteps'

interface PassengerTransportationEmissionExplanationProps {
    estimate: PassengerTransportationEmissionEstimate
    leg: number // 0-indexed
    enableEmissionFactorLink?: boolean
}

function getDistanceExplanationText(
    requestLeg: PassengerTransportationEmissionEstimate['request']['legs'][number],
): string {
    // TODO: extract from API response (does not have them)
    if ('vehicleType' in requestLeg) {
        return 'Mapbox'
    }

    if ('railType' in requestLeg) {
        // sad
        return 'Mapbox'
    }

    return 'Great Circle Distance'
}

export const PassengerTransportationEmissionExplanation: FC<
    PassengerTransportationEmissionExplanationProps
> = ({ estimate, leg, enableEmissionFactorLink }) => {
    const { request: estimateRequest } = estimate

    if (!estimate.legs[leg].distance || !estimate.legs[leg].emissionFactor) {
        throw new Error(
            'PassengerTransportationEmissionExplanation does not support estimates without distance or emission factor',
        )
    }

    const distance = estimate.legs[leg].distance
    const emissionFactor = estimate.legs[leg].emissionFactor

    const formattedDistance = formatNumbers(distance.amount, 2)
    const distanceExplanation = (
        <>
            <ExplanationText text="Calculated a distance of" />
            <ExplanationChip text={formattedDistance + ' km'} />
            <ExplanationText text="using" />
            <ExplanationChip text={getDistanceExplanationText(estimateRequest.legs[leg])} />
        </>
    )

    const emissionFactorExplanation = useMemo((): JSX.Element => {
        return (
            <>
                <ExplanationText text="Selected the" />
                <ExplanationChip text={emissionFactor.name} />
                <ExplanationText text="Emission Factor" />
            </>
        )
    }, [emissionFactor])

    const formulaExplanation = (
        <ExplanationText text="Multiplied the Emission Factor by the amount" />
    )

    const steps: { description?: JSX.Element }[] = [
        { description: distanceExplanation },
        { description: emissionFactorExplanation },
        { description: formulaExplanation },
    ]

    return (
        <>
            <ExplanationSteps steps={steps} />
            <Box>
                <EmissionFactorExplanation
                    emissionFactor={
                        emissionFactor as Omit<EmissionFactorWithGasEmissions, 'gasEmissions'> & {
                            gasEmissions: GasEmissions
                        }
                    }
                    amount={null}
                    label={emissionFactor.name}
                    href={
                        enableEmissionFactorLink
                            ? `/emission-factors/${emissionFactor.id}`
                            : undefined
                    }
                />
            </Box>
        </>
    )
}

export default PassengerTransportationEmissionExplanation
