import { sortBy } from 'lodash'
import { useQuery } from 'react-query'
import { UseQueryOptions } from 'react-query/types/react/types'

import { getApiKeys } from 'endpoints/dapi'
import { IApiKey } from 'models/apiKey'
import { queryKeys } from 'queryKeys'

const useApiKeys = (options?: UseQueryOptions<any>) => {
    const {
        isLoading,
        data: apiKeys,
        refetch,
    } = useQuery<IApiKey[]>(
        queryKeys.GET_API_KEYS,
        () =>
            getApiKeys().then((res) => sortBy(res, (apiKey: IApiKey) => apiKey.name.toLowerCase())),
        options,
    )

    return {
        refetch,
        isLoadingApiKeys: isLoading,
        apiKeys,
    }
}

export default useApiKeys
