import { Text } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'
import { FC } from 'react'

const SingleColorItem = ({ color, label }: { color: string; label: string }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 1,
            }}
        >
            <Box
                sx={{
                    borderRadius: '100%',
                    width: '20px',
                    height: '20px',
                    backgroundColor: color,
                }}
            />
            <Text variant={'caption'}>{label}</Text>
        </Box>
    )
}

const MultiColorItem = ({ colors, labels }: { colors: string[]; labels: string[] }) => {
    function generateDynamicGradient(colors: string[]): string {
        const totalColors = colors.length
        const gradientStops = colors.map((color, index) => {
            const positionPercentage = (index / (totalColors - 1)) * 100
            return `${color} ${positionPercentage.toFixed(2)}%`
        })

        return gradientStops.join(', ')
    }

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 1,
                width: '232px',
                flexDirection: 'column',
            }}
        >
            <Box
                sx={{
                    borderRadius: '20px',
                    width: '100%',
                    height: '8px',
                    background: `linear-gradient(90deg, ${generateDynamicGradient(colors)})`,
                }}
            />
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                }}
            >
                <Text variant={'caption'}>{labels[0]}</Text>
                <Text variant={'caption'}>{labels[1]}</Text>
            </Box>
        </Box>
    )
}

const MapLegend: FC<{ legend: { color: string[]; label: string[] }[] }> = ({ legend }) => {
    return (
        <Box
            sx={{
                position: 'absolute',
                left: '24px',
                bottom: '32px',
                padding: 1,
                background: 'white',
                borderRadius: '12px',
                display: 'flex',
                gap: '12px',
                flexDirection: 'column',
            }}
        >
            {legend.map((l, index) => {
                if (l.color.length > 1) {
                    return <MultiColorItem key={index} colors={l.color} labels={l.label} />
                } else {
                    return <SingleColorItem key={index} color={l.color[0]} label={l.label[0]} />
                }
            })}
        </Box>
    )
}

export default MapLegend
