import { Dropdown, LuneTheme, Slider, Text } from '@lune-fe/lune-ui-lib'
import { Box, SxProps } from '@mui/material'
import _ from 'lodash'
import { memo, useEffect, useState } from 'react'
import { debounce } from 'throttle-debounce'

import useAccountCurrency from 'hooks/useAccountCurrency'

export interface PriceRangeFilterProps {
    min: number
    max: number
    value: [number, number]
    setValue: (newValue: [number, number]) => void
    sx?: SxProps
    setExternalIsEmpty?: (isEmpty: boolean) => void
}

const PriceRangeFilter = ({
    sx,
    min,
    max,
    value,
    setValue,
    setExternalIsEmpty,
}: PriceRangeFilterProps) => {
    const { palette } = LuneTheme
    const toCurrency = useAccountCurrency(true)
    const [isEmpty, setIsEmpty] = useState<boolean>(true)
    const [localValue, setLocalValue] = useState<[number, number]>(value)

    useEffect(() => {
        const isEmpty = min === value[0] && max === value[1]
        setIsEmpty(isEmpty)
        setExternalIsEmpty?.(isEmpty)
    }, [min, max, value, setExternalIsEmpty])

    const isDefaultValue = _.isEqual(value, localValue)
    useEffect(() => {
        if (!isDefaultValue) {
            setLocalValue(value)
        }
    }, [isDefaultValue, value])

    const updatePriceDebounce = debounce(
        300,
        (newVal) => {
            if (!_.isEqual(newVal, value)) {
                setValue(newVal)
            }
        },
        { atBegin: true },
    )

    return (
        <Dropdown
            dataTestId={'price-range-filter'}
            closeOnClick={false}
            labelSx={{ color: isEmpty ? palette.Grey600 : palette.Grey900 }}
            buttonLabel={
                isEmpty ? 'Price' : `${toCurrency?.(value[0])} - ${toCurrency?.(value[1])}`
            }
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                ...sx,
            }}
        >
            <Box sx={{ p: 3 }}>
                <Text variant={'button'} sx={{ mb: 4, display: 'block' }}>
                    {isEmpty
                        ? 'Any price'
                        : `${toCurrency?.(localValue[0])} - ${toCurrency?.(localValue[1])}`}
                </Text>
                <Slider
                    value={localValue}
                    onChange={(_, newVal) => {
                        updatePriceDebounce(newVal)
                        setLocalValue(newVal as [number, number])
                    }}
                    step={5}
                    valueLabelFormat={(val) => `${toCurrency?.(val)}`}
                    valueLabelDisplay={'auto'}
                    min={min}
                    max={max}
                />
            </Box>
        </Dropdown>
    )
}

export default memo(PriceRangeFilter, (prev, next) => {
    return _.isEqual(prev.value, next.value)
})
