import { CtaTile, MainLayoutContainer, Text } from '@lune-fe/lune-ui-lib'
import { useNavigate } from 'react-router-dom'

import { useIsAuthorised } from 'hooks/useUserState'
import Logo from 'images/SustainabilityPage.png'

const Widgets = () => {
    const navigate = useNavigate()
    const isAdmin = useIsAuthorised()

    return (
        <MainLayoutContainer headerComponent={<Text variant={'h4'}>Widgets</Text>}>
            {isAdmin && (
                <CtaTile
                    title={`Sustainability Page`}
                    subTitle={'Customise your public Sustainability Page'}
                    image={Logo}
                    onClick={() => navigate(`/widgets/sustainability-page`)}
                />
            )}
        </MainLayoutContainer>
    )
}

export default Widgets
