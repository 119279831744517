import { Text } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import { FC, ReactNode } from 'react'

const FormBox: FC<{ title: string; children: ReactNode }> = ({ children, title }) => {
    return (
        <Box
            sx={{
                p: 4,
                width: '100%',
                maxWidth: '448px',
                borderRadius: '32px',
                backgroundColor: `white`,
                display: 'flex',
                justifyContent: 'center',
                boxSizing: 'border-box',
                flexDirection: 'column',
                '*': {
                    boxSizing: 'border-box',
                },
            }}
        >
            <Text variant={'h4'} sx={{ mb: 4 }}>
                {title}
            </Text>
            {children}
        </Box>
    )
}

export default FormBox
