import { Bundle } from '@lune-climate/lune'
import { BackButton, Text } from '@lune-fe/lune-ui-lib'
import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import BundlesLayout from 'components/BundlesLayout'
import DiscardChangesConfirmation from 'components/DiscardChangesConfirmation'
import useActiveAccount from 'hooks/useActiveAccount'
import useBundles from 'hooks/useBundles'
import useBuyOffsetsState from 'hooks/useBuyOffsetsState'
import useHasUnsavedChanges from 'hooks/useHasUnsavedChanges'
import useQuery from 'hooks/useQuery'
import useUpdateEffect from 'hooks/useUpdateEffect'
import BuyOffsetsBundleCard from 'views/BuyOffsets/BuyOffsetsBundleCard'
import CartButton from 'views/BuyOffsets/CartButton'
import CustomPortfolioCartModalController from 'views/BuyOffsets/CustomPortfolioCartModalController'

/*
 * Component to manage the "Select Bundles" view where you select what bundles you want to add to your portfolio
 * by clicking on the bundle cards. It's responsible for managing the state of the selected bundles and passing
 * that to the Global State.
 */
const BuildPortfolio = () => {
    const navigate = useNavigate()
    const { setHasUnsavedChangesState } = useHasUnsavedChanges()
    const { loading, bundles } = useBundles()
    const { activeAccount } = useActiveAccount()

    const { buyOffsetProps, setBuyOffsetProps, resetBuyOffsetProps } = useBuyOffsetsState()
    const { selectedBundles, isCustomPortfolioCartOpen } = buyOffsetProps

    const [buyNowBundleSelection, setBuyNowBundleSelection] = useState<Bundle>()
    const [localSelectedBundles, setLocalSelectedBundles] = useState<Bundle[]>(selectedBundles)

    const [openDiscardChangesModal, setOpenDiscardChangesModal] = useState<boolean>(false)
    const [preselectedBundleId] = useQuery('bundleId')

    // used when navigating from within a project
    const [projectSlug] = useQuery('projectSlug')

    useEffect(() => {
        setBuyOffsetProps({
            selectedBundles: localSelectedBundles,
        })
    }, [localSelectedBundles, setBuyOffsetProps])

    const toggleBundleSelection = (bundle: Bundle) => {
        setBuyNowBundleSelection(undefined)
        if (localSelectedBundles.find((b) => b.id === bundle.id)) {
            setLocalSelectedBundles(localSelectedBundles.filter((b) => b.id !== bundle.id))
        } else {
            setLocalSelectedBundles([...localSelectedBundles, bundle])
        }
    }

    useUpdateEffect(() => {
        // reset selected bundles and BuyOffsetProps state when changing account
        // to avoid mixing bundle prices and currencies
        setLocalSelectedBundles([])
        resetBuyOffsetProps()
    }, [activeAccount])

    useEffect(() => {
        if (preselectedBundleId && !!bundles.length) {
            const preselectedBundle = bundles.find((b) => b.id === preselectedBundleId)
            if (preselectedBundle) {
                resetBuyOffsetProps()
                setLocalSelectedBundles([preselectedBundle])
                setBuyOffsetProps({ isCustomPortfolioCartOpen: true })
            }
        }
    }, [preselectedBundleId, bundles, resetBuyOffsetProps, setBuyOffsetProps])

    return (
        <>
            <BundlesLayout
                loading={loading}
                bundles={bundles}
                stickyHeader={
                    <>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <BackButton
                                sx={{ ml: -2 }}
                                onClick={() => {
                                    if (preselectedBundleId && projectSlug) {
                                        navigate(`/projects/${projectSlug}`)
                                    } else {
                                        navigate('/order')
                                    }
                                }}
                            />
                            <CartButton
                                numberOfSelectedBundles={localSelectedBundles.length}
                                onClick={() =>
                                    setBuyOffsetProps({ isCustomPortfolioCartOpen: true })
                                }
                            />
                        </Box>
                    </>
                }
                header={
                    <>
                        <Text variant="h4" sx={{ mb: 1 }}>
                            Buy one or more project bundles
                        </Text>
                        <Text variant={'body3'} sx={{ color: `Grey600` }}>
                            The simplest way to buy carbon with confidence
                        </Text>
                    </>
                }
            >
                <BuyOffsetsBundleCard
                    selectedBundles={localSelectedBundles}
                    onAddToPortfolioClick={(bundle: Bundle) => {
                        toggleBundleSelection(bundle)
                    }}
                    onBuyNowClick={(bundle: Bundle) => {
                        setBuyNowBundleSelection(bundle)
                        if (
                            !localSelectedBundles.length ||
                            (localSelectedBundles.length === 1 &&
                                localSelectedBundles[0].id === bundle.id)
                        ) {
                            setLocalSelectedBundles([bundle])
                            setBuyOffsetProps({ isCustomPortfolioCartOpen: true })
                        } else {
                            setOpenDiscardChangesModal(true)
                        }
                    }}
                />
            </BundlesLayout>

            {isCustomPortfolioCartOpen && (
                <CustomPortfolioCartModalController
                    onCloseModal={() => {
                        setBuyOffsetProps({ isCustomPortfolioCartOpen: false })
                    }}
                    onRemoveBundle={(bundle: Bundle) =>
                        setLocalSelectedBundles((prev) => prev.filter((b) => b.id !== bundle.id))
                    }
                />
            )}

            {openDiscardChangesModal && buyNowBundleSelection && (
                <DiscardChangesConfirmation
                    onConfirm={() => {
                        setOpenDiscardChangesModal(false)
                        setHasUnsavedChangesState(false)
                        setLocalSelectedBundles([buyNowBundleSelection])
                        setBuyOffsetProps({ isCustomPortfolioCartOpen: true })
                    }}
                    onCancel={() => {
                        setBuyNowBundleSelection(undefined)
                        setOpenDiscardChangesModal(false)
                    }}
                />
            )}
        </>
    )
}

export default BuildPortfolio
