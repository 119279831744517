import Box from '@mui/material/Box'
import moment from 'moment'
import { FC, useEffect, useMemo, useState } from 'react'
import { DateRangePicker, Range } from 'react-date-range'

import { LuneTheme } from '../theme'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

export interface DateRangePickerComponentProps {
    onChange: (range: Range) => void
    predefinedRange?: PredefinedRange
}

export interface PredefinedRange {
    startDate: Date
    endDate: Date
}

const DateRangePickerComponent: FC<DateRangePickerComponentProps> = ({
    predefinedRange,
    onChange,
}) => {
    const { typography, palette } = LuneTheme
    const todayUTC = useMemo(() => moment().utc(), [])
    const [state, setState] = useState<Range>({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    })

    const [startDate, setStartDate] = useState<Date | undefined>(undefined)
    const [endDate, setEndDate] = useState<Date | undefined>(undefined)

    useEffect(() => {
        if (predefinedRange) {
            setState({
                startDate: predefinedRange.startDate,
                endDate: predefinedRange.endDate,
                key: 'selection',
            })
        }
    }, [predefinedRange])

    const changeDateRange = (selection: Range): void => {
        if (!startDate && !endDate) {
            setStartDate(selection.startDate)
        } else if (startDate && endDate) {
            setStartDate(selection.startDate)
            setEndDate(undefined)
        } else {
            setStartDate(selection.startDate)
            setEndDate(selection.endDate)
        }
    }

    useEffect(() => {
        if (startDate && endDate) {
            onChange({
                startDate,
                endDate,
            })
        }
    }, [endDate, startDate])

    return (
        <Box
            sx={{
                '.rdrDateDisplayWrapper': {
                    display: 'none',
                },
                '.rdrDefinedRangesWrapper': {
                    display: 'none',
                },
                '.rdrCalendarWrapper': {
                    fontFamily: typography.fontFamily,
                    fontSize: '14px',
                    color: palette.Grey900,
                    position: 'relative',
                },
                '.rdrWeekDay': {
                    textAlign: 'center',
                    fontSize: '14px',
                    color: palette.Grey900,
                    fontWeight: 500,
                },
                '.rdrMonthName': {
                    textAlign: 'center',
                    fontSize: '14px',
                    color: palette.Grey900,
                    fontWeight: 500,
                    padding: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '36px',
                },
                '.rdrDayInPreview': {
                    borderTop: '2px dashed',
                    borderBottom: '2px dashed',
                },
                '.rdrDayEndPreview': {
                    border: '2px dashed',
                    borderLeft: 'none',
                },
                '.rdrDayStartPreview': {
                    border: '2px dashed',
                    borderRight: 'none',
                },
                '.rdrDayStartPreview.rdrDayEndPreview': {
                    border: '2px dashed',
                },
                '.rdrDay, .rdrSelected, .rdrStartEdge, .rdrEndEdge, .rdrDayStartPreview, .rdrDayEndPreview, .rdrDayInPreview':
                    {
                        color: `${palette.Blue50} !important`,
                        top: '0 !important',
                        left: '0 !important',
                        right: '0 !important',
                        bottom: '0 !important',
                    },
                '.rdrInRange': {
                    color: `${palette.Blue50} !important`,
                },
                '.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span': {
                    color: `${palette.Grey900} !important`,
                },
                '.rdrStartEdge ~.rdrDayNumber, .rdrEndEdge ~.rdrDayNumber': {
                    backgroundColor: `${palette.Blue600} !important`,
                    borderRadius: '100% !important',
                },
                '.rdrDayPassive .rdrDayNumber': {
                    backgroundColor: 'white !important',
                },
                '.rdrMonth': {
                    width: '252px',
                    padding: 0,
                    '&:not(:last-of-type)': {
                        paddingRight: '24px',
                    },
                },
                '.rdrDay': {
                    height: '36px',
                    width: '36px',
                    lineHeight: '36px',
                    marginBottom: '2px',
                    '*': {
                        height: '36px',
                        width: '36px',
                        lineHeight: '36px',
                        boxSizing: 'border-box',
                        fontWeight: '400 !important',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                    },
                },
                '.rdrNextPrevButton': {
                    height: '36px',
                    width: '36px',
                    textAlign: 'center',
                    borderRadius: '100%',
                    background: 'none',
                    '&:hover': {
                        backgroundColor: palette.Grey100,
                    },
                },
                '.rdrNextPrevButton i': {
                    display: 'inline-block',
                    width: '6px',
                    height: '6px',
                    transform: 'rotate(-225deg)',
                    borderStyle: 'unset',
                    margin: '0 !important',
                },
                '.rdrPprevButton i': {
                    borderRight: `2px solid ${palette.Grey500}`,
                    borderBottom: `2px solid ${palette.Grey500}`,
                },
                '.rdrNextButton i': {
                    borderLeft: `2px solid ${palette.Grey500}`,
                    borderTop: `2px solid ${palette.Grey500}`,
                },
                '.rdrDayToday span:after': {
                    display: 'none',
                },
                '.rdrMonthAndYearPickers': {
                    display: 'none',
                },
                '.rdrMonthAndYearWrapper': {
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    padding: 0,
                    height: '36px',
                },
                '.rdrDay.rdrDayDisabled:not(.rdrDayPassive) .rdrInRange~.rdrDayNumber span': {
                    color: `${palette.Grey300} !important`,
                },
                '.rdrDay.rdrDayDisabled .rdrDayNumber span': {
                    color: `${palette.Grey300} !important`,
                    background: palette.White,
                },
                '.rdrDayPassive': {
                    opacity: 0,
                },
            }}
        >
            <DateRangePicker
                onChange={(i) => {
                    setState(i.selection)
                    changeDateRange(i.selection)
                }}
                months={2}
                ranges={[state]}
                direction="horizontal"
                maxDate={todayUTC.toDate()}
                color={palette.Blue50}
                weekStartsOn={1}
                weekdayDisplayFormat={'EEEEE'}
                preventSnapRefocus={true}
                calendarFocus="backwards"
            />
        </Box>
    )
}

export default DateRangePickerComponent
