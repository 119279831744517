import { CountTimeseries } from '@lune-climate/lune'
import { FC } from 'react'
import { Range } from 'react-date-range'

import Divider from 'components/Divider'
import EmissionCalculationsChart from 'views/Dashboard/Charts/EmissionCalculationsChart'

const EmissionCalculationAnalytics: FC<{
    dateRange: Range
    data?: CountTimeseries
}> = ({ dateRange, data }) => {
    return (
        <>
            {data && (
                <>
                    <EmissionCalculationsChart data={data} dateRange={dateRange} />
                    <Divider />
                </>
            )}
        </>
    )
}

export default EmissionCalculationAnalytics
