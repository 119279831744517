import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

function extractNavPath(pathname: string): string {
    const path = pathname.split('/')

    if (path.length === 0) {
        return ''
    }
    if (path.length === 1) {
        return '/'
    }

    return path.slice(0).join('/')
}

export default function useActiveRoutePath() {
    const [activeRoutePath, setActiveRoutePath] = useState<string>('')
    const location = useLocation()

    useEffect(() => {
        setActiveRoutePath(extractNavPath(location.pathname))
    }, [location])

    return activeRoutePath
}
