import { InfiniteData, useInfiniteQuery } from 'react-query'

import { getAccounts } from 'endpoints/dapi'
import useActiveOrganisation from 'hooks/useActiveOrganisation'
import { IAccount } from 'models/account'
import { IPaginationResult } from 'models/pagination'
import { queryKeys } from 'queryKeys'

const useAllOrganisationAccounts = ({
    limit,
}: {
    limit?: number
}): {
    paginatedAccounts: InfiniteData<IPaginationResult<IAccount>> | undefined
    loading: boolean
    fetchNextPage: () => void
    refetch: () => void
    hasNextPage: boolean | undefined
    refetching: boolean
} => {
    const { activeOrg } = useActiveOrganisation()

    const {
        data: paginatedAccounts,
        fetchNextPage,
        hasNextPage,
        refetch,
        isRefetching,
        isLoading,
    } = useInfiniteQuery<IPaginationResult<IAccount>>({
        queryKey: queryKeys.GET_ALL_ORGANISATION_ACCOUNTS,
        enabled: !!activeOrg,
        queryFn: async ({ pageParam = { cursor: undefined } }) => {
            return getAccounts({ after: pageParam.cursor, limit })
        },
        getNextPageParam: (lastPage) => {
            return lastPage.hasMore
                ? { cursor: lastPage.data[lastPage.data.length - 1].id }
                : undefined
        },
    })

    return {
        paginatedAccounts,
        loading: isLoading,
        refetching: isRefetching,
        fetchNextPage,
        refetch,
        hasNextPage,
    }
}

export default useAllOrganisationAccounts
