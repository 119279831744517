import Avatar from '@mui/material/Avatar'
import { FC } from 'react'

const BundleAvatar: FC<{ name: string; image?: string }> = ({ name, image }) => (
    <Avatar
        alt={name}
        src={image}
        variant={`rounded`}
        sx={{
            width: `48px`,
            height: `48px`,
            borderRadius: `8px`,
        }}
    />
)

export default BundleAvatar
