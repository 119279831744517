import { useEffect } from 'react'
import { useQuery } from 'react-query'

import { AccountMini } from 'components/AccountFilter'
import { getRecentlyUsedAccounts } from 'endpoints/dapi'
import useActiveAccount from 'hooks/useActiveAccount'
import { queryKeys } from 'queryKeys'

const useRecentlyUsedAccounts = () => {
    const { activeAccount } = useActiveAccount()
    const mode = activeAccount?.type

    const {
        isLoading: loadingRecentlyUsedAccounts,
        data: recentlyUsedAccounts,
        refetch,
    } = useQuery<AccountMini[]>(
        queryKeys.RECENTLY_USED_ACCOUNTS,
        () => getRecentlyUsedAccounts({ type: mode }),
        {
            enabled: !!mode,
        },
    )

    useEffect(() => {
        if (mode) {
            refetch()
        }
    }, [mode, refetch])

    return {
        loadingRecentlyUsedAccounts,
        recentlyUsedAccounts,
    }
}

export default useRecentlyUsedAccounts
