import { BundleSummary, CarbonPermanence } from '@lune-climate/lune'
import { countriesList, Textarea } from '@lune-fe/lune-ui-lib'
import { Field } from 'react-final-form'

import { CertificationType } from 'views/Projects/ProjectBundles/BundleFiltersBox'
import ProposalRequestLabeledFormSection from 'views/Proposals/ProposalRequestForm/ProposalRequestLabeledFormSection'
import { RangeInputs } from 'views/Proposals/ProposalRequestForm/RangeInputs'
import { SelectWithMustHaveOption } from 'views/Proposals/ProposalRequestForm/SelectWithMustHaveOption'
import { ProjectTypesWithLabels } from 'views/Proposals/ProposalRequestForm/shared'

const geographiesList = countriesList.map((c) => ({
    value: c[2],
    label: c[0],
}))

export const offsetTypeList = [
    {
        value: BundleSummary.offset_type.CARBON_REMOVAL,
        label: 'Carbon Removal',
    },
    {
        value: BundleSummary.offset_type.EMISSIONS_REDUCTION,
        label: 'Emission Reduction',
    },
]

export const carbonPermanenceList = [
    {
        value: CarbonPermanence.NO_STORAGE,
        label: 'No storage',
    },
    {
        value: CarbonPermanence.SHORT_TERM,
        label: 'Short-term storage',
    },
    {
        value: CarbonPermanence.LONG_TERM,
        label: 'Long-term storage',
    },
]

const registryList = Object.values(CertificationType).map((i) => ({
    value: i,
    label: i,
}))

const projectTypeList = Object.entries(ProjectTypesWithLabels).map(([key, label]) => ({
    value: key,
    label,
}))

export const ProjectRequirementsStep = () => {
    return (
        <>
            <ProposalRequestLabeledFormSection label={'Number of projects'}>
                <Field name={'numberOfBundles'}>
                    {({ input: { onChange, value } }: any) => (
                        <RangeInputs
                            onChange={(val) => {
                                onChange(val)
                            }}
                            value={value}
                        />
                    )}
                </Field>
            </ProposalRequestLabeledFormSection>
            <ProposalRequestLabeledFormSection label={'Geographies to prioritise'}>
                <Field name={'geographiesIncluded'}>
                    {({ input: { onChange, value } }: any) => (
                        <SelectWithMustHaveOption
                            dataTestId={`prop-request-geographies-included-dropdown`}
                            placeholder={'Search for countries'}
                            onChange={(val) => {
                                onChange(val)
                            }}
                            value={value}
                            items={geographiesList}
                        />
                    )}
                </Field>
            </ProposalRequestLabeledFormSection>
            <ProposalRequestLabeledFormSection label={'Geographies to exclude'}>
                <Field name={'geographiesExcluded'}>
                    {({ input: { onChange, value } }: any) => (
                        <SelectWithMustHaveOption
                            placeholder={'Search for countries'}
                            onChange={(val) => {
                                onChange(val)
                            }}
                            value={value}
                            items={geographiesList}
                        />
                    )}
                </Field>
            </ProposalRequestLabeledFormSection>
            <ProposalRequestLabeledFormSection label={'Project characteristics'}>
                <Field name={'offsetType'}>
                    {({ input: { onChange, value } }: any) => (
                        <SelectWithMustHaveOption
                            placeholder={'Both reductions and removals'}
                            onChange={(val) => {
                                onChange(val)
                            }}
                            value={value}
                            items={offsetTypeList}
                        />
                    )}
                </Field>
                <Field name={'carbonPermanence'}>
                    {({ input: { onChange, value } }: any) => (
                        <SelectWithMustHaveOption
                            dataTestId={`prop-request-carbon-permanence-dropdown`}
                            placeholder={'Any carbon storage timeframe'}
                            onChange={(val) => {
                                onChange(val)
                            }}
                            value={value}
                            items={carbonPermanenceList}
                        />
                    )}
                </Field>
                <Field name={'registry'}>
                    {({ input: { onChange, value } }: any) => (
                        <SelectWithMustHaveOption
                            placeholder={'With any certification'}
                            onChange={(val) => {
                                onChange(val)
                            }}
                            value={value}
                            items={registryList}
                        />
                    )}
                </Field>
                <Field name={'projectType'}>
                    {({ input: { onChange, value } }: any) => (
                        <SelectWithMustHaveOption
                            placeholder={'Any project type'}
                            onChange={(val) => {
                                onChange(val)
                            }}
                            value={value}
                            items={projectTypeList}
                        />
                    )}
                </Field>
            </ProposalRequestLabeledFormSection>
            <ProposalRequestLabeledFormSection label={'Anything else?'}>
                <Field name={'additionalComment'}>
                    {({ input: { onChange, value } }: any) => (
                        <Textarea
                            value={value}
                            onChange={onChange}
                            wide
                            rows={5}
                            placeholder={'Anything else you want us to take into account...'}
                        />
                    )}
                </Field>
            </ProposalRequestLabeledFormSection>
        </>
    )
}

export default ProjectRequirementsStep
