import { ButtonGroup } from '@lune-fe/lune-ui-lib'
import { FC } from 'react'

import useAccounts from 'hooks/useAccounts'
import { getCurrencySymbol } from 'models/currency'
import { OrderType } from 'models/order'

const OrderTypeChooser: FC<{
    selectedType: string
    onChange: (type: OrderType) => void
}> = ({ selectedType, onChange }) => {
    const { activeAccount } = useAccounts()
    return (
        <ButtonGroup
            sx={{
                height: '54px !important',
                overflow: 'hidden',
                flex: '1 1 50%',
            }}
            items={[
                {
                    label: 'tCO₂',
                    value: OrderType.QUANTITY,
                    dataTestId: 'by-volume-btn',
                },
                {
                    label: getCurrencySymbol(activeAccount?.currency || ''),
                    value: OrderType.VALUE,
                    dataTestId: 'by-value-btn',
                },
            ]}
            onChange={onChange as (type: string) => void}
            value={selectedType}
        />
    )
}

export default OrderTypeChooser
