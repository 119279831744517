import { FC, ReactElement } from 'react'

const ConditionalTooltip = ({
    show,
    tooltip,
    contentWidth,
    children,
}: {
    show: boolean
    tooltip: FC<{ children: ReactElement; contentWidth?: boolean }>
    contentWidth?: boolean
    children: ReactElement
}) => {
    return show ? tooltip({ children, contentWidth }) : <>{children}</>
}

export default ConditionalTooltip
