import { Box } from '@mui/material'
import { FC, ReactNode } from 'react'

import BackgroundImage from 'images/gradient-background.png'

const GradientWrapper: FC<{ children: ReactNode }> = ({ children }) => {
    return (
        <Box
            sx={{
                p: 6,
                width: '100%',
                flex: '1 1 auto',
                borderRadius: '32px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                boxSizing: 'border-box',
                backgroundImage: `url(${BackgroundImage})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
            }}
        >
            {children}
        </Box>
    )
}

export default GradientWrapper
