import {
    AccountBalanceOutlined,
    AcUnitOutlined,
    AgricultureOutlined,
    ArticleOutlined,
    AttachmentOutlined,
    BlenderOutlined,
    BuildOutlined,
    BusinessCenterOutlined,
    CakeOutlined,
    CampaignOutlined,
    CarRepairOutlined,
    CategoryOutlined,
    ChairOutlined,
    ChatOutlined,
    CheckroomOutlined,
    CloudOutlined,
    ConstructionOutlined,
    ContentCutOutlined,
    DeleteOutlined,
    DevicesOutlined,
    DirectionsBoatOutlined,
    DirectionsCarOutlined,
    DomainOutlined,
    EggAltOutlined,
    EggOutlined,
    ElectricBoltOutlined,
    FactoryOutlined,
    FestivalOutlined,
    FlightOutlined,
    ForestOutlined,
    FormatPaintOutlined,
    GavelOutlined,
    GrassOutlined,
    HandymanOutlined,
    HealthAndSafetyOutlined,
    HomeOutlined,
    HotelOutlined,
    InventoryOutlined,
    KeyOutlined,
    LandscapeOutlined,
    LandslideOutlined,
    LocalBarOutlined,
    LocalCafeOutlined,
    LocalFireDepartmentOutlined,
    LocalFloristOutlined,
    LocalGasStationOutlined,
    LocalShippingOutlined,
    MedicalInformationOutlined,
    MedicationOutlined,
    OilBarrelOutlined,
    ParkOutlined,
    PetsOutlined,
    PowerOutlined,
    PrecisionManufacturingOutlined,
    RecyclingOutlined,
    RestaurantMenuOutlined,
    RestaurantOutlined,
    SchoolOutlined,
    ScienceOutlined,
    SetMealOutlined,
    ShoppingBagOutlined,
    SmokingRoomsOutlined,
    SpaOutlined,
    SportsBaseballOutlined,
    TakeoutDiningOutlined,
    TerrainOutlined,
    TheaterComedyOutlined,
    TrainOutlined,
    WarehouseOutlined,
    WaterDropOutlined,
    YardOutlined,
} from '@mui/icons-material'
import React, { useMemo } from 'react'

const categoryNames = [
    'agriculture',
    'livestock',
    'plants',
    'fishing activities',
    'forestry activities',
    'mining activities',
    'land use change',
    'advertising services',
    'cloud computing',
    'consumer goods rental',
    'domestic services',
    'financial services',
    'government services',
    'information and communication services',
    'legal services',
    'publishing services',
    'vehicle maintenance and services',
    'cultural services',
    'education services',
    'recreational services',
    'gardening services',
    'medical services',
    'accommodation',
    'catering services',
    'restaurants',
    'air transportation',
    'rail transportation',
    'road transportation',
    'storage',
    'water transportation',
    'construction',
    'construction materials',
    'infrastructure',
    'clothing',
    'footwear',
    'sports products',
    'textiles',
    'beverages: alcoholic',
    'beverages: non-alcoholic',
    'dairy products',
    'fish and seafood',
    'fruits and vegetables',
    'grains and grain products',
    'meat, poultry and eggs',
    'nuts and seeds',
    'oils and fats',
    'ready-made food and other food products',
    'sugar, confectionery and desserts',
    'metals',
    'metal manufacturing',
    'natural materials',
    'wood materials',
    'food processing',
    'ceramic products',
    'chemicals and chemical products',
    'electrical equipment',
    'electronic products',
    'furniture',
    'home appliances',
    'machinery',
    'medical products',
    'office supplies',
    'paint',
    'paper products',
    'personal care products',
    'pharmaceutical products',
    'tobacco products',
    'vehicle parts',
    'fuel',
    'heat and steam',
    'utilities',
    'refrigerants and fugitive gases',
    'commercial and industrial waste',
    'construction waste',
    'household waste',
    'waste management',
    'water',
    'glass',
    'plastics',
    'packaging',
    'business services',
] as const
export type CategoryName = (typeof categoryNames)[number]
export function isCategoryName(maybeCategoryName: string): maybeCategoryName is CategoryName {
    return categoryNames.includes(maybeCategoryName as CategoryName)
}

// colour: [R, G, B]
const categoryMetadata = new Map<
    CategoryName,
    { icon: React.ReactNode; colour: [number, number, number] }
>([
    ['agriculture', { icon: <AgricultureOutlined />, colour: [255, 185, 103] }],
    ['livestock', { icon: <PetsOutlined />, colour: [255, 119, 89] }],
    ['plants', { icon: <LocalFloristOutlined />, colour: [196, 235, 158] }],
    ['fishing activities', { icon: <SetMealOutlined />, colour: [0, 153, 241] }],
    ['forestry activities', { icon: <ParkOutlined />, colour: [196, 253, 158] }],
    ['mining activities', { icon: <LandslideOutlined />, colour: [189, 189, 189] }],
    ['land use change', { icon: <TerrainOutlined />, colour: [255, 119, 89] }],
    ['advertising services', { icon: <CampaignOutlined />, colour: [183, 126, 255] }],
    ['cloud computing', { icon: <CloudOutlined />, colour: [105, 223, 201] }],
    ['consumer goods rental', { icon: <KeyOutlined />, colour: [255, 139, 216] }],
    ['domestic services', { icon: <HomeOutlined />, colour: [255, 139, 216] }],
    ['financial services', { icon: <AccountBalanceOutlined />, colour: [105, 223, 201] }],
    ['government services', { icon: <AccountBalanceOutlined />, colour: [120, 125, 248] }],
    ['information and communication services', { icon: <ChatOutlined />, colour: [183, 126, 255] }],
    ['legal services', { icon: <GavelOutlined />, colour: [120, 125, 248] }],
    ['publishing services', { icon: <ArticleOutlined />, colour: [183, 126, 255] }],
    [
        'vehicle maintenance and services',
        { icon: <DirectionsCarOutlined />, colour: [59, 201, 240] },
    ],
    ['cultural services', { icon: <TheaterComedyOutlined />, colour: [229, 126, 255] }],
    ['education services', { icon: <SchoolOutlined />, colour: [120, 125, 248] }],
    ['recreational services', { icon: <FestivalOutlined />, colour: [229, 126, 255] }],
    ['gardening services', { icon: <YardOutlined />, colour: [196, 235, 258] }],
    ['medical services', { icon: <HealthAndSafetyOutlined />, colour: [255, 67, 67] }],
    ['accommodation', { icon: <HotelOutlined />, colour: [255, 225, 117] }],
    ['catering services', { icon: <RestaurantMenuOutlined />, colour: [255, 225, 117] }],
    ['restaurants', { icon: <RestaurantOutlined />, colour: [255, 225, 117] }],
    ['air transportation', { icon: <FlightOutlined />, colour: [0, 153, 241] }],
    ['rail transportation', { icon: <TrainOutlined />, colour: [255, 185, 103] }],
    ['road transportation', { icon: <LocalShippingOutlined />, colour: [189, 189, 189] }],
    ['storage', { icon: <WarehouseOutlined />, colour: [255, 225, 117] }],
    ['water transportation', { icon: <DirectionsBoatOutlined />, colour: [0, 153, 241] }],
    ['construction', { icon: <ConstructionOutlined />, colour: [255, 185, 103] }],
    ['construction materials', { icon: <DomainOutlined />, colour: [255, 185, 103] }],
    ['infrastructure', { icon: <HandymanOutlined />, colour: [189, 189, 189] }],
    ['clothing', { icon: <CheckroomOutlined />, colour: [253, 240, 125] }],
    ['footwear', { icon: <WaterDropOutlined />, colour: [255, 185, 103] }],
    ['sports products', { icon: <SportsBaseballOutlined />, colour: [255, 225, 117] }],
    ['textiles', { icon: <ContentCutOutlined />, colour: [229, 126, 255] }],
    ['beverages: alcoholic', { icon: <LocalBarOutlined />, colour: [229, 126, 255] }],
    ['beverages: non-alcoholic', { icon: <LocalCafeOutlined />, colour: [105, 223, 201] }],
    ['dairy products', { icon: <EggOutlined />, colour: [255, 225, 117] }],
    ['fish and seafood', { icon: <SetMealOutlined />, colour: [0, 153, 241] }],
    ['fruits and vegetables', { icon: <LocalFloristOutlined />, colour: [196, 235, 158] }],
    ['grains and grain products', { icon: <GrassOutlined />, colour: [255, 225, 117] }],
    ['meat, poultry and eggs', { icon: <EggOutlined />, colour: [255, 119, 89] }],
    ['nuts and seeds', { icon: <EggAltOutlined />, colour: [255, 225, 117] }],
    ['oils and fats', { icon: <OilBarrelOutlined />, colour: [253, 240, 125] }],
    [
        'ready-made food and other food products',
        { icon: <TakeoutDiningOutlined />, colour: [255, 119, 89] },
    ],
    ['sugar, confectionery and desserts', { icon: <CakeOutlined />, colour: [253, 240, 125] }],
    ['metals', { icon: <FactoryOutlined />, colour: [59, 201, 240] }],
    ['metal manufacturing', { icon: <PrecisionManufacturingOutlined />, colour: [59, 201, 240] }],
    ['natural materials', { icon: <LandscapeOutlined />, colour: [255, 185, 103] }],
    ['wood materials', { icon: <ForestOutlined />, colour: [255, 119, 89] }],
    ['food processing', { icon: <RestaurantOutlined />, colour: [255, 119, 89] }],
    ['ceramic products', { icon: <BlenderOutlined />, colour: [59, 201, 240] }],
    ['chemicals and chemical products', { icon: <ScienceOutlined />, colour: [105, 223, 201] }],
    ['electrical equipment', { icon: <ElectricBoltOutlined />, colour: [253, 240, 125] }],
    ['electronic products', { icon: <DevicesOutlined />, colour: [105, 223, 201] }],
    ['furniture', { icon: <ChairOutlined />, colour: [255, 119, 89] }],
    ['home appliances', { icon: <HomeOutlined />, colour: [255, 225, 117] }],
    ['machinery', { icon: <BuildOutlined />, colour: [189, 189, 189] }],
    ['medical products', { icon: <MedicalInformationOutlined />, colour: [255, 67, 67] }],
    ['office supplies', { icon: <AttachmentOutlined />, colour: [255, 185, 103] }],
    ['paint', { icon: <FormatPaintOutlined />, colour: [0, 153, 241] }],
    ['paper products', { icon: <ArticleOutlined />, colour: [255, 185, 103] }],
    ['personal care products', { icon: <SpaOutlined />, colour: [255, 225, 117] }],
    ['pharmaceutical products', { icon: <MedicationOutlined />, colour: [244, 67, 67] }],
    ['tobacco products', { icon: <SmokingRoomsOutlined />, colour: [255, 187, 103] }],
    ['vehicle parts', { icon: <CarRepairOutlined />, colour: [59, 201, 240] }],
    ['fuel', { icon: <LocalGasStationOutlined />, colour: [0, 153, 241] }],
    ['heat and steam', { icon: <LocalFireDepartmentOutlined />, colour: [255, 185, 103] }],
    ['utilities', { icon: <PowerOutlined />, colour: [253, 240, 125] }],
    ['refrigerants and fugitive gases', { icon: <AcUnitOutlined />, colour: [105, 223, 201] }],
    ['commercial and industrial waste', { icon: <DeleteOutlined />, colour: [189, 189, 189] }],
    ['construction waste', { icon: <DeleteOutlined />, colour: [189, 189, 189] }],
    ['household waste', { icon: <DeleteOutlined />, colour: [189, 189, 189] }],
    ['waste management', { icon: <RecyclingOutlined />, colour: [189, 189, 189] }],
    ['water', { icon: <WaterDropOutlined />, colour: [0, 153, 241] }],
    ['glass', { icon: <WaterDropOutlined />, colour: [59, 201, 240] }],
    ['plastics', { icon: <ShoppingBagOutlined />, colour: [59, 201, 240] }],
    ['packaging', { icon: <InventoryOutlined />, colour: [255, 185, 103] }],
    ['business services', { icon: <BusinessCenterOutlined />, colour: [120, 125, 248] }],
])

export const CategoryIcon = ({ name }: { name: string }) => {
    const icon = useMemo((): React.ReactNode => {
        if (!isCategoryName(name)) {
            return <CategoryOutlined />
        }
        return categoryMetadata.get(name)!.icon
    }, [name])
    return icon
}
