import { styled, useTheme } from '@mui/material/styles'
import Switch, { SwitchProps } from '@mui/material/Switch'
import { FC, useMemo } from 'react'

const CustomizedSwitches: FC<SwitchProps> = (props) => {
    const { palette, ...theme } = useTheme()

    const StyledSwitch = useMemo(
        () =>
            styled(Switch)({
                width: 42,
                height: 26,
                padding: 0,
                '& .MuiSwitch-switchBase': {
                    padding: 0,
                    margin: 2,
                    transitionDuration: '300ms',
                    '&.Mui-checked': {
                        transform: 'translateX(16px)',
                        color: '#fff',
                        '& + .MuiSwitch-track': {
                            backgroundColor: palette.Grey900,
                            opacity: 1,
                            border: 0,
                        },
                        '&.Mui-disabled + .MuiSwitch-track': {
                            opacity: 0.5,
                        },
                    },
                    '&.Mui-focusVisible .MuiSwitch-thumb': {
                        color: palette.Grey900,
                        border: '6px solid #fff',
                    },
                    '&.Mui-disabled .MuiSwitch-thumb': {
                        color: palette.Grey600,
                    },
                    '&.Mui-disabled + .MuiSwitch-track': {
                        opacity: 0.7,
                    },
                },
                '& .MuiSwitch-thumb': {
                    boxSizing: 'border-box',
                    width: 22,
                    height: 22,
                },
                '& .MuiSwitch-track': {
                    borderRadius: 26 / 2,
                    backgroundColor: '#E9E9EA',
                    opacity: 1,
                    transition: theme.transitions.create(['background-color'], {
                        duration: 500,
                    }),
                },
            }),
        [palette, theme],
    )

    return <StyledSwitch {...props} />
}

export default CustomizedSwitches
