import {
    Area,
    CloudProvider,
    CompanyEmissionEstimate,
    CompanyEstimateRequest,
    CurrencyCode,
    Distance,
} from '@lune-climate/lune'
import { CountryPicker, Select, Switch, Text } from '@lune-fe/lune-ui-lib'
import Box from '@mui/material/Box'
import { useSnackbar } from 'notistack'
import { FC, RefObject, useEffect, useMemo, useState } from 'react'
import { Field } from 'react-final-form'
import { useParams } from 'react-router-dom'

import { FormInput } from 'components/FormInput'
import useAccounts from 'hooks/useAccounts'
import { useLuneClient } from 'hooks/useLuneClient'
import { SnackbarMessages } from 'SnackbarMessages'
import {
    CalculateEmissionsFormWrapper,
    CalculateEmissionsLabeledFormSection,
    CloudInfrastructureProviderPicker,
    EstimateTypeEnum,
    InputWithButtonGroup,
    InputWithCurrency,
} from 'views/CalculateEmissions'

export const composeValidators =
    (...validators: any[]) =>
    (value: string) =>
        validators.reduce((error, validator) => error || validator(value), undefined)

const required = (value: any) => (value ? undefined : SnackbarMessages.REQUIRED_FIELD)

const isWithin100Percent = (value: any) =>
    value >= 0 && value <= 100 ? undefined : 'Should be between 0 and 100'

const isInteger = (value?: unknown) =>
    value !== undefined && Number.isInteger(value) ? undefined : 'This should be an integer'

const onCreate = async (
    luneClient: ReturnType<typeof useLuneClient>,
    values: CompanyEstimateRequest,
) => luneClient.createCompanyEstimate(values)
const onUpdate = async (
    luneClient: ReturnType<typeof useLuneClient>,
    id: string,
    values: CompanyEstimateRequest,
) => luneClient.updateCompanyEstimate(id, values)

const CompanyCarbonFootprint: FC<{ scrollRef: RefObject<HTMLDivElement> }> = ({ scrollRef }) => {
    const { id } = useParams<{ id: string }>()
    const { enqueueSnackbar: snackbar } = useSnackbar()
    const { activeAccount } = useAccounts()
    const [initialFormValue, setInitialFormValue] = useState<CompanyEstimateRequest>()
    const [formValue, setFormValue] = useState<CompanyEstimateRequest>()
    const [onNextPage, setOnNextPage] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true)
    const [emissionEstimate, setEmissionEstimate] = useState<CompanyEmissionEstimate>()
    const luneClient = useLuneClient()

    useEffect(() => {
        setEmissionEstimate(undefined)
        setOnNextPage(false)
    }, [activeAccount])

    const defaultFormValues: CompanyEstimateRequest = useMemo(() => {
        const activeAccCurrency: CurrencyCode = activeAccount
            ? (activeAccount.currency as any as CurrencyCode)
            : CurrencyCode.GBP
        return {
            averageCarDistanceTravelled: {
                amount: '0',
                unit: Distance.unit.KM,
            },
            bundleSelection: undefined,
            city: '',
            companyCars: 0,
            countryCode: ``,
            electricityConsumption: 0,
            electronicDeviceExpenses: {
                value: '0',
                currency: activeAccCurrency,
            },
            employees: 0,
            employeesUsingPublicTransport: 0,
            firstOrBusinessClassPercentage: 0,
            foodAndDrinksExpenses: {
                value: '0',
                currency: activeAccCurrency,
            },
            garbage: 0,
            gasConsumption: 0,
            greenElectricityUsed: false,
            longFlights: 0,
            mediumFlights: 0,
            name: '',
            officeArea: {
                unit: Area.unit.SQUARE_METERS,
                amount: '0',
            },
            postcode: '',
            quantityTrunc: undefined,
            recycledGarbagePercentage: 0,
            remoteEmployeesPercentage: 0,
            shortFlights: 0,
            tech: {
                cloud: {
                    cost: {
                        value: `0`,
                        currency: activeAccCurrency,
                    },
                    provider: CloudProvider.AWS,
                },
                onPremise: {
                    electricityCost: {
                        value: `0`,
                        currency: activeAccCurrency,
                    },
                },
            },
            months: 12,
            vegetarianAndVeganPercentage: 0,
        }
    }, [activeAccount])

    useEffect(() => {
        if (id) {
            luneClient
                .getCompanyEstimate(id)
                .then((res) => {
                    if (res.isOk()) {
                        const estimate: CompanyEmissionEstimate = res.value
                        setEmissionEstimate(estimate)
                        setInitialFormValue(estimate.request)
                    } else {
                        setInitialFormValue(defaultFormValues)
                    }
                    setLoading(false)
                })
                .catch(() => {
                    snackbar(SnackbarMessages.GENERIC_ERROR)
                    setInitialFormValue(defaultFormValues)
                })
        } else {
            setInitialFormValue(defaultFormValues)
            setLoading(false)
        }
    }, [id, defaultFormValues, snackbar, luneClient])

    return (
        <CalculateEmissionsFormWrapper<CompanyEstimateRequest>
            emissionType={EstimateTypeEnum.COMPANY}
            loading={!activeAccount || loading}
            initialFormValue={initialFormValue!}
            setUpdatedFormValue={setFormValue}
            layoutScrollRef={scrollRef}
            onNext={onNextPage ? undefined : () => setOnNextPage(true)}
            onBack={onNextPage ? () => setOnNextPage(false) : undefined}
            onCreate={(v) => onCreate(luneClient, v)}
            onUpdate={(id, v) => onUpdate(luneClient, id, v)}
            emissionEstimateResult={emissionEstimate?.mass}
            emissionId={id}
            emissionEstimateName={emissionEstimate?.request.name}
        >
            {!onNextPage ? (
                <>
                    <CalculateEmissionsLabeledFormSection>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                gap: 1 / 2,
                            }}
                        >
                            <Field
                                sx={{ flex: '1 1 auto' }}
                                name={'name'}
                                placeholder={'Title (optional)'}
                                component={FormInput}
                            />
                            <Field
                                sx={{ width: '180px' }}
                                name={'months'}
                                component={({ input: { onChange, value }, meta }: any) => (
                                    <Select
                                        items={[
                                            {
                                                label: '3 months',
                                                value: 3,
                                            },
                                            {
                                                label: '6 months',
                                                value: 6,
                                            },
                                            {
                                                label: '12 months',
                                                value: 12,
                                            },
                                        ]}
                                        value={value}
                                        onChange={(e) => onChange(e)}
                                        error={meta.touched && meta.error}
                                    />
                                )}
                            />
                        </Box>
                    </CalculateEmissionsLabeledFormSection>
                    <CalculateEmissionsLabeledFormSection label={'Office location & size'}>
                        <Field name={'countryCode'} validate={required}>
                            {({ input: { onChange, value }, meta }: any) => (
                                <CountryPicker
                                    value={value}
                                    onChange={(e) => onChange(e)}
                                    error={meta.touched && meta.error}
                                />
                            )}
                        </Field>
                        <Field
                            name={'officeArea'}
                            component={({ input: { onChange, value } }, meta: any) => (
                                <InputWithButtonGroup
                                    onChange={onChange}
                                    value={value}
                                    error={meta.touched && meta.error}
                                    buttonItems={[
                                        {
                                            label: 'm2',
                                            value: Area.unit.SQUARE_METERS,
                                        },
                                        {
                                            label: 'ft2',
                                            value: Area.unit.SQUARE_FEET,
                                        },
                                    ]}
                                />
                            )}
                        />
                    </CalculateEmissionsLabeledFormSection>
                    <CalculateEmissionsLabeledFormSection label={'Electricity consumption'}>
                        <Field
                            name={'electricityConsumption'}
                            number
                            highlightOnFocus
                            endAdornment={'kWh'}
                            component={FormInput}
                            validate={isInteger}
                        />
                        <Field
                            name={'greenElectricityUsed'}
                            component={({ input: { onChange, value } }: any) => (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        height: '56px',
                                        mt: 1 / 2,
                                    }}
                                >
                                    <Text variant={`body3`}>We use green electricity</Text>
                                    <Switch
                                        defaultChecked={value}
                                        onChange={(event, checked) =>
                                            setTimeout(() => {
                                                onChange(checked)
                                            }, 300)
                                        }
                                    />
                                </Box>
                            )}
                        />
                    </CalculateEmissionsLabeledFormSection>
                    <CalculateEmissionsLabeledFormSection label={'Natural gas consumption'}>
                        <Field
                            name={'gasConsumption'}
                            number
                            highlightOnFocus
                            validate={isInteger}
                            endAdornment={'m3 of gas'}
                            component={FormInput}
                        />
                    </CalculateEmissionsLabeledFormSection>
                    <CalculateEmissionsLabeledFormSection label={'Waste production'}>
                        <Field
                            name={'garbage'}
                            number
                            highlightOnFocus
                            endAdornment={'kg'}
                            component={FormInput}
                            validate={isInteger}
                        />
                        <Field
                            name={'recycledGarbagePercentage'}
                            number
                            highlightOnFocus
                            endAdornment={'% recycled'}
                            validate={composeValidators(isInteger, isWithin100Percent)}
                            component={FormInput}
                        />
                    </CalculateEmissionsLabeledFormSection>
                    <CalculateEmissionsLabeledFormSection label={'Number of employees'}>
                        <Field
                            number
                            highlightOnFocus
                            name={'employees'}
                            endAdornment={'employees'}
                            component={FormInput}
                            validate={composeValidators(isInteger, (v: number) => {
                                if (!v || v <= 0) {
                                    return `This should be a positive number`
                                }
                            })}
                        />
                        <Field
                            name={'remoteEmployeesPercentage'}
                            endAdornment={'% remote'}
                            number
                            highlightOnFocus
                            validate={composeValidators(isInteger, isWithin100Percent)}
                            component={FormInput}
                        />
                    </CalculateEmissionsLabeledFormSection>
                    <CalculateEmissionsLabeledFormSection label={'Commuting'}>
                        <Field
                            name={'employeesUsingPublicTransport'}
                            number
                            highlightOnFocus
                            endAdornment={'employees commuting by public transport'}
                            component={FormInput}
                            validate={composeValidators(isInteger, (value: any) => {
                                if (formValue && value !== 0 && value > formValue.employees) {
                                    return 'Should be lower than total employees number'
                                }
                            })}
                        />
                    </CalculateEmissionsLabeledFormSection>
                    <CalculateEmissionsLabeledFormSection
                        label={'Average travel distance per company owned car'}
                    >
                        <Field
                            name={'averageCarDistanceTravelled'}
                            component={({ input: { onChange, value }, meta }: any) => (
                                <InputWithButtonGroup
                                    onChange={onChange}
                                    value={value}
                                    error={meta.touched && meta.error}
                                    buttonItems={[
                                        {
                                            label: 'km',
                                            value: Distance.unit.KM,
                                        },
                                        {
                                            label: 'miles',
                                            value: Distance.unit.MI,
                                        },
                                    ]}
                                />
                            )}
                        />
                        <Field
                            name={'companyCars'}
                            number
                            highlightOnFocus
                            endAdornment={'company owned cars'}
                            validate={isInteger}
                            component={FormInput}
                        />
                    </CalculateEmissionsLabeledFormSection>
                    <CalculateEmissionsLabeledFormSection label={'Number of flights'}>
                        <Field
                            name={'shortFlights'}
                            number
                            highlightOnFocus
                            endAdornment={'short flights (up to 3h)'}
                            component={FormInput}
                            validate={isInteger}
                        />
                        <Field
                            name={'mediumFlights'}
                            number
                            highlightOnFocus
                            endAdornment={'medium flights (up to 6h)'}
                            component={FormInput}
                            validate={isInteger}
                        />
                        <Field
                            name={'longFlights'}
                            number
                            highlightOnFocus
                            endAdornment={'long flights (over 6h)'}
                            component={FormInput}
                            validate={isInteger}
                        />
                    </CalculateEmissionsLabeledFormSection>
                    {(!!formValue?.longFlights ||
                        !!formValue?.shortFlights ||
                        !!formValue?.mediumFlights) && (
                        <CalculateEmissionsLabeledFormSection
                            label={'First / business class flights'}
                        >
                            <Field
                                name={'firstOrBusinessClassPercentage'}
                                endAdornment={'%'}
                                number
                                highlightOnFocus
                                validate={composeValidators(isInteger, isWithin100Percent)}
                                component={FormInput}
                            />
                        </CalculateEmissionsLabeledFormSection>
                    )}
                </>
            ) : (
                <>
                    <CalculateEmissionsLabeledFormSection label={'Amount spent on food & drinks'}>
                        <Field
                            name={'foodAndDrinksExpenses'}
                            component={({ input: { onChange, value }, meta }: any) => (
                                <InputWithCurrency
                                    error={meta.touched && meta.error}
                                    onChange={onChange}
                                    value={value}
                                />
                            )}
                        />
                        <Field
                            name={'vegetarianAndVeganPercentage'}
                            component={FormInput}
                            endAdornment={' % of vegan / vegetarian meals'}
                            number
                            highlightOnFocus
                            validate={composeValidators(isInteger, isWithin100Percent)}
                        />
                    </CalculateEmissionsLabeledFormSection>
                    <CalculateEmissionsLabeledFormSection
                        label={'Amount spent on newly purchased devices'}
                    >
                        <Field
                            name={'electronicDeviceExpenses'}
                            component={({ input: { onChange, value }, meta }: any) => (
                                <InputWithCurrency
                                    error={meta.touched && meta.error}
                                    onChange={onChange}
                                    value={value}
                                />
                            )}
                        />
                    </CalculateEmissionsLabeledFormSection>
                    <CalculateEmissionsLabeledFormSection label={'Cloud infrastructure cost'}>
                        <Field
                            name={'tech.cloud.cost'}
                            component={({ input: { onChange, value }, meta }: any) => (
                                <InputWithCurrency
                                    error={meta.touched && meta.error}
                                    onChange={onChange}
                                    value={value}
                                />
                            )}
                        />
                        <Field
                            name={'tech.cloud.provider'}
                            component={({ input: { onChange, value } }: any) => (
                                <CloudInfrastructureProviderPicker
                                    onChange={onChange}
                                    value={value}
                                />
                            )}
                        />
                    </CalculateEmissionsLabeledFormSection>
                    <CalculateEmissionsLabeledFormSection label={'On premise infrastructure cost'}>
                        <Field
                            name={'tech.onPremise.electricityCost'}
                            component={({ input: { onChange, value }, meta }: any) => (
                                <InputWithCurrency
                                    error={meta.touched && meta.error}
                                    onChange={onChange}
                                    value={value}
                                />
                            )}
                        />
                    </CalculateEmissionsLabeledFormSection>
                </>
            )}
        </CalculateEmissionsFormWrapper>
    )
}

export default CompanyCarbonFootprint
