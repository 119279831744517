import { InfiniteData, useInfiniteQuery } from 'react-query'

import { getInvoices } from 'endpoints/dapi'
import useActiveOrganisation from 'hooks/useActiveOrganisation'
import { queryKeys } from 'queryKeys'
import { PaginatedInvoices } from 'views/Settings/Billing/Billing'

/**
 * Loads invoices via infinite query (paginated query)
 */
const useInvoices = (): {
    paginatedInvoices: InfiniteData<PaginatedInvoices> | undefined
    loading: boolean
    fetchNextPage: () => void
    refetch: () => void
    hasNextPage: boolean | undefined
} => {
    const { activeOrg } = useActiveOrganisation()

    const {
        data: paginatedInvoices,
        isLoading,
        fetchNextPage,
        hasNextPage,
        refetch,
    } = useInfiniteQuery<PaginatedInvoices>({
        queryKey: queryKeys.GET_INVOICES,
        enabled: !!activeOrg,
        queryFn: async ({ pageParam = { cursor: undefined } }) => {
            return getInvoices({ after: pageParam.cursor })
        },
        getNextPageParam: (lastPage, allPages) => {
            return lastPage.data.hasMore ? { cursor: allPages.length + 1 } : undefined
        },
        refetchOnMount: true,
    })

    return {
        paginatedInvoices,
        loading: isLoading,
        fetchNextPage,
        refetch,
        hasNextPage,
    }
}

export default useInvoices
