import { Account as IAccount, Bundle, UpdateAccountRequest } from '@lune-climate/lune'
import { LoadingWrapper, MainLayoutContainer, Text } from '@lune-fe/lune-ui-lib'
import { useSnackbar } from 'notistack'
import { memo, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import AccountFormWrapper, {
    AccountFormKeys,
    IAccountFormData,
} from 'components/AccountForm/AccountFormWrapper'
import NotAdminTooltip from 'components/NotAdminTooltip'
import useAccounts from 'hooks/useAccounts'
import useIndividualAccountBundles from 'hooks/useIndividualAccountBundles'
import useIsTestMode from 'hooks/useIsTestMode'
import { useLuneClient } from 'hooks/useLuneClient'
import { useIsAuthorised } from 'hooks/useUserState'
import { SnackbarMessages } from 'SnackbarMessages'
import { CUSTOM_PORTFOLIO_ID } from 'views/Settings/BundlePicker/BundlePicker'

const Account = () => {
    const isTestMode = useIsTestMode()
    const isAuthorised = useIsAuthorised()
    const { accounts, refetchAccounts, loading } = useAccounts()
    const [account, setAccount] = useState<IAccount>()
    const [bundleSelection, setBundleSelection] = useState<Record<string, number | string>>()
    const [bundles, setBundles] = useState<Bundle[]>()
    const { accountId } = useParams<{ accountId: string }>()
    const luneClient = useLuneClient()

    const { enqueueSnackbar: snackbar } = useSnackbar()
    const { loadBundles, loadAllBundles, updateCustomBundleSelection } =
        useIndividualAccountBundles()

    const onUpdateSuccess = () => {
        refetchAccounts()
        snackbar(SnackbarMessages.SETTINGS_SAVED)
    }

    const onSubmit = async (data: IAccountFormData) => {
        refetchAccounts()

        if (!account) {
            return
        }

        await updateCustomBundleSelection(account.id, data.bundleSettings.customBundleSelection)
        const selectedPortfolioId = data.bundleSettings.selectedBundlePortfolioId
        return luneClient
            .updateAccount(account.id, {
                name: data.name,
                beneficiary: data.beneficiary,
                bundlePortfolioId:
                    selectedPortfolioId === CUSTOM_PORTFOLIO_ID ? undefined : selectedPortfolioId,
            } as UpdateAccountRequest & { bundlePortfolioId?: string })
            .then((r) => r.unwrap())
    }

    useEffect(() => {
        const account = accounts.find((acc) => acc.id === accountId)

        if (!account) {
            return
        }

        setAccount(account)

        loadBundles(account.id).then((bundleSelection) => {
            setBundleSelection(bundleSelection)
        })
        loadAllBundles(account.id).then((bundles) => {
            setBundles(bundles)
        })
    }, [accountId, accounts, loadAllBundles, loadBundles])

    return (
        <LoadingWrapper loading={loading} sx={{ height: 1 }}>
            <MainLayoutContainer
                headerComponent={
                    <>
                        <Text variant="h6" sx={{ mb: 1 }}>
                            Account
                        </Text>
                        <Text variant="h4" sx={{ mb: 1 }}>
                            {account?.name}
                        </Text>
                    </>
                }
            >
                <></>
                <LoadingWrapper loading={!bundleSelection || !account}>
                    {/* Key prop forces account form to re-mount when changing between accounts
                    This helps keep the BundlePicker data consistent */}
                    <div key={`${JSON.stringify(bundleSelection)}_${accountId}`}>
                        <AccountFormWrapper
                            loading={loading || !account}
                            bundles={bundles}
                            readOnly={!isAuthorised}
                            existingAccount
                            onSubmit={onSubmit}
                            onSuccess={onUpdateSuccess}
                            initialValues={{
                                name: account?.name,
                                currency: account?.currency!,
                                beneficiary: account?.beneficiary ?? undefined,
                                bundleSettings: {
                                    customBundleSelection: bundleSelection!,
                                    selectedBundlePortfolioId:
                                        account?.bundlePortfolioId ?? CUSTOM_PORTFOLIO_ID,
                                },
                            }}
                            disabledFields={isTestMode ? [AccountFormKeys.NAME] : undefined}
                            forbiddenTooltip={NotAdminTooltip}
                        />
                    </div>
                </LoadingWrapper>
            </MainLayoutContainer>
        </LoadingWrapper>
    )
}

export default memo(Account)
