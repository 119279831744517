export enum LoadMoreKeys {
    EMISSION_CALCULATION = 'emissionCalculation',
}

// A hook to be used to save Load more state
// It's used to save the last total count of loaded items, which can be used as initial load limit
const useLoadMoreState = (): {
    clearLastCount: (key: LoadMoreKeys) => void
    getLastCount: (key: LoadMoreKeys) => string | null
    saveLastCount: (key: LoadMoreKeys, count: string) => void
} => {
    const getLastCount = (key: LoadMoreKeys): string | null => {
        return sessionStorage.getItem(key)
    }

    const clearLastCount = (key: LoadMoreKeys) => {
        sessionStorage.removeItem(key)
    }

    const saveLastCount = (key: LoadMoreKeys, count: string) => {
        sessionStorage.setItem(key, count)
    }

    return {
        clearLastCount,
        getLastCount,
        saveLastCount,
    }
}

export default useLoadMoreState
