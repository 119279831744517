import { Button, CodeBox, Modal, StandardModalHeader, Text } from '@lune-fe/lune-ui-lib'
import ContentCopyIcon from '@mui/icons-material/ContentCopyOutlined'
import { useSnackbar } from 'notistack'
import { FC } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const SecretTokenModal: FC<{ secretToken: string; onClose: () => void }> = ({
    secretToken,
    onClose,
}) => {
    const { enqueueSnackbar: snackbar } = useSnackbar()
    return (
        <Modal
            header={<StandardModalHeader title={`Copy the secret before closing`} />}
            subheader={
                <Text variant={'body1'}>
                    You will not be able to view this secret again after you close this dialog
                </Text>
            }
            open
            onClose={() => {}}
            actions={
                <>
                    <Button variant={'outlined'} wide onClick={onClose} data-testid={`close-btn`}>
                        Close
                    </Button>
                    <CopyToClipboard
                        text={secretToken}
                        onCopy={() => snackbar(`Selection copied to the clipboard.`)}
                    >
                        <Button wide leftIcon={<ContentCopyIcon />} data-testid={`copy-btn`}>
                            Copy
                        </Button>
                    </CopyToClipboard>
                </>
            }
        >
            <CodeBox data-testid={`copy-secret-token`}>{secretToken}</CodeBox>
        </Modal>
    )
}

export default SecretTokenModal
