import { SingleShippingEmissionEstimate } from '@lune-climate/lune'
import { SuccessResponse } from '@lune-climate/lune/esm/core/SuccessResponse'
import { CalculationResults, resolvedLegToApiLeg } from '@lune-fe/lune-components-lib'
import { Stack } from '@mui/material'
import { FC, RefObject, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useLuneClient } from 'hooks/useLuneClient'
import { EstimateTypeEnum, EstimateTypeName, ResultsWrapper } from 'views/CalculateEmissions'
import SingleLegEstimate from 'views/CalculateEmissions/Logistics/SingleLegEstimate'
import MultiLegEstimate from 'views/CalculateEmissions/MultiLegLogistics/MultiLegEstimate'
import { Leg } from 'views/CalculateEmissions/MultiLegLogistics/MultiLegLogisticsResults'

const LogisticsResults: FC<{ scrollRef: RefObject<HTMLDivElement> }> = ({ scrollRef }) => {
    const { id } = useParams<{ id: string }>()
    const [estimate, setEstimate] = useState<SuccessResponse<SingleShippingEmissionEstimate>>()
    const [resolvedLegs, setResolvedLegs] = useState<Leg[]>([])
    const luneClient = useLuneClient()

    useEffect(() => {
        if (id) {
            luneClient.getShippingEstimate(id).then((result) => {
                if (result.isOk()) {
                    const estimate = result.value
                    if (estimate.resolvedLegs) {
                        setResolvedLegs(
                            estimate.resolvedLegs.map((resolvedLeg) => {
                                return resolvedLegToApiLeg(
                                    resolvedLeg,
                                    result.value.convertedShipment,
                                    result.value.shipmentConversionMethod,
                                )
                            }),
                        )
                    }
                }
                return result.map(setEstimate)
            })
        }
    }, [id, luneClient])

    return (
        <ResultsWrapper
            scrollRef={scrollRef}
            loading={!estimate?.request}
            calculatedAmount={estimate?.mass.amount!}
        >
            {estimate && (
                <>
                    <CalculationResults
                        title={estimate.request.name || EstimateTypeName(EstimateTypeEnum.SHIPPING)}
                        amount={estimate.mass.amount}
                    />
                    <Stack direction={'column'} spacing={9} mt={9}>
                        {resolvedLegs.length ? (
                            <MultiLegEstimate
                                legs={resolvedLegs}
                                load={estimate.request.shipment}
                                meta={estimate._meta}
                                source={estimate.source}
                                requestRoute={
                                    'route' in estimate.request ? estimate.request.route : undefined
                                }
                            />
                        ) : (
                            <SingleLegEstimate estimate={estimate} />
                        )}
                    </Stack>
                </>
            )}
        </ResultsWrapper>
    )
}

export default LogisticsResults
