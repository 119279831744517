import { useLocation } from 'react-router-dom'

/**
 * Returns `true` if we navigated to the current page using react-router's navigation,
 * `false` otherwise.
 */
export default function useNavigatedWithinTheApp(): boolean {
    const location = useLocation()
    // The documentation[1] doesn't currently say anything the "default" value.
    //
    // A Stack Overflow thread[2] suggests comparing against it and it seems to work.
    //
    // [1] https://reactrouter.com/en/main/hooks/use-location#locationkey
    // [2] https://stackoverflow.com/questions/37385570/how-to-know-if-react-router-can-go-back-to-display-back-button-in-react-app/70532858#70532858
    return location.key !== 'default'
}
